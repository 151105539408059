import { UserListItem } from "../../../../../../types";
import styles from "./index.module.scss";
import cn from "classnames";
import { CardContact } from "../../../../Card/card-contact/CardContact";

type Props = {
  data: UserListItem[];
  onClickClose?: (id: string) => void;
  withoutAvatars?: boolean;
  mini?: boolean;
  classNames?: string
  atModal?: boolean
  refetch?: () => void;
};

export const UserListCards = (
  {
    data,
    onClickClose,
    withoutAvatars,
    mini = false,
    classNames,
    atModal,
    refetch
  }: Props): JSX.Element => {
  const isThereData = data && data.length > 0;

  return (
    <>
      <ul className={cn(styles.body, classNames)}>
        {isThereData &&
          data.map((user) => (
            <li
              key={user.id}
            >
              <CardContact
                contact={user}
                mini={mini}
                backgroundWhite={atModal}
                canOpenNew={atModal}
                atList={atModal}
                refetch={refetch}
              />
            </li>
          ))}
      </ul>
      {!isThereData && <div className={styles.noData}>Не назначено</div>}
    </>
  );
};
