import { User } from "../../../types";

import { DRAWER_IN_DRAWER_ID, FixedNav } from "../../UI";
import { CallsWithCompany, CallsWithUser, GeneralForm, Title } from "./components";
import { useLoadCallsBetweenPhones, useLoadCallsWithPhone, useLoadUsers } from "../../../service/api/queries";
import { RoleGuard } from "../../../service/router/RoleGuard";
import { useSelect, selectCurrentAccount } from "../../../service/redux/selectors";
import { scrollToElement } from "../../../utils/scroll";

import styles from "./index.module.scss";

type Props = {
	fio?: string;
	email?: string;
	phone: string;
	inDrawer?: boolean;
};

export const UnknownContact = ({ phone, inDrawer, fio, email }: Props): JSX.Element => {
	const { users } = useLoadUsers({});
	const { currentAccount } = useSelect(selectCurrentAccount);
	const { data: callsWithUser } = useLoadCallsBetweenPhones([phone, currentAccount?.account.phone || ""]);
	const { data: callsWithCompany, canViewAllCompany } = useLoadCallsWithPhone(phone);

	const scrollTopOffset = 55;

	const blocksIds = {
		wrapper: inDrawer ? DRAWER_IN_DRAWER_ID : "drawer-right-block",
		general: "general-block-unknown-contact-view",
		usersCalls: "users-calls-block-unknown-contact-view",
		companyCalls: "company-calls-block-unknown-contact-view",
	};

	const navigation = () => {
		const nav = [
			{
				label: "Основное",
				action: () => scrollToElement(blocksIds.wrapper, blocksIds.general, scrollTopOffset),
			},
			{
				label: "Звонки с пользователем",
				action: () => scrollToElement(blocksIds.wrapper, blocksIds.usersCalls, scrollTopOffset),
			},
		];

		if (canViewAllCompany) {
			nav.push({
				label: "Звонки с агентством",
				action: () => scrollToElement(blocksIds.wrapper, blocksIds.companyCalls, scrollTopOffset),
			});
		}

		return nav;
	};

	return (
		<RoleGuard only="estateAgencyUser">
			<div className={styles.container}>
				<div className={styles.contentContainer}>
					<FixedNav navigation={navigation()} />

					<div className={styles.content}>
						<Title phone={phone} fio={fio} email={email} />
						<GeneralForm phone={phone} fio={fio} id={blocksIds.general} />
						<CallsWithUser
							calls={callsWithUser}
							user={(currentAccount as User).account}
							id={blocksIds.usersCalls}
						/>
						<CallsWithCompany
							users={users}
							calls={callsWithCompany}
							canView={canViewAllCompany}
							id={blocksIds.companyCalls}
						/>
					</div>
				</div>
			</div>
		</RoleGuard>
	);
};
