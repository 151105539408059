import { ReactNode, useRef, useEffect, useState } from "react";
import cn from "classnames";

import { toastInfo } from "../../../../index";
import { useClickOutside } from "../../../../../../utils/hooks";

import { ReactComponent as CopyIcon } from "../../../../../../assets/icons/copy.svg";
import { ReactComponent as PencilIcon } from "../../../../../../assets/icons/pencil.svg";
import styles from "./index.module.scss";

export type IndividualInputWrapperOptions = {
	canBeCopied?: boolean;
	initialEditMode?: boolean;
	stopCloseOnClickOutside?: boolean;
	displayInsteadOfValue?: string | ReactNode;
	closeEditMode?: boolean;
	onCloseOutside?: () => void;
};

type Props = {
	children: ReactNode;
	defaultValue: string;
	isLoading: boolean;
	className?: string;
	options?: IndividualInputWrapperOptions;
	classNames?: {
		value?: string;
	};
};

export const IndividualSaveWrapper = ({
	children,
	defaultValue,
	isLoading,
	className,
	options,
	classNames,
}: Props): JSX.Element => {
	const [editMode, setEditMode] = useState(options?.initialEditMode || false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	useClickOutside({
		ref: wrapperRef,
		onClick: () => {
			if (!isLoading && !options?.stopCloseOnClickOutside) {
				if (options?.onCloseOutside) {
					options?.onCloseOutside();
				}
				setEditMode(false);
			}
		},
	});

	useEffect(() => {
		if (options?.closeEditMode) {
			setEditMode(false);
		}
	}, [options?.closeEditMode]);

	const onCopy = () => {
		const tempInput = document.createElement("input");
		tempInput.value = defaultValue;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);

		toastInfo({ text: "Скопировано в буфер обмена", Icon: CopyIcon });
	};

	return (
		<div ref={wrapperRef} className={styles.wrapper}>
			<div className={cn(styles.value, { [styles.valueHide]: editMode }, classNames?.value)}>
				<div
					className={cn(className, {
						[styles.copy]: options?.canBeCopied,
					})}
					onClick={() => {
						if (options?.canBeCopied) {
							onCopy();
						}
					}}
				>
					{options?.displayInsteadOfValue || defaultValue}
				</div>
				<div className={styles.edit} onClick={() => setEditMode((prev) => !prev)}>
					<PencilIcon className={styles.pencil} />
				</div>
			</div>
			{editMode && children}
		</div>
	);
};
