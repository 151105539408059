import styles from "./index.module.scss"
import { Popup } from "../../../../../../../../../../../../../UI";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { MenuItem } from "../../../../../../../../../../../../ShowsAndViews/components/card-actions/CardActions";
import { ReactComponent as OpenInNew } from "../../../../../../../../../../../../../../assets/icons/openInNewBlue.svg";
import { ReactComponent as Warning } from "../../../../../../../../../../../../../../assets/icons/iconInfoSmall.svg";
import { ReactComponent as IconCancel } from "../../../../../../../../../../../../../../assets/icons/iconClear.svg";
import { ReactComponent as AddInCart } from "../../../../../../../../../../../../../../assets/icons/Shopping_Cart.svg";
import cn from "classnames";
import { KindType } from "../../../../../../../../../../../../../../types";
import {
  useCreateDeal,
  useFinishDeal,
  useTerminateDeal
} from "../../../../../../../../../../../../../../service/api/mutations";
import { useDispatch } from "react-redux";
import {
  closeDrawer,
  openDrawer,
  openDrawerInDrawer
} from "../../../../../../../../../../../../../../service/redux/modules/drawer/drawer.action";
import { ApplicationCard } from "../../../../../../../../../../index";
import { useEffect } from "react";
import { useOpenCancelApplicationModal } from "./cancel-modal/useOpenCancelApplicationModal";
import {
  useGetFromArchive
} from "../../../../../../../../../../../../RealEstatePage/mode/RealEstateView/components/stickyFooter/helpers/useGetFromArchive";
import { useApplyServiceModal } from "../apply-service-modal";


type Props = {
  applicationId: string
  kind: KindType | undefined
  whoIsClient?: "seller" | "buyer"
  refetch: () => void
  applicationPublicLink?: string
  isArchive?: boolean
}

export const StickyFooterApplication = (
  {
    applicationId,
    kind,
    whoIsClient,
    refetch,
    applicationPublicLink,
    isArchive,
  }: Props): JSX.Element => {

  const dispatch = useDispatch()
  const onSuccess = () => {
    refetch()
  }

  const onSuccessCancel = () => {
    dispatch(closeDrawer())
  }
  const {mutate: mutateFinishDeal} = useFinishDeal({onSuccess})
  const {mutate: mutateTerminateDeal} = useTerminateDeal({onSuccess: onSuccessCancel})
  const {mutate: mutateCreateDeal, data: SuccessData} = useCreateDeal({onSuccess})

  const buttonText = kind === KindType.APPLICATION ? "Создать сделку" : "Завершить сделку"
  const warningText = whoIsClient === "seller"
    ? "Прикрепите заявку на покупку, чтобы начать сделку"
    : "Прикрепите заявку на продажу, чтобы начать сделку"

  const openOnPublic = () => {
    window.open(`${applicationPublicLink}`)
  }

  const openDeal = (id?: string) => {
    if (id) {
      dispatch(
        openDrawerInDrawer({
          children: <ApplicationCard applicationId={id} inDrawer/>,
          width: 800,
        })
      );
    }
  }

  const {openApplyServiceModal} = useApplyServiceModal({applicationId})

  const {handleCancelApplicationModal} = useOpenCancelApplicationModal({applicationId, whoIsClient})

  const actionsMenu: MenuItem[] = [
    {
      label: "Открыть на публичной странице",
      icon: <OpenInNew/>,
      onClick: openOnPublic,
      id: 1,
    },
    {
      label: "Применить купон",
      icon: <AddInCart/>,
      onClick: openApplyServiceModal,
      id: 2
    },
    {
      label: "Отказаться",
      icon: <IconCancel/>,
      onClick: handleCancelApplicationModal,
      id: 5,
      className: "1"
    },

  ]

  const actualMenu = actionsMenu

  const mainButtonAction = () => {
    if (kind === KindType.APPLICATION) {
      mutateCreateDeal(applicationId)
    } else {
      mutateFinishDeal(applicationId)
    }
  }
  const {handleGetFromArchive} = useGetFromArchive(applicationId)

  useEffect(() => {
    if (SuccessData) {
      const currentApplicationId = (SuccessData as any)?.id
      // history.push(`/leadsanddeals?tab=applications&id=${currentApplicationId}`)
      dispatch(closeDrawer())
      dispatch(openDrawer({
        children: <ApplicationCard applicationId={currentApplicationId} kind={KindType.DEAL}/>,
        width: 800
      }))
    }
  }, [SuccessData]);

  return (
    <div className={styles.bottoms}>
      {kind === KindType.APPLICATION && !isArchive &&
        <>
          <div className={styles.row}>
            <div>
              <Warning/>
            </div>
            <div className={styles.warning}>
              {warningText}
            </div>
          </div>
          <div className={styles.actions}>
            <Popup
              openTrigger={<div className={styles.openTrigger}>Другие действия</div>}
              options={{withArrow: true}}
              classNames={
                {
                  trigger: styles.clicked,
                  arrow: styles.arrow,
                  card: styles.popupCard
                }
              }
            >
              {actualMenu.map((item) => (
                <div key={item.id} className={cn(
                  styles.actionItem,
                  {[ styles.deleteActionItem ]: item.className !== undefined}
                )} onClick={item.onClick}>
                  {item.icon}
                  <span>{item.label}</span>
                </div>
              ))}
            </Popup>
          </div>
        </>
      }
      {kind === KindType.DEAL && !isArchive &&
        <div className={styles.deleteActionItem} onClick={() => mutateTerminateDeal(applicationId)}>
          <IconCancel/>
          <div>Сделка сорвалась</div>
        </div>
      }
      {!isArchive &&
        <Button onClick={mainButtonAction}>
          {buttonText}
        </Button>
      }
      {isArchive &&
        <Button onClick={() => handleGetFromArchive(applicationId)}>
          Создать лид
        </Button>
      }
    </div>
  )
}