import { ReactElement, useMemo } from "react";

type Props = {
	children?: ReactElement | ReactElement[];
	selectedTab: number;
};

type Return = {
	currentChildren: ReactElement;
};

type UseChildrenComponent = (props: Props) => Return;

export const useChildrenComponent: UseChildrenComponent = ({ children, selectedTab }) => {
	/**
	 * Need for adding selectedTab to props
	 */
	const currentChildren = useMemo(() => {
		if (children) {
			const mainSelectedChildren: ReactElement = children[selectedTab];

			const { children: nestedChildren } = mainSelectedChildren.props;

			if (nestedChildren?.length > 0) {
				return {
					...mainSelectedChildren,
					props: {
						...mainSelectedChildren.props,
						children: nestedChildren?.map((child) => ({
							...child,
							props: {
								...child.props,
								selectedTab,
							},
						})),
					},
				};
			}

			return {
				...mainSelectedChildren,
				props: {
					...mainSelectedChildren.props,
					children: {
						...nestedChildren,
						selectedTab,
					},
				},
			};
		}

		return <></>;
	}, [selectedTab, children]);

	return {
		currentChildren,
	};
};
