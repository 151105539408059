import { IUserEditFormResponse, ProductTypeEnum } from "../../../../../types";
import styles from "./index.module.scss"
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { MenuItem } from "../../../ShowsAndViews/components/card-actions/CardActions";
import { ReactComponent as OpenInNew } from "../../../../../assets/icons/openInNewBlue.svg";
import { ReactComponent as Arrows } from "../../../../../assets/icons/DoubleChevron_Right.svg";
import { Popup } from "../../../../UI";
import cn from "classnames";
import { useAddInCartModal } from "../../../Servises/cart/helpers/addInCartModal/useAddInCartModal";
import { useAddToCart } from "../../../../../service/api/mutations";
import { useFireModal } from "../fireModal";
import { useChangeOfficeModal } from "../change-office-modal";

type Props = {
  user?: IUserEditFormResponse
  canEdit?: boolean;
  refetch: () => void;
}

export const PersonalCardStickyFooter = (
  {
    user,
    canEdit,
    refetch
  }: Props): JSX.Element => {

  const openOnPublic = () => {
    window.open(`${user?.publicPageLink}`)
  }
const { openFireModal } = useFireModal({id: user?.id ?? "", refetch: refetch})
const { openChangeModal } = useChangeOfficeModal({id: user?.id ?? "", refetch: refetch})

  const {mutate} = useAddToCart({onSuccess: () => {refetch()}})

  const {openAddInCartModal} = useAddInCartModal({
    ids: [user?.id ?? ""],
    type: ProductTypeEnum.employerSubscribtion,
    title: "Выберите срок продления",
    action: mutate,
  })

  const actionsMenu: MenuItem[] = [
    {
      label: "Сменить офис",
      icon: <Arrows/>,
      onClick: openChangeModal,
      id: 1
    },
    {
      label: "Уволить",
      icon: <Arrows/>,
      onClick: openFireModal,
      id: 1
    },
    {
      label: "Открыть на публичной странице",
      icon: <OpenInNew/>,
      onClick: openOnPublic,
      id: 3,
    },
  ]

  const actualMenu = canEdit ? actionsMenu : actionsMenu.slice(2)

  return (
    <div className={styles.root}>
      <div className={styles.actions}>
        <Popup
          openTrigger={<div className={styles.openTrigger}>Другие действия</div>}
          options={{withArrow: true}}
          classNames={
            {
              trigger: styles.trigger,
              arrow: styles.arrow,
              card: styles.popupCard
            }
          }
        >
          {actualMenu.map((item) => (
            <div key={item.id} className={cn(
              styles.actionItem,
              {[ styles.deleteActionItem ]: item.className !== undefined}
            )} onClick={item.onClick}>
              {item.icon}
              <span>{item.label}</span>
            </div>
          ))}
        </Popup>
      </div>
      <Button
        onClick={() => openAddInCartModal()}
        disabled={user?.isInCart}
      >
        {user?.isInCart ? "В корзине" : "Продлить аккаунт"}
      </Button>
    </div>
  )
}