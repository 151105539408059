import { useState, useEffect } from "react";

import { toastError } from "../../../../../../../../UI";
import { useChangeApplicationStage } from "../../../../../../../../../service/api/mutations";
import { useHandleFinishApplication } from "../../../../../../../../../utils/hooks";
import {
	StagesType,
	ChangeStageResponse,
	ApplicationChangeStageError,
	KindType,
} from "../../../../../../../../../types";

type UseUpdateStage = {
	stages: StagesType[];
	applicationId: string;
	refetch: () => void;
	stageNum: number;
	kind?: KindType
};

export const useChangeStage = ({ stages, applicationId, refetch, stageNum, kind }: UseUpdateStage) => {
	const [errorsLabel, setErrorsLabel] = useState<string[]>([]);

	useEffect(() => {
		setErrorsLabel([]);
	}, [stageNum]);

	const onSuccess = (data: ChangeStageResponse) => {
		const { errors } = data as ApplicationChangeStageError;

		if (errors) {
			const errorsLabelMap = errors?.map((error) => error.label);
			setErrorsLabel(errorsLabelMap || []);
			toastError({
				text: "Для перехода на следующий этап, не выполнены все условия.",
			});
		} else {
			setErrorsLabel([]);
		}

		refetch();
	};

	const { handleSuccessApplication: onFinish, isLoading: isLoadingFinish } = useHandleFinishApplication({
		applicationId,
		onSuccess: refetch,
	});

	const { mutate, isLoading: isLoadingChangeStage } = useChangeApplicationStage({ onSuccess, kind });

	const onNextStage = (stageNumber: number) => {
		const stageName = stages[stageNumber - 1].name;
		mutate({ applicationId, stageName });
		refetch();
	};

	const isLoading = isLoadingChangeStage || isLoadingFinish;

	return {
		onNextStage,
		isLoading,
		errorsLabel,
		onFinish,
	};
};
