import { ReactNode } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	isOpen?: boolean;
	className?: string;
};

export const SideContainer = ({ children, isOpen = false, className }: Props): JSX.Element => (
	<div
		className={cn(
			styles.root,
			{
				[styles.opened]: isOpen,
			},
			className
		)}
	>
		{children}
	</div>
);
