import { useState } from "react";
import { useQuery } from "react-query";
import { Conditions, RealEstateFilter } from "../../../../types";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate/load-parsed-objects-count/DEFAULT_QN";

type Props = {
	defaultFilters?: RealEstateFilter;
	absoluteSearchConditions?: Conditions;
	enabled?: boolean;
};

// Load only objects count (with filtration possibility)
export const useLoadParsedRealEstatesCount = ({
	defaultFilters = {},
	absoluteSearchConditions = [],
	enabled = true,
}: Props) => {
	const [filter, setFilter] = useState<RealEstateFilter>(defaultFilters);
	const [take, setTake] = useState(20);

	const skip = 0;

	const loadObjectsCount = () =>
		api.parsedRealEstate.count({
			take,
			skip,
			search: filter,
			absoluteSearchConditions,
		});

	const { data, isLoading } = useQuery([DEFAULT_QN, take, skip, filter], loadObjectsCount, { enabled });

	return {
		isLoading,
		total: data?.data || 0,
		take,
		setTake,
		filter,
		setFilter,
	};
};
