import {
	CLEAN_UP,
	SET_DEFAULT_VALUES,
	SET_DISABLED,
	SET_DEFAULT_VALUES_FOR_ONE,
	SET_SHOW_VALUES,
	ShowAndViewsActions,
} from "./shows-and-views.action";
import { ShowsAndViewsTInitialState } from "../../types";

const initialState: ShowsAndViewsTInitialState = {
	disabledValues: {},
	showValues: {},
};

const DEFAULT_DISABLED_VALUES = [false, false, false, false, false, false];
const DEFAULT_SHOW_VALUES = [true, true, true, false, false, false];

const generateDefault = (ids: string[]) => {
	const disabledValues = {};
	const showValues = {};
	ids.forEach((id) => {
		disabledValues[id] = DEFAULT_DISABLED_VALUES;
		showValues[id] = DEFAULT_SHOW_VALUES;
	});

	return {
		disabledValues,
		showValues,
	};
};

export const showsAndViewsReducer = (
	state = initialState,
	action: ShowAndViewsActions
): ShowsAndViewsTInitialState => {
	switch (action.type) {
		case SET_DEFAULT_VALUES_FOR_ONE:
			return {
				...state,
				disabledValues: {
					...state.disabledValues,
					[action.id]: DEFAULT_DISABLED_VALUES,
				},
				showValues: {
					...state.showValues,
					[action.id]: DEFAULT_SHOW_VALUES,
				},
			};
		case SET_DEFAULT_VALUES:
			return {
				...state,
				...generateDefault(action.ids),
			};
		case SET_DISABLED:
			return {
				...state,
				disabledValues: {
					...state.disabledValues,
					[action.id]: action.disabledValues,
				},
			};
		case SET_SHOW_VALUES:
			return {
				...state,
				showValues: {
					...state.showValues,
					[action.id]: action.showValues,
				},
			};
		case CLEAN_UP:
			return {
				...state,
				...initialState,
			};
		default:
			return state;
	}
};
