import { Dispatch, SetStateAction, useEffect } from "react";

import {
	useLoadApplicationObjectsCount,
	useLoadParsedRealEstatesCount,
} from "../../../../../../../service/api/queries";
import { RealEstateFilter, RealEstateListTab } from "../../../../../../../types";

type Props = {
	tab?: RealEstateListTab;
	defaultFilters: RealEstateFilter;
};

type Return = {
	isLoading: boolean;
	total: number;
	filter: RealEstateFilter;
	setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
};

type UseLoadObjectsCount = (props: Props) => Return;

export const useLoadObjectsCount: UseLoadObjectsCount = ({ tab = "PARSED", defaultFilters }) => {
	const parsedObjectsEnabled = tab === "PARSED";
	const applicationObjectEnabled = tab === "AGENCY";

	const {
		isLoading: parsedObjectsIsLoading,
		total: parsedObjectsTotal,
		filter: parsedObjectsFilter,
		setFilter: parsedObjectsSetFilter,
	} = useLoadParsedRealEstatesCount({
		defaultFilters,
		enabled: parsedObjectsEnabled,
	});

	const {
		isLoading: applicationObjectsIsLoading,
		total: applicationObjectsTotal,
		filter: applicationObjectsFilter,
		setFilter: applicationObjectsSetFilter,
	} = useLoadApplicationObjectsCount({
		defaultFilters,
		enabled: applicationObjectEnabled,
	});

	const loadHook: Record<Exclude<RealEstateListTab, "AD">, Return> = {
		PARSED: {
			isLoading: parsedObjectsIsLoading,
			total: parsedObjectsTotal,
			filter: parsedObjectsFilter,
			setFilter: parsedObjectsSetFilter,
		},
		AGENCY: {
			isLoading: applicationObjectsIsLoading,
			total: applicationObjectsTotal,
			filter: applicationObjectsFilter,
			setFilter: applicationObjectsSetFilter,
		},
	};

	useEffect(() => {
		loadHook[tab].setFilter(defaultFilters);
	}, [defaultFilters, tab]);
	return loadHook[tab];
};
