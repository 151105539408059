import styles from "./index.module.scss"
import { useLoadAppliedServicesHistory } from "../../../../service/api/queries";
import { CardProductForTable } from "../../Card";

type Props = {
  applicationId: string
}

export const AppliedServicesHistory = (
  {
    applicationId
  }: Props): JSX.Element => {

  const {isFetching, list, refetch} = useLoadAppliedServicesHistory({applicationId})

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        История применения купонов
      </div>
      <div className={styles.col}>
        {list && list.map((item => (
          <div className={styles.item} key={item.id}>
            <CardProductForTable
              product={item}
              refetch={refetch}
              type="applied"
            />
          </div>
        )))}
      </div>
    </div>
  )
}