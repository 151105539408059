import { useState } from "react";
import { useQuery } from "react-query";

import { api } from "../../index";

export const useLoadCallsBetweenPhones = (phones: [string, string]) => {
	const [take, setTake] = useState(10);
	const [page, setPage] = useState(1);

	const loadCalls = () => api.call.getCallsBetweenPhones(phones, { take, skip: take * (page - 1) });

	const { data, isLoading } = useQuery(["loadCallsBetweenPhones", take, page], loadCalls);

	return {
		data: data?.data?.data || [],
		isLoading,
		take,
		setTake,
		page,
		setPage,
	};
};
