import { useSelector } from "../../../../../service/redux/useSelector";

import { ReactComponent as CheckMark } from "../../../../../assets/icons/check-mark.svg";
import styles from "./index.module.scss";

export const CreatingAuthAccession = (): JSX.Element => {
	const { companyName } = useSelector((state) => state.regSteps);

	return (
		<div className={styles.root}>
			<CheckMark />
			<h2>{`Запрос на присоединение к компании ${companyName ? `«${companyName}»` : ""} отправлен`}</h2>
			<p>Ожидайте подтверждения в течении 3-х дней</p>
		</div>
	);
};
