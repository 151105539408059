import { useMutation } from "react-query";
import { RealEstateUpdatePhotoPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/real-estate/update-photo/DEFAULT_MN";

type UseObjectUpdatePhoto = {
	onSuccess?: () => void;
};

export const useObjectUpdatePhoto = ({ onSuccess }: UseObjectUpdatePhoto) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (payload: RealEstateUpdatePhotoPayload) => api.realEstate.updatePhoto(payload),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
