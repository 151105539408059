import { CategoryEnum } from "../../../../../../types";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";

import { InfoRow, PopupObjectType } from "../../../../index";

type Props = {
	control: Control<any>;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	label: string;
	getValues: UseFormGetValues<any>;
	setCategory: (category: CategoryEnum[], prevValue: CategoryEnum[]) => void;
	disabled?: boolean;
};

export const Category = ({
	control,
	register,
	setValue,
	label,
	getValues,
	setCategory,
	disabled,
}: Props): JSX.Element => {
	const watchCategory = useWatch({
		control,
		name: "category",
		defaultValue: getValues("category") || [],
	});

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={label}>
			<PopupObjectType
				register={register}
				setValue={setValue}
				defaultValues={watchCategory || []}
				variant="light"
				onChangeObjectType={setCategory}
				disabled={disabled}
				allowEmpty={false}
			/>
		</InfoRow>
	);
};
