import styles from "./index.module.scss"
import { Contact, ContactHistoryTabs } from "../../../../../../types";
import { useEffect, useState } from "react";
import { ReactComponent as IconNotification } from "../../../../../../assets/icons/iconHasNotifications.svg";
import { useForm } from "react-hook-form";
import { RadioButton } from "../../../../../UI";
import { Chat } from "./chat";
import { useLeadsAndDealsTabs } from "../../../../LeadsAndDeals/helpers";
import { NoData, Pagination } from "@sdelka_crm/sdelka-crm-component-library";
import { TrafficTable } from "../../../../LeadsAndDeals/components";
import { useLoadTraffic } from "../../../../../../service/api/queries";

type Props = {
  contact: Contact
}

type Tabs = {
  cian?: boolean,
  avito?: boolean,
  companySite?: boolean
};

const historyTypeOptions = ({cian, avito, companySite}: Tabs) => [
  {label: `Звонки`, value: "calls"},
  {label: `Чат Циан`, value: "chatCian", icon: cian && <IconNotification/>},
  {label: `Чат Авито`, value: "chatAvito", icon: avito && <IconNotification/>},
  {label: `Чат Сайт компании`, value: "chatCompany", icon: companySite && <IconNotification/>},
];

export const CommunicationHistory = ({contact}: Props): JSX.Element => {
  const {
    tabsNewEvents,
    id
  } = contact

  const trafficData = useLoadTraffic({});
  const {
    setFilter: setTrafficsFilter,
    isFetching: isFetchingTraffics,
  } = trafficData;

  useEffect(() => {
    // setTrafficsFilter({contactId: id})
  }, [id, setTrafficsFilter])
  
  const historyOptions = historyTypeOptions((tabsNewEvents || {}) as Tabs);
  const [tab, setTab] = useState<ContactHistoryTabs>(ContactHistoryTabs.calls)

  const {control} = useForm({
    defaultValues: {
      historyType: "calls",
    },
  });


  const onTypeChange = (value: string | number) => {
    switch (value) {
      case "calls":
        return setTab(ContactHistoryTabs.calls)
      case "chatCian":
        return setTab(ContactHistoryTabs.chatCian)
      case "chatAvito":
        return setTab(ContactHistoryTabs.chatAvito)
      case "chatCompany":
        return setTab(ContactHistoryTabs.chatCompany)
      default:
        return setTab(ContactHistoryTabs.calls)
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        История коммуникаций
      </div>
      <div className={styles.body}>
        <RadioButton
          name="historyType"
          control={control}
          options={historyOptions}
          handleOnChange={onTypeChange}
          background="blue"
          backgroundButtons="blue"
        />
      </div>
      <div className={styles.chatCard}>
        {tab === ContactHistoryTabs.calls &&
          <div className={styles.tabContent}>
            <div className={styles.tableWithPagination}>
              {!isFetchingTraffics && trafficData.isError ? (
                <NoData onRefetch={trafficData.refetch} />
              ) : (
                <>
                  <TrafficTable
                    trafficList={trafficData.list}
                    isLoading={isFetchingTraffics}
                    refetchTraffics={trafficData.refetch}
                    className={{
                      root: styles.callsWrapper,
                      title: styles.callsSeparator
                    }}
                  />
                </>
              )}
            </div>
          </div>
        }
        {tab !== ContactHistoryTabs.calls &&
        <Chat tab={tab} contactId={id} contact={contact}/>
        }
      </div>
    </div>
  )
}