import { useEffect, useMemo } from "react";
import ReactDOMServer from "react-dom/server";

import { toastError } from "../../../../../../index";
import { Loading, NoData, Info } from "./components";
import {
	useLoadParsedRealEstate,
	useLoadApplicationRealEstate,
} from "../../../../../../../../service/api/queries";

type Props = {
	id: string;
	applicationId?: string;
	clickOnTitleListener: () => void;
	clickOnContactButtonListener: () => void;
};

type Return = {
	renderContent: string;
};

type UseBalloon = (props: Props) => Return;

export const useBalloon: UseBalloon = ({
	id,
	applicationId,
	clickOnTitleListener,
	clickOnContactButtonListener,
}) => {
	const deleteListeners = () => {
		const balloonTitle = document.getElementById(`real-estate-balloon__button-${id}`);
		const contactButton = document.getElementById(`balloon__contacts-phone-${id}`);
		if (balloonTitle) {
			balloonTitle.removeEventListener("click", clickOnTitleListener);
		}
		if (contactButton) {
			contactButton.addEventListener("click", clickOnTitleListener);
		}
	};

	const activateListeners = () => {
		const balloonTitle = document.getElementById(`real-estate-balloon__button-${id}`);
		const contactButton = document.getElementById(`balloon__contacts-phone-${id}`);
		if (balloonTitle) {
			balloonTitle.addEventListener("click", clickOnTitleListener);
		}
		if (contactButton) {
			contactButton.addEventListener("click", clickOnContactButtonListener);
		}
	};
	useEffect(
		() => () => {
			deleteListeners();
		},
		[]
	);

	const onError = () => {
		const ERROR_TEXT = "Не удалось загрузить данные";
		toastError({ text: ERROR_TEXT });
	};

	const { realEstate: applicationRealEstate, isFetching: isFetchingApplicationRealEstate } =
		useLoadApplicationRealEstate({ id: applicationId!, onError, enabled: !!applicationId });
	const { realEstate: parsedRealEstate, isFetching: isFetchingParsedRealEstate } = useLoadParsedRealEstate({
		id,
		onError,
	});

	const realEstate = applicationId ? applicationRealEstate : parsedRealEstate;
	const isFetching = applicationId ? isFetchingApplicationRealEstate : isFetchingParsedRealEstate;

	const balloonContentBody = () => {
		if (isFetching) {
			return <Loading />;
		}
		if (realEstate) {
			setTimeout(() => {
				activateListeners();
			}, 0);

			return <Info realEstate={realEstate} />;
		}

		return <NoData />;
	};

	const renderContent = useMemo(
		() => ReactDOMServer.renderToStaticMarkup(balloonContentBody()),
		[isFetching, realEstate, id]
	);

	return {
		renderContent,
	};
};
