import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { PinContact } from "../../index";
import { UserList } from "../UserList";
import { useLoadContacts } from "../../../../service/api/queries";
import {
	useDeleteContacts,
	useSetContactFromParentLead,
	useUpdateContact,
} from "../../../../service/api/mutations";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { ApplicationCardPayload, ContactTypeMap, KindType, UserListItem, WhoIsClient } from "../../../../types";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import styles from "./index.module.scss";

type Props = ApplicationCardPayload & {
	type: WhoIsClient;
	canSetContactFromLead?: boolean;
	mini?: boolean;
	kind?: KindType,
	label?: string
	classNames?: string
};

export const Contacts = ({
	type,
	applicationId = "",
	scrollY,
	stageConditions,
	canSetContactFromLead,
	kind=KindType.APPLICATION,
	mini=false,
	label,
	classNames,
}: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { data, refetch } = useLoadContacts({ applicationId, type, kind });
	const { mutate: mutateContact, isSuccess: isSuccessContact } = useUpdateContact({kind});
	const { mutate: setContactFromLead } = useSetContactFromParentLead({ onSuccess: refetch });
	const { mutate: deleteContact } = useDeleteContacts({ onSuccess: refetch });

	const addContact = ({ id }: { id: string }) => {
		if (applicationId) {
			mutateContact({ applicationId, id, type, kind });
		}
		dispatch(closeModal());
	};

	const title = type === "seller" ? "Продавец" : "Покупатель";
	const isLast = stageConditions?.isLast ?? false;

	const users: UserListItem[] =
		data?.map(({ id, name, secondName, lastName, type: contactType, phones, phone, pseudonym, relatedKinds, preApplicationId, applicationId }) => ({
			id,
			firstName: name,
			secondName,
			lastName,
			pseudonym,
			type: ContactTypeMap[contactType],
			phones,
			phone,
			relatedKinds,
			preApplicationId,
			applicationId
		})) ?? [];

	return (
		<div style={{ marginTop: 60 }}>
			<UserList
				type={type}
				title={label ? label :title}
				applicationId={applicationId}
				users={users}
				refetch={refetch}
				handleDelete={deleteContact}
				scrollY={scrollY}
				isSuccess={isSuccessContact}
				stageConditions={stageConditions}
				withoutAvatars
				mini={mini}
				classNames={classNames}
			>
				<div className={styles.controls}>
					{canSetContactFromLead && users.length === 0 && applicationId && (
						<Button Icon={PlusIcon} variant="text" onClick={() => setContactFromLead({ applicationId })}>
							Прикрепить из лида
						</Button>
					)}
					{!stageConditions?.isNext && (
						<PinContact onPin={addContact} disabled={isLast}>
							<Button Icon={PlusIcon} variant="text" disabled={isLast}>
								Добавить контакт
							</Button>
						</PinContact>
					)}
				</div>
			</UserList>
		</div>
	);
};
