import { KindType } from "../../../../../types";
import { Tabs } from "./components";
import { RealEstateView } from "../RealEstateView";
import { memo } from "react";
import { useLoadApplicationRealEstate } from "../../../../../service/api/queries";

type Props = {
  id: string;
  blocksIds?: Record<string, string>;
  inDrawer?: boolean
  applicationTemplateId?: string
  isFromLead?: boolean
  kind?: KindType
};

export const RealEstateViewWithTabs = memo((
  {
    id,
    blocksIds,
    inDrawer,
    applicationTemplateId,
    isFromLead,
    kind
  }: Props): JSX.Element => {

  const {realEstate, isFetching, remove, refetch: refetchRealEstate} = useLoadApplicationRealEstate({
    id, kind
  });
  const {activeApplications} = realEstate || {};

  return (
    <div style={{width: "800px"}}>
      {
        activeApplications &&
        <Tabs activeApplications={activeApplications} currentApplicationId={id}/>
      }
      {realEstate &&
        <RealEstateView
          id={applicationTemplateId ?? id}
          blocksIds={blocksIds}
          realEstate={realEstate}
          refetch={refetchRealEstate}
          remove={remove}
          isFetching={isFetching}
          inDrawer={inDrawer}
          isFromLead={isFromLead}
        />
      }
    </div>
  );
});
