import { H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { PublicLayout } from "../../../../UI";
import { CompilationList, VoteBanner } from "../../components";

import styles from "./index.module.scss";

export const AgentObjectCompilation = (): JSX.Element => (
	<PublicLayout classNames={{ content: styles.root }}>
		<div>
			<H2>Подборка объектов от риэлтора</H2>

			<VoteBanner />
		</div>

		<div>
			<CompilationList />
		</div>
	</PublicLayout>
);
