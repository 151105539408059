import { useEffect } from "react";
import {
	Control,
	useForm,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormReset,
	UseFormSetValue,
	useWatch,
} from "react-hook-form";
import isEqual from "lodash.isequal";

import {
	CategoryEnum,
	RealEstateFilter,
	AddressValue,
	MultiLayerSelectOption,
} from "../../../../../../types";

type Props = {
	filter: RealEstateFilter;
};

type Return = {
	register: UseFormRegister<RealEstateFilter>;
	setValue: UseFormSetValue<RealEstateFilter>;
	control: Control<RealEstateFilter>;
	getValues: UseFormGetValues<RealEstateFilter>;
	watcherResponsibleUsers: MultiLayerSelectOption[];
	watcherFeedAdds: string[] | undefined;
	watcherFeedDateEnd: Date | undefined;
	watchCategory: CategoryEnum[] | undefined;
	watcherAddress: AddressValue | undefined;
	watchForm: RealEstateFilter;
	handleSubmit: UseFormHandleSubmit<RealEstateFilter>;
	reset: UseFormReset<RealEstateFilter>;
};

type UseFilterForm = (props: Props) => Return;

export const useFilterForm: UseFilterForm = ({ filter }) => {
	const { register, setValue, control, getValues, watch, handleSubmit, reset } = useForm<any>({
		defaultValues: filter,
	});

	const watchForm = watch();
	const [watcherResponsibleUsers, watcherFeedAdds, watcherFeedDateEnd, watchCategory, watcherAddress] = [
		useWatch({
			control,
			name: "responsibleUsers.userId",
			defaultValue: getValues("responsibleUsers.userId"),
		}),
		useWatch({
			control,
			name: "feedAds.feedId",
			defaultValue: getValues("feedAds.feedId") || [],
		}),
		useWatch({
			control,
			name: "feedAds.endDate",
			defaultValue: getValues("feedAds.endDate") || "",
		}),
		useWatch({
			control,
			name: "category",
			defaultValue: filter?.category || [],
		}),
		useWatch({
			control,
			name: "address",
			defaultValue: filter?.address || {
				label: "",
				value: "",
				location: undefined,
			},
		}),
	];

	useEffect(() => {
		if (!isEqual(watchForm, filter)) {
			reset(filter);
		}
	}, [filter]);

	return {
		register,
		setValue,
		control,
		getValues,
		watcherResponsibleUsers,
		watcherFeedAdds,
		watcherFeedDateEnd,
		watchCategory,
		watcherAddress,
		watchForm,
		handleSubmit,
		reset,
	};
};
