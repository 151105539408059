import { useForm } from "react-hook-form";
import { H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { QuizForm } from "./QuizForm";
import { HiddenWidget } from "../HiddenWidget";
import { useUpdateApplication } from "../../../../service/api/mutations";
import {
	ApplicationUpdatePayload, KindType,
	PropsAdditionalProperties,
	QuizQuestion,
	StageConditions,
} from "../../../../types";

type Props = {
	title?: string;
	questions: QuizQuestion[];
	stageConditions: StageConditions;
	additionalProperties: PropsAdditionalProperties;
	applicationId: string;
	refetch: () => void;
	kind?: KindType
};

export const Quiz = ({
	title,
	questions,
	stageConditions,
	additionalProperties,
	applicationId,
	refetch,
	kind
}: Props): JSX.Element => {
	const { control, register, getValues, setValue } = useForm<any>({
		defaultValues: { additionalProperties },
	});

	const { mutate } = useUpdateApplication({kind});

	const onChange = () => {
		const payload: ApplicationUpdatePayload = {
			applicationId,
			applicationData: { ...getValues() },
		};

		mutate(payload);
	};

	return (
		<>
			<H2>{title || ""}</H2>
			{(stageConditions ? stageConditions.isNext : false) ? (
				<HiddenWidget />
			) : (
				<QuizForm
					onChange={onChange}
					register={register}
					setValue={setValue}
					getValues={getValues}
					control={control}
					refetch={refetch}
					applicationId={applicationId}
					questions={questions}
					disabled={stageConditions.isLast}
				/>
			)}
		</>
	);
};
