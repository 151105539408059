import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { TransparentInput } from "../../../../../../../../index";
import { commonPlaceholders } from "../../../../../../../../../../utils/placeholders";

import { getMask } from "./helpers";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
	name?: string;
	placeholder?: string;
	useCleanUp?: boolean;
	onlyPositive?: boolean;
	maxLength?: number
};

export const NumberInput = ({
	form,
	field,
	name,
	placeholder,
	useCleanUp,
	onlyPositive,
	maxLength
}: Props): JSX.Element => {
	const { register, setValue, watch } = form;
	const {
		required,
		dataType: { name: defaultName, isFloat },
	} = field;
	const allowDecimal = isFloat;

	const realName = name ?? defaultName;

	if (!realName) {
		return <></>;
	}

	const mask = getMask({ onlyPositive, allowDecimal });

	const watcherValue = watch(realName);

	const cleanUp = useCleanUp ? { setValue } : undefined;

	return (
		<TransparentInput
			mask={mask}
			setValueForMask={setValue}
			withCleanUp={cleanUp}
			value={watcherValue}
			placeholder={placeholder ?? commonPlaceholders.notSpecified}
			name={realName}
			register={register}
			required={required}
			maxLenght={maxLength}
		/>
	);
};
