import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { RealEstate } from "../../../../../types";
import { Prompt } from "react-router";

import { ApplicationBusinessProcess, ObjectEditLoading, RealEstateForm } from "../../../../UI";
import { useOnSubmit } from "./helpers";
import { cleanUp, setRoomsAmount } from "../../../../../service/redux/modules/object-edit/object-edit.action";
import { useLoadConfig } from "../../../../../service/api/queries";
import { useLeaveAlertModal, useQueryParams } from "../../../../../utils/hooks";
import { useGetNavigation } from "../../helpers";
import styles from "./index.module.scss";
import { ReactComponent as Edit } from "../../../../../assets/icons/edit-page.svg";

type Props = {
  id: string;
  realEstate: RealEstate;
  isFetching: boolean;
  refetch: () => void;
  remove: () => void;
  options?: {
    disableMap?: boolean;
    notFullHeight?: boolean;
  };
};

export const RealEstateEdit = (
  {
    id,
    options = {},
    realEstate,
    isFetching,
    refetch,
    remove,
  }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const {disableMap} = options;
  const {
    queryParams: {mode},
    changeQueryParams,
  } = useQueryParams<{ mode: string }>([{name: "mode"}]);

  const handleChangeTab = (newTab: string) => changeQueryParams([{name: "mode", newValue: newTab}]);
  const {setNavigationY} = useGetNavigation({})

  useEffect(() => {
    setNavigationY(prevState => [
      ...prevState,
      {
        id: "edit",
        label: <Edit/>,
        changeTab: () => handleChangeTab("edit"),
        height: 90,
      },
    ]);

  }, [setNavigationY]);

  const {
    objectConfig,
    isFetching: isConfigLoading,
    currentCategory,
    setCategory,
    removeData: removeConfig,
  } = useLoadConfig({});
  const {openLeaveAlertModal} = useLeaveAlertModal();

  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState,
    clearErrors,
    setError,
  } = useForm<any>({
    defaultValues: realEstate,
  });

  const {isSubmitSuccessful} = formState;

  useEffect(() => {
    if (realEstate) {
      setCategory({
        category: realEstate.category,
        businessProcessId: realEstate.type.id,
      });

      if (Object.keys(watch()).length === 0) {
        dispatch(setRoomsAmount(realEstate?.rooms || ""));
        reset(realEstate);
      }
    }
  }, [realEstate]);

  const form = {
    control,
    register,
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
    formState,
    reset,
    handleSubmit,
  };

  const {onSubmit, isUpdateLoading} = useOnSubmit({
    refetchRealEstate: refetch,
    setError,
    clearErrors,
    applicationId: id,
    disableMap,
  });

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      remove();
      removeConfig();
      dispatch(cleanUp());
    };
  }, []);

  useEffect(() => {
    clearErrors();
  }, [currentCategory]);

  if ((isFetching || isConfigLoading) && !realEstate) {
    return <ObjectEditLoading/>;
  }

  return (
    <div className={styles.root}>

      <h2>Редактировать объект</h2>
      <ApplicationBusinessProcess form={form}/>
      <RealEstateForm
        id={id}
        form={form}
        isMutationLoading={isUpdateLoading}
        objectConfig={objectConfig}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isNewMod
      />
    </div>
  );
};
