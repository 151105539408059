import { MultiLayerSelectOption } from "../../../types";
import { prepareShortUserOutput } from "../prepareShortUserOutput";

import styles from "./index.module.scss";

const Position = ({ userName, positionLabel }: { userName: string; positionLabel?: string }) => (
	<>
		{userName}

		{positionLabel ? <span className={styles.position}>{`(${positionLabel})`}</span> : null}
	</>
);

export const prepareOffices = (
	offices: Array<any>,
	getPositionName?: (id: string) => string | undefined
): MultiLayerSelectOption[] => {
	const layers: MultiLayerSelectOption[] = [];
	offices.forEach((office) => {
		layers.push({
			label: { name: office.name, children: office.name },
			value: office.id,
			parents: ["", ""],
			layer: 0,
		});

		office.departments.forEach((department) => {
			layers.push({
				label: { name: department.name, children: department.name },
				value: department.id,
				parents: ["", office.id],
				layer: 1,
			});

			department.users.forEach((user) => {
				const { account } = user;

				const prepared = prepareShortUserOutput(account);
				let position = "";

				if (getPositionName) {
					position = getPositionName(user.positionId) || "";
				}

				layers.push({
					label: {
						name: prepared,
						children: <Position userName={prepared} positionLabel={position} />,
					},
					value: user.id,
					parents: [office.id, department.id],
					layer: 2,
				});
			});
		});
	});

	return layers;
};
