import { RealEstate, PreCreateObjectPayload } from "../../../../../../types";
import { useEffect } from "react";

import { RealEstateForm, ObjectEditLoading } from "../../../../index";
import { useLoadCreateConfig, useCreateObjectForm } from "../../../../../../utils/hooks";
import { useOnSubmit } from "./helpers";

import styles from "./index.module.scss";

type Props = {
	onSubmit: (data: RealEstate) => void;
	defaultValues: PreCreateObjectPayload;
	applicationId: string;
};

export const CreateObject = ({ applicationId, onSubmit, defaultValues }: Props): JSX.Element => {
	const {
		category,
		type: { id: businessProcessId },
	} = defaultValues;
	const defaultCategory = {
		category,
		businessProcessId,
	};

	const { objectConfig, isConfigLoading, currentCategory } = useLoadCreateConfig({ defaultCategory });
	const { form, handleSubmit } = useCreateObjectForm({ defaultValues });

	const { clearErrors, setError } = form;

	useEffect(() => {
		clearErrors();
	}, [currentCategory]);

	const { onSubmit: handle, isCreateLoading } = useOnSubmit({
		applicationId,
		setError,
		clearErrors,
		onObjectCreate: onSubmit,
	});

	if (isConfigLoading) {
		return <ObjectEditLoading />;
	}

	return (
		<div className={styles.root}>
			<RealEstateForm
				id="new"
				form={form}
				isMutationLoading={isCreateLoading}
				objectConfig={objectConfig}
				onSubmit={handle}
				handleSubmit={handleSubmit}
				isNewMod
			/>
		</div>
	);
};
