import { useDispatch } from "react-redux";
import { Lead } from "../../../../../../types";
import { closeModal, openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss"
import { useJoinToSdelkaPro } from "service/api/mutations/auth/useJoinToSdelkaPro";
import { closeDrawer } from "service/redux/modules/drawer/drawer.action";
import { initialize, joinToSdelkaPro2, useFinishRegistration } from "service/redux/modules/auth/auth.thunk";
import { TokenService } from "service/api/services/inner/TokenService";
import { ReactComponent as CheckMarkSuccess } from "../../../../../../assets/icons/CheckMarkSuccess.svg";

export const Join =() => {
  
	const {asyncMutate} = useJoinToSdelkaPro()
  const dispatch = useDispatch()

  const onApply = async () => {
    
    try {
      const response = await asyncMutate()
      if (response) {
        TokenService.setCurrentToken(response.data[0].token)
        await dispatch(useFinishRegistration());
        dispatch(initialize())
        dispatch(closeModal());
        dispatch(closeDrawer());
      }
      
    } catch (error) {
      console.error("Error during mutation:", error);
    }

  };

  return (

    <div className={styles.wrap}>
    <div className={styles.block}>
      <CheckMarkSuccess />
      <div className={styles.text}>
        <h1>
          Поздравляем!
        </h1>
        <p>
          Вы присоединились к сделке ПРО
        </p>
      </div>
    </div>
    <Button onClick={onApply} classNames={{root: styles.button}}>
        Начать работу
    </Button>

  </div>
  )

}