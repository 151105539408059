import { ReactNode, useEffect } from "react";
import cn from "classnames";
import { Button, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { RadioButton } from "../../../../../../../../UI";
import { RequiredFields } from "./components";
import styles from "./index.module.scss";
import { ErrorsAndWarnings, PaymentMethods, RequiredFieldsType } from "../../../../../../../../../types";
import { StartAdErrors } from "./components/StartAdError";

type Props = {
	format: string;
	control: any;
	disabled?: boolean;
	requiredFields: RequiredFieldsType;
	onFillFields: () => void;
	onStartAdClick: () => void;
	onChangeTariff: (value: number | string) => void;
	// FIXME: types
	watch: any;
	setValue: any;
	isWaiting?: boolean;
	isError?: boolean;
	messages: ErrorsAndWarnings;
	isPostedAndWaitingForStop?: boolean;
};

const startAdOptions = (format: string) => {
	switch (format) {
		case "cian":
			return [
				{ value: 1, label: "Посуточно" },
				{ value: 2, label: "14 дней" },
				{ value: 3, label: "30 дней" },
			];
		default:
			return [
				{ value: 1, label: "1 месяц" },
				{ value: 2, label: "2 месяца" },
				{ value: 3, label: "3 месяца" },
			];
	}
};

const StartAdTariffLabel = ({ children }: { children: ReactNode }): JSX.Element => (
	<div className={styles.tariffOption}>
		<Tooltip>{children}</Tooltip>
		<span>{children}</span>
	</div>
);

const paymentOptions = [
	{
		value: 1,
		label: <StartAdTariffLabel>Пакетное размещение</StartAdTariffLabel>,
	},
	{
		value: 2,
		label: <StartAdTariffLabel>Разовое размещение</StartAdTariffLabel>,
	},
];

const startAdTariffOptions = [
	{ value: 1, label: <StartAdTariffLabel>Стандарт</StartAdTariffLabel> },
	{ value: 2, label: <StartAdTariffLabel>Premium</StartAdTariffLabel> },
	{ value: 3, label: <StartAdTariffLabel>Top</StartAdTariffLabel> },
];

export const StartAd = ({
	format,
	control,
	requiredFields,
	onStartAdClick,
	onFillFields,
	watch,
	disabled = false,
	setValue,
	isWaiting,
	isError,
	messages,
	onChangeTariff,
	isPostedAndWaitingForStop,
}: Props): JSX.Element => {
	const paymentOption = watch("startAdPayment", false);

	useEffect(() => {
		if (paymentOption === 2) {
			setValue("startAdDuration", 1);
		}
	}, [paymentOption, control, setValue]);

	return (
		<div className={styles.root}>
			{isError && <StartAdErrors messages={messages} />}
			<div className={styles.paymentOptions}>
				<div className={styles.fixedWidthTitle}>Способ оплаты:</div>
				<div>
					<RadioButton
						separate
						className={cn(styles.radioButton, styles[format])}
						name="startAdPayment"
						options={paymentOptions}
						control={control}
						disabled={isWaiting || isError}
						disabledClassName={styles.paymentOptionsDisabled}
					/>
				</div>
			</div>
			<div className={styles.options}>
				<div className={styles.fixedWidthTitle}>Сроки рекламы:</div>

				<div className={styles.radio}>
					<RadioButton
						className={styles.radioButton}
						name="startAdDuration"
						options={
							Object.values(PaymentMethods)[paymentOption - 1] === PaymentMethods.SINGLE
								? [startAdOptions(format)[0]]
								: startAdOptions(format)
						}
						control={control}
						disabled={isWaiting || isError}
						disabledClassName={styles.startAdDuration}
					/>
				</div>
			</div>

			{format === "cian" ? (
				<div className={styles.options}>
					<div className={styles.fixedWidthTitle}>Выбрать тариф:</div>

					<div className={styles.radio}>
						<RadioButton
							separate
							className={cn(styles.radioButton, styles[format])}
							name="startAdTariff"
							options={startAdTariffOptions}
							control={control}
							handleOnChange={onChangeTariff}
							disabled={isPostedAndWaitingForStop}
						/>
					</div>
				</div>
			) : (
				""
			)}

			{!isWaiting && !isError && (
				<div className={cn(styles.startAd, styles[format])}>
					<Button disabled={disabled} onClick={onStartAdClick}>
						Начать рекламу
					</Button>

					{!requiredFields.valid && (
						<RequiredFields requiredFields={requiredFields} onFillFields={onFillFields} />
					)}
				</div>
			)}
		</div>
	);
};
