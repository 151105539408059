import { useMemo } from "react";
import { Column, useSortBy, useTable, useColumnOrder } from "react-table";
import cn from "classnames";

import { ColumnsSetting } from "./ColumnsSetting";

import { ReactComponent as ArrowIcon } from "../../../../assets/icons/arrow.svg";
import styles from "./index.module.scss";

type Props = {
	columns: Column[];
	data: any;
	isFirstColumnFixed: boolean;
	heighBodyRow?: number;
};

export const TableCustomizable = ({
	columns,
	data,
	isFirstColumnFixed,
	heighBodyRow = 76,
}: Props): JSX.Element => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		allColumns,
		rows,
		prepareRow,
		setSortBy,
		setColumnOrder,
	} = useTable({ data, columns }, useSortBy, useColumnOrder);

	const heighRow = useMemo(() => data.length * heighBodyRow, [data.length]);

	const getFieldNameToSort = (header: string) => {
		switch (header) {
			case "account.lastName":
				return "account.lastName";
			case "position.name":
				return "position.name";
			case "companyId":
				return "companyId";
			case "account.phone":
				return "account.phone";
			case "lastActionDate":
				return "lastActionDate";
			default:
				return "id";
		}
	};

	return (
		<>
			<ColumnsSetting data={data} allColumns={allColumns} changeColumnOrder={setColumnOrder} />

			<div className={styles.wrapper}>
				<table
					className={cn(styles.table, {
						[styles.fixedFirstColumn]: isFirstColumnFixed,
					})}
					{...getTableProps()}
				>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th
										className={styles.headerTh}
										{...column.getHeaderProps()}
										onClick={() => {
											if (column.sortable) {
												const desc = !column.isSortedDesc;
												setSortBy([{ id: getFieldNameToSort(column.id), desc }]);
											}
										}}
									>
										<div className={styles.headerThContent}>
											{column.render("Header")}
											<span>
												{column.sortable ? (
													<ArrowIcon
														className={cn(styles.arrowIcon, {
															[styles.arrowIconAsc]: column.isSorted,
															[styles.arrowIconDesc]: column.isSortedDesc,
														})}
													/>
												) : (
													""
												)}
											</span>
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()} style={{ height: `${heighRow}px` }}>
						{rows.map((row) => {
							prepareRow(row);

							return (
								<tr className={styles.row} {...row.getRowProps()}>
									{row.cells.map((cell) => (
										<td className={styles.td} {...cell.getCellProps()}>
											<div className={styles.tdContent}>
												{cell.column.customTemplate ? cell.column.customTemplate(cell) : cell.render("Cell")}
											</div>
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};
