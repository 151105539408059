import { useDispatch } from "react-redux";

import { toastError } from "../../../../UI";
import { closeModal, openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { usePositionDelete } from "../../../../../service/api/mutations";

type UseDeletePositionModal = {
	refetch: () => void;
};

const DELETE_POSITION_ERROR_TEXT =
	"Невозможно удалить должность, т.к. имеются активные пользователи с этой должностью!";

export const useDeletePositionModal = ({ refetch }: UseDeletePositionModal) => {
	const dispatch = useDispatch();

	const { mutate } = usePositionDelete({
		onError: () => {
			dispatch(closeModal());
			toastError({
				text: DELETE_POSITION_ERROR_TEXT,
			});
		},
		onSuccess: () => {
			refetch();
			dispatch(closeModal());
		},
	});

	const showConfirmModal = (positionId: string) => {
		dispatch(
			openModal({
				title: "Вы уверены, что хотите удалить должность?",
				body: "",
				variant: "alert",
				alertIcon: "warning",
				alertButtonSubmit: {
					label: "Удалить",
					onClick: () => mutate({ id: positionId }),
				},
				alertButtonDecline: {
					label: "Отменить",
					onClick: () => dispatch(closeModal()),
				},
			})
		);
	};

	return { showConfirmModal };
};
