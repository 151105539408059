import { Information } from "../../../../../../../../../../../../../UI";
import { useAdditionalText } from "./helpers";
import { FindContactByPhoneResponseRelations } from "../../../../../../../../../../../../../../types";

type Props = {
	relations: FindContactByPhoneResponseRelations;
};

export const RelationsInfo = ({ relations }: Props): JSX.Element => {
	const { additionalText } = useAdditionalText(relations);

	return (
		<div>
			<Information>
				<div>
					Внимание, данный контакт уже используется как Основной.
					<br />
					{additionalText}
				</div>
			</Information>
		</div>
	);
};
