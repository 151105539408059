import { ReactNode } from "react";

import styles from "./index.module.scss";

type Props = {
	stageNum: number;
	color: string;
	children: ReactNode;
};

export const StageTitle = ({ stageNum, color, children }: Props): JSX.Element => (
	<div className={styles.root}>
		<div style={{ borderColor: color }} className={styles.stage}>
			Этап {stageNum}
		</div>
		<div className={styles.title}>
			<div>{children}</div>
		</div>
	</div>
);
