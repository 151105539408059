import { Office, ProductTypeEnum, User, UserStatusEnum, userStatuses } from "../../../../../types";
import styles from "./index.module.scss";
import { Table } from "../../../../UI";
import { useBlinkRow, useQueryParams } from "../../../../../utils/hooks";
import { useColumns } from "./columns";
import { useCallback } from "react";
import { useAddInCartModal } from "../../cart/helpers/addInCartModal/useAddInCartModal";
import { ReactComponent as Cart } from "../../../../../assets/icons/Shopping_Cart.svg";
import { useOpenProfileCard } from "../../../PersonalCard/helpers";
import { useOpenOfficeCard } from "../../../office-card/helpers";
import { ProductTile, servicesCategory } from "../../../../../types/product";
import { useOpenProductCard } from "../../../product-card";

type Props = {
  productsList: ProductTile[];
  isLoading: boolean;
  refetch: () => void;
};

export const ProductsTable = (
  {
    productsList,
    isLoading,
    refetch
  }: Props): JSX.Element => {
  const {queryParams, changeQueryParams, removeQueryParam} = useQueryParams<{
    userId: string;
  }>([{name: "productId"}]);
  const columns = useColumns(refetch);
  const {rowsToBlink, callback} = useBlinkRow();

  const {openProductDrawer} = useOpenProductCard({
    onClose: () => removeQueryParam(["productId"]),
    refetch: refetch,
  });

  const renderTitle: (item:  string) => JSX.Element = useCallback(
    (category: string) => {
      // const ids = usersList.filter(elem => elem.status === status).map(elem => elem.id)

      return (
        <div className={styles.row}>
          <div className={styles.title}>
            {servicesCategory[category]}
          </div>
        </div>
      )
    },
    []
  );

  const handleRowClick = (row: ProductTile) => {
    changeQueryParams([
      {name: "productId", newValue: row.id},
    ]);
    openProductDrawer(row.id)
  };

  return (
    <div className={styles.wrapper}>
      {productsList?.length > 0 || isLoading ? (
        <Table<ProductTile>
          idKey="id"
          data={productsList}
          columns={columns}
          groupKey="category"
          rowClass={styles.row}
          transformGroupTitle={renderTitle}
          onRowClick={handleRowClick}
          loading={{isLoading, rowsCount: 5}}
          activeRow={{
            accessor: "",
            activeId: 1,
          }}
          blinkRows={rowsToBlink}
        />
      ) : (
        <div className={styles.empty}>Похожих продуктов не найдено</div>
      )}
    </div>
  )
}