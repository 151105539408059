import { useForm } from "react-hook-form";
import { PreCreateObjectPayload } from "../../../types";

type Props = {
	defaultValues: PreCreateObjectPayload;
};

export const useCreateObjectForm = ({ defaultValues }: Props) => {
	const { control, register, setValue, handleSubmit, watch, getValues, formState, clearErrors, setError } =
		useForm({
			defaultValues,
		});

	const form = {
		control,
		register,
		setValue,
		getValues,
		watch,
		clearErrors,
		setError,
		formState,
	};

	return {
		form,
		handleSubmit,
	};
};
