import { useLoadImg } from "../../../service/api/mutations";
import { getImageWidthHeight } from "../../file";

export const useLoadPhotos = () => {
	const { mutateAsync } = useLoadImg({});

	const loadPhotos = async (files: Array<File & { preview: string; photoType?: "photo" | "plan" }>) => {
		const sizes = await Promise.all(files.map((file) => getImageWidthHeight(file)));

		const imgUrls = await Promise.all(
			files.map((file, index) => {
				const formData = new FormData();

				formData.append("file", file);

				const payload = {
					file: formData,
					params: {
						photoType: file.photoType,
						width: sizes[index].width,
						height: sizes[index].height,
					},
				};

				return mutateAsync(payload);
			})
		);

		return imgUrls.map((img, index) => ({
			photoOriginal: img.data.photoOriginal.url,
			photo1280: img.data.photo1280.url,
			photo1280Watermark: img.data.photo1280Watermark.url,
			photo96x96: img.data.photo96x96.url,
			order: index + 1,
			description: "",
			width: img.data.width,
			height: img.data.height,
		}));
	};

	return { loadPhotos };
};
