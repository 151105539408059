import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { ChangeLeadStatusPayload } from "../../../../types";

import { toastError } from "../../../../components/UI";
import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";

const DEFAULT_MN = "sdelka/lead/change-stage/DEFAULT_MN";

type UseChangeStatus = {
	onSuccess?: (data?: any) => void;
};

export const useChangeStatus = ({ onSuccess }: UseChangeStatus) => {
	const onError = (error: AxiosError) => {
		const message = axiosError({ error });

		if (message === "Can't finish lead without applications!") {
			toastError({ text: "Нельзя завершить лид без заявки" });
		}
	};

	return useMutation(DEFAULT_MN, {
		mutationFn: ({ id, payload }: { id: string; payload: ChangeLeadStatusPayload }) =>
			api.leads.changeStatus(id, payload),
		onSuccess,
		onError,
	});
};
