import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { AddToDepartmentModal } from "./AddToDepartmentModal";

export const useAddToDepartmentModal = (
  ) => {
  const dispatch = useDispatch()

  const handleAddToDepartmentModal = (  departmentId: string) => {
    dispatch(
      openModal({
        body: <>
          <AddToDepartmentModal departmentId={departmentId}/>
        </>,
        width: 660,
      })
    )
  }
  return {handleAddToDepartmentModal}
}