import { UseMutateFunction, useMutation, MutationFunction, UseMutationOptions } from "react-query";
import { AxiosResponse } from "axios";
import { Application, ApplicationUpdatePayload, KindType } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/application/update-application/DEFAULT_MN";

type Props = {
  onSuccess?: (data: AxiosResponse<Application>) => void;
  kind?: KindType
};

type Return = {
  mutate: UseMutateFunction<AxiosResponse<Application>, unknown, ApplicationUpdatePayload, unknown>;
  isLoading: boolean;
  isSuccess: boolean;
};

type UseUpdateApplication = (props: Props) => Return;

export const useUpdateApplication: UseUpdateApplication = ({ onSuccess, kind }) => {
	let mutation;

	if (kind === KindType.APPLICATION) {
		 mutation = useMutation<AxiosResponse<Application>, unknown, ApplicationUpdatePayload, unknown>(
			DEFAULT_MN,
			{
				mutationFn: (variables: ApplicationUpdatePayload) => api.application.updateInfo(variables),
				onSuccess,
			}
		);
	}
	if (kind === KindType.PREAPPLICATION) {
		 mutation = useMutation<AxiosResponse<Application>, unknown, ApplicationUpdatePayload, unknown>(
			DEFAULT_MN,
			{
				mutationFn: (variables: ApplicationUpdatePayload) => api.PreApplicationService.updateInfo(variables),
				onSuccess,
			}
		);
	}
	if (kind === KindType.DEAL) {
		mutation = useMutation<AxiosResponse<Application>, unknown, ApplicationUpdatePayload, unknown>(
		 DEFAULT_MN,
		 {
			 mutationFn: (variables: ApplicationUpdatePayload) => api.deals.updateInfo(variables),
			 onSuccess,
		 }
	 );
 }
  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
  };
};
