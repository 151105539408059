import { ReactNode } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	classNames?: {
		wrapper?: string;
	};
};

export const Tag = ({ children, classNames }: Props): JSX.Element => (
	<span className={cn(styles.wrapper, classNames?.wrapper)}>{children}</span>
);
