import { MouseEvent, useState } from "react";
import { CollectionItem, RealEstate } from "../../../../types";
import cn from "classnames";
import styles from "./index.module.scss";

import { formatThousand } from "../../../../utils/formatters";
import { CardObjectStage } from "../CardObject/components/stage";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { ImageIcons } from "./image-icons/ImageIcons";
import { ReactComponent as BuildingsIcon } from "../../../../assets/icons/buildings.svg";
import { ReactComponent as DescriptionIcon } from "../../../../assets/icons/chatIcon.svg";
import { ReactComponent as IconClientComment } from "../../../../assets/icons/iconClientComment.svg";

type Props = {
  id: string;
  objectData: RealEstate;
  onCardClick?: (id: string) => void;
  onAuxClick?: (id: string) => void;
  classNames?: {
    content?: string;
    img?: string;
    root?: string
  };
  withPrice?: boolean;
  collectionItem?: CollectionItem
  isHovered?: boolean
  canOpenANewTab?: boolean
};

export const CardObjectMini = (
  {
    id,
    objectData,
    classNames,
    onCardClick,
    onAuxClick,
    withPrice,
    collectionItem,
    isHovered,
    canOpenANewTab
  }: Props): JSX.Element => {
  const {
    actual,
    photos,
    addressLabel,
    price,
    title,
  } = objectData;
  const isNotActual = actual ? actual !== "Актуальный" : false;

  const handleCardClick = () => {
    if (onCardClick) {
      onCardClick(id);
    }
  };

  const handleAuxClick = () => {
    if (onAuxClick) {
      onAuxClick(id);
    }
  };

  const mouseDownHandler = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    switch (event.button) {
      case 1:
        return handleAuxClick();
      case 2:
        return "";
      case 0:
      default:
        return handleCardClick();
    }
  };

  const [isImageError, setIsImageError] = useState(false);
  const onImageError = () => setIsImageError(true);

  return (
    <div className={cn(styles.root, classNames?.root)} onClick={mouseDownHandler} onAuxClick={mouseDownHandler}>

      <div
        className={cn(styles.img, classNames?.img, {
          [ styles.largerImg ]: withPrice,
          [ styles.noImg ]: !photos?.[ 0 ]?.photo1280 || isImageError,
        })}
      >
        {photos && photos.length && !isImageError ? (
          <img onError={onImageError} alt="object" src={photos[ 0 ].photo1280}/>
        ) : (
          <BuildingsIcon/>
        )}
        {collectionItem?.clientComment && <Tooltip
            classNames={{
              root: styles.clientComment,
              card: cn(styles.tooltipCard, styles.tooltipComment),
            }}
            iconSize="big"
            CustomIcon={IconClientComment}
        >
          {collectionItem?.clientComment}
        </Tooltip>}
        {collectionItem?.collectionStatus && <ImageIcons collectionItem={collectionItem}/>}
      </div>
      <div
        className={cn(styles.content, {
          [ styles.smallerContent ]: withPrice,
        })}
      >
        <div className={!isHovered ? styles.hide : ""}>
          <div className={cn(styles.flexRow)}>
            <div className={styles.flexRow}>
              <div className={cn(styles.title, {[ styles.lineThrough ]: isNotActual})}>{title}</div>
              {objectData.description &&
                  <Tooltip
                      classNames={{
                        root: styles.tooltipDescription,
                        card: cn(styles.tooltipCard, styles.tooltipMini),
                      }}
                      iconSize="big"
                      CustomIcon={DescriptionIcon}
                  >
                    {objectData.description}
                  </Tooltip>}
            </div>
            {withPrice && <div className={styles.price}>{`${formatThousand(price)} ₽`}</div>}
          </div>
          <div className={styles.address}>{addressLabel}</div>
        </div>
      </div>
      <CardObjectStage
        object={objectData}
        isCompilation
        classNames={cn(styles.stageInSample, !isHovered ? styles.hide : "")}
        canOpenANewTab={canOpenANewTab}
        isMini
      />
    </div>
  );
};
