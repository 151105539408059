import cn from "classnames";

import { HighlightText, IndividualCheckbox, RoundInput } from "../../../../index";
import { OptionsListWrapper } from "../OptionsListWrapper";
import { DefaultSelectProps, SelectOption } from "../../../../../../types";

import selectStyles from "../selectStyles.module.scss";

type Props = {
	isListOpen: boolean;
	options: SelectOption[];
	handleOptionClick: (value: string) => void;
	checkIfSelected: (value: string) => boolean;
	searchHighLight?: string;
	withIndividualCheckbox?: {
		checkedCheck: (value: string) => boolean;
	};
	withRoundInput?: {
		onSearch: (value: string) => void;
	};
} & Pick<DefaultSelectProps, "classNames" | "size">;

export const OptionsList = ({
	isListOpen,
	options,
	handleOptionClick,
	checkIfSelected,
	searchHighLight,
	withIndividualCheckbox,
	withRoundInput,
	classNames,
	size,
}: Props): JSX.Element => (
	<OptionsListWrapper
		isListOpen={isListOpen}
		classNames={classNames}
		size={size}
		thereIsOptions={!!options.filter(opt => opt.label !== "" && opt.label !== undefined).length}
		searchChildren={
			!!withRoundInput && (
				<RoundInput
					className={selectStyles.searchInput}
					name="search"
					onChange={withRoundInput.onSearch}
					placeholder="Поиск"
					append="search"
				/>
			)
		}
	>
		<div className={selectStyles.optionsOverflow}>
			{options.map((item, index) => (
				<div
					// eslint-disable-next-line react/no-array-index-key
					key={`${item.value}-${index}`}
					onClick={() => handleOptionClick(item.value.toString())}
					className={cn(selectStyles.customOptionNotSelected, classNames?.optionNotSelected, {
						[selectStyles.customOptionSelected]: checkIfSelected(item?.value?.toString()),
					})}
				>
					{withIndividualCheckbox && (
						<IndividualCheckbox
							readOnly
							className={selectStyles.optionCheckbox}
							checked={withIndividualCheckbox.checkedCheck(item.value)}
						/>
					)}

					<HighlightText
						allText={item.label}
						textToHighlight={searchHighLight}
						classNames={{ highlight: selectStyles.highlightSearch }}
					/>
				</div>
			))}
		</div>
	</OptionsListWrapper>
);
