import { RealEstate } from "../../../../../../types";
import { ObjectsGroupHeader } from "../../../components";
import { useSelector } from "../../../../../../service/redux/useSelector";
import { usePreparedObjects } from "./helpers";

import styles from "./index.module.scss";

type Props = {
  realEstatesList: RealEstate[];
  isParser: boolean;
  isLoading: boolean;
  refetchList: () => void
};

export const ObjectsListRegular = ({realEstatesList, isLoading, isParser, refetchList}: Props): JSX.Element => {
  const {objectsGroupIds} = useSelector((state) => state.objectList);

  const preparedObjects = usePreparedObjects({
    objectsList: realEstatesList,
    isParser,
    refetchList
  });

  return (
    <>
      {objectsGroupIds.length ? (
        <>
          <ObjectsGroupHeader/>
          {preparedObjects
            .filter((objectItem) => objectsGroupIds.includes(objectItem.value))
            .map((objectItem) => (
              <div key={objectItem.value + objectItem.children} className={styles.objectsGroupItem}>

              </div>
            ))}
        </>
      ) : (
        <>
          {isLoading ? (
            Array.from(Array(10).keys()).map((item, index) => <div key={item + index} className={styles.skeleton}/>)
          ) : (
            <div className={styles.list}>
              {preparedObjects.map((object, index) => (
                <div key={object.value + index}>{object.children}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
