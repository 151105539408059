import { Control, UseFormGetValues, useWatch } from "react-hook-form";

import { MultiLayerSelectOption } from "../../../../../../types";

type Props = {
	control: Control<any>;
	getValues: UseFormGetValues<any>;
};

type Return = {
	watcherTypeId: string[];
	watcherResponsibleUsers: MultiLayerSelectOption[];
};

type UseWatchers = (props: Props) => Return;

export const useWatchers: UseWatchers = ({ control, getValues }) => {
	const TYPE_ID = "typeId";
	const RESP_USERS = "responsibleUsers";

	const [watcherTypeId, watcherResponsibleUsers] = [
		useWatch({
			control,
			name: TYPE_ID,
			defaultValue: getValues(TYPE_ID),
		}),
		useWatch({
			control,
			name: RESP_USERS,
			defaultValue: getValues(RESP_USERS),
		}),
	];

	return {
		watcherTypeId,
		watcherResponsibleUsers,
	};
};
