import { getPublicHost } from "./getPublicHost";

type Feature = "popup" | "noopener" | "noreferrer";

type Props = {
  url: string;
  target?: "_self" | "_blank" | "_parent" | "_top";
  features?: Feature[];
};

export const openPublicPage = ({url, target, features}: Props) => {
  const host = getPublicHost();
  const {protocol} = window.location;
  const base = `${protocol}//${host}`;

  const fullUrl = new URL(url, base);

  const featuresString = features?.join(" ");

  return window.open(fullUrl, target, featuresString);
};
