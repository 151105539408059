import { useState } from "react";
import { useDispatch } from "react-redux";
import { LoadByScroll } from "@sdelka_crm/sdelka-crm-component-library";
import { CardTaskList, TabsRadio } from "../../index";
import { HiddenWidget } from "../HiddenWidget";
import { useLoadTasks } from "../../../../service/api/queries";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { useCreateTaskModal } from "../../../../utils/hooks";
import {
  ApplicationCardPayload,
  DefaultTasksFiltersType,
  KindType,
  TaskCountInfo,
  TaskStatusVariant,
} from "../../../../types";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-blue-small.svg";
import { ReactComponent as OpenInNew } from "../../../../assets/icons/open_in_new_active.svg";
import styles from "./index.module.scss";
import cn from "classnames";

type Props = ApplicationCardPayload;

const activeStages: TaskStatusVariant[] = ["new", "inWork", "done", "failed"];

const getOptions = (countData?: TaskCountInfo, kind?: KindType) => {
  if (kind === KindType.PREAPPLICATION) {
    return [
      {label: "Взаимодействие", count: countData?.communications || 0},
      {label: "Обычные", count: countData?.defaults || 0},
      {label: "По показам", count: countData?.showOrViews || 0},
    ]
  } else {
    return  [
      {label: "Обычные", count: countData?.defaults || 0},
      {label: "Взаимодействие", count: countData?.communications || 0},
      {label: "По показам", count: countData?.showOrViews || 0},
    ]
  }
};

export const TasksToCompleteInApplication = (
  {
    scrollY,
    applicationId = "",
    stageConditions,
    kind
  }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(false);

  const defaultFilter: DefaultTasksFiltersType = kind === KindType.APPLICATION
    ? {
      taskType: ["defaults"],
      variant: activeStages,
      connectedApplicationId: applicationId
    } : {
      taskType: ["communications"],
      variant: activeStages,
      connectedPreApplicationId: applicationId
    };

  const getFilter = (taskType: string) => {
    if (kind === KindType.APPLICATION) {
      return {
        taskType: [taskType],
        variant: activeStages,
        connectedApplicationId: applicationId
      }
    } else {
      return {
        taskType: [taskType],
        variant: activeStages,
        connectedPreApplicationId: applicationId
      }
    }
  }

  const {
    data = [],
    tabsCount,
    setFilter,
    refetch: refetchTask,
  } = useLoadTasks({
    defaultFilter,
    defaultStage: ["new", "inWork", "done", "failed"],
    enabled,
    take: 5
  });

  const refetch = () => {
    refetchTask();
  };

  const onTabChange = (idx: number) => {
    if (kind === KindType.PREAPPLICATION) {
      if (idx === 0) {
        setFilter(getFilter("communications"))
      }
      if (idx === 2) {
        setFilter(getFilter("showOrViews"))
      }
      if (idx === 1) {
        setFilter(getFilter("defaults"))
      }
    } else {
      if (idx === 1) {
        setFilter(getFilter("communications"))
      }
      if (idx === 2) {
        setFilter(getFilter("showOrViews"))
      }
      if (idx === 0) {
        setFilter(getFilter("defaults"))
      }
    }

  };

  const handleSeeAll = () => {
    if (kind === KindType.APPLICATION) {
      window.open(`/tasks?applicationId=${applicationId}`)
    }
    if (kind === KindType.PREAPPLICATION) {
      window.open(`/tasks?preApplicationId=${applicationId}`)
    }
  }

  const {openCreateTaskModal} = useCreateTaskModal({
    applicationId,
    onCreate: () => {
      dispatch(closeModal());
      refetch();
    },
    kind: kind ?? KindType.APPLICATION
  });

  return (
    <div className={styles.root}>
      <div className={styles.rowBetween}>
        <div className={styles.title}>
          Задачи
        </div>
        <div className={styles.row}>
          <div className={cn(styles.row, styles.link)}
               onClick={openCreateTaskModal}
          >
            <PlusIcon/>
            <div>
              Создать задачу
            </div>
          </div>
          <div className={cn(styles.row, styles.link)}
               onClick={handleSeeAll}
               style={{marginLeft: "12px"}}
          >
            <OpenInNew/>
            <div>
              Смотреть все
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.menu}>
          <TabsRadio initialTab={0} titles={getOptions(tabsCount, kind)} onTabChange={onTabChange}>
            <div/>
            <div/>
          </TabsRadio>
        </div>
        {(stageConditions ? stageConditions.isNext : false) ? (
          <HiddenWidget/>
        ) : (
          <LoadByScroll height={250} scrollY={scrollY} onInView={() => setEnabled(true)} isLoading={!enabled}>
            {data?.length ? (
              <CardTaskList underlay tasks={data || []} refetch={refetch}/>
            ) : (
              <div className={styles.noTasks}>Задачи на выполнение отсутствуют</div>
            )}
          </LoadByScroll>
        )}
      </div>
    </div>
  );
};
