import { ShowsViews, ShowsViewsStatus, WhoIsClient } from "../../../../../types";

import {
	useCancelShowOrView,
	useFinishShowOrView,
	useNewDate,
	useUpdateShowOrView,
} from "../../../../../service/api/mutations";

type Args = {
	item: ShowsViews;
	type: WhoIsClient;
	setCurrentItem: (newItem: ShowsViews) => void;
	onSuccess: () => void;
	form: any;
};

export const useCardStatusHandler = ({
	item,
	setCurrentItem,
	type,
	onSuccess,
	form: { getValues, setValue, watchForm },
}: Args) => {
	const onUpdateStatus = (status: ShowsViewsStatus) => setCurrentItem({ ...item, status });

	const { isLoading: isLoadingUpdate } = useUpdateShowOrView({});
	const { mutate: mutateFinish, isLoading: isLoadingFinish } = useFinishShowOrView({
		onSuccess: () => onSuccess(),
	});
	const { mutate: mutateNewDate, isLoading: isLoadingNewDate } = useNewDate({
		onSuccess: () => onUpdateStatus("waiting"),
	});
	const { mutate: mutateCancel, isLoading: isLoadingCancel } = useCancelShowOrView({
		onSuccess: () => onUpdateStatus("canceled"),
	});

	const onUpdate = (newStatus: ShowsViewsStatus) => {
		if (newStatus === "successFinished") {
			mutateCancel(
				 item.id
			);
		} else if (newStatus === "waiting") {
			const date = new Date(watchForm.calendar);
			const [hours, minutes] = watchForm.time.split(":").map((i) => +i);

			date.setHours(hours);
			date.setMinutes(minutes);

			mutateNewDate({
				id: item.id,
				plannedDate: new Date(date),
			});
		} else if (/showOrViewMissed|preSuccess/.test(newStatus)) {
			onUpdateStatus(newStatus);
			setValue("mainRadio", "");
		} else if (newStatus === "success") {
			onUpdateStatus(newStatus);
		} else if (newStatus === "canceled") {
			const values = getValues();

			const date = new Date(watchForm.showDate);
			const [hours, minutes] = watchForm.showTime.split(":").map((i) => +i);

			date.setHours(hours);
			date.setMinutes(minutes);

			mutateFinish({
				id: item.id,
				[`${type}NeedsHelpToAnotherNewObject`]: values.needHelp,
				[`${type}Continue`]: values.isSelling,
				[`${type}NeedsHelpToSellHisObject`]: values.isNeedHelp,
			});
		}
	};

	const isLoading = isLoadingCancel || isLoadingUpdate || isLoadingNewDate || isLoadingFinish;

	return {
		onUpdate,
		onUpdateStatus,
		isLoading,
	};
};
