import { memo } from "react";
import styles from "./index.module.scss";
import { Popover } from "components/UI";
import cn from "classnames";
import { Kind } from "types";
import { ReactComponent as OpenInNew } from "../../../../assets/icons/open-in-new.svg";
import { ReactComponent as InCart } from "../../../../assets/icons/trash.svg";
import { ReactComponent as ToCart } from "../../../../assets/icons/Shopping_Cart.svg";
import { useDeleteFromCart } from "../../../../service/api/mutations";

export type Props = {
  relatedKinds: {
    applications: Kind[];
    deals: Kind[];
    preApplications: Kind[];
  };
  canOpenANewTab?: boolean
  classNames?: string
  onClick?: () => void
  mini?: boolean
  atList?: boolean
  atServices?: boolean
  hasCart?: boolean
  isInCart?: boolean
  refetch?: () => void
  id?: string
}
// todo доделать хлястик( бордеррадиус,логику отображения поповера )
export const ContactStrap = memo((
  {
    relatedKinds,
    classNames,
    mini = false,
    canOpenANewTab,
    onClick,
    atList,
    atServices,
    hasCart,
    isInCart,
    refetch,
    id
  }: Props): JSX.Element => {
  const {
    applications,
    deals,
    preApplications,
  } = relatedKinds

  const {mutate: mutateDelete, isLoading: isLoadingDelete} = useDeleteFromCart({
    onSuccess: refetch
  })

  const handleDelete = (event) => {
    event.stopPropagation()
    mutateDelete(id ?? "")
  }

  const sumLength = preApplications.length + deals.length + preApplications.length

  return (
    <>
      {canOpenANewTab &&
        <div
          className={cn(styles.canOpenInNew, {[ styles.canOpenInNewWithoutStage ]: sumLength === 0})}
          onClick={onClick}
        >
          <OpenInNew/>
        </div>
      }
      {hasCart &&
        <div
          className={cn(styles.cart)}
          onClick={isInCart ? handleDelete : onClick}
        >
          {isInCart ? <InCart/> : <ToCart/>}
        </div>
      }
      {((applications && applications.length > 0) ||
          (deals && deals.length > 0) ||
          (preApplications && preApplications.length > 0)) &&
        <div className={cn(styles.wrap, classNames, {[ styles.mini ]: mini})} onClick={(e) => e.stopPropagation()}>
          {
            applications && applications.length > 0 &&
            <div className={cn(styles.applications, styles.strap, {[ styles.atList ]: atList})}>
            </div>
          }
          {
            deals && deals.length > 0 &&
            <div className={cn(styles.deals, styles.strap, {[ styles.atList ]: atList})}>
            </div>
          }
          {
            preApplications && preApplications.length > 0 &&
            <div className={cn(styles.preApplications, styles.strap, {[ styles.atList ]: atList})}>
            </div>
          }
          <Popover
            classNames={{over: styles.stagePopup, root: styles.root}}
            overElement={
              <div className={styles.shedule}>
                {preApplications && preApplications.length > 0 &&
                  <div className={styles.stage}>
                        <span className={styles.stageCircle} style={{background: "#D5D3CE"}}>
                          {preApplications[ 0 ].stageNo}
                        </span>
                    <span className={styles.stagesText}>
                          {`Предзаявка. ${(preApplications)[ 0 ].businessProcessTitle}. Этап: 
                          ${(preApplications)[ 0 ].stage}`}
                        </span>
                  </div>
                }
                {applications && applications.length > 0 &&
                  <div className={styles.stage}>
                          <span className={styles.stageCircle} style={{background: "#30CFB1"}}>
                            {applications[ 0 ].stageNo}
                          </span>
                    <span className={styles.stagesText}>
                          {`Заявка. ${(applications)[ 0 ].businessProcessTitle}. Этап: 
                          ${(applications)[ 0 ].stage}`}
                          </span>
                  </div>
                }
                {deals && deals.length > 0 &&
                  <div className={styles.stage}>
                          <span className={styles.stageCircle} style={{background: "#336BEB"}}>
                            {deals[ 0 ].stageNo}
                          </span>
                    <span className={styles.stagesText}>
                          {`Сделка. ${(deals)[ 0 ].businessProcessTitle}. Этап: 
                          ${(deals)[ 0 ].stage}`}
                          </span>
                  </div>
                }
                {/* todo */}
                {sumLength > 4 &&
                  <div>{`еще ${sumLength - (preApplications && 1) - (applications && 1) - (deals && 1)}`}</div>}
              </div>
            }
          />
        </div>}
    </>
  )
})