import { addDays, formatDuration, intervalToDuration } from "date-fns";
import { ru } from "date-fns/locale";

import { getPublicHost } from "../../../../publicPage";

export const createLink = (id: string): string => {
	const host = getPublicHost();
	const { protocol } = window.location;

	return `${protocol}//${host}/presentation/${id}`;
};

export const countTimeLeft = (timeStart: Date): string => {
	const duration = intervalToDuration({
		start: timeStart,
		end: addDays(timeStart, 5),
	});

	const left = duration.days ? { days: duration.days } : { hours: duration.hours };

	return formatDuration(left, { delimiter: ", ", locale: ru });
};
