import {
	TaskSubTypeLabelEnum,
	TaskTypeLabelEnum,
	TaskTypes,
	MultiLayerSelectOption,
} from "../../../../../../../../types";

const capitalize = (str?: string) => str?.replace(/\W/, (c) => c.toUpperCase());

export const mapTaskTypesToSelectOptions = (types: TaskTypes[]): MultiLayerSelectOption[] => {
	const options: MultiLayerSelectOption[] = [];

	types.forEach((type) => {
		if (!type.value) return;

		const label = capitalize(type.label) ?? TaskTypeLabelEnum[type.value] ?? "Неизвестный тип";

		options.push({
			value: type.value,
			label: {
				name: label,
				children: label,
			},
			layer: 0,
			parents: [],
		});

		type.subtypes.forEach((subtype) => {
			if (!subtype.value) return;

			const sublabel = capitalize(subtype.label) ?? TaskSubTypeLabelEnum[type.value] ?? "Неизвестный подтип";

			options.push({
				value: subtype.value,
				label: {
					name: sublabel,
					children: sublabel,
				},
				layer: 1,
				parents: [type.value],
			});
		});
	});

	return options;
};
