import { memo, MouseEvent } from "react";
import cn from "classnames";
import isEqual from "lodash.isequal";
import styles from "./index.module.scss";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { CardObject, CardObjectMini } from "../../index";
import { useSample } from "../../../pages/RealEstateList/views/sample/RealEstateListCompilation/helpers";
import { CollectionItem, RealEstate } from "../../../../types";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/iconPlus.svg";
import { ReactComponent as PlusIconBlue } from "../../../../assets/icons/plus-blue.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/IconDeleteWhite.svg";
import { ReactComponent as TrashIconBlue } from "../../../../assets/icons/IconDeleteBlue.svg";

type Props = {
  objectData: RealEstate;
  isShortCard?: boolean;
  onCardClick?: (id: string) => void;
  onAuxClick?: (id: string) => void;
  onEnterBar?: () => void;
  onLeaveBar?: () => void;
  isInSample?: boolean;
  imgOnly?: boolean;
  disableDelete?: boolean;
  sampledApplicationId: string;
  isCompare: boolean;
  isCompilation: boolean;
  collectionItem?: CollectionItem
  isHovered?: boolean
};

export const CardObjectSample = memo(
  ({
     objectData,
     isShortCard,
     onCardClick,
     onAuxClick,
     onEnterBar,
     onLeaveBar,
     isInSample,
     imgOnly,
     disableDelete,
     isCompare,
     isCompilation,
     sampledApplicationId,
     collectionItem,
     isHovered,
   }: // eslint-disable-next-line sonarjs/cognitive-complexity
     Props): JSX.Element => {
    const {
      onAdd: {onAdd, isAddLoading},
      onRemove: {onRemove, isRemoveLoading},
    } = useSample({sampledApplicationId, isCompare, isCompilation});

    const isLoading = isAddLoading || isRemoveLoading;

    const handleAdd = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      if (!isLoading && onAdd) {
        onAdd(objectData);
      }
    };

    const isParser = !isEqual(objectData.parserData, undefined);

    const handleRemove = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      if (!isLoading && onRemove) {
        onRemove(objectData);
      }
    };

    return (
      <div className={cn(styles.root, {[ styles.rootHovered ]: !isShortCard})}>
        {isShortCard ? (
          <CardObjectMini
            id={objectData.id}
            objectData={objectData}
            onAuxClick={onAuxClick}
            onCardClick={onCardClick}
            classNames={{
              img: imgOnly ? styles.nonActiveImage : "",
              content: cn(styles.content, {[ styles.hide ]: imgOnly}),
            }}
            withPrice
            collectionItem={collectionItem}
            isHovered={isHovered}
          />
        ) : (
          <CardObject
            id={objectData.id}
            onAuxClick={onAuxClick}
            onCardClick={onCardClick}
            clientObject={objectData}
            classNames={{
              root: styles.cardRoot,
              content: cn(styles.content, {
                [ styles.contentInCompilation ]: isInSample,
              }),
            }}
            variant="standard"
            isCompilation={isCompilation}
            cardBackground="white"
          />
        )}
        {isInSample && !isShortCard && <div className={styles.inCompilationText}>Объект уже в подборке</div>}
        {!imgOnly && !disableDelete && (
          <div
            onClick={isInSample ? handleRemove : handleAdd}
            onMouseEnter={onEnterBar}
            onMouseLeave={onLeaveBar}
            className={cn(styles.bar, {
              [ styles.add ]: !isInSample,
              [!objectData.hasPreApplication && isParser ? styles.addPreApp : ""]: !isInSample,
              [ styles.remove ]: isInSample,
            })}
          >
            {isInSample ? (
              // eslint-disable-next-line no-nested-ternary
              <>{isRemoveLoading
                ? <Loader className={styles.loader}/>
                : (!objectData.hasPreApplication && isParser
                  ? <TrashIconBlue/>
                  : <TrashIcon/>)}</>
            ) : (
              <>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isAddLoading ? (
                  <Loader className={styles.loader}/>
                ) : (!objectData.hasPreApplication && isParser ?
                    <PlusIconBlue className={cn( styles.addPreApp, {[ styles.hide ]: isInSample})}/>
                    : <PlusIcon className={cn(styles.add, {[ styles.hide ]: isInSample})}/>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);
