import { ReactNode } from "react";
import { useDispatch } from "react-redux";

import { PinModal } from "./PinModal";
import { openModal } from "../../../../service/redux/modules/modal/modal.action";
import { LeadTargetPinPayload, LeadTargetPinType } from "../../../../types";

type Props = {
	children: ReactNode;
	open?: LeadTargetPinType;
	onPinSelected: (pinPayload: LeadTargetPinPayload) => void;
	hasObjectWithoutApplication?: boolean;
};

export const PinObject = ({
	children,
	open = LeadTargetPinType.APPLICATION,
	onPinSelected,
	hasObjectWithoutApplication,
}: Props): JSX.Element => {
	const dispatch = useDispatch();

	const handleClick = () => {
		dispatch(
			openModal({
				body: (
					<PinModal
						open={open}
						onPinSelected={onPinSelected}
						hasObjectWithoutApplication={hasObjectWithoutApplication}
					/>
				),
				width: 840,
			})
		);
	};

	return <div onClick={handleClick}>{children}</div>;
};
