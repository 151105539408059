import { RealEstate, repairValues, unitValue } from "../../../../../../../../../../types";

const getTheValue = (nameGroup: string[], object: RealEstate) => {
	const valuesGroup: any = [];

	for (let i = 0; i < nameGroup.length; i += 1) {
		let deepSearch: any = object;

		if (nameGroup[i].includes(".")) {
			const keys = nameGroup[i].split(".");

			for (let j = 0; j < keys.length; j += 1) {
				if (deepSearch[keys[j]]) {
					deepSearch = deepSearch[keys[j]];
				} else {
					deepSearch = undefined;
				}
			}
		} else {
			deepSearch = object[nameGroup[i]];
		}

		valuesGroup.push(deepSearch);
	}

	return valuesGroup;
};

type GetObjectComplexValue = {
	object: RealEstate;
	nameGroup: string[];
	type: string;
};

export const getObjectComplexValue = ({ object, nameGroup, type }: GetObjectComplexValue): any => {
	const valuesGroup: any = getTheValue(nameGroup, object);

	switch (type) {
		case "floors":
			return valuesGroup.slice(0, 2).join(" ").replace(" ", " из ");
		case "decorationsOrRefurbishment":
			return valuesGroup[0] && valuesGroup[1] ? `${repairValues[valuesGroup[1]]} (${valuesGroup[1]})` : "-";
		case "communications":
			return valuesGroup[0];
		case "numberWithUnit":
			return `${valuesGroup[0]} ${unitValue[valuesGroup[1]] || ""}`;
		case "commercialPartsSquareView":
			return {
				isPossible: valuesGroup[0],
				partsAmount: valuesGroup[1],
				partsSquares: valuesGroup[2],
			};
		default:
			return "";
	}
};
