import { ReactNode } from "react";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	foreground?: ReactNode;
};

export const Parallax = ({ children, foreground }: Props): JSX.Element => (
	<div className={styles.root}>
		{foreground && <div className={styles.foreground}>{foreground}</div>}

		{children}
	</div>
);
