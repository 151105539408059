import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Contact, ContactPinDefaultValues } from "../../../../types";

import { Contacts } from "./Contacts";
import { openModal } from "../../../../service/redux/modules/modal/modal.action";

type Props = {
	children: ReactNode;
	onPin: (contact: Contact) => void;
	hideThisIds?: string[];
	defaultValues?: ContactPinDefaultValues;
	disabled?: boolean;
	classNames?: {
		contentWrapper?: string;
		root?: string;
	};
};

export const PinContact = ({
	children,
	onPin,
	hideThisIds,
	defaultValues,
	disabled,
	classNames,
}: Props): JSX.Element => {
	const dispatch = useDispatch();

	const handleClick = () => {
		if (!disabled) {
			dispatch(
				openModal({
					body: <Contacts onPin={onPin} hideThisIds={hideThisIds} defaultValues={defaultValues} />,
					width: 1000,
					classNames: { contentWrapper: classNames?.contentWrapper },
				})
			);
		}
	};

	return (
		<div onClick={handleClick} className={classNames?.root}>
			{children}
		</div>
	);
};
