import styles from "./index.module.scss";

type Props = {
	id: string;
};

export const Observe = ({ id }: Props): JSX.Element => (
    <div className={styles.container} id={id}>
            <h2>Следи</h2>
            <div className={styles.grid}>
                <div className={styles.contentLeft}>
                    <h3>Новые функции от команды Сделки</h3>
                    <span>28.10.23</span>
                    <p>Новая карточка реклама позволит получить больше информации и статику размещения объектов.  Вы сможете получить подробную информацию о количестве просмотров, кликов и других метриках, связанных с размещением вашей рекламы. Если у вас есть конкретные вопросы или потребности, дайте нам знать, и мы с удовольствием поможем вам.</p>
                    <span>01.10.23</span>
                    <p> Теперь Сделка может работать с данными, полученными из вашей системы Mango АТС. Это позволяет обеспечить более гладкую и эффективную работу вашего агентсва в Сделке. Если у вас есть вопросы или нужна помощь в настройке интеграции, пожалуйста, сообщите нам. Мы с удовольствием вам поможем!</p>
                    <span>04.09.23</span>
                    <p>Новые виды вопросов для виджета "Квиз" в карточке заявки. Мы можем предложить несколько новых видов вопросов, которые вы можете добавить в карточку заявки: 
                        </p><p> 1. Множественный выбор: Позволяет пользователям выбрать один или несколько вариантов ответов из предоставленного списка. 
                        <br/> 2. Открытый вопрос: Позволяет пользователям свободно ввести текстовый ответ. 
                        <br/> 3. Ранжирование: Пользователям предлагается упорядочить варианты ответов по их предпочтению. 
                        <br/> 4. Шкала удовлетворенности: Позволяет пользователям оценить свою степень удовлетворенности по шкале от 1 до 10 или в процентах. 
                        <br/> 5. Вопрос с приоритетом: Позволяет пользователям определить предпочтительный вариант ответа из предоставленного списка. 
                        <br/> 6. Да/нет вопрос: Пользователям предлагается ответить "да" или "нет" на вопрос. 
                        </p><p><br/> Это лишь несколько примеров, и мы готовы обсудить с вами дополнительные виды вопросов в зависимости от ваших требований и целей.</p>
                    {/* <div className={styles.paragraph}>
                        <span>Зачем?</span>
                        <p>
                        Наш любимый командир опять в игре! Вы готовы к новому месяцу эпических состязаний по Hearthstone? В эти выходные, 2 и 3 апреля, первый турнир на полях сражений Lobby Legends пройдет в YouTube и Twitch в 18:00 МСК! 16 лучших игроков на полях сражений со всего мира сразятся за долю призового фонда в размере 50 000 $ и титул первого чемпиона Lobby Legends.
                        </p>
                    </div>
                    <div className={styles.paragraph}>
                        <span>Подробности</span>
                        <p>
                        Наш любимый командир опять в игре! Вы готовы к новому месяцу эпических состязаний по Hearthstone? В эти выходные, 2 и 3 апреля, первый турнир на полях сражений Lobby Legends пройдет в YouTube и Twitch в 18:00 МСК! 16 лучших игроков на полях сражений со всего мира сразятся за долю призового фонда в размере 50 000 $ и титул первого чемпиона Lobby Legends.
                        </p>
                    </div>
                    <div className={styles.paragraph}>
                        <span>Зачем?</span>
                        <p>
                        Наш любимый командир опять в игре! Вы готовы к новому месяцу эпических состязаний по Hearthstone? В эти выходные, 2 и 3 апреля, первый турнир на полях сражений Lobby Legends пройдет в YouTube и Twitch в 18:00 МСК! 16 лучших игроков на полях сражений со всего мира сразятся за долю призового фонда в размере 50 000 $ и титул первого чемпиона Lobby Legends.
                        </p>
                    </div>
                    <div className={styles.paragraph}>
                        <span>Зачем?</span>
                        <p>
                        Наш любимый командир опять в игре! Вы готовы к новому месяцу эпических состязаний по Hearthstone? В эти выходные, 2 и 3 апреля, первый турнир на полях сражений Lobby Legends пройдет в YouTube и Twitch в 18:00 МСК! 16 лучших игроков на полях сражений со всего мира сразятся за долю призового фонда в размере 50 000 $ и титул первого чемпиона Lobby Legends.
                        </p>
                    </div> */}
                </div>

                <div className={styles.contentRight}>
                    <div className={styles.article}>
                        <h3>Новая карточка реклама позволит получить больше информации и статику размещения объектов</h3>
                        <span>28.10.23</span>
                    </div>
                    <div className={styles.article}>
                        <h3>Добавлена интеграция с Mango АТС</h3>
                        <span>01.10.23</span>
                    </div>
                    <div className={styles.article}>
                        <h3>Новые виды вопросов для виджета "Квиз" в карточке заявки</h3>
                        <span>04.09.23</span>
                    </div>
                </div>
            </div>
        </div>
)