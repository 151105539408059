import { PlatformAdStatus } from "../../../../../../../../types";

export const prepareErrors = (adStatus: PlatformAdStatus) => {
	let errors = adStatus.reason;

	if (errors) {
		if (!Array.isArray(adStatus.reason)) {
			errors = [{ message: adStatus.reason as unknown as string, action: [], code: "" }];
		}
	} else errors = [];

	return errors;
};
