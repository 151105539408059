import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { Col, Container, Row, TextField, TogglePass } from "../../../../UI";
import { TermsBlock } from "../../components";
import { validationFirstStepSchema } from "../formResolvers";
import { useRegistration } from "../../../../../service/api/mutations";
import {
	setRegistrationData,
	setStep,
} from "../../../../../service/redux/modules/registration-steps/reg-steps.action";
import { getFlag } from "../../../../../utils/getFlag";
import { phoneMask } from "../../../../../utils/masks";
import { paths } from "../../../../../utils/paths";
import { useAuthAutocomplete } from "../../helpers/useAuthAutocomplete";
import { RegistrationPayloadV2 } from "../../../../../types";
import { ReactComponent as PhoneIcon } from "../../../../../assets/icons/phone.svg";
import styles from "./index.module.scss";

export const FirstStep = (): JSX.Element => {
	const dispatch = useDispatch();
	const [flagIcon, setFlagIcon] = useState<ReactNode | undefined>(undefined);

	const { control, handleSubmit, setError, formState, watch, getValues } = useForm({
		resolver: yupResolver(validationFirstStepSchema),
		defaultValues: {
			phone: "",
		},
	});
	const phoneWatcher = watch("phone");
	const { fieldStyle } = useAuthAutocomplete(phoneWatcher);
	const onRegistrationSuccess = () => {
		dispatch(setRegistrationData({ phone: getValues().phone}));
		dispatch(setStep("sms-code"));
	};

	const { mutate, mutation, error } = useRegistration({
		onSuccess: onRegistrationSuccess,
		setError,
	});
	const [errorMessage, setErrorMessage] = useState("Данный номер уже зарегистрирован")

const onSubmit = async (values: RegistrationPayloadV2) => {
  mutate({ phone: values.phone.replace(/[^\d]/g, '') });
};
useEffect(()=>{
	if((error as any)?.response?.data?.error.errorCode) {
		setErrorMessage("Данный номер уже зарегистрирован")
	}
},[error])
	const getFlagIcon = (phone: string) => {
		setFlagIcon(getFlag(phone));
		setErrorMessage("")
	};

	return (
		<div className={styles.root}>
			<h1>Регистрация</h1>
			<p>Введите номер телефона, на который хотите зарегистрировать аккаунт</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.fieldsContainer}>
					<Container>
						<Row marginBottom={20}>
							<Col xs={12}>
								<TextField
									className={fieldStyle}
									control={control}
									label="Телефон"
									name="phone"
									mask={phoneMask}
									onChange={getFlagIcon}
									prepend={<PhoneIcon />}
									error={formState?.errors?.phone?.message?.toString()}
								/>
							</Col>
							<p className={styles.errorMessage}>
								{error && errorMessage && errorMessage}
							</p>
						</Row>
					</Container>
				</div>
				
				<div className={styles.submitContainer}>
					<Button type="submit">Зарегистрироваться</Button>
					<div className={styles.loginLinkContainer}>
						<Link className={styles.link} to={paths.loginPath}>
							Войти
						</Link>
					</div>
				</div>
				</form>
				<TermsBlock />
			
		</div>
	);
};
