import { useMutation } from "react-query";

import { Position } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/position/update-position/DEFAULT_MN";

type UpdateProps = {
	onSuccess?: () => void;
};

export const usePositionUpdate = ({ onSuccess }: UpdateProps) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: ({ id, name }: Omit<Position, "permission" | "companyId">) =>
			api.positions.update(id, { name }),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
