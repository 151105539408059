import { Dispatch, SetStateAction, useState } from "react";
import { Button, Underlay } from "@sdelka_crm/sdelka-crm-component-library";

import { PinObjectTab } from "../../../index";
import { LeadTargetPinPayload, LeadTargetPinType, RealEstate } from "../../../../../types";

import styles from "./index.module.scss";

type Props = {
  open: LeadTargetPinType;
  onPinSelected: (payload: LeadTargetPinPayload) => void;
  hasObjectWithoutApplication?: boolean;
};

export const PinModal = ({open, onPinSelected, hasObjectWithoutApplication}: Props): JSX.Element => {
  const [selectedElement, setSelectedElement] = useState<RealEstate | undefined>(
    undefined
  );

  const handlePin = () => {
    if (selectedElement?.id) {
      onPinSelected({
        type: LeadTargetPinType.OBJECT,
        entityId: selectedElement.applicationId,
      });
    }
  };

  return (
    <>
      <div className={styles.pinHeader}>
        <h3>Прикрепить</h3>
      </div>
      <Underlay className={styles.pinContainer}>
        <PinObjectTab
          setSelectedElement={setSelectedElement as Dispatch<SetStateAction<RealEstate | undefined>>}
          isObjectWithoutApplication={hasObjectWithoutApplication}
        />
      </Underlay>
      <div className={styles.buttonContainer}>
        <Button disabled={!selectedElement} onClick={handlePin}>
          Прикрепить
        </Button>
      </div>
    </>
  );
};
