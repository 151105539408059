import skeletonImg from "../../../../../../../../../../assets/images/map-object-skeleton.png";

export const Loading = (): JSX.Element => (
	<div className="balloon-container">
		<img className="real-estate-balloon__img" src={skeletonImg} alt="object-img" />

		<div className="balloon-container__context">
			<p className="real-estate-balloon__title">
				<span className="real-estate-balloon__button skeleton" />
				<span className="real-estate-balloon__object-icon skeleton" />
			</p>

			<div className="real-estate-balloon__desc-text skeleton" />
			<div className="real-estate-balloon__desc-text skeleton skeleton_big" />

			<div className="real-estate-balloon__prices">
				<p className="real-estate-balloon__full-price skeleton" />
				<p className="real-estate-balloon__price-per-unit skeleton" />
			</div>
		</div>

		<div className="balloon__footer">
			<div className="real-estate-contacts">
				<div className="real-estate-contacts__img skeleton" />

				<div>
					<p className="real-estate-contacts__title skeleton" />
					<p className="real-estate-contacts__phone-number skeleton" />
				</div>
			</div>
		</div>
	</div>
);
