import { getShortIdBySource, getSourceDescription, getStatusDescription } from "./components";
import { Lead, LeadSourceEnum } from "../../../../../../../types";

import styles from "./index.module.scss";
import { useQueryParams } from "../../../../../../../utils/hooks";
import { useDispatch } from "react-redux";
import { openDrawerInDrawer } from "../../../../../../../service/redux/modules/drawer/drawer.action";
import { useLoadApplicationRealEstate, useLoadParsedRealEstate } from "../../../../../../../service/api/queries";
import { useGetNavigation } from "../../../../../RealEstatePage/helpers";
import { RealEstateView } from "../../../../../RealEstatePage/mode";

type Props = {
  lead: Lead;
};

export const LeadTitle = ({lead}: Props): JSX.Element => {
  const {
    status,
    source,
    adSource,
    target,
    parserObject,
    archivedEstateObject,
  } = lead;

  const {queryParams, changeQueryParams, removeQueryParam} = useQueryParams([
    {name: "parserRealEstateId"},
    {name: "applicationId"},
    {name: "mode"},
  ]);
  const ESTATE_ID = parserObject?.id ?? archivedEstateObject?.id ?? ""

  const {realEstate, isFetching, refetch, remove} = useLoadParsedRealEstate({
    id: ESTATE_ID,
  });

  const {realEstate: realEstateApp, isFetching: isFetchingApp, refetch: refetchApp, remove: removeApp} = useLoadApplicationRealEstate({
    id: ESTATE_ID,
  });

  const { blocksIds} = useGetNavigation({
    realEstate: archivedEstateObject?.id ? realEstateApp : realEstate,
    isParser: true,
  });
  const dispatch = useDispatch();
  const handleClose = () => {
    removeQueryParam(["parserRealEstateId", "mode", "applicationId"]);
  };

  const handleClick = () => {
    if (source === LeadSourceEnum.coldCall || source === LeadSourceEnum.archivedEstateObject) {
      dispatch(
        openDrawerInDrawer({
          width: 800,
          children:  <RealEstateView
            id={ESTATE_ID}
            blocksIds={blocksIds}
            realEstate={archivedEstateObject?.id ? realEstateApp : realEstate}
            refetch={archivedEstateObject?.id ? refetchApp : refetch}
            remove={archivedEstateObject?.id ? removeApp : remove}
            isFetching={archivedEstateObject?.id ? isFetchingApp : isFetching}
          />,
          onClose: handleClose,
        }))
    }
  }

  const {color, label} = getStatusDescription(status)

  return (
    <>
      <div style={{color}}>
        {label}
      </div>
      <div className={styles.header}>
        <div className={styles.title}>
          {getSourceDescription(source)}
        </div>
        <div className={styles.shortId} onClick={handleClick}>
          {getShortIdBySource(lead).shortId}
        </div>
      </div>
      {source === LeadSourceEnum.external &&
        <>
          <div className={styles.row}>
            <div className={styles.adLabel}>
              Рекламный канал
            </div>
            <div className={styles.adSource}>
              {`${adSource}`}
            </div>
          </div>
        </>
      }
    </>
  );
};
