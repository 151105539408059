export const sortInObject = (object: any[], key: string, sortWay: "ASC" | "DESC" = "ASC") => {
	const alphabetSort = (a: any, b: any) => {
		if (a[key] === b[key]) return 0;

		if (sortWay === "ASC") {
			return a[key] < b[key] ? 1 : -1;
		}

		if (sortWay === "DESC") {
			return a[key] > b[key] ? 1 : -1;
		}

		return 0;
	};

	return object.sort(alphabetSort);
};
