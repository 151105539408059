import { useHistory } from "react-router";
import isEqual from "lodash.isequal";

import { RealEstate } from "../../../../../../../types";
import { CardObject } from "../../../../../../UI";

import styles from "./index.module.scss";
import {
  usePopupMenu
} from "../../../../../RealEstateList/views/regular/ObjectsListRegular/components/CardObjectWithPopupMenu/helpers";

type Props = {
  realEstate: RealEstate;
};

export const Item = ({realEstate}: Props): JSX.Element => {
  const {applicationId} = realEstate;
  const {push, location} = useHistory();

  const onCardClick = () => {
    const encodedBackPath = encodeURIComponent(`${location.pathname}${location.search}`);

    push(`/real-estate/application/${applicationId}?mode=ad&backPath=${encodedBackPath}`);
  };
  const object = realEstate
  const isParser = !isEqual(object.parserData, undefined);
  const {menu} = usePopupMenu({object, isParser});

  return (
    <CardObject
      clientObject={realEstate}
      id={applicationId}
      cardBackground="white"
      classNames={{root: styles.card}}
      onCardClick={onCardClick}
      popupMenu={menu}
      variant="ad-list"
    />
  );
};
