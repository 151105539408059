import cn from "classnames";
import { useEffect } from "react";

import { useTimer } from "../../../utils/hooks";

import styles from "./index.module.scss";

type Props = {
	onRefetch: () => void;
};

export const InfinityWick = ({ onRefetch }: Props): JSX.Element => {
	const [timeRemaining, handleTimer, handleRestart] = useTimer(30);

	useEffect(() => {
		handleTimer();
	}, []);

	useEffect(() => {
		if (timeRemaining === 0) {
			onRefetch();
			handleRestart();
		}
	}, [timeRemaining]);

	return (
		<div className={styles.divider}>
			<div className={styles.linesContainer}>
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((line) => (
					<div
						key={line}
						className={cn(styles.line, {
							[styles[`line-${line}`]]: line * 2 > 30 - timeRemaining,
						})}
					/>
				))}
			</div>
		</div>
	);
};
