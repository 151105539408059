import { ReactNode, useMemo } from "react";
import { Subway, DefaultMultiLayerSelectProps, MultiLayerSelectOption } from "../../../../../types";

import { MultiLayerSelect } from "../MultiLayerSelect";

import { ReactComponent as MetroIcon } from "../../../../../assets/icons/metro-letter.svg";
import styles from "./index.module.scss";

type Props = DefaultMultiLayerSelectProps<MultiLayerSelectOption> & {
	subways: Subway[];
	name: string;
};

export const MultiLayerMetroSelect = ({
	subways,
	name,
	defaultValue = [],
	register,
	setValue,
	size = "normal",
	onChange,
	placeholder,
	error,
	classNames,
	required,
	disabled,
	customSearchFunction,
	isSearchable,
	isLoading,
}: Props): JSX.Element => {
	const metroLabel = (subway: Subway): ReactNode => (
		<div className={styles.label}>
			<span className={styles.circle} style={{ background: subway.color }}>
				<MetroIcon />
			</span>
			<span>{subway.name}</span>
		</div>
	);

	const defaultValueMultiLayer = useMemo(
		(): MultiLayerSelectOption[] =>
			defaultValue.map((layer) => {
				const foundSubway = subways.find((subway) => subway.name === layer.value);

				const children = foundSubway ? metroLabel(foundSubway) : layer.value;

				return {
					...layer,
					label: {
						name: foundSubway?.name,
						children,
					},
				} as MultiLayerSelectOption;
			}),
		[defaultValue]
	);

	const options = useMemo((): MultiLayerSelectOption[] => {
		const layers: MultiLayerSelectOption[] = [];
		subways.forEach((subway) => {
			layers.push({
				label: {
					name: subway.name,
					children: metroLabel(subway),
				},
				value: subway.name,
				parents: [],
				layer: 0,
			});

			subway.stations.forEach((station) => {
				layers.push({
					label: {
						name: station,
						children: station,
					},
					value: station,
					parents: [subway.name],
					layer: 1,
				});
			});
		});

		return layers;
	}, [subways]);

	return (
		<MultiLayerSelect
			name={name}
			defaultValue={defaultValueMultiLayer}
			options={options}
			register={register}
			placeholder={placeholder}
			setValue={setValue}
			error={error}
			size={size}
			onChange={onChange}
			classNames={classNames}
			required={required}
			disabled={disabled}
			customSearchFunction={customSearchFunction}
			isSearchable={isSearchable}
			isLoading={isLoading}
		/>
	);
};
