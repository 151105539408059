import { useState } from "react";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { Checkbox, Textarea } from "../../index";
import { Vote, VoterFormPayload, VoteFormResults } from "../../../../types";

import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as CheckmarkIcon } from "../../../../assets/icons/checkmark-pixeled.svg";
import { ReactComponent as QuestionIcon } from "../../../../assets/icons/question-simbol.svg";
import { ReactComponent as ThumbIcon } from "../../../../assets/icons/thumb-up.svg";
import styles from "./index.module.scss";

type FormType = Omit<VoterFormPayload, "vote">;

type Props = {
	onSubmitForm: (payload: VoterFormPayload) => void;
	voteResults?: VoteFormResults;
};

export const VoterFormLight = ({ onSubmitForm, voteResults }: Props): JSX.Element => {
	const [vote, setVote] = useState<Vote>(undefined);
	const { handleSubmit, control, register } = useForm<FormType>();

	const viewRequest = {
		sent: "Запрос на просмотр отправлен",
		appointed: "Просмотр назначен",
		done: "Просмотр прошел",
	};

	const onSubmit = ({ needShow, comment }: FormType) => {
		const payload = { needShow, comment, voteStatus: vote };

		onSubmitForm(payload);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
			<div
				className={cn(styles.voterBlock, {
					[styles.voterBlockVoted]: voteResults,
				})}
			>
				{voteResults ? (
					<div
						className={cn(styles.viewRequest, {
							[styles.viewRequestAnswered]: voteResults.viewRequest !== "sent",
						})}
					>
						<CheckmarkIcon />
						{viewRequest[voteResults.viewRequest]}
					</div>
				) : (
					<Checkbox control={control} name="needShow" label="Хочу на просмотр" />
				)}

				<div
					className={cn(styles.voteBtnContainer, {
						[styles.voteBtnContainerVoted]: voteResults,
					})}
				>
					<div
						onClick={() => setVote("like")}
						className={cn(styles.voteBtn, styles.thumbUp, {
							[styles.thumbUp__active]: vote === "like" || voteResults?.voteStatus === "like",
							[styles.voteBtnHide]: voteResults && voteResults?.voteStatus !== "like",
						})}
					>
						<ThumbIcon />
					</div>

					<div
						onClick={() => setVote("dislike")}
						className={cn(styles.voteBtn, styles.thumbDown, {
							[styles.thumbDown__active]: vote === "dislike" || voteResults?.voteStatus === "dislike",
							[styles.voteBtnHide]: voteResults && voteResults?.voteStatus !== "dislike",
						})}
					>
						<ThumbIcon />
					</div>

					<div
						onClick={() => setVote("notSure")}
						className={cn(styles.voteBtn, styles.question, {
							[styles.question__active]: vote === "notSure" || voteResults?.voteStatus === "notSure",
							[styles.voteBtnHide]: voteResults && voteResults?.voteStatus !== "notSure",
						})}
					>
						<QuestionIcon />
					</div>
				</div>
			</div>

			{voteResults ? (
				<div className={styles.comment}>
					<h4>Ваш комментарий</h4>
					<p>{voteResults.comment}</p>
				</div>
			) : (
				<Textarea register={register} name="comment" placeholder="Введите ваш комментарий по объекту" />
			)}

			{voteResults ? (
				<div className={styles.editButtonContainer}>
					<Button Icon={EditIcon} variant="text">
						Редактировать ответ
					</Button>
				</div>
			) : (
				<div className={styles.submitContainer}>
					<Button type="submit" disabled={!vote}>
						Проголосовать
					</Button>
				</div>
			)}
		</form>
	);
};
