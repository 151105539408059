import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import * as yup from "yup";
import { Col, Container, Row, TextField, TogglePass } from "../../../UI";
import { AuthLayout } from "../components";
import { signIn } from "../../../../service/redux/modules/auth/auth.thunk";
import { useAuthAutocomplete } from "../helpers";
import { selectAccounts, selectCurrentAccount, useSelect } from "../../../../service/redux/selectors";
import { AuthGuard } from "../../../../service/router/AuthGuard";
import { getDayTimeGreeting } from "../../../../utils/date";
import { getFlag } from "../../../../utils/getFlag";
import { paths } from "../../../../utils/paths";
import { phoneMask } from "../../../../utils/masks";

import { ReactComponent as DoorLock } from "../../../../assets/icons/door-lock.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/phone.svg";
import styles from "./index.module.scss";

export const LoginPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const {isLoading} = useSelect(selectCurrentAccount);
  const {errors} = useSelect(selectAccounts);
  const history = useHistory();
  const [flagIcon, setFlagIcon] = useState<ReactNode | undefined>(undefined);
  const [passwordInputType, setPasswordInputType] = useState<"password" | "text">("password");

  const onSubmit = async (values: { phone: string; password: string }) => {
    dispatch(
      signIn(
        {
          phone: values.phone,
          password: values.password,
        },
        history
      )
    );
  };

  const schema = yup.object().shape({
    phone: yup
      .string()
      .test("phone", "Невалидный телефон", (phone) => phone?.replace(/\D/gi, "").length === 11)
      .required(),
    password: yup.string().required("Обязательное поле"),
  });

  const {control, handleSubmit, setError, formState, getValues, watch} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {phone: "", password: ""},
  });

  const phoneWatcher = watch("phone");
  const {fieldStyle} = useAuthAutocomplete(phoneWatcher);

  useEffect(() => {
    if (errors) {
      if (errors.includes("password too weak")) {
        setError("password", {message: "Слишком простой пароль"});
      }
      if (errors.includes("Account with 'phone' does not exist")) {
        setError("phone", {message: "Телефон не зарегистрирован"});
      }
      if (errors.includes("phone must be a valid phone number")) {
        setError("phone", {message: "Невалидный телефон"});
      }
      if (errors.includes("Invalid credentials")) {
        setError("password", {message: "Некорректный пароль"});
      }
    }
  }, [errors]);

  const getFlagIcon = (phone: string) => {
    getValues();
    setFlagIcon(getFlag(phone));
  };

  return (
    <AuthGuard onlyNotAuthorized>
      <AuthLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>{getDayTimeGreeting()}</h1>
          <div className={styles.inputsContainer}>
            <Container>
              <Row marginBottom={20}>
                <Col xs={12}>
                  <TextField
                    className={fieldStyle}
                    control={control}
                    label="Телефон"
                    name="phone"
                    mask={phoneMask}
                    onChange={getFlagIcon}
                    prepend={<PhoneIcon/>}
                    append={flagIcon}
                    error={formState?.errors?.phone?.message?.toString()}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextField
                    className={fieldStyle}
                    label="Пароль"
                    autoComplete
                    type={passwordInputType}
                    name="password"
                    control={control}
                    error={formState?.errors?.password?.message?.toString()}
                    append={
                      <TogglePass
                        type={passwordInputType}
                        onClick={() =>
                          setPasswordInputType((prev) => {
                            if (prev === "password") {
                              return "text";
                            }
                            return "password";
                          })
                        }
                      />
                    }
                    prepend={<DoorLock/>}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.forget}>
              <Link to={paths.resetPasswordPath} className={styles.link}>
                Забыли пароль?
              </Link>
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.enterButtonContainer}>
                <Button fullWidth type="submit" isLoading={isLoading}>
                  Войти
                </Button>
              </div>
              <Link to={paths.registrationPath.default} className={styles.link}>
                Зарегистрироваться
              </Link>
            </div>
          </div>
        </form>
      </AuthLayout>
    </AuthGuard>
  );
};
