export const SET_EXPANDED = "sdelka/sidebar/SET_EXPANDED";
export const SET_OPEN_ACCOUNTS = "sdelka/sidebar/SET_OPEN_ACCOUNTS";
export const SET_OPEN_OFFICES = "sdelka/sidebar/SET_OPEN_OFFICES";
export const CLOSE_ALL = "sdelka/sidebar/CLOSE_ALL";

export type SidebarAction =
	| { type: typeof SET_EXPANDED; isExpanded: boolean }
	| { type: typeof SET_OPEN_ACCOUNTS; isOpen: boolean }
	| { type: typeof SET_OPEN_OFFICES; isOpen: boolean }
	| { type: typeof CLOSE_ALL };

export const setExpanded = (isExpanded: boolean): SidebarAction => ({
	type: SET_EXPANDED,
	isExpanded,
});

export const setOpenAccounts = (isOpen: boolean): SidebarAction => ({
	type: SET_OPEN_ACCOUNTS,
	isOpen,
});

export const setOpenOffices = (isOpen: boolean): SidebarAction => ({
	type: SET_OPEN_OFFICES,
	isOpen,
});

export const closeAll = (): SidebarAction => ({
	type: CLOSE_ALL,
});
