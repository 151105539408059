import { UseFormGetValues } from "react-hook-form";

type GetDefaultForSelect = {
	fieldName: string;
	getValues: UseFormGetValues<any>;
};

export const getDefaultForSelect = ({ fieldName, getValues }: GetDefaultForSelect): string | string[] => {
	const defaultValue = getValues(fieldName);

	return defaultValue || "";
};
