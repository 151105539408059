import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { TextField } from "../../../../../UI";
import { useAuthAccession } from "../../../../../../service/api/mutations";
import { commonErrors } from "../../../../../../utils/errors";

import styles from "./index.module.scss";
import { JoinCompanyModal } from "../../FinishRegistration/joinCompanyModal";

export const CompanyRealtorForm = (): JSX.Element => {

	const schema = yup.object().shape({
		shortCompanyId: yup.string().required(commonErrors.required),
	});
	const { openJoinCompanyModal } = JoinCompanyModal();
	const useJoinCompanyModal = () => {
		openJoinCompanyModal()
	};
	const { handleSubmit, control, formState, setError } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { shortCompanyId: "" },
	});

	const { mutate } = useAuthAccession({
		setError,
	});

	const onSubmit = (values: { shortCompanyId: string }) => {
		const data = {
			shortId: values?.shortCompanyId,
		};
		mutate(data);
		useJoinCompanyModal()
	};

	return (
		<form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.fields}>
				<TextField
					control={control}
					label="ID компании"
					name="shortCompanyId"
					placeholder="Указать"
					error={formState.errors.shortCompanyId?.message?.toString()}
				/>
			</div>
			<Button type="submit" classNames={{ root: styles.buttonRealtorInCompany }}>
				Отправить запрос
			</Button>
		</form>
	);
};
