import { useMutation } from "react-query";

import { Position } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/position/create-position/DEFAULT_MN";

type CreateProps = {
	onSuccess?: () => void;
};

export const usePositionCreate = ({ onSuccess }: CreateProps) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: ({ name, permission }: Omit<Position, "id" | "companyId">) =>
			api.positions.create({ name, permission }),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
