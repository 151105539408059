import { UseMutateFunction, useMutation } from "react-query";
import { ChangeTariffPayload, RealEstateUpdateAutoRenewalResponse } from "../../../../types";
import { api } from "../../api";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/real-estate-ad/change-tariff/DEFAULT_MN";

type Props = {
	onSuccess?: (
		data: AxiosResponse<RealEstateUpdateAutoRenewalResponse>,
		variables: ChangeTariffPayload,
		context: unknown
	) => void | Promise<unknown>;
	onError?: () => any;
};

type Return = {
	mutate: UseMutateFunction<
		AxiosResponse<RealEstateUpdateAutoRenewalResponse>,
		unknown,
		ChangeTariffPayload,
		unknown
	>;
	isLoading: boolean;
};

type UseComplaintAutoReject = (props: Props) => Return;

export const useChangeTariff: UseComplaintAutoReject = ({ onSuccess, onError }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: ChangeTariffPayload) => api.realEstateAd.changeTariff(data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
