import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { RealEstate } from "../../../../../../../../../../types";

const addPostfix = (dirtyValue: string, postfix?: string) =>
	`${dirtyValue || "-"}${postfix && dirtyValue ? ` ${postfix}` : ""}`;

const getTheValue = (name: string, object: RealEstate) => {
	let returnValue: any = object;

	if (name.includes(".")) {
		const keys = name.split(".");

		for (let i = 0; i < keys.length; i += 1) {
			if (returnValue[keys[i]]) {
				returnValue = returnValue[keys[i]];
			} else {
				returnValue = undefined;
			}
		}
	} else {
		returnValue = returnValue[name];
	}

	return returnValue;
};

const getIfArray = (dirtyArray: any[]) =>
	dirtyArray.reduce((accum, item) => (item ? `${accum},\n${item}` : accum))?.trim();

// eslint-disable-next-line no-confusing-arrow
const getIfBoolean = (dirtyBoolean: boolean) => (dirtyBoolean ? "Есть" : "Нет");

const getIfMooltiselect = (dirtyMultiSelect: Record<string, unknown>) => {
	if (!dirtyMultiSelect) {
		return "-";
	}

	const keys = Object.keys(dirtyMultiSelect);
	// eslint-disable-next-line no-confusing-arrow
	const keysToArray = keys.map((key) => (dirtyMultiSelect[key] === true ? `${key}, ` : ""));
	const arrayToString = keysToArray.join("").trimEnd();

	return arrayToString.slice(0, arrayToString.length - 1);
};

type GetObjectValue = {
	object: RealEstate;
	name: string;
	postfix: string;
	type: string;
	template: string;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getObjectValue = ({ object, name, postfix, type, template }: GetObjectValue): any => {
	if (!name) {
		return "-";
	}

	const dirtyValue = getTheValue(name, object);

	if (type === "multiSelect" && template === "viewList") {
		return dirtyValue;
	}

	if (Array.isArray(dirtyValue)) {
		return getIfArray(dirtyValue);
	}

	switch (type) {
		case "boolean":
			return getIfBoolean(dirtyValue);
		case "number":
			return dirtyValue === 0 ? "Нет" : addPostfix(dirtyValue, postfix);
		case "multiSelect":
			return getIfMooltiselect(dirtyValue);
		case "date":
			return formatDate(dirtyValue);
		default: {
			if (!dirtyValue) {
				return "-";
			}

			return addPostfix(dirtyValue, postfix);
		}
	}
};
