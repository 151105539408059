import { useEffect } from "react";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import {
	ObjectAdGlobalStatistics,
	ObjectLayoutLoading,
	ObjectAdDescriptionForm,
	CardObjectHeader,
} from "../../../../UI";
import { RealEstateAdPlatform, RealEstateAdHeader, RealEstateAdPlatformsTable } from "./components";
import { useLoadPlatforms } from "../../../../../service/api/queries";
import { RealEstateDescriptionFormValues, RealEstateWithSchema } from "../../../../../types";

import styles from "./index.module.scss";

type Props = {
	id: string;
	onNav: (platforms: Array<{ id: string; name: string }>) => void;
	realEstate?: RealEstateWithSchema;
	isFetchingObject: boolean;
	refetch: () => void;
};

export const RealEstateAd = ({ id, onNav, realEstate, isFetchingObject, refetch }: Props): JSX.Element => {
	const {
		data,
		isLoading: isLoadingPlatforms,
		refetch: refetchPlatforms,
		remove,
	} = useLoadPlatforms({ applicationId: id });

	useEffect(() => {
		refetch();
		refetchPlatforms();

		return () => remove();
	}, [id]);

	/* Creating navigation out of fetched data */
	useEffect(() => {
		if (isLoadingPlatforms || !data) return;

		onNav(
			data?.data.map((platform) => ({
				id: `ad_${platform.name}`,
				name: platform.name,
			}))
		);
	}, [isLoadingPlatforms, data]);

	if (isFetchingObject || !realEstate) return <ObjectLayoutLoading />;

	return (
		<div className={styles.root}>
			<div className={styles.cardObjectHeader}>
				<CardObjectHeader clientObject={realEstate} clickable={false} />
			</div>

			<div className={styles.content}>
				<div id="ad_common">
					<RealEstateAdHeader onButtonClick={() => false} />

					<ObjectAdGlobalStatistics title="Размещения на площадках" applicationId={id} />
				</div>

				<div className={styles.adDescription}>
					<ObjectAdDescriptionForm
						realEstate={realEstate as RealEstateDescriptionFormValues}
						refetch={refetch}
						applicationId={id}
					/>
				</div>

				{isLoadingPlatforms ? (
					<Loader height={200} />
				) : (
					data?.data.map((platform) => (
						<div id={`ad_${platform.name}`} key={`${platform.id}_${platform.name}`}>
							<RealEstateAdPlatform
								applicationId={id}
								platform={platform}
								refetchPlatforms={refetchPlatforms}
							/>
						</div>
					))
				)}

				<div id="ad_free" className={styles.block}>
					<RealEstateAdPlatformsTable applicationId={id} title="Бесплатные площадки" type="freeTable" />
				</div>

				<div id="ad_non_free" className={styles.block}>
					<RealEstateAdPlatformsTable applicationId={id} title="Платные площадки" type="paidTable" />
				</div>
			</div>
		</div>
	);
};
