import { useDispatch } from "react-redux";

import { openModal } from "../../../../service/redux/modules/modal/modal.action";
import { PreCreateApplicationPayload, PreCreateObjectPayload } from "../../../../types";
import { PreCreateModal } from "./components";

import styles from "./index.module.scss";

type Props = {
	title: string;
	onCreated: (values: PreCreateApplicationPayload | PreCreateObjectPayload) => void;
	isFull?: boolean;
};

type Return = {
	handleCreateObject: () => void;
};

type UsePreCreateApplication = (props: Props) => Return;

export const usePreCreateApplication: UsePreCreateApplication = ({ title, onCreated, isFull }) => {
	const dispatch = useDispatch();

	const handleCreateObject = () => {
		dispatch(
			openModal({
				title,
				body: <PreCreateModal onCreated={onCreated} isFull={isFull} />,
				width: 660,
				classNames: {
					contentWrapper: styles.contentWrapper,
				},
			})
		);
	};

	return {
		handleCreateObject,
	};
};
