import { useContext, createContext } from "react";

import { Client } from "../api/Client";

export const ClientContext = createContext<typeof Client | null>(null);

export function useClient(): typeof Client {
	const client = useContext(ClientContext);

	if (!client) {
		throw new Error("No context found");
	}

	return client;
}
