import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../../service/redux/modules/modal/modal.action";
import { Contact } from "../../../../../../../../../types";
import { DeleteContactModal } from "./DeleteContactModal";

type Return = {
  openDeleteContactModal: () => void
}

type Props = {
  onPinContact: (contact: (Contact | null | undefined), onPinSuccess?: () => void) => Promise<void>
}

type useDeleteContactModal = ({onPinContact}: Props) => Return

export const useDeleteContactModal: useDeleteContactModal = ({onPinContact}) => {
  const dispatch = useDispatch()
  const openDeleteContactModal = () => {
    dispatch(
      openModal(
        {
          width: 660,
          body: <DeleteContactModal
          onPinContact={onPinContact}
          />
        }
    ))
  }

  return {
    openDeleteContactModal
  }
}

