import { api } from "../../index";
import { useMutation } from "react-query";
import {  RealEstateUpdatePriceWithDiscount } from "../../../../types";

const DEFAULT_MN = "sdelka/estateObjectStore/DEFAULT_MN";

type UseUpdatePriceWithDiscount = {
  onSuccess?: () => void;
};

export const UseUpdatePriceWithDiscount = ({onSuccess}: UseUpdatePriceWithDiscount) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (payload: RealEstateUpdatePriceWithDiscount) => api.realEstate.updatePriceWithDiscount(payload),
    onSuccess,
  });

  return {
    mutatePriceWithDiscount: mutation.mutate,
    isLoading: mutation.isLoading,
  };
}