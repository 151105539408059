import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { RealEstateFilter, AddressValue } from "../../../../../types";

import {
	AddressSelect,
	ButtonSquare,
	CalendarInPopup,
	MultiLayerSelect,
	MultiSelect,
	PopupObjectType,
	RadioButton,
} from "../../../../UI";
import { objectFilters } from "../../../../../utils/clientObjects";
import { useResponsibleUsers } from "../../../../../utils/hooks";
import { useFeedList, useFilterForm, useResetForm, useSubmitForm } from "./helpers";

import { ReactComponent as CancelIcon } from "../../../../../assets/icons/cancel.svg";
import styles from "./index.module.scss";

type Props = {
	filter: RealEstateFilter;
	changeFilter: (payload: RealEstateFilter) => void;
};

export const Filters = ({ filter, changeFilter }: Props): JSX.Element => {
	const {
		register,
		setValue,
		control,
		getValues,
		watcherResponsibleUsers,
		watcherFeedAdds,
		watcherFeedDateEnd,
		watchCategory,
		watcherAddress,
		watchForm,
		handleSubmit,
		reset,
	} = useFilterForm({ filter });

	const { responsibleUsers } = useResponsibleUsers();

	const { feedAds } = useFeedList();

	const { showBtn, setShowBtn } = useSubmitForm({
		filter,
		watchForm,
	});

	const { resetFilter } = useResetForm({
		reset,
		setShowBtn,
		changeFilter,
	});

	return (
		<form className={styles.root} onSubmit={handleSubmit(changeFilter)}>
			<div className={styles.line}>
				<div className={cn(styles.filterWrapper, styles.responsibleWrapper)}>
					<MultiLayerSelect
						name="responsibleUsers.userId"
						options={responsibleUsers || []}
						register={register}
						placeholder="Ответственный"
						setValue={setValue}
						isSearchable
						defaultValue={watcherResponsibleUsers}
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.feedWrapper)}>
					<MultiSelect
						name="feedAds.feedId"
						setValue={setValue}
						options={feedAds || []}
						register={register}
						placeholder="Рекламная площадка"
						defaultValue={watcherFeedAdds}
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.dataWrapper)}>
					<CalendarInPopup
						form={{
							register,
							setValue,
							getValues,
							name: "feedAds.endDate",
						}}
						defaultDate={watcherFeedDateEnd}
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.dealType)}>
					<RadioButton
						name="type.value"
						options={objectFilters.sellBusinessProcess}
						control={control}
						background="blue"
						textColor="blue"
					/>
				</div>

				<div className={cn(styles.itemWrapper, styles.objectTypeWrapper)}>
					<PopupObjectType register={register} setValue={setValue} defaultValues={watchCategory || []} />
				</div>

				<div className={styles.addressFilters}>
					<AddressSelect
						placeholder="Адрес"
						name="address"
						register={register}
						classNames={{
							root: styles.addressWrapper,
							input: styles.addressInput,
							selectListWrapper: styles.listClassName,
						}}
						value={watcherAddress as AddressValue}
						setValue={setValue}
					/>
				</div>
			</div>

			<div className={styles.line}>
				<Button
					variant="text"
					classNames={{ root: styles.resetBtn, icon: styles.cancelIcon }}
					onClick={resetFilter}
					Icon={CancelIcon}
				>
					<span>Сбросить</span>
				</Button>
			</div>

			{showBtn && (
				<ButtonSquare type="submit" classNames={{ root: styles.submitBtn }}>
					Показать
				</ButtonSquare>
			)}
		</form>
	);
};
