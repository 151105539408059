import { ILessonVideoPreviewItem } from "../../../../types";
import styles from "./index.module.scss"
import { useState } from "react";
import cn from "classnames";
import { ReactComponent as BuildingsIcon } from "../../../../assets/icons/buildings.svg";
import { ReactComponent as OpenInNew } from "../../../../assets/icons/open-in-new.svg";

type Props = {
  lesson: ILessonVideoPreviewItem
  classNames?: {
    root?: string;
    content?: string;
    imgContainer?: string;
    owner?: string
  };
}

export const CardVideoLesson = (
  {
    lesson,
    classNames
  }: Props): JSX.Element => {
  const {
    title,
    description,
    tags,
    previewImage,
    videoUrl
  } = lesson

  const [isImageError, setIsImageError] = useState(false);
  const onImageError = () => setIsImageError(true);

  const handleOpen = () => {
    window.open(`${videoUrl}`)
  }

  return (
    <div className={styles.root}  onClick={handleOpen}>
      <div className={styles.row}>
        <div
          className={cn(styles.img, classNames?.imgContainer, {
            [ styles.noImg ]: !previewImage || isImageError,
          })}
        >
          {previewImage && !isImageError ? (
            <>
              <img onError={onImageError} alt="object" src={previewImage}/>
            </>
          ) : (
            <BuildingsIcon/>
          )}
        </div>
        <div className={styles.column}>
          <div className={styles.column}>
            <div className={styles.title}>
              {title}
            </div>
            <div className={styles.description}>
              {description}
            </div>
          </div>
          <div className={styles.rowTags}>
            {tags.map(item => (
              <div className={styles.tag}>
                {item}
              </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className={styles.stage}>
       <OpenInNew/>
      </div>
    </div>
  )
}