import styles from "./index.module.scss"
import { Contact, ContactHistoryTabs } from "../../../../../../../types";
import { ReactComponent as ChatCian } from "../../../../../../../assets/icons/chatCian.svg";
// import { ReactComponent as ChatAvito } from "../../../../../../../assets/icons/chatAvito.svg";
import { ReactComponent as ChatCompany } from "../../../../../../../assets/icons/chatCompany.svg";
import { ChatAvito } from "./chat-avito/ChatAvito";

type Props = {
  tab: ContactHistoryTabs
  contactId: string
  contact: Contact
}

export const Chat = (
  {
    tab,
    contactId,
    contact
  }: Props): JSX.Element => {

  const getChatByTab = () => {
    switch (tab) {
      case ContactHistoryTabs.chatAvito:
        return <ChatAvito contactId={contactId} contact={contact}/>
      case ContactHistoryTabs.chatCian:
        return <ChatCian/>
      case ContactHistoryTabs.chatCompany:
        return <ChatCompany/>
      default:
        return <ChatCian/>
    }
  }

  return (
    <div className={styles.root}>
      {getChatByTab()}
    </div>
  )

}