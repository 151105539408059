import styles from "./index.module.scss"

export const CartHeader = () => {

  return (
    <div className={styles.root}>
      <div className={styles.elements}>
        <div className={styles.element}>Товар</div>
        <div className={styles.element}>Тариф</div>
        <div className={styles.element}>Кол-во</div>
        <div className={styles.element}>Цена</div>
      </div>
    </div>
  )
}