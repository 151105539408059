import { ReactNode } from "react";

import { DefaultSelectProps } from "../../../../../../types";

import selectStyles from "../selectStyles.module.scss";

type Props = {
	children: ReactNode;
	thereIsSelected: boolean;
} & Pick<DefaultSelectProps, "placeholder">;

export const SelectedWrapper = ({
	children,
	thereIsSelected,
	placeholder = "Выбрать",
}: Props): JSX.Element => (
	<span className={selectStyles.selectedOptionWrapper}>
		{placeholder && !thereIsSelected ? (
			<span className={selectStyles.placeholder}>{placeholder}</span>
		) : (
			<>{children}</>
		)}
	</span>
);
