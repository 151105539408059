import { ReactNode, useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import cn from "classnames";

import styles from "./index.module.scss";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow.svg";

type Props = {
	name: string;
	className?: string;
	label?: string;
	postfix?: ReactNode;
	register?: any;
	setValue: UseFormSetValue<any>;
	max?: number;
	min?: number;
	value?: number;
	onChange?: (value: number) => void;
};

export const CounterInput = ({
	name,
	className = "",
	label = "",
	postfix = "",
	register,
	setValue,
	max = Infinity,
	value = 0,
	onChange,
}: Props): JSX.Element => {
	const [innerValue, setInnerValue] = useState(value);

	useEffect(() => {
		setValue(name, `${innerValue}`);
	}, [innerValue]);

	useEffect(() => {
		if (onChange) return;
		setInnerValue(value);
	}, [value]);

	const mask = (val) => /^\d+$/.test(val);

	const onInnerChange = onChange
		? (e) => mask(+e.target.value) && onChange(+e.target.value)
		: (e) => mask(+e.target.value) && setInnerValue(+e.target.value);

	return (
		<div className={cn(styles.root, className)}>
			{label ? <div className={styles.label}>{label}</div> : ""}

			<div className={styles.inputWrapper}>
				<input
					maxLength={max}
					type="text"
					className={styles.input}
					{...register(name)}
					value={onChange ? value : innerValue}
					onChange={onInnerChange}
				/>

				{postfix}

				<div className={styles.arrows}>
					<div onClick={() => (onChange ? onChange(value + 1) : setInnerValue(innerValue + 1))}>
						<Arrow />
					</div>
					<div onClick={() => (onChange ? onChange(value - 1) : setInnerValue(innerValue - 1))}>
						<Arrow />
					</div>
				</div>
			</div>
		</div>
	);
};
