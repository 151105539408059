import { useDispatch } from "react-redux";
import { openModal } from "../../../../service/redux/modules/modal/modal.action";
import { PreCreateApplicationPayload, PreCreateObjectPayload } from "../../../../types";
import { PreCreateModal } from "./components";

import styles from "./index.module.scss";

type Props = {
	title: string;
	onCreated: (values: PreCreateApplicationPayload | PreCreateObjectPayload) => void;
	isFull?: boolean;
	isLoading?: boolean
};

type Return = {
	handleCreateObject: () => void;
};

type UsePreCreatePreApplication = (props: Props) => Return;

export const usePreCreatePreApplication: UsePreCreatePreApplication = ({ title, onCreated, isFull, isLoading }) => {
	const dispatch = useDispatch();

	const handleCreateObject = () => {
		dispatch(
			openModal({
				title,
				body: <PreCreateModal
					onCreated={onCreated}
					isFull={isFull}
					isLoading={isLoading}
				/>,
				width: 633,
				classNames: {
					contentWrapper: styles.contentWrapper,
				},
			})
		);
	};

	return {
		handleCreateObject,
	};
};
