import { ReactNode } from "react";
import { Redirect } from "react-router";
import { selectAccounts, selectCurrentAccount } from "../redux/selectors";

import { useSelector } from "../redux/useSelector";
import { paths } from "../../utils/paths";

type Props = {
	children: ReactNode;
	onlyNotAuthorized?: boolean;
};

export const AuthGuard = ({ children, onlyNotAuthorized }: Props): JSX.Element => {
	const { authorized } = useSelector(selectAccounts);
	const { currentAccount } = useSelector(selectCurrentAccount);

	const logged = authorized && currentAccount;

	if (onlyNotAuthorized && logged) {
		return <Redirect to={paths.dashboardPath} />;
	}

	if (!onlyNotAuthorized && !logged) {
		return <Redirect to={paths.loginPath} />;
	}

	return <>{children}</>;
};
