import React, { ReactNode } from "react";
import cn from "classnames";
import { formatDate, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import {
	AdStatusesEnumV2,
	ErrorsAndWarningsType,
	PaymentMethodsTranslation,
	PlatformAdStatusValues,
	SubStatusesEnum,
	UpdateStatusesEnum,
} from "../../../types";
import { AdStatus } from "../../../components/UI";

import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as GreenClock } from "../../../assets/icons/clock-green.svg";
import { ReactComponent as AlertInfo } from "../../../assets/icons/alert-info.svg";
import { ReactComponent as Alert } from "../../../assets/icons/alert.svg";
import { ReactComponent as ClockTimer } from "../../../assets/icons/clock-timer.svg";
import { ReactComponent as ClockTimerGreenIcon } from "../../../assets/icons/clock-timer-green.svg";
import { ReactComponent as FireIcon } from "../../../assets/icons/fire.svg";
import styles from "./index.module.scss";
import { formatDateWithTime } from "utils/date";
import { getDayDifference } from "./utils";

type CurrentStatusLayoutResult = [
	{
		isWaiting: boolean;
		wasInAd: boolean;
		editError: boolean;
		notPosted: boolean;
		isError: boolean;
		isPosted: boolean;
		isPostedAndHasErrors: boolean;
		isArchived: boolean;
		isPostedAndWaitingForStop: boolean;
		errors?: ErrorsAndWarningsType[];
		isArchivedWithError: boolean;
	},
	ReactNode,
	ReactNode
];

type Options = {
	noData: boolean;
	noTooltip: boolean;
};

type Args = {
	adStatus: PlatformAdStatusValues;
	options?: Options;
};

const defaultOptions = {
	noData: false,
	noTooltip: false,
};

// TODO: Refactor
// eslint-disable-next-line sonarjs/cognitive-complexity
export const getCurrentStatusLayout = ({ adStatus, options }: Args): CurrentStatusLayoutResult => {
	const { noData, noTooltip } = options || defaultOptions;

  // Проверка наличия adStatus
  if (!adStatus) {
    return [
      {
        isWaiting: false,
        wasInAd: false,
        editError: false,
        notPosted: false,
        isError: false,
        isPosted: false,
        isPostedAndHasErrors: false,
        isArchived: false,
        isPostedAndWaitingForStop: false,
        isArchivedWithError: false,
      },
      null,
      null,
    ];
  }

	const notPosted: boolean = adStatus.status === AdStatusesEnumV2.NOT_POSTED;
	const isWaiting: boolean = adStatus.status === AdStatusesEnumV2.WAITING;
	const wasInAd: boolean = adStatus.status === AdStatusesEnumV2.ARCHIVE;
	const isError: boolean = adStatus.status === AdStatusesEnumV2.ERROR;

	const isPosted: boolean = adStatus.status === AdStatusesEnumV2.POSTED;
	const isPostedAndHasErrors: boolean = isPosted && adStatus.updateStatus?.errors?.length > 0;
	const isPostedAndWaitingForStop: boolean = isPosted && adStatus.subStatus === SubStatusesEnum.STOPPING;

	const isArchived: boolean = adStatus.status === AdStatusesEnumV2.ARCHIVE;

	const isArchivedWithError: boolean = adStatus?.previousStatus === AdStatusesEnumV2.ERROR;

	const editError: boolean = /Ошибка редактирования/.test(adStatus.status);
	const error: boolean =
		!/Ошибка редактирования/.test(adStatus.status) && /Ошибка|Ошибка размещения/.test(adStatus.status);
	const isFinished = adStatus?.endDate
		? new Date().getTime() - new Date(adStatus?.endDate).getTime() > 0
		: false;
	let Layout: React.ReactElement = (
		<AdStatus startDate={new Date()} endDate={adStatus.endDate || new Date()} />
	);
	let FreePaidTableLayout: React.ReactElement = (
		<AdStatus startDate={new Date()} endDate={adStatus.endDate || new Date()} />
	);

	if (isFinished) {
		Layout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIcon, styles.adFinished)}>
					<Clock />
					<span>Реклама закончилась</span>
				</div>

				{!noData && adStatus.startedAt && (
					<div className={styles.adDate}>{`от ${formatDate(adStatus.startedAt ?? "")}`}</div>
				)}
			</div>
		);
	}

	if (notPosted) {
		Layout = <span className={styles.adTitle}>Не рекламируется</span>;
	}

	if (isError) {
		Layout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIcon, styles.adWaiting)}>
					<Alert className={styles.alertIcon} />

					<div className={cn(styles.errorStatusContainer, styles.adTitle)}>
						<span>Ошибка размещения</span>
						{!noData && (
							<div className={styles.adDate}>{`от ${formatDateWithTime(adStatus.gotErrorAt as Date)}`}</div>
						)}
					</div>
				</div>

				{!noData && adStatus.startedAt && (
					<div className={styles.adDate}>{`Отправлено: ${formatDateWithTime(
						adStatus.startedAt as Date
					)}`}</div>
				)}
			</div>
		);
	}

	/* if (!active) {
		Layout = <span>{wasInAd ? adStatus.status : "Не рекламируется"}</span>;
	} */

	if (isWaiting) {
		Layout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIcon, styles.adWaiting, styles.adTitle)}>
					<Clock />

					<span>Ожидает размещения</span>
					{adStatus.subStatus === SubStatusesEnum.ON_MODERATION && (
						<div className={styles.onModeration}>На модерации</div>
					)}
					{/* {!noTooltip && <Tooltip classNames={{ root: styles.tooltip }}>Фид еще не запрашивался</Tooltip>} */}
				</div>

				{!noData && adStatus.startedAt && (
					<div className={styles.adDate}>{`Отправлено: ${formatDateWithTime(
						adStatus.startedAt as Date
					)}`}</div>
				)}
			</div>
		);
	}

	if (error) {
		Layout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIcon, styles.adError)}>
					<AlertInfo />
					<span>Ошибка размещения</span>
				</div>

				{!noData && <div className={styles.adDate}>{`от ${formatDate(adStatus.startedAt ?? "")}`}</div>}
			</div>
		);
	}

	if (isPosted) {
		const finishData = adStatus.finishDate;
		const dayDifference = finishData ? getDayDifference(new Date(finishData)) : 0;

		const lessThanWeek = dayDifference <= 7;
		const lessThanThreeDays = dayDifference <= 3;

		const hasErrors =
			adStatus?.updateStatus?.status === UpdateStatusesEnum.ERROR ||
			adStatus?.updateStatus?.status === UpdateStatusesEnum.ON_MODERATION;

		const generateIcon = () => {
			if (dayDifference < 1) return <GreenClock />;
			if (hasErrors) return <GreenClock />;
			if (lessThanThreeDays) return <FireIcon className={styles.alignedIcon} />;
			if (lessThanWeek) return <ClockTimer className={styles.alignedIcon} />;
			return <GreenClock />;
		};

		const generateText = () => {
			if (dayDifference < 1) return "Активна";
			if (hasErrors) return "Активна";
			if (lessThanThreeDays)
				return `Остал${dayDifference > 1 ? "ось" : "ся"} ${dayDifference} д${
					dayDifference > 1 ? "ня" : "ень"
				} рекламы`;
			if (lessThanWeek) return `Осталось ${dayDifference} дн${dayDifference > 4 ? "ей" : "я"} рекламы`;
			return "Активна";
		};

		Layout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIcon, styles.adFinished)}>
					{isPostedAndWaitingForStop ? (
						<>
							<Clock />
							<div className={cn(styles.errorStatusContainer, styles.adTitle)}>
								<span>Ожидает остановки</span>
								{adStatus?.updateStatus?.status === UpdateStatusesEnum.ERROR && (
									<div className={styles.error}>Ошибка при изменениях</div>
								)}
								{adStatus?.updateStatus?.status === UpdateStatusesEnum.ON_MODERATION && (
									<div className={styles.onModeration}>Изменения на модерации</div>
								)}
							</div>
						</>
					) : (
						<>
							{generateIcon()}
							<div className={cn(styles.errorStatusContainer, styles.adTitle)}>
								<span>{generateText()}</span>
								{!noData && finishData && (
									<div className={styles.adDate}>{`до ${formatDate(finishData as Date)}`}</div>
								)}
								{adStatus?.updateStatus?.status === UpdateStatusesEnum.ERROR && (
									<div className={styles.error}>Ошибка при изменениях</div>
								)}
								{adStatus?.updateStatus?.status === UpdateStatusesEnum.ON_MODERATION && (
									<div className={styles.onModeration}>Изменения на модерации</div>
								)}
							</div>
						</>
					)}
				</div>

				{!noData && (
					<div className={styles.adDate}>
						Обновлено {`${formatDateWithTime(adStatus.updateOnPlatformAt as Date)}`}
					</div>
				)}
				{adStatus.period && adStatus.payMethod && (
					<div className={styles.adDate} style={{ marginTop: "5px" }}>
						{PaymentMethodsTranslation[adStatus.payMethod]}, {adStatus.period} месяц
					</div>
				)}
			</div>
		);
		FreePaidTableLayout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIconSecondLayout, styles.adFinished)}>
					<ClockTimerGreenIcon />
					<span>{dayDifference} дн.</span>
				</div>
			</div>
		);
	}

	if (isArchived) {
		Layout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIcon, styles.adFinished)}>
					<div className={cn(styles.errorStatusContainer, styles.adTitle)}>
						<span>Архив</span>
					</div>
				</div>

				{/* TODO: Изменить на поле которое отвечает за то, когда объект попал в архив (требуется от бека) */}
				{/* {!noData && adStatus.finishDate && (
					<div className={styles.adDate}>
						В Архиве с {`${formatDateWithTime(adStatus.finishDate as Date)}`}
					</div>
				)} */}
				{(adStatus.period || adStatus.payMethod) && (
					<div className={styles.adDate} style={{ marginTop: "5px" }}>
						{adStatus.payMethod
							? `${PaymentMethodsTranslation[adStatus.payMethod]}${adStatus.period ? "," : ""}`
							: ""}{" "}
						{adStatus.period ? `${adStatus.period} месяц` : ""}
					</div>
				)}
			</div>
		);
	}

	if (isFinished) {
		FreePaidTableLayout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIcon, styles.adFinished)}>
					<Clock />
					<span>Реклама закончилась</span>
				</div>

				{!noData && <div className={styles.adDate}>{`от ${formatDate(adStatus.startedAt ?? "")}`}</div>}
			</div>
		);
	}

	if (!isPosted) {
		FreePaidTableLayout = <></>;
	}

	if (isWaiting) {
		FreePaidTableLayout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIcon, styles.adWaiting)}>
					<Clock />

					<span>Фид еще не запрашивался</span>

					{!noTooltip && <Tooltip classNames={{ root: styles.tooltip }}>Фид еще не запрашивался</Tooltip>}
				</div>

				{!noData && <div className={styles.adDate}>{`от ${formatDate(adStatus.startedAt ?? "")}`}</div>}
			</div>
		);
	}

	if (error) {
		FreePaidTableLayout = (
			<div className={styles.adContainer}>
				<div className={cn(styles.adIcon, styles.adError)}>
					<Alert />
					<span>Ошибка размещения</span>
				</div>

				{!noData && <div className={styles.adDate}>{`от ${formatDate(adStatus.startedAt ?? "")}`}</div>}
			</div>
		);
	}

	return [
		{
			isWaiting,
			wasInAd,
			editError,
			notPosted,
			isError,
			isPosted,
			isPostedAndHasErrors,
			isArchived,
			isPostedAndWaitingForStop,
			errors: adStatus?.errors,
			isArchivedWithError,
		},
		Layout,
		FreePaidTableLayout,
	];
};
