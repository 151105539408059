import { useHistory } from "react-router";

import { useQueryParams } from "../../../../../../../../../../../../utils/hooks";

type Props = {
	isActive: boolean;
	applicationId: string;
};

type Return = {
	handleClick: () => void;
};

type UseOpenApplication = (props: Props) => Return;

type QueryParams = {
	applicationId: string;
	backPath: string;
};

export const useOpenApplication: UseOpenApplication = ({ isActive, applicationId }) => {
	const { queryParams, changeQueryParams } = useQueryParams<QueryParams>([
		{ name: "applicationId", type: "string" },
		{ name: "backPath", type: "string" },
	]);

	const { push } = useHistory();

	const handleClick = () => {
		if (isActive) return;

		if (queryParams.applicationId) {
			changeQueryParams([{ name: "applicationId", newValue: applicationId }]);
		} else {
			const encodedBackPath = encodeURIComponent(queryParams.backPath);

			push(`/real-estate/application/${applicationId}?mode=view&backPath=${encodedBackPath}`);
		}
	};

	return {
		handleClick,
	};
};
