import { Dispatch, SetStateAction, useMemo } from "react";
import cn from "classnames";
import { createNumberMask } from "text-mask-addons";
import { useDebouncedCallback } from "use-debounce";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import {
	AddressSelect,
	ButtonSquare,
	DoubleInput, InfoRow, MultiLayerSelect,
	PopupObjectType,
	RadioButton,
	Select,
} from "../../../../../UI";
import { useDrawFilter, useFormActions, useFormFilter, useLoadObjectsCount } from "./helpers";
import { ObjectListOptionalFilters } from "./ObjectListOptionalFilters";
import { formatThousand } from "../../../../../../utils/formatters";
import { objectFilters, objectResidentialVariant } from "../../../../../../utils/clientObjects";
import { selectObjectList, useSelect } from "../../../../../../service/redux/selectors";
import { MultiLayerSelectOption, RealEstateFilter, RealEstateListTab } from "../../../../../../types";

import { ReactComponent as Filters } from "../../../../../../assets/icons/filters.svg";
import { ReactComponent as Spinner } from "../../../../../../assets/icons/spinner.svg";
import { ReactComponent as CancelIcon } from "../../../../../../assets/icons/cancel.svg";
import styles from "./index.module.scss";
import { Responsible } from "../../../../../UI/Form/GenerateComponentByType/Components";
import { useLoadUsers } from "../../../../../../service/api/queries";
import { prepareResponsibleUsers } from "../../../../../../utils/transformation";

type Props = {
	globalFilter: RealEstateFilter;
	changeGlobalFilter: (payload: RealEstateFilter) => void;
	changeActualFilter: (payload: RealEstateFilter) => void;
	objectsFilterTypes: string[];
	setObjectsFilterTypes: Dispatch<SetStateAction<string[]>>;
	isCompilation?: boolean;
	onSaveCompilationFilters?: () => void;
	className?: string;
	resetFilter: () => void;
	tab: RealEstateListTab;
	isParser?: boolean
};

// Onchange triggers only count object request.
// By clicking 'show' btn - changes global filters for reload object list.
export const ObjectListFastFilters = ({
	changeGlobalFilter,
	changeActualFilter,
	globalFilter,
	isCompilation,
	onSaveCompilationFilters,
	className,
	objectsFilterTypes,
	setObjectsFilterTypes,
	resetFilter,
	tab,
	isParser,
}: Props): JSX.Element => {
	const { isLoading, total, filter, setFilter } = useLoadObjectsCount({
		defaultFilters: globalFilter,
		tab,
	});
	const { filtersShowButton } = useSelect(selectObjectList);

	const onSearchCount = (data: SetStateAction<RealEstateFilter>) => setFilter(data);

	const debouncedSearch = useDebouncedCallback(onSearchCount, 200);

	const {
		register,
		setValue,
		handleSubmit,
		reset,
		getValues,
		control,
		formWatcher,
		watchCategory,
		watcherUnit,
		watcherPriceFrom,
		watcherPriceTo,
		watcherAddress,
		watcherResponsible
	} = useFormFilter({ filter });

	const {
		showBtn,
		onChangePriceFrom,
		onChangePriceTo,
		onChangeDealType,
		onChangeUnit,
		onChangeObjectType,
		onFormSubmit,
		onChangeResponsible
	} = useFormActions({
		filter,
		setFilter: debouncedSearch,
		reset,
		setObjectsFilterTypes,
		globalFilter,
		getValues,
		formWatcher,
		changeGlobalFilter,
		filtersShowButton,
		changeActualFilter,
	});

	const { openFiltersDrawer } = useDrawFilter({
		setFilter: debouncedSearch,
		reset,
		getValues,
		setObjectsFilterTypes,
		tab,
		isParser
	});

	const { users } = useLoadUsers({});

	const responsibleUsers = useMemo((): MultiLayerSelectOption[] => prepareResponsibleUsers(users), [users]);

	const showObjectListOptionalFilters = () => {
		if (objectsFilterTypes.length) {
			return objectResidentialVariant.find((objectVarType) =>
				objectVarType.find((variant) => objectsFilterTypes.includes(variant?.value))
			);
		}

		return false;
	};

	return (
		<form className={cn(styles.root, className)} onSubmit={handleSubmit(onFormSubmit)}>
			<div className={styles.leftFilters}>
				<div className={styles.firstLine}>
					<div className={cn(styles.itemWrapper, styles.dealType)}>
						<RadioButton
							name="type.value"
							options={objectFilters.sellBusinessProcess}
							control={control}
							background="blue"
							textColor="blue"
							handleOnChange={onChangeDealType}
						/>
					</div>
					<div className={cn(styles.itemWrapper, styles.objectTypeWrapper, {[styles.objectTypeWrapperAgency]: !isParser})}>
						<PopupObjectType
							register={register}
							setValue={setValue}
							defaultValues={watchCategory}
							onChangeObjectType={onChangeObjectType}
							allowEmpty
						/>
					</div>
					<div className={cn(styles.itemWrapper, styles.price, {[styles.priceAgency]: !isParser, [styles.lastElem]: isParser})}>
						<div className={cn(styles.priceTrigger)}>
							<span className={styles.priceText}>Цена</span>
							<div style={{ width: 80 }}>
								<Select
									name="unit"
									size="small"
									defaultValue={watcherUnit || "объект"}
									options={objectFilters.priceFor}
									register={register}
									setValue={setValue}
									onChange={onChangeUnit}
								/>
							</div>
						</div>
						<div>
							<DoubleInput
								register={register}
								onFirstInputChange={onChangePriceFrom}
								onSecondInputChange={onChangePriceTo}
								names={["priceFrom", "priceTo"]}
								size="superLarge"
								textAlign="right"
								placeholders={["от", "до"]}
								value={[watcherPriceFrom || "", watcherPriceTo || ""]}
								setValue={setValue}
								masks={[
									createNumberMask({
										prefix: "",
										thousandsSeparatorSymbol: " ",
									}),
									createNumberMask({
										prefix: "",
										thousandsSeparatorSymbol: " ",
									}),
								]}
							/>
						</div>
					</div>
					{!isParser &&
						<div className={cn(styles.itemWrapper, styles.lastElem, styles.responsible)}>
							<MultiLayerSelect
								name={"responsible"}
								options={responsibleUsers || []}
								register={register}
								placeholder="Ответственный"
								setValue={setValue}
								isSearchable
								defaultValue={watcherResponsible}
								onChange={onChangeResponsible}
								classNames={{ root: styles.select, selectListWrapper: styles.selectListWrapper }}
							/>
						</div>
					}
				</div>
				{showObjectListOptionalFilters() && (
					<ObjectListOptionalFilters
						globalFilter={globalFilter}
						filter={filter}
						setFilter={debouncedSearch}
						type={objectsFilterTypes}
						register={register}
						setValue={setValue}
						control={control}
						getValues={getValues}
						tab={tab}
					/>
				)}
				<div className={styles.actionsRow}>
					<Button color="blue-disabled" onClick={openFiltersDrawer} classNames={{ root: styles.moreWrapper }}>
						<div className={styles.more}>
							<Filters />
							<span className={styles.moreText}>Еще фильтры</span>
						</div>
					</Button>
					<Button
						variant="text"
						classNames={{
							root: styles.resetBtn,
							icon: styles.cancelIcon,
						}}
						onClick={resetFilter}
						Icon={CancelIcon}
					>
						<span>Сбросить</span>
					</Button>
					{isCompilation && (
						<button type="button" className={styles.compilationButton} onClick={onSaveCompilationFilters}>
							Сохранить фильтр
						</button>
					)}
				</div>
				{showBtn && !filtersShowButton && (
					<div
						className={cn(styles.submitBtn, {
							[styles.afterSecondLine]: showObjectListOptionalFilters(),
						})}
					>
						<ButtonSquare type="submit" classNames={{root: styles.buttonSubmit}}>
							{isLoading ? (
								<Spinner className={styles.spinner} />
							) : (
								(total && `Показать ${formatThousand(total)}`) || "Объектов не найдено"
							)}
						</ButtonSquare>
					</div>
				)}
			</div>
			<div className={styles.addressFilters}>
				<AddressSelect
					placeholder="Адрес"
					name="address"
					register={register}
					classNames={{
						root: styles.address,
						input: styles.placeholder,
						selectListWrapper: styles.listClassName,
					}}
					value={watcherAddress}
					setValue={setValue}
				/>
			</div>
		</form>
	);
};
