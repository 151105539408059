import { formatDateWithTime } from "../../../../../../../utils/date";

import styles from "./index.module.scss";

type Props = {
	createdAt?: Date;
};

export const EditTitle = ({ createdAt }: Props): JSX.Element => {
	const createdAtLabel = createdAt ? (
		<span className={styles.date}>{`соз. ${formatDateWithTime(createdAt)}`}</span>
	) : null;

	return (
		<div className={styles.root}>
			<h3 className={styles.title}>Редактирование задачи</h3>

			{createdAtLabel}
		</div>
	);
};
