import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router";
import { RealEstateMap, SidebarLayout, XYNavLayout } from "../../../UI";
import { RealEstateView } from "../mode";
import { useGetNavigation, useMapAdjust } from "../helpers";
import { useLoadParsedRealEstate } from "../../../../service/api/queries";
import { closeDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { selectCurrentAccount, selectObjectEdit, useSelect } from "../../../../service/redux/selectors";
import { paths } from "../../../../utils/paths";
import { useLoadApplication } from "components/pages/Application/ApplicationCard/helpers";
import { ApplicationInfo } from "components/pages/Application/ApplicationCard/components";
import { ApplicationCardResponse } from "types";
import { useEffect } from "react";

type MatchParams = {
  id: string;
};

type Props = {
  parsedRealEstateId?: string;
  onClose?: () => void;
  refetchList?: () => void
};

const ParsedRealEstatePageRender = ({parsedRealEstateId, onClose, refetchList}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {coord, dontMarkOnMap} = useSelect(selectObjectEdit);
  const {currentAccount} = useSelect(selectCurrentAccount);
  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const mapCenter = currentAccount?.company?.mapCenter?.split(",")?.map((cord) => Number(cord)) as [
    number,
    number
  ];

  const match = useRouteMatch<MatchParams>();
  const {id} = match.params;

  const PARSED_REAL_ESTATE_ID = parsedRealEstateId ?? id;

  const {realEstate, isFetching, refetch, remove} = useLoadParsedRealEstate({
    id: PARSED_REAL_ESTATE_ID,
  });

  const {navigationY, blocksIds, setNavigationY} = useGetNavigation({
    realEstate,
    isParser: true,
  });
  // const {
  //   application,
  // } = useLoadApplication({applicationId: id});
  useEffect(() => {
    setNavigationY([
      ...navigationY,
      {
        id: "leads",
        label: "Предзаявка",
        height: 170,
        disabled: true,
        changeTab: () => 0
      },
    ]);
  }, [setNavigationY])
  const {onMapClick} = useMapAdjust({realEstate});

  const onCloseClick = () => {
    dispatch(closeDrawer());

    if (onClose) {
      onClose();
    }
  };

  return (
    <XYNavLayout
      yNavigation={navigationY}
      yNavigationTop={64}
      activeYTab={0}
      onCloseClick={parsedRealEstateId ? onCloseClick : undefined}
      withGoBack={{defaultBackPath: `${paths.objectsPath.list}`}}
      hasMap={true}
    >
      {PARSED_REAL_ESTATE_ID && (
        <>
          <RealEstateView
            id={PARSED_REAL_ESTATE_ID}
            blocksIds={blocksIds}
            realEstate={realEstate}
            refetch={refetch}
            remove={remove}
            isFetching={isFetching}
            refetchList={refetchList}
          />
          {/*{application && <ApplicationInfo*/}
          {/*  application={application as ApplicationCardResponse}*/}
          {/*  applicationId={PARSED_REAL_ESTATE_ID}*/}
          {/*  refetch={() => 0}*/}
          {/*/>}*/}
        </>
      )}
    </XYNavLayout>
  );
};

export const ParsedRealEstatePage = ({parsedRealEstateId, onClose , refetchList}: Props): JSX.Element => (
  <>
    {parsedRealEstateId ? (
      <ParsedRealEstatePageRender parsedRealEstateId={parsedRealEstateId} onClose={onClose} refetchList={refetchList}/>
    ) : (
      <SidebarLayout fullSize>
        <ParsedRealEstatePageRender refetchList={refetchList}/>
      </SidebarLayout>
    )}
  </>
);
