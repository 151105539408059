import { VideoLessonsFiltersType } from "../../../../../../../../types";
import { useEffect, useMemo, useState } from "react";
import { api } from "../../../../../../../../service/api";
import { useQuery } from "react-query";

type UseLoadVideoLessons = {
  defaultFilter?: VideoLessonsFiltersType
  defaultTake?: number;
  name?: string
}

export const useGetVideoLessonsList = (
  {
    defaultFilter = {fullTextSearch: ""},
    defaultTake = 30,
    name = "loadVideoLessons",
  }: UseLoadVideoLessons) => {

  const [filter, setFilter] = useState(defaultFilter);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(defaultTake);
  const skip = useMemo(() => (page - 1) * take, [page]);

  const loadVideoLessons = () => api.videoLessons.list({ take, skip, search: defaultFilter, value: "videoLesson" })

  const { data, isLoading, refetch, isFetching, isFetched, isError } = useQuery(
    [name, take, skip, filter],
    loadVideoLessons,
  );

  useEffect(() => {
    setPage(1);
    refetch();
  }, [filter]);

  return {
    list: data?.data?.data || [],
    count: data?.data?.count || 0,
    isLoading,
    isFetching,
    isFetched,
    isError,
    total: data?.data?.total || 0,
    page: data?.data?.page || 1,
    setPage,
    refetch,
    take,
    setTake,
    filter,
    setFilter,
  };
}