import styles from "./index.module.scss"
import { ReactComponent as Alert } from "../../../../../../../../../../../../../../../assets/icons/iconWarnBig.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";

type Props = {
  onApply: () => void
  whoIsClient: "seller" | "buyer" | undefined
}

export const CancelApplicationModal = (
  {
    onApply,
    whoIsClient
  }: Props): JSX.Element => {
const dispatch = useDispatch()
  const onDeny = () => {
    dispatch(closeModal())
  }

  const text = whoIsClient === "seller"
    ? <>
      Данное действие приведет к: <br></br>
      - Заявка попадет в архив <br></br>
      - Реклама объекта будет остановлена <br></br>
      - Статус объекта изменится на “Без активной заявки” <br></br>
      - Назначенные показы будут отменены
  </>
    : <>
      Данное действие приведет к: <br></br>
      - Заявка попадет в архив <br></br>
      - Назначенные просмотры будут отменены
    </>

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Вы уверены, что хотите отказаться от заявки?</div>
          <div className={styles.text}>
            {text}
          </div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button onClick={onApply} classNames={{root: styles.buttonCreate}}>
          Продолжить
        </Button>
      </div>
    </>
  )
}