import { useEffect, useRef, useState } from "react";
import cn from "classnames";

import { IndividualCheckbox, RoundInput } from "../../../index";
import {
	NativeSelect,
	OptionsListWrapper,
	SelectedContainer,
	SelectedWrapper,
	SelectError,
	SelectWrapper,
} from "../components";
import { useClickOutside } from "../../../../../utils/hooks";
import { DefaultSingleSelectProps, SelectOption } from "../../../../../types";

import selectStyles from "../components/selectStyles.module.scss";
import styles from "./index.module.scss";

type GroupedSelectProps = {
	groups: { label: string; options: SelectOption[] }[];
	defaultGroup?: string[];
	customSearchFunction?: (value: string) => void;
} & DefaultSingleSelectProps;

export const GroupedSelect = ({
	name,
	defaultValue,
	groups,
	register,
	setValue,
	size = "normal",
	onChange,
	placeholder,
	error,
	defaultGroup,
	isSearchable,
	classNames,
	append,
	prepend,
	required,
	isLoading,
	disabled,
	hideArrow,
	customSearchFunction,
	withBorder,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
GroupedSelectProps): JSX.Element => {
	const [selectedValue, setSelectedValue] = useState(defaultValue);
	const [selectedGroup, setSelectedGroup] = useState<string[]>();
	const [isListOpen, setListOpen] = useState(false);
	const [filteredOptions, setFilteredOptions] = useState(groups);

	const wrapperRef = useRef<HTMLDivElement>(null);
	useClickOutside({
		ref: wrapperRef,
		onClick: () => setListOpen(false),
	});

	useEffect(() => {
		const element = document.getElementById(name);
		if (element) {
			(element as HTMLInputElement).value = selectedValue as string;
			setValue(name, (element as HTMLInputElement).value);
		}
	}, [selectedValue]);

	const selectItem = (value: string, group: string, label: string) => {
		setSelectedValue(value);
		setSelectedGroup([label, group]);
		setListOpen(false);
		if (onChange) {
			onChange(value);
		}
	};

	const selectTrigger = () => setListOpen((prev) => !prev);

	// Searching by group and label.
	const onSearch = (value: string) => {
		if (customSearchFunction) {
			return customSearchFunction(value);
		}

		const newOptions = groups.map((item) => ({
			label: item.label,
			options: item.options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase())),
		}));

		const result = newOptions.filter((option) => option.options.length).length
			? newOptions.filter((option) => option.options.length)
			: groups.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()));

		return setFilteredOptions(result);
	};

	if (isLoading) {
		return <div className={selectStyles.loading} />;
	}

	return (
		<SelectWrapper
			setListOpen={setListOpen}
			size={size}
			classNames={classNames}
			withBorder={withBorder}
			error={error}
			disabled={disabled}
		>
			<NativeSelect name={name} disabled={disabled} required={required} register={register} options={[]}>
				{filteredOptions.map((item, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<optgroup key={index} label={item.label}>
						{item.options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</optgroup>
				))}
			</NativeSelect>

			<SelectedContainer
				isListOpen={isListOpen}
				onSelectClick={selectTrigger}
				append={append}
				prepend={prepend}
				hideArrow={hideArrow}
				disabled={disabled}
				isLoading={isLoading}
				size={size}
				classNames={classNames}
			>
				<SelectedWrapper placeholder={placeholder} thereIsSelected={!!selectedValue}>
					{`${selectedGroup ? `${selectedGroup[1]} / ` : defaultGroup && `${defaultGroup[1]} / `}
            ${selectedGroup ? selectedGroup[0] : defaultGroup && defaultGroup[0]}`}
				</SelectedWrapper>
			</SelectedContainer>

			<SelectError error={error} classNames={classNames} />

			<OptionsListWrapper
				isListOpen={isListOpen}
				classNames={classNames}
				size={size}
				thereIsOptions={!!groups.length}
				searchChildren={
					isSearchable &&
					groups.length >= 3 && (
						<RoundInput
							className={selectStyles.searchInput}
							name="search"
							onChange={onSearch}
							placeholder="Поиск"
							append="search"
						/>
					)
				}
			>
				<div className={selectStyles.optionsOverflow}>
					{filteredOptions.map((item, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<div key={index} className={styles.groupWrapper}>
							<div className={styles.groupName}>{item.label}</div>

							{item.options.map((option) => (
								<div
									key={option.value}
									onClick={() => selectItem(option.value.toString(), item.label, option.label)}
									className={cn(selectStyles.customOptionNotSelected, {
										[selectStyles.customOptionSelected]: option.value === selectedValue,
									})}
								>
									<IndividualCheckbox
										readOnly
										className={selectStyles.optionCheckbox}
										checked={option.value === selectedValue}
									/>

									{option.label}
								</div>
							))}
						</div>
					))}
				</div>
			</OptionsListWrapper>
		</SelectWrapper>
	);
};
