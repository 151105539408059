import { ReactNode } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Column = {
	render: (item) => ReactNode;
};

type Props<T> = {
	thead?: Array<string>;
	cols: Column[];
	data: T[];
	className?: string;
};

export const TableSimple = <T extends Record<string, unknown>>({
	thead,
	cols,
	data,
	className = "",
}: Props<T>): JSX.Element => (
	<div className={cn(styles.root, className)}>
		<table className={styles.table}>
			{thead && (
				<thead className={styles.thead}>
					<tr>
						{thead.map((th, thId) => (
							// eslint-disable-next-line react/no-array-index-key
							<th key={thId}>{th}</th>
						))}
					</tr>
				</thead>
			)}
			<tbody className={styles.tbody}>
				{data.map((item, trId) => (
					// eslint-disable-next-line react/no-array-index-key
					<tr key={trId}>
						{cols.map((col, tdId) => (
							// eslint-disable-next-line react/no-array-index-key
							<td key={tdId}>{col.render(item)}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	</div>
);
