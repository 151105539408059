import { Dispatch, SetStateAction } from "react";
import { ReactComponent as Telegramm } from "../../../../assets/icons/telegram.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/icons/whatsapp.svg";
import styles from "./index.module.scss";
import { ContactStrap } from "../ContactStrap/ContactStrap";

import {
	CheckboxCell,
	NameCell,
	PhoneCell,
	TypeCell,
	MenuCell,
	ApplicationsCell,
	ResponsibleCell,
} from "./cells";
import { Contact, Phone } from "../../../../types";
import { CardContact } from "../../../UI/Card/card-contact/CardContact";
// contacts
export const useColumns = (
	checked: string[],
	setChecked: Dispatch<SetStateAction<string[]>>,
	contextClick: string,
	refetch: () => void
) => [
	// {
	// 	accessor: "checkbox",
	// 	forceShow: (row: Contact) => checked.includes(row.id),
	// 	showOnHover: true,
	// 	render: ({ row }: { row: Contact }) => (
	// 		<CheckboxCell contact={row} checked={checked} setChecked={setChecked} />
	// 	),
	// },
	// {
		{
			accessor: "contactCard",
			render: ({ row }: { row: Contact, item: string, }) => {
				return (
					<CardContact contact={row} backgroundWhite/>
				);
		},
			
		},
	
		// пока оставил на всякий случай, старый код
	// },
	// {
	// 	accessor: "name",
	// 	render: ({ row }: { row: Contact, item: string, phone: Phone[] }) => {
	// 		return (
	// 			<>
	// 				<NameCell contact={row} />
	// 				{row && row.phones && <PhoneCell item={row?.phones} />}
	// 				<TypeCell item={row.type} contact={row} />
	// 			</>
	// 		);
	// },
		
	// },
	// {
	// 	accessor: "phones",
	// 	render: ({ item}: { item: Phone[] }) => {
	// 		<>
	// 					{/* <NameCell contact={row} /> */}
	// 	<PhoneCell item={item} />
	// 		</>

	
	// },
	// },
	// {
	// 	accessor: "type",
	// 	render: ({ item, row }: { item: string; row: Contact }) => {
	// 	<TypeCell item={item} contact={row} />
	// },
	// },
	// {
	// 	accessor: "requests",
	// 	render: ({ row }: { row: Contact }) => <ApplicationsCell contact={row} />,
	// },
	// {
	// 	accessor: "requestsResponsible",
	// 	render: ({ row }: { row: Contact }) => <ResponsibleCell contact={row} />,
	// },
	// {
	// 	accessor: "menu",
	// 	render: ({ row }: { row: Contact }) => (
	// 		<MenuCell contact={row} contextClick={contextClick} refetch={refetch} />
	// 	),
	// },

];