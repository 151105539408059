import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/user/office-update-photo/DEFAULT_MN";

type UseOfficeUpdatePhoto = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useUpdateLogo = ({ onSuccess, onError }: UseOfficeUpdatePhoto) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: ({ officeId, data }: { officeId: string; data: { avatar: string } }) =>
      api.offices.updatePhoto(officeId, data),
    onSuccess,
    onError,
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
