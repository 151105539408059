import { useState, useMemo } from "react";

import { StagesType } from "../../../../../types";

type UseDisplayStages = {
	stages: StagesType[];
	selectedTab: number;
};
export const useDisplayStages = ({ stages, selectedTab }: UseDisplayStages) => {
	const [wasNotInit, setWasNotInit] = useState(true);

	const initValue = () => {
		if (stages.length <= 4) {
			return stages;
		}

		switch (selectedTab) {
			case 0: {
				return [
					stages[selectedTab],
					stages[selectedTab + 1],
					stages[selectedTab + 2],
					stages[selectedTab + 3],
				];
			}
			case stages.length - 2: {
				return [
					stages[selectedTab - 2],
					stages[selectedTab - 1],
					stages[selectedTab],
					stages[selectedTab + 1],
				];
			}
			case stages.length - 1: {
				return [
					stages[selectedTab - 3],
					stages[selectedTab - 2],
					stages[selectedTab - 1],
					stages[selectedTab],
				];
			}
			default: {
				return [
					stages[selectedTab - 1],
					stages[selectedTab],
					stages[selectedTab + 1],
					stages[selectedTab + 2],
				];
			}
		}
	};

	const displayStages = useMemo((): StagesType[] => {
		if (wasNotInit) {
			setWasNotInit(false);

			return initValue();
		}
		if (stages.length <= 4) {
			return stages;
		}
		if (selectedTab === 0) {
			return [stages[0], stages[1], stages[2], stages[3]];
		}
		if (selectedTab === stages.length - 1 || selectedTab === stages.length - 2) {
			return [
				stages[stages.length - 4],
				stages[stages.length - 3],
				stages[stages.length - 2],
				stages[stages.length - 1],
			];
		}

		return [stages[selectedTab - 1], stages[selectedTab], stages[selectedTab + 1], stages[selectedTab + 2]];
	}, [stages, selectedTab]);

	return {
		displayStages,
	};
};
