import { useMutation } from "react-query";

import { Office } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/office/create-office/DEFAULT_MN";

type UseCreateOffice = {
	onSuccess?: () => void;
};

export const useOfficeCreate = ({ onSuccess }: UseCreateOffice) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (
			data: Omit<Office, "id" | "companyId" | "departments"> & {
				departmentName: string;
			}
		) =>
			api.offices
				.create({
					name: data.name,
					addressId: data.addressId,
					addressLabel: data.addressLabel,
					location: data.location,
				})
				.then((response) =>
					api.departments.create({
						officeId: response.data.id,
						name: data.departmentName,
					})
				),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
