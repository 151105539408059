import { useState } from "react";
import { Pagination } from "@sdelka_crm/sdelka-crm-component-library";
import { RealEstateTabs, SidebarLayout } from "../../UI";
import { AdvertisedObjectsTable, Filters, NeedAttention } from "./components";
import { useLoadApplicationAds } from "../../../service/api/queries";
import { DEFAULT_FILTER } from "../../../service/api/helpers";
import { useFilterAction } from "./helpers";
import { AdStatusEnum } from "../../../types";
import styles from "./index.module.scss";
import { AdListHeader } from "./components/AdvertisedObjectsList/components/header/AdListHeader";
import { AdStatus } from "./helpers/ad-status";

export const AdvertisingCabinet = (): JSX.Element => {
  const [currentStatus, setCurrentStatus] = useState<AdStatusEnum[]>([AdStatusEnum.NotPublished]);

  const {realEstatesList, isFetching, setPage, total, take, page, filter, setFilter, setTake, setSort, sort, counts} =
    useLoadApplicationAds({
      defaultPage: 1,
      options: {keepPreviousData: true},
      defaultFilter: DEFAULT_FILTER,
    });

  const {changeStatus, handlePageChange, changeFilter} = useFilterAction({
    filter,
    setFilter,
    setCurrentStatus,
    setPage,
  });

  const handleFilterClick = (status: AdStatusEnum | string, fieldName: string) => {
    const newSelected = filter?.[ fieldName ] ? [...filter[ fieldName ]] : [];
    if (newSelected && newSelected.includes(status)) {
      const index = newSelected.indexOf(status);
      if (index > -1) {
        newSelected.splice(index, 1);
        setFilter({
          ...filter,
          [ fieldName ]: [...newSelected],
        });
        return;
      }
    }
    newSelected.push(status);
    setFilter({
      ...filter,
      [ fieldName ]: [...newSelected],
    });
  };

  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

  const selectAll = () => {
    if (realEstatesList && !isSelectedAll) {
      setSelectedObjects(realEstatesList.map((realEstate) => realEstate.id));
      setIsSelectedAll(true);
    } else {
      setSelectedObjects([]);
      setIsSelectedAll(false);
    }
  };

  return (
    <SidebarLayout
      fullSize
      hat={{
        element: <Filters filter={filter} changeFilter={changeFilter}/>,
        elementHeight: 50,
      }}
      rightColumn={<NeedAttention/>}
    >
      <div className={styles.root}>
        <div className={styles.tabs}>
          <RealEstateTabs/>
        </div>
        <div className={styles.statusFilter}>
          <div className={styles.statusArea}>
            <AdStatus
              filter={filter}
              handleFilterClick={handleFilterClick}
              setFilter={setFilter}/>
          </div>
        </div>
        <div className={styles.content}>
          <AdListHeader
            total={total}
            take={take}
            setTake={setTake}
            sort={sort}
            setSort={setSort}
            isSelectedAll={isSelectedAll}
            selectAll={selectAll}
            isLoading={isFetching}
            selectedItemsIds={selectedObjects}/>
          <AdvertisedObjectsTable
            objects={realEstatesList}
            isFetching={isFetching}
            counts={counts}
          />
          {!isFetching && total > 0 && (
            <Pagination
              totalCount={total}
              isLoading={isFetching}
              pageSize={take}
              currentPage={page}
              onPageChange={(nextPage: number) => handlePageChange(nextPage)}
              classNames={{root: styles.pagination}}
            />
          )}
        </div>
      </div>
    </SidebarLayout>
  );
};
