// Cadasral code mask `00:00:000000:0000`
export const cadastralCodeMask = (): Array<string | RegExp> => [
	/\d/,
	/\d/,
	":",
	/\d/,
	/\d/,
	":",
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	":",
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];
