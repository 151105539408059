import { UseMutateFunction, useMutation } from "react-query";
import { RealEstateUpdatePayload } from "../../../../types";

import { api } from "../../index";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/real-estate/update-real-estate/DEFAULT_MN";

type Props = {
	onSuccess?: () => void;
	onError?: () => void;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<any>, unknown, RealEstateUpdatePayload, unknown>;
	isLoading: boolean;
};

type UseApplicationRealEstateUpdate = (props: Props) => Return;

export const useApplicationRealEstateUpdate: UseApplicationRealEstateUpdate = ({ onSuccess, onError }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (payload: RealEstateUpdatePayload) => api.applicationRealEstate.update(payload),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
