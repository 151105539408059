import { useQueryParams, useOpenLeadInDrawer } from "../../../../utils/hooks";

type Props = {
	refetch: () => void;
	callbackAfterClose?: (id: string) => void;
};

type Return = {
	handleOpenLead: (id: string) => void;
};

type UseOpenLead = (props: Props) => Return;

export const useOpenLead: UseOpenLead = ({ refetch, callbackAfterClose }) => {
	const { removeQueryParam, changeQueryParams } = useQueryParams<{
		tab: string;
		id: string;
	}>([{ name: "tab" }, { name: "id" }]);

	const { openLeadInDrawer } = useOpenLeadInDrawer({ refetch });

	const handleOpenLead = (id: string) => {
		const beforeOpen = () => {
			changeQueryParams([{ name: "id", newValue: id }]);
		};

		const afterClose = () => {
			removeQueryParam("id");

			if (callbackAfterClose) {
				callbackAfterClose(id);
			}
		};

		openLeadInDrawer({ id, afterClose, beforeOpen });
	};

	return {
		handleOpenLead,
	};
};
