import { useQuery } from "react-query";
import { KindType, WhoIsClient } from "../../../../types";
import { api } from "../../index";

const DEFAULT_QN = "sdelka/application/load-contacts/DEFAULT_QN";

type UseLoadContacts = {
  applicationId: string;
  type: WhoIsClient;
  enabled?: boolean;
  kind?: KindType;
};

export const useLoadContacts = ({ applicationId, type, enabled = true, kind = KindType.APPLICATION }: UseLoadContacts) => {
  let data;
  let refetch;
  let remove;
  let isLoading;
  let isFetched;

  if (kind === KindType.APPLICATION) {
    const loadContacts = () => api.application.contacts({ applicationId, type });
    const queryResult = useQuery([DEFAULT_QN + type, type, applicationId], loadContacts, { enabled });
    data = queryResult.data?.data;
    refetch = queryResult.refetch;
    remove = queryResult.remove;
    isLoading = queryResult.isLoading;
    isFetched = queryResult.isFetched;
  }

  if (kind === KindType.PREAPPLICATION) {
    const loadContacts = () => api.PreApplicationService.contacts({ applicationId, type });
    const queryResult = useQuery([DEFAULT_QN + type, type, applicationId], loadContacts, { enabled });
    data = queryResult.data?.data;
    refetch = queryResult.refetch;
    remove = queryResult.remove;
    isLoading = queryResult.isLoading;
    isFetched = queryResult.isFetched;
  }
  if (kind === KindType.DEAL) {
    const loadContacts = () => api.deals.contacts({ applicationId, type });
    const queryResult = useQuery([DEFAULT_QN + type, type, applicationId], loadContacts, { enabled });
    data = queryResult.data?.data;
    refetch = queryResult.refetch;
    remove = queryResult.remove;
    isLoading = queryResult.isLoading;
    isFetched = queryResult.isFetched;
  }
  return {
    data,
    isLoading,
    refetch,
    remove,
    isFetched,
  };
};
