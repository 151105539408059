import "./Balloon.scss";

type Props = {
	header?: JSX.Element;
	body?: JSX.Element;
	footer?: JSX.Element;
};

export const Balloon = ({ footer, header, body }: Props): JSX.Element => (
	<div className="balloon-container" id="balloon-container">
		<div>{header}</div>

		<div className="balloon-container__context">{body}</div>

		<div className="balloon__footer">{footer}</div>
	</div>
);
