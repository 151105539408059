import { useDispatch } from "react-redux";
import { Lead } from "../../../../../../types";
import { closeModal, openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss"
import { useJoinToSdelkaPro } from "service/api/mutations/auth/useJoinToSdelkaPro";
import { closeDrawer } from "service/redux/modules/drawer/drawer.action";
import { useHistory } from "react-router-dom";
import { Join } from "./Join";



// eslint-disable-next-line @typescript-eslint/naming-convention
// type UseOpenRelatedLeadModal = (props: Props) => Return

export const JoinSdelkaProModal =() => {
  const dispatch = useDispatch()
  const openJoinSdelkaProModal = () => {
    dispatch(
      openModal({
        width: 660,
        body:
          <Join />
      })
    )
  }
  return {
    openJoinSdelkaProModal
  }
}