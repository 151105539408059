import styles from "./index.module.scss"

export const PhotosPlug = () => {
  return (
    <div className={styles.root}>
      <div className={styles.text}>
        Фотографии объекта не добавлены
      </div>
    </div>
  )
}