import { InfoRow } from "../../../../../../../../../../UI";

type Props = {
	squares: string;
	postfix?: string;
};

export const RoomsSquareList = ({ squares, postfix = "" }: Props): JSX.Element => {
	const squaresArray = squares.split(" ");

	if (!squaresArray[0]) {
		return <></>;
	}

	return (
		<>
			{squaresArray.map((square, index) => (
				<InfoRow
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					label={`${index + 1} комната`}
					aspect={{
						label: 6,
						content: 7,
					}}
				>
					{`${square}${postfix ? " " : ""}${postfix}`}
				</InfoRow>
			))}
		</>
	);
};
