import { CollectionObject, ObjectCompareAdd, ObjectCompareRemove } from "../../../../types";

import { Client } from "../../Client";

const SERVICE_URL = "/client-objects/object-compare";

export const ObjectCompareService = {
	getObjects: (applicationId) => Client.get<CollectionObject[]>(`${SERVICE_URL}/${applicationId}`),
	addObject: ({ comparedApplicationId, clientObjectApplicationIds, parserObjectIds }: ObjectCompareAdd) =>
		Client.post<CollectionObject>(`${SERVICE_URL}/${comparedApplicationId}/`, {
			clientObjectApplicationIds,
			parserObjectIds,
		}),
	deleteObject: ({ comparedApplicationId, objectId }: ObjectCompareRemove) =>
		Client.delete(`${SERVICE_URL}/${comparedApplicationId}/${objectId}`),
	deleteUnactual: (comparedApplicationId) =>
		Client.delete(`${SERVICE_URL}/${comparedApplicationId}/unactualObjects`),
};
