import { UserData } from "../../../types";
import cn from "classnames";

import { prepareShortUserOutput } from "../../../utils/transformation";

import styles from "./index.module.scss";
import { ReactComponent as ArrowSvg } from "../../../assets/icons/arrow-right.svg";

type Props = {
	creator?: Partial<UserData>;
	responsible?: Partial<UserData>;
	className?: string;
};

export const CreatorToResponsible = ({ creator, responsible, className }: Props): JSX.Element => {
	const creatorLabel = creator
		? prepareShortUserOutput({
				name: creator.name ?? "",
				lastName: creator.lastName ?? "",
				secondName: creator.secondName,
		  }).trim()
		: "Система";

	const responsibleLabel = prepareShortUserOutput({
		name: responsible?.name ?? "",
		lastName: responsible?.lastName ?? "",
		secondName: responsible?.secondName,
	}).trim();

	return (
		<div className={cn(styles.root, className)}>
			<span>{creatorLabel}</span>

			{responsible && (
				<>
					<ArrowSvg className={styles.arrow} />
					<span>{responsibleLabel}</span>
				</>
			)}
		</div>
	);
};
