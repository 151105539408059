import { useMutation } from "react-query";
import { ShowsViewsFinishPayload } from "../../../../types";

import { api } from "../../api";

const DEFAULT_MN = "sdelka/shows-views/finish-show-view/DEFAULT_MN";

type UseFinishShowOrView = {
	onSuccess?: (data: any) => void;
};

export const useFinishShowOrView = ({ onSuccess }: UseFinishShowOrView) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: ShowsViewsFinishPayload) => api.showsViews.finish(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
