import { useDebouncedCallback } from "use-debounce";

import { useUpdateLead } from "../../../../../../service/api/mutations";

type Props = {
	leadId: string;
};

type Return = {
	saveComment: (comment: string) => void;
	isLoading: boolean
};

type UseUpdateLeadComment = (props: Props) => Return;

export const useUpdateLeadComment: UseUpdateLeadComment = ({ leadId }) => {
	const { mutate, isLoading } = useUpdateLead({});

	const saveComment = useDebouncedCallback((comment: string) => {
		const payload = {
			id: leadId,
			data: {
				comment,
			},
		};

		mutate(payload);
	}, 0);

	return {
		saveComment,
		isLoading
	};
};
