import { useState } from "react";
import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";

import { InfoRow } from "../../../../../../UI";
import { NoData } from "./components";

import styles from "./index.module.scss";

type Props = {
	officialData: any;
};

export const BuildingInfo = ({ officialData }: Props): JSX.Element => {
	const [active, setActive] = useState(0);

	if (!officialData) {
		return <NoData />;
	}

	// eslint-disable-next-line no-param-reassign
	delete officialData.url;

	const tabs = Object.keys(officialData);

	const officialDataKey = tabs[active];

	return (
		<div className={styles.root}>
			<Tabs tabs={tabs} active={active} onChange={setActive} size="small" maxWidth={720} offset={20}/>

			{officialData[officialDataKey] &&
				Object?.keys(officialData[officialDataKey]).map((subOfficialDataKey, index) => (
					<div key={subOfficialDataKey} className={styles.buildingBlock}>
						{officialData[officialDataKey][subOfficialDataKey] &&
						typeof officialData[officialDataKey][subOfficialDataKey] === "object" ? (
							<>
								{!Array.isArray(officialData[officialDataKey][subOfficialDataKey]) && (
									<div className={styles.title}>{Object.keys(officialData[officialDataKey])[index]}</div>
								)}

								{Object.keys(officialData[officialDataKey][subOfficialDataKey]).map(
									(subSubOfficialDataKey) => (
										<InfoRow
											key={subSubOfficialDataKey}
											label={subSubOfficialDataKey}
											aspect={{ label: 10, content: 7 }}
										>
											{officialData[officialDataKey][subOfficialDataKey][subSubOfficialDataKey]}
										</InfoRow>
									)
								)}
							</>
						) : (
							<InfoRow label={subOfficialDataKey} aspect={{ label: 10, content: 7 }}>
								{officialData[officialDataKey][subOfficialDataKey] || "Нет данных"}
							</InfoRow>
						)}
					</div>
				))}
		</div>
	);
};
