import React from "react";
import styles from "./index.module.scss";
import { PlatformSupportData } from "../../../../../../../../../../../types";

type Props = {
	supportData: PlatformSupportData & {
		id: string;
	};
};

export const SupportData = ({ supportData }: Props): JSX.Element => {
	if (
		supportData.manager === undefined &&
		supportData.profileNum === undefined &&
		supportData.id === undefined
	)
		return <React.Fragment />;
	return (
		<div className={styles.supportData}>
			{supportData.manager !== undefined ? (
				<div className={styles.supportDataManagerInfo}>
					<p>Ваш менеджер:</p>
					<div>
						<p>
							{supportData?.manager?.lastName} {supportData?.manager?.firstName}
						</p>
						<p>{supportData?.telNum}</p>
						<p>доб. номер {supportData?.addNum}</p>
					</div>
				</div>
			) : (
				<div />
			)}
			<div className={styles.supportDataIdContainer}>
				{supportData?.profileNum !== undefined && (
					<div className={styles.supportDataId}>
						<span>Id компании: </span>
						<span>Id {supportData?.profileNum}</span>
					</div>
				)}
				{supportData?.id && (
					<div className={styles.supportDataId}>
						<span>Id объявления: </span>
						<span>Id {supportData?.id}</span>
					</div>
				)}
			</div>
		</div>
	);
};
