import { Dispatch, SetStateAction } from "react";
import { Contact, Kind, Phone, UserPreview } from "../../../../../../types";
import { UserListCards } from "components/UI/ApplicationComponents/UserList/components";
import styles from "./style.module.scss";
import classNames from "classnames";
import { LoadingRows } from "components/pages/Authentication";

type Props = {
  contacts: Contact[];
  isLoading: boolean;
  selectedContact: Contact | undefined;
  setSelectedContact: Dispatch<SetStateAction<Contact | undefined>>;
  isCreation?: boolean
};
type UserListItem = {
  id: string;
  name: string;
  lastName: string;
  secondName?: string | null | undefined;
  pseudonym?: string | null | undefined;
  avatar?: string | undefined;
  phones?: Phone[];
  type?: string | undefined;
  relatedKinds?: {
    applications: Kind[];
    deals: Kind[];
    preApplications: Kind[];
  };
  callData?: {
    type: "in" | "out" | "chat";
    status: "success" | "missed" | "busy" | "not_available";
    start: Date;
  }
  lastCommunication?: {
    type: "in" | "out" | "chat";
    status: "success" | "missed" | "busy" | "not_available";
    start: Date;
  } | null
  responsibleUser?: UserPreview
  iAmResponsibleUser?: boolean
};

export const ContactList = (
  {
    contacts,
    isLoading,
    selectedContact,
    setSelectedContact,
    isCreation
  }: Props): JSX.Element => {

  const mapContactToUserListItem = (contact: Contact): UserListItem => {
    return {
      id: contact.id,
      name: contact?.name,
      lastName: contact?.lastName,
      secondName: contact?.secondName,
      pseudonym: contact?.pseudonym,
      phones: contact?.phones,
      type: contact?.type,
      relatedKinds: contact.relatedKinds,
      callData: contact.callData,
      responsibleUser: contact.responsibleUser,
      iAmResponsibleUser: contact.iAmResponsibleUser,
      lastCommunication: contact.lastCommunication
    };
  };

  const handleClick = (el) => {
    if (isCreation) {
      return
    }
    setSelectedContact(el)
  }

  return (
    <>
      {isLoading ?
        <div>
          <LoadingRows/>
        </div> :
        <>
          <div className={styles.listContainer}>
            {contacts.map((el) => (
              <div
                className={classNames(styles.list, {
                  [ styles.selected ]: selectedContact?.id === el.id,
                })}
                onClick={() => handleClick(el)}
              >
                <UserListCards
                  data={[mapContactToUserListItem(el)]}
                  mini={true}
                  atModal

                />
              </div>
            ))}
          </div>
        </>}
    </>
  );
};
