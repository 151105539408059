import { Button, P } from "@sdelka_crm/sdelka-crm-component-library";

import { useCopyText } from "../../../../index";

import styles from "./index.module.scss";

type Props = {
	presentationLink: string;
	disabledGenerateButton: boolean;
};

export const LinkGenerator = ({ presentationLink, disabledGenerateButton }: Props): JSX.Element => {
	const { onCopy } = useCopyText({});

	const openLink = () => window.open(presentationLink, "_blank", "noopener noreferrer");

	return (
		<>
			<P variant="medium" classNames={{ root: styles.rootP }}>
				Ссылка на онлайн презентацию
			</P>

			{!presentationLink ? (
				<Button type="submit" variant="text" disabled={disabledGenerateButton}>
					Сгенерировать
				</Button>
			) : (
				<div className={styles.generatedLink}>
					<P variant="large">{presentationLink}</P>

					<Button variant="text" onClick={() => onCopy(presentationLink)}>
						Скопировать
					</Button>

					<Button variant="text" onClick={openLink}>
						Перейти
					</Button>
				</div>
			)}
		</>
	);
};
