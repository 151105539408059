import { ApplicationInList, ApplicationInListV2, ApplicationsFilter, KindType } from "../../../../../types";

import { TableApplication } from "../../../../UI";
import { StageTitle } from "../StageTitle";
import { useOpenApplicationCard } from "../../../../../utils/application";
import { useBlinkRow } from "../../../../../utils/hooks";

import styles from "./index.module.scss";


type Props = {
  requestsList: ApplicationInListV2[];
  filter?: ApplicationsFilter;
  isLoading: boolean;
  withStage: boolean;
  total?: number;
  totalCounts?: Record<string, number>;
  refetchApplications?: () => void;
  kind?:KindType
};

export const DealsTable = ({
                                   requestsList,
                                   filter,
                                   isLoading,
                                   withStage,
                                   total,
                                   totalCounts,
                                   refetchApplications,
                                   kind
                                 }: Props): JSX.Element => {
  const {rowsToBlink, callback} = useBlinkRow();
  const {handleClickApplication} = useOpenApplicationCard({
    callbackAfterClose: (id: string) => callback(id), kind
  });

  const handleTableRowClick = (row: ApplicationInListV2) => {
    const {
      id,
      type: {whoIsClient},
      shortType: {kind}
    } = row;

    handleClickApplication({
      applicationId: id,
      initialTab: 0,
      whoIsClient,
      kind,
      refetch: refetchApplications,
    });
  };

  const getColor = ((application) => {
    
    switch (application.type.kind) {
      case 'preApplication':
        return "#D5D3CE";
      case  'application':
        return "#FFE254"
      case 'deal':
        return "#7F8DEF"
      default:
        return "#000"
    }
  });

  return (
    <div className={styles.wrapper}>
      {requestsList?.length > 0 || isLoading ? (
        <TableApplication
          data={requestsList}
          filter={filter}
          kind={kind}
          groupKey="stage"
          withStage={withStage}
          tableRowClick={handleTableRowClick}
          transformGroupTitle={(title, data) => (
            <StageTitle stageNum={data?.stageNo || 1} color={getColor(data)}>
              {title}
              {totalCounts && total ? (
                <span className={styles.counts}>{` (${totalCounts[ title ]}/${total})`}</span>
              ) : null}
            </StageTitle>
          )}
          isLoading={isLoading}
          refetchApplications={refetchApplications}
          blinkRows={rowsToBlink}
        />
      ) : (
        <div className={styles.empty}>Похожих заявок не найдено</div>
      )}
    </div>
  );
};
