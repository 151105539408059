import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { KindType, Task } from "../../../../types";

import { CreateTaskModal } from "../../../../components/UI";
import { openModal } from "../../../../service/redux/modules/modal/modal.action";

import styles from "./index.module.scss";

type Props = {
	applicationId?: string;
	kind: KindType
	onCreate?: (data: AxiosResponse<Task>) => void;
};

export const useCreateTaskModal = ({ applicationId, onCreate, kind }: Props) => {
	const dispatch = useDispatch();

	const openCreateTaskModal = () =>
		dispatch(
			openModal({
				title: <h3 className={styles.title}>Создать задачу</h3>,
				body: <CreateTaskModal applicationId={applicationId} onCreate={onCreate} kind={kind} />,
				width: 800,
				classNames: {
					contentWrapper: styles.contentWrapperClassName,
					wrapper: styles.wrapperClassName,
					contentBody: styles.contentBody,
				},
			})
		);

	return { openCreateTaskModal };
};
