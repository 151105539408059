import { createContext, ReactNode, useContext } from "react";
import { useLoadTasks } from "../../api/queries";

type UseLoadTasksReturns = ReturnType<typeof useLoadTasks>;

/**
 * TasksAttentionContextType is a return type of "useLoadTasks", but without
 *  fields that can break invariant of a context.
 *
 * For example, "setFilter" must be forbidden, because attention tasks have the exact filter
 *  "requiresAttention"
 * */
type TasksAttentionContextType = Omit<UseLoadTasksReturns, "setFilter">;

const TasksAttentionContext = createContext<TasksAttentionContextType | null>(null);

type Props = {
	children: ReactNode;
};

export const TasksAttentionContextProvider = ({ children }: Props): JSX.Element => {
	const value = useLoadTasks({
		defaultFilter: {
			requiresAttention: true,
		},
	});

	return <TasksAttentionContext.Provider value={value}>{children}</TasksAttentionContext.Provider>;
};

export const useTasksAttentionContext = (): TasksAttentionContextType => {
	const context = useContext(TasksAttentionContext);
	if (context === null) {
		throw new Error("Tasks attention context must be inside values");
	}

	return context;
};
