import { TaskVirtualStatus } from "../../../../../../../../../../types";

export const GroupTranslationEnum: Record<TaskVirtualStatus, string> = Object.freeze({
	inWork: "В работе",
	missed: "Просроченные",
	hot: "Горящие",
	success: "Успешные",
	failed: "Неуспешные",
	checking: "На проверке",
	paused: "На паузе",
});
