import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { RealEstateAdDescriptionModal } from "./RealEstateAdDescriptionModal";
import { useLoadPlatformDescription } from "../../../../../../../../../service/api/queries";
import { closeModal, openModal } from "../../../../../../../../../service/redux/modules/modal/modal.action";

import styles from "./RealEstateAdDescriptionModal/index.module.scss";

type HookArguments = {
	applicationId: string;
	feedId: string;
	error?: boolean;
};

type HookReturnObject = {
	onModalOpen: () => void;
};

export const useAdDescriptionModal = ({
	applicationId,
	feedId,
	error = false,
}: HookArguments): HookReturnObject => {
	const [currentFeedId, setFeedId] = useState("");
	const { refetch, data, isLoading, remove, isFetched, isError } = useLoadPlatformDescription({
		applicationId,
		feedId,
	});
	const dispatch = useDispatch();

	const onClose = () => {
		setFeedId("");
		remove();
	};

	useEffect(() => {
		if (!isFetched) return;

		if (isFetched && isError) {
			dispatch(closeModal());

			return;
		}

		if (feedId !== currentFeedId) return;
		dispatch(
			openModal({
				body: (
					<RealEstateAdDescriptionModal
						price={data?.price}
						pricePerUnit={data?.pricePerUnit}
						feedId={feedId}
						unit={data?.unit}
						square={data?.square}
						icon={data?.icon}
						error={error}
						data={{
							id: applicationId,
							adTitle: data?.adTitle || "",
							adDescription: data?.adDescription || "",
							adDescriptionSignatureId: data?.adDescriptionSignatureId || "",
						}}
						remove={remove}
						dispatch={dispatch}
						applicationId={applicationId}
					/>
				),
				classNames: {
					root: isLoading ? styles.modalLoading : styles.modalWrapper,
					contentBody: styles.modal,
				},
				onClose,
			})
		);
	}, [isFetched]);

	const onModalOpen = () => {
		setFeedId(feedId);
		dispatch(
			openModal({
				body: <Loader height={450} />,
			})
		);
		refetch();
	};

	return {
		onModalOpen,
	};
};
