import { useDispatch } from "react-redux";
import { TaskModal } from "../../../components/UI";
import { openModal } from "../../../service/redux/modules/modal/modal.action";

type Props = {
  onUpdatedTask: () => void;
  refetchParent?: () => void
  isLast?: boolean
};

export const useOpenTaskModal = ({onUpdatedTask, refetchParent, isLast}: Props) => {
  const dispatch = useDispatch();

  const handleOpenTaskModal = (id: string) => {
    dispatch(
      openModal({
        body: <TaskModal id={id} onUpdatedTask={onUpdatedTask} refetchParent={refetchParent} isLast={isLast}/>,
        width: 800,
      })
    );
  };

  return {handleOpenTaskModal};
};
