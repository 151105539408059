import { useMutation } from "react-query";
import { api } from "../../api";
import { IRequestPrice } from "../../../../types";

const DEFAULT_MN = "sdelka/compilation/use-request-price/DEFAULT_MN";

type Props = {
  payload: IRequestPrice
}

export const useRequestPrice = ({payload}: Props) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: () => api.cart.requestPrice(payload),
  });

  return {
    data: mutate.data?.data,
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};
