import { useMutation } from "react-query";
import { ShowsViewsCancelPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/shows-views/cancel-show-view/DEFAULT_MN";

type UseCancelShowOrView = {
	onSuccess?: (id: any) => void;
};

export const useCancelShowOrView = ({ onSuccess }: UseCancelShowOrView) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (id: string) => api.showsViews.cancel(id),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
