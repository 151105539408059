import { useHistory } from "react-router";

import { useQueryParams } from "../../../../../../../../../utils/hooks";

type QueryParams = {
	backPath: string;
};

type Return = {
	handleOnReturnToBack: () => void;
};

type UseReturnToBack = () => Return;

export const useReturnToBack: UseReturnToBack = () => {
	const { push } = useHistory();

	const { queryParams } = useQueryParams<QueryParams>([{ name: "backPath", type: "string" }]);

	const handleOnReturnToBack = () => {
		if (queryParams.backPath) {
			const backPath = decodeURIComponent(queryParams.backPath);
			push(backPath);
		}
	};

	return {
		handleOnReturnToBack,
	};
};
