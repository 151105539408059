import { ReactElement, ReactNode } from "react";

import { Logo, LogoColor } from "../../../../assets/iconsJSX";
import styles from "./index.module.scss";

interface Props {
	children: ReactNode;
	leftColumn: ReactElement;
	logoColor?: LogoColor;
	hideLogo?: boolean;
}

export const SplitScreenLayout = ({ children, logoColor, leftColumn, hideLogo }: Props): JSX.Element => (
	<div className={styles.root}>
		<div className={styles.leftColumn}>
			{leftColumn}
		</div>

		<div className={styles.rightColumn}>{children}</div>
	</div>
);
