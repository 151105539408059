import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router";

import styles from "./index.module.scss";
import { BuildingInfo } from "components/UI/RealEstate/RealEstateForm/ObjectEditLayout/components";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as Edit } from "assets/icons/iconEdit.svg";
import { usePutAdditionalProperties } from "service/api/queries/deals/usePutAdditionalProperties";
type Props = {
	form: any;
	editFields: any;
  viewFields: any;
  blockTitle: string;
  blockName: string
  id:string
  refetch?: ()=>void;
  formData
};

export const Block = ({
  form, editFields, viewFields, blockTitle, blockName, id, formData
}: Props): JSX.Element => {

const [isEdit, setIsEdit] = useState(false)
const { mutate, isLoading, data:putData } = usePutAdditionalProperties({
   data:{additionalProperties:form.getValues().additionalProperties},
  id: id
   });
const putAD = () => {
	mutate();
};

const saveHandler = () => {
  putAD()
  setIsEdit(false)
}
const discardChangesHandler = () => {
  setIsEdit(false)
}

	return (
		<div className={styles.root}>
      <div className={styles.header}>
        <h2>{blockName}</h2>
        {!isEdit &&
        <Button Icon={Edit} variant="text" onClick={() => setIsEdit(true)}>
          Редактировать
        </Button>
        }
      </div>
      {isEdit ? 
      // <BuildingInfo form={form} fields={putData ? putData :editFields }/> :
      // <BuildingInfo form={form} fields={putData ? putData :viewFields} />}
      <BuildingInfo form={form} fields={editFields }/> :
      <BuildingInfo form={form} fields={viewFields} />}
      {isEdit && 
      <div className={styles.buttons}>
        <Button variant="outlined" onClick={discardChangesHandler}>
          Отменить
        </Button>
        <Button onClick={saveHandler}>
          Сохранить
        </Button>
      </div>}
		</div>
	);
};
