import { Control, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { createNumberMask } from "text-mask-addons";

import { InfoRow, RadioButton, TransparentInput } from "../../../../index";

import styles from "./index.module.scss";

type Props = {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	register: UseFormRegister<any>;
	name: [string, string];
	disabled?: boolean;
};

export const Metro = ({ control, setValue, register, name, disabled }: Props): JSX.Element => {
	const metroMaxMinutesWatcher = useWatch({
		control,
		name: name[1],
		defaultValue: "",
	});

	const metroPlacementOptions = [
		{
			value: undefined,
			label: "Неважно",
			disabled,
		},
		{
			value: "Пешком",
			label: "Пешком",
			disabled,
		},
		{
			value: "Транспортом",
			label: "Транспортом",
			disabled,
		},
	];

	return (
		<div className={styles.metroWrapper}>
			<RadioButton name={name[0]} size="small" options={metroPlacementOptions} control={control} />
			<InfoRow aspect={{ label: 8, content: 7.3 }} label="Не более">
				<div className={styles.metroInputWrapper}>
					<TransparentInput
						placeholder="000"
						name={name[1]}
						register={register}
						className={styles.metroInput}
						variant="noPadding"
						textAlign="right"
						mask={createNumberMask({
							prefix: "",
							integerLimit: 3,
						})}
						setValueForMask={setValue}
						value={metroMaxMinutesWatcher}
					/>
					Минут
				</div>
			</InfoRow>
		</div>
	);
};
