import { ReactNode } from "react";
import cn from "classnames";

import { selectAccounts, useSelect } from "../../../../service/redux/selectors";
import { AlertRedLine } from "./components";
import { Sidebar } from "./Sidebar";
import { ScrollBtn } from "./ScrollBtn";
import { useScroll } from "./helpers";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	fullSize?: boolean;
	services?: boolean
	background?: "white" | "blue";
	hat?: {
		element: ReactNode;
		elementHeight: number;
	};
	rightColumn?: ReactNode;
	withScrollBtn?: boolean;
};

export const SidebarLayout = ({
	children,
	fullSize,
	services,
	background = "blue",
	hat,
	rightColumn,
	withScrollBtn = false,
}: Props): JSX.Element => {
	const { isAsEmployee } = useSelect(selectAccounts);
	const { showScrollBtn, scrollTop, scrollWrapper } = useScroll({
		withScrollBtn,
	});

	return (
		<div className={styles.wrapper}>
			<div className={styles.root}>
				<Sidebar />

				<div
					className={cn(styles.container, styles[`background__${background}`], {
						[styles.containerFullSize]: !fullSize,
						[styles.containerServices]: services,
					})}
				>
					{isAsEmployee && <AlertRedLine />}

					{hat && (
						<>
							<div
								className={cn(styles.header, {
									[styles.headerWithRedLine]: isAsEmployee,
								})}
							>
								{hat.element}
							</div>

							<div style={{ height: hat.elementHeight }} />
						</>
					)}

					<div ref={scrollWrapper}>{children}</div>

					{withScrollBtn && <ScrollBtn onClick={scrollTop} showScrollBtn={showScrollBtn} />}
				</div>
			</div>

			{rightColumn}
		</div>
	);
};
