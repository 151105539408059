import { Dispatch, SetStateAction } from "react";
import cn from "classnames";
import { ReactComponent as TimerIcon } from "../../../../../assets/icons/iconNotRequested.svg";
import { ReactComponent as CheckedIcon } from "../../../../../assets/icons/iconPublished.svg";
import { ReactComponent as Archive } from "../../../../../assets/icons/archive-2.svg";
import styles from "./index.module.scss";
import { ButtonsFuncReturnType } from "../../../LeadsAndDeals/components/StatusFilter/components/helpers/types";
import { adStatuses, AdStatusesEnumV2, RealEstateFilter } from "../../../../../types";

export const adButtons = (
  curFilter: { status?: AdStatusesEnumV2[] },
  setCurFilter: Dispatch<SetStateAction<RealEstateFilter | any>>,
  filterClick: (val: any) => void
): ButtonsFuncReturnType => {

  const handleSetFilter = (clickedStatus: AdStatusesEnumV2) => {
    filterClick(clickedStatus);
  };

  return [
    {
      classNames: cn(styles.button, styles.buttonPoop, {
        [ styles.selected ]: curFilter.status?.includes(AdStatusesEnumV2.WAITING),
      }),
      onClick: () => handleSetFilter(AdStatusesEnumV2.WAITING),
      child: <TimerIcon/>,
      popoverText: adStatuses.Waiting,
    },
    {
      classNames: cn(styles.button, styles.buttonPoop, {
        [ styles.selected ]: curFilter.status?.includes(AdStatusesEnumV2.POSTED),
      }),
      onClick: () => handleSetFilter(AdStatusesEnumV2.POSTED),
      child: <CheckedIcon/>,
      popoverText: adStatuses.Posted,
    },
    {
      classNames: cn(styles.button, styles.buttonPoop, {
        [ styles.selected ]: curFilter.status?.includes(AdStatusesEnumV2.NOT_POSTED),
      }),
      onClick: () => handleSetFilter(AdStatusesEnumV2.NOT_POSTED),
      child: <Archive/>,
      popoverText: adStatuses.NotPosted,
    },
  ];
};
