import cn from "classnames";

import { DefaultSelectProps } from "../../../../../../types";

import selectStyles from "../selectStyles.module.scss";

type Props = Pick<DefaultSelectProps, "error" | "classNames">;

export const SelectError = ({ error, classNames }: Props): JSX.Element => (
	<>{error && <div className={cn(selectStyles.error, classNames?.error)}>{error}</div>}</>
);
