import { useMutation } from "react-query";
import { ShowsViewsFinishPayload } from "../../../../types";

import { api } from "../../api";

const DEFAULT_MN = "sdelka/shows-views/make-show-view-final/DEFAULT_MN";


type Props = {
  onSuccess?: () => void;
}

export const useMakeShowOrViewFinal = ({onSuccess}: Props) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (id: string) => api.showsViews.makeFinal(id),
    onSuccess
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
