import cn from "classnames";
import { useEffect } from "react";
import { useForm, WatchObserver } from "react-hook-form";
import { Subscription } from "react-hook-form/dist/utils/createSubject";
import { useDebouncedCallback } from "use-debounce";

import { Checkbox } from "../../../../../../../../UI";
import { filters } from "./helpers";
import { TaskLeftMenuFilter } from "../../../../../../../../../types";
import { useTasksContext } from "../../../../../../../../../service/context/Tasks";
import { FiltersGroup } from "./components";

import styles from "./index.module.scss";

type FieldValues = Record<TaskLeftMenuFilter, boolean>;

type Props = {
	className?: string;
};

export const TasksLeftFilters = ({ className }: Props): JSX.Element => {
	const { filter: globalFilter, setFilter } = useTasksContext();

	const { control, watch, handleSubmit, setValue, getValues, reset } = useForm<FieldValues>();

	const prepareValues: WatchObserver<FieldValues> = (checks, target) => {
		if (target.type !== "change") return;

		if (target.name === "returned" && checks?.returned) {
			reset();
			setValue("returned", checks?.returned);
		} else setValue("returned", false);
	};

	const submit = () => {
		const checks = getValues();

		const checkedItems = Object.keys(checks).filter((key) => checks[key]) as TaskLeftMenuFilter[];

		const hasCheckedAny = checkedItems.length > 0;
		const status = hasCheckedAny ? checkedItems : undefined;

		setFilter({
			...globalFilter,
			status,
		});
	};

	const submitDebounced = useDebouncedCallback(submit, 500);

	const onChange: WatchObserver<FieldValues> = (...args) => {
		prepareValues(...args);
		submitDebounced();
	};

	useEffect(() => {
		const subscription: Subscription = watch(onChange);

		return () => subscription.unsubscribe();
	}, [handleSubmit, watch]);

	return (
		<div className={cn(styles.root, className)}>
			<div className={styles.content}>
				{filters.map((filter) =>
					filter.subfilters ? (
						<FiltersGroup
							key={filter.name}
							allFilters={filters}
							filter={filter}
							control={control}
							getValues={getValues}
							setValue={setValue}
						/>
					) : (
						<Checkbox
							key={filter.name}
							control={control}
							name={filter.name}
							label={filter.label}
							checkboxClassName={styles.checkbox}
							labelTextClassName={styles.checkboxLabel}
						/>
					)
				)}
			</div>
		</div>
	);
};
