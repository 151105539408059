import { Dispatch, SetStateAction, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { CardObject, RoundInput } from "../../../index";
import { useLoadApplicationRealEstates } from "../../../../../service/api/queries";
import { CONDITIONS_WITHOUT_APPLICATIONS } from "../../../../../utils/clientObjects";
import { Conditions, RealEstate } from "../../../../../types";

import styles from "./index.module.scss";

type Props = {
  setSelectedElement: Dispatch<SetStateAction<RealEstate | undefined>>;
  isObjectWithoutApplication?: boolean;
};

type ListProps = {
  realEstatesList: RealEstate[];
  handleSelectObject: (id: string) => void;
  selectedObject?: RealEstate;
};

const PinObjectTabList = ({realEstatesList, selectedObject, handleSelectObject}: ListProps) => {

 return (
    <>
      { realEstatesList.length > 0 ? realEstatesList.map((object) => (
        <div key={object.id} className={styles.objectItemWrapper}>
          <CardObject
            id={object.id}
            cardBackground="white"
            clientObject={object}
            selectedId={selectedObject?.id || undefined}
            onCardClick={handleSelectObject}
            options={{hideFluctuations: true}}
            variant="standard"
            atPinTab
            selected={selectedObject?.id === object.id}
          />
        </div>
      ))
      : <div className={styles.noFound}>
          Ничего не найдено
        </div>
      }
    </>
  );
}

export const PinObjectTab = ({setSelectedElement, isObjectWithoutApplication}: Props): JSX.Element => {
  const [selectedObject, setSelectedObject] = useState<RealEstate | undefined>(undefined);
  const absoluteSearchConditions: Conditions = isObjectWithoutApplication
    ? CONDITIONS_WITHOUT_APPLICATIONS
    : [];
  const {realEstatesList, isLoading, setFilter} = useLoadApplicationRealEstates({
    absoluteSearchConditions,
  });


  const handleSelectObject = (id: string) => {
    const foundObject = realEstatesList.find((object) => object.id === id);
    setSelectedObject(foundObject);
    setSelectedElement(foundObject);
  };

  const debouncedSetFilter = useDebouncedCallback(setFilter, 200);

  const onSearch = (val: string) =>
    debouncedSetFilter({
      values: {
        addressLabel: val,
        fullTextSearch: val,
      },
    });

  return (
    <div className={styles.root}>
      <RoundInput
        className={styles.search}
        name="addressLabel"
        prepend="search"
        onChange={onSearch}
        placeholder="Поиск"
      />
      <div className={styles.objectListWrapper}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader height={50}/>
          </div>
        ) : (
          <PinObjectTabList
            realEstatesList={realEstatesList}
            selectedObject={selectedObject}
            handleSelectObject={handleSelectObject}
          />
        )}
      </div>
    </div>
  );
};
