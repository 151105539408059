import {
	ResetPassStepsAction,
	SET_CURRENT_STEP,
	SET_VERIFICATION_CODE,
	SET_PHONE,
	CLEAN_UP,
	SET_JWT_TOKEN,
} from "./reset-pass-steps.action";
import { ResetPassStepsTInitialState } from "../../types";

const initialState: ResetPassStepsTInitialState = {
	currentStep: "phone-verification",
};

export const resetPassStepsReducer = (
	state = initialState,
	action: ResetPassStepsAction
): ResetPassStepsTInitialState => {
	switch (action.type) {
		case SET_CURRENT_STEP:
			return {
				...state,
				currentStep: action.step,
			};
		case SET_VERIFICATION_CODE:
			return {
				...state,
				verificationCode: action.code,
			};
		case SET_PHONE:
			return {
				...state,
				phone: action.phone,
			};
		case CLEAN_UP:
			return {
				...state,
				currentStep: "phone-verification",
				verificationCode: undefined,
				phone: undefined,
				token: undefined,
			};
		case SET_JWT_TOKEN: {
			return {
				...state,
				token: action.token,
			};
		}
		default:
			return state;
	}
};
