import { useQuery } from "react-query";

import { api } from "../../index";
import { useQueryParams } from "utils/hooks";

const DEFAULT_QN = "sdelka/application/load-employee/DEFAULT_QN";

type UseLoadEmployee = {
	applicationId: string;
	enabled?: boolean;
};

export const useLoadEmployee = ({ applicationId, enabled = true }: UseLoadEmployee) => {
	const { queryParams } = useQueryParams<{
		tab: string;
	}>([{ name: "tab" }]);
	const { tab } = queryParams;
	
	const loadEmployee = () => {
		if (tab==="deals") {
			return api.deals.employee({ applicationId });
		}
		return api.application.employee({ applicationId });
	}

	const { data, refetch, remove, isLoading, isFetched } = useQuery(DEFAULT_QN, loadEmployee, { enabled });

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
	};
};
