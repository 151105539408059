import styles from "./index.module.scss";

type Props = {
  ownerComment?: string;
};

export const OwnerComment = ({ownerComment}: Props): JSX.Element => (
  <div className={styles.root}>
    <div className={styles.title}>Описание объявления</div>

    <div className={styles.text}>{ownerComment ?? "У объявления нет описания."}</div>
  </div>
);
