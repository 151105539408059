import { useMemo, useState } from "react";
import cn from "classnames";
import { differenceInMinutes } from "date-fns";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ButtonClose } from "../../../../UI";
import { useInviteUpdate } from "../../../../../service/api/mutations";
import { formatPhone } from "../../../../../utils/formatters";
import { requestDateCalculator } from "../../../../../utils/date";
import { invitationUrlBase } from "../../../../../utils/constants";
import { getStatus } from "../../helpers/getStatus";
import { Invite, Office, Position } from "../../../../../types";

import { ReactComponent as Clock } from "../../../../../assets/icons/clock.svg";
import { ReactComponent as Copy } from "../../../../../assets/icons/copy.svg";
import { ReactComponent as Fire } from "../../../../../assets/icons/fire.svg";
import { ReactComponent as Waiting } from "../../../../../assets/icons/waiting.svg";
import styles from "./index.module.scss";

type Props = {
	invite: Invite;
	offices?: Office[];
	positions?: Position[];
};

// Invite card which user can approve or decline
export const InviteApprove = ({ invite, offices, positions }: Props): JSX.Element => {
	const difference = differenceInMinutes(new Date(), new Date(invite.createdAt));

	const { mutateAsync } = useInviteUpdate({});
	const [status, setStatus] = useState(getStatus(difference));

	const allDepartments = useMemo(() => offices?.map((office) => office.departments), [offices])?.flat();

	const currentDepartment = useMemo(
		() => allDepartments?.find((department) => department.id === invite.departmentId),
		[offices]
	);

	const currentOffice = useMemo(
		() => offices?.find((office) => currentDepartment && office.departments.includes(currentDepartment)),
		[offices]
	);

	const currentPosition = useMemo(
		() => positions?.find((position) => position.id === invite.positionId),
		[positions]
	);

	const declineRequest = () => {
		const payload = {
			active: false,
		};

		mutateAsync({ id: invite.id, data: payload })
			.then(() => setStatus("deleted"))
			.catch(() => setStatus(getStatus(difference)));
	};

	const revertDecline = () => {
		const payload = {
			active: true,
		};

		mutateAsync({ id: invite.id, data: payload })
			.then(() => setStatus(getStatus(difference)))
			.catch(() => setStatus("deleted"));
	};

	return (
		<div className={cn(styles.root, styles[`status__${status}`])}>
			<div className={styles.inviteBlock}>
				<span className={styles.overflowText}>{currentPosition?.name}</span>
			</div>

			<div className={styles.inviteBlock}>
				<span className={styles.overflowText}>
					{currentOffice?.name} /{currentDepartment?.name}
				</span>
			</div>

			<div className={cn(styles.inviteBlock, styles.phoneBlock)}>
				<span className={styles.userPhone}>
					{invite.phone.match(/^\s+$/) ? invite.phone : formatPhone(invite.phone)}
				</span>
			</div>

			{status !== "deleted" && (
				<div className={styles.inviteBlock}>
					{status === "default" && <Clock className={styles.inviteIcon} />}
					{status === "warning" && <Waiting className={styles.inviteIcon} />}
					{status === "hot" && <Fire className={styles.inviteIcon} />}

					<span className={styles.days}>{requestDateCalculator(new Date(invite.createdAt))}</span>

					<Button
						variant="outlined"
						Icon={Copy}
						classNames={{ root: styles.linkBtn }}
						onClick={() => navigator.clipboard.writeText(`${invitationUrlBase}${invite.link}`)}
					>
						Ссылка
					</Button>

					<ButtonClose classNames={{ root: styles.decline }} onClick={declineRequest} />
				</div>
			)}

			{status === "deleted" && (
				<div className={styles.inviteBlock}>
					<span className={styles.declined}>Вы отклонили заявку на вступление</span>

					<Button
						type="button"
						variant="text"
						classNames={{ root: styles.revert }}
						onClick={() => revertDecline()}
					>
						Восстановить
					</Button>
				</div>
			)}
		</div>
	);
};
