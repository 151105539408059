import { PointType } from "../Map";
import { Point } from "../Point";

interface Props {
	points?: PointType[];
	getContactPhone?: (objectId: string) => Promise<string>;
	onPointClick?: (point: PointType) => void;
	selectPointWithObjects?: (point: PointType) => void;
	clickOnTitle?: (id?: string) => void;
	priceType: string;
}

/*
 * Компонент отображает точки недвижимости.
 */
export const PointsInCluster = ({
	points,
	getContactPhone,
	onPointClick,
	selectPointWithObjects,
	clickOnTitle,
	priceType,
}: Props): JSX.Element => (
	<>
		{points?.map((point, index) => (
			// @ts-ignore
			<Point
				getContactPhone={getContactPhone}
				onPointClick={onPointClick}
				selectPointWithObjects={selectPointWithObjects}
				clickOnTitle={clickOnTitle}
				// eslint-disable-next-line react/no-array-index-key
				key={`${point.lat}${point.lon}${index}`}
				point={point}
				priceType={priceType}
			/>
		))}
	</>
);
