import { UseFormReturn, useWatch } from "react-hook-form";
import { Button, Underlay } from "@sdelka_crm/sdelka-crm-component-library";

import { CalendarRangeInPopup, Checkbox, InfoRow, TransparentInput } from "../../../index";
import { useUpdateApplication } from "../../../../../service/api/mutations";
import { commonPlaceholders } from "../../../../../utils/placeholders";
import { Application, ApplicationUpdatePayload, KindType } from "../../../../../types";

import styles from "./index.module.scss";

const CHECKBOX_NAME = "isAgentDocument";

type AgentDocumentFormProps = {
	applicationId?: string;
	form: UseFormReturn<any>;
	isAgentDocumentExist: boolean;
	refetch: () => void;
};

export const AgentDocumentForm = ({
	applicationId,
	form,
	isAgentDocumentExist,
	refetch,
}: AgentDocumentFormProps): JSX.Element => {
	const [nameFrom, nameTo] = ["agentDocumentDateFrom", "agentDocumentDateTo"];

	const { handleSubmit, control, register, setValue, getValues, watch, formState } = form;

	const [fromWatcher, toWatcher] = [
		useWatch({
			control,
			name: nameFrom,
			defaultValue: getValues(nameFrom) || null,
		}),
		useWatch({
			control,
			name: nameTo,
			defaultValue: getValues(nameTo) || null,
		}),
	];

	const isDefaultDateNull = fromWatcher && toWatcher;

	const defaultDate = isDefaultDateNull ? [fromWatcher, toWatcher] : undefined;

	const { mutate, isLoading } = useUpdateApplication({
		onSuccess: () => refetch(),
		kind: KindType.APPLICATION
	});

	const checkboxValue = watch(CHECKBOX_NAME);

	const onSubmit = (
		values: Pick<Application, "agentDocumentDateFrom" | "agentDocumentDateTo" | "agentDocumentPercent">
	) => {
		const { agentDocumentDateFrom, agentDocumentDateTo, agentDocumentPercent } = values;

		const payload: ApplicationUpdatePayload = {
			applicationId: applicationId || "",
			applicationData: {
				agentDocumentDateFrom,
				agentDocumentDateTo,
				agentDocumentPercent,
			},
		};

		mutate(payload);
	};

	return (
		<Underlay className={styles.root}>
			{!isAgentDocumentExist && (
				<Checkbox name={CHECKBOX_NAME} control={control} label="Агентский договор заключен" />
			)}

			{(isAgentDocumentExist || checkboxValue) && (
				<form onSubmit={handleSubmit(onSubmit)}>
					{!isAgentDocumentExist && <div className={styles.divider} />}

					<InfoRow label="Срок действия договора">
						<CalendarRangeInPopup
							classNames={{ trigger: styles.popupTrigger }}
							noAutoDates
							defaultDate={defaultDate}
							form={{
								register,
								setValue,
								getValues,
								name: [nameFrom, nameTo],
								required: true,
							}}
						/>

						{(formState.errors[nameFrom] || formState.errors[nameTo]) && (
							<div className={styles.error}>Обязательное поле</div>
						)}
					</InfoRow>

					<InfoRow label="Комиссия по договору">
						<TransparentInput
							withCleanUp={{ setValue }}
							placeholder={commonPlaceholders.notSpecified}
							name="agentDocumentPercent"
							register={register}
							error={formState?.errors?.agentDocumentPercent?.message?.toString()}
						/>
					</InfoRow>

					<div className={styles.submitContainer}>
						<Button variant="text" type="submit" isLoading={isLoading}>
							{isAgentDocumentExist ? "Сохранить изменения" : "Сохранить"}
						</Button>
					</div>
				</form>
			)}
		</Underlay>
	);
};
