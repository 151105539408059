import cn from "classnames";

import styles from "./index.module.scss";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/arrow-in-round.svg";

type Props = {
	onClick: () => void;
	showScrollBtn: boolean;
};

export const ScrollBtn = ({ onClick, showScrollBtn }: Props): JSX.Element => (
	<span className={cn(styles.root, { [styles.show]: showScrollBtn })}>
		<ArrowIcon onClick={onClick} />
	</span>
);
