import styles from "./index.module.scss"
import { ReactComponent as Alert } from "../../../../../../../../assets/icons/iconWarnBig.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../service/redux/modules/modal/modal.action";
import { useCancelShowOrView } from "../../../../../../../../service/api/mutations";

type Props = {
  isViews: boolean
  showViewId: string
  onSuccess: () => void;
}

export const CancelShowWiewModal = ({isViews, showViewId, onSuccess}: Props) => {
  const dispatch = useDispatch()
  const labelText = isViews ? "просмотра" : "показа"
  const {mutate} = useCancelShowOrView({onSuccess})

  const onApply = () => {
    mutate(showViewId)
    dispatch(closeModal())
  }

  const onDeny = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>
            {`Отказавшись от ${labelText}, вы не сможете его редактировать и сделать финальным`}
          </div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button onClick={onApply} classNames={{root: styles.buttonCreate}}>
          Отказаться
        </Button>
      </div>
    </>
  )
}