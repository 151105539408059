import { CSSProperties } from "react";
import cn from "classnames";

import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import styles from "./index.module.scss";

type Props = {
	onCloseClick: () => void;
	jsStyles?: CSSProperties;
	classNames?: {
		root?: string;
	};
};

export const ButtonCloseBlack = ({ onCloseClick, classNames, jsStyles }: Props): JSX.Element => (
	<div onClick={onCloseClick} className={cn(styles.root, classNames?.root)} style={jsStyles}>
		<CloseIcon />
	</div>
);
