import { SidebarLayout } from "../../UI";
import { TasksContent, TasksTopFilters } from "./components";
import { TasksContextProvider } from "../../../service/context/Tasks";

const hat = {
	element: <TasksTopFilters />,
	elementHeight: 50,
};

export const Tasks = (): JSX.Element => (
	<TasksContextProvider>
		<SidebarLayout fullSize withScrollBtn hat={hat}>
			<TasksContent />
		</SidebarLayout>
	</TasksContextProvider>
);
