import { useMutation } from "react-query";

import { api } from "../../api";

const DEFAULT_MN = "sdelka/shows-views/view/DEFAULT_MN";

type UseCreateShowOrView = {
  onSuccess?: () => void;
};

export type Payload = {
  date: Date,
  clientObjectId?: string
  parserId?: string
  applicationId: string
}

export const useCreateView = ({onSuccess}: UseCreateShowOrView) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (payload: Payload) =>
     api.showsViews.createView(payload.applicationId, payload.date, payload.clientObjectId, payload.parserId),
    onSuccess,
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
