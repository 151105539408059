import { memo } from "react";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { Rate } from "../../index";
import { formatPhone } from "../../../../utils/formatters";
import { SocialLink } from "../../../../types";

import { ReactComponent as InstagramIcon } from "../../../../assets/icons/instagram.svg";
import { ReactComponent as VkIcon } from "../../../../assets/icons/vk.svg";
import { ReactComponent as ChatIcon } from "../../../../assets/icons/chat.svg";
import { ReactComponent as ShareIcon } from "../../../../assets/icons/share.svg";
import { ReactComponent as PersonIcon } from "../../../../assets/icons/person.svg";
import styles from "./index.module.scss";

type Props = {
	background?: "white" | "blue";
	size?: "default" | "medium";
	image?: string;
	company: string;
	account: {
		firstName: string;
		lastName: string;
		secondName?: string;
	};
	phone: string;
	socialLinks: SocialLink[];
	rating: number;
};

const iconByType = {
	vk: <VkIcon />,
	instagram: <InstagramIcon />,
};

export const CardAgent = memo(
	({
		image,
		company,
		account,
		phone,
		socialLinks,
		rating,
		background = "white",
		size = "default",
	}: Props): JSX.Element => (
		<div className={cn(styles.root, styles[background], styles[size])}>
			<div className={styles.image}>{image ? <img src={image} alt="_image" /> : <PersonIcon />}</div>

			<div className={styles.body}>
				<div className={styles.header}>
					<div className={styles.company}>{company}</div>

					<div className={styles.rating}>
						<div>{rating}</div>
						<Rate allowRate={false} rate={rating} />
					</div>
				</div>

				<div className={styles.fullName}>
					<div className={styles.lastName}>{account.lastName}</div>

					<div className={styles.restName}>
						{account.firstName} {account.secondName}
					</div>
				</div>

				<div className={styles.phone}>{formatPhone(phone)}</div>

				<div className={styles.socials}>
					{socialLinks.map((link) => (
						<div
							key={`${link.link}_${link.type}`}
							onClick={() => window.open(link.link, "_blank")}
							className={styles.link}
						>
							{iconByType[link.type]}
						</div>
					))}
				</div>

				<div className={styles.footer}>
					<Button color="blue-disabled" Icon={ChatIcon}>
						Написать в чат
					</Button>

					<div className={styles.share}>
						<span>Поделиться</span>
						<ShareIcon />
					</div>
				</div>
			</div>
		</div>
	)
);
