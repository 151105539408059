import { useGetOffice } from "../../../service/api/mutations";
import { MainInfo } from "./components/main";
import { Departments } from "./components/departments/Departments";
import { OfficeCardStickyFooter } from "./components/office-card-sticky-footer/OfficeCardStickyFooter";

type Props = {
  officeId: string;
  refetch?: () => void;
};

export const OfficeCard = ({officeId, refetch}: Props): JSX.Element => {
  const {office, isFetching, refetch: RefetchOffice} = useGetOffice({officeId})
  const refetchAll = () => {
    refetch?.();
    RefetchOffice?.();
  };

  return (
    <>
      <div style={{padding: "16px 40px", minHeight: "100%"}}>
        {office &&
          <>
            <MainInfo office={office} refetch={refetchAll} canEdit={true}/>
            <Departments office={office} refetch={refetchAll} canEdit={true}/>
          </>
        }
      </div>
      <OfficeCardStickyFooter office={office} refetch={refetchAll} canEdit={true}/>
    </>

  )
}