import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/business-process/pre-application/DEFAULT_QN";

type Props = {
	applicationId: string;
	stage: string;
	enabled: boolean;
};

type Return = {
	data: any;
	isLoading: boolean;
	refetch: () => void;
	remove: () => void;
	isFetched: boolean;
	isFetching: boolean;
};

type UseLoadCardBlocks = (props: Props) => Return;

export const useLoadCardBlocksPreApplications: UseLoadCardBlocks = ({ applicationId, stage, enabled }) => {

	const loadCardBlocks = () => api.PreApplicationService.getCardBlocks({ applicationId, stage });

	const { data, refetch, remove, isLoading, isFetched, isFetching } = useQuery(
		[DEFAULT_QN, stage],
		loadCardBlocks,
		{
			enabled,
			keepPreviousData: true,
		}
	);

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
		isFetching,
	};
};
