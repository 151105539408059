import { useEffect, useState } from "react";
import { RealEstateWithSchema } from "../../../../../types";

import { FixedNavProps, FixedNavVerticalMenu } from "../../../../UI";
import { useQueryParams } from "../../../../../utils/hooks";
import { scrollIntoView } from "../../../../../utils/scroll";
import { ReactComponent as Edit } from "../../../../../assets/icons/edit-page.svg";

type BlockIdType = {
	common: string;
	info: string;
	description: string;
	maybe: string;
	official: string;
};

type Props = {
	realEstate?: RealEstateWithSchema;
	isNew?: boolean;
	isParser?: boolean;
	isDisableEdit?: boolean;
};

type Return = {
	activeNav: FixedNavProps;
	onAdNav: (
		platforms: Array<{
			name: string;
			id: string;
		}>
	) => void;
	navigationY: FixedNavVerticalMenu[];
	blocksIds: BlockIdType;
	setNavigationY: React.Dispatch<React.SetStateAction<FixedNavVerticalMenu[]>>;
	setActiveNav: React.Dispatch<React.SetStateAction<FixedNavProps>>;
};
type UseGetNavigation = (props: Props) => Return;

export const useGetNavigationPreApplication: UseGetNavigation = ({ realEstate }) => {

	const {
		queryParams: { mode },
		changeQueryParams,
	} = useQueryParams<{ mode: string }>([{ name: "mode" }]);

	const handleChangeTab = (newTab: string) => changeQueryParams([{ name: "mode", newValue: newTab }]);

	const blocksIds = {
		common: "common-block-object-view",
		info: "info-block-object-view",
		description: "description-block-object-view",
		maybe: "maybe-block-object-view",
		official: "official-block-object-view",
	};

	const navigationCommon = {
		navigation: [
			{ label: "Общая", action: () => scrollIntoView(blocksIds.common) },
			{ label: "Информация", action: () => scrollIntoView(blocksIds.info) },
			{
				label: "Описание",
				action: () => scrollIntoView(blocksIds.description),
				hidden: !!realEstate?.parserData,
			},
			// Hide while is not ready
			// { label: 'Подходящие заявки', action: () => document.getElementById(blocksIds.maybe)?.scrollIntoView() },
			{
				label: "Официальные ресурсы",
				action: () => scrollIntoView(blocksIds.official),
			},
		],
		additionalLabel: realEstate?.shortId ? `id ${realEstate.shortId}` : "",
	};
  const edit = {
    id: "edit",
    label: <Edit />,
    changeTab: () => handleChangeTab("edit"),
    height: 90,
  }
  const view = {
    id: "view",
    label: "Объект",
    changeTab: () => handleChangeTab("view"),
    height: 130,
  }
  const leads = {
    id: "pre-applications",
    label: "Предзаявка",
    changeTab: () => handleChangeTab("pre-applications"),
    height: 180,
  }
	const [navigationY, setNavigationY] = useState<FixedNavVerticalMenu[]>([]);

  useEffect(()=>{
    mode==="pre-applications" && setNavigationY([leads])
    mode==="view" && setNavigationY([leads, view])
    mode==="edit" && setNavigationY([leads, view, edit])
    
  }, [mode, setNavigationY])
	const navigationMap = {
		view: navigationCommon,
		documents: undefined,
		edit: undefined,
	};

	const [activeNav, setActiveNav] = useState<FixedNavProps>(navigationMap.view);

	const onAdNav = (platforms: Array<{ name: string; id: string }>) =>
		setActiveNav({
			navigation: [
				{
					label: "Общая",
					action: () => document.getElementById("ad_common")?.scrollIntoView(),
				},
				...platforms.map((i) => ({
					label: i.name,
					action: () => document.getElementById(i.id)?.scrollIntoView(),
				})),
				{
					label: "Бесплатные",
					action: () => document.getElementById("ad_free")?.scrollIntoView(),
				},
				{
					label: "Платные",
					action: () => document.getElementById("ad_non_free")?.scrollIntoView(),
				},
			],
		});

	return {
		activeNav,
    onAdNav,
    navigationY,
    blocksIds,
    setNavigationY,
		setActiveNav
	};
};
