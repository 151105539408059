import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ParsedRealEstatePage } from "../../../../components/pages/RealEstatePage";
import { changeCurrentTab, openDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { useQueryParams } from "../../index";
import styles from "./index.module.scss";
import { ApplicationCard } from "../../../../components/pages/Application";
import { KindType } from "../../../../types";
import { PreApplicationTemplate } from "../../../../components/pages/Application/PreApplicationTemplate";
import { XYNavLayout } from "../../../../components/UI";
import { useGetNavigation } from "../../../../components/pages/RealEstatePage/helpers";
import { useLoadApplicationRealEstate } from "../../../../service/api/queries";

type Queries = {
  mode: string;
  applicationId: string;
  parserRealEstateId: string;
  preApplicationId: string
  tab: string
};

type Props = {
  onClose?: () => void;
  isParser?: boolean;
  refetch?: () => void
  kind?: KindType
  refetchList?: () => void
};

type Return = {
  handleOpenDrawerRealEstate: (id: string) => void;
};

type UseOpenDrawerRealEstate = (props: Props) => Return;

export const useOpenDrawerRealEstate: UseOpenDrawerRealEstate = ({onClose, isParser, refetch, kind, refetchList}) => {

  const {queryParams, changeQueryParams, removeQueryParam} = useQueryParams<Queries>([
    {name: "tab"},
    {name: "mode"},
    {name: "applicationId"},
    {name: "preApplicationId"},
    {name: "filter"},
    {name: "parserRealEstateId"},
  ]);

  const {mode, tab, applicationId, preApplicationId , parserRealEstateId} = queryParams

  const dispatch = useDispatch();
  const handleClose = () => {
    removeQueryParam(["mode", "applicationId", "parserRealEstateId"]);
    if (onClose) {
      onClose();
    }
  };

  const {realEstate} = useLoadApplicationRealEstate({
    id: isParser ? parserRealEstateId : (kind === KindType.APPLICATION ? applicationId : preApplicationId),
    kind: KindType.APPLICATION
  });
  const {activeNav, navigationY} = useGetNavigation({
    realEstate,
  });

  const onTab = (newTabIndex: number) => {
    dispatch(changeCurrentTab({newCurrentTab: newTabIndex}));
  };

  const openApplicationRealEstate = (id: string) => {

    const label = () => {
      let label = ""
      if (kind === KindType.APPLICATION) {
        return label = "Заявка"
      }
      if (kind === KindType.PREAPPLICATION) {
        return label = "Предзаявка"
      }
      if (kind === KindType.DEAL) {
        return label = "Сделка"
      }
    }
    const navigation = [{label: label(), height: 160}];

    const yNavigation = {
      navigation,
      initialTab: 0,
    };

    removeQueryParam("filter")
    changeQueryParams([
      {name: "tab", newValue: "AGENCY"},
      {name: "mode", newValue: queryParams.mode || "view"},
    ]);

    if (kind === KindType.APPLICATION) {
      changeQueryParams([
        {name: "applicationId", newValue: id},
      ]);
      dispatch(changeCurrentTab({newCurrentTab: 0}))
      dispatch(
        openDrawer({
          hasMap: true,
          onClose: () => {
            removeQueryParam(["applicationId"])
          },
          width: 800,
          children:
          <>
            <XYNavLayout
              yNavigation={navigationY}
              yNavigationTop={64}
              activeYTab={mode}
              navVariant="grey"
            >
            </XYNavLayout>
            <ApplicationCard
              applicationId={id}
              inDrawer
              kind={KindType.APPLICATION}
              onTab={onTab}
              isFromObjectList={true}
            />,
          </>
        }),
      );
    }
    if (kind === KindType.PREAPPLICATION) {
      changeQueryParams([{name: "preApplicationId", newValue: id}])
      dispatch(changeCurrentTab({newCurrentTab: 0}))
      dispatch(
        openDrawer({
          children: [
            <PreApplicationTemplate applicationId={id} isFromLead={false} isFromObjectList/>
          ],
          width: 800,
          onClose: () => {
            removeQueryParam(["preApplicationId"])
            dispatch(changeCurrentTab({newCurrentTab: 0}))
          },
          yNavigation,
        })
      );
    }
  };

  const openParsedRealEstate = (id: string) => {
    changeQueryParams([{name: "tab", newValue: "PARSED"}, {name: "parserRealEstateId", newValue: id}]);
    dispatch(
      openDrawer({
        hasMap: true,
        width: 800,
        children: <ParsedRealEstatePage parsedRealEstateId={id} onClose={handleClose} refetchList={refetchList}/>,
        classNames: {
          rightBlock: styles.drawerRightBlock,
          wrapper: styles.drawerWrapper,
        },
      })
    );
  };

  const handleOpenDrawerRealEstate = (id: string) =>
    isParser ? openParsedRealEstate(id) : openApplicationRealEstate(id);

  return {handleOpenDrawerRealEstate};
};
