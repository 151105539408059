import styles from "./index.module.scss";

export const BannersSkeleton = (): JSX.Element => (
	<div className={styles.root}>
		{[1, 2, 3].map((item) => (
			<div key={item} className={styles.block}>
				<div className={styles.header} />
				<div className={styles.blockItem} />
			</div>
		))}
	</div>
);
