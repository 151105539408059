import { UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Subway, MultiLayerSelectOption } from "../../../../../../types";

import {
	AddressSelect,
	CardParsedObject,
	InfoRow,
	MultiLayerMetroSelect,
	TransparentInput,
} from "../../../../../UI";
import { commonPlaceholders } from "../../../../../../utils/placeholders";
import { cadastralCodeMask } from "../../../../../../utils/masks";

import fakeImgSrc from "../../../../../../assets/images/fake-object-img.png";
import styles from "./index.module.scss";

const fakeParsedObj = {
	title: "ЖК «Новая свежайшая Перспектива»",
	address: "Россия, Москва, Фестивальная улица, 39",
	tags: [],
};

type Props = {
	register: UseFormRegister<any>;
	watch: UseFormWatch<any>;
	setValue: UseFormSetValue<any>;
	subways: Subway[];
};

export const Address = ({ register, watch, setValue, subways }: Props): JSX.Element => {
	const [watchCadastralCode, watchSubways, watchAddress] = watch([
		"cadastralCode",
		"subways",
		"addressObject",
	]);

	const setSubwayValue = (name: string, selected: MultiLayerSelectOption[]) => {
		setValue(
			name,
			selected.map((layer) => ({
				...layer,
				label: {
					name: layer.value,
				},
			}))
		);
	};

	return (
		<>
			<InfoRow label="Адрес">
				<AddressSelect
					placeholder="Введите адрес"
					name="addressObject"
					hideArrow
					value={watchAddress}
					register={register}
					setValue={setValue}
					classNames={{ input: styles.placeholder }}
				/>
			</InfoRow>

			<InfoRow label="№ квартиры" labelFont="ubuntu">
				<TransparentInput
					register={register}
					name="flatNumber"
					placeholder={commonPlaceholders.notSpecified}
				/>
			</InfoRow>

			<InfoRow label="Метро" labelFont="ubuntu">
				<MultiLayerMetroSelect
					subways={subways}
					name="subways"
					register={register}
					setValue={setSubwayValue}
					defaultValue={watchSubways}
					placeholder="Выбрать"
					classNames={{ root: styles.metro }}
				/>
			</InfoRow>

			<InfoRow label="Кадастровый номер" labelFont="ubuntu">
				<TransparentInput
					register={register}
					name="cadastralCode"
					mask={cadastralCodeMask}
					setValueForMask={setValue}
					value={watchCadastralCode}
					placeholder={commonPlaceholders.cadastralCode}
					guide={false}
				/>
			</InfoRow>

			<CardParsedObject
				title={fakeParsedObj.title}
				address={fakeParsedObj.address}
				tags={fakeParsedObj.tags}
				img={fakeImgSrc}
				classNames={{
					address: styles.parsedAddress,
					title: styles.parsedTitle,
					image: styles.parsedImg,
					wrapper: styles.parsedObject,
				}}
			/>
		</>
	);
};
