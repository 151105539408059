import { useEffect } from "react";
import { OpenApplicationInDrawer } from "../../../../../../../../types";

import { useQueryParams } from "../../../../../../../../utils/hooks";

type Props = {
	openApplicationDrawerInDrawer: ({ id, isTemplate }: OpenApplicationInDrawer) => void;
};

type UseAutoOpenApplication = (props: Props) => void;

export const useAutoOpenApplication: UseAutoOpenApplication = ({ openApplicationDrawerInDrawer }) => {
	const {
		queryParams: { applicationId },
	} = useQueryParams<{ applicationId: string }>([{ name: "applicationId" }]);

	useEffect(() => {
		if (applicationId) {
			openApplicationDrawerInDrawer({
				id: applicationId,
				isTemplate: true,
			});
		}
	}, []);
};
