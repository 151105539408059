export const arrayIsEqual = (firstArray?: Array<any>, secondArray?: Array<any>): boolean => {
	if (!(firstArray && secondArray)) {
		return false;
	}

	if (firstArray.length !== secondArray.length) {
		return false;
	}

	return firstArray.reduce((prev, cur, index) => Boolean(prev && cur === secondArray[index]), true);
};
