import { Dispatch, SetStateAction } from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { createNumberMask } from "text-mask-addons";
import { RealEstateFilter, RealEstateListTab } from "../../../../../../../../../types";
import cn from "classnames";

import { DoubleInput, MultiCheckbox } from "../../../../../../../../UI";

import styles from "../../index.module.scss";

type Props = {
  control: Control<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
  filter: RealEstateFilter;
  globalFilter: RealEstateFilter;
  flatTypesOptions: { value: string; label: string }[];
  tab: RealEstateListTab;
};

export const RoomShareOptional = ({
                                    control,
                                    register,
                                    setValue,
                                    getValues,
                                    setFilter,
                                    filter,
                                    globalFilter,
                                    flatTypesOptions,
                                    tab,
                                  }: Props): JSX.Element => {
  const onChangeRoomsType = (roomsType: string[]) => setFilter({...globalFilter, ...filter, roomsType});

  const [nameRoomsFrom, nameRoomsTo, flatTypeName, nameFrom, nameTo] = [
    "roomsFrom",
    "roomsTo",
    "additionalProperties.planirovka",
    "roomSquareFrom",
    "roomSquareTo",
  ];

  const [watchFlatType, watchRoomSquareFrom, watchRoomSquareTo, watchRoomsFrom, watchRoomsTo] = [
    useWatch({
      control,
      name: flatTypeName,
      defaultValue: getValues(flatTypeName) || [],
    }),
    useWatch({
      control,
      name: nameFrom,
      defaultValue: getValues(nameFrom) || "",
    }),
    useWatch({
      control,
      name: nameTo,
      defaultValue: getValues(nameTo) || "",
    }),
    useWatch({
      control,
      name: nameRoomsFrom,
      defaultValue: getValues(nameRoomsFrom) || "",
    }),
    useWatch({
      control,
      name: nameRoomsTo,
      defaultValue: getValues(nameRoomsTo) || "",
    }),
  ];

  return (
    <div className={styles.root}>
      <div className={cn(styles.itemWrapper, styles.flatType)}>
        <MultiCheckbox
          name={flatTypeName}
          register={register}
          setValue={setValue}
          options={flatTypesOptions}
          onChange={onChangeRoomsType}
          defaultValue={watchFlatType || []}
        />
      </div>

      <div className={cn(styles.itemWrapper, styles.start)}>
        <span className={styles.inputTitleFlatSquare}>Пл. м2:</span>
        <div>
          <DoubleInput
            names={[nameFrom || "", nameTo || ""]}
            register={register}
            font="ubuntu"
            textAlign="right"
            setValue={setValue}
            size="unFixed"
            placeholders={["от", "до"]}
            masks={[
              createNumberMask({
                prefix: "",
                thousandsSeparatorSymbol: " ",
              }),
              createNumberMask({
                prefix: "",
                thousandsSeparatorSymbol: " ",
              }),
            ]}
            value={[watchRoomSquareFrom, watchRoomSquareTo]}
          />
        </div>
      </div>

      {/* TODO: make this */}
      <div className={cn(styles.itemWrapper, styles.start)}>
        <span className={styles.inputTitleFlat}>Комнат</span>

        <div>
          <DoubleInput
            value={[watchRoomsFrom || "", watchRoomsTo || ""]}
            names={[nameRoomsFrom, nameRoomsTo]}
            register={register}
            font="ubuntu"
            textAlign="right"
            setValue={setValue}
            size="unFixed"
            placeholders={["от", "до"]}
            masks={[
              createNumberMask({
                prefix: "",
                thousandsSeparatorSymbol: " ",
              }),
              createNumberMask({
                prefix: "",
                thousandsSeparatorSymbol: " ",
              }),
            ]}
          />
        </div>
      </div>
    </div>
  );
};
