import { SelectOption } from "../inputs";

export enum ProductTypeEnum {
  service = 'service',
  employerSubscribtion = 'employerSubscribtion'
}

export type CartPayload = {
  productType?: ProductTypeEnum,
  id: string,
  amount?: number,
  tariff?: string
}


export type CartItemProduct = {
  id: string
  photo: string // заглушку присылает бек т.к. для разного типа товара они разные
  title: string
  description?: string
  tariff: string
  tariffOptions: SelectOption[]
  amount: number
  totalPrice: number
  productType: ProductTypeEnum
}

export type CartResponse = {
  products: CartItemProduct[]
  employersCount: number
  discount: number
  currentPromoCode?: string
  fullPrice: number
  priceWithDiscount?: number
  paymentUrl: string
}

export enum SubscriptionRenewalPeriod {
  year = "365",
  "180days" = "180",
  "90days" = "90",
  "60days" = "60",
}

export type IRequestPrice = {
  productType: ProductTypeEnum
  userIds?: string[]
  subscriptionRenewalPeriod?: SubscriptionRenewalPeriod
  tariff?: string
  serviceId?: string
}

export type IRequestPriceResponse = {
  price: number
  totalPrice: number
}


