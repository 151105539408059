import { useDispatch } from "react-redux";
import { openDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { OfficeCard } from "../OfficeCard";

type Return = {
  openOfficeDrawer: (officeId: string) => void;
};

type Props = {
  onClose?: () => void;
  refetch?: () => void;
};

type UseOfficeCard = (props: Props) => Return;

export const useOpenOfficeCard: UseOfficeCard = ({onClose, refetch}) => {
  const dispatch = useDispatch();

  const openOfficeDrawer = (officeId: string) => {
    dispatch(
      openDrawer({
        children: <OfficeCard officeId={officeId} refetch={refetch}/>,
        width: 800,
        onClose,
      })
    );
  };

  return {
    openOfficeDrawer,
  };
};
