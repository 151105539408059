import { useQuery } from "react-query";

import { api } from "../../index";

type UseLoadYourApplications = {
	contactId: string;
};

export const useLoadYourApplications = ({ contactId }: UseLoadYourApplications) => {
	const loadYourApplications = () => api.publicPage.applications(contactId);

	const { data, isLoading, refetch } = useQuery("loadYourApplications", loadYourApplications);

	return {
		data: data?.data || [],
		isLoading,
		refetch,
	};
};
