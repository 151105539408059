import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";
import { Lead } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/application/delete-lead-target/DEFAULT_MN";

type Props = {
	leadId: string;
	onSuccess: () => void;
	onError: () => void;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<Lead>, unknown, void, unknown>;
	isLoading: boolean;
};

type UseDeleteLeadTarget = (props: Props) => Return;

export const useDeleteLeadTarget: UseDeleteLeadTarget = ({ leadId, onError, onSuccess }) => {
	const mutation = useMutation(`${DEFAULT_MN}:${leadId}`, {
		mutationFn: () => api.leads.deleteTarget(leadId),
		onError,
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
