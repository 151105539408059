import { useMutation } from "react-query";
import { UseFormSetError } from "react-hook-form";
import { AxiosError } from "axios";

import { Department } from "../../../../types";
import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";

const DEFAULT_MN = "sdelka/department/update-department/DEFAULT_MN";

type UseDepartmentUpdate = {
	onSuccess?: () => void;
	setError?: UseFormSetError<any>;
};

export const useDepartmentUpdate = ({ onSuccess, setError }: UseDepartmentUpdate) => {
	const onError = (error: AxiosError) => {
		const message = axiosError({ error });

		if (setError) {
			setError("name", { message });
		}
	};

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (data: Omit<Department, "officeId" | "users" | "office">) =>
			api.departments.update(data.id, { name: data.name }),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
