import { InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { useWatch } from "react-hook-form";

import {
	CategoryEnum,
	ApplicationBusinessProcessType,
	BusinessProcessTranslateEnum,
	FormPropsEditRealEstate,
} from "../../../../types";

import styles from "./index.module.scss";

const CLICK_TO_CLOSE_ID = "sdelka/base-fields-object-edit/CLICK_TO_CLOSE_ID";

type Props = {
	form: Pick<FormPropsEditRealEstate, "control" | "getValues">;
};

export const ApplicationBusinessProcess = ({ form }: Props): JSX.Element => {
	const { control, getValues } = form;

	const [category, type]: [CategoryEnum, ApplicationBusinessProcessType] = [
		useWatch({
			control,
			name: "category",
			defaultValue: getValues("category"),
		}),
		useWatch({
			control,
			name: "type",
			defaultValue: getValues("type"),
		}),
	];

	const { label: businessProcessLabel, value } = type || {};

	const valueLabel = BusinessProcessTranslateEnum[value];

	return (
		<div className={styles.root}>
			<div id={CLICK_TO_CLOSE_ID} style={{ display: "none" }} />
			<InfoRow label="Тип сделки" aspect={{ label: 1, content: 1 }}>
				{valueLabel}
			</InfoRow>

			<InfoRow label="Тип объекта" aspect={{ label: 1, content: 1 }}>
				{category}
			</InfoRow>

			<InfoRow label="Тип воронки" aspect={{ label: 1, content: 1 }}>
				{businessProcessLabel}
			</InfoRow>
		</div>
	);
};
