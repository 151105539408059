import { Control, Controller } from "react-hook-form";
import cn, { Argument } from "classnames";

import styles from "../index.module.scss";

type IFormCheckboxProps = {
	control: Control<any>;
	label?: string;
	labelTextClassName?: Argument;
	checkboxClassName?: string;
	name: string;
	defaultValue?: boolean;
	variant?: "default" | "noOpacity";
	weight?: "regular" | "bold";
};

export const Checkbox = ({
	name,
	control,
	label,
	labelTextClassName,
	checkboxClassName,
	variant = "default",
	weight = "regular",
	defaultValue = false,
}: IFormCheckboxProps): JSX.Element => (
	<Controller
		control={control}
		name={name}
		defaultValue={defaultValue}
		render={({ field: { onChange, value } }) => (
			<label
				className={cn(styles.label, styles[variant], styles[weight], {
					[styles.checked]: value,
				})}
			>
				<input onClick={() => onChange(!value)} type="checkbox" className={styles.checkbox} />
				<span className={cn(styles.checkboxBg, checkboxClassName)} />
				{label && <span className={cn(styles.labelText, labelTextClassName)}>{label}</span>}
			</label>
		)}
	/>
);
