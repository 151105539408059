import { useState } from "react";

import { PointType } from "../../../../types";

type Return = {
	onSelectPoint: (point: PointType) => void;
	onUnselectPoint: () => void;
	selectedPoint: PointType | null;
};

type UseSelectPoint = () => Return;

export const useSelectPoint: UseSelectPoint = () => {
	const [selectedPoint, setSelectedPoint] = useState<PointType | null>(null);

	const onSelectPoint = (point: PointType) => {
		setSelectedPoint(point);
	};

	const onUnselectPoint = () => {
		setSelectedPoint(null);
	};

	return {
		onSelectPoint,
		onUnselectPoint,
		selectedPoint,
	};
};
