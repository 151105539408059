import { DefaultTasksFiltersType, MultiLayerSelectOption } from "../../../../../../types";

const isEmpty = (arr: any) => typeof arr === "object" && arr?.length === 0;

type Output = DefaultTasksFiltersType;

type Input = Omit<Output, "taskType"> & {
	taskType?: MultiLayerSelectOption[];
};

const withoutEmptyArrays = (values: Input): Input =>
	Object.entries(values).reduce(
		(accum, [filterName, filterValue]) => ({
			...accum,
			[filterName]: !isEmpty(filterValue) ? filterValue : undefined,
		}),
		{}
	);

const withNecessaryFiltersOnly = (
	values: Input,
	necessaryFilters: Array<keyof DefaultTasksFiltersType>
): Input =>
	necessaryFilters.reduce(
		(accum, filter) => ({
			...accum,
			[filter]: values[filter],
		}),
		{}
	);

const withMappedTaskType = (values: Input): Output => ({
	...values,
	taskType: values.taskType?.filter((type) => type.layer === 0).map((type) => type.value),
	taskSubType: values.taskType?.filter((type) => type.layer > 0).map((type) => type.value),
});

/**
 * Prepare form values before setting a new filter
 */
export const prepareFormValues = (
	values: Input,
	necessaryFilters: Array<keyof DefaultTasksFiltersType>
): Output => withMappedTaskType(withoutEmptyArrays(withNecessaryFiltersOnly(values, necessaryFilters)));
