import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { TextField } from "../../../../UI";
import { useRequirePhone } from "../../../../../service/api/mutations";
import {
	setPhone,
	setStep,
	setJwtToken,
} from "../../../../../service/redux/modules/reset-pass-steps/reset-pass-steps.action";
import { paths } from "../../../../../utils/paths";
import { phoneMask } from "../../../../../utils/masks";
import { digitOnly } from "../../../../../utils/string";

import { ReactComponent as PhoneIcon } from "../../../../../assets/icons/phone.svg";
import styles from "./index.module.scss";

export const PhoneVerification = (): JSX.Element => {
	const dispatch = useDispatch();

	const schema = yup.object().shape({
		phone: yup
			.string()
			.test("phone", "Невалидный телефон", (phone) => phone?.replace(/\D/gi, "").length === 11)
			.required(),
	});

	const { control, handleSubmit, setError, formState } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { phone: "" },
	});

	const { mutate } = useRequirePhone({
		onSuccess: (data) => {
			const {
				data: { token },
			} = data;
			dispatch(setJwtToken(token));

			dispatch(setStep("sms-code"));
		},
		setError,
	});

	const onSubmit = async (values: { phone: string }) => {
		dispatch(setPhone(values.phone));
		mutate(digitOnly(values.phone));
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<h1>Восстановление пароля</h1>

			<div className={styles.formContainer}>
				<TextField
					control={control}
					label="Телефон"
					name="phone"
					mask={phoneMask}
					prepend={<PhoneIcon />}
					error={formState?.errors?.phone?.message?.toString()}
				/>
			</div>

			<div className={styles.buttonsContainer}>
				<Button type="submit" classNames={{ root: styles.button }}>
					Получить код
				</Button>

				<Link className={styles.link} to={paths.loginPath}>
					назад
				</Link>
			</div>
		</form>
	);
};
