import { Tooltip, TooltipReactNode } from "@sdelka_crm/sdelka-crm-component-library";

import { Table } from "../../../../UI";

import { ReactComponent as OpenInView } from "../../../../../assets/icons/open-in-new.svg";
import yaImg from "../../../../../assets/images/ya.svg";
import styles from "./index.module.scss";

type AdTableRow = {
	name: string;
	img: string;
	adViews: number;
	contactViews: number;
	link: string;
	tariff: {
		name: string;
		services: Array<{ name: string }>;
	};
};

const columns = [
	{
		accessor: "image",
		render: ({ row }: { row: AdTableRow }) => (
			<div className={styles.image}>
				<img src={row.img} alt={row.name} />
			</div>
		),
	},
	{
		accessor: "adViews",
		render: ({ row }: { row: AdTableRow }) => (
			<>
				<div className={styles.label}>Просмотров объявления:</div>

				<div className={styles.value}>{row.adViews}</div>
			</>
		),
	},
	{
		accessor: "contactViews",
		render: ({ row }: { row: AdTableRow }) => (
			<>
				<div className={styles.label}>Просмотров контакта:</div>

				<div className={styles.value}>{row.contactViews}</div>
			</>
		),
	},
	{
		accessor: "tariff",
		render: ({ row }: { row: AdTableRow }) => (
			<div className={styles.tariff}>
				<Tooltip classNames={{ root: styles.tariffTooltip }} iconSize="medium">
					Children
				</Tooltip>
				<div className={styles.tariffLabel}>{row.tariff.name}</div>
				<div>
					<TooltipReactNode
						label={<div className={styles.tariffServices}>{`+ ${row.tariff.services.length}`}</div>}
					>
						<div className={styles.services}>
							<div className={styles.servicesHeader}>Дополнительные услуги</div>

							<div className={styles.servicesBody}>
								{row.tariff.services.map((service, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<div key={index} className={styles.service}>
										<div>{`${index + 1}. ${service.name}`}</div>

										<div className={styles.serviceStatus}>Активна</div>
									</div>
								))}
							</div>
						</div>
					</TooltipReactNode>
				</div>
			</div>
		),
	},
	{
		accessor: "link",
		render: ({ row }: { row: AdTableRow }) => (
			<div onClick={() => window.open(row.link, "_blank")} className={styles.link}>
				<OpenInView />
			</div>
		),
		columnClassName: styles.linkColumn,
	},
];

const data = [
	{
		id: "1",
		name: "Яндекс",
		img: yaImg,
		adViews: 345,
		contactViews: 21,
		link: "http://example.com",
		tariff: {
			name: 'Тариф "Стандартный"',
			services: [{ name: "Услуга №1" }],
		},
	},
	{
		id: "2",
		name: "Яндекс",
		img: yaImg,
		adViews: 345,
		contactViews: 21,
		link: "http://example.com",
		tariff: {
			name: 'Тариф "Стандартный"',
			services: [{ name: "Услуга №1" }],
		},
	},
];

export const AdTable = (): JSX.Element => (
	<div className={styles.root}>
		<Table<AdTableRow> idKey="id" columns={columns} rowClass={styles.table} data={data} />
		<div onClick={() => console.log("clicked")} className={styles.showMore}>
			<span>Смотреть еще 22</span>
		</div>
	</div>
);
