import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import cn from "classnames";

import { toastError } from "../../../../../UI";
import { useAccountUpdatePhoto, useUpdateLogo } from "../../../../../../service/api/mutations";
import { initialize } from "../../../../../../service/redux/modules/auth/auth.thunk";
import { useLoadPhotos } from "../../../../../../utils/hooks";
import { IUserEditFormResponse, Office, User } from "../../../../../../types";

import { ReactComponent as PenIcon } from "../../../../../../assets/icons/edit.svg";
import styles from "./index.module.scss";

type Props = {
	office?: Office;
	canEdit?: boolean;
	refetchOffice?: () => void;
};

export const Photo = ({ office, canEdit, refetchOffice }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { loadPhotos } = useLoadPhotos();
	const [isLoading, setIsLoading] = useState(false);

	const onError = () => {
		toastError({
			text: `Не удалось загрузить файл`,
		});
		setIsLoading(false);
	};

	const { mutate } = useUpdateLogo({
		onSuccess: () => {
			dispatch(initialize());
			setIsLoading(false);
			refetchOffice?.();
		},
		onError,
	});

	const useCreateDropzone = () =>
		useDropzone({
			accept: "image/*",
			noDrag: true,
			disabled: isLoading || !canEdit,
			onDropRejected: onError,
			onDrop: async (acceptedFiles: File[]) => {
				setIsLoading(true);

				const acceptedSizes = acceptedFiles.filter((file) => {
					if (file.size > 5000000) {
						toastError({
							text: `Невозможно загрузить ${file.name}. Картинка должна весить менее 5мб`,
						});
					}

					return file.size <= 5000000;
				});

				const files = acceptedSizes.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
						photoType: "photo" as "photo" | "plan" | undefined,
					})
				);

				const curPhotos = await loadPhotos(files);

				mutate({
					officeId: office?.id || "",
					data: { avatar: curPhotos[0].photoOriginal },
				});
			},
		});

	const avatarDropzone = useCreateDropzone();
	const [initials, setInitials] = useState("");
	useEffect(() => {
		if (office?.name || office?.addressLabel) {
			setInitials(`${office?.name?.slice(0, 1) || ""}`.toUpperCase());
		}
	}, [office?.name]);

	return (
		<div
			{...avatarDropzone.getRootProps()}
			className={cn(styles.root, {
				[styles.noAvatar]: !office?.logotip,
				[styles.isLoading]: isLoading,
				[styles.cantUpload]: !canEdit,
			})}
		>
			<input {...avatarDropzone.getInputProps()} />

			{office?.logotip ? <img src={office?.logotip} alt="Avatar" /> : <p>{initials}</p>}

			<div className={styles.shadow} />
			{isLoading ? <Loader className={styles.loader} /> : <PenIcon />}
		</div>
	);
};
