import { useRef, useEffect, useState } from "react";

export const useTimer = (initialTime: number): [number, () => void, () => void] => {
	const timerId = useRef<NodeJS.Timeout>();
	const [timer, setTimer] = useState(initialTime);

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			if (timerId.current) {
				clearInterval(timerId.current);
			}
		};
	}, []);

	const handleTimer = () => {
		setTimer(initialTime);

		timerId.current = setInterval(() => {
			setTimer((prev) => {
				const value = prev - 1;
				if (value < 0 && timerId.current) {
					clearInterval(timerId.current);

					return prev;
				}

				return value;
			});
		}, 1000);
	};

	const handleRestart = () => {
		setTimer(initialTime);
	};

	return [timer, handleTimer, handleRestart];
};
