import cn from "classnames";
import styles from "./index.module.scss";
import { Plug } from "../plug/Plug";
import { RealEstate } from "../../../../../../../types";
import { CardPerson } from "../../../../../../UI";

type Props = {
  realEstate: RealEstate
}

export const Contact = (
  {
    realEstate,
  }: Props): JSX.Element => {

  const {
    contact,
  } = realEstate

  const onClickContact = (contactId?: string) => {
    window.open(`/contacts?id=${contactId}`);
  }
  const titleContact = "Контакт"

  return (
    <div className={styles.root}>
      <div className={cn(styles.row, styles.header)}>
        <div className={styles.title}>
          {titleContact}
        </div>
      </div>
      {contact ?
        <div onClick={() => onClickContact(contact.id)}>
          <CardPerson
            id={contact.id}
            fio={{
              firstName: contact?.name,
              secondName: contact?.secondName,
              lastName: contact?.lastName,
              pseudonym: contact?.pseudonym,
            }}
            phone={contact.phone ?? ""}
            isContact
          />
        </div>

        : <>
          <Plug plugText="Чтобы добавить контакт, совершите холодный звонок"/>
        </>
      }
    </div>
  )
}


