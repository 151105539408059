import { Button, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { ReactComponent as Alert } from "../../../../../../../../../../../assets/icons/alert-info.svg";
import styles from "./index.module.scss";
import { RequiredFieldsType } from "../../../../../../../../../../../types";

type Props = {
	requiredFields: RequiredFieldsType;
	onFillFields: () => void;
};

export const RequiredFields = ({ requiredFields, onFillFields }: Props): JSX.Element => (
	<div className={styles.needMoreData}>
		<div className={styles.iconInfo}>
			<span>
				<Alert />
			</span>
			<span>
				Данные
				<span style={{ marginLeft: 4 }}>
					{requiredFields.filledrequiredFieldsCount}/{requiredFields.allrequiredFieldsCount}
				</span>
			</span>
		</div>

		{requiredFields?.unfilledrequiredFields && requiredFields?.unfilledrequiredFields.length > 0 && (
			<Tooltip>
				<div className={styles.unfilledRequiredFields}>
					<p>Поля требующие заполнения:</p>
					{requiredFields.unfilledrequiredFields.map((field) => (
						<p key={field.name}>• {field.label}</p>
					))}
				</div>
			</Tooltip>
		)}
		<Button onClick={onFillFields} classNames={{ root: styles.addDataButton }} variant="text">
			Заполнить
		</Button>
	</div>
);
