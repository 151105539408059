import { ApplicationCardPayload, UserListItem } from "../../../../types";

import { UserList } from "../UserList";
import { useLoadEmployee } from "../../../../service/api/queries";

import styles from "./index.module.scss";

type Props = ApplicationCardPayload & {
	applicationId: string;
};

export const Employees = ({ applicationId, scrollY, stageConditions }: Props): JSX.Element => {
	const { data, refetch } = useLoadEmployee({ applicationId });

	const users = data || [];

	const userList: UserListItem[] = users.map((respUser) => {
		const { name, lastName, secondName, id, type, phone } = respUser;

		return {
			id,
		 name,
			lastName,
			secondName,
			type,
			phone,
		};
	});

	return (
		<div className={styles.root}>
			<UserList
				title="Сотрудники"
				applicationId={applicationId}
				users={userList}
				scrollY={scrollY}
				isSuccess={false}
				refetch={refetch}
				stageConditions={stageConditions}
			/>
		</div>
	);
};
