import { QuestionnaireValue, RadioButton } from "../../../index";
import { Control } from "react-hook-form";

type Props = {
	handleOnChange?: (value: QuestionnaireValue) => void;
	control: Control<any>;
	name: string;
	allowedValues: string[];
	disabled?: boolean;
};

export const Select = ({ name, control, handleOnChange, allowedValues, disabled }: Props): JSX.Element => {
	const getOption = allowedValues.map((value) => ({
		value,
		label: value,
		disabled,
	}));

	return (
		<RadioButton name={name} control={control} options={getOption} handleOnChange={handleOnChange} />
	);
};
