import { KindType, RealEstate } from "../../../../../../../../../types";

import { useOpenRealEstate } from "../../../../../../../../../utils/objects";
import { useOpenDrawerRealEstate, useQueryParams } from "../../../../../../../../../utils/hooks";

type Props = {
	isParser?: boolean;
	refetch: () => void;
	realEstate: RealEstate;
};

type Return = {
	handleAuxClick: () => void;
	handleCardClick: () => void;
};

type UseCardClick = (props: Props) => Return;

type QueryParams = {
	applicationId: string;
	parserRealEstateId: string;
};

export const useCardClick: UseCardClick = ({ isParser, refetch, realEstate }) => {
	const { removeQueryParam } = useQueryParams<QueryParams>([
		{ name: "applicationId" },
		{ name: "parserRealEstateId" },
	]);

	const { id, applicationId } = realEstate;

	const locationFrom = isParser ? "parser-list" : "regular-list";
	const openObject = useOpenRealEstate({
		locationFrom,
		options: { inNewTab: true },
	});

	const handleAuxClick = () => openObject(realEstate);

	const onClose = () => {
		refetch();

		if (isParser) {
			removeQueryParam("parserRealEstateId");
		} else {
			removeQueryParam("applicationId");
		}
	};

	const { handleOpenDrawerRealEstate } = useOpenDrawerRealEstate({
		onClose,
		isParser,
		refetch: refetch,
		kind: realEstate.shortType?.kind ?? KindType.APPLICATION
	});
	const handleCardClick = () => handleOpenDrawerRealEstate(isParser ? id : applicationId);

	return {
		handleAuxClick,
		handleCardClick,
	};
};
