/* eslint-disable no-unused-vars */

import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import styles from "./index.module.scss";
import { RealEstateSort } from "../../../../../../../types";
import { useQueryParams } from "../../../../../../../utils/hooks";
import { Select } from "../../../../../../UI";

type Props = {
  total: number;
  take: number;
  setTake: Dispatch<SetStateAction<number>>;
  sort?: RealEstateSort;
  setSort: Dispatch<SetStateAction<RealEstateSort | undefined>>;
  selectAll: () => void;
  isSelectedAll: boolean;
  isLoading: boolean;
  selectedItemsIds: string[];
};

// Component for manipulation with object list size and sort options.
export const AdListHeader = (
  {
    take,
    setTake,
    sort,
    setSort,
    isLoading,
    total
  }: Props): JSX.Element => {
  const {setValue, register} = useForm();
  const {queryParams, changeQueryParams} = useQueryParams<{
    compilation: boolean;
    pageSize: string;
    sort: string;
  }>([{name: "compilation", type: "boolean"}, {name: "pageSize"}, {name: "sort"}]);

  const sizeOptions = [
    {label: "5 объектов", value: "5"},
    {label: "25 объектов", value: "25"},
    {label: "50 объектов", value: "50"},
    {label: "75 объектов", value: "75"},
    {label: "100 объектов", value: "100"},
  ];

  const priceOptions = [
    {label: "Сначала новые", value: "createdAt DESC"},
    {label: "Сначала дешевые", value: "price ASC"},
    {label: "Сначала дорогие", value: "price DESC"},
    {label: "Сначала старые", value: "createdAt ASC"},
  ];

  const handleChangeTake = (value: string) => {
    setTake(Number(value));
    changeQueryParams([{name: "pageSize", newValue: encodeURIComponent(value)}]);
  };

  const handleChangeSort = (value: string) => {
    const splited = value.split(" ") as ["price" | "createdAt", "ASC" | "DESC"];

    setSort({field: splited[ 0 ], sort: splited[ 1 ]});
    changeQueryParams([{name: "sort", newValue: value}]);
  };

  useEffect(() => {
    if (queryParams.pageSize) {
      setTake(Number(queryParams.pageSize));
    }
  }, [queryParams.pageSize]);

  useEffect(() => {
    if (queryParams.sort) {
      const decodedSort = decodeURIComponent(queryParams.sort).split(" ") as [
          "price" | "createdAt",
          "ASC" | "DESC"
      ];
      setSort({field: decodedSort[ 0 ], sort: decodedSort[ 1 ]});
    }
  }, [queryParams.sort]);

  return (
    <div className={styles.root}>
      <form className={styles.objectManipulations}>
        {/*<div className={styles.selectActionsContainer}>*/}
        {/*	/!* <IndividualCheckbox *!/*/}
        {/*	/!*  onChange={selectAll} *!/*/}
        {/*	/!*  checked={isSelectedAll} *!/*/}
        {/*	/!*  label="Выделить все" *!/*/}
        {/*	/!*  disabled={isLoading || queryParams.compilation} *!/*/}
        {/*	/!* /> *!/*/}

        {/*	/!* <SelectedActions selectedItemsIds={selectedItemsIds} /> *!/*/}

        {/*	/!* {isLoading ? ( *!/*/}
        {/*	/!*  <div className={styles.skeleton} /> *!/*/}
        {/*	/!* ) : ( *!/*/}
        {/*	/!*  <div className={styles.totalAmount}> *!/*/}
        {/*	/!*    {`(${selectedItemsIds.length}/${total})`} *!/*/}
        {/*	/!*  </div> *!/*/}
        {/*	/!* )} *!/*/}
        {/*</div>*/}
        <div className={styles.total}>
          {`${total} объектов`}
        </div>

        <div className={styles.objectDisplay}>
          <Select
            name="size"
            defaultValue={take.toString()}
            onChange={handleChangeTake}
            options={sizeOptions}
            register={register}
            setValue={setValue}
            size="small"
            classNames={{root: styles.select}}
            disabled={isLoading}
          />

          <Select
            name="price"
            defaultValue={`createdAt DESC`}
            onChange={handleChangeSort}
            options={priceOptions}
            register={register}
            setValue={setValue}
            size="small"
            disabled={isLoading}
          />
        </div>
      </form>
    </div>
  );
};
