import cn from "classnames";

import { CountStatusInfo } from "../../CountStatusInfo";

import styles from "./index.module.scss";

export type StatusInfoElemsData = {
	totalFeeds: number;
	postedFeeds: number;
	waitingFeeds: number;
	errors: number;
};

type Props = {
	small?: boolean;
	feedsData: StatusInfoElemsData;
};

type CountStatusInfoElems = (statusInfoElemsData: StatusInfoElemsData) => {
	id: number;
	type: "success" | "wait" | "error";
	text: string;
}[];

const countStatusInfoElems: CountStatusInfoElems = ({ totalFeeds, postedFeeds, waitingFeeds, errors }) => [
	{ id: 1, type: "success", text: `${postedFeeds} из ${totalFeeds} площадок` },
	{ id: 2, type: "wait", text: `Ожидает ${waitingFeeds}` },
	{ id: 3, type: "error", text: `${errors} ошибок` },
];

type SmallCountStatusInfoElems = (statusInfoElemsData: StatusInfoElemsData) => {
	id: number;
	type: "success" | "wait" | "error";
	text: string;
	value: string;
}[];

const smallCountStatusInfoElems: SmallCountStatusInfoElems = ({
	totalFeeds,
	postedFeeds,
	waitingFeeds,
	errors,
}) => [
	{
		id: 1,
		type: "success",
		text: "Размещенно:",
		value: `${postedFeeds}/${totalFeeds}`,
	},
	{ id: 2, type: "wait", text: "Ожидает:", value: `${waitingFeeds}` },
	{ id: 3, type: "error", text: "Ошибки", value: `${errors}` },
];

export const FeedsList = ({ small, feedsData }: Props): JSX.Element => (
	<div className={styles.countStatusInfoContainer}>
		{small
			? smallCountStatusInfoElems(feedsData).map((smallCountStatus) => (
					<div className={styles.smallCountInfo} key={smallCountStatus.id}>
						<span className={styles.smallCountInfoTitle}>{smallCountStatus.text}</span>
						<span className={cn(styles.smallCountInfoValue, styles[smallCountStatus.type])}>
							{smallCountStatus.value}
						</span>
					</div>
			  ))
			: countStatusInfoElems(feedsData).map((countStatusInfo) => (
					<div key={countStatusInfo.id}>
						<CountStatusInfo text={countStatusInfo.text} type={countStatusInfo.type} />
					</div>
			  ))}
	</div>
);
