/* eslint-disable sonarjs/no-duplicate-string */
import {
  ActualEnum,
  BuyBusinessProcessEnum,
  CategoryEnum,
  Conditions,
  RealEstateFilter,
  SellBusinessProcessEnum,
} from "../../types";

export const objectResidentialVariant = [
  [{label: "Квартира", value: CategoryEnum[ "Квартира" ]}],
  [{label: "Комната", value: CategoryEnum[ "Комната" ]}],
  [
    {label: "Дом/Дача/Коттедж", value: CategoryEnum[ "Дом" ]},
    {label: "Земельный участок", value: CategoryEnum[ "Участок" ]},
  ],
  [{label: "Гараж/Машиноместо", value: CategoryEnum[ "Гараж" ]}],
];

export const objectCreationResidentialVariant = [
  [{label: "Квартира", value: CategoryEnum[ "Квартира" ]}],
  [{label: "Комната", value: CategoryEnum[ "Комната" ]}],
  [{label: "Дом/Дача/Коттедж", value: CategoryEnum[ "Дом" ]}],
  [{label: "Земельный участок", value: CategoryEnum[ "Участок" ]}],
  [{label: "Гараж/Машиноместо", value: CategoryEnum[ "Гараж" ]}],
];

export const objectCommercialVariant = [
  [{label: "Коммерческая земля", value: CategoryEnum[ "Земля" ]}],
  [
    {label: "Офисное помещение", value: CategoryEnum[ "Офис" ]},
    {label: "Торговая площадь", value: CategoryEnum[ "Торговая площадка" ]},
    {
      label: "Свободное назначение",
      value: CategoryEnum[ "Свободное назначение" ],
    },
  ],
  [{label: "Склад", value: CategoryEnum[ "Склад" ]}],
  [{label: "Здание", value: CategoryEnum[ "Здание" ]}],
];

// Object fast filters config.
export const objectFilters = {
  sellBusinessProcess: [
    {value: SellBusinessProcessEnum.sale, label: "Продажа"},
    {value: SellBusinessProcessEnum.rentOut, label: "Аренда"},
  ],
  buyBusinessProcess: [
    {value: BuyBusinessProcessEnum.buy, label: "Покупка"},
    {value: BuyBusinessProcessEnum.rent, label: "Снять"},
    {
      value: BuyBusinessProcessEnum.buyNewBuilding,
      label: "Покупка новостройки",
    },
  ],
  objectType: [
    {value: "Жилая", label: "Жилая"},
    {value: "Коммерческая", label: "Коммерческая"},
  ],
  objectVariant: [
    [
      {
        label: "Квартира",
        value: "Квартиры",
      },
    ],
    [
      {
        label: "Комната",
        value: "Комнаты",
      },
      {
        label: "Доля",
        value: "Доля",
      },
    ],
    [
      {
        label: "Дом",
        value: "Дома",
      },
      {
        label: "Таунхаус",
        value: "Таунхаусы",
      },
      {
        label: "Участок",
        value: "Участки",
      },
    ],
    [
      {
        label: "Гараж/Машиноместо",
        value: "Гараж/Машиноместо",
      },
    ],
  ],
  priceFor: [
    {value: "объект", label: "за объект"},
    {value: "м2", label: "за м2"},
  ],
  flatRooms: [
    {value: "Студия", label: "Студия"},
    {value: "1", label: "1"},
    {value: "2", label: "2"},
    {value: "3", label: "3"},
    {value: "4", label: "4"},
    {value: "5", label: "5"},
    {value: "6", label: "6"},
    {value: "7", label: "7"},
    {value: "8", label: "8"},
    {value: "9", label: "9+"},
    {value: "Свободная планировка", label: "Свободная планировка"},
  ],
  flatTypes: [
    {value: "Смежные", label: "Смежная"},
    {value: "Изолированные", label: "Изолированная"},
  ],
  square: [
    {value: "гек", label: "га"},
    {value: "сот", label: "сот"},
  ],
  material: [
    {label: "Панельный", value: "Панельный"},
    {label: "Кирпичный", value: "Кирпичный"},
    {label: "Деревянный", value: "Деревянный"},
    {label: "Блочный", value: "Блочный"},
    {label: "Газобетонный блок", value: "Газобетонный блок"},
    {label: "Пенобетонный блок", value: "Пенобетонный блок"},
    {label: "Газосиликатный блок", value: "Газосиликатный блок"},
    {label: "Щитовой ", value: "Щитовой "},
    {label: "Монолитный", value: "Монолитный"},
    {label: "Кирпично-монолитный", value: "Кирпично-монолитный"},
    {label: "Брус", value: "Брус"},
    {label: "Бревно", value: "Бревно"},
    {label: "Газоблоки", value: "Газоблоки"},
    {label: "Металл", value: "Металл"},
    {label: "Сэндвич-панели", value: "Сэндвич-панели"},
    {label: "Ж/б панели", value: "Ж/б панели"},
    {
      label: "Экспериментальные материалы",
      value: "Экспериментальные материалы",
    },
    {label: "Каркасный", value: "Каркасный"},
  ],
};

export const defaultValues: RealEstateFilter = {
  type: {
    value: SellBusinessProcessEnum.sale,
  },
  address: {
    label: "",
    value: "",
  },
  rooms: [],
  priceFrom: "",
  priceTo: "",
  unit: objectFilters.priceFor[ 0 ].value,
  category: [],
  actual: [ActualEnum[ "Актуальный" ]],
};

export const CONDITIONS_WITHOUT_APPLICATIONS: Conditions = [
  {
    filterType: "and",
    conditions: [
      {
        field: "actual",
        operator: "eq",
        value: ActualEnum[ "Актуальный" ],
      },
    ],
  },
];
