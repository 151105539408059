import { Dispatch, SetStateAction, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

import { FixedNavMenu } from "../../components/UI";

// Find active nav for navs header.
export function useGetActiveNavByScroll(
	navs: FixedNavMenu[] | undefined,
	setScrollTop: Dispatch<SetStateAction<number>>,
	scrollTop: number,
	setActiveNav: Dispatch<SetStateAction<string>>,
	rootId: string,
	offset?: number
): void {
	const onScroll = (e) => {
		setScrollTop(e?.target?.scrollTop);
		const offsetResult = offset || 0;
		// Search if scroll value = nav block (finding by id) position in page.
		navs?.forEach((nav) => {
			const element = document.getElementById(nav.id ? nav.id : nav.label);
			if (element && element.offsetTop - offsetResult <= e?.target?.scrollTop) {
				setActiveNav(nav.label);
			}
		});
	};

	const debounce = useDebouncedCallback(onScroll, 200);

	useEffect(() => {
		document.getElementById(rootId)?.addEventListener("scroll", debounce);

		return () => document.getElementById(rootId)?.removeEventListener("scroll", debounce);
	}, [scrollTop]);
}
