import { RealEstate } from "../../../../types";
import styles from "./index.module.scss";
import { CardObject } from "../CardObject";

type Props = {
  clientObject: RealEstate;
  onChange?: (clientObject?: RealEstate) => void;
  clickable?: boolean;
};

export const CardObjectHeader = ({clientObject, clickable = true}: Props): JSX.Element => {
  const {applicationId} = clientObject;

  const {pathname, search} = window.location;
  const encodedBackPath = encodeURIComponent(`${pathname}${search}`);

  const objectUrl = `/real-estate/application/${applicationId}?mode=view&backPath=${encodedBackPath}`;
  return (
    <a className={styles.root} href={clickable ? objectUrl : undefined} target="_blank" rel="noreferrer">
      <CardObject
        clientObject={clientObject}
        id={clientObject.id}
        variant="atApp"
        options={{
          canOpenANewTab: true
        }}
        classNames={{
          content: styles.cardContent,
          owner: styles.responsible
        }}
        cardBackground={"white"}
      />
    </a>
  );
};
