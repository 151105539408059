import { Button } from "@sdelka_crm/sdelka-crm-component-library";
// import cn from "classnames";

import arrowRead from "../../../../assets/images/arrow.svg";

import styles from "./index.module.scss";

type Props = {
	id: string;
};

export const Read = ({ id }: Props): JSX.Element => (
	<div className={styles.container} id={id}>
		<h2>Если удобнее читать</h2>
		<div className={styles.grid}>
			<div className={styles.containerRead}>
				<div className={styles.leftRead}>
					<img className={styles.imgArrow} src={arrowRead} alt="arrowRead" />
				</div>
				<div className={styles.rightRead}>
					<span className={styles.labelRead}>Методология риэлторской деятельности</span>
					<p>Та же информация в письменном виде от основателя сделки</p>

                    <div className={styles.btnContainer}>
                    <div className={styles.buttonContainer}>
						<Button
							// Icon={PlusIcon}
							// onClick={handleOpenCreateModal}
							// classNames={{
							// 	root: cn(styles.button, {
							// 		[styles.buttonShort]: isAccountsOpen,
							// 	}),
							// }}
						>
							<span className={styles.buttonText}>Скачать PDF</span>
						</Button>
					</div>
                    <div className={styles.buttonContainer}>
						<Button
							// Icon={PlusIcon}
							// onClick={handleOpenCreateModal}
							// classNames={{
							// 	root: cn(styles.button, {
							// 		[styles.buttonShort]: isAccountsOpen,
							// 	}),
							// }}
						>
							<span className={styles.buttonText}>Скачать FB2</span>
						</Button>
					</div>
                    </div>
				</div>
			</div>

			<div className={styles.containerRead}>
				<div className={styles.leftRead}>
					<img src={arrowRead} alt="arrowRead" />
				</div>
				<div className={styles.rightRead}>
					<span className={styles.labelRead}>Методология риэлторской деятельности</span>
					<p>Та же информация в письменном виде от основателя сделки</p>

                    <div className={styles.btnContainer}>
                    <div className={styles.buttonContainer}>
						<Button
							// Icon={PlusIcon}
							// onClick={handleOpenCreateModal}
							// classNames={{
							// 	root: cn(styles.button, {
							// 		[styles.buttonShort]: isAccountsOpen,
							// 	}),
							// }}
						>
							<span className={styles.buttonText}>Скачать PDF</span>
						</Button>
					</div>
                    <div className={styles.buttonContainer}>
						<Button
							// Icon={PlusIcon}
							// onClick={handleOpenCreateModal}
							// classNames={{
							// 	root: cn(styles.button, {
							// 		[styles.buttonShort]: isAccountsOpen,
							// 	}),
							// }}
						>
							<span className={styles.buttonText}>Скачать FB2</span>
						</Button>
					</div>
                    </div>
				</div>
			</div>
		</div>
	</div>
);
