import { Dispatch, SetStateAction } from "react";
import { UseFormReset } from "react-hook-form";

import { RealEstateFilter } from "../../../../../../types";

import { DEFAULT_FILTER } from "../../../../../../service/api/helpers";

type Props = {
	reset: UseFormReset<any>;
	setShowBtn: Dispatch<SetStateAction<boolean>>;
	changeFilter: (payload: RealEstateFilter) => void;
};

type Return = {
	resetFilter: () => void;
};

type UseResetForm = (props: Props) => Return;

export const useResetForm: UseResetForm = ({ reset, setShowBtn, changeFilter }) => {
	const resetFilter = () => {
		changeFilter({ ...DEFAULT_FILTER });
		reset({ ...DEFAULT_FILTER });
		setShowBtn(false);
	};

	return {
		resetFilter,
	};
};
