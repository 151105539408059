import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { KindType, WhoIsClient } from "../../../../types";
import { useOpenApplicationCard } from "../../../../utils/application";
import { useOpenLead } from "./index";
import { useLoadApplications, useLoadLeads, useLoadTraffic } from "../../../../service/api/queries";
import { useQueryParams } from "../../../../utils/hooks";
import { useLoadDeals } from "service/api/queries/deals/useLoadDeals";
import {
  closeDrawerInDrawer,
  openDrawer,
  openDrawerInDrawer
} from "../../../../service/redux/modules/drawer/drawer.action";
import { PreApplicationTemplate } from "../../Application/PreApplicationTemplate";
import { useDispatch } from "react-redux";
import { ContactInfo } from "../../ContactCard";
import { ApplicationCard } from "../../Application";

type TabType = "contacts" | "leads" | "applications" | "deals" | "contracts";

export const useLeadsAndDealsTabs = () => {
  const {push} = useHistory();
  const [curTab, setCurTab] = useState(0);

  const {handleClickApplication} = useOpenApplicationCard({});

  const {queryParams, removeQueryParam} = useQueryParams<{
    tab: string;
    id: string;
    client: WhoIsClient;
    preApplicationId: string
    applicationId: string
    dealId: string
    contactId: string
    parserRealEstateId: string
  }>([
    {name: "tab"},
    {name: "mode"},
    {name: "id"},
    {name: "client"},
    {name: "preApplicationId"},
    {name: "applicationId"},
    {name: "dealId"},
    {name: "parserRealEstateId"},
    {name: "contactId"},
  ]);
  const {
    id,
    tab,
    client,
    preApplicationId,
    applicationId,
    dealId,
    contactId
  } = queryParams;

  const trafficData = useLoadTraffic({enabled: curTab === 0});
  const {
    filter: trafficsFilter,
    setFilter: setTrafficsFilter,
    refetch: refetchTraffics,
    isFetching: isFetchingTraffics,
  } = trafficData;

  const leadsData = useLoadLeads({enabled: curTab === 1});
  const {
    filter: leadFilter,
    setFilter: setLeadFilter,
    refetch: refetchLeads,
    isFetching: isFetchingLeads,
  } = leadsData;

  const applicationsData = useLoadApplications({enabled: curTab === 2});
  const {
    filter: applicationsFilter,
    setFilter: setApplicationsFilter,
    refetch: refetchApplications,
    isFetching: isFetchingApplications,
  } = applicationsData;

  const dealsData = useLoadDeals({enabled: curTab === 3});
  const {
    filter: dealsFilter,
    setFilter: setDealsFilter,
    refetch: refetchDeals,
    isFetching: isFetchingDeals,
  } = dealsData;

  const contractsFilter = {};

  const TABS: Record<TabType, unknown> = {
    contacts: [trafficsFilter, setTrafficsFilter, refetchTraffics, isFetchingTraffics],
    leads: [leadFilter, setLeadFilter, refetchLeads, isFetchingLeads],
    applications: [applicationsFilter, setApplicationsFilter, refetchApplications, isFetchingApplications],
    deals: [dealsFilter, setDealsFilter, refetchDeals, isFetchingDeals],
    contracts: [contractsFilter, () => false],
  };

  const TAB_KEYS = Object.keys(TABS);
  const filterType = TAB_KEYS[ curTab ];

  const filter = TABS[ filterType ][ 0 ];
  const setFilter = TABS[ filterType ][ 1 ];
  const refetch = TABS[ filterType ][ 2 ];
  const isFetching = TABS[ filterType ][ 3 ];
  const dispatch = useDispatch();
  const updateCurTab = (index: number): void => {
    if (curTab !== index) {
      setCurTab(index);
    }
  };

  const closeDrawer = () => {
    refetch();
    removeQueryParam("contactId");
  };

  const navigationY = [{label: "Контакт"}];
  const {handleOpenLead} = useOpenLead({refetch});

  const openContactDrawer = () => {
    dispatch(
      openDrawer({
        children: <ContactInfo contactId={queryParams.contactId} refetchList={refetch}/>,
        width: 800,
        onClose: closeDrawer,
        yNavigation: {
          initialTab: 0,
          navigation: navigationY,
        },
      })
    );
  };

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case "contacts":
          if (contactId) {
            setTimeout(() => {
              openContactDrawer();
            }, 2000);
          }
          updateCurTab(0);
          break;
        case "leads": {
          if (id) {
            setTimeout(() => {
              handleOpenLead(id);
            }, 0);
          }
          if (preApplicationId) {
            setTimeout(() => {
              dispatch(
                openDrawerInDrawer({
                  children: (
                    <PreApplicationTemplate refetchLead={refetch} applicationId={preApplicationId} isFromLead={true}/>),
                  width: 800,
                  onClose: () => removeQueryParam(["preApplicationId", "mode"])
                })
              );
            }, 200)
          }
          if (applicationId) {
            console.log(2222)
            setTimeout(() => {
              dispatch(closeDrawerInDrawer())
              dispatch(closeDrawerInDrawer())
              dispatch(   openDrawerInDrawer({
                width: 800,
                children: (
                  <ApplicationCard
                    applicationId={applicationId}
                    kind={KindType.APPLICATION}
                    inDrawerInDrawer={true}
                    isFromObjectList={false}
                    refetch={refetch}
                  />
                ),
                onClose: () => removeQueryParam(["applicationId"])
              }))

            }, 300);
          }

          updateCurTab(1);
          break;
        }
        case "AGENCY": {
          if (preApplicationId) {

            setTimeout(() => {
              handleClickApplication({
                applicationId: preApplicationId,
                whoIsClient: client,
                refetch,
                kind: KindType.PREAPPLICATION,
                isFromObjectList: true
              });
            }, 0);

          }
          if (applicationId) {
            setTimeout(() => {
              handleClickApplication({
                applicationId,
                whoIsClient: client,
                refetch,
                kind: KindType.APPLICATION,
                isFromObjectList: true
              });
            }, 0);
          }
          updateCurTab(1);
          break;
        }
        case "applications":
          if (applicationId) {
            setTimeout(() => {
              handleClickApplication({
                applicationId,
                whoIsClient: client,
                refetch,
              });
            }, 0);
          }
          if (preApplicationId) {
            setTimeout(() => {
              handleClickApplication({
                applicationId: preApplicationId,
                whoIsClient: client,
                refetch,
                kind: KindType.PREAPPLICATION
              });
            }, 0);
          }
          updateCurTab(2);
          break;
        case "deals":
          if (dealId) {
            setTimeout(() => {
              handleClickApplication({
                applicationId: dealId,
                whoIsClient: client,
                refetch,
                kind: KindType.DEAL
              });
            }, 0);
          }
          updateCurTab(3);
          break;
        case "contracts":
          updateCurTab(4);
          break;
        default:
          updateCurTab(0);
          break;
      }
    }
  }, [tab]);


  const onChangeCurTab = (newTab: number) => {
    setCurTab(newTab);
    push(`/leadsanddeals?tab=${TAB_KEYS[ newTab ]}`);
  };

  return {
    curTab,
    onChangeCurTab,
    filter,
    setFilter,
    refetch,
    isFetching,
    // leads
    leadsData,
    // applications
    applicationsData,
    // traffic
    trafficData,
    //
    dealsData,
  };
};
