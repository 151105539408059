import { useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import { AdError } from "../../../../../../../../../types";

import { ErrorAction } from "./ErrorAction";

import { ReactComponent as AlertIcon } from "../../../../../../../../../assets/icons/alert.svg";
import styles from "./index.module.scss";

export type RealEstateAdErrorsProps = {
	errors: Array<AdError>;
	data: any;
	isEditError?: boolean;
	applicationId: string;
};

export const RealEstateAdErrors = ({
	errors,
	data,
	isEditError,
	applicationId,
}: RealEstateAdErrorsProps): JSX.Element => {
	const adBlockRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (adBlockRef.current) {
			const allATags = adBlockRef.current.querySelectorAll("a");

			if (allATags) {
				for (let i = 0; i < allATags.length; i += 1) {
					allATags[i].setAttribute("target", "_blank");
					allATags[i].setAttribute("rel", "noreferrer");
				}
			}
		}
	}, []);

	return (
		<div ref={adBlockRef} className={styles.root}>
			{errors.map((error, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={index} className={styles.container}>
					<div className={styles.errorTitle}>
						{isEditError ? (
							<div className={styles.editError}>
								<AlertIcon />
								Ошибка размещения
							</div>
						) : (
							"Ошибка"
						)}
					</div>

					<div
						className={styles.errorMessage}
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(error.message || "Неизвестная ошибка"),
						}}
					/>

					<div className={styles.buttons}>
						<ErrorAction
							type={error.action.length ? error.action[0].type : undefined}
							data={data}
							applicationId={applicationId}
						/>
					</div>
				</div>
			))}
		</div>
	);
};
