import { IUserEditFormRequest, IUserEditFormResponse, userStatuses } from "../../../../../types";
import { useState } from "react";
import styles from "./index.module.scss"
import { ReactComponent as PenIcon } from "../../../../../assets/icons/edit.svg";
import { ReactComponent as Plug } from "../../../../../assets/icons/employee-card-plug.svg";
import { formatPhone } from "../../../../../utils/formatters";
import { useDispatch } from "react-redux";
import { initialize } from "../../../../../service/redux/modules/auth/auth.thunk";
import { useUpdateUserCard } from "../../../../../service/api/mutations";
import { useForm, useWatch } from "react-hook-form";
import { InfoRow, TransparentInput } from "../../../../UI";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { Photo } from "./Photo";

type Props = {
  user?: IUserEditFormResponse;
  canEdit?: boolean;
  refetch?: () => void;
};

export const MainInfo = (
  {
    user,
    canEdit,
    refetch
  }: Props) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false)
  const toggleMode = () => setEditMode((prev) => !prev);
  const onEditFormSuccessfulSubmit = () => {
    refetch?.();
    toggleMode();
    dispatch(initialize());
  };

  const {userUpdate, isLoading} = useUpdateUserCard({
    onSuccess: onEditFormSuccessfulSubmit,
  });

  const {handleSubmit, register, setValue, formState, control} = useForm<IUserEditFormRequest>({
    defaultValues: {name: user?.name, secondName: user?.secondName, lastName: user?.lastName}
  });

  const onSubmit = (values: IUserEditFormRequest) => {
    userUpdate({id: user?.id || "", payload: values});
  };

  const nameWatcher = useWatch({
    control,
    name: "name",
    defaultValue: user?.name || "",
  })
  const secondWatcher = useWatch({
    control,
    name: "secondName",
    defaultValue: user?.secondName || "",
  })
  const lastWatcher = useWatch({
    control,
    name: "lastName",
    defaultValue: user?.lastName || "",
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.root}>
        <div className={styles.row}>
          {editMode
            ? <Photo user={user} canEdit={canEdit} refetchUser={refetch}/>
            : <div className={styles.image}>
              {user?.avatar
                ? <img src={user?.avatar} alt={"avatar"}/>
                : <Plug/>
              }
            </div>
          }
          {editMode &&
            <div className={styles.editContent}>
              <InfoRow label={"Фамилия"}>
                <TransparentInput
                  className={styles.input}
                  register={register}
                  placeholder="Фамилия"
                  withCleanUp={{setValue}}
                  name="lastName"
                  error={formState?.errors?.lastName?.message?.toString()}
                  required
                  value={lastWatcher}
                />
              </InfoRow>
              <InfoRow label={"Имя"}>
                <TransparentInput
                  className={styles.input}
                  register={register}
                  placeholder="Имя"
                  name="name"
                  withCleanUp={{setValue}}
                  error={formState?.errors?.name?.message?.toString()}
                  required
                  value={nameWatcher}
                />
              </InfoRow>
              <InfoRow label={"Отчество"}>
                <TransparentInput
                  className={styles.input}
                  register={register}
                  placeholder="Отчество"
                  name="secondName"
                  withCleanUp={{setValue}}
                  value={secondWatcher}
                  error={formState?.errors?.secondName?.message?.toString()}
                />
              </InfoRow>
            </div>
          }
          {!editMode &&
            <div className={styles.column} style={{height: "164px"}}>
              <div className={styles.column}>
                <div className={styles.rowBetween}>
                  <div className={styles.firstName}>
                    {user?.lastName}
                  </div>
                  {canEdit &&
                    <div className={styles.edit} onClick={toggleMode}>
                      <PenIcon/>
                    </div>
                  }
                </div>
                <div className={styles.name}>{`${user?.name} ${user?.secondName ?? ""}`}</div>
                <div className={styles.phone}>{formatPhone(user?.phone ?? "")}</div>
              </div>
              <div className={styles.status}>
                {userStatuses[ user?.status ?? "" ]} {" "}
              </div>
            </div>}
        </div>

      </div>
      {editMode &&
        <div className={styles.submitBtns}>
          <Button variant="outlined" onClick={toggleMode} isLoading={isLoading}>
            Отменить
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Применить
          </Button>
        </div>
      }
    </form>
  )
}