import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Underlay } from "@sdelka_crm/sdelka-crm-component-library";

import {
	Boolean,
	BooleanWithContact,
	BooleanWithNum,
	DateInput,
	MortgageSelect,
	Number,
	QuizTask,
	Select,
	Text,
	WithTasks,
} from "./components";
import { QuizQuestionDataTypeValues, QuizQuestionType } from "../../../types";

import styles from "./index.module.scss";

export type QuestionnaireValue = string | number | string[];

type Props = {
	applicationId: string;
	question: string;
	onChange: (value: QuestionnaireValue) => void;
	register: UseFormRegister<any>;
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	control: Control<any>;
	name?: string;
	nameGroup?: string[];
	type: QuizQuestionType;
	allowedValues?: string[];
	refetch: () => void;
	disabled?: boolean;
	options?: { label: string; value: number }[];
	values?: QuizQuestionDataTypeValues[];
};

export const Questionnaire = ({
	applicationId,
	question,
	onChange,
	register,
	getValues,
	setValue,
	control,
	name,
	nameGroup,
	type,
	allowedValues,
	refetch,
	disabled,
	options,
	values,
}: Props): JSX.Element => {
	const handleOnChange = (value: QuestionnaireValue) => {
		if (onChange) {
			onChange(value);
		}
	};

	const renderComponent = (): JSX.Element => {
		switch (type) {
			case "boolean":
				return (
					<Boolean
						name={name}
						control={control}
						handleOnChange={handleOnChange}
						disabled={disabled}
						options={options}
					/>
				);

			case "sumWithBoolean":
				return (
					<BooleanWithNum
						nameGroup={nameGroup as string[]}
						getValues={getValues}
						register={register}
						setValue={setValue}
						control={control}
						handleOnChange={handleOnChange}
						disabled={disabled}
						options={options}
					/>
				);

			case "mortgageSelect":
				return (
					<MortgageSelect
						nameGroup={nameGroup as string[]}
						getValues={getValues}
						setValue={setValue}
						register={register}
						control={control}
						disabled={disabled}
						options={options}
						handleOnChange={handleOnChange}
					/>
				);

			case "contactSelectWithBoolean":
				return (
					<BooleanWithContact
						nameGroup={nameGroup as string[]}
						getValues={getValues}
						setValue={setValue}
						control={control}
						disabled={disabled}
						handleOnChange={handleOnChange}
					/>
				);

			case "select": {
				return (
					<Select
						name={name as string}
						control={control}
						allowedValues={allowedValues as string[]}
						handleOnChange={handleOnChange}
						disabled={disabled}
					/>
				);
			}

			case "taskCreation": {
				return (
					<QuizTask
						getValues={getValues}
						nameGroup={nameGroup as string[]}
						setValue={setValue}
						control={control}
						handleOnChange={handleOnChange}
						refetch={refetch}
						disabled={disabled}
						options={options}
					/>
				);
			}

			case "multipleTaskCreationRadioButton": {
				return (
					<WithTasks
						applicationId={applicationId}
						nameGroup={nameGroup as string[]}
						values={values}
						getValues={getValues}
						control={control}
						handleOnChange={handleOnChange}
						refetch={refetch}
					/>
				);
			}

			case "number": {
				return (
					<Number
						register={register}
						control={control}
						getValues={getValues}
						inputName={name as string}
						setValue={setValue}
						disabled={disabled}
						handleOnChange={handleOnChange}
					/>
				);
			}

			case "text": {
				return (
					<Text
						register={register}
						control={control}
						getValues={getValues}
						inputName={name as string}
						setValue={setValue}
						disabled={disabled}
						handleOnChange={handleOnChange}
					/>
				);
			}

			case "date": {
				return (
					<DateInput
						name={name as string}
						setValue={setValue}
						getValues={getValues}
						handleOnChange={handleOnChange}
						register={register}
					/>
				);
			}

			default:
				return <>{type}</>;
		}
	};

	return (
		<>
			{type !== "mortgageSelect" && (
				<Underlay className={styles.root}>
					<div className={styles.question}>{question}</div>

					{renderComponent()}
				</Underlay>
			)}
		</>
	);
};
