import cn from "classnames";
import styles from "./index.module.scss";
import { Plug } from "../plug/Plug";
import { ContactView } from "./ContactView";
import { AddContactLabel } from "./AddLabel";
import { ShowsViews } from "../../../../../types";

type Props = {
  isViews: boolean
  item: ShowsViews
  isAgent: boolean
  refetch: () => void
}

export const CardShowOrViewContact = (
  {
    isViews,
    isAgent,
    item,
    refetch
  }: Props): JSX.Element => {
  const onClickContact = (contactId?: string) => {
    window.open(`/contacts?id=${contactId}`, "_blank", "noopener noreferrer");
  }

  // eslint-disable-next-line no-nested-ternary
  const contact = isViews
    ? (!isAgent ? item.sellerContact : item.sellerAgentContact)
    : (!isAgent ? item.buyerContact : item.buyerAgentContact)
  const titleContactCustomer = isViews ? "Продавец объекта:" : "Потенциальный покупатель:"
  const titleContactAgent = isViews ? "Агент продавца:" : "Агент потенциального покупателя:"
  const plugText = contact ? "" : "Контакт отсутствует"

  return (
    <div className={styles.root}>
      <div className={cn(styles.row, {[ styles.header ]: contact !== undefined})}>
        <div className={styles.title}>
          {isAgent ? titleContactAgent : titleContactCustomer}
        </div>
        <AddContactLabel
          showviewId={item.id}
          refetch={refetch}
          contact={contact}
          isAgent={isAgent}
          isViews={isViews}
        />
      </div>
      {contact
        ? <ContactView
          contact={contact}
          onClickContact={onClickContact}
          plugText={plugText}
        />
        : <Plug plugText={plugText}/>
      }
    </div>
  )
}
