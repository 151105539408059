import { Lead } from "../../../../../../../types";

import styles from "./index.module.scss";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

type Props = {
  lead: Lead;
};

export const Footer = ({lead}: Props): JSX.Element => {
  const {shortId, createdAt, creator} = lead;
  const formatFIO = (name?: string, secondName?: string, lastName?: string) =>
    `${lastName ? lastName : ""} ${name ? name.slice(0, 1) : ""}.${secondName ? secondName.slice(0, 1) : ""}`

  return (
    <div className={styles.root}>
      <div className={styles.column}>
        <div className={styles.lightGrey}>
          ID лида:
        </div>
        <div className={styles.darkGrey}>
          {`№ ${shortId}`}
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.lightGrey}>
          Лид создал:
        </div>
        <div className={styles.darkGrey}>
          {formatFIO(creator?.account.name, creator?.account.secondName, creator?.account.lastName)}
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.lightGrey}>
          Дата создания:
        </div>
        <div className={styles.darkGrey}>
          {createdAt ? `${formatDate(createdAt)} в ${formatDate(createdAt, "time")}` : "Не указано"}
        </div>
      </div>
    </div>
  );
};
