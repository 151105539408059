import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/load-statistics/DEFAULT_QN";

type UseLoadStatistics = {
	applicationId: string;
};

export const useLoadStatistics = ({ applicationId }: UseLoadStatistics) => {
	const loadStatistics = () => api.realEstateAd.statistics(applicationId);

	const { data, refetch, remove, isLoading, isFetched } = useQuery(DEFAULT_QN, loadStatistics, {
		enabled: true,
	});

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
	};
};
