import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../types";

import { InfoRow } from "../../../../../../../index";
import {
	AgentBonus,
	FlatCondition,
	Floors,
	Price,
	Communications,
	RoomsForSale,
	Material,
	CheckboxWithText,
	NumberWithUnit,
	CommercialPartsSquare,
	RemoteJKSelect,
} from "../Inputs";
import { RequiredDots } from "../RequiredDots";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const ComplexFields = ({ form, field }: Props): JSX.Element => {
	const {
		label,
		dataType: { type, nameGroup },
	} = field;

	if (!nameGroup) {
		return <></>;
	}

	const complex = {
		agentBonus: <AgentBonus form={form} field={field} />,
		price: <Price form={form} field={field} />,
		decorationsOrRefurbishmentEdit: <FlatCondition form={form} field={field} />,
		communications: <Communications form={form} field={field} />,
		roomsSelect: <RoomsForSale form={form} field={field} />,
		materialMultiple: <Material form={form} field={field} />,
		checkboxWithText: <CheckboxWithText form={form} field={field} />,
		numberWithUnit: <NumberWithUnit form={form} field={field} />,
		commercialPartsSquare: <CommercialPartsSquare form={form} field={field} />,
		remoteJKSelect: <RemoteJKSelect form={form} field={field} />,
	};

	const simple = {
		floors: <Floors form={form} field={field} />,
	};

	return (
		<>
			{complex[type] ? (
				complex[type]
			) : (
				<InfoRow
					label={label}
					labelFont="ubuntu"
					aspect={{ label: 1, content: 1 }}
					classNames={{ root: styles.customInfoRow }}
				>
					<RequiredDots required={field.reqiredFor} />
					{simple[type]}
				</InfoRow>
			)}
		</>
	);
};
