import { RealEstate } from "../../../../../../../types";
import { useMemo } from "react";

import { SelectableItems } from "../../../../../../UI";
import { CardObjectWithPopupMenu } from "../components";

type Props = {
	objectsList: RealEstate[];
	isParser: boolean;
	refetchList?: () => void
};

export const usePreparedObjects = ({ objectsList, isParser, refetchList }: Props): SelectableItems[] =>
	useMemo(
		() =>
			objectsList.map((object) => ({
				value: object.id,
				children: <CardObjectWithPopupMenu object={object} isParser={isParser} refetchList={refetchList}/>,
			})),
		[objectsList, isParser]
	);
