import {
	RealEstateWithSchema,
	RealEstateSchema,
	RealEstatePhoto,
	RealEstateAddPhotoPayload,
	RealEstateUpdatePhotoPayload,
	GetRealEstateFiltersPayload,
	RealEstateFiltersStructure,
	Subway,
	RealEstateGetConfigPayload,
	RealEstateGeneratePresentationPayload,
	RealEstateCreatePresentationResponse,
	PublicPresentation,
	GetClusterPayload,
	RealEstateRotatePhotoPayload, RealEstateUpdatePriceWithDiscount, Condition,
} from "../../../../types";
import { getRealEstateFilters } from "../../helpers";

import { Client } from "../../Client";

export const RealEstateService = {
	get: ({ id }: { id: string }) => Client.get<RealEstateWithSchema>(`/estateObjectStore/${id}`),
	updatePriceWithDiscount: ({id, data}: RealEstateUpdatePriceWithDiscount) => Client.put(
		`/estateObjectStore/${id}/discount`,
		{
			data
		}
	),
	getConfig: (payload: RealEstateGetConfigPayload) => {
		const flatType = payload["additionalProperties.tip_kvartiry"] ?? undefined;
		const flatPath = flatType
			? `&additionalProperties.tip_kvartiry=${payload["additionalProperties.tip_kvartiry"]}`
			: "";
		const categoryPath = `category=${payload.category}&businessProcessId=${payload.businessProcessId}${flatPath}`;

		return Client.get<RealEstateSchema>(`/objectCardStructure/forObject?${categoryPath}`);
	},
	getOfficialData: (id: string) =>
		Client.get(`/estate-objects/remote-selection/findReformaJKH?addressId=${id}`),
	addPhoto: ({ applicationId, data }: RealEstateAddPhotoPayload) =>
		Client.post<RealEstatePhoto[]>(`/client-objects/photo/${applicationId}`, {
			data,
		}),
	updatePhoto: ({ applicationId, data }: RealEstateUpdatePhotoPayload) =>
		Client.put<RealEstatePhoto[]>(`/client-objects/photo/${applicationId}`, {
			data,
		}),
	rotatePhoto: ({ photoId, rotation }: RealEstateRotatePhotoPayload) =>
		Client.patch<RealEstatePhoto>(`/client-objects/photo/${photoId}/rotate?rotation=${rotation}`),
	removePhoto: (photoId: string) => Client.delete(`/client-objects/photo/${photoId}`),
	getFilters: (data: GetRealEstateFiltersPayload) =>
		Client.post<RealEstateFiltersStructure>("/objectCardStructure/getConfigForFilters", data),
	getSubwayList: () => Client.get<Subway[]>("/estate-objects/remote-selection/getSubwayList"),
	archive: (id: string) => Client.patch<RealEstateWithSchema>(`/estateObjectStore/${id}/archive`),
	copyToMyRealEstates: (id: string) => Client.post<RealEstateWithSchema>(`/estateObjectStore/copy/${id}`),
	generatePresentation: (data: RealEstateGeneratePresentationPayload) =>
		Client.post<RealEstateCreatePresentationResponse>("/object-share", data),
	getPresentations: (realEstateId: string) =>
		Client.get<PublicPresentation[]>(`/object-share/${realEstateId}`),
	deletePresentation: (id: string) => Client.delete<unknown>(`/object-share/${id}`),
	getPublicRealEstate: (id: string) => Client.get<RealEstateWithSchema>(`/object-share/public/${id}`),
	findJK: ({ source, query }) =>
		Client.get(`/estate-objects/remote-selection/findJK?feedFormat=${source}&query=${query}`),
	getJk: ({ id }) => Client.get(`/estate-objects/remote-selection/findOneJK?id=${id}`),
	getClusters: ({ payload, search }: GetClusterPayload) => {
		const searchConditions = getRealEstateFilters({ searchPayload: search });

		return Client.post("/estateObjectStore/searchForMap", {
			...payload,
			search: {
				filterType: "and",
				conditions: searchConditions,
			},
		});
	},
	getDoublesFromOwners: (id: string) => Client.post(
		`/estateObjectStore/${id}/doublesFromOwners`,

	),
	getDoublesFromAgency: (id: string) => {
	return 	Client.post(
			`/estateObjectStore/${id}/doublesFromAgency`,
		)
	},
	getRealEstateCollections: (conditions: Condition[]) => Client.post(
		`/application/collection/search`,
		{
			search: {
				filterType: "and",
				conditions,
				take: 50,
				skip: 0
			}
		}
	),
	}
;
