import { useState } from "react";
import cn from "classnames";

import { NewDate } from "../../NewDate";

import styles from "../index.module.scss";

type Props = {
	form: any;
	isViews?: boolean;
};

export const DateOfView = ({ form, isViews = false }: Props): JSX.Element => {
	const [active, setActive] = useState(false);

	return (
		<div className={styles.wrapper}>
			<div onClick={() => setActive(!active)} className={cn(styles.button, { [styles.active]: active })}>
				{isViews ? "Укажите время просмотра" : "Укажите время показа"}
			</div>
			{active && (
				<div className={styles.buttonBody}>
					<NewDate
						form={{
							...form,
							calendarName: "calendar",
							timeName: "time",
						}}
					/>
				</div>
			)}
		</div>
	);
};
