import cn from "classnames";

import { Avatar } from "../Avatar";
import { prepareShortUserOutput } from "../../../../utils/transformation";

import styles from "./index.module.scss";

type Props = {
	lastName: string;
	name: string;
	secondName?: string;
	pseudonym?: string;
	phone?: string;
	withAvatar?: {
		imgSrc: string;
	};
	options?: {
		nameFont?: "cera" | "ubuntu";
		phoneFont?: "cera" | "ubuntu";
	};
	classNames?: {
		root?: string;
		displayName?: string;
		avatar?: string;
		noImg?: string;
		phone?: string;
	};
};

export const NameWithPhone = ({
	lastName,
	name,
	pseudonym,
	secondName,
	phone,
	withAvatar,
	options,
	classNames,
}: Props): JSX.Element => {
	const displayName = prepareShortUserOutput({ lastName, name, secondName });

	return (
		<div className={cn(styles.root, classNames?.root)}>
			{withAvatar && (
				<Avatar
					imgSrc={withAvatar.imgSrc}
					lastName={lastName}
					name={name}
					classNames={{
						root: cn(styles.avatar, classNames?.avatar),
						noImgBlock: classNames?.noImg,
					}}
				/>
			)}

			<div>
				<div
					className={cn(styles.name, classNames?.displayName, {
						[styles.nameFontCera]: options?.nameFont === "cera",
					})}
				>
					{pseudonym ? pseudonym : ""}
					{displayName}
				</div>

				{phone && (
					<div
						className={cn(styles.phone, classNames?.phone, {
							[styles.phoneFontUbuntu]: options?.phoneFont === "ubuntu",
						})}
					>
						{phone}
					</div>
				)}
			</div>
		</div>
	);
};
