import { useMutation } from "react-query";
import { TargetObjectToLead } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/lead/target-object/DEFAULT_MN";

type UseTargetObject = {
	onSuccess?: (data: any) => void;
	onError?: () => void;
};

export const useTargetObject = ({ onSuccess, onError }: UseTargetObject) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: TargetObjectToLead) => api.leads.addTargetObject(data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
