import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate/load-subways/DEFAULT_QN";

export const useLoadSubways = () => {
	const loadSubways = () => api.realEstate.getSubwayList();

	const { data, refetch, isLoading } = useQuery(DEFAULT_QN, loadSubways);

	return {
		data: data?.data || [],
		isLoading,
		refetch,
	};
};
