import { formatter } from "../../../../../../../../../map-utils/utils";
import { ObjectData } from "../../../../../../../../../../../../types";

export const RealEstateDesc = ({
	id,
	desc,
	title,
	price,
	pricePerUnit,
	changeOfPrice,
}: Partial<ObjectData>): JSX.Element => (
	<>
		<p className="real-estate-balloon__title">
			<button id={`real-estate-balloon__button-${id}`} className="real-estate-balloon__button" type="button">
				{title || "Нет заголовка"}
			</button>
			<button type="button" className="real-estate-balloon__object-icon real-estate-balloon__object-info">
				<span className="real-estate-balloon__desc-full-text">{desc || "Нет описания"}</span>
			</button>
		</p>
		<p className="real-estate-balloon__desc">
			<span className="real-estate-balloon__desc-text">{desc || "Нет описания"}</span>
		</p>
		<div className="real-estate-balloon__prices">
			<p className={`real-estate-balloon__full-price ${changeOfPrice}`}>{`${formatter.format(price!)} ₽`}</p>
			<p className="real-estate-balloon__price-per-unit">{`${formatter.format(pricePerUnit!)} ₽/м²`}</p>
		</div>
	</>
);
