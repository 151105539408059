import { useEffect } from "react";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { formatPhone } from "../../../../../utils/formatters";
import { prepareShortUserOutput } from "../../../../../utils/transformation";
import { useOpenApplicationCard } from "../../../../../utils/application";
import { useLoadApplications } from "../../../../../service/api/queries";
import { UserData } from "../../../../../types";
import styles from "./index.module.scss";

type UserLocalData = UserData & { name: string };

export const AlertApplications = (): JSX.Element => {
  const {list: applicationsList, setFilter, isFetching} = useLoadApplications({enabled: true});

  const {handleClickApplication} = useOpenApplicationCard({});

  useEffect(() => {
    setFilter({requiresAttention: true});
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {"Ваши заявки требующие внимания "}

        {!isFetching && <span>{`(${applicationsList.length})`}</span>}
      </div>

      <Loader height={250} style={{display: isFetching ? "block" : "none"}}/>

      {!applicationsList.length && !isFetching && (
        <div className={styles.noAlertApplications}>Заявки, требующие внимания, отсутствуют</div>
      )}

      <div className={styles.body} style={{display: isFetching ? "none" : "block"}}>
        {applicationsList.map((application) => {
          const {
            id,
            type: {whoIsClient, typeName},
            clientObject,
            contact
          } = application;
          const {addressLabel} = clientObject || {};

          const phone = contact?.phone
          const user: UserLocalData = (contact || {}) as UserLocalData;

          const {name = "", secondName = "", lastName = ""} = user;

          const openApplication = () => handleClickApplication({applicationId: id, whoIsClient});

          return (
            <div key={id} className={styles.block} onClick={openApplication}>
              <div className={styles.address}>{`${typeName} ${addressLabel || ""}`}</div>

              <div className={styles.userInfo}>
                <span>{formatPhone(phone || "")}</span>
                {Boolean(name) && (
                  <span className={styles.name}>
										{prepareShortUserOutput({name, secondName, lastName})}
									</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
