import styles from "./index.module.scss";
import { useTasksAttentionContext } from "../../../../../../../../../service/context/TasksAttention";

export const TasksAttentionHeader = (): JSX.Element => {
	const { total } = useTasksAttentionContext();

	const countLabel = `(${total})`;

	return (
		<h3 className={styles.root}>
			<span className={styles.title}>Задачи, требующие внимания</span>

			<span className={styles.count}>{countLabel}</span>
		</h3>
	);
};
