import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { ApplicationCardResponse, EditClientEstate, KindType, RealEstate } from "../../../../types";
import { useRouteMatch } from "react-router";
import { ApplicationInfo } from "components/pages/Application/ApplicationCard/components";
import { SidebarLayout, XYNavLayout } from "../../../UI";
import { RealEstateAd, RealEstateDocument, RealEstateEdit, RealEstateViewWithTabs } from "../mode";
import { useGetNavigation, useMapAdjust } from "../helpers";
import {
	LOAD_APPLICATION_REAL_ESTATE_DEFAULT_QN,
	LOAD_APPLICATION_REAL_ESTATE_EDIT_QN,
	useLoadApplicationRealEstate,
} from "../../../../service/api/queries";
import { closeDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { selectCurrentAccount, selectObjectEdit, useSelect } from "../../../../service/redux/selectors";
import { useQueryParams } from "../../../../utils/hooks";
import { paths } from "../../../../utils/paths";
import { useLoadApplication } from "components/pages/Application/ApplicationCard/helpers";
import { ShowsAndViews } from "components/pages/ShowsAndViews";

type MatchParams = {
	id: string;
};

type Props = {
	applicationId?: string;
	onClose?: () => void;
};

const RealEstatePageRender = ({ applicationId, onClose }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { coord, dontMarkOnMap } = useSelect(selectObjectEdit);

	const {
		queryParams: { mode },
	} = useQueryParams<{ mode: string }>([{ name: "mode" }]);

	const { currentAccount } = useSelect(selectCurrentAccount);
	const mapZoom = Number(currentAccount?.company?.mapZoom);
	const { mapCenter } = currentAccount?.settings || {};

	const getMapCenter = (): [number, number] | undefined =>
		mapCenter ? [mapCenter.lat, mapCenter.lon] : undefined;

	const match = useRouteMatch<MatchParams>();

	const { id } = match.params;

	const APPLICATION_ID = applicationId ?? id;

	const queryName =
		mode === "edit" ? LOAD_APPLICATION_REAL_ESTATE_EDIT_QN : LOAD_APPLICATION_REAL_ESTATE_DEFAULT_QN;

	const { realEstate, isFetching, refetch, remove } = useLoadApplicationRealEstate({
		id: APPLICATION_ID,
		queryName,
	});

	const isDisableEdit = !realEstate?.permissions?.edit;

	const { onAdNav, navigationY, blocksIds } = useGetNavigation({
		realEstate,
		isDisableEdit,
	});

	const isEditOrCreate = mode === "edit";
	const { onMapClick } = useMapAdjust({ realEstate, isEditOrCreate });

	const onCloseClick = () => {
		dispatch(closeDrawer());
		if (onClose) {
			onClose();
		}
	};

	const {
		application,
		refetch: refetchApplication,
	} = useLoadApplication({ applicationId: APPLICATION_ID});

	const refetchAll = () => {
		if (refetch) {
 			refetch();
		}
		refetchApplication();
	};

	const clientEstate: RealEstate | undefined = useMemo(() => {
		if (realEstate) {
			const copy: EditClientEstate = {
				...realEstate,
				applications: [],
				activeApplications: {},
			};

			if (copy?.schema) {
				delete copy.schema;
			}

			return copy;
		}

		return undefined;
	}, [realEstate]);


	return (
		<XYNavLayout
			yNavigation={navigationY}
			yNavigationTop={64}
			activeYTab={mode}
			onCloseClick={applicationId ? onCloseClick : undefined}
			withGoBack={{ defaultBackPath: `${paths.objectsPath.list}` }}
			navVariant="grey"
		>
			{APPLICATION_ID && (
				<>
					{mode === "view" && (
						<RealEstateViewWithTabs
							id={APPLICATION_ID}
							blocksIds={blocksIds}
							kind={KindType.APPLICATION}
						/>
					)}
					{mode === "edit" && clientEstate && (
						<RealEstateEdit
							id={APPLICATION_ID}
							realEstate={clientEstate}
							refetch={refetch}
							remove={remove}
							isFetching={isFetching}
						/>
					)}
					{mode === "ad" && (
						<RealEstateAd
							id={APPLICATION_ID}
							onNav={onAdNav}
							realEstate={realEstate}
							refetch={refetch}
							isFetchingObject={isFetching}
						/>
					)}
						{mode === "leads" && application && application?.creator && clientEstate && (
							<ApplicationInfo
								application={application as ApplicationCardResponse}
								applicationId={APPLICATION_ID}
								refetch={refetchAll}
							/>
					)}
						{mode === "impressionsAndViews" && application && application?.creator && clientEstate && (
							<ShowsAndViews 
								applicationId={APPLICATION_ID} 
								type={realEstate?.type.label === "sale" ? "views" : "shows"} 
						/>
					)}
					{mode === "documents" && <RealEstateDocument />}
				</>
			)}
		</XYNavLayout>
	);
};

export const RealEstatePage = ({ applicationId, onClose }: Props): JSX.Element => (
	<>
		{applicationId ? (
			<RealEstatePageRender applicationId={applicationId} onClose={onClose} />
		) : (
			<SidebarLayout fullSize>
				<RealEstatePageRender />
			</SidebarLayout>
		)}
	</>
);
