import { useDispatch } from "react-redux";
import { openDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { ProductCard } from "../ProductCard";

type Return = {
  openProductDrawer: (officeId: string) => void;
};

type Props = {
  onClose?: () => void;
  refetch?: () => void;
};

type UseProductCard = (props: Props) => Return;

export const useOpenProductCard: UseProductCard = ({onClose, refetch}) => {
  const dispatch = useDispatch();

  const openProductDrawer = (productId: string) => {
    dispatch(
      openDrawer({
        children: <ProductCard productId={productId} refetch={refetch}/>,
        width: 800,
        onClose,
      })
    );
  };

  return {
    openProductDrawer,
  };
};
