import { MouseEvent, ReactNode, useEffect, useState } from "react";
import cn from "classnames";

import { Popup, PopupProps } from "../Popup";

import { ReactComponent as DotsIconGray } from "../../../../assets/icons/dotsGray.svg";
import { ReactComponent as DotsIcon } from "../../../../assets/icons/dots.svg";
import styles from "./index.module.scss";

export type PopupMenuItems = {
  label: string;
  action: () => void;
  disabled?: boolean;
  className?: string;
  icon?: ReactNode
};

type Props = {
  menuItems: PopupMenuItems[];
  contextClick?: boolean;
  onClose?: () => void;
  source?: "application" | "parser"
} & Pick<PopupProps, "classNames" | "options">;

export const PopupMenu = (
  {
    menuItems,
    contextClick = false,
    onClose,
    classNames,
    options,
    source,
  }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(contextClick);
  }, [contextClick]);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const onDotsClick = (event: MouseEvent<HTMLOrSVGElement>) => {
    event.stopPropagation();

    if (isOpen) {
      handleClose();
    } else {
      setIsOpen(true);
    }
  };

  const handleClick = (action: () => void) => {
    action();
    handleClose();
  };

  return (
    <Popup
      classNames={classNames}
      options={options}
      openTrigger={
        source
          ? <DotsIconGray
            onClick={onDotsClick}
            onMouseUp={(event: MouseEvent<HTMLOrSVGElement>) => event.stopPropagation()}
          />
          : <DotsIcon
            onClick={onDotsClick}
            onMouseUp={(event: MouseEvent<HTMLOrSVGElement>) => event.stopPropagation()}
          />
      }
      outerOpenState={{
        outerIsOpen: isOpen,
        outerSetIsOpen: setIsOpen,
      }}
      onClickOutside={handleClose}
    >
      {menuItems.map((pop) => (
        <div
          key={pop.label}
          onClick={() => {
            if (!pop.disabled) {
              handleClick(pop.action);
            }
          }}
          className={cn(styles.popupItem, pop.className, {
            [ styles.popupItemDisabled ]: pop.disabled,
          })}
        >
          {pop.icon}
          {pop.label}
        </div>
      ))}
    </Popup>
  );
};
