import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { formatPhone } from "../../../../../../utils/formatters";
import { toastInfo } from "../../../../../UI";

export const useRegisteredPhoneRedirect = (phoneIsRegistered: boolean, accountPhone: string) => {
	const history = useHistory();

	useEffect(() => {
		// Redirect to /login if phone is registered
		if (phoneIsRegistered) {
			const phone = formatPhone(accountPhone);

			toastInfo({
				text: `Телефон ${phone} уже зарегистрирован. Войдите в систему чтобы присоединиться к компании!`,
				autoClose: false,
			});

			history.replace("/login");
		}
	}, [phoneIsRegistered]);
};
