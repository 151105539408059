import { Call, User } from "../../../../../types";

import { ChooserCompanyUser } from "../ChooserCompanyUser";
import { NotCalls } from "../NotCalls";

import styles from "./index.module.scss";

type Props = {
	calls: Call[];
	canView: boolean;
	id: string;
	users: User[];
};

export const CallsWithCompany = ({ calls, canView, id, users }: Props): JSX.Element => (
	<>
		{canView && (
			<div className={styles.root} id={id}>
				<div className={styles.title}>История звонков с Агентством</div>
				{calls.length <= 0 && <NotCalls />}

				{calls.length > 0 &&
					calls.map((call) => <ChooserCompanyUser call={call} key={call.id} users={users} />)}
			</div>
		)}
	</>
);
