import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, P } from "@sdelka_crm/sdelka-crm-component-library";

import { ConfirmAgreement } from "./ConfirmAgreement";
import { useLoadCompanyPublic, useLoadUserInvites } from "../../../../../service/api/queries";
import { useSelect, selectRegisterSteps } from "../../../../../service/redux/selectors";
import {
	setCompanyData,
	setSawInvites,
	setShowCompany,
	setStep,
} from "../../../../../service/redux/modules/registration-steps/reg-steps.action";
import { useInviteDecline } from "../../../../../service/api/mutations";
import { InviteUserInfo } from "../../../../../types";
import { TokenService } from "service/api/services/inner/TokenService";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info.svg";
import styles from "./index.module.scss";
import { HasInvitesModal } from "../FinishRegistration/hasInvitesModal";

export const HasInvites = (): JSX.Element => {
	const dispatch = useDispatch();

	const { registrationData } = useSelect(selectRegisterSteps);

	const token = TokenService.getCurrentToken()
	const appeal = registrationData?.name ? `${registrationData.name}, тебя приглашают` : "Вас приглашают";

	const { invites, refetch } = useLoadUserInvites();
	
	const [hoveredInvite, setHoveredInvite] = useState("");
	const [selectedInvite, setSelectedInvite] = useState<InviteUserInfo | undefined>(undefined);

	const { mutate } = useInviteDecline({
		onSuccess: () => refetch(),
	});

	const declineInvite = (inviteId: string) => mutate(inviteId);

	useLoadCompanyPublic({
		id: hoveredInvite,
		onSuccess: (data) => dispatch(setCompanyData(data.data)),
	});

	const handleContinueRegistration = () => {
		dispatch(setSawInvites(true));
		// dispatch(setStep("choose-role"));
	};

	const show = (id: string) => {
		setHoveredInvite(id);
		dispatch(setShowCompany(true));
	};

	const hide = () => {
		setHoveredInvite("");
		dispatch(setShowCompany(false));
		dispatch(setCompanyData(undefined));
	};

	const { openHasInvitesModal } = HasInvitesModal();

	return (
		<div className={styles.root}>
			<h2>
			Компании, пригласившие вас
			</h2>

			{!selectedInvite ? (
				<div className={styles.invitesList}>
					{invites?.map((invite) => (
						<div className={styles.invitesListItem} key={invite.inviteId}>
							<div className={styles.invitesListItemCompany}>
								<div className={styles.invitesListItemCompanyName}>
									<span className={styles.invitesListItemName}>{invite.companyName}</span>
									
									<div
										onMouseEnter={() => show(invite.companyId)}
										onMouseLeave={hide}
										className={styles.invitesListItemInfo}
									>
										<InfoIcon />
									</div>
								</div>
							
								<p className={styles.positionName}>{invite.positionName}</p>
							</div>

							<div className={styles.invitesListItemManipulation}>
								<Button type="button" variant="text" onClick={() => {
									setSelectedInvite(invite)
									openHasInvitesModal({invite, setSelectedInvite})
									}}>
									Принять
								</Button>

							</div>
						</div>
					))}
					{!invites.length && <p className={styles.no_invites}>У вас пока нет приглашений от компаний</p>}
				</div>
			) : (
				// <ConfirmAgreement selectedInvite={selectedInvite} setSelectedInvite={setSelectedInvite} />
				<div></div>
			)}

		</div>
	);
};
