import { UserPreview } from "../../../../../types";
import { UserBlock } from "./renderUserBlock";

type Props = {
  executor: {
    userId?: string
    user?: UserPreview
  }
}

export	const RenderExecutor = ({executor}: Props): JSX.Element => {
  const {
    name,
    lastName,
    secondName
  } = executor.user || {name: "", secondName: "", lastName: ""}

  if (executor) {
    const href = `employees?userId=${executor.userId}`;

    return UserBlock({name, lastName, secondName,  href});
  }

  return <div>Не назначен</div>;
};