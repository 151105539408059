import { SET_HOVERED_RE_IMG, CLEAN_UP, CompilationAction } from "./compilation.action";

import { CompilationTInitialState } from "../../types";

const initialState: CompilationTInitialState = {
	hoveredRealEstateImg: undefined,
};

export const compilationReducer = (
	state = initialState,
	action: CompilationAction
): CompilationTInitialState => {
	switch (action.type) {
		case SET_HOVERED_RE_IMG: {
			return {
				...state,
				hoveredRealEstateImg: action.hoveredRealEstateImg,
			};
		}
		case CLEAN_UP: {
			return {
				...state,
				hoveredRealEstateImg: undefined,
			};
		}
		default:
			return state;
	}
};
