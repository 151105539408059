import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAccount } from "../../../../../service/redux/selectors";
import { useGetUser } from "../../../../../service/api/mutations";
import { PersonalCard } from "../../index";
import { addTabInDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";
import { useEffect } from "react";
import { MyOrders } from "../my-orders/MyOrders";

type Props = {
  userId?: string;
  refetch?: () => void;
}

export const PersonalCardWrapper = (
  {
    userId,
    refetch,
  }: Props) => {
  const dispatch = useDispatch();
  const {currentAccount} = useSelector(selectCurrentAccount);
  const {id: currentUserId} = currentAccount || {};
  const isCurrentUser = currentUserId === userId
  const {user, isFetching, refetch: refetchUser} = useGetUser({userId: userId || currentUserId});
  const titleView = isCurrentUser ? "Мой профиль" : "Сотрудник"

  const refetchAll = () => {
    refetch?.();
    refetchUser?.();
  };

  const viewNav = {
    id: "view",
    label: titleView,
    changeTab: () => {
    },
    height: 162,
  }
  const viewChildren = (
    <PersonalCard
      refetchAll={refetchAll}
      isFetching={isFetching}
      user={user}
    />
  )
  const myOrdersNav = {
    id: "myOrders",
    label: "Мои заказы",
    changeTab: () => {
    },
    height: 162,
  }
  const myOrdersChildren = (
    <MyOrders/>
  )

  useEffect(() => {
    if (user) {
      dispatch(addTabInDrawer({
        newChildren: viewChildren,
        newNavigation: viewNav
      }))
      if (isCurrentUser) {
        dispatch(addTabInDrawer({
          newChildren: myOrdersChildren,
          newNavigation: myOrdersNav
        }))
      }
    }
  }, [currentAccount, user])

  return (
    <>
    </>
  )
}