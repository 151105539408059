import { ClusterType } from "../Map";
import { ClusterPoint } from "./ClusterPoint";
import useYMaps from "../map-utils/useYMaps";

type Props = {
	clusters?: ClusterType[];
};

/**
 * Компонент отображает кластеры недвижимости.
 */
export const Points = ({ clusters }: Props): JSX.Element => {
	const { mapInstance } = useYMaps();

	const clickOnCluster = (coords: number[]) => {
		const zoom = mapInstance.getZoom();
		mapInstance.setCenter(coords, zoom + 2, {
			checkZoomRange: true,
		});
	};

	return (
		<>
			{clusters?.map((cluster, index) => (
				// @ts-ignore
				<ClusterPoint
					/* eslint-disable-next-line react/no-array-index-key */
					key={index}
					cluster={cluster}
					clickOnCluster={clickOnCluster}
				/>
			))}
		</>
	);
};
