import { AxiosResponse } from "axios";
import { UseMutateAsyncFunction, UseMutateFunction, useMutation } from "react-query";

import { api } from "../../index";
import { GetClusterPayload } from "../../../../types";

type Props = {
	onSuccess?: () => void;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<any>, unknown, GetClusterPayload, unknown>;
	mutateAsync: UseMutateAsyncFunction<AxiosResponse<any>, unknown, GetClusterPayload, unknown>;
	isLoading: boolean;
};

type UseGetParsedRealEstateClusters = (props: Props) => Return;

export const useGetParsedRealEstateClusters: UseGetParsedRealEstateClusters = ({ onSuccess }) => {
	const mutation = useMutation("getClusters", {
		mutationFn: ({ payload, search }: GetClusterPayload) => api.realEstate.getClusters({ payload, search }),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		mutateAsync: mutation.mutateAsync,
		isLoading: mutation.isLoading,
	};
};
