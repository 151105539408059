import { BusinessProcessSettings } from "../../../../types";

export const SET_BUSINESS_PROCESSES = "sdelka/business-process/SET_BUSINESS_PROCESSES";
export const CLEAN_UP = "sdelka/business-process/CLEAN_UP";

export type BusinessProcessAction =
	| {
			type: typeof SET_BUSINESS_PROCESSES;
			businessProcesses: BusinessProcessSettings[];
	  }
	| { type: typeof CLEAN_UP };

export const setBusinessProcesses = (
	businessProcesses: BusinessProcessSettings[]
): BusinessProcessAction => ({
	type: SET_BUSINESS_PROCESSES,
	businessProcesses,
});

export const cleanUp = (): BusinessProcessAction => ({
	type: CLEAN_UP,
});
