import { TaskLeftMenuFilter } from "../../../../../../../../../../types";

export type LeftFilter = {
	name: TaskLeftMenuFilter;
	label: string;
	subfilters?: readonly LeftFilter[];
};

export const filters: readonly LeftFilter[] = [
	{
		name: "active",
		label: "Активные",
		subfilters: [
			{
				name: "missed",
				label: "Просроченные",
			},
			{
				name: "hot",
				label: "Горящие",
			},
			{
				name: "inWork",
				label: "В работе",
			},
		],
	},
	{
		name: "returned",
		label: "Возвращенные",
	},
	{
		name: "finished",
		label: "Завершенные",
		subfilters: [
			{
				name: "success",
				label: "Успешные",
			},
			{
				name: "failed",
				label: "Неуспешные",
			},
		],
	},
	{
		name: "paused",
		label: "На паузе",
	},
	{
		name: "checking",
		label: "На проверке",
	},
] as const;
