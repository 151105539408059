import styles from "./index.module.scss";
import { ReactComponent as AlertIcon } from "../../../../../../assets/icons/alert-info.svg";

type Props = {
	errorsLabel?: string[];
};

export const StageErrors = ({ errorsLabel }: Props): JSX.Element => (
	<>
		{errorsLabel && errorsLabel?.length > 0 && (
			<div className={styles.errors}>
				<div className={styles.title}>
					<AlertIcon height={17} width={20} className={styles.icon} />
					Для перехода на следующий этап не выполнены условия!
				</div>
				<ol>
					{errorsLabel?.map((error) => (
						<li key={error}>{error}</li>
					))}
				</ol>
			</div>
		)}
	</>
);
