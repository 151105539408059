import { UseMutateFunction, useMutation } from "react-query";
import { AxiosResponse } from "axios";
import {
	PlatformDescriptionIdData,
	SendObjectToFeedResponse,
	DateBorders,
	PayMethod,
	CianTariff,
} from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/real-estate-ad/start-ad/DEFAULT_MN";

type Props = {
	onSuccess?: (
		data: AxiosResponse<SendObjectToFeedResponse>,
		variables: PlatformDescriptionIdData & DateBorders & PayMethod & { tariff?: CianTariff },
		context: unknown
	) => void;

	onError?: (
		data: AxiosResponse<SendObjectToFeedResponse>,
		variables: PlatformDescriptionIdData & DateBorders & PayMethod & { tariff?: CianTariff },
		context: unknown
	) => void;
};

type Return = {
	mutate: UseMutateFunction<
		AxiosResponse<SendObjectToFeedResponse>,
		unknown,
		PlatformDescriptionIdData & DateBorders & PayMethod & { tariff?: CianTariff },
		unknown
	>;
	isLoading: boolean;
};

type UseStartAd = (props: Props) => Return;
export const useStartAd: UseStartAd = ({ onSuccess, onError }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: PlatformDescriptionIdData & DateBorders & PayMethod & { tariff?: CianTariff }) =>
			api.realEstateAd.startAd(data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
