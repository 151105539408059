import { AxiosResponse } from "axios";
import { UseMutationResult, useMutation } from "react-query";

import { api } from "../../index";
import { CallCenterResponseData, MarkSuccessRequestArgs } from "../../../../types";

const mutationKey = "/sdelka/callCenterChecking/success";

type Response = AxiosResponse<CallCenterResponseData>;

type Props = {
	onSuccess?: (data: Response) => void;
	onError?: (error: unknown) => void;
};

type Return = UseMutationResult<
	AxiosResponse<CallCenterResponseData>,
	unknown,
	MarkSuccessRequestArgs,
	unknown
>;

export const useMarkSuccess = ({ onSuccess, onError }: Props): Return =>
	useMutation({
		mutationFn: api.callCenter.markSuccess,
		mutationKey,
		onSuccess,
		onError,
	});
