import { ApplicationInListV2, KindType } from "../../../types";
import { CardApplication } from "../../../components/UI/Card/card-application";
import styles from "./index.module.scss"

type Props = {
  dots?: boolean;
  withStage?: boolean;
  contextClick?: string;
  refetchApplications?: () => void;
  kind?: KindType
};

export const useTableApplicationColumns = ({withStage, contextClick, kind}: Props) => [
    {
      accessor: "cardApplication",
      render: ({row}: { row: ApplicationInListV2 }) => (
          <CardApplication
            kind={kind}
            application={row}
            contextClick={contextClick}
            withStage={withStage}
            classNames={{
              root: styles.appCard
            }}
            canOpenANewTab
          />
        ),
    }
  ];
