import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { PresentationsList, GenerateForm, LinkGenerator } from "./components";
import { createLink } from "./helpers";
import { openModal } from "../../../../service/redux/modules/modal/modal.action";
import { useRealEstateGeneratePresentation } from "../../../../service/api/mutations";
import { formatThousand } from "../../../formatters";
import {
	ParserPresentationPayload,
	RealEstateCreatePresentationResponse,
	ApplicationGeneratePresentationPayload,
} from "../../../../types";
import { AxiosResponse } from "axios";

type Return = {
	handleCreatePresentation: () => void;
};

type UseCreatePresentation = {
	applicationId?: string;
	parserObjectId?: string;
	currentPrice: number;
};

export const useCreatePresentation = ({
	applicationId,
	parserObjectId,
	currentPrice,
}: UseCreatePresentation): Return => {
	const [presentationLink, setPresentationLink] = useState("");
	const dispatch = useDispatch();
	const {
		register,
		setValue,
		control,
		handleSubmit,
		formState: { isSubmitted },
	} = useForm({
		defaultValues: { pricePresentation: formatThousand(currentPrice) },
	});

	const onSuccess = (response: AxiosResponse<RealEstateCreatePresentationResponse>) => {
		const { data } = response;

		setPresentationLink(createLink(data.id));
	};

	const onError = (error: unknown) => console.info(`error - ${error}`);

	const { mutate, isLoading } = useRealEstateGeneratePresentation({
		onSuccess,
		onError,
	});

	const onSubmit = (values: { pricePresentation: string }) => {
		const price = Number(values.pricePresentation);

		if (applicationId) {
			const payload: ApplicationGeneratePresentationPayload = {
				objectApplicationId: applicationId,
				price,
			};

			mutate(payload);
		} else if (parserObjectId) {
			const payload: ParserPresentationPayload = {
				parserObjectId,
				price,
			};

			mutate(payload);
		}
	};

	const id = applicationId || parserObjectId;

	const handleCreatePresentation = () => {
		dispatch(
			openModal({
				body: (
					<form onSubmit={handleSubmit(onSubmit)}>
						<GenerateForm
							register={register}
							setValue={setValue}
							control={control}
							currentPrice={currentPrice}
						/>

						<LinkGenerator
							presentationLink={presentationLink}
							disabledGenerateButton={isLoading || isSubmitted}
						/>

						{id && <PresentationsList id={id} />}
					</form>
				),
				width: 625,
			})
		);
	};

	useEffect(() => {
		if (presentationLink) {
			handleCreatePresentation();
		}
	}, [presentationLink]);

	return { handleCreatePresentation };
};
