import cn from "classnames";

import { Tag } from "../../index";

import styles from "./index.module.scss";

type Props = {
	title: string;
	address: string;
	tags: string[];
	img?: string;
	classNames?: {
		wrapper?: string;
		image?: string;
		title?: string;
		address?: string;
		tag?: string;
	};
};

export const CardParsedObject = ({ title, address, tags, img, classNames }: Props): JSX.Element => (
	<div className={cn(styles.wrapper, classNames?.wrapper)}>
		{img && (
			<div>
				<img className={cn(styles.image, classNames?.image)} src={img} alt={title} />
			</div>
		)}
		<div>
			<div className={cn(styles.title, classNames?.title)}>{title}</div>
			<div className={cn(styles.address, classNames?.address)}>{address}</div>
			<div className={styles.tags}>
				{tags.map((tag) => (
					<Tag key={tag} classNames={{ wrapper: classNames?.tag }}>
						{tag}
					</Tag>
				))}
			</div>
		</div>
	</div>
);
