import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/pre-application/delete-application-contacts/DEFAULT_MN";

type UseDeleteContacts = {
  onSuccess?: (data: any) => void;
  onError?: () => void
};

export const useDeleteContactsPreApp = ({onSuccess, onError}: UseDeleteContacts) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (data: { applicationId?: string; id: string }) =>
      api.PreApplicationService.deleteContact(data),
    onSuccess,
    onError
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    error: mutation.error
  };
};
