import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/settings/load-businsess-process-sources/DEFAULT_QN";

export const useLoadBusinessProcessSources = () => {
	const loadBusinessProcessSources = () => api.settings.businessProcessSources();

	const { data } = useQuery([DEFAULT_QN], loadBusinessProcessSources);

	return { data: data?.data || [] };
};
