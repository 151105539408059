import { useWatch } from "react-hook-form";

import { useTaskFormContext } from "../../../../../../service/context/TaskForm";

export const useWatchers = () => {
	const { getValues, control } = useTaskFormContext();

	const remindForName = "remindFor";


	const remindForCurrentWatcher = String(
		useWatch({
			name: remindForName,
			control,
			defaultValue: getValues(remindForName),
		})
	);

	const remindForWatcher = getValues?.(remindForName) ? remindForCurrentWatcher : undefined;

	return {
		remindForWatcher,
	};
};
