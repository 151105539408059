import { Client } from "../../Client";
import {
  AppliedProductsResponse,
  AvailableToPurchaseResponse,
  ProductListResponse,
  ProductsBuyHistoryResponse,
  PurchasedProductsResponse
} from "../../../../types/product";

export const ServicesService = {
  list: () => Client.get<ProductListResponse>(`/services`),
  get: (id: string) => Client.get(`/services/${id}`),
  getPurchased: (applicationId?: string) =>
    Client.post<PurchasedProductsResponse>(`/services/purchased${applicationId ? "?applicationId=" : ""}${applicationId || ""}`),
  getAvailableToPurchase: (applicationId?: string) =>
    Client.post<AvailableToPurchaseResponse>(`/services/available${applicationId ? "?applicationId=" : ""}${applicationId || ""}`),

  getAppliedServicesHistory: () => Client.post<AppliedProductsResponse>(`/services/appliedHistory`),

  getBuyHistory: () => Client.post<ProductsBuyHistoryResponse>(`/services/buyHistory`)
}