import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider, setLogger } from "react-query";

import { ClientContext } from "./service/context/ClientContext";
import { App } from "./App";
import { Client } from "./service/api/Client";
import store from "./service/redux/store";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

// mute all loggers from react-query
setLogger({
	log: () => {},
	warn: () => {},
	error: () => {},
});

ReactDOM.render(
	<StrictMode>
		<BrowserRouter>
			<ClientContext.Provider value={Client}>
				<Provider store={store}>
					<QueryClientProvider client={queryClient}>
						<App />
					</QueryClientProvider>
				</Provider>
			</ClientContext.Provider>
		</BrowserRouter>
	</StrictMode>,
	document.getElementById("root")
);
