import { Client } from "../../Client";
import { FindAddressDetailed } from "../../../../types";

export const AddressService = {
	findAddressDetailed: (addressOrPoint: string) =>
		Client.get<FindAddressDetailed[]>(`/address/findAddressDetailed?addressOrPoint=${addressOrPoint}`),

	findAddressPoint: (addressOrPoint: string) =>
		Client.get<FindAddressDetailed[]>(`/address/findPointDetailed?point=${addressOrPoint}`),
};
