import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Avatar, RoundInput } from "../../../../index";
import { useLoadOffices, useLoadPositions } from "../../../../../../service/api/queries";
import { SideContainer } from "../../SideContainer";
import { setOpenOffices } from "../../../../../../service/redux/modules/sidebar/sidebar.action";
import { selectSideBar, useSelect } from "../../../../../../service/redux/selectors";
import { authAsEmployeeThunk } from "../../../../../../service/redux/modules/auth/auth.thunk";

import { ReactComponent as Close } from "../../../../../../assets/icons/close.svg";
import styles from "./index.module.scss";
import { useOfficesWithoutMe } from "./helpers";

export const AccountSwitcherOffices = (): JSX.Element => {
	const dispatch = useDispatch();
	const { isAccountsOpen, isOfficesOpen } = useSelect(selectSideBar);
	const { offices } = useLoadOffices({});
	const { getPositionName } = useLoadPositions({});
	const [searchOffices, setSearchOffices] = useState(offices);

	const officesWithoutMe = useOfficesWithoutMe({ searchOffices });

	useEffect(() => {
		setSearchOffices(offices);
	}, [offices.length]);

	const { register } = useForm();

	const onSearch = (value: string) => {
		//TODO починить этот костыль как будет время
		if (value === "+7 (914) 777 77 77") {
			return
		}
		if (value) {
			setSearchOffices(
				offices?.filter((searchOffice) => searchOffice.name.toLowerCase().match(value.toLowerCase()))
			);
		} else {
			setSearchOffices(offices);
		}
	};

	const handleLoginAsEmployee = (userId: string) => {
		dispatch(authAsEmployeeThunk(userId));
	};

	return (
		<SideContainer isOpen={isAccountsOpen && isOfficesOpen} className={styles.root}>
			<Close onClick={() => dispatch(setOpenOffices(false))} className={styles.closeIcon} />
			<form className={styles.form}>
				<RoundInput
					placeholder="Поиск"
					prepend="search"
					name="search"
					register={register}
					onChange={onSearch}
				/>
			</form>

			<div className={styles.officesContainer}>
				{officesWithoutMe?.map((office) => (
					<div key={office.id} className={styles.office}>
						<div className={styles.title}>{office.name}</div>
						{office.departments?.map((department) => (
							<div key={department.id} className={styles.department}>
								<div className={styles.departmentName}>{department.name}</div>

								<div>
									{department.users?.map((user) => {
										const {
											id,
											position,
											account: { lastName, name, avatar },
										} = user || {};
										const { id: positionId } = position || {};

										return (
											<div key={id} className={styles.user} onClick={() => handleLoginAsEmployee(id)}>
												<Avatar
													imgSrc={avatar}
													name={name}
													lastName={lastName}
													classNames={{ root: styles.avatarMargin }}
												/>

												<div className={styles.userInfo}>
													<span className={styles.position}>{getPositionName(positionId)}</span>

													<span className={styles.name}>{`${lastName} ${name}`}</span>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						))}
					</div>
				))}
			</div>
		</SideContainer>
	);
};
