import { useMutation } from "react-query";
import { RealEstateAddPhotoPayload } from "../../../../types";

import { api } from "../../api";

const DEFAULT_MN = "sdelka/real-estate/add-photo/DEFAULT_MN";

type UseObjectAddPhoto = {
	onSuccess?: () => void;
};

export const useObjectAddPhoto = ({ onSuccess }: UseObjectAddPhoto) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (payload: RealEstateAddPhotoPayload) => api.realEstate.addPhoto(payload),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
