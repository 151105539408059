import { ReactNode } from "react";

import { FixedNavVerticalMenu } from "../../../../components/UI";

export const OPEN_DRAWER = "sdelka/drawer/OPEN_DRAWER";
export const CLOSE_DRAWER = "sdelka/drawer/CLOSE_DRAWER";
export const OPEN_DRAWER_IN_DRAWER = "sdelka/drawer/OPEN_DRAWER_IN_DRAWER";
export const CLOSE_DRAWER_IN_DRAWER = "sdelka/drawer/CLOSE_DRAWER_IN_DRAWER";
export const ADD_NEW_TAB = "sdelka/drawer/ADD_NEW_TAB";
export const ADD_NEW_TAB_IN_DRAWER = "sdelka/drawer/ADD_NEW_TAB_IN_DRAWER";
export const CHANGE_CURRENT_TAB = "sdelka/drawer/CHANGE_CURRENT_TAB";
export const CHANGE_CURRENT_TAB_BY_LABEL = "sdelka/drawer/CHANGE_CURRENT_TAB_BY_LABEL";
export const CHANGE_CURRENT_TAB_IN_DRAWER = "sdelka/drawer/CHANGE_CURRENT_TAB_IN_DRAWER";
export const CHANGE_CURRENT_TAB_IN_DRAWER_BY_LABEL = "sdelka/drawer/CHANGE_CURRENT_TAB_IN_DRAWER_BY_LABEL";
export const DELETE_TAB = "sdelka/drawer/DELETE_TAB";
export const DELETE_TAB_IN_DRAWER = "sdelka/drawer/DELETE_TAB_IN_DRAWER";
export const SHOW_MAP = "sdelka/drawer/SHOW_MAP";
export const RELOAD_DRAWER_IN_DRAWER = "sdelka/drawer/RELOAD_DRAWER_IN_DRAWER";

type SetDrawer = {
  children: ReactNode | ReactNode[];
  width?: number;
  onClose?: () => void;
  classNames?: {
    wrapper?: string;
    rightBlock?: string;
  };
  yNavigation?: {
    navigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[];
    initialTab?: number;
  };
  disableClose?: boolean;
  hasMap?: boolean
};

type SetDrawerInDrawer = {
  children: ReactNode;
  width: number;
  onClose?: () => void;
  withCloseArrow?: boolean
  yNavigation?: {
    navigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">[];
    initialTab?: number;
  };
};

type ReloadDrawerInDrawer = {
  children: ReactNode;
  width: number
}

type ShowMapInDrawer = {
  hasMap?: boolean
}

type AddTabInDrawer = {
  newChildren: ReactNode;
  newNavigation: Omit<FixedNavVerticalMenu, "id" | "changeTab">;
};
type DeleteTabInDrawer = {
  index: number;
};
type ChangeCurrentTab = {
  newCurrentTab: number;
};

type ChangeCurrentTabByLabel = {
  title: string
}

export type DrawerAction =
  | { type: typeof OPEN_DRAWER; payload: SetDrawer }
  | { type: typeof OPEN_DRAWER_IN_DRAWER; payload: SetDrawerInDrawer }
  | { type: typeof CLOSE_DRAWER }
  | { type: typeof CLOSE_DRAWER_IN_DRAWER }
  | { type: typeof ADD_NEW_TAB; payload: AddTabInDrawer }
  | { type: typeof ADD_NEW_TAB_IN_DRAWER; payload: AddTabInDrawer }
  | { type: typeof SHOW_MAP; payload: ShowMapInDrawer }
  | { type: typeof DELETE_TAB; payload: DeleteTabInDrawer }
  | { type: typeof DELETE_TAB_IN_DRAWER; payload: DeleteTabInDrawer }
  | { type: typeof CHANGE_CURRENT_TAB; payload: ChangeCurrentTab }
  | { type: typeof CHANGE_CURRENT_TAB_BY_LABEL; payload: ChangeCurrentTabByLabel }
  | { type: typeof CHANGE_CURRENT_TAB_IN_DRAWER; payload: ChangeCurrentTab }
  | { type: typeof CHANGE_CURRENT_TAB_IN_DRAWER_BY_LABEL; payload: ChangeCurrentTabByLabel }
  | { type: typeof RELOAD_DRAWER_IN_DRAWER; payload: ReloadDrawerInDrawer }


export const openDrawer = (
  {
    children,
    width = 500,
    onClose,
    classNames,
    yNavigation,
    disableClose = false,
  }: SetDrawer): DrawerAction => ({
  type: OPEN_DRAWER,
  payload: {children, width, onClose, classNames, yNavigation, disableClose},
});

export const closeDrawer = (): DrawerAction => ({
  type: CLOSE_DRAWER,
});

export const openDrawerInDrawer = ({children, width, onClose, withCloseArrow}: SetDrawerInDrawer): DrawerAction => ({
  type: OPEN_DRAWER_IN_DRAWER,
  payload: {children, width, onClose, withCloseArrow},
});

export const reloadDrawerInDrawer = ({children, width}: ReloadDrawerInDrawer): DrawerAction => ({
  type: RELOAD_DRAWER_IN_DRAWER,
  payload: {children, width},
});

export const showMapInDrawer = ({hasMap}: ShowMapInDrawer): DrawerAction =>({
  type: SHOW_MAP,
  payload: {hasMap}
})

export const closeDrawerInDrawer = (): DrawerAction => ({
  type: CLOSE_DRAWER_IN_DRAWER,
});

export const addTabInDrawer = ({newChildren, newNavigation}: AddTabInDrawer): DrawerAction => ({
  type: ADD_NEW_TAB,
  payload: {newChildren, newNavigation},
});

export const addTabInDrawerInDrawer = ({newChildren, newNavigation}: AddTabInDrawer): DrawerAction => ({
  type: ADD_NEW_TAB_IN_DRAWER,
  payload: {newChildren, newNavigation},
});

export const deleteTabInDrawer = (index: number) => ({
  type: DELETE_TAB,
  payload: {index},
});

export const deleteTabInDrawerInDrawer = (index: number) => ({
  type: DELETE_TAB_IN_DRAWER,
  payload: {index},
});

export const changeCurrentTab = ({newCurrentTab}: ChangeCurrentTab): DrawerAction => ({
  type: CHANGE_CURRENT_TAB,
  payload: {newCurrentTab},
});

export const changeCurrentTabByLabel = ({title}: ChangeCurrentTabByLabel): DrawerAction => ({
  type: CHANGE_CURRENT_TAB_BY_LABEL,
  payload: {title}
})

export const changeCurrentTabInDrawer = ({newCurrentTab}: ChangeCurrentTab): DrawerAction => ({
  type: CHANGE_CURRENT_TAB_IN_DRAWER,
  payload: {newCurrentTab},
});

export const changeCurrentTabInDrawerByLabel = ({title}: ChangeCurrentTabByLabel): DrawerAction => ({
  type: CHANGE_CURRENT_TAB_IN_DRAWER_BY_LABEL,
  payload: {title}
})