import { UserAccountData } from "../../../../../../types";
import styles from "./index.module.scss";
import cn from "classnames";
import { CardPerson } from "../../../../Card";

type Props = {
  data: UserAccountData[];
  onClickClose?: (id: string) => void;
  withoutAvatars?: boolean;
  mini?: boolean;
  classNames?: string
  atModal?: boolean
};

export const UserListCardsLocal = (
  {
    data,
    onClickClose,
    withoutAvatars,
    mini = false,
    classNames,
    atModal
  }: Props): JSX.Element => {
  const isThereData = data && data.length > 0;

  return (
    <>
      <ul className={cn(styles.body, classNames)}>
        {isThereData &&
          data.map((user) => (
            <li
              key={user.id}
            >
              <CardPerson
                id={user.id}
                phone={user.phone}
                position={user.position as any}
                avatar={user.avatar}
                fio={{
                  firstName: user.name,
                  lastName: user.lastName,
                  secondName: user.secondName ?? ""
                }}
              /></li>
          ))}
      </ul>
      {!isThereData && <div className={styles.noData}>Не назначено</div>}
    </>
  );
};
