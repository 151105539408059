import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/invite/decline-invite/DEFAULT_MN";

type UseInviteDecline = {
	onSuccess?: () => void;
};

export const useInviteDecline = ({ onSuccess }: UseInviteDecline) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (inviteId: string) => api.invites.deleteAuthentication(inviteId),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
	};
};
