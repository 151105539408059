import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { currentAccountReducer } from "./modules/auth/currentAccountReducer";
import { modalReducer } from "./modules/modal/modal.reducer";
import { drawerReducer } from "./modules/drawer/drawer.reducer";
import { gallerySliderReducer } from "./modules/gallery-slider/gallery-slider.reducer";
import { accountsReducer } from "./modules/auth/accountsReducer";
import { regStepsReducer } from "./modules/registration-steps/reg-steps.reducer";
import { resetPassStepsReducer } from "./modules/reset-pass-steps/reset-pass-steps.reducer";
import { sidebarReducer } from "./modules/sidebar/sidebar.reducer";
import { objectEditReducer } from "./modules/object-edit/object-edit.reducer";
import { objectListReducer } from "./modules/object-list/object-list.reducer";
import { compilationReducer } from "./modules/compilation/compilation.reducer";
import { showsAndViewsReducer } from "./modules/shows-and-views/shows-and-views.reducer";
import { businessProcessReducer } from "./modules/business-process/business-process.reducer";
import { cartReducer } from "./modules/cart/cart.reducer";

export const rootReducer = combineReducers({
	accounts: accountsReducer,
	currentAccount: currentAccountReducer,
	modal: modalReducer,
	drawer: drawerReducer,
	gallerySlider: gallerySliderReducer,
	regSteps: regStepsReducer,
	resetPassSteps: resetPassStepsReducer,
	sidebar: sidebarReducer,
	objectEdit: objectEditReducer,
	objectList: objectListReducer,
	compilation: compilationReducer,
	showsAndViews: showsAndViewsReducer,
	businessProcess: businessProcessReducer,
	cart: cartReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));

export default store;
