import { useEffect, useState } from "react";
import styles from "./index.module.scss";

export const useAuthAutocomplete = (phoneWatcher: string): { fieldStyle: string } => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (phoneWatcher && !clicked) {
      setClicked(true);
    }
  }, [phoneWatcher]);

  return {fieldStyle: !clicked ? styles.field : ""};
};
