import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import isEqual from "lodash.isequal";
import { ApplicationFilterPayload, LeadStatusEnum, Stage } from "../../../../../../types";

import { MultiCheckbox } from "../../../../../UI";
import { useSelect, selectBusinessProcesses } from "../../../../../../service/redux/selectors";
import { requestsButtons, StageButtons } from "./helpers";

import styles from "./index.module.scss";

type Props = {
	filter: ApplicationFilterPayload & any;
	setFilter: Dispatch<SetStateAction<ApplicationFilterPayload>>;
	requestStages: Stage[];
	handleFilterClick: (status: LeadStatusEnum, fieldName: string) => void;
};

export const ApplicationStatus = ({
	requestStages,
	filter = {},
	setFilter,
	handleFilterClick,
}: Props): JSX.Element => {
	const { businessProcesses } = useSelect(selectBusinessProcesses);

	const { setValue, register, getValues, control, watch, reset } = useForm<any>({
		defaultValues: {
			typeId: filter.typeId || [],
			stage: filter.stage || [],
		},
	});

	const formWatcher = watch();

	const [typeIdWatcher, stageWatcher] = [
		useWatch({
			control,
			name: "typeId",
			defaultValue: getValues("typeId") || [],
		}),
		useWatch({
			control,
			name: "stage",
			defaultValue: getValues("stage") || [],
		}),
	];

	const filterClick = (val: any) => handleFilterClick(val, "stage");

	const memoizedCallback = requestsButtons(
		filter as { stage?: LeadStatusEnum[] },
		setFilter,
		filterClick,
		requestStages
	);

	const applicationTypeOptions = useMemo(() => {
		if (typeIdWatcher?.length === 1) {
			return businessProcesses.map((process) => {
				let child: any;

				if (
					requestStages?.length > 0 &&
					!!setFilter &&
					!!handleFilterClick &&
					filter &&
					process?.id === typeIdWatcher[0]
				) {
					child = (
						<div className={styles.applicationsStages}>
							<StageButtons title="" buttons={memoizedCallback} />
						</div>
					);
				}

				return {
					value: process.id,
					label: process.typeName,
					child,
				};
			});
		}

		return businessProcesses.map((process) => ({
			value: process.id,
			label: process.typeName,
		}));
	}, [typeIdWatcher, requestStages, stageWatcher]);

	useEffect(() => {
		if (!isEqual(formWatcher, filter)) {
			reset(filter);
		}
	}, [filter]);

	const onChange = (values: string[]) => {
		setFilter({
			...filter,
			typeId: values,
		} as any);
	};

	return (
		<>
			{applicationTypeOptions?.length > 0 && (
				<>
					<div className={styles.headline}>Тип заявки</div>
					<MultiCheckbox
						name="typeId"
						register={register}
						setValue={setValue}
						options={applicationTypeOptions}
						onChange={onChange}
						defaultValue={filter.typeId}
						direction="column"
						className={styles.applicationsTypes}
					/>
				</>
			)}
		</>
	);
};
