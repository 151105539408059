import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";

import { api } from "../../index";
import { StopAdPayload } from "../../../../types";

const DEFAULT_MN = "sdelka/real-estate-ad/stop-ad/DEFAULT_MN";

type Props = {
	onSuccess?: (
		data: AxiosResponse<StopAdPayload>,
		variables: StopAdPayload,
		context: unknown
	) => void | Promise<unknown>;

	onError?: (
		data: AxiosResponse<StopAdPayload>,
		variables: StopAdPayload,
		context: unknown
	) => void | Promise<unknown>;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<StopAdPayload>, unknown, StopAdPayload, unknown>;
	isLoading: boolean;
};

type UseStopAd = (props: Props) => Return;

export const useStopAd: UseStopAd = ({ onSuccess, onError }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: StopAdPayload) => api.realEstateAd.stopAd(data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
