import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/user/user-fire/DEFAULT_QN";

type Props = {
  userId: string
  onSuccess?: () => void;
  onError?: () => void;
};

export const useFireEmployee = ({ onSuccess, onError, userId }: Props) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: ( newResponsibleUserId: string ) =>
      api.users.fire(userId, newResponsibleUserId),
    onSuccess,
    onError
  });

  return {
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};