import { useEffect, useMemo, useState } from "react";
import { Button, Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { Info as InfoCard } from "../ApplicationCard/components/ApplicationInfo/component";
import {
  LOAD_APPLICATION_REAL_ESTATE_DEFAULT_QN,
  LOAD_APPLICATION_REAL_ESTATE_EDIT_QN,
  useArhivePreApplications,
  useLoadApplicationRealEstate,
  useLoadPreApplicationTemplate
} from "../../../../service/api/queries";
import { useOpenApplicationInDrawer, useOpenTaskModal, useQueryParams } from "../../../../utils/hooks";
import { ReactComponent as OpenInNew } from "../../../../assets/icons/openInNewBlue.svg";
import { ReactComponent as DoubleChevron_Right } from "../../../../assets/icons/DoubleChevron_Right.svg";
import styles from "./index.module.scss";
import {
  useLoadCardBlocksPreApplications
} from "service/api/queries/pre-application-template/useLoadCardBlocksPreApplications";
import { EditClientEstate, KindType, RealEstate } from "types";
import {
  useFinishSuccessPreApplications
} from "service/api/queries/pre-application-template/useFinishSuccessPreApplications";
import { Popup } from "components/UI";
import {
  changeCurrentTab,
  closeDrawer,
  closeDrawerInDrawer,
  openDrawer,
  openDrawerInDrawer
} from "service/redux/modules/drawer/drawer.action";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { selectCurrentAccount, selectObjectEdit, useSelect } from "service/redux/selectors";
import { useDispatch } from "react-redux";
import { RealEstateEdit, RealEstateViewWithTabs } from "components/pages/RealEstatePage/mode";
import { useGetNavigationPreApplication } from "./helpers/useGetNavigationPreApplication";
import { ApplicationCard } from "../ApplicationCard";
import { MenuItem } from "components/pages/ShowsAndViews/components/card-actions/CardActions";
import { LeadCard } from "components/pages/LeadCard";
import {
  useGetFromArchive
} from "../../RealEstatePage/mode/RealEstateView/components/stickyFooter/helpers/useGetFromArchive";
import { useOpenApplicationCard } from "../../../../utils/application";

type Props = {
  applicationId: string;
  refetchLead?: () => void;
  isFromLead?: boolean
  reopenPreapp?: (id: string) => void
  isFromObjectList?: boolean
};
type MatchParams = {
  id: string;
};
// eslint-disable-next-line sonarjs/cognitive-complexity
export const PreApplicationTemplate = ({applicationId, refetchLead, isFromLead, reopenPreapp, isFromObjectList}: Props): JSX.Element => {
  const {removeQueryParam, changeQueryParams} = useQueryParams<{ preApplicationId: string }>([{name: "preApplicationId"}]);
  const dispatch = useDispatch();
  const {coord, dontMarkOnMap} = useSelect(selectObjectEdit);
  const history = useHistory()
  const {
    queryParams: {mode},
  } = useQueryParams<{ mode: string }>([{name: "mode"}]);
 
  const {currentAccount} = useSelect(selectCurrentAccount);
  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const {mapCenter} = currentAccount?.settings || {};

  const getMapCenter = (): [number, number] | undefined =>
    mapCenter ? [mapCenter.lat, mapCenter.lon] : undefined;

  const match = useRouteMatch<MatchParams>();
  const {id} = match.params;
  const APPLICATION_ID = applicationId ?? id;

  const queryName =
    mode === "edit" ? LOAD_APPLICATION_REAL_ESTATE_EDIT_QN : LOAD_APPLICATION_REAL_ESTATE_DEFAULT_QN;
  const {data, isLoading, refetch, remove} = useLoadPreApplicationTemplate({
    applicationId,
    isFromLead,
    refetchLead,
    reopenPreapp,
    isFromObjectList
  });
  const {realEstate, isFetching, refetch: refetch2, remove: remove2} = useLoadApplicationRealEstate({
    id: data?.applicationTemplateId ?? "",
    queryName,
  });

  const isDisableEdit = !realEstate?.permissions?.edit;
  const location = useLocation()

  const {activeNav, onAdNav, navigationY, blocksIds, setNavigationY} = useGetNavigationPreApplication({
    realEstate,
    isDisableEdit,
  });

  // const isEditOrCreate = mode === "edit";
  // const { onMapClick } = useMapAdjust({ realEstate, isEditOrCreate });

  const onCloseClick = () => {
    dispatch(closeDrawer());
  };

  const clientEstate: RealEstate | undefined = useMemo(() => {
    if (realEstate) {
      const copy: EditClientEstate = {
        ...realEstate,
        applications: [],
        activeApplications: {},
      };
      if (copy?.schema) {
        delete copy.schema;
      }
      return copy;
    }
    return undefined;
  }, [realEstate]);

  const whoIsClient = data?.type.whoIsClient

  const openAtPublic = () => {
    if (data) {
      window.open(`${data.publicPageLink}`)
    }
  }

  const actionsMenu: MenuItem[] = [
    {
      label: "Открыть лид",
      icon: <DoubleChevron_Right/>,
      onClick: () => openLead(),
      id: 1
    },
    // {
    //   label: "Открыть чат",
    //   icon: <DoubleChevron_Right/>,
    //   onClick: () => 1,
    //   id: 2
    // },
    {
      label: "Открыть на публичной странице",
      icon: <OpenInNew/>,
      onClick: () => openAtPublic(),
      id: 3,
    },
  ]

  const actualMenu = (location.search.includes("tab=applications"))
    ? actionsMenu
    : actionsMenu.slice(1, 3)

  const [stageNum, setStageNum] = useState(0);
  const stages = data ? data.stages : []
  const stageTitle: string | null = stages && stages[ stageNum ] ? stages[ stageNum ].title : null;

  const {
    data: currentBlocks,
    isFetching: isFetchingBlocks,
    refetch: refeatchBlocks
  } = useLoadCardBlocksPreApplications({
    applicationId,
    stage: stageTitle as string,
    enabled: !!applicationId && !!stageTitle,
  });

  useEffect(() => {
    refetch()
    refeatchBlocks()
  }, [applicationId])

  const {
    mutate: arhiveMutate,
    data: arhivePreApplicationsData
  } = useArhivePreApplications({applicationId})

  const {
    mutate: finishSuccessMutate,
    data: finishSuccessData,
    isLoading: finishSuccessLoading,
    error
  } = useFinishSuccessPreApplications({applicationId})

  const arhivePreApplication = () => {
    arhiveMutate()
  }

  const {handleOpenTaskModal} = useOpenTaskModal({
    onUpdatedTask: arhivePreApplication,
    refetchParent: refetchLead,
    isLast: true
  });

  useEffect(() => {
    // todo
    if (arhivePreApplicationsData) {
      dispatch(closeDrawerInDrawer())
    }
  }, [arhivePreApplicationsData])

  const finishSuccesPreApplication = () => {
    if (!error) {
      removeQueryParam("preApplicationId")

    }
    finishSuccessMutate()
  }


  useEffect(() => {
    if (finishSuccessData && isFromLead === true) {
      const currentApplicationId = (finishSuccessData?.data as any)?.applicationId
      dispatch(closeDrawerInDrawer())
      changeQueryParams([{name: "applicationId", newValue: currentApplicationId}])
      setTimeout(() => {
        dispatch(openDrawerInDrawer({
          children: <ApplicationCard applicationId={currentApplicationId} inDrawerInDrawer kind={KindType.APPLICATION}/>,
          width: 800
        }))
      }, 200)
    }
    // if (finishSuccessData && isFromLead === false) {
    //   const currentApplicationId = (finishSuccessData?.data as any)?.applicationId
    //   changeQueryParams([{name: "tab", newValue: "applications"}, {name: "applicationId", newValue: currentApplicationId}])
    //   dispatch(closeDrawer())
    //
    //   setTimeout(() => {
    //     dispatch(changeCurrentTab({newCurrentTab: 0}))
    //     dispatch(openDrawer({
    //       children: <ApplicationCard applicationId={currentApplicationId}  kind={KindType.APPLICATION}/>,
    //       width: 800,
    //     }))
    //     dispatch(changeCurrentTab({newCurrentTab: 0}))
    //   }, 200)
    //
    // }
  }, [ finishSuccessData]);

  const applicationTemplateId = data?.applicationTemplateId

  const openLead = () => {
    dispatch(closeDrawer());
    if (data?.parentLead.id) {
      dispatch(openDrawer({
        children: <LeadCard id={data.parentLead.id} refetch={refetch}/>,
        width: 500,
      }));
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("tab", "leads");
      const newUrl = window.location.pathname + '?' + urlParams.toString();
      history.replace(newUrl);
      dispatch(openDrawerInDrawer({
        children: <PreApplicationTemplate applicationId={applicationId} isFromLead={true}/>,
        width: 800
      }));
    }
  }

  const {handleGetFromArchive} = useGetFromArchive(applicationTemplateId, true)

  return (
    <>
        {APPLICATION_ID && (
          <div className={styles.wrap}>
            {mode === "1" && (
              <RealEstateViewWithTabs
                id={applicationTemplateId ?? ""}
                // id={applicationId}
                blocksIds={blocksIds}
                inDrawer
                kind={KindType.PREAPPLICATION}
                applicationTemplateId={applicationTemplateId ?? ""}
              />
            )}
            {mode === "edit" && clientEstate && (
              <RealEstateEdit
                id={applicationTemplateId ?? ""}
                // id={data?.applicationTemplateId ?? ""}
                realEstate={clientEstate}
                refetch={refetch}
                remove={remove}
                isFetching={isFetching}
              />
            )}
            {data &&
              <InfoCard
                applicationId={applicationId}
                kind={KindType.PREAPPLICATION}
                application={data}
                onTab={() => 0}
                refetch={refetch}
                stageNum={stageNum}
                setStageNum={setStageNum}
                isFetchingBlocks={isFetchingBlocks}
                currentBlocks={currentBlocks}
              />
            }
          </div>
        )}
      {data &&
        <>
          {data && !data.isArchived &&
            <div className={styles.bottoms}>
              <div className={styles.actions}>
                <Popup
                  openTrigger={<div className={styles.openTrigger}>Другие действия</div>}
                  options={{withArrow: true}}
                  classNames={
                    {
                      trigger: styles.clicked,
                      arrow: styles.arrow,
                      card: styles.popupCard
                    }
                  }
                >
                  {actualMenu.map((item) => (
                    <div key={item.id} className={styles.actionItem} onClick={item.onClick}>
                      {item.icon}
                      <span>{item.label}</span>
                    </div>
                  ))}
                </Popup>
              </div>
              <Button
                color="red"
                variant="text"
                onClick={() => handleOpenTaskModal(data?.lastActiveCommunicationId ?? "")}
              >
                Отказаться
              </Button>
              <Button onClick={finishSuccesPreApplication}>
                Активировать заявку
              </Button>
            </div>
          }
          {data.isArchived &&
            <div className={styles.bottoms}>
              <Button onClick={() => handleGetFromArchive(data.applicationTemplateId ?? "")}>
                Создать лид
              </Button>
            </div>
          }
        </>
      }
      {isLoading && <div className={styles.root}>
        <Loader height={250}/>
        {/*  пока оставлено*/}
        {/* {data && <Info refetch={refetch} refetchLead={refetchLead} application={data as unknown as ApplicationTemplate} />} */}
        {/* {data && <InfoCard
					applicationId={applicationId}
					kind="pre-applications"
					application={data}
					onTab={() => 0}
					refetch={refetch}
					stageNum={stageNum}
					setStageNum={setStageNum}
					isFetchingBlocks={isFetchingBlocks}
					currentBlocks={currentBlocks}
				/>} */}
      </div>}
    </>
  );
};
