import { useForm } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ContentBlock } from "../../../../UI";
import { Address, MainInfo, ObjectInfo } from "../components";
import { useLoadSubways } from "../../../../../service/api/queries";
import { DEFAULT_TRAFFIC } from "../../../../../service/api/helpers";
import { ApplicationsFilter, User, SelectOption } from "../../../../../types";

import styles from "./index.module.scss";

type Props = {
	defaultValues: ApplicationsFilter;
	users: User[];
	requestStageOptions: SelectOption[];
	submitFilter: (filter: ApplicationsFilter) => void;
	sourcesOptions: SelectOption[];
};

export const RequestFiltersModal = ({
	defaultValues,
	users,
	submitFilter,
	sourcesOptions,
	requestStageOptions,
}: Props): JSX.Element => {
	const { handleSubmit, control, register, setValue, watch, reset, getValues } = useForm({
		defaultValues,
	});

	const { data: subways } = useLoadSubways();

	const onSubmit = (values: ApplicationsFilter) => submitFilter(values);

	const resetForm = () => {
		const updateForm = {
			...DEFAULT_TRAFFIC,
			fullTextSearch: defaultValues.fullTextSearch,
			typeId: defaultValues.typeId,
			responsibleUsers: defaultValues.responsibleUsers,
			signed: defaultValues.signed,
		};

		reset(updateForm);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
			<h3 className={styles.header}>Еще фильтры</h3>
			<div className={styles.content}>
				<div className={styles.leftBlock}>
					<ContentBlock margin="bigBottomAndRight">
						<MainInfo
							register={register}
							control={control}
							users={users}
							sourcesOptions={sourcesOptions}
							requestStageOptions={requestStageOptions}
							setValue={setValue}
							watch={watch}
						/>
					</ContentBlock>
					<ContentBlock title="Информация об объекте" margin="bigBottomAndRight">
						<ObjectInfo getValues={getValues} setValue={setValue} register={register} control={control} />
					</ContentBlock>

					<ContentBlock title="Адрес объекта" margin="bigBottomAndRight">
						<Address register={register} watch={watch} setValue={setValue} subways={subways} />
					</ContentBlock>
				</div>
				<div className={styles.actions}>
					<Button type="submit" fullWidth>
						Показать
					</Button>

					<Button onClick={resetForm} variant="text" color="red" classNames={{ root: styles.cancelBtn }}>
						Отчистить фильтры
					</Button>
				</div>
			</div>
		</form>
	);
};
