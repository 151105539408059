import { CartResponse } from "../../../../../types";
import styles from "./index.module.scss"
import { useState } from "react";
import { InfoRow } from "../../../../UI";
import { usePromoCode } from "../../../../../service/api/mutations";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { formatThousand } from "../../../../../utils/formatters";

type Props = {
  cart?: CartResponse
  refetch: () => void
}

export const Footer = ({cart, refetch}: Props) => {
  const {
    employersCount,
    discount,
    currentPromoCode,
    fullPrice,
    priceWithDiscount,
    paymentUrl
  } = cart || {}

  const [promoCode, setPromoCode] = useState<string | undefined>(currentPromoCode)

  const handlePay = () => {
    window.open(`${paymentUrl}`)
  }

  const {mutate: mutatePromoCode} = usePromoCode({onSuccess: refetch})

  const handleChangeCode = (value) => {
    setPromoCode(value)
  }

  const handleApplyPromoCode = () => {
    mutatePromoCode(promoCode)
  }


  return (
    <div className={styles.footer}>
      <div className={styles.footerInfo}>
        Для финальной скидки считаются только сотрудники,
        чьи подписки действительны не менее 30 дней.
        И плюс те, которые в корзине. Скидка действует только на сотрудников.
      </div>
      <InfoRow classNames={{root: styles.infoRow}} label="Сотрудников" aspect={{label: 6, content: 5}}>
        {employersCount || 0}
      </InfoRow>
      <InfoRow classNames={{root: styles.infoRow}} label="Скидка" aspect={{label: 6, content: 5}}>
        {discount || 0}
      </InfoRow>
      <div className={styles.row}>
        <InfoRow classNames={{root: styles.infoRow}} label="Промокод" aspect={{label: 6, content: 3}}>
          <input onChange={(e) => handleChangeCode(e.target.value)} placeholder="Указать" className={styles.inputCode}/>
        </InfoRow>
        <div
          className={styles.promoCodeButton}
          onClick={handleApplyPromoCode}>
          Применить
        </div>
      </div>
      <div className={styles.rowButton}>
        <div className={styles.row}>
          <div className={styles.priceWithDiscount}>{discount ? `${formatThousand(priceWithDiscount)} ₽` : `${formatThousand(fullPrice)} ₽`}</div>
          {
            discount !== 0 &&
            <div className={styles.price}>
              {`${formatThousand(fullPrice)} ₽`}
            </div>
          }
        </div>
        <Button
          classNames={{root: styles.button}}
          onClick={handlePay}
          disabled={fullPrice === 0}
        >
          Оплатить
        </Button>
      </div>

    </div>
  )
}