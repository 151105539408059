
import { Plug } from "../plug/Plug";
import { Contact } from "../../../../../../../types";
import { CardContact } from "../../../../../../UI/Card/card-contact/CardContact";
import styles from "./index.module.scss"

type Props = {
  contact?: Contact | null
  onClickContact: (id: string) => void
  plugText: string
}

export const ContactView = ({contact, onClickContact, plugText}: Props): JSX.Element => (
    <>
      { contact
        ? (
          <div onClick={() => onClickContact(contact.id)} className={styles.card}>
            <CardContact
              contact={contact}
              mini={true}
              backgroundWhite
            />
          </div>)
        : (<Plug plugText={plugText}/>)}
    </>
  )