import { useLoadCart } from "../../../../service/api/queries";
import { useDeleteFromCart } from "../../../../service/api/mutations";
import { useState } from "react";
import styles from "./index.module.scss"
import { productNounHelper } from "../../../../utils/string";
import { CartHeader, Footer } from "./helpers";
import { CardProduct } from "../../../UI";


type Props = {
  refetchTable: () => void
}

export const Cart = ({refetchTable}: Props) => {
  const {
    cart,
    refetch,
    isLoading
  } = useLoadCart()

  const [isBlindOpen, setBlindOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleRefetch = () => {
    refetch()
    refetchTable()
  }

  return (
    <div className={styles.underlayer}>

      <div
        className={styles.root}
        onMouseEnter={() => {
          setBlindOpen(true)
          setHovered(true)
        }}
        onMouseLeave={() => {
          setBlindOpen(false)
          setHovered(false)
        }}
      >
        <div className={styles.header}>
          <div className={styles.title}>
            Корзина
          </div>
          <div className={styles.subTitle}>
            {cart?.products && cart?.products.length} {productNounHelper(cart?.products.length || 0)}
          </div>
          {hovered && <CartHeader/>}
        </div>
        <div className={styles.products}>
          {cart?.products && cart.products.map((item) => (
              <div key={item.id}>
                <CardProduct
                  product={item}
                  onSuccess={handleRefetch}
                  isHovered={hovered}
                />
              </div>
            )
          )}
        </div>
        {hovered && <Footer cart={cart} refetch={handleRefetch}/>}
      </div>
    </div>
  )
}