import { useCancelApplication } from "../../../../../../../../../../../../../../../service/api/mutations";
import { useDispatch } from "react-redux";
import {
  closeModal,
  openModal
} from "../../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { CancelApplicationModal } from "./CancelApplicationModal";

type Props = {
  applicationId: string
  whoIsClient: "seller" | "buyer" | undefined
}

export const useOpenCancelApplicationModal = (
  {
    applicationId,
    whoIsClient
  }: Props) => {
  const dispatch = useDispatch()

  const onSuccessCancel = () => {
    dispatch(closeModal())
  }

  const {mutate: mutateCancelApplication} = useCancelApplication({onSuccess: onSuccessCancel})

  const handleCancelApplication = () => {
    mutateCancelApplication(applicationId)
  }

  const handleCancelApplicationModal = () => {
    dispatch(
      openModal({
        body: <CancelApplicationModal
          whoIsClient={whoIsClient}
          onApply={handleCancelApplication}
        />,
        width: 660,
      })
    )
  }
  return {handleCancelApplicationModal}
}