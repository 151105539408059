import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/bank/find-bank/DEFAULT_MN";

export const useFindBank = () => {
	const queryClient = useQueryClient();

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (query: string) => api.application.remoteBanks(query),
	});

	const getLastMutationCache = () => {
		const allMutations = queryClient.getMutationCache().findAll({ mutationKey: DEFAULT_MN });

		return allMutations[allMutations.length - 1];
	};

	useEffect(() => () => mutate.reset(), []);

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
		getLastMutationCache,
	};
};
