import { memo } from "react";
import { RealEstate } from "../../../../../../types";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "../../index.module.scss";
import cn from "classnames";
import { ReactComponent as DescriptionIcon } from "../../../../../../assets/icons/chatIcon.svg";
import { ReactComponent as SignedByOtherAgency } from "../../../../../../assets/icons/iconSignedByOtherAgency.svg";
import { ReactComponent as HasRejectedLead } from "../../../../../../assets/icons/iconRejectedLead.svg";
import { ReactComponent as HasActiveLead } from "../../../../../../assets/icons/iconActiveLead.svg";
import { ReactComponent as HasFreezeLead } from "../../../../../../assets/icons/iconFreezeLead.svg";
import { ReactComponent as SignedByMyAgency } from "../../../../../../assets/icons/signed.svg";

type Props = {
  clientObject: RealEstate;
  variant?: "default" | "ads";
}

export const LeadIconBlock = memo(
  ({clientObject, variant}: Props): JSX.Element => (
    <>
      {clientObject.description && variant === "ads" &&
          <Tooltip
              classNames={{
                root: styles.tooltipDescription,
                card: styles.tooltipCard,
              }}
              iconSize="big"
              CustomIcon={DescriptionIcon}
          >
            {clientObject.description}
          </Tooltip>}
      <div className={styles.infoIconRow}>
        {clientObject.icons?.signedByOtherAgency &&
            <Tooltip
                classNames={{
                  root: styles.tooltipDescription,
                  card: styles.tooltipCard,
                }}
                iconSize="big"
                CustomIcon={SignedByOtherAgency}
            >
                Заключено агентское соглашение с другим агенством
            </Tooltip>
        }
        {clientObject.icons?.hasRejectedLead &&
            <Tooltip
                classNames={{
                  root: styles.tooltipDescription,
                  card: cn(styles.tooltipCard, styles.tooltipActiveInfoLess),
                }}
                iconSize="medium"
                CustomIcon={HasRejectedLead}
            >
                У вас имеется неактивный лид с этим объектом
            </Tooltip>
        }
        {clientObject.icons?.hasActiveLead &&
            <Tooltip
                classNames={{
                  root: styles.tooltipDescription,
                  card: cn(styles.tooltipCard, styles.tooltipActiveInfoLess),
                }}
                CustomIcon={HasActiveLead}
                iconSize="medium"
            >
                У вас имеется активный лид с этим обьектом
            </Tooltip>}
        {clientObject.icons?.hasFreezeLead &&
            <Tooltip
                classNames={{
                  root: styles.tooltipDescription,
                  card: styles.tooltipCard,
                }}
                CustomIcon={HasFreezeLead}
                iconSize="medium"
            >
                У вас имеется замороженный лид с этим объектом
            </Tooltip>}
        {clientObject.icons?.signedByMyAgency &&
            <Tooltip
                classNames={{
                  root: styles.tooltipDescription,
                  card: styles.tooltipCard,
                }}
                CustomIcon={SignedByMyAgency         }
                iconSize="medium"
            >
                Есть подписанный агентский договор
            </Tooltip>}
      </div>
    </>
  )
)