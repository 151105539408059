import { Plug } from "../plug/Plug";
import { Contact } from "../../../../../types";
import { CardContact } from "../../../../UI/Card/card-contact/CardContact";
import styles from "./index.module.scss"


type Props = {
  contact?: Contact | null
  onClickContact: (id: string) => void
  plugText: string
}

export const ContactView = ({contact, onClickContact, plugText}: Props): JSX.Element => (
  <>
    {contact
      ? (
        <div onClick={() => onClickContact(contact.id)}>
          <CardContact
            contact={contact}
            canOpenNew
            classNames={{
              root: styles.card
            }}
            backgroundWhite

          />
        </div>
      )
      : <Plug plugText={plugText}/>
    }
  </>
)