import { useState } from "react";
import cn from "classnames";

import { ReactComponent as StarIcon } from "../../../assets/icons/star.svg";
import styles from "./index.module.scss";

type Props = {
	allowRate?: boolean;
	onClickRate?: (grade: number) => void;
	rate: number;
};

export const Rate = ({ onClickRate, rate, allowRate = true }: Props): JSX.Element => {
	const [hovered, setHovered] = useState(0);

	const handleClickRate = (grade: number) => {
		if (onClickRate) {
			onClickRate(grade);
		}
	};

	return (
		<div className={styles.rateContainer}>
			{[1, 2, 3, 4, 5].map((star) => (
				<div
					key={star}
					onClick={() => allowRate && handleClickRate(star)}
					onMouseEnter={() => allowRate && setHovered(star)}
					onMouseLeave={() => allowRate && setHovered(0)}
					className={cn(styles.rate, {
						[styles.rateFilled]: star <= rate || star <= hovered,
					})}
				>
					<StarIcon />
				</div>
			))}
		</div>
	);
};
