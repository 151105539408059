import { useDispatch } from "react-redux";
import { openModal } from "../../../service/redux/modules/modal/modal.action";
import { AddToCollection } from "./add-to-collection/AddToCollection";
import styles from "./index.module.scss"
import { RealEstate } from "../../../types";

type Props = {
  realEstateId: string
  objectData: RealEstate
}

type Return = {
  openAddToCollectionModal: () => void
}

type useAddToCollectionModal = (props: Props) => Return

export const useAddToCollectionModal: useAddToCollectionModal = (
  {
    realEstateId,
    objectData,
  }: Props
) => {

  const dispatch = useDispatch()

  const openAddToCollectionModal = () => {
    dispatch(
      openModal(
        {
          width: 800,
          body: <AddToCollection realEstateId={realEstateId} objectData={objectData}/>,
          classNames: {
            contentBody: styles.contentBody,
            contentWrapper: styles.modal
          },
        }
      )
    )
  }
  return {
    openAddToCollectionModal
  }
}