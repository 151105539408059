import { useEffect, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import cn from "classnames";

import { arrayIsEqual } from "../../../../../utils/arrayIsEqual";

import styles from "./index.module.scss";
import commonStyles from "../index.module.scss";

type IGroupedCheckboxProps = {
	name: string;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	className?: string;
	defaultValue?: string[];
	onChange?: (value: string[]) => void;
	options: { label: string; value: string }[][];
	allowEmpty?: boolean;
};

// User can select multiply checkbox only in one group - else value resets.
export const GroupedCheckbox = ({
	name,
	register,
	setValue,
	defaultValue,
	options,
	className,
	onChange,
	allowEmpty = true,
}: IGroupedCheckboxProps): JSX.Element => {
	const [selected, setSelected] = useState(defaultValue || []);
	register(name);

	useEffect(() => {
		setValue(name, selected);
	}, [selected]);

	useEffect(() => {
		const isEqual = arrayIsEqual(defaultValue, selected);

		if (defaultValue && !isEqual) {
			setSelected(defaultValue);
		}
	}, [defaultValue]);

	const isFieldDisabled = (value: string, group: Array<{ value: string }>): boolean => {
		if (allowEmpty) return false;

		const selectedItems = group.reduce(
			(accum, item) => (selected.includes(item.value) ? accum + 1 : accum),
			0
		);

		const isOneItemSelected = selectedItems === 1;
		const isCurrentSelected = selected.includes(value);

		return isOneItemSelected && isCurrentSelected;
	};

	const onCheckboxChange = (item: string, groupItems: { label: string; value: string }[]) => {
		if (isFieldDisabled(item, groupItems)) {
			return;
		}

		if (selected?.length > 0 && !groupItems.find((i) => i.value === selected[0])) {
			if (onChange) {
				onChange([item]);
			}

			setSelected([item]);

			return;
		}

		if (selected.includes(item)) {
			const newValue = selected.filter((i) => i !== item);

			if (onChange) {
				onChange(newValue);
			}

			setSelected(newValue);

			return;
		}

		const newValue = selected.concat(item);

		if (onChange) {
			onChange(newValue);
		}

		setSelected(newValue);
	};

	return (
		<div className={cn(styles.checkboxesWrapper, className)}>
			{options.map((option, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={index} className={styles.groupWrapper}>
					{option.map((item) => (
						<div className={styles.checkboxWrapper} key={item.value}>
							<label
								className={cn(commonStyles.label, {
									[commonStyles.checked]: selected.includes(item.value),
								})}
							>
								<input
									onClick={() => onCheckboxChange(item.value, option)}
									type="checkbox"
									className={commonStyles.checkbox}
									// disabled={isFieldDisabled(option, item.value)}
								/>
								<span className={commonStyles.checkboxBg} />
								<span className={commonStyles.labelText}>{item.label}</span>
							</label>
						</div>
					))}
				</div>
			))}
		</div>
	);
};
