import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { H3 } from "@sdelka_crm/sdelka-crm-component-library";

import { ContentBlock, GenerateComponentByType } from "../index";
import { BusinessProcess, CategoryEnum, RealEstateFilter, RealEstateFilterBlock, User } from "../../../types";

import styles from "./index.module.scss";

type Props = {
	filterBlocks: RealEstateFilterBlock[];
	control: Control<RealEstateFilter>;
	setValue: UseFormSetValue<RealEstateFilter>;
	register: UseFormRegister<RealEstateFilter>;
	getValues: UseFormGetValues<RealEstateFilter>;
	setCategory: (category: CategoryEnum[], prev: CategoryEnum[]) => void;
	users: User[];
	onChangeTypeValue?: (value: BusinessProcess) => void;
	disabled?: boolean;
	isParser?: boolean
};

export const ObjectsFilterFromConfig = ({
	filterBlocks,
	control,
	getValues,
	setCategory,
	users,
	setValue,
	register,
	onChangeTypeValue,
	disabled,
	isParser
}: Props): JSX.Element => (
	<div className={styles.filters}>
		{filterBlocks.map((block, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<div key={index}>
				{block.isTitle ? (
					<H3 id={block.blockName} classNames={{ root: styles.titleGroup }}>
						{block.blockName}
					</H3>
				) : (
					<ContentBlock title={block.blockTitle} margin="bigBottomAndRight" id={block.blockName}>
						{block.fields.map((field, fieldIndex) => (
							<GenerateComponentByType
								// eslint-disable-next-line react/no-array-index-key
								key={fieldIndex}
								control={control}
								setValue={setValue}
								field={field}
								getValues={getValues}
								register={register}
								users={users}
								setCategory={setCategory}
								onChangeTypeValue={onChangeTypeValue}
								disabled={disabled}
								isParser={isParser}
							/>
						))}
					</ContentBlock>
				)}
			</div>
		))}
	</div>
);
