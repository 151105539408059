import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

import { QueriesToChange, QueryParamsReturn } from "../../../types";

type UseQueryParams = {
	name: string;
	type?: "boolean" | "string" | "number";
}[];

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useQueryParams = <T extends Record<string, unknown>>(
	urlParams: UseQueryParams
): QueryParamsReturn<T> => {
	const params = {};
	const location = useLocation();
	const { push } = useHistory();
	const rawQueryParams = location.search;
	const fullPath = `${location.pathname}${location.search}`;

	for (let i = 0; i < urlParams.length; i += 1) {
		let queryParam: any = new URLSearchParams(rawQueryParams).get(urlParams[i].name);

		if (queryParam) {
			if (urlParams[i].type === "boolean") {
				queryParam = Boolean(queryParam);
			}
			if (urlParams[i].type === "number") {
				queryParam = Number(queryParam);
			}
			params[urlParams[i].name] = queryParam;
		}
	}

	const replace = function replaceQueryParam(url, key, value) {
		const regex = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
		const separator = url.indexOf('?') !== -1 ? '&' : '?';

		if (url.match(regex)) {
			return url.replace(regex, `$1${key}=${value}$2`);
		} else {
			return `${url}${separator}${key}=${value}`;
		}
	}

	const changeQueryParams = (queriesToChange: QueriesToChange[]) => {
		let updatedPath = fullPath;
		for (const queryToChange of queriesToChange) {
			updatedPath = replace(updatedPath, queryToChange.name, queryToChange.newValue)
		}
		const queriesToChangeWithReplaced: Array<QueriesToChange & { isReplaced: boolean }> = [];
		for (let i = 0; i < queriesToChange.length; i += 1) {
			const { name, newValue, isEncode } = queriesToChange[i];
			if (updatedPath.includes(`${name}=`)) {
				const param = isEncode ? encodeURIComponent(params[name]) : params[name];
				updatedPath = updatedPath
					.replace(`&${name}=${param}`, `&${name}=${newValue}`)
					.replace(`?${name}=${param}`, `?${name}=${newValue}`);
				queriesToChangeWithReplaced.push({
					...queriesToChange[i],
					isReplaced: true,
				});
			} else {
				queriesToChangeWithReplaced.push({
					...queriesToChange[i],
					isReplaced: false,
				});
			}
		}
		for (let i = 0; i < queriesToChangeWithReplaced.length; i += 1) {
			const { name, newValue, isReplaced } = queriesToChangeWithReplaced[i];
			const withQuery = updatedPath.includes(`${location.pathname}?`);
			if (withQuery) {
				updatedPath = `${updatedPath}${isReplaced ? "" : `&${name}=${newValue}`}`;
			} else {
				updatedPath = `${updatedPath}?${name}=${newValue}`;
			}
		}
		push(updatedPath);
	};

	const removeQueryParamsInner = (allParams: URLSearchParams, names: string[]) => {
		names.forEach((name) => allParams.delete(name));
		push(`${location.pathname}?${allParams.toString()}`);
	};

	const removeQueryParam = (name: string | string[]) => {
		const allParams = new URLSearchParams(window.location.search);

		return removeQueryParamsInner(allParams, Array.isArray(name) ? (name as string[]) : [name]);
	};

	return {
		queryParams: params as T,
		changeQueryParams,
		removeQueryParam,
	};
};
