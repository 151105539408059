import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import cn from "classnames";
import { TaskTypes } from "../../../../../../../types";
import { useMemo } from "react";

import { MultiLayerSelect } from "../../../../../../UI";
import { mapTaskTypesToSelectOptions } from "./helpers";

import styles from "./index.module.scss";

type Props = {
	types: TaskTypes[];
	name: string;
	placeholder: string;
	isLoading?: boolean;
	control: Control<any>;
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	register: UseFormRegister<any>;
};

export const TaskType = ({
	types,
	placeholder,
	name,
	isLoading,
	control,
	getValues,
	setValue,
	register,
}: Props): JSX.Element => {
	const typeWatcher = useWatch({
		control,
		name,
		defaultValue: getValues(name) || [],
	});

	const options = useMemo(() => mapTaskTypesToSelectOptions(types), [types]);

	return (
		<div className={cn(styles.filterWrapper, styles.taskTypeWrapper)}>
			<MultiLayerSelect
				name={name}
				options={options}
				setValue={setValue}
				register={register}
				placeholder={placeholder}
				defaultValue={typeWatcher}
				isLoading={isLoading}
			/>
		</div>
	);
};
