import { CollapseDown, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { RelatedLeadCard } from "../../../../../../UI";
import { Lead, LeadSourceEnum } from "../../../../../../../types";
import { UseOpenRelatedLeadModal } from "./addRelatedLeadModal/UseOpenRelatedLeadModal";
import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/plus-small.svg";
import { ReactComponent as InfoIcon } from "../../../../../../../assets/icons/iconInfo.svg";
import styles from "./index.module.scss";
import cn from "classnames";
import { selectCurrentAccount, useSelect } from "../../../../../../../service/redux/selectors";
import { Plug } from "../plug/Plug";
import { CardLead } from "../../../../../../UI/Card/card-lead/CardLead";

type Props = {
  lead: Lead;
  refetch: () => void;
};

export const RelatedLeads = ({lead, refetch}: Props): JSX.Element => {
  const {openAddRelatedLeadModal} = UseOpenRelatedLeadModal({lead, refetch})
  const handleClickSubLead = (id: string) => {
    window.open(`/leadsanddeals?tab=leads&id=${id}`, "_blank", "noopener noreferrer");
  };

  const {currentAccount} = useSelect(selectCurrentAccount);
  const isAnotherResponsible = lead.responsibleUser ? lead.responsibleUser.id !== currentAccount?.id : false;

  return (
    <div className={styles.root}>
      <div>
        <CollapseDown
          label={
            <div className={styles.title}>
              Побочные лиды
            </div>
          }
          classNames={{
            root: styles.tableRoot,
            labelText: styles.labelText
          }}
          headerChildren={
            <>
              <Tooltip
                classNames={{
                  root: styles.tooltipDescription,
                  card: styles.tooltipCard,
                }}
                iconSize="big"
                CustomIcon={InfoIcon}
              >
                Побочный лид создается с целью клонирования лида для назначения его на другого сотрудника или на себя
              </Tooltip>
            </>
          }
        >
          {lead.relatedLeads?.childRelatedLeads && lead.relatedLeads?.childRelatedLeads.length > 0
            ? lead?.relatedLeads?.childRelatedLeads?.map((subLead) => (
            <CardLead 
              lead={subLead} 
              mini
              background="gray"
            />
            
          ))
          : <Plug plugText="Нет побочных лидов"/>
          }
        </CollapseDown>
      </div>
      {lead.source !== LeadSourceEnum.lead && !isAnotherResponsible &&
        <div
          className={cn(styles.row, styles.add)}
          onClick={openAddRelatedLeadModal}
        >
          <PlusIcon/>
          <div>Создать лид</div>
        </div>
      }
    </div>
  );
};
