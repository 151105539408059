import cn from "classnames";
import { Control, UseFormGetValues, UseFormSetValue, useWatch } from "react-hook-form";

import { Checkbox } from "../../../../../../../../../../UI";
import { LeftFilter } from "../../helpers";
import { TaskLeftMenuFilter } from "../../../../../../../../../../../types";

import styles from "./index.module.scss";

type FieldValues = Record<TaskLeftMenuFilter, boolean>;

type Props = {
	control: Control<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	allFilters: readonly LeftFilter[];
	filter: LeftFilter;
};

export const FiltersGroup = ({ control, getValues, setValue, allFilters, filter }: Props): JSX.Element => {
	// trigger rerender on subfilters change
	useWatch({
		name: filter.subfilters?.map((sub) => sub.name) ?? [],
		control,
	});

	const onFilterGroupClick = (filterName: string) => {
		const group = allFilters.find((filterItem) => filterItem.name === filterName);
		const subs = group?.subfilters;

		if (!subs) return;

		const allSubsChecked = subs.every((sub) => getValues()[sub.name]);

		// if all sub checkboxes are true, then toggle them to off
		// else toggle them to on
		subs.forEach((sub) => setValue(sub.name, !allSubsChecked));

		setValue("returned", false);
	};

	const anySubIsActive = (groupName: string) =>
		allFilters
			.find((filterItem) => filterItem.name === groupName)
			?.subfilters?.some((sub) => getValues()[sub.name]);

	return (
		<div>
			<span
				onClick={() => onFilterGroupClick(filter.name)}
				className={cn(styles.groupName, {
					[styles.active]: anySubIsActive(filter.name),
				})}
			>
				{filter.label}
			</span>

			<div className={styles.group}>
				{filter.subfilters?.map((subfilter) => (
					<Checkbox key={subfilter.name} control={control} name={subfilter.name} label={subfilter.label} />
				))}
			</div>
		</div>
	);
};
