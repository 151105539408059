import { useForm } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { CardAgent, Comment } from "../../../../../../UI";

import styles from "../common.module.scss";
import tyler from "../../../../../../../assets/images/tyler.jpeg";

const text = `
  В комнате проведен достаточно некачественный ремонт.
  Отсутствует водонагреватель, соответственно будут проблемы с горячей водой.
  В комнате проведен достаточно некачественный ремонт.
  Отсутствует водонагреватель, соответственно будут проблемы. Отсутствует водонагреватель, соответственно будут проблемы."
`;

export const ObjectShows = (): JSX.Element => {
	const { register, setValue, getValues } = useForm({
		defaultValues: {
			prevComment: "Test prev Comment",
			comment: "Test comment",
		},
	});

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<h2>Ваш риэлтор</h2>
				<CardAgent
					background="blue"
					size="medium"
					image={tyler}
					company="Риэлтор «ООО РиалСофт»"
					account={{
						firstName: "Иван",
						lastName: "Баршмачьев",
						secondName: "Николаевич",
					}}
					phone="+7 952 345 54 54"
					socialLinks={[
						{ type: "vk", link: "http://1example.com" },
						{ type: "instagram", link: "http://example.com" },
					]}
					rating={4.2}
				/>

				<Button classNames={{ root: styles.button }}>Перенести просмотр</Button>
			</div>

			<div className={styles.comments}>
				<Comment image={tyler} title="Комментарий риэлтора" text={text} />

				<Comment
					editable
					title="Ваш комментарий"
					text="Test comment"
					form={{ name: "prevComment", register, setValue }}
				/>
			</div>

			<div className={styles.comment}>
				<Comment
					isActive
					placeholder="Введите описание задачи"
					title="Ваш комментарий"
					text={getValues().comment}
					form={{ name: "comment", register, setValue }}
				/>
			</div>
		</div>
	);
};
