import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/real-estate/delete-photo/DEFAULT_MN";

type UseObjectDeletePhoto = {
	onSuccess?: () => void;
};

export const useObjectDeletePhoto = ({ onSuccess }: UseObjectDeletePhoto) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (photoId: string) => api.realEstate.removePhoto(photoId),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
	};
};
