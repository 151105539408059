import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { Alerter, AddressSelect } from "../../../../../UI";
import { setStep } from "../../../../../../service/redux/modules/registration-steps/reg-steps.action";
import { commonErrors } from "../../../../../../utils/errors";
import { useFinishRegistration } from "../../../../../../service/redux/modules/auth/auth.thunk";

import { ReactComponent as PlaceIcon } from "../../../../../../assets/icons/place.svg";
import styles from "./index.module.scss";

export const SingleRealtorForm = (): JSX.Element => {
	const dispatch = useDispatch();

	const schema = yup.object().shape({
		address: yup.object().shape({
			value: yup.string().required(commonErrors.required),
		}),
	});

	const { handleSubmit, formState, register, setValue, watch } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			address: { value: "", label: "" },
		},
	});

	const [watchAddress] = watch(["address"]);

	const onSubmit = () => {
		dispatch(useFinishRegistration());
		dispatch(setStep("done-single-realtor"));
	};

	return (
		<form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.fields}>
				<Alerter classNames={{ root: styles.realtorInfo }} backgroundColor="light">
					Сегодня ты волк-одиночка, но в любой момент сможешь создать компанию или присоединиться к
					существующей.
				</Alerter>

				<AddressSelect
					classNames={{ selected: styles.addressSelect }}
					name="address"
					placeholder="Город или адрес"
					register={register}
					setValue={setValue}
					withBorder
					error={formState.errors.address?.value?.message?.toString()}
					value={watchAddress}
					prepend={<PlaceIcon />}
				/>
			</div>
			<Button type="submit" classNames={{ root: styles.buttonSingleRealtor }}>
				Начать
			</Button>
		</form>
	);
};
