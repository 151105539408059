import { Contact } from "../../../../../../../../types";
import styles from "./index.module.scss"
import cn from "classnames";
import { ReactComponent as ContactIcon } from "../../../../../../../../assets/icons/iconContact.svg";
import { selectCurrentAccount, useSelect } from "../../../../../../../../service/redux/selectors";
import { ReactComponent as ImResponsibleIcon } from "../../../../../../../../assets/icons/imResponsibleIcon.svg";

type Props = {
  contact: Contact
  messageIn?: boolean
}

export const PersonInChat = ({contact, messageIn}: Props): JSX.Element => {
  const {
    lastName,
    name,
    secondName
  } = contact

  const { currentAccount } = useSelect(selectCurrentAccount);

  return (
    <>
      {messageIn ?
        <div className={cn(styles.contact)}>
        <div className={styles.avatar}>
          <ContactIcon/>
        </div>
        <span>
           {`${name || ""} ${secondName || ""} ${lastName || ""}`}
        </span>
      </div>
      : <div className={cn(styles.contact)}>
          <div className={styles.avatar}>
            {currentAccount?.account.avatar
              ? <img src={currentAccount?.account.avatar} alt={"Contact"}/>
              :  <ImResponsibleIcon/>
            }
          </div>
          <span>
           {`${currentAccount?.account.name || ""} ${currentAccount?.account.secondName || ""} ${currentAccount?.account.lastName || ""}`}
        </span>
        </div>
      }
    </>

  )
}