import { Route, Switch } from "react-router-dom";

import { AuthGuard } from "./AuthGuard";
import { Contacts } from "../../components/pages/Contacts";
import {
	InvitePage,
	LoginPage,
	RegistrationPage,
	ResetPassword,
} from "../../components/pages/Authentication";
import { RegistrationPageV2 } from "components/pages/Authentication/RegistrationPageV2";
import { RealEstateList } from "../../components/pages/RealEstateList";
import {
	CreateRealEstatePage,
	ParsedRealEstatePage,
	RealEstatePage,
} from "../../components/pages/RealEstatePage";
import { Dashboard } from "../../components/pages/Dashboard";
import { EmployeesList } from "../../components/pages/EmployeesList";
import { LeadList } from "../../components/pages/LeadsAndDeals";
import {
	AgentObjectCompilation,
	ApplicationPublic,
	ComparisonPublic,
	MainPublic,
} from "../../components/pages/public-part";
import { Tasks } from "../../components/pages/Tasks";
import { AdvertisingCabinet } from "../../components/pages/AdvertisingCabinet";
import { paths } from "../../utils/paths";
import { Services } from "../../components/pages/Servises";

export const Router = (): JSX.Element => (
	<Switch>
		{/* Unauthenticated Routes */}
		<Route exact path={paths.loginPath} component={LoginPage} />
		<Route exact path={paths.registrationPath.default} component={RegistrationPageV2} />
		<Route exact path={paths.registrationPath.linkInvite} component={InvitePage} />
		<Route exact path={paths.resetPasswordPath} component={ResetPassword} />
		<Route exact path={paths.publicPath.publicMain} component={MainPublic} />
		<Route exact path={paths.publicPath.application} component={ApplicationPublic} />
		<Route exact path={paths.publicPath.agentCompilation} component={AgentObjectCompilation} />
		<Route exact path={paths.publicPath.publicComparison} component={ComparisonPublic} />
		<Route exact path={paths.dashboardPath} component={Dashboard} />
		{/* Authenticated Routes */}
		<AuthGuard>
			<Switch>
				<Route exact path={paths.indexPath} component={Services} />
				<Route exact path={paths.objectsPath.createApplicationObject} component={CreateRealEstatePage} />
				<Route exact path={paths.objectsPath.list} component={RealEstateList} />
				<Route exact path={paths.objectsPath.viewParser} component={ParsedRealEstatePage} />
				<Route exact path={paths.objectsPath.viewApplicationObject} component={RealEstatePage} />
				<Route exact path={paths.advertisingPath.list} component={AdvertisingCabinet} />
				<Route exact path={paths.employeesPath.list} component={EmployeesList} />
				<Route exact path={paths.leadsAndDealsPath.list} component={LeadList} />
				<Route exact path={paths.tasks.list} component={Tasks} />
				<Route exact path={paths.tasks.view} component={Tasks} />
				<Route exact path={paths.services.list} component={Services} />
			</Switch>
		</AuthGuard>
	</Switch>
);
