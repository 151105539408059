import { useDispatch } from "react-redux";
import { useEffect, Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router";
import { Location } from "history";
import isEqual from "lodash.isequal";
import { RealEstateFilter } from "../../../../../types";

import { toastSuccess } from "../../../../UI";
import { useUpdateCompilationFilter } from "../../../../../service/api/mutations";
import { COMPILATION_PAGE_LOAD_COMP_QN, useLoadCompilation } from "../../../../../service/api/queries";
import { openModal, closeModal } from "../../../../../service/redux/modules/modal/modal.action";

type Props = {
	applicationId: string;
	isCompilationPage: boolean;
	filter: RealEstateFilter;
	setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
};

export const useSyncCompilationFilters = ({ applicationId, isCompilationPage, filter, setFilter }: Props) => {
	const DEFAULT_FILTER_ON_PAGE = {
		dealType: "Продажа",
		address: {
			label: "",
			value: "",
		},
		priceFrom: "",
		priceTo: "",
		unit: "объект",
		category: [],
		actual: ["Актуальный"],
	};

	const [isComplicationSaved, setIsCompilationSaved] = useState(true);

	const dispatch = useDispatch();
	const { push } = useHistory();
	const { compilation, remove } = useLoadCompilation({
		applicationId,
		queryName: COMPILATION_PAGE_LOAD_COMP_QN,
		enabled: isCompilationPage,
	});
	const { mutate } = useUpdateCompilationFilter({
		onSuccess: () => {
			toastSuccess({ text: "Фильтр успешно сохранен" });
		},
	});

	const { filterData: compilationFilterData } = compilation || {};

	useEffect(() => {
		const isFiltersEqual = isEqual(filter, compilationFilterData);

		if (isFiltersEqual !== isComplicationSaved) {
			setIsCompilationSaved(isFiltersEqual);
		}
	}, [compilationFilterData, filter]);

	useEffect(() => {
		if (compilationFilterData) {
			setFilter({
				...filter,
				...compilationFilterData,
			});
		}
	}, [compilationFilterData]);

	const handleOnSaveCompilationFilter = () => {
		if (!isComplicationSaved) {
			setIsCompilationSaved(true);
		}

		const payload = {
			applicationId,
			filterData: filter,
		};

		mutate(payload);
	};

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			remove();
		};
	}, []);

	const openSaveModal = (nextLocation: Location<unknown>) => {
		const { pathname, search } = nextLocation;

		const fullNewUrl = pathname + search;
		dispatch(
			openModal({
				title: "Изменения в фильтрах не сохранены",
				body: "Сохранить внесенные вами изменения в фильтре?",
				variant: "alert",
				alertIcon: "warning",
				alertButtonSubmit: {
					label: "Сохранить",
					onClick: async () => {
						dispatch(closeModal());
						await handleOnSaveCompilationFilter();
						push(fullNewUrl);
					},
				},
				alertButtonDecline: {
					label: "Не сохранять",
					onClick: async () => {
						await setIsCompilationSaved(true);
						dispatch(closeModal());
						push(fullNewUrl);
					},
				},
			})
		);
	};

	const handleAlertFilterWasntSaved = (nextLocation: Location<unknown>) => {
		const { search } = nextLocation;

		const nextPageNotCompilation = search.indexOf("compilation=true") !== 1;

		if (nextPageNotCompilation && !isComplicationSaved) {
			if (compilationFilterData === null) {
				const isDefaultFilters = isEqual(filter, DEFAULT_FILTER_ON_PAGE);

				if (!isDefaultFilters) {
					openSaveModal(nextLocation);

					return false;
				}
			} else {
				const isFiltersEqual = isEqual(filter, compilationFilterData);
				if (!isFiltersEqual) {
					openSaveModal(nextLocation);

					return false;
				}
			}
		}

		return true;
	};

	return {
		handleAlertFilterWasntSaved,
		handleOnSaveCompilationFilter,
	};
};
