import { ReactNode } from "react";
import { Col as GridCol, Container as GridContainer, Row as GridRow } from "react-grid";

import "./index.scss";

const styles = {
	breakpoints: { xs: 414, sm: 576, md: 768, lg: 992, xl: 1200 },
	containerMaxWidths: { sm: "100%" },
	columns: 12,
	gutterWidth: 10,
};

export const Container = ({ children }: { children: ReactNode }): JSX.Element => (
	<GridContainer styles={styles}>{children}</GridContainer>
);

type TRow = {
	children: ReactNode;
	marginBottom?: number;
};

export const Row = ({ children, marginBottom }: TRow): JSX.Element => (
	<GridRow className="grid-row-class" styles={styles} style={{ marginBottom }}>
		{children}
	</GridRow>
);

// eslint-disable-next-line react/jsx-props-no-spreading
export const Col = (props): JSX.Element => <GridCol {...props} styles={styles} className="grid-col-class" />;
