export enum ErrorCodeEnum {
	// PS - Payment Service
	PS001 = "Объект не рекламируется",
	PS002 = "Платная услуга не существует на площадке",
	PS003 = "Платная услуга уже применена",
	PS004 = "Платная услуга несовместима с другими примененными платными услугами",
	PS005 = "У вас нет лимита на применение платных услуг",

	// AP - Applications
	AP001 = "Должен быть указан хотя бы один контакт",
	AP002 = "У заявки должен быть хотя бы 1 ответственный",
	AP003 = "Вы не можете перейти на данный этап согласно настройкам бизнес-процесса",
	AP004 = "У этапа заявки может быть только один ответственный",
	AP103 = "Эта заявка уже в архиве.",

	// LD - Leads
	LD001 = "Нельзя завершить лид без заявок",
	LD002 = "Во всех заявках, созданных из лида, должен быть указан хотя бы один контакт",
	LD003 = "Не найден родительский лид при создании лида из лида",
	LD004 = "Не найден дочерний лид при создании лида из лида",
	LD005 = 'Взять в работу можно только лид со статусом "Нераспределенные" или "Необработанные"',
	LD006 = "Вы не можете перейти на данный этап согласно настройкам бизнес-процесса",
	LD007 = "Не указан id компании при создании лида через публичное API",
	LD008 = "Во всех заявках, созданных из лида, должен быть указан хотя бы один ответственный",
	LD009 = "Нельзя создать лид из лида без контакта",
	LD010 = "Неверные данные комании при создании публичного лида",
	LD011 = "Нельзя завершать лида когда заявка связанная с ним является шаблоном",
	LD012 = "При переносе лида в некачественные нужно указать причину переноса",
	LD013 = "При переносе лида в отложенный спрос требуется указать дату появления",
	LD014 = "Лид можно завершить только из статуса “В рaботе”",
	LD015 = "При переносе лида в некачественные с причиной “Другое” обязательно нужно указать комментарий причины переноса",
	LD016 = "Взять в работу можно только лид со статусом Новый",
	LD017 = "Взять в работу можно только лид без ответственного или лид где я ответственный",
	LD018 = "Взять в работу можно только незаблокированный лид или заблокированный мною",
	LD019 = "Переместить лид в некачественные можно только из статуса “В работе”",
	LD020 = "Отложить лид можно только из статуса “В работе”",
	LD021 = "Нельзя сменить статус успешно завершенного лида",
	LD022 = "Отказаться от лида можно в случае если он был открыт вами не более чем 1 минуту назад",
	LD023 = "Эти лиды уже связаны",
	LD024 = "Эти лиды не были связаны",
	LD025 = "Лид заблокирован другим агентом, доступ к нему запрещен",
	LD026 = "Отказаться можно только от лида со статусом “Новый” и “В работе”",
	LD027 = "Вернуть из некачественных можно только лид со статусом “Не качественный”",

	// EO - Estate Objects
	EO001 = "Этаж не должен быть больше этажности объекта",
	EO002 = "Суммарная площадь помещений не должна быть больше общей площади объекта",
	EO003 = "Стоимость объекта должна быть больше 100 000 руб.",
	EO004 = "Площадь кухни должна быть больше 2 кв. м.",
	EO005 = "Адрес должен быть указан с точностью до дома",
	EO006 = "Суммарная площадь жилых комнат не должна превышать общую жилую площадь",

	// RE - Registration
	// RE001 = "Данный номер уже зарегистрирован",
	RE002 = "Номер уже зарегистрирован, войдите в систему",
	RE003 = "Регистрация не завершена, завершите ее выбрав роль",
	RE004 = "Ваш запрос на вступление в компанию еще не рассмотрели",
	RE005 = "Ваш запрос на вступление в компанию {companyName} отклонили",

	// OT - OTP (One Time Password) Authentication
	// OT001 = "Неверный код подтверждения",
	OT002 = "Код подтверждения уже использован",
	OT003 = "Код подтверждения просрочен",
	OT004 = "Произошла внутренняя ошибка при проверке кода подтверждения",
	OT005 = "Слишком много звонков на один номер",

	// AC - Application’s Collection of Objects
	AC001 = "Некоторые объекты из запроса уже находятся в подборке",
	AC002 = "Объект уже был удален из подборки ранее",

	// CO - Contacts
	CO001 = "Удаление контакта невозможно - вероятно есть связи с лидами либо заявками",
	CO002 = "Восстановление контакта из архива невозможно, т.к. в компании существует другой контакт с таким же номером",
	CO003 = "Невозможно сохранить контакт, т.к. в компании существует другой контакт с таким же номером",

	// UA - User Account
	UA001 = "Недостаточно прав для редактирования основной информации",

	// EX - Experience
	EX001 = "Даты окончания работы может не быть только у последнего места работы",
	EX002 = "Дата начала на новом месте работы не может быть раньше окончания старой",
	EX003 = "Дата окончания работы не может быть позже даты начала работы",
	EX004 = "Некорректные даты в опыте работы",

	// AN - Accession
	AN001 = "Заявка на вступление была принята ранее",

	// IN - Invite
	IN001 = "Этот номер телефона уже зарегистрирован и работает в этой или другой компании",

	// CFL - Create From Lead
	CFL001 = "заявка не найдена",
	CFL002 = "у заявки нет родительского лида",
	CFL003 = "у родительского лида нет контакта",
	CFL004 = "к заявке уже прикреплен контакт. Удалите его и повторите попытку.",

	// AGR001 - Агентское соглашение
	AGR001 = "Для заключения письменного агентского соглашения укажите кадастровый номер объекта или номер квартиры.",
	AGR002 = "Для заключения письменного агентского соглашения требуется заполнить объект.",
	AGR003 = "Ошибка изменения агентского соглашения",
	PI001 = "Укажите все паспортные данные контакта клиента чтобы заключить с ним письменное агентское соглашение",
	PI002 = "Прикрепите контакт клиента к предзаявке чтобы заключить с ним агентское соглашение",

	PA011 = "Для того, чтобы активировать заявку, требуется заключить агентское соглашение.",

	CO010 = "Невозможно открепить контакт с которым заключено агентское соглашение",
	CO011 = "Нельзя открепить последний контакт",

	// AD - реклама
	AD050 = "Для того чтобы отправить объект в рекламу, пожалуйста заполните рекламный текст в верхней части страницы",

	// SA - корзина
	SA001 = "Данный купон не существует или был применен ранее",
	
	// UE - подписка
	UE001 = "Срок действия подписки закончился, пожалуйста, продлите подписку"
}
