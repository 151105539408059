import { H3 } from "@sdelka_crm/sdelka-crm-component-library";

import { CardPerson } from "../../../../../../UI";
import { useLoadPositions } from "../../../../../../../service/api/queries";
import { User } from "../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
  responsibleUser: User;
};

export const Responsibles = ({responsibleUser}: Props): JSX.Element => {

  const {getPositionName} = useLoadPositions({});

  if (!responsibleUser) {
    return <></>;
  }

  const {id: positionId} = responsibleUser.account.position || {};

  return (
    <>
      <H3>Ответственные по объекту</H3>

      <div className={styles.card}>
        <CardPerson
          id={responsibleUser.id}
          phone={responsibleUser.account.phone}
          position={getPositionName(positionId)}
          avatar={responsibleUser.account.avatar}
          fio={{
            firstName: responsibleUser.account.name,
            lastName: responsibleUser.account.lastName,
            secondName: responsibleUser.account.secondName
          }}
          omitAvatar
        />
      </div>
    </>
  );
};
