import { Control } from "react-hook-form";
import cn from "classnames";
import { Loader, Underlay } from "@sdelka_crm/sdelka-crm-component-library";
import { ShowsViewsStats } from "../../../../../types";
import { RadioButton, TRadio } from "../../../../UI";
import styles from "./index.module.scss";

type Props = {
  isViews: boolean;
  control: Control<any>;
  options: TRadio[];
  name: string;
  stats?: ShowsViewsStats;
  isLoading?: boolean;
  onChange: (value: string | number) => void;
};

export const Header = (
  {
    isViews,
    onChange,
    isLoading,
    name,
    control,
    options,
    stats,
  }: Props): JSX.Element => {
  const {planned, finished, requests, total} = stats || {};

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h2>{isViews ? "Просмотры" : "Показы"}</h2>
      </div>
      <Underlay className={styles.stats}>
        <Loader height={120} style={{display: isLoading ? "block" : "none"}}/>
        <div className={styles.statsContainer} style={{display: isLoading ? "none" : "flex"}}>
          <div>
            <div className={styles.statsField}>
              <span>Запланированные</span>
              <span>{planned}</span>
            </div>
            <div className={styles.statsField}>
              <span>Завершенные</span>
              <span>{finished}</span>
            </div>
            <div className={cn(styles.statsAll, styles.statsField)}>
              <span>Всего</span>
              <span>{total}</span>
            </div>
          </div>
          <div>
            <div className={styles.statsField}>
              <span>Запросы от покупателей</span>
              <span className={styles.red}>{requests}</span>
            </div>
          </div>
        </div>
      </Underlay>
      <div className={styles.body}>
        <RadioButton
          name={name}
          control={control}
          options={options}
          handleOnChange={onChange}
          background="blue"
          backgroundButtons="blue"
        />
      </div>
    </div>
  );
};
