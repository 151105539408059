import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoadingWindow } from "@sdelka_crm/sdelka-crm-component-library";

import { Drawer, GallerySlider, Modal, ToastContainer } from "./components/UI";
import { initialize } from "./service/redux/modules/auth/auth.thunk";
import { Router } from "./service/router/Router";
import { selectAccounts, useSelect } from "./service/redux/selectors";

import FullLogo from "./assets/icons/fullMainLogo.png";
import "@sdelka_crm/sdelka-crm-component-library/dist/esm/index.css";
import "./styles/global.scss";
import styles from "./index.module.scss"

export const App = (): React.ReactElement => {
  const {initialized} = useSelect(selectAccounts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);

  return (
    <>
      <ToastContainer/>
      <Modal/>
      <div className={styles.root}>
        <LoadingWindow initialized={initialized} imgSrc={FullLogo}/>
      </div>
      {initialized && <Router/>}
      <Drawer/>
      <GallerySlider/>
    </>
  );
};
