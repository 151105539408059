import { useReducer, useCallback } from "react";
import { useDispatch } from "react-redux";

import { EditTitle } from "./components";
import { changeTitle, deleteTitle } from "../../../../redux/modules/modal/modal.action";
import { TaskModalEnum, StateModalMode, ActionModalMode } from "../../../../../types";

const initialModeState: StateModalMode = {
	mode: TaskModalEnum.edit,
};

const modeReducer = (_: StateModalMode, action: ActionModalMode) => {
	switch (action.type) {
		case "makeEditMode":
			return { mode: TaskModalEnum.edit };
		case "makeViewMode":
			return { mode: TaskModalEnum.view };
		case "makeFinishMode":
			return { mode: TaskModalEnum.finish };

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

type Props = {
	createdAt?: Date;
};

export const useModeReducer = ({ createdAt }: Props) => {
	const dispatch = useDispatch();

	const [modeState, dispatchMode] = useReducer(modeReducer, initialModeState);

	const handleFinishMode = useCallback(() => {
		dispatchMode({ type: "makeFinishMode" });
	}, []);

	const handleViewMode = useCallback(() => {
		dispatch(deleteTitle());
		dispatchMode({ type: "makeViewMode" });
	}, []);

	const handleEditMode = useCallback(() => {
		dispatch(changeTitle({ newTitle: <EditTitle createdAt={createdAt} /> }));
		dispatchMode({ type: "makeEditMode" });
	}, []);

	const { mode } = modeState;

	return {
		mode,
		handleFinishMode,
		handleViewMode,
		handleEditMode,
	};
};
