import { useMemo, useState } from "react";
import { useQuery } from "react-query";

import { ContactFilter, ContactSort } from "../../../../types";
import { api } from "../../index";
import { CONTACT_DEFAULT_FILTERS } from "../../helpers";

const DEFAULT_QN = "sdelka/contact/load-contacts/DEFAULT_QN";

const DEFAULT_JOIN = ["company", "user", "phones", "comments"];
const DEFAULT_SORT: ContactSort = { createdAt: "ASC" };

type UseContacts = {
	defaultTake?: number;
	defaultSort?: ContactSort;
	join?: string[];
	options?: {
		keepPreviousData?: boolean;
	};
};

export const useContacts = ({
	defaultTake = 10,
	options = {},
	defaultSort = DEFAULT_SORT,
	join = DEFAULT_JOIN,
}: UseContacts) => {
	const { keepPreviousData } = options;
	const [filter, setFilter] = useState<ContactFilter>(CONTACT_DEFAULT_FILTERS);
	const [page, setPage] = useState(1);
	const [take, setTake] = useState(defaultTake);
	const skip = useMemo(() => (page - 1) * take, [page]);
	const [sort, setSort] = useState<ContactSort>(defaultSort);

	const loadContacts = () =>
		api.contacts.list({
			take,
			skip,
			sort,
			join,
			search: filter,
		});

	const { data, isLoading, isFetching, refetch } = useQuery(
		[DEFAULT_QN, take, skip, sort, filter],
		loadContacts,
		{ keepPreviousData }
	);

	return {
		contactsList: data?.data?.data || [],
		isLoading,
		isFetching,
		refetch,
		total: data?.data?.total || 0,
		page: data?.data?.page || 1,
		take,
		setTake,
		sort,
		setSort,
		setPage,
		filter,
		setFilter,
	};
};
