import { Control, UseFormGetValues, UseFormRegister, useWatch } from "react-hook-form";

import { Time, InfoRow } from "../../../../index";

import styles from "./index.module.scss";

type Props = {
	register: UseFormRegister<any>;
	getValues: UseFormGetValues<any>;
	control: Control<any>;
	name: string;
	label: string;
	disabled?: boolean;
};

export const TimeTemplate = ({ register, name, label, getValues, control, disabled }: Props): JSX.Element => {
	const timeWatcher = useWatch({
		control,
		name,
		defaultValue: getValues(name) || undefined,
	});

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={label}>
			<Time
				register={register}
				name={name}
				hideLabel
				classNames={{
					wrapper: styles.wrapper,
				}}
				value={timeWatcher}
				disabled={disabled}
			/>
		</InfoRow>
	);
};
