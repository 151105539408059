import styles from "./index.module.scss";
import { Item } from "../Item";
import { RealEstate } from "../../../../../../../types";

export const useAdColumns = () => [
  {
    accessor: "numberAndCreatedAt",
    render: ({row}: { row: RealEstate }) => (
      <div className={styles.root}>
        <Item key={row.id} realEstate={row} />
      </div>
    ),
  },
];
