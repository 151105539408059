import { useDispatch } from "react-redux";
import { Button, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { closeModal } from "../../../../../../../service/redux/modules/modal/modal.action";
import cn from "classnames";
import { CalendarInPopup, Time, toastError, toastSuccess } from "../../../../../../UI";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { UseMutateFunction } from "react-query";
import { AxiosResponse } from "axios";
import { Payload } from "../../../../../../../service/api/mutations/shows-views/useCreateView";
import styles from "../assign-view-modal/index.module.scss"

type Props = {
  onCreate: UseMutateFunction<AxiosResponse<any>, unknown, Payload, unknown>
  applicationId: string
}

export const AssignShowModal = ({onCreate, applicationId}: Props): JSX.Element => {
  const createText = "показ"
  const dispatch = useDispatch()
  const {register, getValues, setValue, control} = useForm();

  function timeToMilliseconds(timeStr?: string) {
    if (timeStr === undefined) {
      return 0
    }
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  }

  const [time, setTime] = useState("")
  const date1 = new Date(getValues("deadlineDate"));
  const dateError = !getValues("deadlineDate") ? "Выберите дату" : undefined
  const date2 = new Date(timeToMilliseconds(time));
  const timeError = +time < 60000 ? "Выберите время" : undefined
  const sumMilliseconds = date1.getTime() + date2.getTime();
  const sumDate = new Date(sumMilliseconds);
  const [hasError, setHasError] = useState(false)

  const payload: Payload = {
    applicationId,
    date: sumDate
  }

  const onApply = () => {
    if (dateError === "Выберите дату") {
      setHasError(true)
    }
    if (dateError === undefined) {
      setHasError(false)
      onCreate(payload)
      toastSuccess({text: `Вы успешно создали ${createText}`})
      dispatch(closeModal())
    }
  }
  const onDeny = () => {
    toastError({text: `Вы не создали ${createText}`})
    dispatch(closeModal())
  }

  const dateName = "deadlineDate";
  const currentDate = useWatch({
    name: dateName,
    control,
    defaultValue: getValues(dateName),
  });

  const timeName = "deadlineTime";
  const currentTime = useWatch({
    name: timeName,
    control,
    defaultValue: getValues(timeName),
  });

  useEffect(() => {
    setTime(currentTime)
    if (dateError === undefined) {
      setHasError(false)
    }
  }, [currentTime, dateError])

  return (
    <>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.title}>
            {`Назначение ${createText}а`}
          </div>
          <div className={styles.text}>
            Выберите дату показа, после этого вы сможете добавить контакт
          </div>
        </div>
      </div>
      <InfoRow
        classNames={{
          root: cn(styles.infoRow, styles.calendarChild),
          content: styles.flexRow,
        }}
        labelFont="ubuntu"
        label="Дата и время показа"
      >
        <CalendarInPopup
          form={{name: dateName, register, setValue, getValues, required: true}}
          defaultDate={currentDate}
          classNames={{
            wrapper: styles.calendar,
            label: styles.calendarLabel,
            trigger: styles.popupTrigger,
            error: styles.calendarError
          }}
          error={hasError ? dateError : undefined}
        />
        <Time
          classNames={{
            root: styles.timeRoot,
            wrapper: styles.timeWrapper,
          }}
          variant="noLabel"
          name={timeName}
          onChange={(value) => setTime(value)}
          register={register}
          value={currentTime}
          error={timeError}
        />
      </InfoRow>
      <div className={styles.rowEnd}>
        <Button
          onClick={onDeny}
          variant="outlined"
        >
          Отмена
        </Button>
        <Button
          onClick={onApply}
          classNames={{root: styles.buttonCreate}}
          disabled={hasError}
        >
          {`Назначить ${createText}`}
        </Button>
      </div>
    </>
  )
}