import styles from "./index.module.scss"
import cn from "classnames";

type Props = {
  plugText: string
  mini?: boolean
}

export const Plug = ({plugText, mini}: Props): JSX.Element => {
  return (
    <div className={cn(styles.root, {[ styles.mini ]: mini})}>
      <div className={styles.text}>
        {plugText}
      </div>
    </div>
  )
}