import { memo } from "react";
import cn from "classnames";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { Link, Tag, TagTaskStatus, CreatorToResponsible } from "../../index";
import { TaskStatus, TaskStatusEnum, UserPreview } from "../../../../types";

import styles from "./index.module.scss";

type Props = {
	label: string;
	description: string;
	date: Date;
	leftContact?: Partial<UserPreview>;
	rightContact?: Partial<UserPreview>;
	status: TaskStatusEnum;
	isSuccessFinished?: boolean;
	deadline?: Date;
	important?: boolean;
	taskType?: {
		link: string;
		text: string;
	};
	underlay?: boolean;
	id?: string;
	onCardClick?: (id?: string) => void;
	classNames?: {
		root?: string;
		statusIcon?: string;
	};
};

const noTime = ["done", "failed"];

export const CardTask = memo(
	({
		underlay = false,
		label,
		description,
		date,
		leftContact,
		rightContact,
		status,
		isSuccessFinished,
		deadline,
		important,
		taskType,
		id,
		onCardClick,
		classNames,
	}: Props): JSX.Element => {
		const onClick = () => {
			if (onCardClick) {
				onCardClick(id);
			}
		};

		const timeLeftLabel = (): string => {
			if (!deadline) return "";

			const diffSec = Math.round(Math.abs(Date.now() - new Date(deadline).getTime()) / 1000);
			const min = 60;
			const hour = 60 * min;
			const day = 24 * hour;

			if (diffSec < hour) return `${Math.round(diffSec / min)} мин.`;
			if (diffSec < day) return `${Math.round(diffSec / hour)} ч.`;
			return `${Math.round(diffSec / day)} дн.`;
		};

		return (
			<div
				className={cn(styles.root, classNames?.root, underlay && styles.underlay, {
					[styles.click]: !!onCardClick,
				})}
				onClick={onClick}
			>
				<div className={styles.main}>
					<div className={styles.body}>
						<div className={styles.title}>
							<div className={styles.label}>{label}</div>

							{important && <Tag classNames={{ wrapper: styles.tag }}>важно</Tag>}
						</div>

						<div className={styles.description}>{description}</div>

						<div className={styles.bottom}>
							<div className={styles.date}>{formatDate(date)}</div>

							<CreatorToResponsible
								creator={leftContact}
								responsible={rightContact}
								className={styles.contacts}
							/>
						</div>
					</div>

					{taskType && (
						<div className={styles.taskType}>
							{taskType?.link ? (
								<Link inner src={taskType?.link}>
									{taskType?.text}
								</Link>
							) : (
								<div className={styles.text}>{taskType?.text}</div>
							)}
						</div>
					)}
				</div>

				<div className={styles.status}>
					<TagTaskStatus
						status={status}
						isSuccessFinished={isSuccessFinished}
						deadline={deadline}
						classNames={{ icon: classNames?.statusIcon }}
					/>

					{deadline && !noTime.includes(status) && (
						<div className={styles.timeLeft}>{timeLeftLabel()}</div>
					)}
				</div>
			</div>
		);
	}
);
