import { useState } from "react";

export const useBlinkRow = () => {
	const [rowsToBlink, setRowsToBlink] = useState<string>("");

	const callback = (id: string) => {
		setRowsToBlink(id);

		setTimeout(() => setRowsToBlink(""), 1000);
	};

	return { rowsToBlink, callback };
};
