import styles from "./index.module.scss";
import { ReactComponent as Chevron } from "../../../../../../../../../../../assets/icons/chevron.svg";
import { GroupByDetalizationParams, PeriodDatesType } from "../../../../../../../../../../../types";
import React, { useEffect, useMemo, useState } from "react";
import { checkDatesEquality, DATETYPES, DIRECTIONS, getDefaultDate, updateDate, formatDate } from "./utils";

type Props = {
	selectedGroupBy: GroupByDetalizationParams;
	updatePeriodDates: (value: PeriodDatesType) => void;
};

export const PeriodSelector: React.FC<Props> = ({ selectedGroupBy, updatePeriodDates }) => {
	const nowDate = useMemo(() => new Date(), []);
	const [startDate, setStartDate] = useState<Date>(getDefaultDate(selectedGroupBy, DATETYPES.START));
	const [endDate, setEndDate] = useState<Date>(getDefaultDate(selectedGroupBy, DATETYPES.END));

	const forwardArrowDisabled = useMemo(() => {
		return checkDatesEquality(endDate, nowDate);
	}, [endDate, nowDate]);

	const updateDatePeriod = (direction: DIRECTIONS) => {
		setStartDate((prev) => updateDate(prev, selectedGroupBy, direction));
		setEndDate((prev) => updateDate(prev, selectedGroupBy, direction));
	};

	useEffect(() => {
		setStartDate(getDefaultDate(selectedGroupBy, DATETYPES.START));
		setEndDate(getDefaultDate(selectedGroupBy, DATETYPES.END));
	}, [selectedGroupBy]);

	useEffect(() => {
		updatePeriodDates({
			startDate: formatDate(startDate, true),
			endDate: formatDate(endDate, true),
		});
	}, [startDate, endDate, updatePeriodDates]);

	return (
		<div className={styles.container}>
			<button
				type="button"
				className={styles.arrowContainer}
				onClick={() => updateDatePeriod(DIRECTIONS.PREVIOUS)}
			>
				<Chevron />
			</button>
			<p>
				{formatDate(startDate)} - {formatDate(endDate)}
			</p>
			<button
				type="button"
				className={`${styles.arrowContainer} ${forwardArrowDisabled ? styles.disabled : ""}`}
				onClick={() => updateDatePeriod(DIRECTIONS.NEXT)}
			>
				<Chevron />
			</button>
		</div>
	);
};
