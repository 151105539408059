import { CSSProperties, useEffect, useState } from "react";
import cn from "classnames";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/iconMapClose.svg";
import { ReactComponent as MapIcon } from "../../../../../assets/icons/iconMapButton.svg";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import {
  closeDrawerInDrawer,
  openDrawerInDrawer,
  reloadDrawerInDrawer
} from "../../../../../service/redux/modules/drawer/drawer.action";
import { selectCurrentAccount, selectObjectEdit, useSelect } from "../../../../../service/redux/selectors";
import { RealEstateMap } from "../../../Map/RealEstateMap";
import { useMapAdjust } from "../../../../pages/RealEstatePage/helpers";

type Props = {
  jsStyles?: CSSProperties;
  classNames?: {
    root?: string;
  };
};

export const ButtonMap = ({classNames, jsStyles}: Props): JSX.Element => {

  const { coord, allowChangeByMap, addressLabel } = useSelect(selectObjectEdit);
  const { onMapClick, point } = useMapAdjust({ isEditOrCreate: true, allowChangeByMap });
  const { currentAccount } = useSelect(selectCurrentAccount);
  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const mapCenter = currentAccount?.company?.mapCenter?.split(",")?.map((cord) => Number(cord)) as [
    number,
    number
  ];
  const width = document.documentElement.clientWidth
  const [points, setPoints] = useState(point)
  const [isMapOpen, setIsMapOpen] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    setPoints(point)
    if (isMapOpen) {
      dispatch(reloadDrawerInDrawer({
        width: (width - 800),
        children: <div style={{width: `${width < 1600 ? width - 800 : 800}px`, height: "100%"}}>
          <RealEstateMap
            onMapClick={onMapClick}

            mark={point}
            options={{
              center: coord.lat && coord.lon ? [coord.lat as number, coord.lon as number] : mapCenter,
              zoom: mapZoom ?? 16,
              updateCenterIfChanged: true,
              withoutHandlers: true,
            }}
          /></div>
      }))
    }

  }, [allowChangeByMap, coord, point])


  const openMap = () => {
    setIsMapOpen(true)
    dispatch(openDrawerInDrawer({
      withCloseArrow: false,
      width: (width - 800),
      onClose: () => setIsMapOpen(false),
      children: <div style={{width: `${width < 1600 ? width - 800 : 800}px`, height: "100%"}}>
        <RealEstateMap
          onMapClick={onMapClick}
          mark={point}
          options={{
            center: coord.lat && coord.lon ? [coord.lat as number, coord.lon as number] : mapCenter,
            zoom: mapZoom ?? 16,
            updateCenterIfChanged: true,
            withoutHandlers: true,
          }}
        /></div>
    }))
  }

  const closeMap = () => {
    setIsMapOpen(false)
    dispatch(closeDrawerInDrawer())
  }

  return (
    <>
      {isMapOpen
        ? <div onClick={closeMap} className={cn(styles.root, classNames?.root)} style={jsStyles}>
          <CloseIcon/>
        </div>
        : <div onClick={openMap} className={cn(styles.root, classNames?.root)} style={jsStyles}>
          <MapIcon/>
        </div>
      }
    </>
  )

};
