import styles from "./index.module.scss";

export const ContactLoading = (): JSX.Element => (
	<>
		<div className={styles.loadingNav} />

		<div className={styles.loadingContent}>
			<div className={styles.loadingName} />
			<div className={styles.loadingRow} />
			<div className={styles.loadingRow} />
			<div className={styles.loadingRow} />

			<div className={styles.loadingTitle} />
			<div className={styles.loadingBlock} />

			<div className={styles.loadingTitle} />
			<div className={styles.loadingBlock} />
		</div>
	</>
);
