import { useDispatch } from "react-redux";
import { UseFormGetValues, UseFormSetValue, Control, useWatch } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { AxiosResponse } from "axios";

import { QuestionnaireValue, toastSuccess } from "../../../index";
import { TaskCard } from "./components";
import { Boolean } from "../Boolean";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";
import { useTask } from "./helpers";
import { useCreateTaskModal } from "../../../../../utils/hooks";
import { KindType, Task as TaskType } from "../../../../../types";

import { ReactComponent as Checkmark } from "../../../../../assets/icons/checkmark-pixeled.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";

type Props = {
	handleOnChange: (value: QuestionnaireValue) => void;
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	control: Control<any>;
	nameGroup: string[];
	refetch: () => void;
	disabled?: boolean;
	options?: { label: string; value: number }[];
};
export const QuizTask = ({
	nameGroup,
	getValues,
	setValue,
	control,
	handleOnChange,
	refetch,
	disabled,
	options,
}: Props): JSX.Element => {
	const dispatch = useDispatch();

	const [radioName, inputName] = nameGroup;

	const deleteTask = () => {
		setValue(inputName, "");
	};

	const change = (value: QuestionnaireValue) => {
		if (value === 0) {
			deleteTask();
		}

		if (handleOnChange) {
			handleOnChange(value);
		}
	};

	const [radioWatcher, taskWatcher]: [number, string] = [
		useWatch({
			control,
			name: radioName,
			defaultValue: getValues(radioName),
		}),
		useWatch({
			name: inputName,
			control,
			defaultValue: getValues(inputName),
		}),
	];

	const { openCreateTaskModal } = useCreateTaskModal({
		onCreate: (data: AxiosResponse<TaskType>) => {
			dispatch(closeModal());
			toastSuccess({ text: "Задача была создана", Icon: Checkmark });
			const {
				data: { id },
			} = data;

			setValue(inputName, id);
			handleOnChange(id);
		},
		kind: KindType.APPLICATION
	});

	const { task, openTaskModal } = useTask({ taskId: taskWatcher, refetch });

	return (
		<div>
			<Boolean
				control={control}
				name={radioName}
				handleOnChange={change}
				disabled={disabled}
				options={options}
			/>

			<br />

			{radioWatcher === 1 &&
				((task && (
					<TaskCard
						task={task}
						onCardClick={openTaskModal}
						refetch={refetch}
						onDeleteTask={deleteTask}
						disabled={disabled}
					/>
				)) || (
					<Button onClick={openCreateTaskModal} variant="text" disabled={disabled} Icon={PlusIcon}>
							Создать задачу
						</Button>
				))}
		</div>
	);
};
