import { Placemark, withYMaps } from "react-yandex-maps";

import { iconOrangeTemplate, iconTemplate } from "../map-utils/utils";
import { getPriceLabel } from "../../../../utils/clientObjects";
import { PointProps, PointType } from "../../../../types";

import "./Point.scss";

export const Point = withYMaps(
	({ onPointClick, point, selectPointWithObjects, priceType, ymaps }: PointProps) => {
		const { lat, lon, objects } = point;
		const { price, pricePerUnit } = objects[0];
		console.log(priceType)
		const officeLabel = " "
		const currentPrice = priceType === "price" ? getPriceLabel({ price }) : getPriceLabel({ pricePerUnit });
		const iconContent =
			// eslint-disable-next-line no-nested-ternary
			objects.length === 1
				? (priceType === "office" ? officeLabel : `${currentPrice} р` )
				: (priceType === "office" ? `${objects.length} офисов` : `(${objects.length}) от ${currentPrice} р`);
		const handleObjectData = (clickedPoint: PointType) => {
			if (typeof onPointClick === "function") {
				try {
					onPointClick(clickedPoint);
				} catch (err) {
					console.log(err);
				}
			}
		};

		const onClickHandler = () => {
			if (point.objects.length > 1) {
				if (typeof selectPointWithObjects === "function") {
					selectPointWithObjects(point);
				}
			} else {
				handleObjectData(point);
			}
		};

		return (
			// @ts-ignore
			<Placemark
				properties={{ iconContent }}
				geometry={[lat, lon]}
				onClick={onClickHandler}
				options={{
					iconLayout: ymaps?.templateLayoutFactory.createClass(
						objects.length === 1 ? iconTemplate : iconOrangeTemplate
					),
					iconShape: {
						type: "Rectangle",
						coordinates: [
							[-78, -34],
							[0, 0],
						],
					},
				}}
			/>
		);
	}
);
