import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/load-platforms/DEFAULT_QN";

type UseLoadPlatforms = {
	applicationId: string;
};

export const useLoadPlatforms = ({ applicationId }: UseLoadPlatforms) => {
	const loadPlatformsInfo = () => api.realEstateAd.platforms(applicationId, "type=important");

	const { data, refetch, remove, isLoading, isFetched } = useQuery(DEFAULT_QN, loadPlatformsInfo, {
		enabled: false,
	});

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
	};
};
