import { LeadTargetPinPayload, RealEstate, User } from "../../../types";
import { CardObjectMini, PinObject } from "../index";
import { useMode, useOpenRealEstate } from "./helpers";
import styles from "./index.module.scss";
import { Plug } from "../../pages/LeadCard/components/LeadInfo/components/plug/Plug";
import cn from "classnames";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-small.svg";
import { selectCurrentAccount, useSelect } from "../../../service/redux/selectors";

type Props = {
  clientObject?: RealEstate | null;
  onPinSelected: (pinPayload: LeadTargetPinPayload) => void;
  onUnpin: () => void;
  inDrawer?: boolean;
  freezed?: boolean
  responsibleUser?: User
};

export const LeadGoal = (
  {
    clientObject,
    onPinSelected,
    onUnpin,
    inDrawer,
    freezed,
    responsibleUser
  }: Props): JSX.Element => {
  const {applicationId} = clientObject || {};

  const {handleOpenRealEstate} = useOpenRealEstate({
    inDrawer,
    applicationId,
    shortId: clientObject?.shortId,
  });

  const {editMode} = useMode();
  const havePins = clientObject
  const {currentAccount} = useSelect(selectCurrentAccount);
  const isAnotherResponsible = responsibleUser ? responsibleUser.id !== currentAccount?.id : false;

  return (
    <div className={styles.root}>
      <div className={styles.headlineContainer}>
        <div className={styles.title}>Цель обращения:</div>

        {havePins
          ? <div className={cn(styles.row, styles.delete)} onClick={onUnpin}>
            {!freezed && !isAnotherResponsible && <>
              <CloseIcon/>
              <div>Открепить</div>
            </>}
          </div>
          : <PinObject onPinSelected={onPinSelected} hasObjectWithoutApplication>
            <div className={cn(styles.row, styles.add)}>
              {!freezed && !isAnotherResponsible && <>
                <PlusIcon/>
                <div className={styles.add}>Прикрепить объект</div>
              </>}
            </div>
          </PinObject>
        }
      </div>

      {(editMode || !havePins) && (
        <Plug plugText="Нет объекта"/>
      )}

      {havePins && (
        <>
          {clientObject && (
            <div className={styles.objectContainer}>
              {editMode && <div className={styles.inEdit}/>}
              <CardObjectMini
                id={clientObject.id}
                objectData={clientObject}
                onCardClick={handleOpenRealEstate}
                withPrice
                isHovered
                canOpenANewTab
                classNames={{root: styles.leadBackground}}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
