import { useMemo, useState } from "react";
import { isToday } from "date-fns";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { useSample } from "./helpers";
import { ObjectColumnItem, Stub, UnactualDelete } from "./components";
import { objectNounHelper } from "../../../../../../utils/string";

import { ReactComponent as Arrow } from "../../../../../../assets/icons/arrow.svg";
import styles from "./index.module.scss";
import { CollectionItem } from "../../../../../../types";

type Props = {
  isCompilation: boolean;
  isCompare: boolean;
  sampledApplicationId: string;
};

export const SampleSelected = ({isCompilation, isCompare, sampledApplicationId}: Props): JSX.Element => {
  const {
    sampleList,
    sampleRefetch,
    // handleRemoveObjectFromActual,
    isLoadingRemoveObjectFromActual,
    handleOnReturnToBack,
    returnBackLabelTitle,
    sampleTitle,

  } = useSample({isCompilation, isCompare, sampledApplicationId});
  const handleRemoveObjectFromActual = null;

  const [isBlindOpen, setBlindOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const unactualCount = useMemo(() => {
    const compilationArray = sampleList || [];

    const filteredAmount = compilationArray.filter((realEstate) => {
      const {
        object: {actual},
      } = realEstate;
      const notActual = actual ? actual !== "Актуальный" : false;

      const {isDeleted} = realEstate;

      if (notActual && !isDeleted) {
        return true;
      }

      return false;
    });

    return filteredAmount.length;
  }, [sampleList]);

  return (
    <div className={styles.underlayer}>
      <div
        className={styles.root}
        onMouseEnter={() => {
          setBlindOpen(true)
          setHovered(true)
        }}
        onMouseLeave={() => {
          setBlindOpen(false)
          setHovered(false)
        }}
      >
        <div className={styles.left}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>
              <div className={styles.title}>{sampleTitle}</div>

              <div className={styles.subTitle}>
                {sampleList?.length} {objectNounHelper(sampleList?.length || 0)}
              </div>
            </div>

            {isBlindOpen && handleRemoveObjectFromActual && (
              <UnactualDelete
                handleRemoveObjectFromActual={handleRemoveObjectFromActual}
                isLoadingRemoveObjectFromActual={isLoadingRemoveObjectFromActual}
                unactualCount={unactualCount}
              />
            )}
          </div>

          <div className={styles.childrenContainer}>
            <Stub isBlindOpen={isBlindOpen}/>

            {sampleList?.map((realEstate, index) => {
              const prevDate = formatDate(sampleList[ index - 1 ]?.createdAt, "month_short");
              const currDate = formatDate(realEstate.createdAt, "month_short");
              const printDate = index === 0 || prevDate !== currDate;
              const isTodayDate = isToday(new Date(realEstate.createdAt));
              const {isDeleted} = realEstate;
              const isPrevDeleted = sampleList[ index - 1 ]?.isDeleted;

              const showDeleted = isDeleted && !isPrevDeleted;
              const showDate = printDate && !isDeleted;
              const showDivider = showDeleted || showDate;
              const id = realEstate.objectApplicationId || realEstate.parserObjectId;
              const object = sampleList.find(item => item.objectApplicationId === id || item.parserObjectId === id)

              let collectionItem: CollectionItem | undefined
              if (object) {
                const {collectionStatus, clientComment} = object
                collectionItem = {
                  collectionStatus,
                  clientComment
                }
              } else {
                collectionItem = undefined
              }

              return (
                <div key={realEstate.id}>
                  {showDivider && (
                    <div className={styles.dateBlock}>
                      {!isBlindOpen && <div className={styles.divider}/>}

                      {isBlindOpen && (
                        <span>
													{showDeleted && "Удаленные"}
                          {showDate && `${isTodayDate ? "новые " : ""}от ${currDate}`}
												</span>
                      )}
                    </div>
                  )}
                  <ObjectColumnItem
                    isCompare={isCompare}
                    isCompilation={isCompilation}
                    realEstate={realEstate.object}
                    sampledApplicationId={sampledApplicationId}
                    refetch={sampleRefetch}
                    isBlindOpen={isBlindOpen}
                    disableDelete={isDeleted}
                    isHovered={hovered}
                    collectionItem={collectionItem}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.right} onClick={handleOnReturnToBack}>
          <div className={styles.button}>
            <Arrow/>
            <span>{returnBackLabelTitle}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
