import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate/load-real-estate-presentation/DEFAULT_QN";

type UseGetPresentations = {
	id?: string;
};

export const useGetPresentations = ({ id }: UseGetPresentations) => {
	const getPresentations = () => api.realEstate.getPresentations(id || "");

	const { data, refetch, isLoading } = useQuery(DEFAULT_QN, getPresentations, {
		enabled: !!id,
	});

	return {
		presentations: data?.data || [],
		isLoading,
		refetch,
	};
};
