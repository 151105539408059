import { useState } from "react";

type Return = {
	editMode: boolean;
	makeEditMode: () => void;
	makeNotEditMode: () => void;
};

type UseMode = () => Return;

export const useMode: UseMode = () => {
	const [editMode, setEditMode] = useState(false);

	const makeEditMode = () => {
		setEditMode(true);
	};

	const makeNotEditMode = () => {
		setEditMode(false);
	};

	return {
		editMode,
		makeEditMode,
		makeNotEditMode,
	};
};
