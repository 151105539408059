import { objectResidentialVariant, objectCommercialVariant } from "./constant";

const createVariant = (variants: { label: string; value: string }[]): [string[], number] => {
	const variantLabel = variants.map((variant) => variant.value);

	return [variantLabel, variantLabel.length];
};

export const wasChangeVariant = (curCat: string[], prevCat: string[]): boolean => {
	if (curCat?.length === 0 || prevCat?.length === 0) {
		return true;
	}

	const mergeCat = [...curCat, ...prevCat];

	const [firstVar, firstVarLength] = createVariant(objectResidentialVariant[0]);
	if (new Set([...firstVar, ...mergeCat]).size === firstVarLength) {
		return false;
	}

	const [secondVar, secondVarLength] = createVariant(objectResidentialVariant[1]);
	if (new Set([...secondVar, ...mergeCat]).size === secondVarLength) {
		return false;
	}

	const [thirdVar, thirdVarLength] = createVariant(objectResidentialVariant[2]);

	if (new Set([...thirdVar, ...mergeCat]).size === thirdVarLength) {
		return false;
	}
	const [fourthVar, fourthVarLength] = createVariant(objectCommercialVariant[0]);

	const setCategories = new Set([...fourthVar, ...mergeCat]);

	return !(setCategories.size === fourthVarLength);
};
