import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/load-platforms-table/DEFAULT_QN";

type UseLoadPlatformsTable = {
	applicationId: string;
	params?: string;
	enabled?: boolean;
};

export const useLoadPlatformsTable = ({
	applicationId,
	params = "",
	enabled = true,
}: UseLoadPlatformsTable) => {
	const loadPlatforms = (paramString: string) => api.realEstateAd.platforms(applicationId, paramString);

	const { data, refetch, remove, isLoading, isFetched, isFetching } = useQuery(
		[DEFAULT_QN, params],
		() => loadPlatforms(params),
		{
			enabled,
			keepPreviousData: true,
		}
	);

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
		isFetching,
	};
};
