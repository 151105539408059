import styles from "./index.module.scss";
import { CardProductForTable } from "../../../../../UI";
import { ProductTile } from "../../../../../../types/product";

export const useColumns = (refetch: () => void) => [
  {
    accessor: "product",
    render: ({row}: { row: ProductTile }) => {
      return (
        <div className={styles.root}>
          <CardProductForTable
            product={row}
            refetch={refetch}
            type="available"
          />
        </div>
      )
    },
  },
];
