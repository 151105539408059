export const padTimeWithZeroes = (value: string, type: "hours" | "minutes") => {
	if (!value || !value.length) return "00";
	if (value.length === 2) return value;

	switch (type) {
		case "hours":
			return `0${value}`;
		case "minutes":
			return `${value}0`;
		default:
			return value;
	}
};
