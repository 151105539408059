import { memo, ReactNode } from "react";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	header: string;
	text: string;
};

export const GraphTextBlock = memo(
	({ children, header, text }: Props): JSX.Element => (
		<div className={styles.root}>
			<div className={styles.children}>{children}</div>
			<div className={styles.body}>
				<h3 className={styles.header}>{header}</h3>
				<div className={styles.text}>{text}</div>
			</div>
		</div>
	)
);
