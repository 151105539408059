// Function for auto scroll to some element.
// You need to pass: root div id, element id and offset (if you want to add offset. maybe header)
export const scrollToElement = (root: string, id: string, offset?: number) => {
	const element = document.getElementById(id);
	const headerOffset = offset || 0;
	const elementPosition = element?.offsetTop;

	if (elementPosition) {
		document.getElementById(root)?.scrollTo({
			top: elementPosition - headerOffset,
			behavior: "smooth",
		});
	}
};
