import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { useSelector } from "../../../../../service/redux/useSelector";
import { paths } from "../../../../../utils/paths";

import { ReactComponent as CheckMark } from "../../../../../assets/icons/check-mark.svg";
import styles from "./index.module.scss";

export const CreatingNewCompany = (): JSX.Element => {
	const { companyName } = useSelector((state) => state.regSteps);
	const { push } = useHistory();

	useEffect(() => {
		setTimeout(() => {
			push(paths.dashboardPath);
		}, 3000);
	}, []);

	return (
		<div className={styles.root}>
			<CheckMark />
			<h2>{`Погоди, мы создаем компанию «${companyName || ""}»`}</h2>
			<p>Через несколько секунд вы будете перенаправленны в систему.</p>

			<Button onClick={() => push(paths.dashboardPath)}>Продолжить</Button>
		</div>
	);
};
