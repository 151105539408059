import { useMutation } from "react-query";
import { RefuseLeadPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/lead/refuse-lead/DEFAULT_MN";

type UseRefuseLead = {
	onSuccess?: (data: any) => void;
	onError?: () => void;
};

export const useRefuseLead = ({ onSuccess, onError }: UseRefuseLead) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: ({ leadId, payload }: { leadId: string; payload?: RefuseLeadPayload }) =>
			api.leads.refuse(leadId, payload),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
