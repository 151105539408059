import { Button, Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { ButtonCloseBlack, toastError } from "../index";
import {
	closeGallerySlider,
	openGallerySlider,
} from "../../../service/redux/modules/gallery-slider/gallery-slider.action";
import { useSelect, selectGallerySlider } from "../../../service/redux/selectors";
import { useObjectRotatePhoto } from "../../../service/api/mutations";

import { ReactComponent as RotateRightIcon } from "../../../assets/icons/rotate.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import styles from "./index.module.scss";

export const GallerySlider = (): JSX.Element => {
	const dispatch = useDispatch();

	const { isOpen, photos, ids, clickedPhoto, canUpdatePhotos } = useSelect(selectGallerySlider);

	const [isSliderOpen, setIsSliderOpen] = useState(isOpen);
	const [currentPhoto, setCurrentPhoto] = useState(clickedPhoto || photos[0]);
	const [currentIndex, setCurrentIndex] = useState(photos.findIndex((photo) => photo === clickedPhoto) || 0);

	const currentId = ids?.[currentIndex];

	const { mutate: rotatePhoto, isLoading } = useObjectRotatePhoto({
		onSuccess: (res) => {
			dispatch(
				openGallerySlider({
					photos: [
						...photos.slice(0, currentIndex),
						res.data.photo1280Watermark || res.data.photoOriginal,
						...photos.slice(currentIndex + 1),
					],
					clickedPhoto: res.data.photo1280Watermark || res.data.photoOriginal,
					ids,
					canUpdatePhotos,
				})
			);

			setCurrentPhoto(res.data.photo1280Watermark || res.data.photoOriginal);
		},
		onError: () => toastError({ text: "При повороте фото произошла ошибка" }),
	});
	const rotateLeft = () => rotatePhoto({ photoId: currentId ?? "", rotation: "left" });
	const rotateRight = () => rotatePhoto({ photoId: currentId ?? "", rotation: "right" });

	useEffect(() => {
		if (!isOpen) {
			setTimeout(() => setIsSliderOpen(false), 500);
		} else {
			setCurrentPhoto(clickedPhoto || photos[0]);
			setCurrentIndex(photos.findIndex((photo) => photo === clickedPhoto) || 0);
			setIsSliderOpen(true);
		}
	}, [isOpen]);

	const handleCloseButton = () => {
		dispatch(closeGallerySlider());
	};

	const handleArrowClick = (step: "next" | "prev") => {
		const len = photos.length;

		const newPhoto =
			step === "prev" ? photos[(currentIndex + len - 1) % len] : photos[(currentIndex + 1) % len];

		setCurrentPhoto(newPhoto);
		setCurrentIndex(photos.findIndex((photo) => photo === newPhoto));
	};

	return createPortal(
		<div>
			{isSliderOpen && (
				<div
					className={cn(styles.root, "animate__animated", "animate__faster", {
						animate__fadeIn: isOpen,
						animate__fadeOut: !isOpen,
					})}
				>
					<div className={styles.photoContainer}>
						<div className={styles.photo}>
							<img src={currentPhoto} alt="Фото объекта" />

							{isLoading && <Loader className={styles.loader} />}
						</div>

						<div className={styles.toolbar}>
							<div className={styles.numberOf}>{`${currentIndex + 1}/${photos.length}`}</div>

							{canUpdatePhotos && (
								<div className={styles.toolbarButtons}>
									<Button
										Icon={() => <RotateRightIcon style={{ transform: "scaleX(-1)" }} />}
										onClick={rotateLeft}
										disabled={isLoading}
									>
										Повернуть влево
									</Button>

									<Button Icon={() => <RotateRightIcon />} onClick={rotateRight} disabled={isLoading}>
										Повернуть вправо
									</Button>
								</div>
							)}
						</div>

						{!isLoading && (
							<>
								<ArrowIcon className={styles.leftIcon} onClick={() => handleArrowClick("prev")} />
								<ArrowIcon className={styles.rightIcon} onClick={() => handleArrowClick("next")} />

								<ButtonCloseBlack onCloseClick={handleCloseButton} classNames={{ root: styles.button }} />
							</>
						)}
					</div>
				</div>
			)}
		</div>,
		document.body
	);
};
