type Props = {
	compilation?: boolean;
	comparedApplicationId?: string;
	applicationId?: string;
};

type Return = {
	isSample: boolean;
	isCompilation: boolean;
	isCompare: boolean;
};

type UseSample = (props: Props) => Return;

export const useSample: UseSample = ({ compilation, comparedApplicationId }) => {
	const isSample = !!(compilation || (comparedApplicationId && comparedApplicationId?.length > 0));
	const isCompilation = isSample && !!compilation;
	const isCompare = isSample && !!(comparedApplicationId && comparedApplicationId?.length > 0);

	return {
		isSample,
		isCompilation,
		isCompare,
	};
};
