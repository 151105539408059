import { useState } from "react";
import { useDispatch } from "react-redux";

import { closeModal } from "../../../../../../service/redux/modules/modal/modal.action";

type Return = {
	responsiblesIds: string[];
	onDeleteResponsible: (id: string) => void;
	onChangeResponsible: (responsibleId: string[]) => void;
};

type UseResponsible = () => Return;

export const useResponsible: UseResponsible = () => {
	const dispatch = useDispatch();

	const [responsiblesIds, setResponsiblesIds] = useState<string[]>([]);

	const onDeleteResponsible = () => {
		setResponsiblesIds([]);
	};

	const onChangeResponsible = (responsibleId: string[]) => {
		dispatch(closeModal());

		setResponsiblesIds(responsibleId);
	};

	return {
		responsiblesIds,
		onDeleteResponsible,
		onChangeResponsible,
	};
};
