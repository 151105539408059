import { KindType, RealEstate } from "../../../../../../../../types";
import { CardObject, CardObjectProps } from "../../../../../../../UI";
import { useOpenDrawerRealEstate } from "../../../../../../../../utils/hooks";
import { useOpenRealEstate } from "../../../../../../../../utils/objects";
import { usePopupMenu } from "./helpers";

type Props = {
  object: RealEstate;
  isParser: boolean;
  classNames?: {
    root?: string
  }
  refetchList?: () => void
} & Omit<CardObjectProps, "clientObject" | "id">;

export const CardObjectWithPopupMenu = ({object, isParser, refetchList, classNames, ...otherProps}: Props) => {
  const id = isParser ? object.id : (object.shortType.kind === KindType.APPLICATION ? object.applicationId : object.preApplicationId);
  const locationFrom = isParser ? "parser-list" : "regular-list";
  const {menu} = usePopupMenu({object, isParser});

  const openObject = useOpenRealEstate({
    locationFrom,
    options: {inNewTab: true},
  });

  const {handleOpenDrawerRealEstate: openParserDrawer} = useOpenDrawerRealEstate({
    isParser,
    kind: isParser ? KindType.APPLICATION : object.shortType.kind,
    refetchList
  });

  const onCardClick = () => {
      openParserDrawer(id)
  };

  const onAuxClick = () => openObject(object);

  return (
    <CardObject
      id={id}
      clientObject={object}
      cardBackground="white"
      onCardClick={onCardClick}
      onAuxClick={onAuxClick}
      popupMenu={menu}
      options={{showApplication: true}}
      withFeedAds={!isParser}
      {...otherProps}
      variant="standard"
      classNames={{
        root: classNames?.root
      }}
    />
  );
};
