import { useDispatch } from "react-redux";
import { Contact as ContactType } from "../../../../../../types";
import { usePinContactToShowView } from "../../../../../../service/api/mutations";
import { closeModal, deactivateLoading } from "../../../../../../service/redux/modules/modal/modal.action";

type UseOnPinContact = {
  showviewId: string;
  refetch: () => void;
  isViews: boolean
  isAgent: boolean
};

export const useOnPinContact = (
  {
    showviewId,
    refetch,
    isViews,
    isAgent
  }: UseOnPinContact) => {
  const dispatch = useDispatch();

  const {mutateAsync} = usePinContactToShowView({
    onSuccess: () => {
      refetch();
      dispatch(deactivateLoading());
      dispatch(closeModal());
    },
    isAgent,
    isViews
  });

  const onPinContact = async (contact: ContactType | null | undefined, onPinSuccess?: () => void) => {

    await mutateAsync({id: showviewId, contactId: contact?.id ?? ""});

    if (onPinSuccess) {
      onPinSuccess();
    }
  };

  return {onPinContact};
};
