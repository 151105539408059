import { Conditions, DepartmentFilters } from "../../../../types";

export const getDepartmentFilters = ({ searchPayload }: { searchPayload: DepartmentFilters }): Conditions => {
	const searchConditions: Conditions = [];
	Object.entries(searchPayload).forEach((i: [string, any]) => {
		const name = i[0];
		const value = i[1];

		if (name === "ids") {
			searchConditions.push({
				field: "id",
				operator: "in",
				value,
			});
		}
	});

	return searchConditions;
};
