import { ShowsViewsStatus } from "../../../../../../types";
import { capitalizeFirstLetter } from "../../../../../../utils/formatters";

type Return = {
  title: string,
  label: string,
  labelColor: string,
  tooltipText: string
}

export const getLabels = (
  status: ShowsViewsStatus,
  isViews: boolean,
  isConfirmed?: boolean,
  actIsSigned?: boolean,
  plannedDate?: Date,
  isCreatedByOtherAgent?: boolean
): Return => {
  const expiredDate = new Date >= (plannedDate ?? new Date())
  const differentText = isViews ? "просмотр" : "показ"
  const success = actIsSigned ? {
    label: "Акт подписан",
    labelColor: "#21BC5F",
    tooltipText: "Клиент подписал акт об оказании услуг"
  } : {
    label: "Клиент не подписал акт",
    labelColor: "#FF575F",
    tooltipText: "Клиенту отправлен акт об оказании услуг на Публичной странице"
  }
  const waiting = isConfirmed ? {
    label: "Подтверждено",
    labelColor: "#21BC5F",
    tooltipText: "Клиент подтвердил свое присутствие"
  } : {
    label: "Ожидает подтверждения",
    labelColor: "#FF575F",
    tooltipText: "Клиенту отправлена ссылка на показ через Публичную страницу, ожидайте его подтверждения"
  }

  if (expiredDate && status === "waiting") {
    return {title: `${capitalizeFirstLetter(differentText)} должен был состояться`,
      label: "",
      labelColor: "",
      tooltipText: ""
    }
  }

  if (status === "waiting") {
    return {
      title: "Назначен",
      label: waiting.label,
      labelColor: waiting.labelColor,
      tooltipText: waiting.tooltipText
    }
  }

  if (isCreatedByOtherAgent) {
    return {
      title: "Назначен",
      label: "Другим агентом",
      labelColor: "#21BC5F",
      tooltipText: "Риэлтор СДЕЛКИ создал просмотр вашего объекта"
    }
  }

  if (status === "finished") {
    return {
      title: `Проведенный ${differentText}`,
      label: success.label,
      labelColor: success.labelColor,
      tooltipText: success.tooltipText
    }
  }

  if (status === "canceled") {
    return {
      title: `${capitalizeFirstLetter(differentText)} отменен`,
      label: "",
      labelColor: "",
      tooltipText: ""
    }
  }

  return {
    title:`Успешный ${differentText}`,
    labelColor: "",
    label: "",
    tooltipText: ""
  }
}
