import { UseFormSetError, UseFormClearErrors } from "react-hook-form";
import { RealEstate, CreateObjectToApplicationPayload } from "../../../../../../../types";

import { toastSuccess } from "../../../../../index";
import { useCreateObjectToApplication } from "../../../../../../../service/api/mutations";

type Props = {
	applicationId: string;
	setError: UseFormSetError<any>;
	clearErrors: UseFormClearErrors<any>;
	onObjectCreate?: (object: RealEstate) => void;
};

type Return = {
	onSubmit: (data: any) => void;
	isCreateLoading: boolean;
};

type UseOnSubmit = (props: Props) => Return;

export const useOnSubmit: UseOnSubmit = ({ applicationId, onObjectCreate }) => {
	const { mutate, isLoading } = useCreateObjectToApplication({
		disabledPush: !!onObjectCreate,
		onSuccess: (clientObject: RealEstate) => {
			toastSuccess({ text: "Объект недвижимости создан" });

			if (onObjectCreate) {
				onObjectCreate(clientObject);
			}
		},
	});

	const onSubmit = (data: any) => {
		const dataProp = data;

		delete dataProp.objectSubType;

		delete dataProp.category;
		delete dataProp.catalog;
		delete dataProp.objectSubType;
		delete dataProp.dealType;
		delete dataProp.actual;
		delete dataProp.responsibles;
		delete dataProp.addressId;
		delete dataProp.type;

		const payload: CreateObjectToApplicationPayload = {
			applicationId,
			data: {
				...dataProp,
			},
		};

		mutate(payload);
	};

	return {
		onSubmit,
		isCreateLoading: isLoading,
	};
};
