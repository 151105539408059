import { Clusterer } from "react-yandex-maps";

import { PointType } from "../Map";
import { PointWithBalloon } from "./components";
import useYMaps from "../map-utils/useYMaps";
import { clusterShape, createIconsClusterTemplate } from "../map-utils/utils";
// import { ObjectData } from 'types';

interface Props {
	point: PointType;
	getContactPhone?: (objectId: string) => Promise<string>;
	selectPointWithObjects?: (point: PointType) => void;
	clickOnTitle?: (id?: string) => void;
	priceType: string;
	onUnselectPoint: () => void;
}

/*
 * Компонент отображает точки недвижимости.
 */
export const SelectedPoint = ({
	point,
	getContactPhone,
	selectPointWithObjects,
	clickOnTitle,
	priceType,
	onUnselectPoint,
}: Props): JSX.Element => {
	const { ymaps } = useYMaps();

	return (
		//  @ts-ignore
		<Clusterer
			options={{
				clusterIconLayout: ymaps?.templateLayoutFactory.createClass(
					createIconsClusterTemplate("properties.geoObjects.length")
				),
				iconShape: clusterShape,
			}}
		>
			{/* //  @ts-ignore */}
			<PointWithBalloon
				getContactPhone={getContactPhone}
				selectPointWithObjects={selectPointWithObjects}
				clickOnTitle={clickOnTitle}
				point={point}
				priceType={priceType}
				onUnselectPoint={onUnselectPoint}
			/>
		</Clusterer>
	);
};
