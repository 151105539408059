import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { useOpenTaskModal } from "../../../../../../../../../utils/hooks";
import { useTasksAttentionContext } from "../../../../../../../../../service/context/TasksAttention";

import styles from "./index.module.scss";
import { CardTask } from "../../../../../../../../UI/Card/card-task/CardTask";

export const TasksAttentionList = (): JSX.Element => {
  const {data, refetch, isFetching} = useTasksAttentionContext();

  const {handleOpenTaskModal} = useOpenTaskModal({onUpdatedTask: refetch});

  if (isFetching) return <Loader height={500}/>;

  return (
    <div className={styles.root}>
      {data?.map(
        (task) => (
          <div className={styles.card}
               onClick={() => handleOpenTaskModal(task.id)}>
            <CardTask
              task={task}
              mini
              inTable
            />
          </div>
        )
      )}
    </div>
  );
};
