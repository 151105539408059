import { ReactNode } from "react";
import { H2 } from "@sdelka_crm/sdelka-crm-component-library";
import { UserAccountData } from "../../../../types";

import { UserListCardsLocal } from "./components/UserListCards/UserListCardsLocal";

type Props = {
  title: string;
  data: UserAccountData[];
  onDelete: (id: string) => void;
  children?: ReactNode;
  classNames?: string
};

export const UserListLocal = ({children, data, onDelete, title, classNames}: Props): JSX.Element => {


  return (
    <>
      <H2 button={children}>{title}</H2>

      <UserListCardsLocal data={data} onClickClose={(id: string) => onDelete(id)} classNames={classNames}/>
    </>
  );
};
