

import styles from "./index.module.scss";
import { prepareShortUserOutput } from "../../../../../utils/transformation";
import { openInNewWindow } from "../../../../../utils/openInNewWindow";

type Props = {
  name: string;
  lastName: string;
  secondName?: string | undefined;
  href: string;
};

export const UserBlock = ({ name, lastName, secondName, href }: Props): JSX.Element => {
  const onClick = () => {
    openInNewWindow(href);
  };

  const FIO = prepareShortUserOutput({ lastName , name, secondName });

  return (
    <div onClick={onClick} className={styles.root}>
      {FIO}
    </div>
  );
};
