import { H3 } from "@sdelka_crm/sdelka-crm-component-library";

import { Textarea } from "../../../../../../UI";
import { useDescriptionForm, useUpdateDescription } from "./helpers";

import styles from "./index.module.scss";

type Props = {
	objectDescription?: string;
	applicationId: string;
};

export const Description = ({ objectDescription = "", applicationId }: Props): JSX.Element => {
	const { register } = useDescriptionForm({ objectDescription });
	const { handleSaveComment } = useUpdateDescription({ applicationId });

	return (
		<>
			<H3 classNames={{ root: styles.title }}>Комментарий в системе</H3>

			<Textarea
				name="objectDescription"
				rows={4}
				placeholder="Комментарий отсутствует"
				register={register}
				className={styles.comment}
				onChange={handleSaveComment}
			/>
		</>
	);
};
