import { AxiosError, AxiosResponse } from "axios";
import { CartResponse, Compilation } from "../../../../types";
import { api } from "../../api";
import { useQuery } from "react-query";

const DEFAULT_QN = "sdelka/cart/use-load-cart/DEFAULT_QN";
export const CART_PAGE_LOAD_COMP_QN =
  "sdelka/cart/use-load-cart/CART_PAGE_LOAD_COMP_QN";


export const useLoadCart = () => {
  const loadCart = () => api.cart.list();

  const { data, isLoading, refetch, remove, error, isFetching, isFetched } = useQuery(
    "cart",
    loadCart,
    {

    }
  );

  return {
    cart: data?.data,
    isLoading,
    isFetching,
    refetch,
    remove,
    error: error as AxiosError,
    isFetched,
  };
};
