import { Client } from "../../Client";
import {
	Company,
	CompanyAcceptAccession,
	CompanyAccession,
	CompanyPublicData,
	CompanyUpdateAccession,
} from "../../../../types";

export const CompaniesService = {
	get: () => Client.get<Company>("/company"),
	getCompanyPublic: (id: string) => Client.get<CompanyPublicData>(`company/publicData/${id}`),
	getAccessions: () => Client.get<CompanyAccession[]>("/company/accession"),
	updateAccession: (id: string, data: CompanyUpdateAccession) => Client.put(`/company/accession/${id}`, data),
	confirmAccession: (id: string, data: CompanyAcceptAccession) =>
		Client.post(`/company/accession/${id}`, data),
};
