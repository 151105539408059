import { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";

import { api } from "../../index";
import { DEFAULT_TRAFFIC } from "../../helpers";
import { ApplicationsFilter } from "../../../../types";

const DEFAULT_QN = "sdelka/application/load-applications/DEFAULT_QN";

type UseLoadApplications = {
	enabled?: boolean;
	defaultFilter?: ApplicationsFilter;
	keepPreviousData?: boolean;
	defaultTake?: number;
};

export const useLoadApplications = ({
	defaultTake = 30,
	enabled,
	defaultFilter = DEFAULT_TRAFFIC,
	keepPreviousData = false,
}: UseLoadApplications) => {
	const [filter, setFilter] = useState<ApplicationsFilter>(defaultFilter);
	const [page, setPage] = useState(1);
	const [take, setTake] = useState(defaultTake);
	const skip = useMemo(() => (page - 1) * take, [page]);

	const loadApplications = () =>
		api.application.list({
			take,
			skip,
			search: filter,
			value: "application",
		});

	const resetPage = () => {
		if (page !== 1) {
			setPage(1);
		}
	};

	useEffect(() => {
		resetPage();
	}, [filter.stage]);

	useEffect(() => {
		setFilter({
			...filter,
			stage: [],
		});
		resetPage();
	}, [filter.typeId]);

	const { data, isLoading, refetch, isFetching, isError } = useQuery(
		[DEFAULT_QN, take, skip, filter],
		loadApplications,
		{ enabled, keepPreviousData }
	);

	return {
		list: data?.data?.data || [],
		isLoading,
		isError,
		total: data?.data?.total || 0,
		totalCounts: data?.data.totalCounts,
		page: data?.data?.page || 1,
		setPage,
		refetch,
		take,
		setTake,
		filter,
		setFilter,
		isFetching,
	};
};
