import { Collapse, formatDate, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";

import { useCopyText } from "../../../../../../../../utils/hooks";
import { formatPhone } from "../../../../../../../../utils/formatters";
import { Contact, ContactTypeMap, Phone } from "../../../../../../../../types";

import styles from "./index.module.scss";
import cn from "classnames";

type Props = {
  contact: Contact;
};

export const ShowMode = ({contact}: Props): JSX.Element => {
  const {onCopy} = useCopyText({});

  const copyEmail = () => onCopy(contact?.email || "");

  const phoneLabel = (phone: Phone): string => (phone?.type === "main" ? "Основной" : "Доп.");

  const showOtherType = contact?.type === "other";

  return (
    <>
      <InfoRow label="Псевдоним">{contact?.pseudonym || "Не указан"}</InfoRow>
      {contact.phones?.map((phone) => (
        <InfoRow label={phoneLabel(phone)} key={phone.id}>
          {formatPhone(phone.phone)}
        </InfoRow>
      ))}
      <InfoRow label="Тип">{ContactTypeMap[ contact?.type ] || "Не указан"}</InfoRow>

      {showOtherType && <InfoRow label=" ">{contact?.otherType || "Не указан"}</InfoRow>}
      <InfoRow label="Теги">
				<span>
					{ "Не указаны"}
				</span>
      </InfoRow>
      <InfoRow label="E-mail">
				<span onClick={contact?.email ? copyEmail : () => {}} className={cn({[styles.copy]: contact?.email })}>
					{contact?.email || "Не указан"}
				</span>
      </InfoRow>
      <Collapse showLabel="Конфиденциальная информация">
        <InfoRow label="Серия паспорта">
          {contact?.passportInfo?.series || "Не указан"}
        </InfoRow>
        <InfoRow label="Номер паспорта">
          {contact?.passportInfo?.passportNumber || "Не указан"}
        </InfoRow>
        <InfoRow label="Дата выдачи">
          {contact?.passportInfo?.dateOfIssue ? formatDate(contact?.passportInfo.dateOfIssue) : "Не указана"}
        </InfoRow>
        <InfoRow label="Кем выдан">
          {contact?.passportInfo?.issuedBy || "Не указан"}
        </InfoRow>
        <InfoRow label="Код подразделения">
          {contact?.passportInfo?.issuedByCode || "Не указан"}
        </InfoRow>
        <InfoRow label="Адрес регистрации">
          {contact?.passportInfo?.registerAddress || "Не указан"}
        </InfoRow>
        <InfoRow label="Адрес проживания">
          {contact?.addressLabel || "Не указан"}
        </InfoRow>
        <InfoRow label="Дата рождения">
          {contact?.dob ? formatDate(contact?.dob) : "Не указана"}
        </InfoRow>
      </Collapse>
    </>
  );
};
