import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { Textarea } from "../../../../UI/Form/Textarea";
import { useForm } from "react-hook-form";

import styles from "./index.module.scss";
import { Lead } from "types";

type FormType = Pick<Lead, "invalidReason" | "invalidReasonComment">;


export const Comment = (): JSX.Element => {
	const { register } = useForm<FormType>();

	return (
		<div className={styles.container}>
			<div className={styles.grid}>
				<div className={styles.contentLeft}>
					<h3>Отправь свой отзыв о СДЕЛКЕ и получи скидку 10% на оплату следующего месяца</h3>
					<p>
						Отправь ссылку-приглашение коллеге, частному агенту, руководителю агентства недвижимости. Отправь ссылку-приглашение коллеге, частному агенту, руководителю агентства недвижимости. Отправь ссылку-приглашение коллеге, частному агенту, руководителю агентства недвижимости. Отправь ссылку-приглашение коллеге, частному агенту, руководителю агентства недвижимости. Отправь ссылку-приглашение коллеге, час
					</p>

					<div className={styles.proposal}>
                        <span className={styles.titleProposal}>Осеннее предложение</span>
                    </div>
				</div>

				<div className={styles.contentRight}>
					<div className={styles.reasonCommentContainer}>
						<h3>Комментарий</h3>

						<Textarea name="invalidReasonComment" register={register} rows={6} />
					</div>

					<div className={styles.buttonContainer}>
						<Button
						// Icon={PlusIcon}
						// onClick={handleOpenCreateModal}
						// classNames={{
						// 	root: cn(styles.button, {
						// 		[styles.buttonShort]: isAccountsOpen,
						// 	}),
						// }}
						>
							<p className={styles.buttonText}>Отправить</p>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
