import { useMemo } from "react";

import { СountLabel } from "./components";
import { FindContactByPhoneResponseRelations } from "../../../../../../../../../../../../../../../../types";

type Props = FindContactByPhoneResponseRelations;

export const useAdditionalText = ({ leads, applications, deals }: Props) => {
	const haveRelations = leads || applications || deals;

	const NO_RELATIONS_TEXT = "Для использования номера зайдите в контакт и отправьте его в Архив.";

	const additionalText = useMemo(() => {
		if (haveRelations) {
			return (
				<div>
					Имеются действующие <СountLabel label="Лиды" count={leads} needComma={!!applications} />
					<СountLabel label="Заявки" count={applications} needComma={!!deals} />
					<СountLabel label="Сделки" count={deals} />. Использование номера невозможно.
				</div>
			);
		}

		return NO_RELATIONS_TEXT;
	}, [leads, applications, deals]);

	return {
		additionalText,
	};
};
