import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/compilation/get-compilation-objects/DEFAULT_MN";

type UseGetCompilationObjects = {applicationId: string}

export const useGetCompilationObjects = ({applicationId}: UseGetCompilationObjects) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: () =>
      api.compilations.getCollectionObjects(applicationId),
  });

  return {
    mutate: mutate.mutate,
    mutateAsync: mutate.mutateAsync,
    data: mutate.data?.data.data,
    isLoading: mutate.isLoading
  }
}