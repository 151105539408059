import cn from "classnames";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { NameWithPhone } from "../../../../UI";
import { formatPhone } from "../../../../../utils/formatters";
import { Call, UserAccountData } from "../../../../../types";

import { ReactComponent as CallsIcon } from "../../../../../assets/icons/calls-phone.svg";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/bold-arrow.svg";
import styles from "./index.module.scss";

type Props = {
	call: Call;
	user: UserAccountData;
};

export const CallHistory = ({ call, user }: Props): JSX.Element => {
	const IS_CALL_OUT = call.type === "out";
	const ARROW_CLASS = call?.status === "success" ? "" : styles.red;

	return (
		<div className={styles.root}>
			<div className={styles.type}>
				<span className={ARROW_CLASS}>
					<CallsIcon />
				</span>

				<ArrowIcon
					className={cn(styles.arrow, ARROW_CLASS, {
						[styles.arrowOut]: IS_CALL_OUT,
					})}
				/>
			</div>

			<div>
				<div className={styles.direction}>{(IS_CALL_OUT && "Исходящий") || "Входящий"}</div>
				<div className={styles.time}>
					{`${formatDate(call.start ?? "")} ${formatDate(call.start ?? "", "time")}`}
				</div>
			</div>

			<NameWithPhone
				name={user.name}
				secondName={user.secondName}
				lastName={user.lastName}
				phone={formatPhone(user.phone)}
				withAvatar={{
					imgSrc: user.avatar || "",
				}}
			/>
		</div>
	);
};
