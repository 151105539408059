import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { FixedNav, ObjectsFilterFromConfig } from "../../../../../UI";
import { useLoadFilters } from "../../../../../../service/api/queries";
import { useDrawerForm, useDrawerFormActions, useNavbar } from "./helpers";
import { RealEstateFilter, User, BusinessProcess, RealEstateListTab } from "../../../../../../types";

import { ReactComponent as Spinner } from "../../../../../../assets/icons/spinner.svg";
import styles from "./index.module.scss";

const rootId = "object-filters-modal";

type Props = {
	prepareSubmitForm: (values: RealEstateFilter) => void;
	filter: RealEstateFilter;
	users: User[];
	tab: RealEstateListTab;
	isParser?: boolean
};

// Autogenerated filters (much more filters) for object category.
export const ObjectListFiltersModal = (
	{
		prepareSubmitForm,
		filter,
		users,
		tab,
		isParser
	}: Props): JSX.Element => {
	const {
		objectsList,
		navigation,
		isFetching,
		setFilter: setCountFilter,
		filter: countFilter,
	} = useLoadFilters({
		defaultFilter: {
			category: filter?.category || [],
			type: {
				value: filter.type?.value as BusinessProcess,
			},
		},
		rootId,
		tab,
	});

	const { control, register, setValue, handleSubmit, getValues, reset } = useDrawerForm({ filter });

	const { activeNav, setActiveNav } = useNavbar({
		objectsList,
		navigation,
		rootId,
	});

	const { onSubmit, resetForm, setCategory, onChangeTypeValue } = useDrawerFormActions({
		setCountFilter,
		getValues,
		prepareSubmitForm,
		countFilter,
		reset,
	});

	return (
		<div className={styles.root}>
			{navigation && (
				<FixedNav navigation={navigation} activeNav={{ activeLabel: activeNav, setActive: setActiveNav }} />
			)}
			<form id={rootId} className={styles.content}>
				{isFetching ? (
					<div id={rootId} className={styles.loadingWrapper}>
						<Spinner className={styles.spinner} />
					</div>
				) : (
					<>
						<ObjectsFilterFromConfig
							filterBlocks={objectsList}
							control={control}
							setValue={setValue}
							getValues={getValues}
							register={register}
							setCategory={setCategory}
							onChangeTypeValue={onChangeTypeValue}
							users={users}
							isParser={isParser}
						/>
						<div className={styles.actions}>
							<Button fullWidth onClick={handleSubmit(onSubmit)}>
								Показать
							</Button>
							<Button variant="text" color="red" onClick={resetForm} classNames={{ root: styles.cancelBtn }}>
								Сбросить
							</Button>
						</div>
					</>
				)}
			</form>
		</div>
	);
};
