import { UseMutateFunction, useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { RealEstate, CreateObjectToApplicationPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/real-estate/application/create-real-estate/DEFAULT_MN";

type Props = {
	onSuccess?: (data) => void;
	disabledPush?: boolean;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<RealEstate>, unknown, CreateObjectToApplicationPayload, unknown>;
	isLoading: boolean;
};

type UseCreateObjectToApplication = (props: Props) => Return;

export const useCreateObjectToApplication: UseCreateObjectToApplication = ({ onSuccess }) => {
	const onCreateSuccess = (response: AxiosResponse<{ applicationId: string }>) => {
		if (onSuccess) {
			onSuccess(response.data);
		}
	};

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (payload: CreateObjectToApplicationPayload) =>
			api.applicationRealEstate.createObjectToApplication(payload),
		onSuccess: (response) => onCreateSuccess(response),
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
