import { CollectionObject } from "../../../../../../../../../types";

import {
	useLoadCompilation,
	COMPILATION_PAGE_LOAD_COMP_QN,
} from "../../../../../../../../../service/api/queries";
import { useDeleteUnactual } from "../../../../../../../../../service/api/mutations";

type Props = {
	sampledApplicationId: string;
	isCompilation: boolean;
};

type Return = {
	compilationObjects: CollectionObject[];
	refetchCompilation: () => void;
	isLoadingCompilationRemoveObjectFromActual: boolean;
	handleCompilationRemoveObjectFromActual: () => void;
	returnCompilationLabelTitle: string;
	compilationTitle: string;
};

type UseCompilation = (props: Props) => Return;

export const useCompilation: UseCompilation = ({ sampledApplicationId, isCompilation }) => {
	const { compilation, refetch: refetchCompilation } = useLoadCompilation({
		applicationId: sampledApplicationId,
		queryName: COMPILATION_PAGE_LOAD_COMP_QN,
		enabled: isCompilation,
	});

	const compilationObjects = compilation?.collectionObjects || [];

	const { mutate, isLoading: isLoadingCompilationRemoveObjectFromActual } = useDeleteUnactual({
		onSuccess: refetchCompilation,
	});

	const handleCompilationRemoveObjectFromActual = () => mutate(sampledApplicationId);

	const returnCompilationLabelTitle = "Вернуться к заявке";
	const compilationTitle = "Подборка";

	return {
		compilationObjects,
		refetchCompilation,
		handleCompilationRemoveObjectFromActual,
		isLoadingCompilationRemoveObjectFromActual,
		returnCompilationLabelTitle,
		compilationTitle,
	};
};
