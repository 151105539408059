import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { PinContact } from "../../index";
import { Contact, WhoIsClient } from "../../../../types";

import { UserListLocal } from "../UserList";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";

import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";

type Props = {
	type: WhoIsClient;
	data: any[];
	onDelete: (id: string) => void;
	onChange: (contact: Contact) => void;
};

export const ContactsLocal = ({ type, data, onDelete, onChange }: Props): JSX.Element => {
	const dispatch = useDispatch();

	const title = type === "seller" ? "Продавец" : "Покупатель";

	const onPin = (contact: Contact) => {
		onChange(contact);
		dispatch(closeModal());
	};

	return (
		<div style={{ marginTop: 80 }}>
			<UserListLocal title={title} data={data} onDelete={onDelete}>
				<PinContact onPin={onPin}>
					<Button Icon={PlusIcon} variant="text">
						Добавить контакт
					</Button>
				</PinContact>
			</UserListLocal>
		</div>
	);
};
