import { Task } from "../../../../types";
import { useOpenTaskModal } from "../../../../utils/hooks";
import styles from "./index.module.scss";
import { CardTask } from "../card-task/CardTask";

type Props = {
  tasks: Task[];
  underlay?: boolean;
  onClick?: (id: string) => void;
  refetch?: () => void;
  mini?: boolean
};

export const CardTaskList = ({tasks, underlay = false, onClick, refetch, mini}: Props) => {
  const onUpdatedTask = () => {
    refetch?.();
  };

  const {handleOpenTaskModal} = useOpenTaskModal({onUpdatedTask, refetchParent: refetch});

  return (
    <div className={styles.cardTaskList}>
      {tasks.map((item) => {
        const {
          id,
        } = item;
        const onCardClick = () => {
          handleOpenTaskModal(id);
        };
        return (
          <div key={item.id} onClick={onCardClick} className={styles.taskWrapper} style={{cursor: "pointer"}}>
            <CardTask
              task={item}
              mini={mini}
            />
          </div>
        );
      })}
    </div>
  );
};
