// Phone number mask `+7 (000) 000 00 00`
export const phoneMask = (): Array<string | number | RegExp> => [
	"+",
	7,
	" ",
	"(",
	/\d/,
	/\d/,
	/\d/,
	")",
	" ",
	/\d/,
	/\d/,
	/\d/,
	" ",
	/\d/,
	/\d/,
	" ",
	/\d/,
	/\d/,
];
