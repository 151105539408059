import {
	UseFormSetValue,
	UseFormRegister,
	UseFormGetValues,
	Control,
	FieldErrorsImpl,
} from "react-hook-form";

import { Form } from "./components";
import { TaskFormContextProvider } from "../../../service/context/TaskForm";
import { CreateTaskForm } from "../../../types";

type Props = {
	register: UseFormRegister<CreateTaskForm>;
	setValue: UseFormSetValue<CreateTaskForm>;
	getValues: UseFormGetValues<CreateTaskForm>;
	control: Control<CreateTaskForm>;
	errors: FieldErrorsImpl<CreateTaskForm>;
	isCreating?: boolean;
};

export const TaskForm = ({
	register,
	setValue,
	getValues,
	control,
	errors,
	isCreating,
}: Props): JSX.Element => (
	<TaskFormContextProvider
		registerProp={register}
		setValueProp={setValue}
		getValuesProp={getValues}
		controlProp={control}
		errorsProp={errors}
		isCreating={isCreating}
	>
		<Form />
	</TaskFormContextProvider>
);
