import { useMutation } from "react-query";

import { api } from "../../index";
import { InviteUpdatePublicLink } from "../../../../types";

const DEFAULT_MN = "sdelka/invite/update-public-link/DEFAULT_MN";

type UseUpdatePublicLink = {
	onSuccess: () => void;
};

export const useUpdatePublicLink = ({ onSuccess }: UseUpdatePublicLink) => {
	const onError = () => {
		window.alert("Ошибка");
	};

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (values: InviteUpdatePublicLink) => api.invites.updatePublicLink(values),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
	};
};
