export const clearObject = <T>(myObject: T): T => {
	const clearedObject = { ...myObject };
	// @ts-ignore
	Object.keys(clearedObject).forEach((key) => {
		const type = typeof clearedObject[key];

		if (clearedObject[key]) {
			return;
		}

		if (Array.isArray(clearedObject[key])) {
			clearedObject[key] = [];
		} else if (type === "object") {
			clearedObject[key] = {};
		} else {
			clearedObject[key] = "";
		}
	});

	return clearedObject;
};
