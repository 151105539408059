import ReactDOM from "react-dom";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { ButtonCloseBlack, ButtonMap, FixedNavVertical, FixedNavVerticalMenu } from "../../index";
import { DrawerInDrawer } from "../DrawerInDrawer";
import { DrawerWrapper } from "./DrawerWrapper";
import {
  changeCurrentTab,
  closeDrawer,
  deleteTabInDrawer
} from "../../../../service/redux/modules/drawer/drawer.action";
import { selectDrawer, useSelect } from "../../../../service/redux/selectors";
import { useFps, useQueryParams } from "../../../../utils/hooks";
import styles from "./index.module.scss";
import { useLocation } from "react-router";
import { isArray } from "util";

const CLOSE_BUTTON_SKIP = 64;
const NO_SCROLL_DRAWER = "drawer-no-scroll";
const LS_PERF_KEY = "drawer-performance";

const useDrawerCss = (width: number, yNavigation?: Omit<FixedNavVerticalMenu, "id" | "changeTab">[]) => {
  const cssStyles: CSSProperties = {};
  const iconStyles: CSSProperties = {};
  const navStyles: CSSProperties = {animationDuration: "1s"};
  let navTop = 0;

  if (width) {
    const offsetForTabs = 55;
    navStyles.left = "auto";
    navStyles.right = `${width}px`;
    cssStyles.width = `${width}px`;
    iconStyles.right = `${width + offsetForTabs - 48}px`;
  }

  if (yNavigation) {
    navTop = CLOSE_BUTTON_SKIP;
  }

  return {navStyles, cssStyles, iconStyles, navTop};
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Drawer = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    queryParams: {mode}, changeQueryParams
  } = useQueryParams<{ mode: string }>([{name: "mode"}]);
  const handleChangeTab = (newTab: string) => changeQueryParams([{name: "mode", newValue: newTab}]);

  const {
    children,
    isOpen,
    width = 800,
    onClose,
    yNavigation,
    classNames,
    drawerInDrawer,
    disableClose,
    hasMap
  } = useSelect(selectDrawer);

  const [currentYTab, setCurrentYTab] = useState(0);
  const [performance, setPerformance] = useState<"high" | "low">("high");
  const fpsDropCount = useRef(0);
// todo костыль для StickyMenu

  const location = useLocation()
  useEffect(() => {
    if (location.pathname === "/leadsanddeals") {
      const editTab = yNavigation?.navigation.find(el => el.title === "edit")
      const editTabIndex = yNavigation?.navigation.findIndex(el => el === editTab)
      mode === "edit" && editTabIndex && setCurrentYTab(editTabIndex)
      if (mode !== "edit" && editTab && editTabIndex) {
        dispatch(deleteTabInDrawer(editTabIndex))
        setCurrentYTab(+mode)
      }
    }
  }, [mode, currentYTab])

  useFps({
    onTick: (fps) => {
      if (localStorage.getItem(LS_PERF_KEY) === "low") {
        setPerformance("low");
        return;
      }

      const minimalSatisfyingFps = 35;
      const maximumFpsDrops = 5;
      const fpsDropped = fps < minimalSatisfyingFps;
      fpsDropCount.current += fpsDropped ? 1 : 0;
      const fpsDropTooOften = fpsDropCount.current > maximumFpsDrops;

      if (fpsDropTooOften) {
        setPerformance("low");
        localStorage.setItem(LS_PERF_KEY, "low");
      }
    },
    enabled: isOpen,
  });

  const drawerYNavigation = useMemo(() => {
    if (!yNavigation) {
      return undefined;
    }
    // todo
    // if(yNavigation.navigation.length>2 ) {
    // 	const [first, second,third, ...rest ] = yNavigation.navigation
    // 	const navigation = [second, third, first, ...rest]
    // 	return  navigation.map((nav, index) => ({
    // 		...nav,
    // 		id: index,
    // 		changeTab: () => setCurrentYTab(index),
    // 	}));
    // }
    if (yNavigation.navigation.length > 2) {
      // yNavigation && yNavigation.navigation.splice(3, 0, yNavigation.navigation.splice(0, 1)[0]);
      // children && Array.isArray(children) && children.splice(3, 0, children.splice(0, 1)[0]);
    }

    return yNavigation.navigation.map((nav, index) => ({
      ...nav,
      id: index,
      changeTab: () => {
        setCurrentYTab(index);
        handleChangeTab(index.toString())
      },
    }));
  }, [yNavigation, children, currentYTab, mode]);

  useEffect(() => {
    setCurrentYTab(yNavigation?.initialTab || 0);
  }, [yNavigation?.initialTab, yNavigation]);

  const {cssStyles, iconStyles, navStyles, navTop} = useDrawerCss(width, yNavigation?.navigation);

  useEffect(() => {
    if (isOpen) {
      const innerBody = document.querySelector("body");
      innerBody?.classList.add(NO_SCROLL_DRAWER);
    }

    return () => {
      const innerBody = document.querySelector("body");
      innerBody?.classList.remove(NO_SCROLL_DRAWER);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen && drawerInDrawer) {
      throw Error("You need parent Drawer to call DrawerInDrawer component");
    }
  }, [isOpen, drawerInDrawer]);

  const handleCloseDrawer = () => {
    if (!disableClose) {
      dispatch(changeCurrentTab({newCurrentTab: 0}))
      dispatch(closeDrawer());
    }

    if (onClose) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <>
      {isOpen && (
        <div
          className={cn(styles.root, "animate__animated", "animate__faster", "animate__fadeIn", {
            [ styles.highPerf ]: performance === "high",
            [ styles.lowPerf ]: performance === "low",
          })}
        >
          <DrawerWrapper wrapperClassName={cn(classNames?.wrapper)} performance={performance}>
            <ButtonCloseBlack
              onCloseClick={handleCloseDrawer}
              classNames={{root: styles.close}}
              jsStyles={iconStyles}
            />
            {hasMap && <div className={styles.buttonMap}>
              <ButtonMap />
            </div>}
            {drawerInDrawer && (
              <DrawerInDrawer
                width={drawerInDrawer.width}
                parentWidth={width}
                onClose={drawerInDrawer.onClose}
                withCloseArrow={drawerInDrawer.withCloseArrow}
                yNavigation={drawerInDrawer.yNavigation}
              >
                {drawerInDrawer.children}
              </DrawerInDrawer>
            )}
            {yNavigation && drawerYNavigation ? (
              <FixedNavVertical
                style={navStyles}
                navigation={drawerYNavigation}
                top={navTop}
                activeTab={currentYTab}
              />
            ) : null}
            <div
              id="drawer-right-block"
              style={cssStyles}
              className={cn(styles.rightBlock, classNames?.rightBlock, {
                [ styles.withShadow ]: drawerInDrawer,
              })}
            >
              {Array.isArray(children) ? children[ currentYTab ] : children}
            </div>
          </DrawerWrapper>
        </div>
      )}
    </>,
    document.body
  );
};
