import { memo } from "react";
import {
	MedianPrice,
	BarChart,
	PieChart,
	MarkerSentence,
	BarChartProps,
	PieChartProps,
} from "@sdelka_crm/sdelka-crm-component-library";

import { GraphTextBlock } from "../GraphTextBlock";
import { MedianChartProps, PublicChart } from "../../../../../types";

import styles from "./index.module.scss";

const tooltipFormatter = (value: string) => `${value} ${new Date().getFullYear()}`;

type Props = {
	charts: PublicChart[];
};

const getGraphByType = (chart: PublicChart) => {
	if (chart.type === "medianDiagram") {
		const props = chart.props as MedianChartProps;

		return (
			<>
				<MedianPrice
					classNames={{
						main: styles.medianPriceMain,
						wrapper: styles.medianPriceWrapper,
					}}
					medianPrice={props.medianPrice}
					price={props.medianPrice}
					median={props.median}
					border={props.border}
				/>
				<div className={styles.sentences}>
					{props.legend.map((legend) => (
						<MarkerSentence key={legend.children} markerColor={legend.markerColor}>
							{legend.children}
						</MarkerSentence>
					))}
				</div>
			</>
		);
	}

	if (chart.type === "pieChart") {
		const props = chart.props as PieChartProps;

		return <PieChart data={props.data} labels={props.labels} colors={props.colors} />;
	}

	if (chart.type === "pillarChart") {
		const props = chart.props as BarChartProps;

		return (
			<BarChart data={props.data} labels={props.labels} color={props.color} xFormatter={tooltipFormatter} />
		);
	}

	return <></>;
};

export const CompareCompetitors = memo(
	({ charts }: Props): JSX.Element => (
		<div className={styles.root}>
			{charts.map((chart) => (
				<div key={`${chart.title}_${chart.type}`} className={styles.block}>
					<GraphTextBlock header={chart.rightText} text={chart.rightText}>
						<div className={styles.graph}>
							<h3>{chart.title}</h3>
							<div className={styles.graphBlock}>{getGraphByType(chart)}</div>
						</div>
					</GraphTextBlock>
				</div>
			))}
		</div>
	)
);
