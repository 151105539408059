import { useEffect } from "react";
import { RealEstate, PreCreateObjectPayload } from "../../../../../types";
import { Prompt } from "react-router";

import {
	ObjectEditLoading,
	RealEstateForm,
	ApplicationBusinessProcess,
	BlockInfo,
	ContactsLocal,
	ResponsiblesLocal,
	ApplicationTemplateGlobalQuiz,
	ApplicationComment,
} from "../../../../UI";
import { useOnSubmit, useContacts, useResponsible, useQuiz, useComment } from "./helpers";
import { useLoadCreateConfig, useCreateObjectForm, useLeaveAlertModal } from "../../../../../utils/hooks";

import styles from "./index.module.scss";

type Props = {
	defaultValues: PreCreateObjectPayload;
	onObjectCreate?: (object: RealEstate) => void;
	options?: {
		disableMap?: boolean;
		notFullHeight?: boolean;
	};
};

export const RealEstateCreate = ({ options = {}, onObjectCreate, defaultValues }: Props): JSX.Element => {
	const { disableMap } = options;

	const {
		category,
		type: { id: businessProcessId },
	} = defaultValues;
	const defaultCategory = {
		category,
		businessProcessId,
	};

	const { objectConfig, isConfigLoading, currentCategory } = useLoadCreateConfig({ defaultCategory });
	const { form, handleSubmit } = useCreateObjectForm({ defaultValues });
	const { openLeaveAlertModal } = useLeaveAlertModal();

	const {
		clearErrors,
		setError,
		formState: { isSubmitSuccessful },
	} = form;

	useEffect(() => {
		clearErrors();
	}, [currentCategory]);

	const { contacts, onDeleteContacts, onChangeContacts } = useContacts();

	const { responsiblesIds, onDeleteResponsible, onChangeResponsible } = useResponsible();

	const { quizGetValues, quizSetValue, quizRegister, quizControl } = useQuiz();

	const { comment, onChangeComment } = useComment();

	const { onSubmit, isCreateLoading } = useOnSubmit({
		setError,
		clearErrors,
		disableMap,
		onObjectCreate,
		contacts,
		responsiblesIds,
		quizGetValues,
		comment,
	});

	if (isConfigLoading) {
		return <ObjectEditLoading />;
	}

	return (
		<div className={styles.root}>


			<h2>Создание объекта</h2>

			<BlockInfo>
				Заявка будет создана, как только Вы создадите объект. Все данные заполненные в данной карточке будут
				перенесены в заявку.
			</BlockInfo>

			<ApplicationBusinessProcess form={form} />

			<div className={styles.seller}>
				<ContactsLocal
					type="seller"
					data={contacts}
					onDelete={onDeleteContacts}
					onChange={onChangeContacts}
				/>
			</div>

			<div className={styles.responsibles}>
				<ResponsiblesLocal
					responsibles={responsiblesIds}
					onDelete={onDeleteResponsible}
					onChange={onChangeResponsible}
				/>
			</div>

			<RealEstateForm
				id="new"
				form={form}
				isMutationLoading={isCreateLoading}
				objectConfig={objectConfig}
				onSubmit={onSubmit}
				handleSubmit={handleSubmit}
				isNewMod
			/>
		</div>
	);
};
