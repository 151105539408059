import { useForm } from "react-hook-form";

type Props = {
	objectDescription: string;
};

export const useDescriptionForm = ({ objectDescription }: Props) => {
	const { setValue, getValues, register } = useForm({
		defaultValues: {
			objectDescription,
		},
	});

	return {
		setValue,
		getValues,
		register,
	};
};
