import { Lead } from "../../../../../../../../../types";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../../service/redux/modules/modal/modal.action";
import styles from "./index.module.scss";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as Alert } from "../../../../../../../../../assets/icons/alert-big.svg";
import { useAddResponsibleModal } from "../../../../../../../../UI";
import { useOnPinResponsible } from "../../../../../../../../../utils/hooks";

type Props = {
  lead: Lead
  refetch: () => void;
}

export const ConfirmChangeResponsibleModal = ({lead, refetch}: Props): JSX.Element => {
  const {responsibleUser} = lead
  const dispatch = useDispatch()
  const LEAD_TITLE = "Назначение другого ответственного"
  const {onPinResponsible} = useOnPinResponsible({
    refetch,
    leadId: lead.id,
  });
  const {openAddResponsibleModal} = useAddResponsibleModal({
    title: LEAD_TITLE,
    buttonLabel: LEAD_TITLE,
    onPin: onPinResponsible,
    isPinOne: true,
    checkedResponsibles: responsibleUser?.id ? [responsibleUser?.id] : [],
  });
  const onApply = () => {
    dispatch(closeModal())
    openAddResponsibleModal()
  }
  const onDeny = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Назначение другого ответственного</div>
          <div className={styles.text}>
            При смене ответственного вы потеряете доступ к этому лиду.
          </div>
        </div>
      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button onClick={onApply} classNames={{root: styles.buttonCreate}}>
          Согласен
        </Button>
      </div>
    </>
  )
}