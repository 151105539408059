import { useEffect } from "react";
import { Control, UseFormGetValues, useWatch } from "react-hook-form";

import { useFindContactByPhone } from "../../../../../../../../../service/api/queries";

type Props = {
	control: Control<any>;
	getValues: UseFormGetValues<any>;
	defaultPhone: string;
};

export const useCheckContactExist = ({ control, getValues, defaultPhone }: Props) => {
	const mainPhoneWatcher: string = useWatch({
		name: "mainPhone",
		control,
		defaultValue: getValues("mainPhone") || "",
	});

	const formattedDefaultPhone = defaultPhone.replace(/\D/gi, "");

	const formattedCurrentPhone = mainPhoneWatcher.replace(/\D/gi, "");
	const enabled = formattedCurrentPhone.length === 11 && formattedDefaultPhone !== formattedCurrentPhone;

	const { data, isFetching, remove, setPhone } = useFindContactByPhone({
		defaultPhone: mainPhoneWatcher,
		enabled,
	});

	useEffect(() => {
		if (!enabled) {
			remove();
		} else {
			setPhone(formattedCurrentPhone);
		}
	}, [formattedCurrentPhone]);

	return {
		foundContact: data,
		isFetching,
	};
};
