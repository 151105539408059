import styles from "./index.module.scss"
import { BuyHistory, PurchasedServices } from "./components";


export const MyOrders = () => {

  return (
    <div className={styles.root}>
      <PurchasedServices/>
      <BuyHistory/>
    </div>
  )
}