export type TaskType = "all" | "today" | "hot";

export type TaskStatusType = "inWork" | "half" | "fire" | "missed" | "done";
export type TaskPriority = "usual" | "important";

export type TaskStage = "new" | "active" | "checking" | "done" | "planned" | "missed";

export type TaskTypes = {
  label: string;
  value: string;
  subtypes: TaskTypes[];
};

export type TaskSubTypeVariant = "agencyContract" | "photo" | "cleaning" | "other";

export const TaskSubTypeEnum: Record<TaskSubTypeVariant, TaskSubTypeVariant> = Object.freeze({
  agencyContract: "agencyContract", // агентсткий договор
  photo: "photo", // фотография
  cleaning: "cleaning", // уборка
  other: "other", // другое
});

export const TaskSubTypeLabelEnum: Record<TaskSubTypeVariant, string> = Object.freeze({
  agencyContract: "Aгентсткий договор", // агентсткий договор
  photo: "Фотография", // фотография
  cleaning: "Уборка", // уборка
  other: "Другое", // другое
});

export type TaskTypeVariant = "communication" | "default" | "showOrView"

export enum TaskTypeEnum {
  communication = "communication",
  default = "default",
  showOrView = "showOrView",
}

// export const TaskTypeEnum: Record<TaskTypeVariant, TaskTypeVariant> = Object.freeze({
//   communication: "communication",
//   default: "default",
//   showOrView: "showOrView",
// });

export const TaskTypeLabelEnum: Record<TaskTypeVariant, string> = Object.freeze({
  showOrView: "Показ или просмотр",
  default: "Обычная",
  communication: "Коммуникация",
});

export type TaskStatusVariant = "new" | "inWork" | "failed" | "done";

export enum TaskStatusEnum {
  inWork = "inWork", // взята в работу
  failed = "failed",
  done = "done", // завершена
}

export const TaskStatusLabelEnum: Record<TaskStatusVariant, string> = Object.freeze({
  new: "Создана", // создана, не принята
  inWork: "В работе", // взята в работу
  failed: "Провалена",
  done: "Завершена", // завершена
});

export type TaskStatus = {
  baseStatus: TaskStatusVariant;
  virtualStatus: string;
  isRework: boolean;
};

export type TaskPriorityVariant = "usual" | "important";

export const TaskPriorityEnum: Record<TaskPriorityVariant, TaskPriorityVariant> = Object.freeze({
  usual: "usual",
  important: "important",
});

export type TaskVirtualStatus = "missed" | "hot" | "inWork" | "success" | "failed" | "checking" | "paused";

export type TaskLeftMenuFilter =
  | "active"
  | "inWork"
  | "missed"
  | "hot"
  | "returned"
  | "finished"
  | "success"
  | "failed"
  | "paused"
  | "checking";
