import { useDispatch } from "react-redux";

import { RealEstatePageNoTabs } from "../../../pages/RealEstatePage";
import { openDrawerInDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { openInNewWindow } from "../../../../utils/openInNewWindow";

type Props = {
	inDrawer?: boolean;
	applicationId?: string;
	shortId?: string;
};

type Return = {
	handleOpenRealEstate: () => void;
};

type UseOpenRealEstate = (props: Props) => Return;

export const useOpenRealEstate: UseOpenRealEstate = ({ inDrawer, applicationId, shortId = "" }) => {
	const dispatch = useDispatch();
	const openRealEstateDrawerInDrawer = () => {
		if (applicationId) {
			dispatch(
				openDrawerInDrawer({
					children: <RealEstatePageNoTabs applicationId={applicationId} objectShortId={shortId} />,
					width: 800,
				})
			);
		}
	};

	const openRealEstateNewTab = () => {
		if (applicationId) {
			const url = `/real-estate/application/${applicationId}?mode=view`;
			openInNewWindow(url);
		}
	};

	const handleOpenRealEstate = () => (inDrawer ? openRealEstateNewTab() : openRealEstateDrawerInDrawer());

	return {
		handleOpenRealEstate,
	};
};
