import React from "react";
import { ErrorsAndWarnings } from "../../../../../../../../../../../types";
import styles from "./index.module.scss";
import { StartAdMessagesBlock } from "../StartAdMessageBlock";

type Props = {
	messages: ErrorsAndWarnings;
	errorsTitle?: string;
};

export const StartAdErrors: React.FC<Props> = ({ messages, errorsTitle }) => {
	const { errors, warinigs: warnings } = messages;
	return (
		<div className={styles.container}>
			{errors?.length > 0 && (
				<StartAdMessagesBlock
					title={errorsTitle ?? `Ошибк${errors.length === 1 ? "а" : "и"}`}
					items={errors}
				/>
			)}
			{warnings?.length > 0 && (
				<StartAdMessagesBlock title={`Предупреждени${warnings.length === 1 ? "е" : "я"}`} items={warnings} />
			)}
		</div>
	);
};
