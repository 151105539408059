import { memo } from "react";

import styles from "../index.module.scss"

import { ReactComponent as ResponsibleIcon } from "../../../../../assets/icons/responsibleIcon.svg";
import { ReactComponent as ImResponsibleIcon } from "../../../../../assets/icons/imResponsibleIcon.svg";
import { UserPreview } from "../../../../../types";
import cn from "classnames";

type Props = {
  responsible?: UserPreview
  iAmResponsibleUser?: boolean
  classNames?: string
}


export const CardApplicationResponsible = memo(({responsible, iAmResponsibleUser, classNames}: Props): JSX.Element => (
  <div className={cn(styles.responsible, classNames)}>
    {responsible?.name && !iAmResponsibleUser && (
      <>
        {responsible.avatar
          ? <img className={styles.responsibleLogo} src={responsible.avatar} alt="responsibleIcon.svg"/>
          : <ResponsibleIcon/>
        }
        <span>{`${responsible.lastName} ${responsible.name}`}</span>
      </>
    )}
    {iAmResponsibleUser &&
        <div className={styles.responsibleLogo}>
            <ImResponsibleIcon/>
        </div>
    }
  </div>
))