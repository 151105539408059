import { useDispatch } from "react-redux";
import { changeCurrentTab } from "../../service/redux/modules/drawer/drawer.action";
import { useOpenApplicationInDrawer, useQueryParams } from "../hooks";
import { HandleClickApplicationPayload, Kind, KindType, QueriesToChange } from "../../types";

type Props = {
  callbackAfterClose?: (id: string) => void;
  kind?: KindType | Kind
  isFromObjectList?: boolean
};

type Return = {
  handleClickApplication: (props: HandleClickApplicationPayload) => void;
};

type UseOpenApplicationCard = (props: Props) => Return;

export const useOpenApplicationCard: UseOpenApplicationCard = ({callbackAfterClose, isFromObjectList}) => {

  const dispatch = useDispatch();
  const {removeQueryParam, changeQueryParams} = useQueryParams<{
    tab: string;
    client: string;
    preApplicationId: string
    applicationId: string
    dealId: string
  }>([{name: "tab"}, {name: "client"}, {name: "mode"}, {name: "preApplicationId"}, {name: "applicationId"}, {name: "dealId"}]);

  const {handleOpenApplicationCard} = useOpenApplicationInDrawer();

  const handleClickApplication = (
    {
      applicationId,
      kind,
      initialTab = 0,
      whoIsClient,
      refetch,
      isFromObjectList
    }: HandleClickApplicationPayload) => {
    const label = () => {
      let label = ""
      if (kind === KindType.APPLICATION) {
        return label = "Заявка"
      }
      if (kind === KindType.PREAPPLICATION) {
        return label = "Предзаявка"
      }
      if (kind === KindType.DEAL) {
        return label = "Сделка"
      }
    }
    const navigation = [{label: label(), height: 160}];

    const yNavigation = {
      navigation,
      initialTab: 0,
    };

    const beforeOpen = () => {
      let toChange: QueriesToChange[] = []

      if (kind === KindType.PREAPPLICATION) {
        toChange = [
          {name: "preApplicationId", newValue: applicationId},
        ];
      }
      if (kind === KindType.APPLICATION) {
        toChange = [
          {name: "applicationId", newValue: applicationId},
        ];
      }
      if (kind === KindType.DEAL) {
        toChange = [
          {name: "dealId", newValue: applicationId},
        ];
      }
      if (kind === KindType.SUCCESSDEAL) {
        toChange = [
          {name: "dealId", newValue: applicationId},
        ];
      }

      changeQueryParams(toChange);
    };

    const afterClose = () => {
      removeQueryParam(["id", "client", "preApplicationId", "dealId", "applicationId"]);

      if (callbackAfterClose) {
        callbackAfterClose(applicationId);
      }
    };

    let onFirstLoad;

    if (initialTab !== 0) {
      onFirstLoad = () => {
        dispatch(changeCurrentTab({newCurrentTab: initialTab}));
      };
    }

    return handleOpenApplicationCard({
      id: applicationId,
      kind,
      width: 800,
      yNavigation,
      refetch,
      beforeOpen,
      afterClose,
      onFirstLoad,
      isFromObjectList,
    });
  };

  return {handleClickApplication};
};
