import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { CardAgent } from "../../../../UI";
import { useLoadYourAgents } from "../../../../../service/api/queries/public-page";

type Props = {
	contactId: string;
};

export const AgentList = ({ contactId }: Props): JSX.Element => {
	const { data, isLoading } = useLoadYourAgents({ contactId });

	return (
		<div>
			<Loader height={250} style={{ display: isLoading ? "block" : "none" }} />
			<div style={{ display: isLoading ? "none" : "block", marginBottom: 20 }}>
				{data.map((item) => (
					<div key={item.id} style={{ marginBottom: 20 }}>
						<CardAgent
							image={item.account.avatar}
							company={item.company ? item.company.companyName : ""}
							account={{
								firstName: item.account.name,
								lastName: item.account.lastName,
								secondName: item.account.secondName,
							}}
							phone={item.account.phone}
							rating={item.rating}
							socialLinks={item.social}
						/>
					</div>
				))}
			</div>
		</div>
	);
};
