import { RealEstate } from "../../../../../types";

import { CardObjectPublic } from "../../../../UI";

import { ReactComponent as Close } from "../../../../../assets/icons/close.svg";
import styles from "./index.module.scss";

type Props = {
	list: RealEstate[];
};

export const CompetitorsList = ({ list }: Props): JSX.Element => (
	<div className={styles.root}>
		{list.map((object) => (
			<div key={object.id} className={styles.object}>
				<div onClick={() => console.log("close")} className={styles.close}>
					<Close />
				</div>
				<CardObjectPublic clientObject={object} />
			</div>
		))}
	</div>
);
