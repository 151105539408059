/* eslint-disable sonarjs/cognitive-complexity */
import { CSSProperties, MouseEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { Button, Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { ButtonClose } from "../../index";
import { AlertIconComponent } from "./components";
import {
	closeModal,
	DEFAULT_MODAL_WIDTH,
	DEFAULT_ALERT_MODAL_WIDTH,
} from "../../../../service/redux/modules/modal/modal.action";
import { useSelect, selectModal } from "../../../../service/redux/selectors";

import styles from "./index.module.scss";

export const NO_SCROLL_CLASS = "modal-no-scroll";

export const Modal = (): JSX.Element => {
	const {
		isOpen,
		title,
		body,
		width,
		onClose,
		variant,
		alertButtonSubmit,
		alertButtonDecline,
		alertIcon,
		classNames = {},
		isLoading,
	} = useSelect(selectModal);
	const dispatch = useDispatch();
	const modalStyles: CSSProperties = {};

	const getModalWidth = (): number | undefined => {
		if (width === DEFAULT_MODAL_WIDTH && variant === "alert") {
			return DEFAULT_ALERT_MODAL_WIDTH;
		}

		return width;
	};

	modalStyles.width = `${getModalWidth()}px`;

	useEffect(() => {
		if (isOpen) {
			const innerBody = document.querySelector("body");
			innerBody?.classList.add(NO_SCROLL_CLASS);
		}

		return () => {
			const innerBody = document.querySelector("body");
			innerBody?.classList.remove(NO_SCROLL_CLASS);
		};
	});

	const handleCloseModal = () => {
		dispatch(closeModal());

		if (onClose) {
			onClose();
		}
	};

	return (
		<>
			{isOpen && (
				<div
					className={cn(
						styles.root,
						classNames.root,
						styles[`modal__${variant}`],
						"animate__animated",
						"animate__fadeIn",
						"animate__fast"
					)}
					onClick={handleCloseModal}
				>
					<div
						style={modalStyles}
						className={cn(
							styles.content,
							classNames.contentWrapper,
							"animate__animated",
							"animate__fadeInDown",
							"animate__faster"
						)}
						onClick={(event: MouseEvent<HTMLElement>) => event.stopPropagation()}
					>
						{isLoading && <Loader className={styles.loader} />}

						{variant === "alert" && <AlertIconComponent alertIcon={alertIcon} />}

						<ButtonClose onClick={handleCloseModal} classNames={{ root: cn(styles.close) }} />

						<div
							className={cn(classNames.wrapper, {
								[styles.alertcontentBodyContainer]: variant === "alert",
								[styles.loaderWrapper]: isLoading,
							})}
						>
							{typeof title === "string" ? (
								<h3
									className={cn(styles.heading, {
										[styles.headingAlert]: variant === "alert",
									})}
								>
									{title}
								</h3>
							) : (
								title
							)}

							<div className={cn(styles.contentBody, classNames.contentBody)}>{body}</div>

							{variant === "alert" && (alertButtonSubmit || alertButtonDecline) && (
								<div className={styles.alertButtonContainer}>
									{alertButtonDecline && (
										<Button variant="outlined" onClick={alertButtonDecline.onClick}>
											{alertButtonDecline.label}
										</Button>
									)}

									{alertButtonSubmit && (
										<Button onClick={alertButtonSubmit.onClick}>{alertButtonSubmit.label}</Button>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
