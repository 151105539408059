import { Client } from "../../Client";
import { Office, UserAccountData } from "../../../../types";

export const OfficesService = {
	list: () => Client.get<Office[]>("/company/office"),
	delete: (id: string) => Client.delete(`/company/office/${id}`),
	create: (data: Partial<Office>) => Client.post<Office>("/company/office", data),
	update: (id: string, data: Partial<Office>) => Client.put<Office>(`/company/office/${id}`, data),
	get: (id: string) => Client.get<Office>(`/company/office/${id}`),
	updatePhoto: (officeId: string, data: { avatar: string }) =>
		Client.put<Office>(`/office/photo/${officeId}`, data),
};
