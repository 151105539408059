import { ApplicationInListV2, Lead, LeadStatusEnum, OpenApplicationInDrawer } from "../../../../../../../../../types";
import styles from "./index.module.scss"
import { ReactComponent as PlusIcon } from "../../../../../../../../../assets/icons/plus-small.svg";
import { CardApplicationMini } from "../../../../../../../../UI/Card/card-application/card-application-mini";
import { Plug } from "../../../plug/Plug";
import cn from "classnames";
import { useDispatch } from "react-redux";
import {
  closeDrawerInDrawer,
  openDrawerInDrawer
} from "../../../../../../../../../service/redux/modules/drawer/drawer.action";
import { PreApplicationTemplate } from "../../../../../../../Application/PreApplicationTemplate";
import { ApplicationCard } from "../../../../../../../Application";
import { useAutoOpenApplication } from "../../../LeadApplications/hooks";
import { useCreatePreApplicationModal, useQueryParams } from "../../../../../../../../../utils/hooks";
import { toastError } from "../../../../../../../../UI";

type Props = {
  preApplications?: ApplicationInListV2[]
  status: LeadStatusEnum
  lead: Lead
  refetch: () => void
}

export const PreApplications = ({preApplications, status, lead, refetch}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {childApplications, contact} = lead;
  const isFreezed = status === LeadStatusEnum.deferred
  const canAddPreApp = status !== (LeadStatusEnum.deferred || LeadStatusEnum.done)
  const count = preApplications ? preApplications.length : ""
  const plugText = isFreezed ? "Разморозьте лид, чтобы создать" : "Нет предзаявок"
	const {
    queryParams: {preApplicationId}, changeQueryParams, removeQueryParam
  } = useQueryParams<{ preApplicationId: string }>([{name: "preApplicationId"}, {name: "id"}]);

	const handleChangeTab = (newTab: string, newId: string) => {
    changeQueryParams([{name: "preApplicationId", newValue: newId}]);
  }

  const handleOpenPreApplication = (id: string) => {
    dispatch(closeDrawerInDrawer());
    setTimeout(() => {
      dispatch(
        openDrawerInDrawer({
          children: (<PreApplicationTemplate refetchLead={refetch} reopenPreapp={handleOpenPreApplication} applicationId={id} isFromLead={true}/>),
          width: 800,
          onClose: () => removeQueryParam(["preApplicationId"])
        })
      );
    }, 100)

  };

  const openApplicationDrawerInDrawer = ({id}: OpenApplicationInDrawer) => {
    dispatch(closeDrawerInDrawer());
    setTimeout(() => {
      dispatch(
        openDrawerInDrawer({
          children:
            <PreApplicationTemplate refetchLead={refetch} reopenPreapp={handleOpenPreApplication} applicationId={id} isFromLead={true}/>,
          width: 800,
          onClose: () => removeQueryParam(["preApplicationId"])
        })
      );
    }, 100);
  };

  useAutoOpenApplication({openApplicationDrawerInDrawer});
  const {handleCreateApplication} = useCreatePreApplicationModal({
    leadId: lead.id,
    onSuccess: (data) => {
      refetch();
      handleChangeTab("pre-applications", data.id)
      openApplicationDrawerInDrawer({
        id: data.id,
        isTemplate: true,
      });
    },
  });

  const checkBeforeCreate = () => {
    if (contact) {
      handleCreateApplication();
    } else {
      toastError({
        text: "Необходимо добавить контакт лиду, чтобы создать заявку",
      });
    }
  };

  return (
    <div>
      <div className={cn(styles.row, styles.label)}>
        <div className={styles.row}>
          <span className={styles.title}>
            Предзаявки
          </span>
          {!isFreezed && <span className={styles.count}>
            {count}
          </span>}
        </div>
        {canAddPreApp &&
          <div className={styles.row}>
            <PlusIcon/>
            <span className={styles.actionText} onClick={checkBeforeCreate}>
              Добавить
            </span>
          </div>
        }
      </div>
      {preApplications && preApplications.length > 0
        ? <div className={styles.preAppTable}>
          {preApplications.map(app =>
            <div key={app.id} onClick={() => {
              handleChangeTab("pre-applications", app.id)
              handleOpenPreApplication(app.id)
              }}>
              <CardApplicationMini
                application={app}
                classNames={{
                  root: styles.preApp
                }}
                options={{
                  canOpenANewTab: true,
                  success: app.shortType.kind === "successDeal"
                  }
                }
              />
            </div>
          )}
        </div>
        : <div className={styles.plug}>
          <Plug plugText={plugText}/>
        </div>
      }
    </div>
  )
}