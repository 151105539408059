import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/shows-views/new-date/DEFAULT_MN";

type UseNewDate = {
	onSuccess?: (data: any) => void;
};

export const useNewDate = ({ onSuccess }: UseNewDate) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: { id: string; plannedDate: Date }) => api.showsViews.newDate(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
