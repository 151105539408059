import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import {
	CalendarInPopup,
	Checkbox,
	InfoRow,
	MultiSelect,
	TransparentInput,
} from "../../../../../../../index";
import {
	CheckboxGroup,
	NumberInput,
	ObjectRadio,
	RoomsSquareList,
	RoundCheckbox,
	TimeTemplate,
	RadioSelect,
	CheckboxSelect,
	MultiSelectTemplate,
	JustSelect,
} from "../Inputs";
import { RequiredDots } from "../RequiredDots";
import { getDefaultForSelect } from "../../../../helpers";
import { commonPlaceholders } from "../../../../../../../../../utils/placeholders";
import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SimpleFields = ({ form, field }: Props): JSX.Element => {
	const {
		label,
		required,
		reqiredFor,
		dataType: { type, name, template, allowedValues },
	} = field;
	const { control, setValue, register, getValues } = form;

	if (!name) {
		return <></>;
	}

	if (type === "select" && template === "roundChekbox") {
		return <RoundCheckbox form={form} field={field} />;
	}

	if (type === "select" && template === "radio") {
		return <RadioSelect form={form} field={field} />;
	}

	if (type === "select" && template === "checkbox") {
		return <CheckboxSelect form={form} field={field} />;
	}

	if (type === "roomsSquare") {
		return <RoomsSquareList form={form} field={field} />;
	}

	return (
		<InfoRow
			label={label}
			labelFont="ubuntu"
			aspect={{ label: 1, content: 1 }}
			classNames={{ root: styles.customInfoRow }}
		>
			<RequiredDots required={reqiredFor} />

			{type === "text" && (
				<TransparentInput
					withCleanUp={{ setValue }}
					placeholder={commonPlaceholders.notSpecified}
					name={name}
					register={register}
					required={required}
				/>
			)}

			{type === "time" && <TimeTemplate form={form} field={field} />}

			{type === "select" && <JustSelect form={form} field={field} />}

			{type === "number" && (
				<>
					{template === "radioButtonFrom1To4" && <ObjectRadio name={name} control={control} />}

					{template === "number" && <NumberInput form={form} field={field} onlyPositive />}
				</>
			)}

			{type === "boolean" && <Checkbox name={name} control={control} />}

			{type === "multiSelect" && template === "checkbox" && (
				<CheckboxGroup name={name} control={control} allowedValues={allowedValues} />
			)}

			{type === "multiSelect" && template === "select" && (
				<MultiSelect
					name={name}
					setValue={setValue}
					options={
						allowedValues?.map((allowed: any) => ({
							label: allowed,
							value: allowed,
						})) || []
					}
					register={register}
					placeholder="Выбрать"
					defaultValue={getDefaultForSelect({ fieldName: name, getValues }) as string[]}
				/>
			)}

			{type === "multiSelect" && template === "dropdown" && <MultiSelectTemplate form={form} field={field} />}

			{type === "readonly" && <>{getValues(name) || "-"}</>}

			{type === "date" && (
				<div className={styles.calendarContainer}>
					<CalendarInPopup form={{ name, register, setValue, getValues }} />
				</div>
			)}

			{type === "readonlyDate" && <>{formatDate(getValues(name), "full") || "-"}</>}
		</InfoRow>
	);
};
