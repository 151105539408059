import { ComponentType, ReactNode, ReactText, SVGProps } from "react";
import { toast, ToastContainer as Toastyfy } from "react-toastify";
import cn from "classnames";

import { ReactComponent as CheckMarkIcon } from "../../../assets/icons/check-mark-outlined.svg";
import { ReactComponent as RollIcon } from "../../../assets/icons/roll.svg";
import { ReactComponent as WarnIcon } from "../../../assets/icons/warn.svg";
import "react-toastify/dist/ReactToastify.css";
import "./toast.scss";

type Common = {
	StandardIcon: ComponentType<SVGProps<SVGSVGElement>>;
	type: "info" | "error" | "success";
};

type Props = {
	text: string;
	body?: ReactNode;
	Icon?: ComponentType<SVGProps<SVGSVGElement>>;
	className?: string;
	autoClose?: false | number;
};

const Toast = ({
	text,
	body,
	Icon,
	className,
	StandardIcon,
	type,
}: Omit<Props, "autoClose"> & Common): JSX.Element => (
	<div className={className}>
		<div className={cn("Toastify__toast-icon-container", `Toastify__toast-icon-container-${type}`)}>
			{/* @ts-ignore */}
			{Icon ? <Icon /> : <StandardIcon />}
		</div>
		<div className="Toastify__toast-text-container">
			{text}

			{body ?? null}
		</div>
	</div>
);

export const toastInfo = ({ text, body, Icon, className, autoClose = 3000 }: Props): ReactText =>
	toast.info(
		<Toast text={text} body={body} Icon={Icon} className={className} StandardIcon={RollIcon} type="info" />,
		{ autoClose, icon: false }
	);

export const toastSuccess = ({ text, body, Icon, className, autoClose = 3000 }: Props): ReactText =>
	toast.success(
		<Toast
			text={text}
			body={body}
			Icon={Icon}
			className={className}
			StandardIcon={CheckMarkIcon}
			type="success"
		/>,
		{ autoClose, icon: false }
	);

export const toastError = ({ text, body, Icon, className, autoClose = 3000 }: Props): ReactText =>
	toast.error(
		<Toast text={text} body={body} Icon={Icon} className={className} StandardIcon={WarnIcon} type="error" />,
		{ autoClose, icon: false }
	);

export const toastLoading = ({ text }: Props): ReactText => toast.loading(text);

export const ToastContainer = (): JSX.Element => <Toastyfy hideProgressBar closeOnClick={false} />;
