import { useEffect, useRef, useState } from "react";
import { Prompt } from "react-router-dom";
import { Pagination } from "@sdelka_crm/sdelka-crm-component-library";
import { Location } from "history";
import cn from "classnames";

import { RealEstateTabs, SidebarLayout } from "../../UI";
import { ObjectListFastFilters, ObjectListHeader, ObjectsMap } from "./components";
import { ObjectsListRegular, RealEstateListCompilation, SampleSelected } from "./views";
import { useSelector } from "../../../service/redux/useSelector";
import { useQueryParams } from "../../../utils/hooks";
import { defaultValues } from "../../../utils/clientObjects";
import { useAutoScroll, useLoadData, useSample, useSyncCompilationFilters } from "./helpers";
import { RealEstateFilter, RealEstateListTab } from "../../../types";

import styles from "./index.module.scss";
import { useLeadsAndDealsTabs } from "../LeadsAndDeals/helpers";

type TabType = "PARSED" | "AGENCY";

type QueryParams = {
	compilation: boolean;
	applicationId: string;
	compilationApplicationId: string;
	comparedApplicationId: string;
	page: number;
	filter: RealEstateListTab;
	tab: TabType;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RealEstateList = (): JSX.Element => {
	const { objectsGroupIds } = useSelector((state) => state.objectList);

	const [objectsFilterTypes, setObjectsFilterTypes] = useState<string[]>(defaultValues.category ?? []);

	const [isSelectedAll, setIsSelectedAll] = useState(false);
	const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

	const wrapperRef = useRef<HTMLDivElement>(null);

	const { queryParams, changeQueryParams } = useQueryParams<QueryParams>([
		{ name: "compilation", type: "boolean" },
		{ name: "applicationId" },
		{ name: "compilationApplicationId" },
		{ name: "preApplicationId" },
		{ name: "page", type: "number" },
		{ name: "filter" },
		{ name: "comparedApplicationId", type: "string" },
		{ name: "tab", type: "string" },
	]);

	const currentTab = queryParams.tab ?? "PARSED";
	const {	} = useLeadsAndDealsTabs();

	const {
		list: realEstatesList,
		isFetching,
		total,
		take,
		setTake,
		page,
		setPage,
		sort,
		setSort,
		filter,
		setFilter,
		refetch: refetchList
	} = useLoadData({
		defaultPage: queryParams.page,
		tab: currentTab,
	});

	useAutoScroll({ wrapperRef, isFetching, page });
	const [actualFilter, setActualFilter] = useState(filter)

	const { handleOnSaveCompilationFilter, handleAlertFilterWasntSaved } = useSyncCompilationFilters({
		applicationId: queryParams.compilationApplicationId,
		isCompilationPage: !!queryParams.compilation,
		filter: actualFilter,
		setFilter,
	});

	useEffect(() => {
		// It's temporary way.
		setTimeout(() => {
			if (!queryParams?.filter) return;

			const parsedFilters = queryParams?.filter && JSON.parse(queryParams?.filter);
			setFilter(parsedFilters);
		}, 1000);

		return () => {
			setObjectsFilterTypes([]);
		};
	}, []);

	useEffect(() => {
		if (queryParams?.filter) {
			const parsedFilters = JSON.parse(queryParams?.filter);
			setFilter(parsedFilters);
		}
	}, [queryParams?.filter, queryParams?.tab]);

	const handlePageChange = (nextPage: number) => {
		setPage(nextPage);

		changeQueryParams([{ name: "page", newValue: nextPage }]);
	};

	const changeActualFilter = (payload: RealEstateFilter) => {
		handlePageChange(1);
		setActualFilter(payload);
	};

	const changeFilter = (payload: RealEstateFilter) => {
		handlePageChange(1);
		setFilter(payload);
	};

	const resetFilter = () => {
		handlePageChange(1);
		setFilter(defaultValues);

		const encodedBackPath = encodeURIComponent(JSON.stringify(defaultValues));
		changeQueryParams([{ name: "filter", newValue: encodedBackPath, isEncode: true }]);
	};

	const selectAll = () => {
		if (realEstatesList && !isSelectedAll) {
			setSelectedObjects(realEstatesList.map((realEstate) => realEstate.id));
			setIsSelectedAll(true);
		} else {
			setSelectedObjects([]);
			setIsSelectedAll(false);
		}
	};

	const setMapFilter = (value) => {
		setActualFilter(value)
		setFilter(value)
	}

	const { compilation, compilationApplicationId, comparedApplicationId } = queryParams;

	const sampledApplicationId = compilationApplicationId || comparedApplicationId;

	const { isSample, isCompilation, isCompare } = useSample({
		compilation,
		comparedApplicationId,
		applicationId: compilationApplicationId,
	});

	const isParser = currentTab !== "AGENCY";

	return (
		<>
			{queryParams.compilation && (
				<Prompt message={(nextLocation: Location<unknown>) => handleAlertFilterWasntSaved(nextLocation)} />
			)}

			<SidebarLayout
				fullSize
				hat={{
					element: (
						<ObjectListFastFilters
							globalFilter={filter}
							changeGlobalFilter={changeFilter}
							isCompilation={queryParams.compilation}
							onSaveCompilationFilters={handleOnSaveCompilationFilter}
							className={queryParams.compilation ? styles.filtersShorter : ""}
							objectsFilterTypes={objectsFilterTypes}
							setObjectsFilterTypes={setObjectsFilterTypes}
							resetFilter={resetFilter}
							tab={currentTab}
							changeActualFilter={changeActualFilter}
							isParser={isParser}
						/>
					),
					elementHeight: 50,
				}}
				rightColumn={
					isSample ? (
						<SampleSelected
							isCompilation={isCompilation}
							isCompare={isCompare}
							sampledApplicationId={sampledApplicationId}
						/>
					) : undefined
				}
			>
				<div className={styles.root}>
					<div ref={wrapperRef} className={styles.listWrapper}>
						<div
							className={cn(styles.list, {
								[styles.listWithNoFilters]: !objectsFilterTypes?.length,
							})}
						>
							<RealEstateTabs realEstateListFilters={filter} />

							{!objectsGroupIds.length && (
								<ObjectListHeader
									total={total}
									take={take}
									setTake={setTake}
									sort={sort}
									setSort={setSort}
									isSelectedAll={isSelectedAll}
									selectAll={selectAll}
									isLoading={isFetching}
									selectedItemsIds={selectedObjects}
								/>
							)}

							{isSample ? (
								<RealEstateListCompilation
									realEstatesList={realEstatesList}
									isLoading={isFetching}
									sampledApplicationId={sampledApplicationId}
									isCompare={isCompare}
									isCompilation={isCompilation}
									isParser={isParser}
								/>
							) : (
								<ObjectsListRegular
									realEstatesList={realEstatesList}
									isLoading={isFetching}
									// selectedObjects={selectedObjects}
									// setSelectedObjects={setSelectedObjects}
									isParser={isParser}
									refetchList={refetchList}
								/>
							)}

							{!objectsGroupIds.length && (
								<Pagination
									totalCount={total}
									isLoading={isFetching}
									pageSize={take}
									currentPage={page}
									onPageChange={(nextPage: number) => handlePageChange(nextPage)}
									classNames={{root: styles.pagination}}
								/>
							)}
						</div>
					</div>

					<div className={styles.map}>
						<ObjectsMap
							realEstatesList={realEstatesList}
							isLoading={isFetching}
							filter={actualFilter}
							setFilter={setMapFilter}
							isParser={isParser}
						/>
					</div>
				</div>
			</SidebarLayout>
		</>
	);
};
