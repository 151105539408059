import { useState, useMemo, ReactNode } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import DOMPurify from "dompurify";
import { Button, H3 } from "@sdelka_crm/sdelka-crm-component-library";

import { Select, TransparentInput, MarkdownEditor } from "../index";
import { useApplicationRealEstateUpdate } from "../../../service/api/mutations";
import { useLoadObjectSignatures } from "../../../service/api/queries";
import { createObjectDescriptionSchema } from "../../../utils/ads";
import { RealEstateDescriptionFormValues, RealEstate } from "../../../types";

import { ReactComponent as PencilIcon } from "../../../assets/icons/pencil.svg";
import styles from "./index.module.scss";

type Props = {
	applicationId: string;
	additionalFields?: any;
	disableEditMode?: boolean;
	realEstate: RealEstateDescriptionFormValues;
	adTitleError?: ReactNode;
	title?: string;
	refetch?: () => void;
	descriptionFieldName?: string;
	children?: ReactNode;
	onMutate?: {
		func: ({ onSuccess }) => { mutate; isLoading };
		prepareData?: (data) => any;
	};
	options?: {
		hideTitle?: boolean;
		hideVideoLink?: boolean;
		hideDescriptionSignature?: boolean;
	};
	commentPlaceholder?: string;
};

export const ObjectAdDescriptionForm = ({
	applicationId,
	refetch,
	title = "Описание для рекламы",
	descriptionFieldName = "adDescription",
	realEstate,
	options,
	disableEditMode = false,
	children = "",
	additionalFields = () => {},
	onMutate,
	adTitleError,
	commentPlaceholder = "Комментарий пока не заполнен. Оставьте комментарии об объекте",
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props): JSX.Element => {
	const [editMode, setEditMode] = useState(false);

	const { hideTitle, hideVideoLink, hideDescriptionSignature } = options || {};

	const {
		handleSubmit,
		register,
		setValue,
		formState: { errors },
		getValues,
	} = useForm<RealEstate>({
		resolver: yupResolver(
			createObjectDescriptionSchema({
				hideVideoLink: !!hideVideoLink,
				descriptionFieldName,
			})
		),
		defaultValues: {
			adTitle: realEstate.adTitle,
			adDescriptionSignatureId: realEstate.adDescriptionSignatureId,
			[descriptionFieldName]: realEstate[descriptionFieldName],
			additionalProperties: {
				videoUrl: realEstate?.additionalProperties?.videoUrl || "",
			},
		},
	});

	const { objectSignaturesList } = useLoadObjectSignatures();

	const signatureOptions = useMemo(
		() =>
			objectSignaturesList.map((signature) => ({
				value: signature.id,
				label: signature.label,
			})),
		[objectSignaturesList]
	);

	const mutationFunction = onMutate?.func || useApplicationRealEstateUpdate;

	const { mutate, isLoading } = mutationFunction({
		onSuccess: () => {
			if (refetch) {
				refetch();
			}
			setEditMode(false);
		},
	});

	const onSubmit = (values: any) => {
		const payload = {
			id: applicationId,
			data: { ...values, ...additionalFields },
		};

		mutate(onMutate?.prepareData ? onMutate?.prepareData(payload) : payload);
	};

	const isShowDescriptionSignature =
		!hideDescriptionSignature && realEstate.adDescriptionSignatureId !== "" && signatureOptions.length !== 0;

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
				<H3
					classNames={{
						root: cn(styles.h3, { [styles.editModeH3]: editMode }),
					}}
				>
					<div>{title}</div>

					{!disableEditMode &&
						(editMode ? (
							<Button type="submit" variant="text" isLoading={isLoading}>
								Сохранить
							</Button>
						) : (
							<div className={styles.edit} onClick={() => setEditMode(true)}>
								<PencilIcon />
							</div>
						))}
				</H3>

				{editMode || disableEditMode ? (
					<>
						{!hideTitle && (
							<div className={styles.inputWrapper}>
								{adTitleError}
								<TransparentInput
									name="adTitle"
									register={register}
									className={cn(styles.input, {
										[styles.inputError]: Boolean(adTitleError),
									})}
									disabled={isLoading}
									placeholder="Заголовок"
									error={errors.adTitle?.message?.toString()}
								/>
							</div>
						)}

						<MarkdownEditor
							name={descriptionFieldName}
							setValue={setValue}
							getValues={getValues}
							register={register}
							error={errors[descriptionFieldName]?.message?.toString()}
						/>

						{isShowDescriptionSignature && (
							<div className={styles.selectWrapper}>
								<Select
									name="adDescriptionSignatureId"
									register={register}
									setValue={setValue}
									options={signatureOptions}
									defaultValue={realEstate.adDescriptionSignatureId}
									placeholder="Подпись к объявлению"
								/>
							</div>
						)}

						{!hideVideoLink && (
							<div className={styles.inputWrapper}>
								<TransparentInput
									name="additionalProperties.videoUrl"
									register={register}
									className={cn(styles.input, styles.videoInputWrapper)}
									disabled={isLoading}
									preNode={<div className={styles.videoPreNode}>Ссылка на видео:</div>}
									error={errors?.additionalProperties?.videoUrl?.message?.toString()}
									placeholder="Не указана"
								/>
							</div>
						)}
					</>
				) : (
					<div className={styles.viewMode}>
						{!hideTitle && <div className={styles.viewModeTitle}>{realEstate.adTitle}</div>}

						<div
							className={cn(styles.viewModeDescription, {
								[styles.commentPlaceholder]: !realEstate[descriptionFieldName],
							})}
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(realEstate[descriptionFieldName] || commentPlaceholder),
							}}
						/>

						{!hideDescriptionSignature && (
							<div className={styles.viewModeSignature}>
								{objectSignaturesList.find(
									(signature) => signature.id === realEstate.adDescriptionSignatureId
								)?.label || "-"}
							</div>
						)}

						{!hideVideoLink && (
							<div className={styles.viewModeVideo}>
								<span>Ссылка на видео:</span>
								<a
									target="_blank"
									rel="noreferrer"
									href={(realEstate.additionalProperties?.videoUrl as string) || "#"}
								>
									{realEstate.additionalProperties?.videoUrl || "-"}
								</a>
							</div>
						)}
					</div>
				)}

				{children}
			</form>
		</div>
	);
};
