import { User } from "../../../../types";
import { useQuery } from "react-query";

import { api } from "../../index";
import { Product } from "../../../../types/product";

const DEFAULT_QN = "sdelka/user/load-service/DEFAULT_QN";

type Props = {
  productId?: string | null;
  onSuccess?: () => void;
  onError?: () => void;
};

type Return = {
  product?: Product;
  isFetching: boolean;
  refetch: () => void;
};

type UseLoadProduct = (props: Props) => Return;

export const makeUseLoadProductQueryKey = (productId?: string | null) => [DEFAULT_QN, productId];

export const useLoadProduct: UseLoadProduct = ({ productId, onSuccess, onError }) => {
  const loadProduct = () => api.services.get(productId as string);
  const queryKey = makeUseLoadProductQueryKey(productId);
  const { data, isFetching, refetch } = useQuery(queryKey, loadProduct, {
    enabled: !!productId,
    onSuccess,
    onError,
  });

  return {
    product: data?.data,
    isFetching,
    refetch,
  };
};
