import { useMutation } from "react-query";

import { KindType, WhoIsClient } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/application/update-application-contacts/DEFAULT_MN";

type UseUpdateContact = {
	onSuccess?: (data: any) => void;
	kind?: KindType,
};

export const useUpdateContact = ({ onSuccess, kind }: UseUpdateContact) => {
	let mutation;
	if (kind === KindType.APPLICATION) {
		 mutation = useMutation(DEFAULT_MN, {
			mutationFn: (data: { applicationId: string; id: string; type: WhoIsClient }) =>
				api.application.updateContact(data),
			onSuccess,
		});
  }
	if (kind === KindType.PREAPPLICATION) {
		mutation = useMutation(DEFAULT_MN, {
		 mutationFn: (data: { applicationId: string; id: string; type: WhoIsClient }) =>
			 api.PreApplicationService.updateContact(data),
		 onSuccess,
	 });
 }
 if (kind === KindType.DEAL) {
	mutation = useMutation(DEFAULT_MN, {
	 mutationFn: (data: { applicationId: string; id: string; type: WhoIsClient }) =>
		 api.deals.updateContact(data),
	 onSuccess,
 });
}
	return {
		mutateAsync: mutation?.mutateAsync,
		mutate: mutation?.mutate,
		isLoading: mutation?.isLoading,
		isSuccess: mutation?.isSuccess,
	};
};
