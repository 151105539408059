import { UseFormRegister } from "react-hook-form";
import cn from "classnames";

import { AnyInputProps } from "../../../../types";

import styles from "./index.module.scss";

type Props = {
	register: UseFormRegister<any>;
	rows?: number;
	onChange?: (value: string) => void;
} & AnyInputProps;

export const Textarea = ({
	register,
	name,
	rows = 3,
	onChange,
	placeholder,
	className,
	loading,
	disabled,
	error,
	readOnly,
	required = false,
}: Props): JSX.Element => {
	if (loading) {
		return <div className={styles.loading} />;
	}

	return (
		<textarea
			rows={rows}
			placeholder={placeholder}
			className={cn(styles.textarea, className, {
				[styles.textareaError]: error,
			})}
			readOnly={readOnly}
			disabled={disabled}
			{...register(name, {
				required,
				setValueAs: (v) => {
					if (onChange) {
						onChange(v);
					}

					return v;
				},
			})}
		/>
	);
};
