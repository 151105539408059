import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { useDispatch } from "react-redux";
import { CreatingAuthAccession } from "../RegistrationPage/RegistrationDone/CreatingAuthAccession";
import { EnterCodeRegistrationPage } from "../RegistrationPage/EnterCode";
import { AcceptedInvitation } from "../RegistrationPage/RegistrationDone/AcceptedInvitation";
import { InvitePersonalDataForm } from "../RegistrationPage/InvitePersonalDataForm";
import { InviteNotFound } from "../RegistrationPage/InviteNotFound";
import { AuthLayout, LoadingRows, RegistrationAccessController } from "../components";
import { useLoadInviteByLink } from "../../../../service/api/queries";
import { useSelector } from "../../../../service/redux/useSelector";
import { cleanUp } from "../../../../service/redux/modules/registration-steps/reg-steps.action";
import { AuthGuard } from "../../../../service/router/AuthGuard";

type MatchParams = {
  inviteCode: string;
};

export const InvitePage = (): JSX.Element => {
  const {currentStep} = useSelector((state) => state.regSteps);
  const match = useRouteMatch<MatchParams>();
  const {inviteCode} = match.params;
  const {invite, isLoading, error, isFetching} = useLoadInviteByLink(inviteCode);
  const dispatch = useDispatch();

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(cleanUp());
    };
  }, []);

  if (error?.response?.data?.message === "Active link with this key not found!") {
    return (
      <AuthGuard onlyNotAuthorized>
        <AuthLayout>
          <InviteNotFound/>
        </AuthLayout>
      </AuthGuard>
    );
  }

  if (isLoading) {
    return (
      <AuthGuard onlyNotAuthorized>
        <AuthLayout>
          <LoadingRows/>
        </AuthLayout>
      </AuthGuard>
    );
  }

  return (
    <RegistrationAccessController
      forUnauthorized={
        <AuthLayout>
          {currentStep === "first-step" && invite && <InvitePersonalDataForm invite={invite}/>}
          {currentStep === "personal-data" && invite && <InvitePersonalDataForm invite={invite}/>}
          {currentStep === "sms-code" && <EnterCodeRegistrationPage invite={invite}/>}
          {currentStep === "done-auth-accession" && <CreatingAuthAccession/>}
        </AuthLayout>
      }
      forAuthorized={<AuthLayout>{currentStep === "done-invitation" && <AcceptedInvitation/>}</AuthLayout>}
    />
  );
};
