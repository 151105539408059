import { Dispatch, SetStateAction } from "react";
import { ApplicationFilterPayload, LeadStatusEnum, leadStatuses } from "../../../../../../../types";
import cn from "classnames";
import { ButtonsFuncReturnType } from "./types";
import { ReactComponent as TabletOkIcon } from "../../../../../../../assets/icons/tablet-ok.svg";
import { ReactComponent as TimerIcon } from "../../../../../../../assets/icons/timer.svg";
import { ReactComponent as CheckedIcon } from "../../../../../../../assets/icons/radio-selected.svg";
import styles from "../index.module.scss";

export const leadButtons = (
  curFilter: { status?: LeadStatusEnum[] },
  setCurFilter: Dispatch<SetStateAction<ApplicationFilterPayload | any>>,
  filterClick: (val: any) => void
): ButtonsFuncReturnType => {

  const handleSetFilter = (clickedStatus: LeadStatusEnum) => {
    filterClick(clickedStatus);
  };

  return [
    {
      classNames: cn(styles.button, {
        [ styles.selected ]: curFilter.status?.includes(LeadStatusEnum.in_progress),
      }),
      onClick: () => filterClick(LeadStatusEnum.in_progress),
      child: <TabletOkIcon/>,
      popoverText: leadStatuses.in_progress,
    },
    {
      classNames: cn(styles.button, styles.buttonSuccess, {
        [ styles.selected ]: curFilter.status?.includes(LeadStatusEnum.done),
      }),
      onClick: () => handleSetFilter(LeadStatusEnum.done),
      child: <CheckedIcon/>,
      popoverText: leadStatuses.done,
    },
    {
      classNames: cn(styles.button, styles.buttonPoop, styles.withUpDivider, {
        [ styles.selected ]: curFilter.status?.includes(LeadStatusEnum.deferred),
      }),
      onClick: () => handleSetFilter(LeadStatusEnum.deferred),
      child: <TimerIcon/>,
      popoverText: leadStatuses.deferred,
    },
  ];
};
