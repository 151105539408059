// Formatting phone `0 (000) 00-00-00`
export function formatPhone(phone: string): string {
	return phone.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5");
}

export const formatPhoneWithSpace = (string: string, firstNumber = "7"): string => {
	let text = string.replace(/\D+/g, "");
	if (text.length > 10) {
		text = text.substring(text.length - 10);
	}

	text = `${firstNumber + text}`.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g, "$1 ($2) $3 $4 $5");

	return text;
};

// Formatting code (add space after 3 characters)
export function formatCode(code: string): string {
	if (code.length > 3) {
		return code.replace(/(.{3})/g, "$1 ").trim();
	}

	return code;
}

export function formatNumbers(number?: string, digitsAfterComma = 2): string {
	if (!number) {
		return "";
	}
	const [int, float] = `${Number(number).toFixed(digitsAfterComma)}`.split(".");

	return `${int.replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}${Number(float) ? `.${float}` : ""}`;
}

// Formatting money (add space for convenient)
export function formatThousand(value: any): string {
	if (value !== 0 && !value) {
		return "";
	}

	return Number(value).toLocaleString().replace(/,/g, " ");
}

export const thousandsFormatter = (num: number, digits: number): string => {
	const lookup = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: " тыс." },
		{ value: 1e6, symbol: " млн." },
		{ value: 1e9, symbol: " млрд." },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	const item = lookup
		.slice()
		.reverse()
		.find((n) => num >= n.value);

	return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};
