import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/create-deal/DEFAULT_MN";

type UseCreateDeal = {
  onSuccess?: () => void
}

export const useCreateDeal= ({onSuccess}:UseCreateDeal) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (id: string) => api.application.createDeal(id),
    onSuccess
  })

  return {
    data: mutation.data?.data,
    mutate: mutation.mutate,
    mutateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  }
}