import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { ApplyServiceModal } from "./ApplyServiceModal";

type Props = {
  applicationId: string
}

type Return = {
  openApplyServiceModal: () => void;
};


export const useApplyServiceModal = (
  {
    applicationId
  }: Props): Return => {
  const dispatch = useDispatch();

  const openApplyServiceModal = () => {
    dispatch(openModal({
      body: <ApplyServiceModal applicationId={applicationId}/>,
      width: 800
    }))
  }

  return {
    openApplyServiceModal
  }
}