import { Button, Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { TaskForm } from "../index";
import { useCreateForm, useSubmitForm } from "./helpers";

import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { KindType } from "../../../types";

type Props = {
	applicationId?: string;
	onCreate?: (data: any) => void;
	refetch?: () => void;
	kind: KindType
};

export const CreateTaskModal = ({ applicationId, onCreate, refetch, kind }: Props): JSX.Element => {
	const { register, setValue, getValues, control, errors, handleSubmit } = useCreateForm();
	const [disabled, setDisabled] = useState(false)

	const { isLoading, onSubmit } = useSubmitForm({
		applicationId,
		onCreate,
		refetch,
		kind
	});

	useEffect(() => {
		if (!!(errors.executor?.message && errors?.executor?.message?.length > 0)) {
			setDisabled(true)
		}
	}, [errors.executor?.message])

	return (
		<div className={styles.root}>
			<Loader height={250} style={{ display: isLoading ? "block" : "none" }} />
			<form onSubmit={handleSubmit(onSubmit)} style={{ display: isLoading ? "none" : "block" }}>
				<TaskForm
					register={register}
					setValue={setValue}
					getValues={getValues}
					control={control}
					errors={errors}
					isCreating
				/>
				<div className={styles.button}>
					<Button type="submit"  disabled={disabled}>
						Создать задачу
					</Button>
				</div>
			</form>
		</div>
	);
};
