import { Client } from "../../Client";
import {
  MyShowsViewsResponse,
  PlatformDescriptionIdData, ShowAndViewsTabs,
  ShowsApplicationCardResponse,
  ShowsOrViews,
  ShowsViewsCancelPayload,
  ShowsViewsCountPayload,
  ShowsViewsFinishPayload,
  ShowsViewsListResponse,
  ShowsViewsStartPayload,
  ShowsViewsUpdataPayload,
} from "../../../../types";

export const ShowsViewsService = {
  list: (applicationId: string, tab: ShowAndViewsTabs, type: ShowsOrViews) =>
    Client.get<ShowsViewsListResponse>(`/show-and-views/${type}/${applicationId}?tab=${tab}`),

  myList: (date: Date) => Client.get<MyShowsViewsResponse[]>(`/show-and-views/my?day=${date}`),

  newDate: (payload: { id: string; plannedDate: Date }) => {
    const {id, plannedDate} = payload;

    return Client.patch(`/show-and-views/date/${id}`, {plannedDate});
  },

  update: (payload: ShowsViewsUpdataPayload) => {
    const {id, type} = payload;
    const requestPayload = {
      clientObjectId: payload.clientObjectId,
      sellerContactId: payload.sellerContactId,
      sellerAgentContactId: payload.sellerAgentContactId,
      buyerContactId: payload.buyerContactId,
      buyerAgentContactId: payload.buyerAgentContactId,
      plannedDate: payload.plannedDate,
    };

    return Client.put(`/show-and-views/${type}/${id}`, requestPayload);
  },

  cancel: (id: string) => {
    return Client.post(`/show-and-views/cancel/${id}`);
  },

  start: (payload: ShowsViewsStartPayload & { clientObjectApplicationId: string }) => {
    const {type, applicationId, clientObjectApplicationId} = payload;
    const requestPayload = {
      clientObjectApplicationId,
      sellerContactId: payload.sellerContactId,
      sellerAgentContactId: payload.sellerAgentContactId,
      buyerContactId: payload.buyerContactId,
      buyerAgentContactId: payload.buyerAgentContactId,
      plannedDate: payload.plannedDate,
    };

    return Client.post<PlatformDescriptionIdData>(`/show-and-views/${type}/${applicationId}`, requestPayload);
  },

  finish: (payload: ShowsViewsFinishPayload) => {
    const {id} = payload;
    const requestPayload = {
      offer: payload.offer,
      sellerNeedsHelpToAnotherNewObject: payload.sellerNeedsHelpToAnotherNewObject,
      buyerNeedsHelpToSellHisObject: payload.buyerNeedsHelpToSellHisObject,
      sellerNeedsHelpToSellHisObject: payload.sellerNeedsHelpToSellHisObject,
    };

    return Client.post(`/show-and-views/finish/${id}`, requestPayload);
  },

  count: (payload: ShowsViewsCountPayload) => {
    const {applicationId, type} = payload;

    return Client.get<ShowsApplicationCardResponse>(`/show-and-views/${type}/${applicationId}/count`);
  },

  createShow: (applicationId, plannedDate) => {
    const payload = {
      plannedDate
    }
    return Client.post(`/show-and-views/show/${applicationId}`, payload)
  },
  createView: (applicationId, plannedDate, clientObjectId: string | undefined, parserId: string | undefined) => {
    const payload = {
      plannedDate,
      parserObjectId: parserId,
      clientObjectApplicationId: clientObjectId
    }
    return Client.post(`/show-and-views/view/${applicationId}`, payload)
  },

  pinSellerContact: (id: string, contactId: string) => Client.put(`show-and-views/view/${id}/contact/${contactId}`),
  pinSellerAgentContact: (id: string, contactId: string) => Client.put(`show-and-views/view/${id}/agentContact/${contactId}`),
  pinBuyerContact: (id: string, contactId: string) => Client.put(`show-and-views/show/${id}/contact/${contactId}`),
  pinBuyerAgentContact: (id: string, contactId: string) => Client.put(`show-and-views/show/${id}/agentContact/${contactId}`),

  makeFinal: (id: string) => Client.put(`show-and-views/makeFinal/${id}`),
};
