import { useEffect } from "react";
import { AxiosError } from "axios";

import { toastError, TrafficLeadSkeleton } from "../../UI";
import { LeadInfo } from "./components";
import { useReadLead } from "../../../service/api/queries";
import { Lead } from "../../../types";

type Props = {
	id: string;
	refetch?: () => void;
};

export const LeadCard = ({ id, refetch }: Props): JSX.Element => {
	const onError = (err: AxiosError) => {
		if (err?.response?.data?.message === "This lead is locked!") {
			toastError({ text: "Лид заблокирован для просмотра" });
		} else {
			toastError({ text: "Не удалось открыть лид" });
		}
	};

	const { lead, refetch: refetchLead, remove, isFetching } = useReadLead({ id, onError });

	const refetchAll = () => {
		refetchLead();
		if (refetch) {
			refetch();
		}
	};

	useEffect(() => () => remove(), []);

	return <>{isFetching ? <TrafficLeadSkeleton /> : <LeadInfo key={lead?.id} lead={lead as Lead} refetch={refetchAll} />}</>;
};
