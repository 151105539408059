import styles from "./index.module.scss"
import { useFireEmployee } from "../../../../../service/api/mutations";
import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";
import { useLoadOffices, useLoadPositions } from "../../../../../service/api/queries";
import { useForm } from "react-hook-form";
import { prepareOffices } from "../../../../../utils/transformation";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { MultiLayerSelect } from "../../../../UI";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";
import { closeDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";

type Props = {
  id: string
  refetch: () => void
}

export const FireModal = ({id, refetch}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const {mutate} = useFireEmployee({userId: id, onSuccess: () => {
      dispatch(closeModal())
      dispatch(closeDrawer())
      refetch()
    }})
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const {offices} = useLoadOffices({});
  const {getPositionName} = useLoadPositions({});

  const {register, setValue} = useForm();

  const options = useMemo(() => prepareOffices(offices, getPositionName), [offices.length]);
  const handleSetResponsible = () => {
    mutate(selectedUser[ 0 ] ?? "");
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Уволить сотрудника
      </div>
      <div>

      </div>
      <div className={styles.container}>
        <MultiLayerSelect
          name="newResponsibles"
          alwaysOpen
          isSearchable
          options={options}
          register={register}
          setValue={setValue}
          onChange={setSelectedUser}
          selectOne
          classNames={{
            optionNotSelected: styles.option,
          }}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={() => dispatch(closeModal())} variant="outlined">
          Отмена
        </Button>
        <Button disabled={!selectedUser.length} onClick={handleSetResponsible}>
          Уволить
        </Button>
      </div>
    </div>
  )
}