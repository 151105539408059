import { useQuery } from "react-query";
import { api } from "../../api";
import { RealEstate } from "../../../../types";

const DEFAULT_MN = "sdelka/DEFAULT_MN1";

type Props = {
  id: string
  onSuccess?: () => void;
  onError?: () => void;
};

type Return = {
  isLoading: boolean;
  isFetching: boolean;
  doubles: RealEstate[]
  total: string
}

type UseSetObjectDoublesFromAgency = (props: Props) => Return

export const UseSetObjectDoublesFromAgency: UseSetObjectDoublesFromAgency = (
  {
    id,
    onError,
    onSuccess,
  }
) => {
  const loadDoubles = () => api.realEstate.getDoublesFromAgency(id)
  const {data, refetch, remove, isLoading, isFetching} = useQuery(
    DEFAULT_MN,
    loadDoubles,
    {
      onError,
      onSuccess,
    }
  )

  return {
    doubles: data?.data.data,
    isLoading,
    isFetching,
    refetch,
    remove,
    total: data?.data.total
  }
}