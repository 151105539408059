import { useMutation, useQueryClient } from "react-query";
import { UseFormSetError } from "react-hook-form";
import { AxiosError } from "axios";

import { PersonalDataFormType } from "../../../../types";
import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";

const DEFAULT_MN = "sdelka/registration/v2";

type UseRegistration = {
	onSuccess?: () => void;
	setError?: UseFormSetError<any>;
};

export const useCheckDataForm = ({ onSuccess, setError }: UseRegistration) => {
	
	const queryClient = useQueryClient();
	const onError = (error: AxiosError) => {
		const message = axiosError({ error });

		if (message) {
			if (message.includes("Account with the same 'phone' already exist") && setError) {
				setError("phone", { message: "Аккаунт с таким телефоном существует" });
			} else if (message.includes("password too weak") && setError) {
				setError("password", { message: "Слишком простой пароль" });
			} else if (message.includes("phone must be a valid phone number") && setError) {
				setError("phone", { message: "Невалидный телефон" });
			} else if (message.includes("name must be longer than or equal to 2 characters") && setError) {
				setError("name", { message: "Имя должно иметь больше 2 символов" });
			}
		} else {
			window.alert(`Неизвестная ошибка ${message?.toString()}`);
		}
	};

	const mutation = useMutation(DEFAULT_MN, {
		
		mutationFn: (data: PersonalDataFormType) => api.auth.checkPersonalDataForm(data),
		onSuccess: () => {
			onSuccess
		},
		onError: (error) => {
			console.log("useRegistration - Mutation Error", error);
			onError;
		},
	});
	
	const getLastMutationCache = () => {
		const allMutations = queryClient.getMutationCache().findAll({ mutationKey: DEFAULT_MN });
		return allMutations[allMutations.length - 1];
	};

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
		getLastMutationCache,
		onError,
		data: mutation.data,
		error: mutation.error
	};
};
