import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { PolygonGeometry, ZoomControl } from "react-yandex-maps";
import cn from "classnames";

import { Canvas } from "./Canvas/Canvas";
import { Points } from "./Clusters/Points";
import { DrawnPolygons } from "./DrawnPolygons/DrawnPolygons";
import { Map } from "./Map";
import { MapContextType } from "./MapContext/MapContext";
import { MapHandlers } from "./MapHandlers/MapHandlers";
import { PointsInCluster } from "./PointsInCluster/PointsInCluster";
import { Mark } from "./Mark";
import { useSelectPoint } from "./helpers";
import { convertCoords } from "./map-utils/utils";
import { SelectedPoint } from "./SelectedPoint";
import { ClusterType, PointType, RealEstateMapProps } from "../../../types";

import styles from "./index.module.scss";

/**
 * Компонент карты. Занимает 100% ширины и высоты от родителя.
 */
export const RealEstateMap = ({
	clusters,
	points,
	mark,
	onMapChange,
	onMapClick,
	onDraw,
	onRemovePolygon,
	selectPointWithObjects,
	getContactPhone,
	options,
	isParser,
	defaultPolygons,
	priceTypeAlternative
}: RealEstateMapProps): JSX.Element => {
	const [priceType, setPriceType] = useState(priceTypeAlternative || "price");
	const [activeHandlers, setActiveHandler] = useState(false);
	const [mapInstance, setMapInstance] = useState<any>();
	const [polyPoints, setPolyPoints] = useState<PolygonGeometry[] | undefined>(defaultPolygons ?? []);
	const [showCanvas, setShowCanvas] = useState(false);
	const mapContainerRef = useRef<HTMLDivElement>(null);
	const { push } = useHistory();
	const [point, setPoint] = useState(mark)

	useEffect(() => {
		setPoint(mark)
		setPolyPoints(defaultPolygons)
	}, [defaultPolygons, mark])

	// Update center of map
	useEffect(() => {
		if (
			options?.updateCenterIfChanged &&
			mapInstance &&
			options?.center &&
			options?.center[0] &&
			options?.center[1]
		) {
			const zoom = mapInstance.getZoom();
			mapInstance.setCenter([options?.center[0], options?.center[1]], zoom, {
				checkZoomRange: true,
			});
		}
	}, [mapInstance, options?.center?.[0], options?.center?.[1], options?.zoom]);

	const onDrawHandler = () => {
		const { behaviors } = mapInstance;
		setActiveHandler(!activeHandlers);

		if (behaviors.get("drag").isEnabled()) {
			behaviors.disable("drag");
			setShowCanvas(true);
		} else {
			behaviors.enable("drag");
			setShowCanvas(false);
		}
	};

	const sendDrawnArea = (coordinates: number[][]) => {
		const zoom = mapInstance.getZoom();

		if (typeof onDraw === "function") {
			onDraw(coordinates, zoom);
		}
	};

	const coordsHandler = (coordinates: number[][]) => {
		const bounds = mapInstance.getBounds();
		const composeConvertedCoords = convertCoords(bounds);
		const composedCoordinates = coordinates.map(composeConvertedCoords);
		setPolyPoints((lastCoords) => [...lastCoords as any, [composedCoordinates]]);
		sendDrawnArea(composedCoordinates);
		onDrawHandler();
	};

	const onRemoveHandler = () => {
		if (onRemovePolygon) {
			onRemovePolygon();
		}

		setPolyPoints([]);
	};

	const onGetMapInstances = (value: MapContextType) => {
		setMapInstance(value.mapInstance);
	};

	const onSelect = (value: string) => setPriceType(value);

	const clickOnTitle = (id?: string) => {
		if (id) {
			if (isParser) {
				push(`/real-estate/parser/${id}`);
			} else {
				push(`/real-estate/${id}?mode=view`);
			}
		}
	};

	const { onSelectPoint, onUnselectPoint, selectedPoint } = useSelectPoint();

	return (
		<div className={cn(styles.root, "custom-balloon")}>
			{!options?.withoutHandlers && (
				<div
					className={cn(styles.handlersContainer, {
						[styles.handlersContainerSecond]: options?.order === "map-first",
					})}
				>
					<MapHandlers
						polygonCount={polyPoints?.length ?? 0}
						onDrawHandler={onDrawHandler}
						onRemoveHandler={onRemoveHandler}
						activeHandlers={activeHandlers}
						onSelect={onSelect}
					/>
				</div>
			)}

			<div
				ref={mapContainerRef}
				className={cn(styles.mapContainer, {
					[styles.blackAndWhite]: !options?.colored,
					[styles.mapContainerFirst]: options?.order === "map-first",
				})}
			>
				<Map
					onMapChange={onMapChange}
					onMapClick={onMapClick}
					getMapInstances={onGetMapInstances}
					options={{
						center: options?.center,
						zoom: options?.zoom,
					}}
				>
					{/* @ts-ignore */}
					<ZoomControl />

					<PointsInCluster
						points={points}
						getContactPhone={getContactPhone}
						onPointClick={onSelectPoint}
						selectPointWithObjects={selectPointWithObjects}
						clickOnTitle={clickOnTitle}
						priceType={priceType}
					/>

					{!selectedPoint && <Points clusters={clusters} />}

					{selectedPoint && (
						<SelectedPoint
							point={selectedPoint}
							getContactPhone={getContactPhone}
							onUnselectPoint={onUnselectPoint}
							selectPointWithObjects={selectPointWithObjects}
							clickOnTitle={clickOnTitle}
							priceType={priceType}
						/>
					)}
					{/* @ts-ignore */}
					{mark && mark.lat && mark.lon ? <Mark mark={point} /> : null}

					<DrawnPolygons polyPoints={polyPoints ?? []} />
				</Map>
				{showCanvas && <Canvas coordsHandler={coordsHandler} />}
			</div>
		</div>
	);
};

export type { RealEstateMapProps, ClusterType, PointType };
