import { CompanyPublicData } from "../../../../types";

export const SET_CURRENT_STEP = "sdelka/registration-steps/SET_CURRENT_STEP";
export const SET_VERIFICATION_CODE = "sdelka/registration-steps/SET_VERIFICATION_CODE";

export const SET_INVITE_CODE = "sdelka/registration-steps/SET_INVITE_CODE";
export const SET_SAW_INVITES = "sdelka/registration-steps/SET_SAW_INVITES";

export const SET_SHOW_COMPANY_DATA = "sdelka/registration-steps/SET_SHOW_COMPANY_DATA";
export const SET_COMPANY_DATA = "sdelka/registration-steps/SET_COMPANY_DATA";
export const SET_COMPANY_NAME = "sdelka/registration-steps/SET_COMPANY_NAME";

export const SET_REGISTRATION_DATA = "sdelka/registration-steps/SET_REGISTRATION_DATA";

export const SET_JWT_TOKEN = "sdelka/registration-steps/SET_JWT_TOKEN";

export const CLEAN_UP = "sdelka/registration-steps/CLEAN_UP";

export type Steps =
	| "first-step"
	| "personal-data"
	| "sms-code"
	| "has-active-invites"
	| "choose-role"
	| "done-invitation"
	| "done-new-company"
	| "done-auth-accession"
	| "finish-registration"
	| "done-single-realtor";

export type RegistrationData = {
	name?: string;
	phone?: string;
	token?: string;
};
export type RegistrationDataV2 = {
	phone?: string;
	token?: string;
};
export type RegStepsAction =
	| { type: typeof SET_CURRENT_STEP; step: Steps }
	| { type: typeof SET_VERIFICATION_CODE; code: string }
	| { type: typeof SET_SHOW_COMPANY_DATA; showData: boolean }
	| { type: typeof SET_COMPANY_DATA; company?: CompanyPublicData }
	| { type: typeof SET_REGISTRATION_DATA; data: RegistrationDataV2 }
	| { type: typeof SET_INVITE_CODE; code: string }
	| { type: typeof SET_SAW_INVITES; saw: boolean }
	| { type: typeof SET_COMPANY_NAME; name: string }
	| { type: typeof SET_JWT_TOKEN; token: string }
	| { type: typeof CLEAN_UP };

export const setStep = (step: Steps): RegStepsAction => ({
	type: SET_CURRENT_STEP,
	step,
});

export const setVerificationCode = (code: string): RegStepsAction => ({
	type: SET_VERIFICATION_CODE,
	code,
});

export const setShowCompany = (showData: boolean): RegStepsAction => ({
	type: SET_SHOW_COMPANY_DATA,
	showData,
});

export const setCompanyData = (company?: CompanyPublicData): RegStepsAction => ({
	type: SET_COMPANY_DATA,
	company,
});

export const setRegistrationData = (data: RegistrationDataV2): RegStepsAction => ({
	type: SET_REGISTRATION_DATA,
	data,
});

export const setInviteCode = (code: string): RegStepsAction => ({
	type: SET_INVITE_CODE,
	code,
});

export const setSawInvites = (saw: boolean): RegStepsAction => ({
	type: SET_SAW_INVITES,
	saw,
});

export const setCompanyName = (name: string): RegStepsAction => ({
	type: SET_COMPANY_NAME,
	name,
});

export const setJwtToken = (token: string): RegStepsAction => ({
	type: SET_JWT_TOKEN,
	token,
});

export const cleanUp = (): RegStepsAction => ({
	type: CLEAN_UP,
});
