import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { createNumberMask } from "text-mask-addons";

import { InfoRow, TransparentInput } from "../../../index";

type Props = {
	register: UseFormRegister<any>;
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	inputName: string;
	disabled?: boolean;
	handleOnChange: (value: string | number) => void;
};

export const Number = ({
	register,
	control,
	setValue,
	getValues,
	inputName,
	disabled,
	handleOnChange,
}: Props): JSX.Element => {
	const debounced = useDebouncedCallback(handleOnChange, 500);

	const inputValue = useWatch({
		control,
		name: inputName,
		defaultValue: getValues(inputName),
	});

	const handleOnChangeWithDebounce = (value: number | string) => {
		debounced(value);
	};

	return (
		<InfoRow label="Введите сумму">
			<TransparentInput
				register={register}
				placeholder="Указать"
				name={inputName || "1"}
				value={inputValue}
				onChange={handleOnChangeWithDebounce}
				mask={createNumberMask({
					prefix: "",
					thousandsSeparatorSymbol: " ",
				})}
				disabled={disabled}
				setValueForMask={setValue}
			/>
		</InfoRow>
	);
};
