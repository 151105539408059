import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { AssignShowModal } from "./assign-show-modal/AssignShowModal"
import { useCreateShow } from "../../../../../../service/api/mutations/shows-views/useCreateShow";
import { useCreateView } from "../../../../../../service/api/mutations/shows-views/useCreateView";
import { AssignViewModal } from "./assign-view-modal/AssignViewModal";
import styles from "./index.module.scss"


type Props = {
  isViews: boolean
  onSuccess: () => void
  applicationId?: string
  fromObject?: boolean
  parserObjectId?: string;
  clientObjectApplicationId?: string;
}

type Return = {
  openAssignShowAndViewModal: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type UseOpenAssignShowAndViewModal = (props: Props) => Return

export const UseOpenAssignShowAndViewModal: UseOpenAssignShowAndViewModal = (
  {
    isViews,
    onSuccess,
    applicationId,
    parserObjectId,
    clientObjectApplicationId,
    fromObject
  }) => {

  const {mutate: mutateShow} = useCreateShow({onSuccess})
  const {mutate: mutateView} = useCreateView({onSuccess})
  
  const modal = isViews 
    ? <AssignViewModal
      onCreate={mutateView}
      parserObjectId={parserObjectId}
      clientObjectApplicationId={clientObjectApplicationId}
      fromObject={fromObject}
      applicationId={applicationId}
    />
    : <AssignShowModal
      onCreate={mutateShow}
      applicationId={applicationId ?? ""}
    />
  
  const width = isViews ? 800 : 660
  const dispatch = useDispatch()

  const openAssignShowAndViewModal = () => {
    dispatch(
      openModal({
        width,
        contentClass: styles.root,
        classNames:{
          contentBody: styles.root
        },
        body:
          modal
      })
    )
  }
  return {
    openAssignShowAndViewModal
  }
}