import { CSSProperties } from "react";
import cn from "classnames";

import { StagesType } from "../../../../../../types";
import styles from "../../index.module.scss";

type Props = {
	getStyle: (stage: StagesType) => CSSProperties;
	currentStage: number;
	isGrey: boolean;
	selectedTab: number;
	displayStages: StagesType[];
	handleStageItemClick: (stage: number) => void;
};

export const Tabs = ({
	getStyle,
	currentStage,
	isGrey,
	selectedTab,
	displayStages,
	handleStageItemClick,
}: Props): JSX.Element => (
	<div className={styles.stagesContainer}>
		{displayStages?.map((stage, index) => (
			<div
				// eslint-disable-next-line react/no-array-index-key
				key={index}
				onClick={() => handleStageItemClick(stage.stageNumber)}
				style={getStyle(stage)}
				className={cn(styles.stageItem, {
					[styles.stageItemGrey]: isGrey,
					[styles.stageItemCurrent]: selectedTab + 1 === stage?.stageNumber,
					[styles.stageItemPrev]: stage?.stageNumber < currentStage && stage?.stageNumber !== selectedTab + 1,
					[styles.stageItemNext]: stage?.stageNumber > currentStage && stage?.stageNumber === selectedTab + 1,
				})}
			>
				{stage.unknown && <div className={styles.stageItemBlur} />}

				<span style={{color: `${stage.textColor}`}}>{`${stage?.stageNumber} этап`}</span>
				<p style={{color: `${stage.textColor}`}}>{stage.title}</p>
			</div>
		))}
	</div>
);
