import styles from "./index.module.scss";
import { RealEstateMap, SidebarLayout } from "../../UI";
import { Cart } from "./cart";
import { useRef, useState } from "react";
import { useQueryParams } from "../../../utils/hooks";
import cn from "classnames";
import { ServicesTabs } from "../../UI/services-tabs/ServicesTabs";
import { NoData, Pagination } from "@sdelka_crm/sdelka-crm-component-library";
import { useServices } from "./helpers/useServices";
import { UsersTable } from "./tables";
import { OfficesTable } from "./tables/offices-table";
import { RealEstateFilter, RealEstateListTab } from "../../../types";
import { ObjectsMap } from "../RealEstateList/components";
import { selectCurrentAccount, useSelect } from "../../../service/redux/selectors";
import { PointType } from "../../UI/Map/Map";
import { ProductsTable } from "./tables/products-table";
import { useLoadCart } from "../../../service/api/queries";

type TabType = "PRODUCTS" | "OFFICES" | "EMPLOYERS";

enum TabTypeEnum {
  PRODUCTS = "PRODUCTS",
  OFFICES = "OFFICES",
  EMPLOYERS = "EMPLOYERS",
}

type QueryParams = {
  officeId: string;
  productId: string;
  employeeId: string;
  page: number;
  filter: TabTypeEnum;
  tab: TabTypeEnum;
};

export const Services = (): React.ReactElement => {
  const [servicesFilterTypes, setServicesFilterTypes] = useState<string[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {queryParams, changeQueryParams} = useQueryParams<QueryParams>([
    {name: "officeId"},
    {name: "productId"},
    {name: "page", type: "number"},
    {name: "filter"},
    {name: "employeeId", type: "string"},
    {name: "tab", type: "string"},
  ]);

  const currentTab = queryParams.tab ?? TabTypeEnum.PRODUCTS;

  const {
    isLoading,
    list,
    take,
    setTake,
    page,
    setPage,
    sort,
    setSort,
    total,
    refetch,
    isFetching
    //@ts-ignore
  } = useServices({tab: currentTab, defaultPage: 1})


  const [centerXY, setCenterXY] = useState<[number | typeof NaN, number | typeof NaN]>([NaN, NaN]);
  const { currentAccount } = useSelect(selectCurrentAccount);
  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const mapCenter = currentAccount?.company?.mapCenter?.split(",")?.map((cord) => Number(cord)) as [
    number,
    number
  ];
  const getDefaultCenter = (initialCenter?: [number, number]): [number, number] => {
    if (!initialCenter || !initialCenter[0] || !initialCenter[1]) {
      return [59.94506601286178, 30.31705281156423];
    }
    return [initialCenter[0], initialCenter[1]];
  };

  const pointsForMap = list ? list.map(elem => JSON.parse(JSON.stringify({
    objects: [
      {
        id: "",
        price: 0,
        pricePreUnit: 0,
        unut: elem.addressLabel,
      }
    ],
    lat: elem.location?.coordinates?.[0] ?? 59.94506601286178,
    lon: elem.location?.coordinates?.[1] ?? 30.31705281156423,
  }))) : []

  const [actualFilter, setActualFilter] = useState(queryParams.filter)
  const handlePageChange = (nextPage: number) => {
    if (setPage) {
      setPage(nextPage);
    }
    changeQueryParams([{ name: "page", newValue: nextPage }]);
  };
  const changeActualFilter = (payload: TabTypeEnum) => {
    handlePageChange(1);
    setActualFilter(payload);
  };

  const setMapFilter = (value) => {
    setActualFilter(value)
    // setFilter(value)
  }

  const {refetch: refetchCart} = useLoadCart()
  const handleRefetch = () => {
    refetch()
    refetchCart()
  }

  return (
    <SidebarLayout
      services
      // fullSize
      // hat={{
      //   // element: currentFilter,
      //   elementHeight: 50,
      // }}
      rightColumn={
        <Cart refetchTable={refetch}/>
      }
      withScrollBtn
    >
      <div className={styles.root}>
        <div ref={wrapperRef} className={styles.listWrapper}>
          <div
            className={cn(styles.list, {
              // [styles.listWithNoFilters]: !objectsFilterTypes?.length,
            })}
          >
            <ServicesTabs/>
            {currentTab === "EMPLOYERS" &&
              <div className={styles.tabContent}>
                <div className={styles.tableWithPagination}>
                  {!isLoading && !list
                    ? <NoData onRefetch={refetch}/>
                    : <>
                      <UsersTable
                        usersList={list}
                        isLoading={isFetching}
                        refetch={handleRefetch}
                      />
                      {/*{!isFetching && total && total > 0 && (*/}
                      {/*  <Pagination*/}
                      {/*    classNames={{root: styles.pagination}}*/}
                      {/*    totalCount={total || 0}*/}
                      {/*    pageSize={take}*/}
                      {/*    currentPage={page || 0}*/}
                      {/*    onPageChange={(page: number) => setPage ? setPage(page) : () => {*/}
                      {/*    }}*/}
                      {/*    isLoading={isFetching}*/}
                      {/*  />*/}
                      {/*)}*/}
                    </>
                  }
                </div>
              </div>
            }
            {currentTab === "OFFICES" &&
              <div className={styles.tabContent}>
                <div className={styles.tableWithPagination}>
                  {!isLoading && !list
                    ? <NoData onRefetch={refetch}/>
                    : <>
                      <OfficesTable
                        officesList={list}
                        isLoading={isFetching}
                        refetch={refetch}
                      />
                      {!isFetching && total && total > 0 && (
                        <Pagination
                          classNames={{root: styles.pagination}}
                          totalCount={total || 0}
                          pageSize={take}
                          currentPage={page || 0}
                          onPageChange={(page: number) => setPage ? setPage(page) : () => {
                          }}
                          isLoading={isFetching}
                        />
                      )}
                    </>
                  }
                </div>
              </div>
            }
            {currentTab === "PRODUCTS" &&
              <div className={styles.tabContent}>
                <div className={styles.tableWithPagination}>
                  {!isLoading && !list
                    ? <NoData onRefetch={refetch}/>
                    : <>
                      <ProductsTable
                        productsList={list}
                        isLoading={isFetching}
                        refetch={handleRefetch}
                      />
                      {/*{!isFetching && total && total > 0 && (*/}
                      {/*  <Pagination*/}
                      {/*    classNames={{root: styles.pagination}}*/}
                      {/*    totalCount={total || 0}*/}
                      {/*    pageSize={take}*/}
                      {/*    currentPage={page || 0}*/}
                      {/*    onPageChange={(page: number) => setPage ? setPage(page) : () => {*/}
                      {/*    }}*/}
                      {/*    isLoading={isFetching}*/}
                      {/*  />*/}
                      {/*)}*/}
                    </>
                  }
                </div>
              </div>
            }
          </div>
        </div>
        {currentTab === "OFFICES" &&
          <div className={styles.map}>
          <RealEstateMap
            points={pointsForMap}
            priceTypeAlternative="office"
            options={{
              order: "map-first",
              center: mapCenter ?? getDefaultCenter(centerXY),
              zoom: mapZoom,
              updateCenterIfChanged: true,
            }}
          />
        </div>}
      </div>

    </SidebarLayout>
  )
}