const localhostPublicUrl = process.env.REACT_APP_PUBLIC_URL_LOCALHOST as string;
const devPublicUrl = process.env.REACT_APP_PUBLIC_URL_DEV as string;
const stagingPublicUrl = process.env.REACT_APP_PUBLIC_URL_STAGING as string;
const prodPublicUrl = process.env.REACT_APP_PUBLIC_URL_PROD as string;

export const getPublicHost = (): string => {
	const { host } = window.location;

	const isLocalhost = host.includes("localhost");

	if (isLocalhost) {
		return localhostPublicUrl;
	}
	const isStagingHost = host.includes("staging");

	if (isStagingHost) {
		return stagingPublicUrl;
	}
	const isProdHost = host.includes("crm");

	if (isProdHost) {
		return prodPublicUrl;
	}

	return devPublicUrl;
};
