import { useQuery } from "react-query";
import { ShowAndViewsTabs, ShowsOrViews } from "../../../../types";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/shows-views/load-shows-views/DEFAULT_QN";

type UseLoadShowsOrViews = {
	applicationId: string;
	tab?: ShowAndViewsTabs;
	type: ShowsOrViews;
};

export const useLoadShowsOrViews = ({ applicationId, tab = ShowAndViewsTabs.planned, type }: UseLoadShowsOrViews) => {
	const loadViews = () => api.showsViews.list(applicationId, tab, type);

	const { data, refetch, remove, isLoading, isFetched } = useQuery([DEFAULT_QN, tab], loadViews);

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		isFetched,
	};
};
