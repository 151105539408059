import { RealEstateFilter, RealEstateListTab } from "../../../../../../../types";
import { UseFormRegister, UseFormSetValue, Control, UseFormGetValues } from "react-hook-form";
import { Dispatch, SetStateAction } from "react";

import {
	ApartmentOptional,
	RoomShareOptional,
	HouseCottageOptional,
} from "./ObjectListOptionalFiltersComponents";
import { objectFilters } from "../../../../../../../utils/clientObjects";

type Props = {
	type: string[];
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	filter: RealEstateFilter;
	setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
	globalFilter: RealEstateFilter;
	control: Control<any>;
	getValues: UseFormGetValues<any>;
	tab: RealEstateListTab;
};

// Object optional filters. Depends on selected object type.
export const ObjectListOptionalFilters = ({
	type,
	register,
	setValue,
	filter,
	setFilter,
	globalFilter,
	control,
	getValues,
	tab,
}: Props): JSX.Element => {
	if (type.includes("Квартира")) {
		return (
			<ApartmentOptional
				control={control}
				register={register}
				setValue={setValue}
				getValues={getValues}
				setFilter={setFilter}
				filter={filter}
				globalFilter={globalFilter}
				roomsOptions={objectFilters.flatRooms}
				tab={tab}
			/>
		);
	}

	if (type.includes("Комната")) {
		return (
			<RoomShareOptional
				control={control}
				register={register}
				setValue={setValue}
				getValues={getValues}
				setFilter={setFilter}
				filter={filter}
				globalFilter={globalFilter}
				flatTypesOptions={objectFilters.flatTypes}
				tab={tab}
			/>
		);
	}

	return (
		<HouseCottageOptional
			type={type}
			control={control}
			register={register}
			setValue={setValue}
			getValues={getValues}
			setFilter={setFilter}
			filter={filter}
			globalFilter={globalFilter}
			squareOptions={objectFilters.square}
			materialOptions={objectFilters.material}
			tab={tab}
		/>
	);
};
