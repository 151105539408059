import { useDispatch } from "react-redux";

import { PersonalCard } from "../index";
import { openDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { PersonalCardWrapper } from "../components/wrapper/PersonalCardWrapper";

type Return = {
	openProfileDrawer: (userId?: string) => void;
};

type Props = {
	onClose?: () => void;
	refetch?: () => void;
};

type UseOpenProfileCard = (props: Props) => Return;

export const useOpenProfileCard: UseOpenProfileCard = ({ onClose, refetch }) => {
	const dispatch = useDispatch();

	const openProfileDrawer = (userId?: string) => {
		dispatch(
			openDrawer({
				children: <PersonalCardWrapper userId={userId} refetch={refetch} />,
				width: 800,
				onClose,
			})
		);
	};

	return {
		openProfileDrawer,
	};
};
