import {
  FindAddressDetailed,
  IUserEditFormRequest,
  IUserEditFormResponse,
  Office,
  UserAccountData,
  userStatuses
} from "../../../../../types";
import { useEffect, useState } from "react";
import styles from "./index.module.scss"
import { ReactComponent as PenIcon } from "../../../../../assets/icons/edit.svg";
import { ReactComponent as Avatar } from "../../../../../assets/icons/office-briefcase-svgrepo-com 1.svg";
import { formatPhone } from "../../../../../utils/formatters";
import { useDispatch } from "react-redux";
import { initialize } from "../../../../../service/redux/modules/auth/auth.thunk";
import { useFindAddressDetailed, useOfficeUpdate, useUpdateUserCard } from "../../../../../service/api/mutations";
import { useForm, useWatch } from "react-hook-form";
import { InfoRow, Select, TransparentInput } from "../../../../UI";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { Photo } from "./Photo";
import { parsePhone } from "../../../../../utils/parsers";
import { setAddress, setAllowChangeByMap } from "../../../../../service/redux/modules/object-edit/object-edit.action";
import { useDebouncedCallback } from "use-debounce";
import {
  getMask
} from "../../../../UI/RealEstate/RealEstateForm/ObjectEditLayout/components/BuildingInfo/InputRows/Inputs/NumberInput/helpers";
import { phoneMask } from "../../../../../utils/masks";

type Props = {
  office?: Office;
  canEdit?: boolean;
  refetch?: () => void;
};

export const MainInfo = (
  {
    office,
    canEdit,
    refetch
  }: Props) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false)
  const toggleMode = () => setEditMode((prev) => !prev);
  const onEditFormSuccessfulSubmit = () => {
    refetch?.();
    toggleMode();
    dispatch(initialize());
  };

  const {handleSubmit, register, setValue, getValues, control} = useForm<Office>({
    defaultValues: {name: office?.name, mainPhone: office?.mainPhone}
  });

  const [coord, setCoords] = useState(office?.location?.coordinates)
  const [options, setOptions] = useState<{ value: string; label: string }[]>([
    {
      label: office?.addressLabel as string,
      value: office?.addressId as string,
    },
  ]);
  const findAddress = useFindAddressDetailed();
  const debounced = useDebouncedCallback(findAddress.mutateAsync as any, 500);

  const onSelectWrite = async (value: string) => {
    if (value === "") {
      dispatch(setAllowChangeByMap(true))
    }
    const {data} = await debounced(value) ?? [];
    if (data) {
      const foundAddresses = data.filter((address) => address.label);
      const withoutDublicate = foundAddresses.filter(
        (address, index, self) => index === self.findIndex((t) => t.label === address.label)
      );
      if (data.length) {
        const newOptions = [].concat(
          withoutDublicate.map((address) => ({
            label: address.label,
            value: address.id,
          }))
        );
        setOptions(newOptions);
      } else {
        const newOpt = {
          label: "Результатов не найдено",
          value: " ",
        }
        setOptions([newOpt])
      }
    }
  };

  const onSelectChange = (id: string) => {
    if (id === office?.addressId) {
      return;
    }
    const lastMutation: any = findAddress.getLastMutationCache();
    const selectedAddress: FindAddressDetailed = lastMutation.state?.data?.data?.find(
      (address: FindAddressDetailed) => address.id.toString() === id.toString()
    );
    if (!selectedAddress) {
      return;
    }
    const coords = selectedAddress.point.split(",");
    const newOptions = [
      {
        label: selectedAddress.label,
        value: selectedAddress.id,
      },
    ];
    setOptions(newOptions);
    setCoords([Number(coords[ 1 ]), Number(coords[ 0 ])])
  };

  useEffect(() => {
    if (editMode) {
      if (office?.addressLabel) {
        onSelectWrite(office?.addressLabel as string);
      }
    }
  }, [editMode, office?.addressLabel]);

  const {mutate, isLoading} = useOfficeUpdate({
    onSuccess: onEditFormSuccessfulSubmit,
  });

  const onSubmit = (values: Office) => {
    const payload: Office = {
      ...values,
      id: office?.id ?? "",
      location: {
        type: values.location?.type ?? "",
        coordinates: coord ?? office?.location?.coordinates ?? [0, 0]
      }
    }
    mutate(payload);
  };

  const nameWatcher = useWatch({
    control,
    name: "name",
    defaultValue: office?.name || "",
  })
  const phoneWatcher = useWatch({
    control,
    name: "mainPhone",
    defaultValue: parsePhone(office?.mainPhone ?? "") || "",
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.root}>
        <div className={styles.row}>
          {editMode
            ?
            <>
              <Photo office={office} canEdit={canEdit} refetchOffice={refetch} />
            </>
            : <div className={styles.image}>

              {office?.logotip
                ? <img src={office?.logotip} alt={"avatar"}/>
                : <div className={styles.logo}><Avatar/></div>
              }
          </div>}
          {editMode &&
            <div className={styles.editContent}>
                <InfoRow label={"Название"}>
                  <TransparentInput
                    className={styles.input}
                    register={register}
                    placeholder="Название"
                    withCleanUp={{setValue}}
                    name="name"
                    required
                    value={nameWatcher}
                  />
                </InfoRow>
                <InfoRow label={"Адрес"}>
                  <Select
                    placeholder="Введите адрес"
                    name="addressLabel"
                    hideArrow
                    defaultValue={office?.addressId}
                    defaultSearchValue={office?.addressLabel}
                    isSearchable
                    options={options}
                    register={register}
                    setValue={setValue}
                    customSearchFunction={onSelectWrite}
                    onChange={onSelectChange}
                    isLoading={findAddress.isLoading}
                  />
                </InfoRow>
                <InfoRow label={"Телефон"}>
                  <TransparentInput
                    className={styles.input}
                    register={register}
                    placeholder="Телефон"
                    name="mainPhone"
                    setValueForMask={setValue}
                    withCleanUp={{setValue}}
                    value={phoneWatcher}
                    mask={phoneMask}
                  />
                </InfoRow>
            </div>
          }
          {!editMode &&
            <div className={styles.column} style={{height: "164px"}}>
              <div className={styles.column}>
                <div className={styles.rowBetween}>
                  <div className={styles.firstName}>
                    {office?.name}
                  </div>
                  {canEdit &&
                    <div className={styles.edit} onClick={toggleMode}>
                      <PenIcon/>
                    </div>
                  }
                </div>
                <div className={styles.name}>{`${office?.addressLabel}`}</div>
                <div className={styles.phone}>{formatPhone(office?.mainPhone ?? "")}</div>
              </div>
            </div>}
        </div>
      </div>
      {editMode &&
        <div className={styles.submitBtns}>
          <Button variant="outlined" onClick={toggleMode} isLoading={isLoading}>
            Отменить
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Применить
          </Button>
        </div>
      }
    </form>
  )
}