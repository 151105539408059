import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { CreateContact } from "../CreateContact";
import { openDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { useQueryParams } from "../../../../utils/hooks";

type UseCreateContact = {
	refetchContacts: () => void;
	drawerWidth: number;
};

export const useCreateContact = ({ refetchContacts, drawerWidth }: UseCreateContact) => {
	const dispatch = useDispatch();
	const { queryParams, removeQueryParam } = useQueryParams<{
		newPhone: string;
	}>([{ name: "newPhone" }]);

	const onClose = () => {
		refetchContacts();
		removeQueryParam("newPhone");
	};

	const { newPhone } = queryParams;
	const openCreateContact = () => {
		dispatch(
			openDrawer({
				children: <CreateContact onSubmitted={refetchContacts} mainPhone={newPhone} onClose={onClose} />,
				width: drawerWidth,
				onClose,
			})
		);
	};

	useEffect(() => {
		if (newPhone) {
			openCreateContact();
		}
	}, [newPhone]);

	return { openCreateContact };
};
