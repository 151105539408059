import { InnerSecondLevelItem } from "../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	subMenu: InnerSecondLevelItem[];
};

export const SecondLevel = ({ subMenu }: Props): JSX.Element => (
	<div className={styles.root}>
		{subMenu.map((secondLevelItem) => (
			<div key={secondLevelItem.id} className={styles.item} onClick={secondLevelItem.onClick}>
				{secondLevelItem.text}
			</div>
		))}
	</div>
);
