import { Lead, LeadSourceEnum } from "../../../../types";

export const getShortIdBySource = (lead: Lead): {shortId: string, url: string} => {
  const {source, parserObject, sourceApplication, sourceLead, archivedEstateObject} = lead

  switch (source) {
    case LeadSourceEnum.coldCall:
     return {shortId: parserObject?.shortId ?? "", url: `/real-estate/${parserObject?.id}`}
    case LeadSourceEnum.lead:
      return {shortId: sourceLead?.shortId ?? "", url: `/leadsanddeals?tab=leads&id=${sourceLead?.id}`}
    case LeadSourceEnum.show:
      return {shortId: sourceApplication?.shortId ?? "", url: `/shows/${sourceApplication?.showId}`}
    case LeadSourceEnum.view:
      return {shortId: sourceApplication?.shortId ?? "", url: `/views/${sourceApplication?.viewId}`}
    case LeadSourceEnum.archivedEstateObject:
      return {shortId: archivedEstateObject?.shortId ?? "", url: ""}
    default:
      return {shortId: "", url: ""}

  }
}