import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { ChangeStageResponse, FinishApplicationPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/application/application-finish/DEFAULT_MN";

type Props = {
	onSuccess?: (data: ChangeStageResponse, variables: FinishApplicationPayload) => void;
};

export const useFinishApplication = ({ onSuccess }: Props) => {
	const onSuccessContactCreate = (
		res: AxiosResponse<ChangeStageResponse>,
		variables: FinishApplicationPayload
	) => {
		if (onSuccess) {
			onSuccess(res.data, variables);
		}
	};

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: ({ applicationId, action }: FinishApplicationPayload) =>
			api.application.finish({ applicationId, action }),
		onSuccess: (data: AxiosResponse<ChangeStageResponse>, variables: FinishApplicationPayload) =>
			onSuccessContactCreate(data, variables),
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
