import { KindType, RealEstate } from "../../../../../../../../../types";

import { useOpenRealEstate } from "../../../../../../../../../utils/objects";
import { useCreatePresentation, useCreateTaskModal } from "../../../../../../../../../utils/hooks";
import { PopupMenuItems } from "../../../../../../../../UI";
import { openObjectAd } from "./openObjectAd";
import { openPublicObject } from "./openPublicObject";
import { openObjectCompare } from "./openObjectCompare";
import { openSellerContact } from "./openSellerContact";

type Props = {
	object: RealEstate;
	isParser: boolean;
};

type SortableMenuItem = PopupMenuItems & {
	order: number;
};

export const usePopupMenu = ({ object, isParser }: Props) => {
	const locationFrom = isParser ? "parser-list" : "regular-list";
	const contactId: string | undefined = object.sellerContacts?.[0];

	const openObject = useOpenRealEstate({
		locationFrom,
		options: { inNewTab: true },
	});

	const openEditObject = useOpenRealEstate({
		options: { inNewTab: true, mode: "edit" },
	});

	const { openCreateTaskModal } = useCreateTaskModal({
		applicationId: object.applicationId,
		kind:  KindType.APPLICATION
	});

	const { handleCreatePresentation: createPresentation } = useCreatePresentation({
		applicationId: object.applicationId,
		parserObjectId: object.id,
		currentPrice: +object.price,
	});

	const sharedMenu: SortableMenuItem[] = [
		{
			label: "Открыть в новой вкладке",
			action: () => openObject(object),
			order: 0,
		},
		{ label: "Создать презентацию", action: createPresentation, order: 4 },
	];

	const parserMenu: SortableMenuItem[] = [];

	const agencyMenu: SortableMenuItem[] = [
		{ label: "Редактировать", action: () => openEditObject(object), order: 1 },
		{
			label: "Открыть рекламу",
			action: () => openObjectAd(object.applicationId),
			order: 2,
		},
		{
			label: "Открыть публичную страницу",
			action: () => openPublicObject(object.applicationId),
			order: 3,
		},
		{
			label: "Провести конкурентный анализ",
			action: () => openObjectCompare(object.applicationId),
			order: 5,
		},
		{ label: "Создать задачу", action: openCreateTaskModal, order: 7 },
	];

	if (contactId) {
		agencyMenu.push({
			label: "Перейти к собственнику",
			action: () => openSellerContact(contactId),
			order: 6,
		});
	}

	const menu = isParser ? sharedMenu.concat(parserMenu) : sharedMenu.concat(agencyMenu);

	menu.sort((left, right) => left.order - right.order);

	return { menu };
};
