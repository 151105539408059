import { AxiosError } from "axios";
import { UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";

import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";
import { InviteSend } from "../../../../types";

const DEFAULT_MN = "sdelka/invite/send-invitation/DEFAULT_MN";

type UseSendInvitation = {
	onSuccess: () => void;
	setError: UseFormSetError<any>;
};

export const useInviteSend = ({ onSuccess, setError }: UseSendInvitation) => {
	const onError = (error: AxiosError) => {
		const message = axiosError({ error });

		if (message) {
			if (message.includes("Account with this phone already works in your company!")) {
				setError("phone", { message: "Сотрудник уже приглашен" });
			} else if (message.includes("phone must be a valid phone number")) {
				setError("phone", { message: "Невалидный телефон" });
			} else {
				setError("phone", { message: "Ошибка" });
			}
		}
	};

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (data: InviteSend) => api.invites.createPersonalLink(data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
	};
};
