import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/finish-deal/DEFAULT_MN";

type UseFinishDeal = {
  onSuccess?: () => void
}

export const useFinishDeal= ({onSuccess}:UseFinishDeal) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (id: string) => api.deals.finishDeal(id),
    onSuccess
  })

  return {
    mutate: mutation.mutate,
    mutateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  }
}