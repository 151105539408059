import { useState } from "react";
import { useQuery } from "react-query";

import { api } from "../../index";
import { DefaultTasksFiltersType } from "../../../../types";

const DEFAULT_QN = "sdelka/tasks/load-tasks-count/DEFAULT_QN";

type UseTasksCount = {
	key?: string;
	enabled?: boolean;
	defaultFilter: DefaultTasksFiltersType;
	defaultStage?: string[];
};

export const useTasksCount = ({
	key = DEFAULT_QN,
	enabled = true,
	defaultFilter,
	defaultStage = [],
}: UseTasksCount) => {
	const [stage, setStage] = useState(defaultStage);
	const [filter, setFilter] = useState(defaultFilter);

	const loadTasksCount = () => api.tasks.count({ search: { ...filter, stage } });

	const { data, refetch, remove, isLoading, isFetched, isFetching } = useQuery(
		[key, filter],
		loadTasksCount,
		{
			enabled,
			keepPreviousData: true,
		}
	);

	return {
		data: data?.data,
		isLoading,
		refetch,
		remove,
		filter,
		setFilter,
		isFetched,
		isFetching,
		setStage,
	};
};
