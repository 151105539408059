import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { BusinessProcessSettings } from "../../../../types";

import { api } from "../../../api";
import { setBusinessProcesses } from "./business-process.action";

export const loadBusinessProcesses =
	() =>
	(dispatch: Dispatch): void => {
		api.settings
			.businessProcessList([])
			.then((response: AxiosResponse<BusinessProcessSettings[]>) => {
				const { data } = response;

				dispatch(setBusinessProcesses(data));

				return data;
			})
			.catch((error) => {
				console.error(error);
			});
	};
