import { CONTACT_DEFAULT_FILTERS } from "../../../../../service/api/helpers";

/**
 * Specific custom controls (such as MultiLayerSelect) return an input result
 *  as objects, incompatible with backend interface. Map those results to be
 *  ready to go
 */
export const mapFilterInputValues = (values: typeof CONTACT_DEFAULT_FILTERS) => {
	const responsibles = values.responsibles.map((item: { value: string }) => item.value);

	return {
		...values,
		responsibles,
	};
};
