import { useDispatch } from "react-redux";

import { AddResponsible } from "./AddResponsible";
import { openModal } from "../../../service/redux/modules/modal/modal.action";
import { OnPinResponsiblePayload } from "../../../types";

import styles from "./index.module.scss";

type Props = {
	title: string;
	buttonLabel: string;
	onPin: (payload: OnPinResponsiblePayload) => void;
	checkedResponsibles?: string[];
	isPinOne?: boolean;
};

type Return = {
	openAddResponsibleModal: () => void;
};

type UseAddResponsibleModal = (props: Props) => Return;

export const useAddResponsibleModal: UseAddResponsibleModal = ({
	onPin,
	title,
	buttonLabel,
	checkedResponsibles,
	isPinOne,
}) => {
	const dispatch = useDispatch();

	const openAddResponsibleModal = () => {
		dispatch(
			openModal({
				body: (
					<AddResponsible
						title={title}
						buttonLabel={buttonLabel}
						onPin={onPin}
						checkedResponsibles={checkedResponsibles}
						isPinOne={isPinOne}
					/>
				),
				classNames: { contentWrapper: styles.contentWrapperClassName },
				width: 472,
			})
		);
	};

	return {
		openAddResponsibleModal,
	};
};
