import { DefaultTasksFiltersType } from "../../../../types";
import { useHistory } from "react-router-dom";
import { getQueryVariable } from "./getQueryVariable";

export const useFilterQuery = (defaultTasksFilter: DefaultTasksFiltersType) => {
  const history = useHistory()

  const filters = defaultTasksFilter
  if (history.location.search.includes("leadId")) {
    filters.communicationsForLeadId = getQueryVariable("leadId")
  }
  if (history.location.search.includes("applicationId")) {
    filters.connectedApplicationId = getQueryVariable("connectedApplicationId")
  }
  if (history.location.search.includes("preApplicationId")) {
    filters.connectedPreApplicationId  = getQueryVariable("connectedPreApplicationId")
  }

  return filters
}