import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { InfoRow } from "@sdelka_crm/sdelka-crm-component-library";

import { MultiLayerSelect } from "../../../../../Form";
import { useLoadUsers } from "../../../../../../../service/api/queries";
import { prepareResponsibleUsers } from "../../../../../../../utils/transformation";
import { useTaskFormContext } from "../../../../../../../service/context/TaskForm";
import { MultiLayerSelectOption } from "../../../../../../../types";


type Props = {
  classnames: string
}

export const Executor = ({classnames}: Props): JSX.Element => {
  const {register, setValue, control, getValues, errors} = useTaskFormContext();
  const {users} = useLoadUsers({});

  const responsibleUsers = useMemo((): MultiLayerSelectOption[] => prepareResponsibleUsers(users), [users]);

  const responsibleWatcher = useWatch({
    control,
    name: "executor.userId",
    defaultValue: getValues("executor.userId"),
  });

  return (
    <InfoRow labelFont="ubuntu" label="Исполнитель">
      <MultiLayerSelect
        placeholder="Выбрать"
        name={"executor.userId"}
        register={register}
        setValue={setValue}
        error={errors?.executor?.message?.toString()}
        required
        selectOne
        isSearchable
        classNames={{
          wrapper: classnames
        }}
        options={responsibleUsers}/>
    </InfoRow>
  );
};
