import { useEffect } from "react";
import { useRouteMatch } from "react-router";

import { ObjectPhotoGallery } from "../../../../../index";
import { useLoadApplicationRealEstate } from "../../../../../../../service/api/queries";

import styles from "./index.module.scss";

type MatchParams = {
	id: string;
};

type Props = {
	applicationId?: string;
};

export const Gallery = ({ applicationId }: Props): JSX.Element => {
	const match = useRouteMatch<MatchParams>();
	const { id } = match.params;

	const { realEstate, remove, refetch } = useLoadApplicationRealEstate({
		id: applicationId ?? id,
	});

	useEffect(() => {
		if (id === "new") {
			remove();
		}
	}, [id]);

	return (
		<>
			{!realEstate ? null : (
				<div className={styles.root}>
					<h3>Фотографии и тех. план</h3>

					<ObjectPhotoGallery realEstate={realEstate} refetch={refetch} alwaysOpen />
				</div>
			)}
		</>
	);
};
