import { ReactNode } from "react";

import { IconBlockInfo } from "../../IconBlockInfo";

import { ReactComponent as Alert } from "../../../../assets/icons/alert-info.svg";
import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
};

export const BlockInfo = ({ children }: Props): JSX.Element => (
	<IconBlockInfo classNames={{ root: styles.info }} Icon={<Alert className={styles.alertIcon} />}>
		{children}
	</IconBlockInfo>
);
