import cn from "classnames";

import { ReactComponent as CheckMarkOutlined } from "../../../assets/icons/check-mark-outlined.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock-outline.svg";
import { ReactComponent as Error } from "../../../assets/icons/error.svg";
import styles from "./index.module.scss";

type Props = {
	type?: "success" | "wait" | "error";
	text?: string;
};

const getIconByCountStatusInfoType = {
	success: [CheckMarkOutlined, styles.success],
	wait: [Clock, styles.wait],
	error: [Error, styles.error],
};

export const CountStatusInfo = ({ type = "success", text = "" }: Props): JSX.Element => {
	const [Icon, styleByType] = getIconByCountStatusInfoType[type];

	return (
		// @ts-ignore
		<div className={cn(styles.root, styleByType)}>
			<div className={styles.icon}>
				<Icon />
			</div>
			<div className={styles.text}>{text}</div>
		</div>
	);
};
