import { SidebarLayout, FixedNav } from "../../UI";
import { Introduction } from "./Introduction";
import { Read } from "./read";
import { Share } from "./share";
import { Observe } from "./observe";
import { Influence } from "./Influence";
import { DashboardSwitch } from "./dashboardSwitch";
import { scrollToElement } from "../../../utils/scroll";
import { Subscribe } from "./subscribe";

import styles from "./index.module.scss";
import { selectAccounts, useSelect } from "service/redux/selectors";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeDrawer, openDrawer } from "service/redux/modules/drawer/drawer.action";
import { FinishRegistration } from "../Authentication/RegistrationPageV2/FinishRegistration";
import { TokenService } from "service/api/services/inner/TokenService";
import { useHistory } from "react-router";

export const Dashboard = (): JSX.Element => {
	const user = useSelect(selectAccounts)
	const history = useHistory()
	const dispatch = useDispatch()

	const token = TokenService.getCurrentToken()
	
	useEffect(()=>{
		if(!user.authorized) {
			dispatch(
				openDrawer({
					children: [<FinishRegistration />],
					width: 800,
					disableClose: true, 
				},)
	)}
	},[user, token])

	useEffect(()=>{
		if(!token) {
			history.push("/login")
			dispatch(closeDrawer())
		}
	},[token, user])
	const scrollTopOffset = "55";

	const blocksIds = {
		learn: "learn-block-dashboard-view",
		study: "study-block-dashboard-view",
		share: "share-block-dashboard-view",
		observe: "observe-block-dashboard-view",
		subscribe: "subscribe-block-dashboard-view",
		influence: "influence-block-dashboard-view",
	};

	const navigation = [
		{
			label: "Знакомься",
			action: () => scrollToElement(blocksIds.learn, scrollTopOffset),
		},
		{
			label: "Изучай",
			action: () => scrollToElement(blocksIds.study, scrollTopOffset),
		},
		{
			label: "Делись",
			action: () => scrollToElement(blocksIds.share, scrollTopOffset),
		},
		{
			label: "Следи",
			action: () => scrollToElement(blocksIds.observe, scrollTopOffset),
		},
		{
			label: "Подписывайся",
			action: () => scrollToElement(blocksIds.subscribe, scrollTopOffset),
		},
		{
			label: "Влияй",
			action: () => scrollToElement(blocksIds.influence, scrollTopOffset),
		},
	];

	return (

		<SidebarLayout>

			<FixedNav navigation={navigation} />

		<div className={styles.IntroductionContainer}>

			<DashboardSwitch />

			<Introduction sectionId={blocksIds.learn}/>

			<Read id={blocksIds.study}/>

			<Share id={blocksIds.share}/>

			<Observe id={blocksIds.observe}/>

			<Subscribe />

			<Influence id={blocksIds.influence}/>

		</div>
	</SidebarLayout>
	)
}
