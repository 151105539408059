import { useEffect } from "react";
import { UseFormSetError, UseFormClearErrors, UseFormGetValues } from "react-hook-form";
import { Contact, CreateApplication, RealEstate, RealEstateCreatePayload } from "../../../../../../types";

import { toastError, toastSuccess } from "../../../../../UI";
import { useSelect, selectObjectEdit } from "../../../../../../service/redux/selectors";
import { useCreateApplicationObject } from "../../../../../../service/api/mutations";

type Props = {
	setError: UseFormSetError<any>;
	clearErrors: UseFormClearErrors<any>;
	disableMap?: boolean;
	onObjectCreate?: (object: RealEstate) => void;
	contacts: Contact[];
	responsiblesIds: string[];
	quizGetValues: UseFormGetValues<any>;
	comment: string;
};

type Return = {
	onSubmit: (data: any) => void;
	isCreateLoading: boolean;
};

type UseOnSubmit = (props: Props) => Return;

export const useOnSubmit: UseOnSubmit = ({
	setError,
	clearErrors,
	disableMap,
	onObjectCreate,
	contacts,
	responsiblesIds,
	quizGetValues,
	comment,
}) => {
	const { coord, addressId, addressLabel } = useSelect(selectObjectEdit);

	useEffect(() => {
		if (coord.lat && coord.lon && addressLabel) {
			clearErrors("addressLabel");
		}
	}, [coord.lat, coord.lon, addressLabel]);

	const { mutate, isLoading } = useCreateApplicationObject({
		disabledPush: !!onObjectCreate,
		onSuccess: (clientObject: RealEstate) => {
			toastSuccess({ text: "Объект недвижимости создан" });

			if (onObjectCreate) {
				onObjectCreate(clientObject);
			}
		},
	});

	const onSubmit = (data: any) => {
		const dataProp = data;
		if (!disableMap && (!coord.lat || !coord.lon || !addressLabel)) {
			toastError({ text: "Отметьте объект на карте и введите адрес" });
			setError("addressLabel", {
				message: "Отметьте объект на карте и введите адрес",
			});

			return;
		}

		const sellerContacts: CreateApplication["sellerContacts"] = contacts.map((contact) => ({
			contactId: contact.id,
		}));
		const quiz = quizGetValues();

		const responsibleUserId = responsiblesIds[0];
		if (!responsibleUserId) {
			toastError({ text: "Выберите ответственного за объект" });

			return;
		}

		const applicationData: Pick<RealEstateCreatePayload, "applicationData"> = {
			...quiz,
			comment,
			responsibleUserId,
			sellerContacts,
		};
		const businessProcessId = dataProp.type.id;
		delete dataProp.objectSubType;

		delete dataProp.catalog;
		delete dataProp.objectSubType;
		delete dataProp.dealType;
		delete dataProp.actual;
		delete dataProp.responsibles;
		delete dataProp.addressId;
		delete dataProp.type;

		const payload: RealEstateCreatePayload = {
			...dataProp,
			businessProcessId,
			addressId,
			addressLabel,
			location: !disableMap
				? {
						coordinates: [coord.lat, coord.lon],
						type: "Point",
				  }
				: undefined,
			applicationData,
		};

		mutate(payload);
	};

	return {
		onSubmit,
		isCreateLoading: isLoading,
	};
};
