import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss"
import { closeDrawer } from "service/redux/modules/drawer/drawer.action";
import { ReactComponent as CheckMarkSuccess } from "../../../../../../assets/icons/CheckMarkSuccess.svg";

export const newCompanyFormModal =() => {
  const dispatch = useDispatch()

  const onApply = () => {
    dispatch(closeModal())
    dispatch(closeDrawer())

  }
  const openNewCompanyFormModal = () => {
    dispatch(
      openModal({
        width: 660,
        body:
          <div className={styles.wrap}>
            <div className={styles.block}>
              <CheckMarkSuccess />
              <div className={styles.text}>
                <h1>
                  Компания создана
                </h1>
                <p>
                  Вы создали свою компанию и теперь можете приглашать сотрудников
                </p>
              </div>
            </div>
            <Button onClick={onApply} classNames={{root: styles.button}}>
                Начать работу
            </Button>

					</div>
      })
    )
  }
  return {
    openNewCompanyFormModal
  }
}