import { Client } from "../../Client";
import { getTrafficFilters, getFreeTrafficFilters } from "../../helpers";
import {
	Traffic,
	GetTrafficListPayload,
	UpdateTrafficPayload,
	TrafficTargetType,
	GetFreeTrafficListPayload,
	TrafficListResponse,
} from "../../../../types";

export const TrafficService = {
	list: ({ take, skip, search }: GetTrafficListPayload) => {
		const searchConditions = [...getTrafficFilters(search)];

		return Client.post<TrafficListResponse>(
			"/traffic/search",
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
				join: [],
				take,
				skip,
			})
		);
	},

	freeList: ({ take, skip }: GetFreeTrafficListPayload) => {
		const searchConditions = [...getFreeTrafficFilters()];

		return Client.post<{ data: Traffic[] }>(
			"/traffic/search",
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
				join: [],
				take,
				skip,
			})
		);
	},
	read: (id: string) => Client.get<Traffic>(`/traffic/${id}`),
	update: (id: string, payload: UpdateTrafficPayload) => Client.put(`/traffic/${id}`, payload),
	updateTarget: (id: string, target: TrafficTargetType, entityId = "") =>
		Client.put(`traffic/${id}/target/${target}/${entityId}`),
	deleteTarget: (id: string) => Client.delete(`traffic/${id}/target/`),
	updateResult: (id: string, target: TrafficTargetType, entityId = "") =>
		Client.put(`traffic/${id}/result/${target}/${entityId}`),
	deleteResult: (id: string) => Client.delete(`traffic/${id}/result/`),
};
