import { ReactNode, useEffect, useRef, useState } from "react";

import { Options, Selected } from "./components";
import { NativeSelect, SelectError, SelectWrapper } from "../components";
import { useClickOutside } from "../../../../../utils/hooks";
import { arrValuesToMap } from "../../../../../utils/transformation";
import { DefaultMultiLayerSelectProps, MultiLayerSelectOption } from "../../../../../types";

type FOption = MultiLayerSelectOption & {
	hidden?: boolean;
	hideChildren?: boolean;
};

type MultiLayerSelectProps = DefaultMultiLayerSelectProps<MultiLayerSelectOption> & {
	options: MultiLayerSelectOption[];
	alwaysOpen?: boolean;
	selectOne?: boolean;
};

export const MultiLayerSelect = ({
	name,
	defaultValue = [],
	options,
	register,
	setValue,
	size = "normal",
	classNames,
	append,
	prepend,
	onChange,
	placeholder,
	error,
	required,
	disabled,
	customSearchFunction,
	isSearchable,
	isLoading,
	alwaysOpen = false,
	selectOne = false,
	hideArrow,
	withBorder,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
MultiLayerSelectProps): JSX.Element => {
	const [searchVal, setSearchVal] = useState("");
	const [selected, setSelected] = useState<string[]>(defaultValue.map((item) => item.value));
	const [selectedLabels, setSelectedLabels] = useState<Array<{ name: string; children: ReactNode }>>(
		defaultValue.map((item) => item.label)
	);
	const [isListOpen, setIsListOpen] = useState(false);
	const [filteredOption, setFilteredOptions] = useState<FOption[]>([]);

	const handleSetListOpen = (value: boolean) => {
		if (disabled) {
			return;
		}

		setIsListOpen(value);
	};

	const handleToggleListOpen = () => {
		if (disabled) {
			return;
		}
		setIsListOpen((prev) => !prev);
	};

	const wrapperRef = useRef<HTMLDivElement>(null);

	useClickOutside({
		ref: wrapperRef,
		onClick: () => {
			if (!alwaysOpen) setIsListOpen(false);
		},
	});

	useEffect(() => {
		setFilteredOptions(
			options.map((option) => ({
				...option,
				hidden: option.layer !== 0,
				hideChildren: true,
			}))
		);
	}, [options, options.length]);

	const selectAllLowerLevel = (value: string, layer: 0 | 1 | 2): MultiLayerSelectOption[] => {
		if (layer === 2) return [];
		const res: MultiLayerSelectOption[] = [];
		const curItemInd = options.findIndex((item) => item.value === value);
		let i = curItemInd + 1;
		while (i !== options.length) {
			const curOption = options[i];
			if (curOption && curOption.layer <= layer) break;
			res.push(curOption);
			i += 1;
		}

		return res;
	};

	const lowerLevelLabelsAndValues = (
		value: string,
		layer: 0 | 1 | 2
	): { labels: (string | ReactNode)[]; values: string[] } => {
		const lowerLevel = selectAllLowerLevel(value, layer);
		const labels = lowerLevel.map((item) => item.label);
		const values = lowerLevel.map((item) => item.value);

		return { labels, values };
	};

	const setHiddenOptions = ({ value, layer }: MultiLayerSelectOption) => {
		const hideChildren = Boolean(filteredOption.find((item) => item.value === value)?.hideChildren);

		const nFilteredOption = filteredOption.map((option) => {
			if (option.value === value) {
				return {
					...option,
					hidden: false,
					hideChildren: !hideChildren,
				};
			}

			let hidden = true;

			if (hideChildren) {
				hidden = layer === option.layer - 1 ? !hideChildren : hideChildren;
			}

			return option.parents.includes(value)
				? {
						...option,
						hidden,
						hideChildren: true,
				  }
				: option;
		});

		setFilteredOptions(nFilteredOption);
	};

	useEffect(() => {
		if (selected.length === defaultValue?.length) return;

		setSelected(defaultValue?.map((item) => item.value));
		setSelectedLabels(defaultValue?.map((item) => item.label));
	}, [defaultValue?.length]);

	useEffect(() => {
		const selectedMap = arrValuesToMap(selected);

		const selectedOptions = options.filter((option) => selectedMap[option.value]);

		setValue(name, selectedOptions);
	}, [selected]);

	const sortByLayer = (values: string[]) => {
		const valuesMap = arrValuesToMap(values);
		const selectedOptions = options.filter((option) => valuesMap[option.value]);
		selectedOptions.sort((a, b) => a.layer - b.layer);

		return selectedOptions.map((sOption) => sOption.label);
	};

	const selectItem = ({ value, layer }: MultiLayerSelectOption) => {
		const { values } = lowerLevelLabelsAndValues(value, layer);

		const selectedMap = arrValuesToMap(selected);
		const valuesMap = arrValuesToMap(values);

		if (selectOne) {
			const haveSameItem = selected[0] === value;
			const newSelected = haveSameItem ? [] : [value];
			if (onChange) {
				onChange(newSelected);
			}

			const newSelectedMap = arrValuesToMap(newSelected);
			setSelectedLabels(
				filteredOption.filter((fOption) => newSelectedMap[fOption.value]).map((item) => item.label)
			);

			return setSelected(newSelected);
		}

		if (selectedMap[value]) {
			const newSelected = selected.filter((i) => i !== value && !valuesMap[i]);
			if (onChange) {
				onChange(newSelected);
			}

			const newSelectedMap = arrValuesToMap(newSelected);

			setSelectedLabels(
				filteredOption.filter((fOption) => newSelectedMap[fOption.value]).map((item) => item.label)
			);

			return setSelected(newSelected);
		}

		const newSelected = selected.concat(
			value,
			values.filter((val) => !selectedMap[val])
		);

		if (onChange) {
			onChange(newSelected);
		}
		setSelected(newSelected);

		return setSelectedLabels(sortByLayer(newSelected));
	};

	const onSearch = (value: string) => {
		setSearchVal(value);
		if (customSearchFunction) {
			customSearchFunction(value);
		} else {
			if (!value) {
				setFilteredOptions(options);

				return;
			}
			const curOptions = options.filter((option) =>
				option.label.name.toLowerCase().match(value.toLowerCase())
			);

			setFilteredOptions(
				curOptions.reduce((res, curOption) => {
					const resMap = arrValuesToMap(res.map((item) => item.value));
					const lowerLevel = selectAllLowerLevel(curOption.value, curOption.layer);

					return [...res, ...(resMap[curOption.value] ? [] : [curOption, ...lowerLevel])];
				}, [] as MultiLayerSelectOption[])
			);
		}
	};

	const thereIsSelected = !!selected?.length;

	return (
		<SelectWrapper
			setListOpen={handleSetListOpen}
			size={size}
			classNames={classNames}
			withBorder={withBorder}
			error={error}
			disabled={disabled}
		>
			<NativeSelect
				name={name}
				disabled={disabled}
				required={required}
				multiple
				register={register}
				options={[]}
			>
				{filteredOption.map((item, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<option key={`${item.value}-${index}`} value={item.value}>
						{item.label.name}
					</option>
				))}
			</NativeSelect>

			{!alwaysOpen && (
				<Selected
					placeholder={placeholder}
					thereIsSelected={thereIsSelected}
					selectedLabels={selectedLabels ?? [{name: " ", children: <></>}]}
					classNames={classNames}
					size={size}
					append={append}
					hideArrow={hideArrow}
					isLoading={isLoading}
					disabled={disabled}
					prepend={prepend}
					toggleList={handleToggleListOpen}
					isListOpen={isListOpen}
				/>
			)}

			<SelectError error={error} classNames={classNames} />

			<Options
				filteredOption={filteredOption}
				alwaysOpen={alwaysOpen}
				isLoading={isLoading}
				searchVal={searchVal}
				classNames={classNames}
				selectOne={selectOne}
				selectAllLowerLevel={selectAllLowerLevel}
				setHiddenOptions={setHiddenOptions}
				selectItem={selectItem}
				selected={selected}
				isListOpen={isListOpen}
				size={size}
				isSearchable={isSearchable}
				onSearch={onSearch}
			/>
		</SelectWrapper>
	);
};
