import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import * as yup from "yup";

import {
	closeModal,
	openModal,
} from "../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { LeadStatusEnum } from "../../../../../../../../../../../types";

import styles from "./index.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { CalendarInPopup } from "../../../../../../../../../../UI";
import { useChangeStatus } from "../../../../../../../../../../../service/api/mutations";

type Props = {
	leadId: string;
	refetchLead: () => void;
};

type Return = {
	openDefferedModal: () => void;
};

type UseDefferedModal = (props: Props) => Return;

type FormValues = {
	deferredDemandDate?: string;
};

const getMaxDeferredDate = () => new Date(Date.now() + 1000 * 60 * 60 * 24 * 30 * 3);

const schema = yup.object({
	deferredDemandDate: yup
		.string()
		.required()
		.test("deferredDemandDate", "Некорректная дата", (date) => new Date(date!) >= new Date())
		.test(
			"deferredDemandDate",
			"Отложить лид можно максимум на 3 месяца",
			(date) => new Date(date!) <= getMaxDeferredDate()
		),
});

const DeferLeadModal = ({ leadId, refetchLead }: Props): JSX.Element => {
	const dispatch = useDispatch();

	const { handleSubmit, register, watch, setValue, getValues, formState } = useForm<FormValues>({
		resolver: yupResolver(schema),
	});

	const { mutate, isLoading } = useChangeStatus({
		onSuccess: () => {
			refetchLead?.();
			dispatch(closeModal());
		},
	});

	const onSubmit = (values: FormValues) => {
		const payload = {
			status: LeadStatusEnum.deferred,
			deferredDemandDate: values.deferredDemandDate || undefined,
		};

		mutate({ id: leadId, payload });
	};

	const deferredDemandDate = watch("deferredDemandDate");

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.defferedReasonForm}>
				<div className={styles.defferedReason}>
					<span className={styles.defferedReasonLabel}>Отложить до</span>

					<div className={styles.dateContiner}>
						<CalendarInPopup
							form={{
								name: "deferredDemandDate",
								register,
								setValue,
								getValues,
							}}
							onDateChange={(value) => setValue("deferredDemandDate", (value as Date).toISOString())}
							error={formState.errors.deferredDemandDate?.message}
						/>
					</div>
				</div>
			</div>

			<div className={styles.defferedSubmitContainer}>
				<Button disabled={!deferredDemandDate} isLoading={isLoading} type="submit">
					Отложить
				</Button>
			</div>
		</form>
	);
};

export const useDefferedModal: UseDefferedModal = ({ leadId, refetchLead }) => {
	const dispatch = useDispatch();

	const openDefferedModal = () => {
		dispatch(
			openModal({
				title: (
					<div className={styles.defferedTitle}>
						<span>Отложить </span>
						лид
					</div>
				),
				body: <DeferLeadModal leadId={leadId} refetchLead={refetchLead} />,
				width: 676,
			})
		);
	};

	return {
		openDefferedModal,
	};
};
