import { Department, Office } from "../../../../../types";
import { useDispatch } from "react-redux";
import styles from "./index.module.scss"
import { CollapseDown} from "@sdelka_crm/sdelka-crm-component-library";
import { CardPerson } from "../../../../UI";
import { LOAD_POSITIONS_REQUESTS_QN, useLoadPositions } from "../../../../../service/api/queries";
import {ReactComponent as Add} from "../../../../../assets/icons/plus-small.svg";
import { useMemo } from "react";
import { useAddToDepartmentModal } from "../add-to-department-modal/useAddToDepartmentModal";

type Props = {
  office?: Office;
  canEdit?: boolean;
  refetch?: () => void;
};

export const Departments = (
  {
    office,
    canEdit,
    refetch
  }: Props) => {
  const dispatch = useDispatch();
  const departments = office?.departments ?? []

  const {handleAddToDepartmentModal} = useAddToDepartmentModal()

  const {getPositionName} = useLoadPositions({});

  return (
    <div className={styles.root}>
      <div className={styles.departments}>
        {departments && departments.map((dep) => (
          <div key={dep.id} className={styles.department}>
              <CollapseDown
                label={    <div className={styles.row}>
                  <div className={styles.title}>
                    {dep.name}
                  </div>
                  <div className={styles.count}>
                {`(${dep.users?.length ?? 0})`}
                  </div>
              </div>
                }
                classNames={{
                  root: styles.card,
                  labelText: styles.labelText,
                }}
              >
                {dep.users && dep.users.map((user) => (
                  <div className={styles.user}>
                    <CardPerson
                      id={user.id}
                      phone={user.account.phone}
                      position={getPositionName(user.position?.id)}
                      avatar={user.account.avatar}
                      specialisation={user.specialisations}
                      status={user.status}
                      fio={{
                        firstName: user.account.name,
                        lastName: user.account.lastName,
                        secondName: user.account.secondName
                      }}
                      departmentId={dep.id}
                      canRemoveFromDepartment={user.canRemoveFromDepartment}
                      isInCart={user.isInCart}
                    />
                  </div>

                ))}
              </CollapseDown>
            <div className={styles.add} onClick={() => handleAddToDepartmentModal(dep.id)}>
              <Add/>
              Добавить сотрудника
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <div>
          <div>ID офиса:</div>
          <div>{office?.shortId}</div>
        </div>

      </div>
    </div>
  )
}