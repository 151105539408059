import cn from "classnames";
import { TaskStatusEnum, TaskStatusVariant } from "../../../../../../types";

import styles from "./index.module.scss";
import { ReactComponent as ClockTimer } from "../../../../../../assets/icons/clock-outline-wrapped-2.svg";
import { ReactComponent as Reload } from "../../../../../../assets/icons/double-reload-outlined.svg";
import { ReactComponent as Pause } from "../../../../../../assets/icons/pause-outlined.svg";
import { ReactComponent as Warn } from "../../../../../../assets/icons/warn.svg";
import { ReactComponent as Success } from "../../../../../../assets/icons/checkmark-pixeled.svg";
import { ReactComponent as Cancel } from "../../../../../../assets/icons/cancel-filled.svg";

type Props = {
	status: TaskStatusVariant;
	isRework?: boolean;
	isSuccessFinished?: boolean;
	className?: string;
};

export const StatusIcon = ({ status, isRework, isSuccessFinished, className }: Props): JSX.Element => {
	const pickIcon = () => {
		if (isRework) return <Reload />;

		switch (status) {
			case TaskStatusEnum.inWork:
				return <ClockTimer />;

			case TaskStatusEnum.failed:
				return <Warn />;


			case TaskStatusEnum.done:
				return isSuccessFinished ? <Success /> : <Cancel />;

			default:
				return "";
		}
	};

	const icon = pickIcon();

	return <div className={cn(styles.icon, className)}>{icon}</div>;
};
