import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/tasks/load-tasks-types/DEFAULT_QN";

export const useLoadTasksTypes = () => {
	const loadTasksTypes = () => api.tasks.types();

	const { data, refetch, remove, isLoading, isFetched, isFetching } = useQuery(DEFAULT_QN, loadTasksTypes, {
		keepPreviousData: true,
	});

	return {
		types: data?.data ?? [],
		isLoading,
		refetch,
		remove,
		isFetched,
		isFetching,
	};
};
