import {
	ObjectListAction,
	CLEAN_UP,
	SET_OBJECTS_GROUP_IDS,
	SET_OBJECTS_TYPE_FOR_FILTER,
	SET_FILTERS_SHOW_BUTTON,
} from "./object-list.action";
import { ObjectListTInitialState } from "../../types";

const initialState: ObjectListTInitialState = {
	filtersShowButton: false,
	objectsGroupIds: [],
	objectsFilterType: [],
};

export const objectListReducer = (
	state = initialState,
	action: ObjectListAction
): ObjectListTInitialState => {
	switch (action.type) {
		case SET_OBJECTS_GROUP_IDS:
			return {
				...state,
				objectsGroupIds: action.ids,
			};
		case SET_FILTERS_SHOW_BUTTON:
			return {
				...state,
				filtersShowButton: action.filtersShowButton,
			};
		case SET_OBJECTS_TYPE_FOR_FILTER:
			return {
				...state,
				objectsFilterType: action.filterTypes,
			};
		case CLEAN_UP:
			return {
				...state,
				objectsGroupIds: [],
				objectsFilterType: [],
			};
		default:
			return state;
	}
};
