import { Button, Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { FormPropsEditRealEstate, RealEstateSchema } from "../../../../../types";
import { Address, BuildingInfo, FieldsForAd, Gallery } from "./components";

import { ReactComponent as LogoIcon } from "../../../../../assets/icons/logo.svg";
import styles from "./index.module.scss";

type Props = {
	id: string;
	schema: RealEstateSchema;
	form: FormPropsEditRealEstate;
	isSubmitDisabled: boolean;
};

export const ObjectEditLayout = ({ id, isSubmitDisabled, schema, form }: Props): JSX.Element => {
	const { objectEditConfig } = schema;
	const aboutRealEstate = objectEditConfig.slice(1, objectEditConfig.length - 1);
	const dealInfo = objectEditConfig.slice(objectEditConfig.length - 1)?.[0]?.blocks?.[0]?.fields;

	const { formState } = form;

	return (
		<>
			<div className={styles.card}>
				<h3 className={styles.addressH3}>
					Адрес объекта
					<Tooltip
						classNames={{
							root: styles.addressTooltip,
							card: styles.addressTooltipCard,
						}}
					>
						Введите адрес вручную или передвиньте маркер на карте.
					</Tooltip>
				</h3>
				<Address form={form} fields={objectEditConfig?.[0]?.blocks?.[1]?.fields} />
			</div>
			<FieldsForAd />
			<div className={styles.cardsContainer}>
				<div className={styles.leftCardsContainer}>
					{aboutRealEstate.map((about, aboutIndex) => (
						// eslint-disable-next-line react/no-array-index-key
						<div key={`${about.bigTitle}-${aboutIndex}`}>
							{about.bigTitle && <h2>{about.bigTitle}</h2>}
							{about.blocks.map((block, blockIndex) => (
								// eslint-disable-next-line react/no-array-index-key
								<div key={`${block.blockName}-${blockIndex}`} className={styles.card}>
									{block.blockName && <h3>{block.blockName}</h3>}
									<BuildingInfo form={form} fields={block.fields} />
								</div>
							))}
						</div>
					))}
				</div>
				<div className={styles.rightCardsContainer}>
					<div className={styles.stickyContainer}>
						<div className={styles.submitContainer}>
							<Button type="submit" disabled={isSubmitDisabled} fullWidth>
								Сохранить объект
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Gallery applicationId={id} />
			<div className={styles.card}>
				<div className={styles.aboutDealContainer}>
					<h3>О сделке</h3>
					{dealInfo && <BuildingInfo form={form} fields={dealInfo} />}
					<div className={styles.logo}>
						<LogoIcon />
					</div>
				</div>
			</div>
			{Object.keys(formState.errors).length ? (
				<div className={styles.error}>Заполните все поля правильно</div>
			) : (
				<></>
			)}
		</>
	);
};
