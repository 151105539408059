import { ApplicationInListV2, ApplicationsFilter, KindType } from "../../../../../types";
import { TableApplication } from "../../../../UI";
import { StageTitle } from "../StageTitle";
import { useOpenApplicationCard } from "../../../../../utils/application";
import { useBlinkRow, useQueryParams } from "../../../../../utils/hooks";
import styles from "./index.module.scss";
import { useEffect } from "react";

type Props = {
  requestsList: ApplicationInListV2[];
  filter?: ApplicationsFilter;
  isLoading: boolean;
  withStage: boolean;
  total?: number;
  totalCounts?: Record<string, number>;
  refetchApplications?: () => void;
  kind?: KindType
};

export const ApplicationTable = (
  {
    requestsList,
    filter,
    isLoading,
    withStage,
    total,
    totalCounts,
    refetchApplications,
    kind
  }: Props): JSX.Element => {
  const {rowsToBlink, callback} = useBlinkRow();
  const {handleClickApplication} = useOpenApplicationCard({
    callbackAfterClose: (id: string) => callback(id),
  });

  const handleTableRowClick = (row: ApplicationInListV2) => {
    const {
      id,
      type: {whoIsClient},
      shortType: {kind}
    } = row;

    handleClickApplication({
      applicationId: id,
      initialTab: 0,
      whoIsClient,
      kind,
      refetch: refetchApplications,
    });
  };

  const getColor = ((application) => {
    switch (application.type.kind) {
      case 'preApplication':
        return "#D5D3CE";
      case  'application':
        return "#FFE254"
      case 'deal':
        return "#7F8DEF"
      default:
        return "#000"
    }
  });

  const {
    queryParams: {id, tab, mode, preApplicationId, applicationId, dealId},
  } = useQueryParams([{name: "id"}, {name: "tab"}, {name: "mode"}, {name: "preApplicationId"}, {name: "applicationId"}, {name: "dealId"}]);

  useEffect(() => {
    if (tab === "applications" && applicationId && mode !== "pre-applications") {
      handleClickApplication({applicationId: applicationId as string, kind: KindType.APPLICATION})
    }
    if (tab === "applications" && preApplicationId === `${preApplicationId}`) {
      handleClickApplication({applicationId: preApplicationId as string, kind: KindType.PREAPPLICATION})
    }
    if (tab === "deals" && dealId) {
      handleClickApplication({applicationId: dealId as string, kind: KindType.DEAL})
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      {requestsList?.length > 0 || isLoading ? (
        <TableApplication
          data={requestsList}
          filter={filter}
          groupKey="stage"
          withStage={withStage}
          tableRowClick={handleTableRowClick}
          transformGroupTitle={(title, data) => (
            <StageTitle stageNum={data?.stageNo || 1} color={getColor(data)}>
              {title}
              {totalCounts && total ? (
                <span className={styles.counts}>{` (${totalCounts[ title ]}/${total})`}</span>
              ) : null}
            </StageTitle>
          )}
          isLoading={isLoading}
          refetchApplications={refetchApplications}
          blinkRows={rowsToBlink}
        />
      ) : (
        <div className={styles.empty}>Похожих заявок не найдено</div>
      )}
    </div>
  );
};
