import { ShowsViews, ShowsViewsFinishPayload } from "../../../../../types";
import { InfoRow, RadioButton, TransparentInput } from "../../../../UI";
import { createNumberMask } from "text-mask-addons";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import styles from "./index.module.scss"
import { useEffect, useLayoutEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import cn from "classnames";
import { CardApplicationMini } from "../../../../UI/Card/card-application/card-application-mini";
import { Plug } from "../plug/Plug";
import { formatThousand } from "../../../../../utils/formatters";

type Props = {
  isViews: boolean;
  item: ShowsViews;
  register: UseFormRegister<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  inputName: string;
  payload: ShowsViewsFinishPayload
  // handleOnChange: (value: string | number) => void;
}


const bolleanToString = (value) => {
  if (value === true) {
    return "true"
  }
  if (value === false) {
    return "false"
  }
  return undefined
}

export const CardFeedback = (
  {
    isViews,
    item,
    getValues,
    setValue,
    control,
    register,
    inputName,
    payload
  }: Props): JSX.Element => {
  const {
    offer,
    buyerContactId,
    buyerAgentContactId,
    sellerAgentContactId,
    sellerContactId,
    buyerNeedsHelpToSellHisObjectPreApplication,
    buyerNeedsHelpToSellHisObjectPreApplicationTemplate,
    sellerNeedsHelpToSellHisObjectPreApplication,
    sellerNeedsHelpToSellHisObjectPreApplicationTemplate,
    sellerNeedsHelpToAnotherNewObjectPreApplication,
    sellerNeedsHelpToAnotherNewObjectPreApplicationTemplate,
    buyerNeedsHelpToSellHisObject,
    sellerNeedsHelpToSellHisObject,
    sellerNeedsHelpToAnotherNewObject
  } = item

  const buyer = bolleanToString(buyerNeedsHelpToSellHisObject)
  const seller = bolleanToString(sellerNeedsHelpToSellHisObject)
  const sellerAnother = bolleanToString(sellerNeedsHelpToAnotherNewObject)

  const showOrViewDesigionOptions = () => [
    {label: `Да`, value: "true"},
    {label: `Нет`, value: "false"},
  ];

  const [offerValue, setOfferValue] = useState<number | undefined>(offer)

  const handleOnChange = (value) => {
    setOfferValue(value)
  }

  useLayoutEffect(() => {
    setValue("offer", offer)
    setValue("buyerNeedsHelpToSellHisObject", buyerNeedsHelpToSellHisObject)
    setValue("sellerNeedsHelpToSellHisObject", sellerNeedsHelpToSellHisObject)
    setValue("sellerNeedsHelpToAnotherNewObject", sellerNeedsHelpToAnotherNewObject)
  }, [buyerNeedsHelpToSellHisObject, item.buyerNeedsHelpToSellHisObject, item.sellerNeedsHelpToAnotherNewObject, item.sellerNeedsHelpToSellHisObject, offer, sellerNeedsHelpToAnotherNewObject, sellerNeedsHelpToSellHisObject, setValue])

  const preApp = isViews
    ? (sellerNeedsHelpToSellHisObjectPreApplication || sellerNeedsHelpToSellHisObjectPreApplicationTemplate)
    : (buyerNeedsHelpToSellHisObjectPreApplication || buyerNeedsHelpToSellHisObjectPreApplicationTemplate)
  const preAppAnother = sellerNeedsHelpToAnotherNewObjectPreApplication || sellerNeedsHelpToAnotherNewObjectPreApplicationTemplate

  const disabled = isViews
    ? (sellerContactId || sellerAgentContactId)
    : (buyerContactId || buyerAgentContactId)

  const debounced = useDebouncedCallback(handleOnChange, 100);

  const inputValue = useWatch({
    control,
    name: inputName,
    defaultValue: offerValue,
  });

  const radioBuyerValue = useWatch({
    control,
    name: "buyerNeedsHelpToSellHisObject",
    defaultValue: buyer,
  });

  const radioSellerValue = useWatch({
    control,
    name: "sellerNeedsHelpToSellHisObject",
    defaultValue: seller,
  });

  const radioSellerAnotherValue = useWatch({
    control,
    name: "sellerNeedsHelpToAnotherNewObject",
    defaultValue: sellerAnother,
  });

  const handleOnChangeWithDebounce = (value: number | string) => {
    debounced(value);
  };

  return (
    <div className={styles.column}>
      <form>
        <InfoRow
          label="Встречное предложение"
          classNames={{root: styles.infoRow}}
          aspect={{label: 10, content: 8}}
        >
          <TransparentInput
            register={register}
            placeholder="Указать"
            name="offer"
            value={inputValue}
            onChange={handleOnChangeWithDebounce}
            mask={createNumberMask({
              prefix: "",
              thousandsSeparatorSymbol: " ",
            })}
            disabled={!disabled}
            setValueForMask={setValue}
          />
        </InfoRow>
        <InfoRow
          label="Покупателю требуется помощь в продаже:"
          classNames={{root: styles.infoRow}}
          aspect={{label: 10, content: 8}}
        >
          {isViews
            ? <>
              <RadioButton
                name="sellerNeedsHelpToSellHisObject"
                control={control}
                options={showOrViewDesigionOptions()}
                handleOnChange={() => {
                }}
                className={styles.button}
                background="blue"
                canUnselect
                currentValue={radioSellerValue !== undefined ? `${radioSellerValue}` : undefined}
                disabled={!disabled}
                required
              />
              {payload.sellerNeedsHelpToSellHisObject === undefined &&
                <div className={styles.error}>Поле не заполнено</div>}
            </>
            : <>
              <RadioButton
                name="buyerNeedsHelpToSellHisObject"
                control={control}
                options={showOrViewDesigionOptions()}
                handleOnChange={() =>{}
                }
                className={styles.button}
                currentValue={radioBuyerValue !== undefined ? `${radioBuyerValue}` : undefined}
                background="blue"
                canUnselect
                disabled={!disabled}
                required
              />
              {payload.buyerNeedsHelpToSellHisObject === undefined &&
                <div className={styles.error}>Поле не заполнено</div>}
            </>
          }
        </InfoRow>
        {isViews &&
          <InfoRow
              label="Покупателю требуется помощь в продаже:"
              classNames={{root: styles.infoRow}}
              aspect={{label: 10, content: 8}}
            >
              <RadioButton
                name="sellerNeedsHelpToAnotherNewObject"
                control={control}
                options={showOrViewDesigionOptions()}
                handleOnChange={() => {
                }}
                className={styles.button}
                currentValue={radioSellerAnotherValue !== undefined ?`${radioSellerAnotherValue}` : undefined}
                background="blue"
                canUnselect
                disabled={!disabled}
                required
              />
              {payload.sellerNeedsHelpToAnotherNewObject === undefined &&
                <div className={styles.error}>Поле не заполнено</div>}
            </InfoRow>
        }
      </form>
      <div className={styles.row}>
        <div className={cn(styles.noPreAppLabel, {[ styles.preAppLabel ]: preApp})}>
          Будут созданы предзаявки:
        </div>
        <div className={styles.column} style={{gap: "12px"}}>
          {preApp
            ? <div>
              <CardApplicationMini
                application={preApp}
                classNames={{root: styles.card}}
              />
            </div>
            : <Plug plugText="Нет предзаявки" mini/>
          }
          {isViews &&
            <div>
              {preAppAnother
                ? <div>
                  <CardApplicationMini
                    application={preAppAnother}
                    classNames={{root: styles.card}}
                  />
                </div>
                : <Plug plugText="Нет предзаявки" mini/>
              }
            </div>}

        </div>
      </div>
    </div>
  )
}