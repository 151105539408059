import { Office } from "../../../../../../types";
import styles from "./index.module.scss";
import { CardOffice } from "../../../../../UI";

export const useColumns = () => [
  {
    accessor: "user",
    render: ({row}: { row: Office }) => {
      return (
        <div className={styles.root}>
          <CardOffice
            office={row}
            onCardClick={() => {}}
          />
        </div>
      )
    },
  },
];
