import cn from "classnames";
import { CollapseDown, Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { StatisticsBody } from "../../../../../../../../../../../types";
import { TextInfoRows } from "../../../../../../../../../../UI";
import { wrapInTooltip } from "./wrapInTooltip";

import styles from "./index.module.scss";
import { useForm } from "react-hook-form";
import { useGetPlatformDetails } from "../../../../../../../../../../../service/api/queries/real-estate-ad/useGetPlatformDetails";
import { DetalizationChart } from "../DetalizationChart";
import React from "react";

export type ObjectAdStatisticsType = {
	small?: boolean;
	collapse?: boolean;
	list: Pick<
		StatisticsBody,
		"defaultAdSum" | "extraServicesSum" | "totalSum" | "defaultPlacements" | "extraServicesPlacements"
	>;
	cards: Pick<
		StatisticsBody,
		"views" | "viewPrice" | "contactOpens" | "contactOpenPrice" | "leads" | "leadPrice"
	>;
	applicationId: string;
	feedId?: string;
	disableDetalization?: boolean;
	isYandexOrDomclick?: boolean;
};

export const RealEstateAdStatistics = ({
	small = false,
	list,
	cards,
	applicationId,
	feedId,
	disableDetalization,
	isYandexOrDomclick,
}: ObjectAdStatisticsType): JSX.Element => {
	const Wrapper = (children) => (
		<div className={cn(styles.container, { [styles.small]: small })}>{children}</div>
	);

	const {
		data: platformDetails,
		refetch: fetchPlatformDetails,
		isLoading,
	} = useGetPlatformDetails({
		applicationId,
		feedId: feedId as string,
		disableDetalization,
	});

	return (
		<div className={styles.root}>
			{/* {!collapse && !small ? <h3>Расходы</h3> : ""} */}
			{Wrapper(
				<>
					{/* <AdExpenses expenses={list.totalSum} list={list} /> */}

					<CollapseDown
						label="Статистика"
						classNames={{
							root: styles.cardsCollapse,
							labelText: styles.cardsLabel,
						}}
						onClick={fetchPlatformDetails}
					>
						{isLoading && (
							<div style={{ padding: "24px" }}>
								<Loader />
							</div>
						)}
						<ul className={styles.cardsContainer}>
							<TextInfoRows
								className={cn({ [styles.smallInfoRows]: small })}
								items={[
									{
										label: "Просмотров объявления:",
										value: <span className={styles.infoRow}>{platformDetails?.views}</span>,
										id: `ObjectAdStatistics_${1}`,
									},
									{
										label: "Стоимость просмотра:",
										// value: wrapInTooltip(cards.viewPrice),
										value: <span className={styles.unknown}>Неизвестно</span>,
										id: `ObjectAdStatistics_${2}`,
									},
								]}
							/>

							<TextInfoRows
								className={cn({ [styles.smallInfoRows]: small })}
								items={[
									{
										label: "Просмотров контакта:",
										value: <span className={styles.infoRow}>{platformDetails?.contacts}</span>,
										id: `ObjectAdStatistics_${3}`,
									},
									{
										label: "Стоимость просмотра:",
										// value: wrapInTooltip(cards.contactOpenPrice),
										value: <span className={styles.unknown}>Неизвестно</span>,
										id: `ObjectAdStatistics_${4}`,
									},
								]}
							/>

							<TextInfoRows
								className={cn({ [styles.smallInfoRows]: small })}
								items={[
									{
										label: "Кол-во звонков:",
										value: <span className={styles.infoRow}>{platformDetails?.calls}</span>,
										id: `ObjectAdStatistics_${5}`,
									},
									{
										label: "Стоимость звонка:",
										// value: wrapInTooltip(cards.leadPrice),
										value: <span className={styles.unknown}>Неизвестно</span>,
										id: `ObjectAdStatistics_${6}`,
									},
								]}
							/>

							<TextInfoRows
								className={cn({ [styles.smallInfoRows]: small })}
								items={[
									{
										label: "Кол-во добавл. в Избранное:",
										value: <span className={styles.infoRow}>{platformDetails?.addToFavorite}</span>,
										id: `ObjectAdStatistics_${5}`,
									},
									{
										label: "Стоимость лида:",
										// value: wrapInTooltip(cards.leadPrice),
										value: <span className={styles.unknown}>Неизвестно</span>,
										id: `ObjectAdStatistics_${6}`,
									},
								]}
							/>
						</ul>
					</CollapseDown>
					{!disableDetalization && (
						<div className={styles.detalizationContainer}>
							<DetalizationChart
								applicationId={applicationId}
								feedId={feedId as string}
								isYandexOrDomclick={isYandexOrDomclick}
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
};
