import { Dispatch, SetStateAction, useMemo } from "react";
import { Stage, ApplicationsFilter, LeadFiltersType, ContactFilter } from "../../../../types";

import { RequestFastFilters } from "../RequestFilters";
import { LeadFilters } from "../LeadFilters";
import { TrafficFilters } from "../TrafficFilters";
import { ContactsFilters } from "../../Contacts/ContactsFilters";

type Props = {
	curTab: number;
	setFilter: Dispatch<SetStateAction<ApplicationsFilter | LeadFiltersType | ContactFilter>>;
	setRequestStages: Dispatch<SetStateAction<Stage[]>>;
	requestStages: Stage[];
	filter: ApplicationsFilter | LeadFiltersType;
};

export const useLeadsAndDealsFilter = ({
	curTab,
	setFilter,
	setRequestStages,
	requestStages,
	filter,
}: Props) => {
	const currentFilter = useMemo((): JSX.Element => {
		switch (curTab) {
			case 0:
				return <></>;
			case 1:
				return <LeadFilters formSubmit={setFilter} filter={filter as LeadFiltersType} />;
			case 2:
				return (
					<RequestFastFilters
						formSubmit={setFilter}
						filter={filter as ApplicationsFilter}
						setRequestStages={setRequestStages}
						requestStages={requestStages}
					/>
				);
			default:
				return <></>;
		}
	}, [curTab, requestStages, filter]);

	return {
		currentFilter,
	};
};
