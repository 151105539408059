import { ShowsViewsStatus } from "../../../../../../types";
import { ReactComponent as IconWarn } from "../../../../../../assets/icons/alert.svg";
import { ReactComponent as IconWait } from "../../../../../../assets/icons/clock.svg";
import { ReactComponent as IconSuccess } from "../../../../../../assets/icons/iconCheckGreen.svg";
import { ReactComponent as IconCancel } from "../../../../../../assets/icons/iconCancel.svg";
import { ReactComponent as IconFinalSuccess } from "../../../../../../assets/icons/iconSuccess (2).svg";

export const getIcon = (
  status: ShowsViewsStatus,
  isConfirmed?: boolean,
  actIsSigned?: boolean,
  plannedDate?: Date
) => {
  const expiredDate = new Date >= (plannedDate ?? new Date())
  if (expiredDate && status === "waiting") {
    return <IconWarn/>
  }
  if (status === "waiting") {
    return <IconWait/>
  }
  if (status === "finished") {
    return <IconSuccess/>
  }
  if (status === "successFinished") {
    return <IconFinalSuccess/>
  } else {
    return <IconCancel/>
  }
}