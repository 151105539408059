import { memo } from "react";
import { CollectionItem, KindType, RealEstate } from "../../../../../../../../types";

import { CardObjectSample } from "../../../../../../../UI";
import { useOpenRealEstate } from "../../../../../../../../utils/objects";
import { useOpenDrawerRealEstate } from "../../../../../../../../utils/hooks";

import styles from "./index.module.scss";

type Props = {
	isCompilation: boolean;
	isCompare: boolean;
	sampledApplicationId: string;
	realEstate: RealEstate;
	refetch: () => void;
	isBlindOpen: boolean;
	disableDelete?: boolean;
	isHovered?: boolean
	collectionItem?: CollectionItem
};

export const ObjectColumnItem = memo(
	({
		realEstate,
		refetch,
		isBlindOpen,
		isCompare,
		isCompilation,
		disableDelete,
		sampledApplicationId,
		isHovered,
		collectionItem,
	}: Props): JSX.Element => {
		const { applicationId: currentApplicationRealEstateId } = realEstate;

		const openObject = useOpenRealEstate({ options: { inNewTab: true } });
		const { handleOpenDrawerRealEstate } = useOpenDrawerRealEstate({
			onClose: refetch,
			refetch: refetch,
			kind: realEstate.shortType?.kind ?? KindType.APPLICATION
		});
		const handleClickCard = () => handleOpenDrawerRealEstate(currentApplicationRealEstateId);

		return (
			<div className={styles.root}>
				<CardObjectSample
					onAuxClick={() => openObject(realEstate)}
					onCardClick={handleClickCard}
					objectData={realEstate}
					isShortCard
					isInSample
					imgOnly={!isBlindOpen}
					disableDelete={disableDelete}
					isCompare={isCompare}
					isCompilation={isCompilation}
					sampledApplicationId={sampledApplicationId}
					isHovered={isHovered}
					collectionItem={collectionItem}
				/>
			</div>
		);
	}
);
