import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { Comment } from "./comment"

import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy-variant.svg"
import ArrowDown from "../../../../assets/icons/arrowDown.svg"

import styles from "./index.module.scss";

type Props = {
    id: string;
};


export const Share = ({ id }: Props): JSX.Element => (
    <><div className={styles.container} id={id}>
        <h2>Делись</h2>
        <div className={styles.grid}>
            <div className={styles.contentLeft}>
                <h3>Скидка 5% для коллеги и кэшбек для тебя с первой оплаты</h3>
                <p>
                    1. Отправь ссылку-приглашение коллеге, частному агенту, руководителю агентства недвижимости.
                </p>
                <p>
                    2. Попросите перейти по ссылке, и зарегистрироваться в СДЕЛКЕ
                </p>
                <p>
                    3. Получи бонус на свой баланс в размере 100% от первой оплаты приглашенного пользователя.
                </p>

                <div className={styles.buttonContainer}>
                    <div className={styles.proposal}>
                        <span className={styles.titleProposal}>Осеннее предложение</span>
                    </div>

                    <Button
                        Icon={CopyIcon}
                    >
                        <span className={styles.buttonText}>Скопировать ссылку</span>
                    </Button>
                </div>
            </div>

            <div className={styles.contentRight}>
                <h3>Отправь свой видео-отзыв о СДЕЛКЕ и получи скидку 50% на оплату следующего месяца</h3>
                <p>
                    1. Отправь ссылку-приглашение коллеге, частному агенту, руководителю агентства недвижимости.
                </p>
                <p>
                    2. Попросите перейти по ссылке, и зарегистрироваться в СДЕЛКЕ
                </p>
                <p>
                    3. Получи бонус на свой баланс в размере 100% от первой оплаты приглашенного пользователя.
                </p>

                <div className={styles.buttonContainer}>
                    <div className={styles.proposal}>
                        <span className={styles.titleProposal}>Осеннее предложение</span>
                    </div>

                    <span>sales@sdelka-crm.ru</span>
                </div>

            </div>
        </div>

        <Comment />
    </div>

        <div className={styles.moreInfo}>
            <p>Ещё (4)</p>
            <img src={ArrowDown} alt="" />
        </div>
    </>
)