import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";
import { InviteUpdate } from "../../../../types";

const DEFAULT_MN = "sdelka/invite/update-invitation/DEFAULT_MN";

type UseInviteUpdate = {
	onSuccess?: () => void;
	onError?: (error: string) => void;
};

export const useInviteUpdate = ({ onSuccess, onError }: UseInviteUpdate) => {
	const handleError = (error: AxiosError) => {
		const message = axiosError({ error });

		if (onError) {
			onError(message);
		}
	};

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: ({ id, data }: { id: string; data: InviteUpdate }) => api.invites.update(id, data),
		onSuccess,
		onError: handleError,
	});

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
	};
};
