import styles from "./index.module.scss"

type Props = {
  plugText: string
}

export const Plug = ({plugText}: Props): JSX.Element => {


  return (
    <div className={styles.root}>
      <div className={styles.text}>
        {plugText}
      </div>
    </div>
  )
}