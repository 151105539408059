import { RealEstate } from "../../../../../../types";

import { CardObjectPublic, VoterFormLight } from "../../../../../UI";

import styles from "./index.module.scss";

type Props = {
	clientObject: RealEstate;
	onCardClick?: (id: string) => void;
};

export const CardObjectVote = ({ clientObject, onCardClick }: Props): JSX.Element => (
	<div className={styles.root}>
		<CardObjectPublic
			classNames={{ root: styles.card }}
			clientObject={clientObject}
			onCardClick={onCardClick}
			variant="horizontal"
		/>

		<div className={styles.form}>
			<VoterFormLight onSubmitForm={() => console.log("su")} />
		</div>
	</div>
);
