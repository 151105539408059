import { ReactNode, useEffect } from "react";
import { useHistory } from "react-router";
import { selectAccounts, selectRegisterSteps } from "../../../../../service/redux/selectors";
import { useSelector } from "../../../../../service/redux/useSelector";

type Props = {
  forUnauthorized?: ReactNode;
  forAuthorized?: ReactNode;
};

export const RegistrationAccessController = ({forUnauthorized, forAuthorized}: Props): JSX.Element => {
  const {authorized} = useSelector(selectAccounts);
  const {currentStep} = useSelector(selectRegisterSteps);
  const history = useHistory();

  useEffect(() => {
    const shouldRestrictAccess = authorized && currentStep === "first-step";

    if (shouldRestrictAccess) {
      history.replace("/");
    }
  }, [authorized, currentStep, history]);

  if (!authorized) {
    return <div>{forUnauthorized}</div>;
  }

  return <div>{forAuthorized}</div>;
};
