export const translateContactType = (type?: string) => {
  if (type) {
    switch (type) {
      case "agency":
        return "агенство недвижимости"
      case "customer":
        return  "клиент"
      case "personal":
        return "личный"
      case  "other":
        return "другое"
      default:
        return ""
    }
  }
}