import { useDispatch } from "react-redux";
import { CancelShowWiewModal } from "./CancelShowViewModal";
import { openModal } from "../../../../../../../../service/redux/modules/modal/modal.action";


type Return = {
  openCancelShowViewModal: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type useCancelShowViewModal = (isViews: boolean, id: string, onSuccess: () => void) => Return

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const useCancelShowViewModal: useCancelShowViewModal = (isViews, id, onSuccess) => {
  const dispatch = useDispatch()
  const openCancelShowViewModal = () => {
    dispatch(
      openModal(
        {
          width: 660,
          body: <CancelShowWiewModal
            isViews={isViews}
            showViewId={id}
            onSuccess={onSuccess}
          />
        }
      ))
  }

  return {
    openCancelShowViewModal
  }
}

