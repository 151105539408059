import { useState } from "react";
import { Placemark, withYMaps, YMapsApi } from "react-yandex-maps";

import { iconTemplate } from "../../../map-utils/utils";
import { usePropsVariables, useBalloon } from "./helpers";
import { getPriceLabel } from "../../../../../../utils/clientObjects";
import { PointType } from "../../../../../../types";

import "./PointWithCluster.scss";

type Props = {
	point: PointType;
	getContactPhone?: (objectId: string) => Promise<string>;
	selectPointWithObjects?: (point: PointType) => void;
	clickOnTitle?: (id: string) => void;
	priceType: string;
	onUnselectPoint: () => void;
	ymaps?: YMapsApi;
};

export const PointWithBalloon = withYMaps(
	({
		point,
		// getContactPhone,
		// selectPointWithObjects,
		clickOnTitle,
		priceType,
		onUnselectPoint,
		ymaps,
	}: Props): JSX.Element => {
		const { lat, lon, realEstateId, applicationId, pricePerUnit, price } = usePropsVariables({ point });

		const clickOnTitleListener = () => {
			if (clickOnTitle) {
				clickOnTitle(realEstateId);
			}
		};

		const clickOnContactButtonListener = () => {
			console.log("click");
		};

		const { renderContent } = useBalloon({
			id: realEstateId,
			applicationId,
			clickOnContactButtonListener,
			clickOnTitleListener,
		});

		const currentPrice = priceType === "price" ? getPriceLabel({ price }) : getPriceLabel({ pricePerUnit });
		const iconContent = `${currentPrice} р`;
		const [wasOpen, setWasOpen] = useState(false);

		const handleSetPlaceMark = (ref) => {
			if (!wasOpen && ref?.balloon) {
				setWasOpen(true);
				ref.balloon.open();
			}
		};

		return (
			// @ts-ignore
			<Placemark
				properties={{ iconContent, balloonContentBody: renderContent }}
				geometry={[lat, lon]}
				onClick={() => {}}
				onBalloonClose={onUnselectPoint}
				options={{
					iconLayout: ymaps?.templateLayoutFactory.createClass(iconTemplate),
					iconShape: {
						type: "Rectangle",
						coordinates: [
							[-78, -34],
							[0, 0],
						],
					},
				}}
				instanceRef={(ref) => handleSetPlaceMark(ref)}
			/>
		);
	}
);
