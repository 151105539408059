import { useDispatch } from "react-redux";
import { useState } from "react";
import { Condition, RealEstate } from "../../../../types";
import { UseGetRealEstateCollections } from "../../../../service/api/mutations/real-estate/useGetRealEstateCollections";
import { SearchInput } from "./helpers";
import cn from "classnames";
import styles from "./index.module.scss";
import { Button, Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { useAddObjectToCompilation } from "../../../../service/api/mutations";
import { CardObjectBuyElement } from "../../Card";

type Props = {
  realEstateId: string
  objectData: RealEstate
}

export const AddToCollection = (
  {
    realEstateId,
    objectData,
  }: Props
): JSX.Element => {

  const dispatch = useDispatch()
  const [selectedCollectionId, setSelectedCollectionId] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const title = "Добавление в подборку"

  const condition: Condition[] = [
    {
      field: "iAmResponsibleUser",
      operator: "eq",
      value: true
    },
    {
      field: "fullTextSearch",
      operator: "like",
      value: searchValue
    },
  ]

  const {mutate: mutateAdd, onSuccess, onError} = useAddObjectToCompilation({});

  const handleOnAdd = (data: RealEstate, collectionApplicationId: string) => {
    const {applicationId, parserData, id} = data;

    if (applicationId) {
      mutateAdd({
        applicationId: collectionApplicationId,
        clientObjectApplicationIds: [applicationId],
      });
      return;
    }

    if (parserData && id) {
      mutateAdd({applicationId: collectionApplicationId, parserObjectIds: [id]});
    }
  };

  const onApply = (data, collectionApplicationId) => {
    handleOnAdd(data, collectionApplicationId)
    dispatch(closeModal())

  }


  const handleSelectCollection = (id?: string) => {
    if (id === selectedCollectionId) {
      setSelectedCollectionId("")
    }
    setSelectedCollectionId(id ?? "")
  }

  const {collections, isLoading, isFetching} = UseGetRealEstateCollections({condition})

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
  }

  return (
    <div>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.search}>
        <SearchInput
          name="Поиск"
          placeholder="Поиск"
          prepend="search"
          onChange={handleSearchChange}
        />
      </div>
      {isLoading
        ? <div
          className={cn(styles.loading, "animate__animated", "animate__faster", "animate__fadeIn", {
            [ styles.loadingHide ]: !isFetching,
          })}
        >
          <Loader/>
        </div>
        : <div className={styles.collections}>
          {collections && collections.length > 0 ? collections.map((collection) => (
              <div
                key={collection.applicationId}
                onClick={() => handleSelectCollection(collection.applicationId)}
                className={styles.item}
              >
                <CardObjectBuyElement
                  onCardClick={() => {
                  }}
                  applicationId={objectData.applicationId}
                  collection={collection}
                  isFetchingCollection={isFetching}
                  classnames={{
                    body: (selectedCollectionId === collection.applicationId ? styles.cardBody && styles.selected : styles.cardBody),
                    countBlock: styles.countBlock
                  }}
                />
              </div>
            ))
            : <div className={styles.noFound}>
              Ничего не найдено
            </div>
          }
        </div>
      }
      <div className={styles.row}>
        <Button onClick={() => onApply(objectData, selectedCollectionId)} disabled={selectedCollectionId.length === 0}>
          Прикрепить
        </Button>
      </div>
    </div>
  )
}