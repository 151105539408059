import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import isEqual from "lodash.isequal";
import cn from "classnames";

import { ButtonSquare } from "../../../../UI";
import { DateSpan, TaskType, Users } from "./components";
import { useWindowWidth } from "../../../../../utils/hooks";
import { useTasksContext } from "../../../../../service/context/Tasks";
import { defaultTopFilters, prepareFormValues, useFilterNecessityChecker } from "./helpers";
import { useLoadTasksTypes } from "../../../../../service/api/queries";
import { DefaultTasksFiltersType } from "../../../../../types";

import { ReactComponent as CancelIcon } from "../../../../../assets/icons/cancel.svg";
import styles from "./index.module.scss";

export const TasksTopFilters = (): JSX.Element => {
	const { filter, setFilter, roleRelatedFilters } = useTasksContext();

	const { getValues, setValue, register, control, handleSubmit, reset, watch } = useForm({
		defaultValues: { ...filter, ...defaultTopFilters },
	});

	const [showBtn, setShowBtn] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const { types, isFetching: isTypesFetching } = useLoadTasksTypes();

	const { isFilterNecessary } = useFilterNecessityChecker();

	const width = useWindowWidth();

	useEffect(() => {
		if (width > 1600 && !expanded) {
			setExpanded(true);
		} else if (expanded) {
			setExpanded(false);
		}
	}, [width]);

	const currentForm = watch();
	const formOnLastSubmit = useRef(getValues());

	useEffect(() => {
		// if filter changed, we should show a button
		if (!isEqual(formOnLastSubmit.current, currentForm)) setShowBtn(true);
		else setShowBtn(false);
	}, [currentForm]);

	const onSubmit = (values) => {
		const preparedValues = prepareFormValues(values, roleRelatedFilters);

		setFilter({
			...filter,
			...preparedValues,
		});

		formOnLastSubmit.current = currentForm;
		setShowBtn(false);
	};

	const handleReset = () => reset(defaultTopFilters);

	const displayFieldIfNecessary = (fieldFilter: keyof DefaultTasksFiltersType) =>
		isFilterNecessary(fieldFilter) ? { display: "block" } : { display: "none" };

	return (
		<form
			className={cn(styles.root, {
				[styles.expanded]: expanded,
			})}
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className={styles.filterLine}>
				<div style={displayFieldIfNecessary("taskType")}>
					<TaskType
						types={types}
						placeholder="Тип задачи"
						name="taskType"
						isLoading={isTypesFetching}
						control={control}
						getValues={getValues}
						setValue={setValue}
						register={register}
					/>
				</div>

				<div style={displayFieldIfNecessary("deadlineFrom")}>
					<DateSpan
						dateNotSelected="Дедлайн"
						name={["deadlineFrom", "deadlineTo"]}
						getValues={getValues}
						setValue={setValue}
						register={register}
						control={control}
					/>
				</div>

				<div style={displayFieldIfNecessary("executorUserId")}>
					<Users
						name="executorUserId"
						placeholder="Исполнитель"
						setValue={setValue}
						getValues={getValues}
						register={register}
						control={control}
					/>
				</div>

				<div style={displayFieldIfNecessary("participantUserId")}>
					<Users
						name="participantUserId"
						placeholder="Участник задачи"
						setValue={setValue}
						getValues={getValues}
						register={register}
						control={control}
					/>
				</div>

				<div style={displayFieldIfNecessary("creatorId")}>
					<Users
						name="creatorId"
						placeholder="Создатель"
						setValue={setValue}
						getValues={getValues}
						register={register}
						control={control}
						withSystemAsUser
					/>
				</div>

				<div style={displayFieldIfNecessary("createdAtFrom")}>
					<DateSpan
						name={["createdAtFrom", "createdAtTo"]}
						getValues={getValues}
						setValue={setValue}
						register={register}
						dateNotSelected="Дата создания"
						control={control}
					/>
				</div>
			</div>

			<Button
				variant="text"
				classNames={{ root: styles.resetBtn, icon: styles.cancelIcon }}
				onClick={handleReset}
				Icon={CancelIcon}
			>
				<span>Сбросить</span>
			</Button>

			{showBtn && (
				<ButtonSquare type="submit" classNames={{ root: styles.submitBtn }}>
					Применить
				</ButtonSquare>
			)}
		</form>
	);
};
