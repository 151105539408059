import { useEffect, useMemo, useState } from "react";
import { useForm, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { CategoryEnum } from "../../../../types";
import cn from "classnames";

import { GroupedCheckbox, Popup, RadioButton } from "../../index";
import {
	objectCommercialVariant,
	objectCreationResidentialVariant,
	objectResidentialVariant
} from "../../../../utils/clientObjects";

import styles from "./index.module.scss";

type PropsType = any & {
	category: CategoryEnum[];
};

type Props = {
	register: UseFormRegister<PropsType>;
	setValue: UseFormSetValue<PropsType>;
	placeholder?: string;
	onChangeObjectType?: (values: CategoryEnum[], prevValues: CategoryEnum[]) => void;
	defaultValues?: CategoryEnum[];
	variant?: "black" | "light";
	disabled?: boolean;
	notMulti?: boolean;
	allowEmpty?: boolean;
	isCreation?: boolean
};

const commercialLabel = "Коммерческая";
const residentialLabel = "Жилая";

const objectTypeName = "objectType";

const DEFAULT_FORM_VALUE = {
	[objectTypeName]: residentialLabel,
};

const objectType = [
	{ value: residentialLabel, label: residentialLabel },
	{ value: commercialLabel, label: commercialLabel },
];

export const PopupObjectType = ({
	register,
	setValue,
	onChangeObjectType,
	placeholder = "Тип объекта",
	defaultValues = [],
	variant = "black",
	disabled,
	notMulti,
	allowEmpty = true,
	isCreation
}: Props): JSX.Element => {

	const listValues = isCreation === true ? objectCreationResidentialVariant : objectResidentialVariant
	const [objectOptions, setObjectOptions] = useState(listValues);
	const [open, setOpen] = useState(true)

	const { control: radioControl, setValue: setValueRadio } = useForm({
		defaultValues: DEFAULT_FORM_VALUE,
	});

	const currentTab = useWatch({
		control: radioControl,
		name: objectTypeName,
		defaultValue: residentialLabel,
	});

	useEffect(() => {
		if (defaultValues && defaultValues.length) {
			const foundObject = objectCommercialVariant.some((object) =>
				object.some((comVariant) => comVariant.value === defaultValues[0])
			);
			if (foundObject) {
				if (currentTab !== commercialLabel) {
					setValueRadio(objectTypeName, commercialLabel);
				}
			} else if (currentTab !== residentialLabel) {
				setValueRadio(objectTypeName, residentialLabel);
			}
		}
	}, [defaultValues]);

	const colorVariant = () => (defaultValues && defaultValues?.length > 0 ? "black" : variant);

	const labels = useMemo(() => {
		if (defaultValues.length > 0 && objectOptions) {
			const findOption = objectOptions.find((option) =>
				option.map((category) => category.value).includes(defaultValues[0])
			);
			if (findOption) {
				return findOption
					.filter((category) => defaultValues.includes(category.value))
					.map((category) => category.label);
			}
		}

		return [];
	}, [defaultValues, objectOptions]);

	useEffect(() => {
		setObjectOptions(currentTab === commercialLabel ? objectCommercialVariant : listValues);
	}, [currentTab]);

	const handleSetValue = (values: string[]) => {
		if (notMulti && values.length > 1) {
			setValue("category", [values[1]]);
		} else {
			setValue("category", values);
		}
	};

	const onChange = (values: string[]) => {
		handleSetValue(values);
		if (onChangeObjectType) {
			onChangeObjectType(values as CategoryEnum[], defaultValues);
		}

		setOpen(false)
		setTimeout(() => {
			setOpen(true)
		}, 100)
	};

	const label = labels?.length > 0 ? labels.join("/") : placeholder;

	return (
		<Popup
			openTrigger={<div className={cn(styles.typeWrapper, styles[colorVariant()])}>{label}</div>}
			options={{
				withArrow: true,
			}}
			classNames={{
				root: styles.popupRoot,
				card: styles.popupCard,
			}}
			disabled={disabled}
			forceClose={!open}
		>
			<div className={styles.objectType}>
				<RadioButton name="objectType" options={objectType} control={radioControl} textColor="blue" />
				<div className={styles.objectTypeCheckboxes}>
					<GroupedCheckbox
						name="category"
						register={register}
						setValue={setValue}
						options={objectOptions}
						onChange={onChange}
						defaultValue={defaultValues}
						allowEmpty={allowEmpty}
					/>
				</div>
			</div>
		</Popup>
	);
};
