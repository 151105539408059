import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";
import { useWatch } from "react-hook-form";

import { InfoRow, JKSelect } from "../../../../../../../../index";
import { RequiredDots } from "../../RequiredDots";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const RemoteJKSelect = ({ form, field }: Props): JSX.Element => {
	const { register, control, setValue, getValues } = form;
	const {
		label,
		reqiredFor,
		dataType: { nameGroup = [] },
	} = field;

	const [avitoWatcher, cianWatcher, yandexWatcher] = [
		useWatch({
			name: nameGroup[0],
			control,
			defaultValue: getValues(nameGroup[0]) || "",
		}),
		useWatch({
			name: nameGroup[1],
			control,
			defaultValue: getValues(nameGroup[1]) || "",
		}),
		useWatch({
			name: nameGroup[2],
			control,
			defaultValue: getValues(nameGroup[2]) || "",
		}),
	];

	if (!nameGroup.length) {
		return <></>;
	}

	return (
		<>
			<InfoRow label={label} labelFont="ubuntu" aspect={{ label: 1, content: 1 }}>
				<RequiredDots required={reqiredFor} />

				<JKSelect
					name={nameGroup[0]}
					value={avitoWatcher}
					source="avito"
					setValue={setValue}
					register={register}
					placeholder="Введите ЖК Авито"
					classNames={{
						input: styles.input,
						optionNotSelected: styles.notSelectedOption,
					}}
				/>
			</InfoRow>

			<InfoRow labelFont="ubuntu" variant="noLabel" aspect={{ label: 1, content: 1 }}>
				<JKSelect
					name={nameGroup[1]}
					value={cianWatcher}
					source="cian"
					setValue={setValue}
					register={register}
					placeholder="Введите ЖК Циан"
					classNames={{
						input: styles.input,
						optionNotSelected: styles.notSelectedOption,
					}}
				/>
			</InfoRow>

			<InfoRow variant="noLabel" labelFont="ubuntu" aspect={{ label: 1, content: 1 }}>
				<JKSelect
					name={nameGroup[2]}
					value={yandexWatcher}
					source="yandex"
					setValue={setValue}
					register={register}
					placeholder="Введите ЖК Яндекса"
					classNames={{
						input: styles.input,
						optionNotSelected: styles.notSelectedOption,
					}}
				/>
			</InfoRow>
		</>
	);
};
