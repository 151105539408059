import { useDispatch } from "react-redux";
import { H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { CreateContactModalForm } from "./components";
import { openModal } from "../../../../../../../../../service/redux/modules/modal/modal.action";

import styles from "./styles.module.scss";
import { Contacts } from "../../../../../../../Pin/PinContact/Contacts";

export const useContactModal = () => {
	const dispatch = useDispatch();

	const openContactModal = () =>
		dispatch(
			openModal({
				body: <Contacts onPin={() => {}} isCreation />,
				width: 1000,
			})
		);

	return {
		openContactModal,
	};
};
