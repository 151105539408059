import { ProductTypeEnum } from "../cart";
import { SelectOption } from "../inputs";

export enum CategoryEnum {
  service = 'service',
  onlineAds = 'onlineAds',
  offlineAds = "offlineAds",
}

export const servicesCategory: Record<CategoryEnum, string> = {
  service: "Услуги",
  onlineAds: "Онлайн реклама",
  offlineAds: "Офлайн реклама"
}

export type ProductTile = {
  id: string
  photo: string
  title: string
  price: number
  priceWithDiscount?: number
  isInCart?: boolean
  productType: ProductTypeEnum,
  category: CategoryEnum
  tariffOptions: SelectOption[]
  tariffLabel: string
}

export type Product = ProductTile & {
  description?: string | TrustedHTML
}

export type PurchasedProduct = Product & {
  isPurchased?: true
  availableAmount?: number
  tariff?: string
}

export type ProductListResponse = {
  data: ProductTile[]
  total: number
}

export enum AppliedProductStatusEnum {
  inProgress = "inProgress",
  success = "success"
}

export type AppliedProduct = PurchasedProduct & {
  status?: AppliedProductStatusEnum
  createdAt?: Date
  applicationTitle?: string
}

export const appliedProductStatus: Record<AppliedProductStatusEnum, string> = {
  success: "Готово",
  inProgress: "В процессе"
}

export type PurchasedProductsResponse = {
  data: PurchasedProduct[]
  total: number
}

export type AvailableToPurchaseResponse = {
  data: Product[]
  total: number
}

export type AppliedProductsResponse = {
  data: AppliedProduct[]
  total: number
}

export type ProductsBuyHistoryItem = {
  id: string
  productName: string
  purchaseDate: Date
  price: number
  tariff: string
}

export type ProductsBuyHistoryResponse = {
  data: ProductsBuyHistoryItem[]
  total: number
}
