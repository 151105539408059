import { UserNotLoaded } from "./components";
import { MainInfo } from "./components/main";
import { Characteristics } from "./components/characteristics/Characteristics";
import { PersonalCardStickyFooter } from "./components/sticky-footer/PersonalCardStickyFooter";
import { IUserEditFormResponse } from "../../../types";

type Props = {
  refetchAll: () => void;
  user?: IUserEditFormResponse
  isFetching: boolean
};

export const PersonalCard = ({refetchAll, user, isFetching}: Props): JSX.Element => {

  const CAN_EDIT = user?.canEdit

  if (!isFetching && !user) {
    return <UserNotLoaded refetch={refetchAll}/>;
  }

  return (
    <>
      {user &&
        <div style={{padding: "16px 40px"}}>
          <MainInfo user={user} canEdit={CAN_EDIT} refetch={refetchAll}/>
          <Characteristics user={user} canEdit={CAN_EDIT} refetch={refetchAll}/>
        </div>}
      <PersonalCardStickyFooter user={user} canEdit={CAN_EDIT} refetch={refetchAll}/>
    </>
  );
};
