import { CLOSE_GALLERY_SLIDER, GallerySliderAction, OPEN_GALLERY_SLIDER } from "./gallery-slider.action";
import { GallerySliderTInitialState } from "../../types";

const initialState: GallerySliderTInitialState = {
	isOpen: false,
	photos: [],
};

export const gallerySliderReducer = (state = initialState, action: GallerySliderAction) => {
	switch (action.type) {
		case OPEN_GALLERY_SLIDER: {
			return {
				...state,
				isOpen: true,
				photos: action.payload.photos,
				ids: action.payload.ids,
				clickedPhoto: action.payload.clickedPhoto,
				canUpdatePhotos: action.payload.canUpdatePhotos,
			};
		}
		case CLOSE_GALLERY_SLIDER: {
			return {
				...state,
				isOpen: false,
				photos: [],
				clickedPhoto: undefined,
			};
		}
		default:
			return state;
	}
};
