import { AppliedProduct, appliedProductStatus } from "../../../../types/product";
import styles from "./index.module.scss"
import { ReactComponent as Avatar } from "../../../../assets/icons/office-briefcase-svgrepo-com 1.svg";
import cn from "classnames";
import { ReactComponent as ToCart } from "../../../../assets/icons/Shopping_Cart.svg";
import { ReactComponent as Check } from "../../../../assets/icons/Check_Big.svg";
import { useAddToCart, useDeleteFromCart } from "../../../../service/api/mutations";
import { useAddInCartModal } from "../../../pages/Servises/cart/helpers/addInCartModal/useAddInCartModal";
import { formatDateWithTime } from "../../../../utils/date";
import { formatThousand } from "../../../../utils/formatters";

type Props = {
  product: AppliedProduct
  onCardClick?: (id: string) => void
  refetch: () => void
  type?: "available" | "purchased" | "applied"
}

export const CardProductForTable = (
  {
    product,
    onCardClick,
    refetch,
    type,
  }: Props) => {
  const {
    title,
    id,
    photo,
    productType,
    tariffOptions,
    price,
    isInCart,
    tariffLabel,
    tariff,
    isPurchased,
    availableAmount,
    priceWithDiscount,
    applicationTitle,
    createdAt,
    status
  } = product

  const {mutate} = useAddToCart({
    onSuccess: () => {
      refetch()
    }
  })

  const discount = priceWithDiscount ? `-${Math.floor(((price - priceWithDiscount) / price) * 100)}%` : undefined

  const {openAddInCartModal} = useAddInCartModal({
    ids: [id],
    type: productType,
    title: "Выберите условия",
    action: mutate,
    tariffLabel,
    tariffOptions
  })

  const handleOnCardClick = () => {
    if (onCardClick) {
      onCardClick(id)
    }
  }

  const {mutate: mutateDelete} = useDeleteFromCart({
    onSuccess: refetch
  })

  const handleClick = () => {
    if (isInCart) {
      mutateDelete(id ?? "")
    } else {
      openAddInCartModal()
    }
  }

  const countExtra = (tariffOptions && tariffOptions.length <= 3) ? undefined : `+${tariffOptions.length - 2}`
  const tariffOptionsOptimised = (tariffOptions && tariffOptions.length > 3) ? tariffOptions.slice(0, 2) : tariffOptions
  const tariffText = (value: string) => {
   return  tariffOptions?.find(el => el.value === value)?.label ?? ""
  }

  return (
    <div>
      <div className={styles.tableContent} onClick={handleOnCardClick}>
        <div className={styles.rowMain}>
          {photo
            ? <img src={photo} alt={"avatar"}/>
            : <div className={styles.logo}><Avatar/></div>
          }
          {discount && type === "available" &&
            <div className={styles.discount}>
              {discount}
            </div>
          }
          <div className={cn(styles.tableCol, {[ styles.tableColWithOutCard ]: type !== "available"})}>
            <div className={styles.tableRow}>
              <div className={styles.title}>
                {title}
              </div>
              {type === "available" &&
                <div className={cn(styles.title, {[ styles.priceWhenIsDiscount ]: priceWithDiscount})}>
                  {`${formatThousand(priceWithDiscount || price)} ₽`}
                </div>}
              {type === "purchased" &&
                <div className={styles.available}>
                  {`Доступно ${availableAmount}`}
                </div>
              }
              {type === "applied" &&
                <div className={styles.status}>
                  {appliedProductStatus[ status ?? "inProgress" ]}
                </div>
              }
            </div>
            <div className={styles.tableRow}>
              <div className={styles.optionsRow}>
                {type === "available" ?
                  <>
                    {tariffOptions && tariffOptionsOptimised.map(option => (
                      <div className={styles.option}>
                        {option.label}
                      </div>
                    ))}
                    {countExtra &&
                      <div className={styles.countExtra}>
                        {countExtra}
                      </div>}
                  </>
                  : <>
                    <div className={styles.option}>
                      {tariffText(tariff ?? "")}
                    </div>
                  </>
                }
              </div>
              {priceWithDiscount && type === "available" &&
                <div className={styles.priceWithoutDiscount}>
                  {`${formatThousand(price)} ₽`}
                </div>}
            </div>
            {type === "applied" &&
              <div className={styles.tableRow} style={{marginTop: "14px"}}>
                <div className={styles.applicationTitle}>
                  {applicationTitle}
                </div>
                <div className={styles.created}>
                  {formatDateWithTime(createdAt ?? new Date())}
                </div>
              </div>
            }
          </div>
        </div>
        {type === "available" &&
          <>
            <div
              className={cn(styles.cart, {[ styles.purchased ]: isInCart})}
              onClick={(event) => {
                event?.stopPropagation();
                if (onCardClick) {
                  handleOnCardClick()
                } else {
                  handleClick()
                }
              }}
            >
              <>
                {isInCart ? <Check/> : <ToCart/>}
              </>
            </div>
          </>

        }
      </div>
    </div>
  )
}