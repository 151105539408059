import {
  ApplicationUpdatePayload,
  KindType,
  RealEstate,
  RealEstateParserPhones,
  RealEstateUpdatePriceWithDiscount
} from "../../../../../../../types";
import { ReactNode, useRef, useState } from "react";
import { useCompare, useOpenTaskModal, useQueryParams } from "../../../../../../../utils/hooks";
import { useAddObjectToCompilation, useUpdateApplication } from "../../../../../../../service/api/mutations";
import { useColdCall } from "../StickyMenu/helpers";
import styles from "./index.module.scss";
import { ReactComponent as AddToCompilationIcon } from "../../../../../../../assets/icons/iconAddToCompilation.svg";
import { ReactComponent as IconPriceWithDiscount } from "../../../../../../../assets/icons/iconPriceWithDiscount.svg";
import { ReactComponent as IconOpenInNew } from "../../../../../../../assets/icons/openInNewBlue.svg";
import { ReactComponent as IconCompetitorAnalysis } from "../../../../../../../assets/icons/iconCompetitorAnalysis.svg";
import { ReactComponent as IconRenewal } from "../../../../../../../assets/icons/iconRenewal.svg";
import { ReactComponent as IconHouseShow } from "../../../../../../../assets/icons/iconHouseShow.svg";
import { ReactComponent as IconEdit } from "../../../../../../../assets/icons/iconEdit.svg";
import { ReactComponent as IconCancel } from "../../../../../../../assets/icons/close.svg";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { Popup } from "../../../../../../UI";
import { useDiscountModal } from "../../../../../../UI/discount-modal";
import { openRealEstatePublicPage } from "./helpers/openRealEstatePublicPage";
import { EstateBaseRow } from "../../../../../../UI/Card/CardObject/components/Owner/EstateBaseRow";
import { parsePhone } from "../../../../../../../utils/parsers";
import { useForm } from "react-hook-form";
import {
  UseUpdatePriceWithDiscount
} from "../../../../../../../service/api/mutations/real-estate/useUpdatePriceWithDiscount";
import { useAddToCollectionModal } from "../../../../../../UI/collections-modal/useAddToCollectionModal";
import {
  UseOpenAssignShowAndViewModal
} from "../../../../../ShowsAndViews/components/sticky-footer/assign-show-view-modal/useOpenAssignShowAndViewModal";
import {
  changeCurrentTabByLabel,
  changeCurrentTabInDrawerByLabel
} from "../../../../../../../service/redux/modules/drawer/drawer.action";
import { useDispatch } from "react-redux";
import { useGetFromArchive } from "./helpers/useGetFromArchive";
import { useArhivePreApplications } from "../../../../../../../service/api/queries";
import {
  useOpenCancelApplicationModal
} from "../../../../../Application/ApplicationCard/components/ApplicationInfo/component/Info/components/Body/components/BodyInfo/components/sticky-footer/cancel-modal/useOpenCancelApplicationModal";

type Props = {
  realEstate: RealEstate;
  realEstateRefetch: () => void;
  isFromParser?: boolean;
  parserPhones?: RealEstateParserPhones[];
  isFromLead?: boolean
};

type MenuItem = {
  label: string;
  icon: ReactNode;
  onClick: () => void;
  hide?: boolean;
  disabled?: boolean;
  id?: number
  className?: string
};

type QueryParams = {
  mode: string;
  compilation: string;
  applicationId: string;
};

export const StickyFooter = (
  {
    realEstate,
    isFromParser,
    realEstateRefetch,
    isFromLead
  }: Props): JSX.Element => {

  const {
    id: realEstateId,
    price,
    applicationId,
    preApplicationId,
    parserData,
    sourceLinks,
    parserPhones,
    isArchived,
    shortType,
    lastActiveCommunicationId,
    hasPreApplication,
  } = realEstate;

  const [isParsedPhoneOpen, setIsParsedPhoneOpen] = useState(false)

  const [disableAddObject, setDisableAddObject] = useState(false)

  const menuRef = useRef<HTMLDivElement>(null)

  const {changeQueryParams} = useQueryParams<QueryParams>([
    {name: "mode"},
    {name: "compilation"},
    {name: "applicationId"},
  ]);

  const {mutate: addObject} = useAddObjectToCompilation({
    onSuccess: () => setDisableAddObject(true),
  });

  let createColdCall = (realEstateId: string) => {
  }

  if (isFromParser) {
    const {handleCreateColdCall} = useColdCall(realEstateId);
    createColdCall = handleCreateColdCall
  }

  const {handleGetFromArchive} = useGetFromArchive(applicationId, hasPreApplication)

  const {control, register, getValues, setValue} = useForm<any>({});

  const {mutatePriceWithDiscount} = UseUpdatePriceWithDiscount({})

  const {mutate} = useUpdateApplication({kind: KindType.APPLICATION});

  const onChangeDate = () => {
    const payload: ApplicationUpdatePayload = {
      applicationId,
      applicationData: {...getValues()},
    };
    mutate(payload);
  };

  const pick = (obj, ...keys) => keys.reduce((acc, n) => (acc[ n ] = obj[ n ], acc), {});

  const onChangeDiscount = () => {
    const payload: RealEstateUpdatePriceWithDiscount = {
      id: realEstateId,
      data: pick({...getValues()}, ["priceWithDiscount"]),
    };
    mutatePriceWithDiscount(payload);
  };

  const {openDiscountModal} = useDiscountModal({
    register,
    setValue,
    getValues,
    control,
    onSave: onChangeDiscount,
    price
  })
  const objectId = isFromParser ? realEstate.id : undefined

  const clientObjectId = !isFromParser ? realEstate.applicationId : undefined

  const {openAssignShowAndViewModal} = UseOpenAssignShowAndViewModal({
    isViews: true,
    clientObjectApplicationId: clientObjectId,
    parserObjectId: objectId,
    onSuccess: realEstateRefetch,
    fromObject: true,
    applicationId
  })

  const dispatch = useDispatch()

  const {openObjectCompare} = useCompare({applicationId});

  const {openAddToCollectionModal} = useAddToCollectionModal({realEstateId: realEstateId, objectData: realEstate})

  const {handleCancelApplicationModal} = useOpenCancelApplicationModal({
    applicationId,
    whoIsClient: shortType?.whoIsClient ?? "seller"
  })

  const handleChangeTab = (newTab: string) => changeQueryParams([{name: "mode", newValue: newTab}]);

  const actionsMenu: MenuItem[] = [
    {
      label: "Добавить в подборку",
      icon: <AddToCompilationIcon/>,
      onClick: openAddToCollectionModal,
      disabled: disableAddObject,
      id: 1
    },
    {
      label: "Назначить просмотр",
      icon: <IconHouseShow/>,
      onClick: openAssignShowAndViewModal,
      id: 6,
    },
    {
      label: "Редактировать объект",
      icon: <IconEdit/>,
      onClick: () => {
        if (isFromLead) {
          dispatch(
            changeCurrentTabInDrawerByLabel({title: "edit"})
          )
        } else {
          dispatch(changeCurrentTabByLabel({title: "edit"}))
          // handleChangeTab("edit");
          changeQueryParams([{name: "mode", newValue: "edit"}]);
        }
      },
      id: 5,
    },
    {
      label: "Анализ конкурентов",
      icon: <IconCompetitorAnalysis/>,
      onClick: openObjectCompare,
      id: 3,
    },
    {
      label: "Открыть на публичной странице",
      icon: <IconOpenInNew/>,
      onClick: () => openRealEstatePublicPage(applicationId),
      id: 4,
    },
    {
      label: `Отказаться от ${preApplicationId ? "предзаявки" : "заявки"}`,
      icon: <IconCancel className={styles.cancel}/>,
      onClick: () => {
        preApplicationId
          ? handleOpenTaskModal(lastActiveCommunicationId ?? "")
          : handleCancelApplicationModal()
      },
      id: 7,
      className: styles.cancelText
    }
  ]

  const actualMenu = !isFromParser
    ? actionsMenu.slice(1, 6)
    : actionsMenu.slice(0, 2)
  const haveParserPhones = parserPhones && parserPhones.length > 0;

  const {
    mutate: arhiveMutate,
    data: arhivePreApplicationsData
  } = useArhivePreApplications({applicationId: preApplicationId})
  const arhivePreApplication = () => {
    arhiveMutate()
  }

  const {handleOpenTaskModal} = useOpenTaskModal({
    onUpdatedTask: arhivePreApplication,
    refetchParent: () => {
    },
    isLast: true
  });

  return (
    <div className={cn(styles.root)} ref={menuRef}>
      {isFromParser && <div className={styles.footerContact}>
        <div className={styles.flexRow}>
          <div className={styles.contact}>
            {parserData?.ownerName}
          </div>
          <div className={styles.iconBlock}>
            <EstateBaseRow sourceLinks={sourceLinks} isIcon/>
          </div>
        </div>
        {isParsedPhoneOpen
          ? <div className={styles.flexRow}>
            <IconRenewal className={styles.renewal}/>
            {haveParserPhones && parsePhone(parserPhones[ 0 ].phone)}
          </div>
          : <div className={styles.contactPhoneClosed} onClick={() => setIsParsedPhoneOpen(true)}>
            Открыть контактные данные
          </div>
        }
      </div>}
      <div>
        <div className={styles.flexRow}>
          {!isArchived &&
            <div className={styles.actions}>
              <Popup
                openTrigger={<div className={styles.openTrigger}>Другие действия</div>}
                options={{withArrow: true, primaryOpen: "left"}}
                classNames={
                  {
                    trigger: styles.clicked,
                    arrow: styles.arrow,
                    card: styles.popupCard
                  }
                }
              >
                {actualMenu.map((item) => (
                  <div key={item.id} className={cn(styles.actionItem, item.className)} onClick={item.onClick}>
                    {item.icon}
                    <span>{item.label}</span>
                  </div>
                ))}
              </Popup>
            </div>}
          <div className={styles.buttonContainer}>
            {isFromParser && !isArchived &&
              <Button
                onClick={() => createColdCall(realEstateId)}
                classNames={{root: styles.createColdCallButton}}
              >
                Холодный звонок
              </Button>}
            {isArchived &&
              <Button
                onClick={() => handleGetFromArchive(applicationId)}
                classNames={{root: styles.createLead}}
              >
                Создать лид
              </Button>}
          </div>
        </div>
      </div>
    </div>
  );
};