import { Dispatch, SetStateAction, useEffect, useState } from "react";
import isEqual from "lodash.isequal";

import { RealEstateFilter } from "../../../../../../types";

type Props = {
	filter: RealEstateFilter;
	watchForm: RealEstateFilter;
};

type Return = {
	showBtn: boolean;
	setShowBtn: Dispatch<SetStateAction<boolean>>;
};

type UseSubmitForm = (props: Props) => Return;

export const useSubmitForm: UseSubmitForm = ({ filter, watchForm }) => {
	const [showBtn, setShowBtn] = useState(false);

	useEffect(() => {
		const formChanged = !isEqual(watchForm, filter);
		setShowBtn(formChanged);
	}, [watchForm, filter]);

	return {
		showBtn,
		setShowBtn,
	};
};
