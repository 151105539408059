import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { TextField } from "../../../index";
import {
	NativeSelect,
	OptionsList,
	SelectWrapper,
	SelectedContainer,
	SelectError,
	SelectedWrapper,
} from "../components";
import { DefaultSingleSelectProps, SelectOption } from "../../../../../types";
import { arrayIsEqual } from "../../../../../utils/arrayIsEqual";

import styles from "./index.module.scss";

type SelectProps = {
	options: SelectOption[];
	label?: string;
	defaultSearchValue?: string;
	customSearchFunction?: (value: string) => void;
} & DefaultSingleSelectProps;

export const Select = ({
	name,
	defaultValue,
	options,
	register,
	setValue,
	size = "normal",
	onChange,
	placeholder,
	error,
	classNames,
	append,
	prepend,
	isSearchable,
	required,
	isLoading,
	disabled,
	label,
	customSearchFunction,
	defaultSearchValue,
	hideArrow,
	withBorder,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
SelectProps): JSX.Element => {

	const [searchHighLight, setSearchHighLight] = useState<undefined | string>(undefined);
	const [selected, setSelected] = useState(defaultValue);
	const [isListOpen, setListOpen] = useState(false);
	const [filteredOption, setFilteredOptions] = useState(options);

	const defaultSearchFormValue = options.find((item) => item.value === selected)?.label || "";

	const { control, setValue: setSearchValue } = useForm({
		defaultValues: {
			search: defaultSearchFormValue,
		},
	});

	useEffect(() => {
		setFilteredOptions(options);
	}, [options, options.length]);

	useEffect(() => {
		if (Array.isArray(defaultValue) && Array.isArray(selected)) {
			const isValuesEqual = arrayIsEqual(defaultValue, selected);
			if (!isValuesEqual) {
				setSelected(defaultValue || "");
			}
		} else {
			setSelected(defaultValue || "");
		}
	}, [defaultValue]);

	useEffect(() => {
		setSearchValue("search", defaultSearchValue || "");
	}, [defaultSearchValue]);

	useEffect(() => {
		const element = document.getElementById(name);
		if (element) {
			(element as HTMLInputElement).value = selected as string;

			if (defaultSearchValue) {
				setSearchValue("search", defaultSearchValue);
			}

			setValue(name, selected, { shouldValidate: !!selected });
		}
	}, [selected, defaultSearchValue]);

	const selectItem = (value: string) => {
		setSelected(value);
		setListOpen(false);
		setSearchHighLight(undefined);

		if (onChange) {
			onChange(value);
		}

		if (isSearchable) {
			const searchSelectedLabel = filteredOption.find((option) => option.value === value)?.label;
			if (searchSelectedLabel) {
				setSearchValue("search", searchSelectedLabel);
			}
		}
	};

	const selectTrigger = () => {
		if (!disabled) {
			setListOpen((prev) => !prev);
		}
	};

	const onSearch = (value) => {
		if (!isListOpen) {
			setListOpen(true);
		}

		setSearchHighLight(value);

		if (customSearchFunction) {
			customSearchFunction(value);
		} else {
			setFilteredOptions(options.filter((option) => option.label.toLowerCase().match(value.toLowerCase())));
		}
	};

	return (
		<SelectWrapper
			setListOpen={setListOpen}
			size={size}
			classNames={classNames}
			withBorder={withBorder}
			error={error}
			disabled={disabled}
		>
			<NativeSelect
				name={name}
				disabled={disabled}
				required={required}
				register={register}
				options={filteredOption}
			/>

			<SelectedContainer
				isListOpen={isListOpen}
				onSelectClick={selectTrigger}
				append={append}
				prepend={prepend}
				hideArrow={hideArrow}
				disabled={disabled}
				isLoading={isLoading}
				size={size}
				classNames={classNames}
			>
				{isSearchable ? (
					<TextField
						control={control}
						onChange={onSearch}
						name="search"
						label={label}
						placeholder={placeholder}
						variant="smallNoBorder"
						autoSuggest={false}
						onBlur={() =>
							setSearchValue("search", options.find((item) => item.value === selected)?.label || "")
						}
						className={styles.searchSingleInput}
						inputClassName={classNames?.input}
					/>
				) : (
					<SelectedWrapper placeholder={placeholder} thereIsSelected={!!selected}>
						{filteredOption.find((item) => item.value === selected)?.label}
					</SelectedWrapper>
				)}
			</SelectedContainer>

			<SelectError error={error} classNames={classNames} />

			<OptionsList
				isListOpen={isListOpen}
				options={filteredOption}
				handleOptionClick={(value: string) => selectItem(value)}
				checkIfSelected={(value: string) => value === selected}
				searchHighLight={searchHighLight}
				classNames={classNames}
				size={size}
			/>
		</SelectWrapper>
	);
};
