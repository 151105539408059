import { ApplicationInfo, SkeletonApplicationPage } from "./components";
import { useLoadApplication } from "./helpers";
import { ApplicationCardResponse, KindType } from "../../../../types";
import { useGetNavigation, useMapAdjust } from "components/pages/RealEstatePage/helpers";
import { useLoadApplicationRealEstate } from "service/api/queries";
import { useQueryParams } from "utils/hooks";
import styles from "./index.module.scss";
import { XYNavLayout } from "components/UI";
import { selectCurrentAccount, selectObjectEdit, useSelect } from "service/redux/selectors";
import classNames from "classnames";
import {
  StickyFooterApplication
} from "./components/ApplicationInfo/component/Info/components/Body/components/BodyInfo/components/sticky-footer/StickyFooterApplication";
import { useEffect } from "react";


type Props = {
  applicationId: string;
  onTab?: (tab: number) => void;
  refetch?: () => void;
  onFirstLoad?: () => void;
  inDrawer?: boolean;
  inDrawerInDrawer?: boolean;
  kind?: KindType
  showMap?: (isMap: boolean) => void
  isFromObjectList?: boolean
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ApplicationCard = (
  {
    applicationId,
    onTab,
    refetch,
    onFirstLoad,
    inDrawer,
    inDrawerInDrawer,
    kind,
    showMap,
    isFromObjectList
  }: Props): JSX.Element => {


  if (isFromObjectList === true) {
    console.error("isFromObjectList2",isFromObjectList)
  }
  const {
    application,
    isLoadingApplicationCard,
    refetch: refetchApplication,
  } = useLoadApplication({applicationId, onFirstLoad, kind, inDrawerInDrawer, inDrawer, isFromObjectList});

  const refetchAll = () => {
    if (refetch) {
      refetch();
    }
    refetchApplication();
  };

  const {
    queryParams: {mode},
  } = useQueryParams<{ mode: string }>([{name: "mode"}]);
  const {coord, dontMarkOnMap} = useSelect(selectObjectEdit);
  const {realEstate} = useLoadApplicationRealEstate({
    id: applicationId,
    kind: KindType.APPLICATION
  });

  const {activeNav, navigationY} = useGetNavigation({
    realEstate,
  });

  const {changeQueryParams} = useQueryParams<{ applicationId: string, dealId: string }>([{name: "applicationId"}, {name: "dealId"}]);

  useEffect(() => {
    if (kind === KindType.DEAL) {
      changeQueryParams([{name: "tab", newValue: "deals"}, {name: "dealId", newValue: applicationId}])
    }
  }, [applicationId, kind])

  const {currentAccount} = useSelect(selectCurrentAccount);
  const mapZoom = Number(currentAccount?.company?.mapZoom);
  const {mapCenter} = currentAccount?.settings || {};
  const isEditOrCreate = mode === "Заявка";
  const {onMapClick} = useMapAdjust({realEstate, isEditOrCreate});
  const getMapCenter = (): [number, number] | undefined =>
    mapCenter ? [mapCenter.lat, mapCenter.lon] : undefined;

  return (
    <div className={classNames(styles.root, inDrawerInDrawer ? styles.inDrawer : "")}>
      <XYNavLayout
        yNavigation={navigationY}
        yNavigationTop={64}
        activeYTab={mode}
        navVariant="grey"
      >
      </XYNavLayout>
      {isLoadingApplicationCard && !application
        ? <SkeletonApplicationPage/>
        : <>
          <ApplicationInfo
            application={application as ApplicationCardResponse}
            applicationId={applicationId}
            refetch={refetchAll}
            onTab={onTab}
            kind={kind}
          />
          <StickyFooterApplication
            applicationId={applicationId}
            kind={kind}
            whoIsClient={application?.type.whoIsClient}
            refetch={refetchApplication}
            applicationPublicLink={application?.publicPageLink}
            isArchive={application?.isArchived}
          />
        </>
      }
    </div>
  );
};

