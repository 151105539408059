import styles from "./index.module.scss";
import * as yup from "yup";
import { commonErrors } from "utils/errors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PinInput from 'react-pin-input';
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useEffect } from "react";

type Props = {
	onSubmit: (values: { code: string }) => void;
	resetCode: () => void;
	setCode: (value: string) => void;
	setBackStep: () => void;
	timer: number;
	label?: string;
  initialValue:string;
  errorMessage?:string;
};

export const CodeInput = ({
  onSubmit,
  resetCode,
  setCode,
  setBackStep,
  timer,
  label,
  initialValue,
  errorMessage
}: Props): JSX.Element => {

  useEffect(() => {
    const inputs: HTMLCollectionOf<HTMLInputElement> = document.getElementsByTagName("input");
    const inputsArray: HTMLInputElement[] = Array.from(inputs);
    inputsArray.map(el=>{
      el.setAttribute("autocomplete", "off")
      el.setAttribute("readonly", "readonly")
      el.addEventListener("focus", () => {
        el.removeAttribute('readonly');
      });
    })
  }, []);
  const schema = yup.object().shape({
    code: yup.string().min(4, "Введите код полностью").required(commonErrors.required),
  });

  const { handleSubmit, formState, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { code: "" },
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
			  <PinInput 
			  	length={4}
			  	initialValue={initialValue}
			  	secret
			  	secretDelay={0}
			  	onChange={(value, index) => {}}
			  	type="numeric"
			  	inputMode="numeric"
			  	style={{
			  		padding: '10px 10px 0 10px'
			  	}}
			  	inputStyle={{
			  		borderColor: '#D5D3CE',
			  		borderRadius:"6px",
			  		background: "#D5D3CE",
			  		width: "36px",
			  		height:"42px",
			      letterSpacing: "normal",
			      fontSize:"26px",
			      cursor:"pointer"
			  	}}
			  	inputFocusStyle={{
			  		borderColor: 'blue',
			  		background: "transparent",
			  	}}
			  	onComplete={(value: string) => onSubmit({ code: value })}
			  	autoSelect={true}
			  	regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
			  />

      <p className={styles.errorMessage}>
				{errorMessage && errorMessage}
			</p>
    <div className={styles.info}>
      Сейчас на&nbsp;ваш&nbsp;телефон поступит звонок с&nbsp;уникального номера. Введите последние 4&nbsp;цифры входящего номера.
    </div>

    <div className={styles.buttonsContainer}>
      <div className={styles.resend}>
        {timer > 0 && (
          <span>
            Повториить через&nbsp;
            <span className={styles.timeLeft}>{timer}</span>
            &nbsp;с
          </span>
        )}
      </div>

      <div className={styles.buttons}>
        {timer <= 0 && (
          <Button classNames={{ root: styles.button }} onClick={resetCode} type="button" variant="text">
            Запросить новый код
          </Button>
        )}

        <Button classNames={{ root: styles.button }} type="button" variant="text" onClick={setBackStep}>
          Назад
        </Button>
      </div>
    </div>
  </form>
  );
};
