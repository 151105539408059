import { useMemo } from "react";
import { Control, UseFormGetValues, useWatch } from "react-hook-form";
import { QuizQuestionDataTypeValues } from "../../../../../types";

import { RadioButton, toastSuccess } from "../../../index";
import { TasksList } from "./components";
import { useCreateTasksFromApplication } from "../../../../../service/api/mutations";

type Props = {
	applicationId: string;
	nameGroup: string[];
	control: Control<any>;
	getValues: UseFormGetValues<any>;
	values?: QuizQuestionDataTypeValues[];
	handleOnChange: (value: number | string) => void;
	refetch: () => void;
};

export const WithTasks = ({
	applicationId,
	nameGroup,
	control,
	getValues,
	values,
	handleOnChange,
	refetch,
}: Props): JSX.Element => {
	const options =
		values?.map((value) => ({
			value: value.buttonLabel,
			label: value.buttonLabel,
		})) || [];

	const { mutate, isLoading, isSuccess } = useCreateTasksFromApplication({
		applicationId,
		onSuccess: () => {
			refetch();
			toastSuccess({ text: "Задачи успешно созданы" });
		},
	});

	const radioName = nameGroup[0];

	const radioWatcher = useWatch({
		control,
		name: radioName,
		defaultValue: getValues(radioName),
	});

	const { templates, exists } = useMemo(() => {
		const currentValue = values?.find((value) => value.buttonLabel === radioWatcher);

		if (currentValue) {
			return currentValue.tasks;
		}

		return {
			exists: [],
			templates: [],
		};
	}, [radioWatcher, nameGroup]);

	const handleOnChangeRadio = (buttonValue: string | number) => {
		handleOnChange(buttonValue);
	};

	const handleAcceptTasks = () => {
		const payload = { applicationId, payload: templates };

		mutate(payload);
	};

	const tasksCreated = isSuccess || exists?.length > 0;

	return (
		<>
			<RadioButton
				name={radioName}
				control={control}
				options={options}
				handleOnChange={handleOnChangeRadio}
			/>

			<TasksList
				templates={templates}
				exists={exists}
				tasksCreated={tasksCreated}
				isLoading={isLoading}
				handleAcceptTasks={handleAcceptTasks}
				refetch={refetch}
			/>
		</>
	);
};
