import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate-ad/load-feed-list/DEFAULT_QN";

export const useLoadFeedList = () => {
	const loadFeedList = () => api.realEstateAd.getFeedList();

	const { data } = useQuery([DEFAULT_QN], loadFeedList);

	return {
		data: data?.data,
	};
};
