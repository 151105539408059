import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { validationFirstStepSchema } from "../formResolvers";
import { useRegistration } from "../../../../../service/api/mutations";
import {
	setRegistrationData,
	setStep,
} from "../../../../../service/redux/modules/registration-steps/reg-steps.action";

import { RegistrationPayloadV2 } from "../../../../../types";
import styles from "./index.module.scss";
import { CompanyRealtorForm } from "../ChooseRole/Forms/CompanyRealtorForm";
import { HasInvites } from "../HasInvites";
import { CreatingNewCompany } from "../RegistrationDone/CreatingNewCompany";
import { NewCompanyForm } from "../ChooseRole/Forms/NewCompanyForm";
import { TokenService } from "service/api/services/inner/TokenService";
import { selectRegisterSteps, useSelect } from "service/redux/selectors";
import { JoinSdelkaProModal } from "./openJoinSdelkaProModal";

export const  FinishRegistration = (): JSX.Element => {
	const dispatch = useDispatch();
	const [flagIcon, setFlagIcon] = useState<ReactNode | undefined>(undefined);
	const companyName = new URLSearchParams(window.location.search).get("companyName");
	const user = useSelect(selectRegisterSteps);

	useEffect(()=> {
		!user && setStep("first-step")
	}, [user])
	const { control, handleSubmit, setError, formState, watch, getValues } = useForm({
		resolver: yupResolver(validationFirstStepSchema),
		defaultValues: {
			phone: "",
		},
	});
  
	const onRegistrationSuccess = () => {
		dispatch(setRegistrationData({ phone: getValues().phone}));
	};

	const { mutate } = useRegistration({
		onSuccess: onRegistrationSuccess,
		setError,
	});

	const token = TokenService.getCurrentToken()
	useEffect(()=> {
		!token && setStep("first-step")
	}, [token])

	const { openJoinSdelkaProModal } = JoinSdelkaProModal();
	const joinToSdelkaPro = () => {
		openJoinSdelkaProModal()
	};

const onSubmit = async (values: RegistrationPayloadV2) => {
};

	return (
		<div className={styles.root}>	
			<h1>Место работы</h1>
			<div className={styles.workspace}>
				<div className={styles.workspaceCard}>
					<h3>Хочешь тестовый период? </h3>
					<p className={styles.description}>
						Вступай в онлайн-коворкинг СДЕЛКА ПРО <br></br>
						и пользуйся системой 14 дней бесплатно
					</p>
					<Button variant="outlined" onClick={joinToSdelkaPro} >Присоединиться к СДЕЛКА ПРО</Button>
					<div className={styles.link}>
						<a href="https://sdelka.estate/coworking" target="_blank" rel="noopener noreferrer">Узнать о коворкинге</a>
					</div>
				</div>
			</div>
			<div className={styles.invite_block}>
				<div className={styles.invite_form}>
					<h2>Присоединиться к существующей компании</h2>
					<CompanyRealtorForm />
				</div>
				<div className={styles.invite_shedule}>
					<HasInvites />
				</div>
			</div>
			<div className={styles.create_company}>
				<h2>Создать компанию</h2>
					<NewCompanyForm />
			</div>

	</div>
	);
};
