export const NO_IMG_VALUE_COMP = "NO_IMG/value-for-cart-state";

export const SET_HOVERED_RE_IMG = "sdelka/cart/SET_HOVERED_RE_IMG";
export const CLEAN_UP = "sdelka/cart/CLEAN_UP";

export type CartAction =
	| { type: typeof SET_HOVERED_RE_IMG; hoveredCartItemImg?: string }
	| { type: typeof CLEAN_UP };

export const setHoveredCartItemImg = (hoveredCartItemImg?: string): CartAction => ({
	type: SET_HOVERED_RE_IMG,
	hoveredCartItemImg,
});

export const cleanUp = (): CartAction => ({
	type: CLEAN_UP,
});
