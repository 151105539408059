import { useDispatch } from "react-redux";
import cn from "classnames";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { setShowRequiredAdFields } from "../../../../../../../service/redux/modules/object-edit/object-edit.action";
import { useSelect, selectObjectEdit } from "../../../../../../../service/redux/selectors";

import styles from "./index.module.scss";
import { useEffect, useState } from "react";

export const FieldsForAd = (): JSX.Element => {
	const dispatch = useDispatch();
	const { showReqiredAdFields } = useSelect(selectObjectEdit);

	const adPlatforms: { value: string; label: string }[] = [
		{ value: "avito", label: "Avito" },
		{ value: "yandex", label: "Яндекс" },
		{ value: "cian", label: "Циан" },
	];

	const [buttun, setButton] = useState(
		{cian: false, avito: false, yandex: false})
	const initial = {cian: false, avito: false, yandex: false}

	const hadleAdButton = (value: string) => {
		showReqiredAdFields[value] = !showReqiredAdFields[value];
		initial[value] = !initial[value]
		dispatch(setShowRequiredAdFields(showReqiredAdFields));
		setButton(initial)
	};

	return (
		<div className={styles.root}>
			<h3>
				Обязательные поля для рекламы
				<Tooltip classNames={{ root: styles.tooltipCard }}>
					Функция отметит данные, обязательные для выгрузки объекта на рекламные площадки
				</Tooltip>
			</h3>

			<div className={styles.adButtonContainer}>
				{adPlatforms.map((ad) => (
					<div
						key={ad.value}
						onClick={() => hadleAdButton(ad.value)}
						className={cn(styles.adButton, {
							[styles[`adButton__${ad.value}`]]: showReqiredAdFields[ad.value] === true,
						})}
						style={{background: `${showReqiredAdFields[ad.value] === true ? "" : " var(--main-blue-disabled)"}`}}
					>
						{ad.label}
					</div>
				))}
			</div>
		</div>
	);
};
