import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { Task, CreateTaskPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/tasks/create-task/DEFAULT_MN";

type UseCreateTask = {
	onSuccess?: (data: AxiosResponse<Task>) => void;
};

export const useCreateTask = ({ onSuccess }: UseCreateTask) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (payload: CreateTaskPayload) => api.tasks.createTask(payload),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
		mutateAsync: mutate.mutateAsync,
	};
};
