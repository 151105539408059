import { useMemo } from "react";
import { Control, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { SelectOption, User } from "../../../../../../types";

import { Checkbox, DoubleInput, InfoRow, MultiSelect, Select, TransparentInput } from "../../../../../UI";
import { dateMask, datePipe } from "../../../../../../utils/masks";
import { prepareShortUserOutput } from "../../../../../../utils/transformation";

import { ReactComponent as Calendar } from "../../../../../../assets/icons/calendar.svg";
import styles from "./index.module.scss";

type Props = {
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	watch: UseFormWatch<any>;
	control: Control<any>;
	users: User[];
	sourcesOptions: SelectOption[];
	requestStageOptions: SelectOption[];
};

const requestStatusOptions = [
	{ value: "IN_WORK", label: "В Работе" },
	{ value: "CHECKING", label: "На проверке" },
	{ value: "SUCCESS", label: "Выполнена" },
];

export const MainInfo = ({
	register,
	control,
	users,
	setValue,
	watch,
	sourcesOptions,
	requestStageOptions,
}: Props): JSX.Element => {
	const [
		watchCreator,
		watchSource,
		watchStatus,
		watchStage,
		watchDateCreatingFrom,
		watchDateCreatingTo,
		watchModifiedAt,
	] = watch(["creatorId", "source", "status", "stage", "dateCreatingFrom", "dateCreatingTo", "modifiedAt"]);

	const usersOption = useMemo(
		() =>
			users.map((user) => ({
				label: prepareShortUserOutput(user.account),
				value: user.id,
			})),
		[users]
	);

	return (
		<>
			<InfoRow label="Создатель" labelFont="ubuntu">
				<Select
					name="creatorId"
					setValue={setValue}
					options={usersOption}
					register={register}
					placeholder="Выбрать"
					defaultValue={watchCreator}
				/>
			</InfoRow>

			<InfoRow label="Источник" labelFont="ubuntu">
				<MultiSelect
					name="source"
					setValue={setValue}
					options={sourcesOptions}
					register={register}
					placeholder="Выбрать"
					defaultValue={watchSource}
				/>
			</InfoRow>

			<InfoRow label="С задачами" labelFont="ubuntu">
				<Checkbox control={control} name="withTasks" />
			</InfoRow>

			<InfoRow label="Статус задачи" labelFont="ubuntu">
				<MultiSelect
					name="status"
					setValue={setValue}
					options={requestStatusOptions}
					register={register}
					placeholder="Выбрать"
					defaultValue={watchStatus}
				/>
			</InfoRow>

			{requestStageOptions.length > 0 && (
				<InfoRow label="Этап" labelFont="ubuntu">
					<MultiSelect
						name="stage"
						setValue={setValue}
						options={requestStageOptions}
						register={register}
						placeholder="Выбрать"
						defaultValue={watchStage}
					/>
				</InfoRow>
			)}

			<InfoRow label="Дата создания" labelFont="ubuntu">
				<div className={styles.dateWrapper}>
					<Calendar className={styles.dateIcon} />
					<DoubleInput
						separator="-"
						size="large"
						register={register}
						placeholders={["00.00.00", "00.00.00"]}
						masks={[dateMask, dateMask]}
						pipes={[datePipe, datePipe]}
						setValue={setValue}
						names={["dateCreatingFrom", "dateCreatingTo"]}
						value={[watchDateCreatingFrom, watchDateCreatingTo]}
					/>
				</div>
			</InfoRow>

			<InfoRow label="Дата последнего изменения" labelFont="ubuntu">
				<div className={styles.dateWrapper}>
					<Calendar className={styles.dateIcon} />
					<TransparentInput
						register={register}
						name="modifiedAt"
						mask={dateMask}
						pipe={datePipe}
						setValueForMask={setValue}
						value={watchModifiedAt}
						placeholder="00.00.00"
					/>
				</div>
			</InfoRow>
		</>
	);
};
