import { useMutation } from "react-query";

import { AgencyAgrementPayloadType, AgencyAgrementType, ChangeStageResponse, WhoIsClient } from "../../../../types";
import { api } from "../../index";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/pre-application/update-application-contacts/DEFAULT_MN";

type UsePostAgencyAgrement = {
	onSuccess?: () => void;
	data: AgencyAgrementPayloadType
	errorCode?: string
	applicationID: string
};

export const usePostAgencyAgrement = ({ data, errorCode, applicationID, onSuccess }: UsePostAgencyAgrement) => {

	
	const mutation = useMutation(DEFAULT_MN, {
			mutationFn: () => {
					if (errorCode === "AP005") {
						return api.PreApplicationService.postAgencyAgrement({ applicationId: applicationID, data });
					} 
					if (!errorCode) {
						return api.PreApplicationService.putAgencyAgrement({ applicationId: applicationID, data });
					} 
					else {
							return new Promise<AxiosResponse<any>>((resolve, reject) => {
									reject(new Error("Invalid kind provided."));
							});
					}
			},
		onSuccess,
	});

	return {
			mutateAsync: mutation.mutateAsync,
			mutate: mutation.mutate,
			data: mutation.data,
			isLoading: mutation.isLoading,
			isSuccess: mutation.isSuccess,
		mutation
	};
};
