import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { Button, formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import {
	CianPaidServices,
	PaidServices,
	RealEstateAdPartnerHeader,
	RealEstateAdPartnersInfo,
	RealEstateAdStatusHeader,
	StartAd,
} from "./components";
import { useGetPlatform } from "../../../../../../../service/api/queries";
import { useUpdateAutoRenewal } from "../../../../../../../service/api/mutations";
import { getCurrentStatusLayout } from "../../../../../../../utils/ads";
import { useStartAdActions } from "../hooks";
import { prepareErrors, useCian } from "./helpers";
import {
	CianTariff,
	CianTariffNames,
	Limits,
	PaymentMethods,
	PlatformData,
	PlatformPaidServicesData,
} from "../../../../../../../types";

import styles from "./index.module.scss";
import { PostedErrors } from "./components/StartAd/components/PostedErrors";
import { useComplaintAutoReject } from "../../../../../../../service/api/mutations/real-estate-ad/useComplaintAutoReject";
import { CianSelectTariff } from "./components/CianSelectTariff";

type Props = {
	className?: string;
	platform: PlatformData;
	refetchPlatforms?: () => void;
	applicationId: string;
};

const renderActiveFooter: (
	isCian: boolean,
	paidServicesProps: PlatformPaidServicesData,
	onCianChange: (tariff: CianTariff) => void
) => JSX.Element = (isCian, paidServicesProps, onCianChange) =>
	!isCian ? (
		<PaidServices data={paidServicesProps} />
	) : (
		<CianPaidServices onTariffChange={onCianChange} data={paidServicesProps} />
	);

export const RealEstateAdPlatform = ({
	platform,
	className = styles.root,
	refetchPlatforms,
	applicationId,
}: Props): JSX.Element => {
	const [innerPlatform, setInnerPlatform] = useState(platform);

	const { adStatus, supportData, limits, requiredFields, name } = innerPlatform;

	const statistic = adStatus?.statistic;

	const feedId = innerPlatform.id;

	const {
		data,
		isLoading: isPlatformLoading,
		refetch,
		remove,
	} = useGetPlatform({
		applicationId,
		feedId,
	});

	const watingStatusInitialValues = useMemo(() => {
		let startAdPayment: null | number = null;
		let startAdTariff: null | number = null;
		let startAdDuration = 1;

		if (adStatus?.payMethod)
			startAdPayment = Object.values(PaymentMethods).indexOf(adStatus.payMethod as any) + 1;

		if (adStatus?.period) startAdDuration = adStatus?.period;

		if (adStatus?.tariff) startAdTariff = CianTariffNames[adStatus?.tariff];

		return {
			startAdDuration,
			startAdTariff,
			startAdPayment,
		};
	}, [adStatus?.payMethod, adStatus?.period]);

	const { control, getValues, watch, setValue } = useForm({
		defaultValues: watingStatusInitialValues,
	});

	const {
		currentlyUpdating,
		setCurrentlyUpdating,
		loading,
		setIsLoading,
		fillFields,
		startAd,
		stopAd,
		restartAd,
		changeTariff,
		tariffJustChangedArray,
	} = useStartAdActions({ applicationId, feedId, refetch });

	const { mutate: mutateAutoRenewal } = useUpdateAutoRenewal({
		onSuccess: () => {
			if (refetchPlatforms) {
				refetchPlatforms();
			}
		},
	});

	const { cianError, onCianChange, isCian } = useCian({
		platform: innerPlatform,
	});

	const isDomclick = platform.format === "domclick-cian";
	const isYandex = platform.format === "yandex";
	const isYandexOrDomclick = isDomclick || isYandex;

	useEffect(() => {
		if (!isPlatformLoading && data && data?.id === currentlyUpdating) {
			setInnerPlatform(data);
			setCurrentlyUpdating("");
			setIsLoading(false);
			remove();
		}
	}, [isPlatformLoading]);

	useEffect(() => {
		setInnerPlatform(platform);
	}, [platform.adStatus?.autoRenewal]);

	useEffect(
		() => () => {
			remove();
		},
		[]
	);

	const [curStatus, Status] = getCurrentStatusLayout({ adStatus });
	const {
		isWaiting,
		wasInAd,
		editError,
		notPosted,
		isError,
		isPosted,
		isPostedAndHasErrors,
		isArchived,
		isPostedAndWaitingForStop,
		isArchivedWithError,
	} = curStatus;

	const placementsLeft =
		!isWaiting && limits ? `${(limits as Limits).remainder}/${(limits as Limits).limit}` : "";
	const disableStatistics = (isWaiting && !wasInAd) || isError;

	const adPaidServices: PlatformPaidServicesData = {
		applicationId,
		feedId: platform.id,
		feedAdId: adStatus.id,
	};

	const handleStartAd = () => {
		const { startAdPayment } = getValues();
		if (startAdPayment !== null) {
			const currentValue = watch("startAdTariff");
			const selectedTariffOption = Object.values(CianTariffNames)[(currentValue as any) - 1] as CianTariff;

			return startAd(feedId, { months: getValues().startAdDuration }, startAdPayment, selectedTariffOption);
		} else {
			const currentValue = watch("startAdTariff");
			const selectedTariffOption = Object.values(CianTariffNames)[(currentValue as any) - 1] as CianTariff;
			return startAd(feedId, { months: getValues().startAdDuration }, 1, selectedTariffOption);
		}
		return null;
	};
	const handleFillFields = () => fillFields(platform.format);
	const handleStopAd = () => stopAd(feedId);
	const handleAutoRenewal = () =>
		mutateAutoRenewal({
			feedAdId: adStatus.id,
			autoRenewal: !adStatus?.autoRenewal,
		});

	const { mutate: mutateComplaintAutoReject } = useComplaintAutoReject({
		onSuccess: () => {
			if (refetchPlatforms) {
				refetchPlatforms();
			}
		},
	});

	const handleComplaintAutoRejectClick = () =>
		mutateComplaintAutoReject({ applicationId, feedId, isEnabled: !adStatus.complaintAutoReject });

	const restartAdHandler = () => {
		restartAd(feedId);
	};

	const onChangeTariff = (value: number | string) => {
		if (typeof value === "number") {
			if (isCian && (isWaiting || isError || isPosted)) {
				// FIXME: Не уверен, что это хорошо для производительности
				const currentValue = watch("startAdTariff");
				if (currentValue !== value) {
					const selectedTariffOption = Object.values(CianTariffNames)[(value as any) - 1];
					changeTariff({ applicationId, feedId, newTariffValue: selectedTariffOption as CianTariffNames });
				}
			}
		}
	};

	// FIXME: Bad performance
	watch("startAdTariff");

	return (
		<RealEstateAdPartnersInfo
			isLoading={loading}
			headerClassName={styles.partnerAdHeader}
			className={cn(className, { [styles.archived]: isArchived })}
			disableStatistics={disableStatistics || notPosted}
			statistics={statistic}
			applicationId={applicationId}
			feedId={feedId}
			headerLabel={name}
			isYandexOrDomclick={isYandexOrDomclick}
			editError={
				editError
					? {
							data: {
								platformName: platform.name,
								feedId,
								applicationId,
								onRetry: handleStartAd,
							},
							applicationId,
							errors: prepareErrors(adStatus),
					  }
					: undefined
			}
			header={
				<>
					<RealEstateAdPartnerHeader
						applicationId={applicationId}
						feedId={feedId}
						img={platform.icon}
						link={adStatus.link}
						alt={platform.name}
						autoRenewal={adStatus?.autoRenewal}
						adIsStarted={isPosted || isArchived}
						adIsWaiting={isWaiting}
						onAdStopClick={handleStopAd}
						onAdRestartClick={restartAdHandler}
						onAutoRenewalClick={handleAutoRenewal}
						onComplaintAutoRejectClick={handleComplaintAutoRejectClick}
						cianError={cianError}
						isCian={isCian}
						complaintAutoReject={adStatus.complaintAutoReject}
						isPostedAndWaitingForStop={isPostedAndWaitingForStop}
						adIsInArchive={isArchived}
						adIsError={isError}
						statusObject={
							<RealEstateAdStatusHeader
								statusClassName={String(isWaiting) && styles.adWaiting}
								status={Status}
								date={`Рекламируется с ${formatDate(adStatus.startedAt ?? "")}`}
								tariff={(limits as Limits)?.tariff}
								placementsLeft={placementsLeft}
								isError={isError}
								isPosted={isPosted || isArchived}
							/>
						}
					/>
					{isCian && isPosted && (
						<CianSelectTariff
							format={platform.format}
							onChangeTariff={onChangeTariff}
							control={control}
							watch={watch}
							isPostedAndWaitingForStop={isPostedAndWaitingForStop}
							isTariffJustChanged={tariffJustChangedArray.includes(feedId)}
						/>
					)}
					{(isPostedAndHasErrors || isArchivedWithError) && (
						<PostedErrors
							messages={{
								errors: isArchivedWithError ? adStatus.errors : adStatus.updateStatus?.errors,
								warinigs: isArchivedWithError ? adStatus.warinigs : adStatus.updateStatus?.warinigs,
							}}
						/>
					)}
				</>
			}
			footerClassName={cn(styles.partnersAdEditFooter)}
			footer={
				<>
					{(notPosted || isWaiting || isError) && (
						<StartAd
							disabled={
								!requiredFields.valid ||
								getValues().startAdPayment === null ||
								(isCian && getValues().startAdTariff === null)
							}
							format={platform.format}
							onStartAdClick={handleStartAd}
							onFillFields={handleFillFields}
							control={control}
							requiredFields={requiredFields}
							watch={watch}
							setValue={setValue}
							isWaiting={isWaiting}
							isError={isError}
							messages={{ errors: adStatus.errors, warinigs: adStatus.warinigs }}
							onChangeTariff={onChangeTariff}
							isPostedAndWaitingForStop={isPostedAndWaitingForStop}
						/>
					)}
					{!isCian && (isWaiting || isError || isPosted || isArchived) && !isDomclick && (
						<div style={isWaiting || isError ? { marginTop: "40px" } : {}}>
							{renderActiveFooter(isCian, adPaidServices, onCianChange)}
						</div>
					)}
					{(isArchived || isPostedAndWaitingForStop) && (
						<div className={styles.archiveContainer}>
							<Button onClick={handleStartAd}>Вернуть в рекламу</Button>
							<Button variant="outlined" onClick={restartAdHandler}>
								Разместить как новое
							</Button>
						</div>
					)}
				</>
			}
			supportData={{ ...supportData, id: adStatus.externalId }}
		/>
	);
};
