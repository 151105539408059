import CircleIcon from "../../../../assets/icons/ellipse.svg"
import YouTubeIcon from "../../../../assets/icons/youtube.svg"
import TelegramIcon from "../../../../assets/icons/telegramCircle.svg"

import styles from "./index.module.scss";

export const Subscribe = (): JSX.Element => (
    <div className={styles.container}>
        <h2>Подписывайся</h2>

        <div className={styles.containerSubscribe}>
            <div className={styles.box}>
                <div className={styles.contentBox}>
                    <div className={styles.title}>
                        <img src={CircleIcon} alt="avatar" />
                        История СДЕЛКИ
                    </div>
                    <div className={styles.textBox}>
                    <p>
                        Еженедельные новости об обновлениях, новых функциях и акциях
                    </p>
                    </div>
                    <div className={styles.link}>
                        <img src={TelegramIcon} alt="" />
                        <p>Telegram</p>
                    </div>
                </div>
            </div>


            <div className={styles.box}>
                <div className={styles.contentBox}>
                    <div className={styles.title}>
                        <img src={CircleIcon} alt="avatar" />
                        СДЕЛКА | Автоматизация агентства недвижимости
                    </div>
                    <div className={styles.textBox}>
                    <p>
                        Еженедельные новости об обновлениях, новых функциях и акциях
                    </p>
                    </div>
                    <div className={styles.link}>
                        <img src={YouTubeIcon} alt="" />
                        <p>YouTube</p>

                        <img src={YouTubeIcon} alt="" />
                        <p>RuTube</p>
                    </div>
                </div>
            </div>

            <div className={styles.box}>
                <div className={styles.contentBox}>
                    <div className={styles.title}>
                        <img src={CircleIcon} alt="avatar" />
                        Отзывы клиентов
                    </div>
                    <div className={styles.textBox}>
                    <p>
                        Еженедельные новости об обновлениях, новых функциях и акциях
                    </p>
                    </div>
                    <div className={styles.link}>
                        <img src={TelegramIcon} alt="" />
                        <p>Telegram</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)