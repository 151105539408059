import styles from "../index.module.scss"
import { useLoadPurchasedServices } from "../../../../../../service/api/queries";
import { CardProductForTable } from "../../../../../UI";

export const PurchasedServices = () => {

  const {services, total: totalPurchased, refetch} = useLoadPurchasedServices({})

  return (
    <div>
      <div className={styles.title}>
        <div>
          Доступные купоны
        </div>
        <div className={styles.count}>
          {totalPurchased}
        </div>
      </div>
      <div className={styles.col}>
        {services && services.map(item => (
          <div key={item.id} className={styles.item}>
            <CardProductForTable
              product={item}
              refetch={refetch}
              type="purchased"/>
          </div>
        ))}
      </div>
    </div>
  )
}