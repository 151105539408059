import { Task, TaskSubTypeLabelEnum, TaskTypeLabelEnum } from "../../../../../../../../../../../types";
import { useTasksContext } from "../../../../../../../../../../../service/context/Tasks";
import { useOpenTaskModal } from "../../../../../../../../../../../utils/hooks";

import styles from "./index.module.scss";
import { CardTask } from "../../../../../../../../../../UI/Card/card-task/CardTask";

type Props = {
  groupName: string;
  tasks: Task[];
};

const getLabel = (type: string, subtype?: string) => {
  const typeLabel = TaskTypeLabelEnum[ type ];
  const subtypeLabel = subtype && TaskSubTypeLabelEnum[ subtype ];

  return subtype ? `${typeLabel} - ${subtypeLabel}` : typeLabel;
};

export const TasksGroupedList = ({groupName, tasks}: Props): JSX.Element => {
  const {
    tasksQuery: {refetch},
  } = useTasksContext();

  const {handleOpenTaskModal} = useOpenTaskModal({onUpdatedTask: refetch});

  const onCardClick = (id?: string) => {
    if (id) handleOpenTaskModal(id);
  };

  return (
    <div>
      <h3 className={styles.groupName}>{groupName}</h3>

      <div className={styles.list}>
        {tasks.map((task) => (
          <div className={styles.card}
               onClick={() => onCardClick(task.id)}>
            <CardTask task={task} inTable/>
          </div>
        ))}
      </div>
    </div>
  );
};
