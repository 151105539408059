import { Dispatch, SetStateAction } from "react";
import { Body, Header } from "./components";
import { useCardScroll, useChangeStage, useUpdateApplication } from "./helpers";
import { ApplicationCardResponse, KindType, RealEstate } from "../../../../../../../../types";

import styles from "./index.module.scss";
import { CardLead } from "components/UI/Card/card-lead/CardLead";

type Props = {
  applicationId: string;
  application: ApplicationCardResponse;
  refetch: () => void;
  onTab?: (tab: number) => void;
  stageNum: number;
  setStageNum: Dispatch<SetStateAction<number>>;
  currentBlocks: any[];
  isFetchingBlocks: boolean;
  kind?: KindType
};
export const Info = (
  {
    applicationId,
    application,
    refetch,
    onTab,
    stageNum,
    setStageNum,
    isFetchingBlocks,
    currentBlocks,
    kind
  }: Props): JSX.Element => {
  const {
    stages,
    clientObject,
    type: {whoIsClient},
  } = application;

  const {
    onNextStage,
    isLoading: isLoadingNextStage,
    errorsLabel,
    onFinish,
  } = useChangeStage({stages, applicationId, refetch, stageNum, kind});
  const {onScroll, scrollY} = useCardScroll({currentBlocks});

  const {unpinObject, pinObject} = useUpdateApplication({
    refetch,
    applicationId,
    kind
  });
  const addObject = (object: RealEstate) => pinObject(object.id);

  const isFetching = isLoadingNextStage;
  return (
    <div onScroll={onScroll} className={styles.root}>
      {kind === KindType.APPLICATION ?
        <Header whoIsClient={whoIsClient} clientObject={clientObject} applicationId={applicationId}/>
        : <div className={styles.cardLead}>
          <CardLead lead={application.parentLead} className={styles.card_lead}/>
        </div>
      }
      <Body
        applicationId={applicationId}
        application={application}
        onTab={onTab}
        refetch={refetch}
        scrollY={scrollY}
        unpinObject={unpinObject}
        addObject={addObject}
        setStageNum={setStageNum}
        currentBlocks={currentBlocks}
        onNextStage={onNextStage}
        errorsLabel={errorsLabel}
        isFetching={isFetching}
        isFetchingBlocks={isFetchingBlocks}
        onPinObject={addObject}
        onFinish={onFinish}
        kind={kind}
      />
    </div>
  );
};
