import { Control, useForm, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useEffect, useState } from "react";
import { CompilationFilterData, RealEstateFilter } from "../../../types";
import { useDebouncedCallback } from "use-debounce";
import isEqual from "lodash.isequal";

import { useUpdateCompilationFilter } from "../../../service/api/mutations";
import { useLoadingToast } from "../index";
import { deleteEmptyField } from "../../objects";

type Props = {
	isFetched?: boolean;
	isFetchingLoadingFilter?: boolean;
	applicationId: string;
	defaultValues: CompilationFilterData;
};

type Return = {
	control: Control<CompilationFilterData>;
	register: UseFormRegister<CompilationFilterData>;
	setValue: UseFormSetValue<CompilationFilterData>;
	getValues: UseFormGetValues<CompilationFilterData>;
};

type UseUpdateApplicationCompilation = (props: Props) => Return;

export const useUpdateApplicationCompilation: UseUpdateApplicationCompilation = ({
	isFetched = true,
	isFetchingLoadingFilter = false,
	applicationId,
	defaultValues = null,
}) => {
	const LOADING_TEXT = "Сохраниение фильтров...";
	const [filter, setFilter] = useState<RealEstateFilter | null>(null);

	const { control, register, setValue, getValues, reset, watch } = useForm<CompilationFilterData>({
		defaultValues,
	});

	const formWatcher = watch();

	const { createLoader, updateLoader, toastId } = useLoadingToast({
		loadingText: LOADING_TEXT,
	});

	const {
		mutate: updateCompilationFilter,
		isLoading: isLoadingUpdate,
		isSuccess,
	} = useUpdateCompilationFilter({
		onSuccess: () => {
			updateLoader();
		},
	});

	const updateFilter = () => {
		const filterData = getValues();
		const payload = { applicationId, filterData };

		if (!isLoadingUpdate) {
			updateCompilationFilter(payload);
			setFilter(filterData);
		}
	};

	const isEqualFilter = isEqual(deleteEmptyField(getValues()), deleteEmptyField(filter));

	const isFetching = isFetchingLoadingFilter || isLoadingUpdate;

	const checkingEqualing = () => {
		if (!isFetching && !isEqualFilter) {
			setTimeout(() => {
				updateFilter();
			}, 0);
		}
	};

	useEffect(() => {
		reset(filter);
	}, [filter]);

	const debouncedSearch = useDebouncedCallback(checkingEqualing, 500);

	useEffect(() => {
		if (!isFetching) {
			if (!isFetching && isFetched && toastId === null && !isEqualFilter && isSuccess) {
				createLoader();
			}
			debouncedSearch();
		}
	}, [formWatcher, isFetchingLoadingFilter]);

	return {
		control,
		register,
		setValue,
		getValues,
	};
};
