import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { Task, FinishTaskPayload } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/tasks/finish-task-success/DEFAULT_MN";

type UseFinishTask = {
  onSuccess?: (data: AxiosResponse<Task>) => void;
};

export const useFinishSuccessTask = ({ onSuccess }: UseFinishTask) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: (payload: FinishTaskPayload) => api.tasks.finishTaskSuccess(payload),
    onSuccess,
  });

  return {
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
    mutateAsync: mutate.mutateAsync,
  };
};
