import { IUserEditFormResponse, User } from "../../../../types";
import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/user/get-user/DEFAULT_QN";

type Props = {
  userId?: string | null;
  onSuccess?: () => void;
  onError?: () => void;
};

type Return = {
  user?: IUserEditFormResponse;
  isFetching: boolean;
  refetch: () => void;
};

type UseLoadUser = (props: Props) => Return;

export const makeUseLoadUserQueryKey = (userId?: string | null) => [DEFAULT_QN, userId];

export const useGetUser: UseLoadUser = ({ userId, onSuccess, onError }) => {
  const loadUser = () => api.users.getUser(userId as string);
  const queryKey = makeUseLoadUserQueryKey(userId);
  const { data, isFetching, refetch } = useQuery(queryKey, loadUser, {
    enabled: !!userId,
    onSuccess,
    onError,
  });

  return {
    user: data?.data,
    isFetching,
    refetch,
  };
};
