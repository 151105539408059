import { Location } from "history";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { closeModal, openModal } from "../../../service/redux/modules/modal/modal.action";
import { useQueryParams } from "../../../utils/hooks";
import { changeCurrentTab, deleteTabInDrawer } from "../../../service/redux/modules/drawer/drawer.action";

type Props = {
	title?: string;
	body?: string;
	leaveButtonLabel?: string;
	stayButtonLabel?: string;
};

export const useLeaveAlertModal = ({
	title = "Изменения не будут сохранены",
	body = "Закрытие формы приведет к потере данных",
	leaveButtonLabel = "Уйти",
	stayButtonLabel = "Остаться",
}: Props = {}) => {
	const history = useHistory();
	const location = useLocation()
	const dispatch = useDispatch();
	const {
		queryParams: {mode}, changeQueryParams
	} = useQueryParams<{ mode: string }>([{name: "mode"}]);
	const handleChangeTab = (newTab: string) => changeQueryParams([{ name: "mode", newValue: newTab }]);
	
	const canLeave = useRef(false);
	useEffect(() => {
		if(canLeave.current) {
			handleChangeTab("")
		}
	}, [canLeave, dispatch])

	const openLeaveAlertModal = (nextLocation: Location<unknown>) => {
		dispatch(
			openModal({
				title,
				body,
				variant: "alert",
				alertIcon: "warning",
				alertButtonSubmit: {
					label: stayButtonLabel,
					onClick: () => {
						dispatch(changeCurrentTab({newCurrentTab: 4}))
						dispatch(closeModal());
					},
				},
				alertButtonDecline: {
					label: leaveButtonLabel,
					onClick: () => {
						canLeave.current = true;
						
						// history.push(nextLocation.pathname + nextLocation.search.slice(0,-4));
						handleChangeTab("2")
							dispatch(closeModal());
						dispatch(changeCurrentTab({newCurrentTab: 2}))
						dispatch(deleteTabInDrawer(4))
	
						// location.pathname==="/leadsanddeals" &&  window.location.reload();
					},
				},
			})
		);

		return canLeave.current;
	};

	const closeLeaveAlertModal = () => {
		dispatch(closeModal());dispatch(closeModal());dispatch(closeModal())
		
	};

	return { openLeaveAlertModal, closeLeaveAlertModal };
};
