import { Placemark, withYMaps } from "react-yandex-maps";

import useYMaps from "../map-utils/useYMaps";
import { markTemplate } from "../map-utils/utils";
import { MarkType } from "../../../../types";

type Props = {
	mark: MarkType;
};

export const Mark = withYMaps(({ mark }: Props): JSX.Element => {
	const { lat, lon } = mark;
	const { ymaps, mapInstance } = useYMaps();

	const clickOnMark = () => {
		const zoom = mapInstance.getZoom();
		mapInstance.setCenter([lat, lon], zoom + 2, {
			checkZoomRange: true,
		});
	};

	return (
		// @ts-ignore
		<Placemark
			geometry={[lat, lon]}
			onClick={clickOnMark}
			options={{
				iconLayout: ymaps?.templateLayoutFactory.createClass(markTemplate),
				iconShape: {
					type: "Rectangle",
					coordinates: [
						[-22, -62],
						[22, 0],
					],
					radius: 16,
				},
			}}
		/>
	);
});
