import {
  AgentDocument,
  ApplicationComment,
  ChildDeals,
  Contacts,
  DealCharacteristics,
  DealProperties,
  Employees,
  MirrorApplication,
  ObjectAd,
  ObjectCreation,
  ObjectShowsOrViews,
  Quiz,
  Recommendations,
  Responsibles,
  TasksToCompleteInApplication,
} from "../../../../../../../../../../../../../../UI";
import {
  ApplicationCardPayload,
  KindType,
  PropsAdditionalProperties,
  QuizQuestion,
  RealEstate,
  StageConditions,
  User
} from "../../../../../../../../../../../../../../../types";
import styles from "./blocks.module.scss";
import React from "react";
import { AgencyAgrement } from "components/pages/Application/PreApplicationTemplate/components/AgencyAgrement";
import { CardObjectBuyWrapper } from "../../../../../../Header/components/CardObjectBuyWrapper";
import {
  AppliedServicesHistory
} from "../../../../../../../../../../../../../../UI/ApplicationComponents/applied-services-history/AppliedServicesHistory";


type MapComponentsByBlockType = {
  agentDocument: (props) => React.ReactElement;
  ads: (props) => React.ReactElement;
  requirementsComment: (props) => React.ReactElement;
  responsibles: (props) => React.ReactElement;
  sellerContacts: (props) => React.ReactElement;
  buyerContacts: (props) => React.ReactElement;
  tasks: (props) => React.ReactElement;
  viewData: (props) => React.ReactElement;
  showData: (props) => React.ReactElement;
  adsRecommendations: (props) => React.ReactElement;
  jointObject: (props) => React.ReactElement;
  employees: (props) => React.ReactElement;
  jointPodbor: (props) => React.ReactElement;
  quiz: (props) => React.ReactElement;
  dealProperties: (props) => React.ReactElement;
  dealCharacteristics: (props) => React.ReactElement;
  agencyAgreement: (props) => React.ReactElement;
  mirrorApplication: (props) => React.ReactElement;
  childDeals: (props) => React.ReactElement;
  appliedServicesHistory: (props) => React.ReactElement
};

export const mapComponentsByBlockType: MapComponentsByBlockType = {
  agentDocument: ({applicationId}: ApplicationCardPayload): React.ReactElement => (
    <div id="agentDocument">
      <AgentDocument applicationId={applicationId}/>
    </div>
  ),
  ads: ({
          scrollY,
          stageConditions,
          applicationId,
        }: {
    scrollY: number;
    stageConditions: StageConditions;
    applicationId: string;
  }): React.ReactElement => (
    <div id="ads" className={styles.objectAd}>
      <ObjectAd scrollY={scrollY} stageConditions={stageConditions} applicationId={applicationId}/>
    </div>
  ),
  sellerContacts: ({
                     scrollY,
                     applicationId,
                     stageConditions,
                     kind,
                   }: ApplicationCardPayload): React.ReactElement => (
    <div id="sellerContacts">
      <Contacts
        kind={kind}
        type="seller"
        scrollY={scrollY}
        applicationId={applicationId}
        stageConditions={stageConditions}
      />
    </div>
  ),
  buyerContacts: ({
                    scrollY,
                    applicationId,
                    stageConditions,
                    kind,
                  }: ApplicationCardPayload): React.ReactElement => (
    <div id="buyerContacts">
      <Contacts
        kind={kind}
        type="buyer"
        scrollY={scrollY}
        applicationId={applicationId}
        stageConditions={stageConditions}
      />
    </div>
  ),
  requirementsComment: ({
                          comment,
                          scrollY,
                          applicationId,
                          stageConditions,
                          kind
                        }: { comment: string } & ApplicationCardPayload): React.ReactElement => (
    <div id="comment" className={styles.comment}>
      <ApplicationComment
        comment={comment}
        scrollY={scrollY}
        applicationId={applicationId}
        stageConditions={stageConditions}
        kind={kind}
      />
    </div>
  ),
  dealProperties: ({applicationId}: ApplicationCardPayload): React.ReactElement => (
    <div id="dealProperties">
      <DealProperties id={applicationId ? applicationId : ""}/>
    </div>
  ),
  dealCharacteristics: ({applicationId, block, application}: any): React.ReactElement => (
    <div id="dealCharacteristics">
      <DealCharacteristics id={applicationId ? applicationId : ""} block={block} application={application}/>
    </div>
  ),
  responsibles: (
    {
      scrollY,
      applicationId,
      stageConditions,
      responsibleUser,
      refetch,
      kind
    }: ApplicationCardPayload & {
      responsibleUser?: User;
      refetch: () => void;
    }): React.ReactElement => (
    <div id="responsibles">
      <Responsibles
        scrollY={scrollY}
        applicationId={applicationId}
        stageConditions={stageConditions}
        responsibleUser={responsibleUser}
        refetch={refetch}
        kind={kind}
      />
    </div>
  ),

  tasks: ({scrollY, applicationId, stageConditions, kind}: ApplicationCardPayload): React.ReactElement => (
    <div id="tasks">
      <TasksToCompleteInApplication
        applicationId={applicationId}
        scrollY={scrollY}
        stageConditions={stageConditions}
        kind={kind}
      />
    </div>
  ),
  agencyAgreement: ({applicationId}: ApplicationCardPayload): React.ReactElement => (
    <div id="agentDocument" style={{marginTop: "60px", marginBottom: "30px"}}>
      <AgencyAgrement applicationId={applicationId}/>
    </div>
  ),
  viewData: ({
               scrollY,
               applicationId,
               stageConditions,
               onTab,
             }: ApplicationCardPayload & {
    onTab?: (tab: number) => void;
  }): React.ReactElement => (
    <div id="viewData">
      <ObjectShowsOrViews
        type="views"
        applicationId={applicationId}
        scrollY={scrollY}
        onTab={onTab}
        stageConditions={stageConditions}
      />
    </div>
  ),
  showData: ({
               scrollY,
               applicationId,
               stageConditions,
               onTab,
             }: ApplicationCardPayload & {
    onTab?: (tab: number) => void;
  }): React.ReactElement => (
    <div id="showData">
      <ObjectShowsOrViews
        type="shows"
        applicationId={applicationId}
        scrollY={scrollY}
        onTab={onTab}
        stageConditions={stageConditions}
      />
    </div>
  ),
  adsRecommendations: ({
                         scrollY,
                         applicationId,
                         stageConditions,
                       }: {
    scrollY: number;
    applicationId: string;
    stageConditions: StageConditions;
  }): React.ReactElement => (
    <div id="recommendations">
      <Recommendations scrollY={scrollY} applicationId={applicationId} stageConditions={stageConditions}/>
    </div>
  ),
  jointObject: (
    {
      scrollY,
      addObject,
      onPinObject,
      unpinObject,
      clientObject,
      stageConditions,
      applicationId,
      kind,
    }: {
      scrollY: number;
      clientObject: RealEstate | undefined;
      addObject: (object: RealEstate) => void;
      onPinObject: (object: RealEstate) => void;
      unpinObject: () => void;
      stageConditions: StageConditions;
      applicationId: string;

      kind?: "pre-applications" | "applications",
    }): React.ReactElement => (
    <div id="jointObject">
      <ObjectCreation
        kind={kind}
        scrollY={scrollY}
        object={clientObject}
        stageConditions={stageConditions}
        onRemoveObject={unpinObject}
        onObjectChange={addObject}
        onPinObject={onPinObject}
        applicationId={applicationId}
      />
    </div>
  ),
  employees: ({
                scrollY,
                applicationId,
                stageConditions,
              }: ApplicationCardPayload & {
    applicationId: string;
  }): React.ReactElement => (
    <div id="employees">
      <Employees scrollY={scrollY} applicationId={applicationId} stageConditions={stageConditions}/>
    </div>
  ),

  mirrorApplication: (
    {
      application,
      whoIsClient,
      kind
    }): React.ReactElement => {

    return (
      <div id="mirrorApplication">
        <MirrorApplication
          applicationId={application.mirrorApplicationId}
          application={application}
          whoIsClient={whoIsClient}
          kind={KindType.APPLICATION}
          isArchived={application.isArchived}
        />
      </div>
    )
  },

  appliedServicesHistory: (
    {
      applicationId
    }): React.ReactElement => {

    return (
      <>
        <AppliedServicesHistory applicationId={applicationId}/>
      </>
    )
  },

  childDeals: (
    {
      applicationId,
      application
    }): React.ReactElement => {
    return (
      <>
        <ChildDeals applicationId={applicationId} isArchived={application.isArchived}/>
      </>
    )
  },

  jointPodbor: ({
                  applicationId,
                  application
                }: {
    applicationId: string;
    application: any
    stageConditions: StageConditions;
    refetch: () => void;
    selectedTab: number;
    collectionDescription?: string;
  }): React.ReactElement => (
    <div id="jointPodbor">
      <CardObjectBuyWrapper applicationId={application.applicationTemplateId ?? applicationId}/>
    </div>
  ),
  quiz: ({
           title,
           questions,
           additionalProperties,
           stageConditions,
           applicationId,
           refetch,
           kind
         }: {
    title: string;
    questions: QuizQuestion[];
    additionalProperties: PropsAdditionalProperties;
    stageConditions: StageConditions;
    applicationId: string;
    refetch: () => void;
    kind: KindType
  }): React.ReactElement => (
    <div>
      <Quiz
        title={title}
        questions={questions}
        stageConditions={stageConditions}
        additionalProperties={additionalProperties}
        applicationId={applicationId}
        refetch={refetch}
        kind={kind}
      />
    </div>
  ),
};
