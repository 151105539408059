import { CartPayload, ProductTypeEnum, SelectOption } from "../../../../../../types";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { AddInCartModal } from "./AddInCartModal";

type Props = {
  ids: string[]
  title: string;
  type: ProductTypeEnum
  action: (payload: CartPayload[]) => void
  tariffLabel?: string
  tariffOptions?: SelectOption[]
}

type Return = {
  openAddInCartModal: () => void;
};


export const useAddInCartModal = (
  {
    ids,
    title,
    type,
    action,
    tariffLabel,
    tariffOptions,
  }: Props): Return => {
  const dispatch = useDispatch();

  const openAddInCartModal = () => {
    dispatch(
      openModal({
        body: (
          <AddInCartModal
            action={action}
            ids={ids}
            title={title}
            type={type}
            tariffLabel={tariffLabel}
            tariffOptions={tariffOptions}
          />
        ),
        width: 660,
      })
    );
  };

  return {
    openAddInCartModal
  }
}