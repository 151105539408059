import { useMemo } from "react";

import { MultiLayerSelectOption } from "../../../types";
import { useLoadUsers } from "../../../service/api/queries";
import { prepareResponsibleUsers } from "../../transformation";

export const useResponsibleUsers = () => {
	const { users } = useLoadUsers({});
	const responsibleUsers = useMemo((): MultiLayerSelectOption[] => prepareResponsibleUsers(users), [users]);

	return { users, responsibleUsers };
};
