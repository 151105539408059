import {
  UseGetRealEstateCollections
} from "../../../../../../../service/api/mutations/real-estate/useGetRealEstateCollections";
import { getCondition } from "./helpers/Conditions"
import cn from "classnames";
import styles from "./index.module.scss";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { RealEstate } from "../../../../../../../types";
import { Plug } from "../plug/Plug";
import { useEffect } from "react";
import { CardObjectBuyElement } from "../../../../../../UI";

type Props = {
  isFromParser: boolean
  forAdded?: boolean
  realEstate: RealEstate
}

export const CollectionList = (
  {
    isFromParser,
    forAdded,
    realEstate,
  }: Props): JSX.Element => {

  const id = realEstate.parserData ? realEstate.id : realEstate.applicationId
  const condition = getCondition(isFromParser, id, forAdded)
  const {mutateLoading, data, mutate, isFetching} = UseGetRealEstateCollections({condition})

  useEffect(() => {
    mutate()
  }, [mutate])

  const title = !forAdded ? "Подходящие подборки" : "Участвует в подборках"
  const count = data ? `(${data?.length})` : "(0)"

  return (
    <div className={styles.root}>
      <div className={cn(styles.row, styles.header)}>
        <div className={styles.row}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.counter}>
            {count}
          </div>
        </div>
        {/*{ TODO убрать коммент и поправить стили роу когда будет готовы данные для ссылки}*/}
        {/*<div className={cn(styles.row, styles.lookOther)}>*/}
        {/*  <OpenInNew/>*/}
        {/*  Смотреть все*/}
        {/*</div>*/}
      </div>
      {(data && data.length > 0)
        ? <>
          {mutateLoading
            ? <div
              className={cn(styles.loading, "animate__animated", "animate__faster", "animate__fadeIn", {
                [ styles.loadingHide ]: !isFetching,
              })}
            >
              <Loader/>
            </div>
            : <div className={styles.collections}>
              {data && data.slice(0, 3).map((collect) => (
                <div key={collect.applicationId}>
                  <CardObjectBuyElement
                    applicationId={realEstate.applicationId}
                    collection={collect}
                    isFetchingCollection={isFetching}
                    classnames={{
                      body: styles.card
                    }}
                  />
                </div>
              ))
              }
            </div>
          }
        </>
        : <div>
          <Plug plugText="Подходящих подборок не найдено"/>
        </div>
      }
    </div>
  )
}


