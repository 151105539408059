import { useQuery } from "react-query";

import { Conditions, Office, RealEstateSort } from "../../../../types";
import { api } from "../../index";
import { useState } from "react";

const DEFAULT_QN = "sdelka/office/load-offices/DEFAULT_QN";
export const EMPLOYEE_LIST_LOAD_OFFICES_QN =
	"sdelka/office/employee-list-load-offices/EMPLOYEE_LIST_LOAD_OFFICES_QN";

type Props = {
	enabled?: boolean;
	queryName?: string;
	defaultTake?: number;
	// defaultFilter?: RealEstateFilter;
	absoluteSearchConditions?: Conditions;
	options?: {
		keepPreviousData?: boolean;
	};
	defaultPage?: number;
};

export const useLoadOffices = (
	{
		enabled = true,
		queryName = DEFAULT_QN,
		defaultPage,
		defaultTake
	}: Props) => {
	const [take, setTake] = useState(defaultTake);
	const [page, setPage] = useState(defaultPage);
	const [sort, setSort] = useState<RealEstateSort | undefined>({
		field: "price",
		sort: "ASC",
	});

	const loadOffices = () => api.offices.list();

	const { data, isLoading, refetch, isFetching} = useQuery(queryName, loadOffices, {
		enabled,
	});

	return {
		offices: data?.data || [],
		total: data?.data.length || 0,
		isLoading,
		refetch,
		take,
		page,
		sort,
		setTake,
		setSort,
		setPage,
		isFetching
	};
};
