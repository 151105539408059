import styles from "./index.module.scss"
import { ReactComponent as Player } from "../../../../assets/icons/Player.svg";
import { CallData, TrafficWithDate } from "../../../../types";
import { getIconAndLabel, getLabel } from "./helpers/getIconCall";
import { formatDateWithTime } from "../../../../utils/date";
import { AudioPlayer } from "./helpers/audio-player/AudioPlayer";
import { useRef } from "react";

type Props = {
  callData?: CallData | null
}

export const CardTraffic = ({callData}: Props): JSX.Element => {
  const newDate = new Date(callData?.start ?? "")

  const  audio = new Audio(callData?.link ?? "");

  const ref = useRef(null)

  return (
    <div className={styles.root} id={"trafficCard"} ref={ref}>
      <div className={styles.rowBetween}>
        <div className={styles.row}>
          {callData &&
            <div className={styles.callIcon}>
            {getIconAndLabel(callData as CallData)}
          </div>}
          <div className={styles.column}>
            {callData &&
              <div className={styles.label}>
              {getLabel(callData as CallData).label}
            </div>}
            <div className={styles.date}>
              {formatDateWithTime(newDate)}
            </div>
          </div>
        </div>

      </div>
      <div>
        <AudioPlayer audio={audio}/>
      </div>
    </div>
  )
}