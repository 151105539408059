import cn from "classnames";

import { ContactPreview, ContactSubtypeMap, ContactTypeMap } from "../../../../types";
import { formatPhone } from "../../../../utils/formatters";

import styles from "./index.module.scss";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/open-in-new.svg";

type Props = ContactPreview & {
	onClick?: (id: string) => void;
};

export const ContactPreviewCard = ({
	id,
	name,
	secondName,
	lastName,
	type,
	otherType,
	subtype,
	otherSubtype,
	phone,
	onClick,
}: Props): JSX.Element => {
	const secondNameLine = `${name || ""} ${lastName || ""}`;
	const mainPhone = phone ? formatPhone(phone) : "Телефон отсутствует";

	const typeLabel = type !== "other" ? ContactTypeMap[type] : otherType;

	const defaultOnClick = (contactId?: string) => window.open(`/contacts?id=${contactId}`);

	const handleOnClick = () => (onClick ? onClick(id) : defaultOnClick(id));

	return (
		<div className={styles.root} onClick={handleOnClick}>
			<div className={styles.fio}>
				<b className={styles.overflow}>{secondName}</b>

				<span className={styles.overflow}>{secondNameLine}</span>
			</div>

			<b className={styles.phone}>{mainPhone}</b>

			<div className={styles.types}>
				<span className={cn(styles.type, styles.overflow)}>{typeLabel}</span>
			</div>

			<LinkIcon className={styles.linkIcon} />
		</div>
	);
};

type HOCFromContactProp = {
	contact: ContactPreview;
};

// eslint-disable-next-line react/jsx-props-no-spreading
export const ContactPreviewCardHOCFromContact = ({ contact }: HOCFromContactProp): JSX.Element => (
	<ContactPreviewCard {...contact} />
);
