import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";

import { defaultValues } from "../../../utils/clientObjects";
import { useQueryParams } from "../../../utils/hooks";
import { paths } from "../../../utils/paths";
import { RealEstateFilter, RealEstateListTab } from "../../../types";

import styles from "./index.module.scss";

enum TabTypeEnum {
  PRODUCTS = 0,
  OFFICES = 1,
  EMPLOYERS = 2
}

type Props = {
  // servicesListFilters?: RealEstateFilter;
};

type QueryParams = {
  employeeId: string;
  filter: string;
  tab: "PRODUCTS" | "OFFICES" | "EMPLOYERS";
  productId: string;
  officeId: string;
};

export const ServicesTabs = ({ }: Props): JSX.Element => {
  const { push, location } = useHistory();
  const {
    queryParams: {
      tab,
      employeeId,
      productId,
      officeId,
      filter,
    },
  } = useQueryParams<QueryParams>([
    { name: "officeId" },
    { name: "productId" },
    { name: "page", type: "number" },
    { name: "filter"},
    { name: "employeeId", type: "string" },
    { name: "tab", type: "string" },
  ]);

  const defaultTab = TabTypeEnum[tab] ?? 0


  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  const handleChangeTab = (tabNum: number) => {
    switch (tabNum) {
      case 0: {
        push(
          `${paths.services.list}?tab=PRODUCTS`
        );
        break;
      }
      case 1: {
        push(
          `${paths.services.list}?tab=OFFICES`
        );
        break;
      }
      case 2: {
        push(
          `${paths.services.list}?tab=EMPLOYERS`
        );
        break;
      }
      default:
        break;
    }
    setActiveTab(tabNum);
  };

  const tabsItems = [
    <div className={styles.element} style={{ width: 212 }}>Услуги</div>,
    <div className={styles.element} style={{ width: 250 }}>Офисы и отделы</div>,
    <div className={styles.element} style={{ width: 220 }}>Сотрудники</div>,
  ];

  return (
    <div className={styles.tabs}>
      <Tabs tabs={tabsItems} size="large" active={activeTab} onChange={handleChangeTab} classNames={{root: styles.root}} />
    </div>
  );
};
