import { User, UserPreview } from "../../types";

export const prepareUserData = (user?: User): UserPreview => {
	const { account } = user || {};

	const { name = "", lastName = "", secondName = "", avatar = "" } = account || {};

	return {
		name,
		lastName,
		secondName: secondName || "",
		avatar,
	};
};
