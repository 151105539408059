import { UserPreview } from "../../../../../types";
import { UserBlock } from "./renderUserBlock";

type Props = {
  creator?: UserPreview
  creatorId?: string
}

export 	const RenderCreator = ({creator, creatorId}: Props): JSX.Element => {
  const {
    name,
    lastName,
    secondName
  } = creator || {name: "", secondName: "", lastName: ""}
  if (creator) {
    const href = `employees?userId=${creatorId}`;

    return UserBlock({name, lastName, secondName,  href});
  }

  return <div>Система</div>;
};