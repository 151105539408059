// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { Button, Loader, CollapseDown } from "@sdelka_crm/sdelka-crm-component-library";

import { TableSimple } from "../../../../../../UI";
import { useLoadPlatformsTable } from "../../../../../../../service/api/queries";
import { useAddOrRemoveAllPlatformFeed } from "../../../../../../../service/api/mutations";
import { useStartAdActions } from "../hooks";
import { generateSimpleTableData } from "./generateSimpleTableData";

import styles from "./index.module.scss";

type Props = {
	title: string;
	applicationId: string;
	type: "freeTable" | "paidTable";
};

const thead: Array<string> = ["Площадка", "Статус", "Время", "Действия"];
const cols = [
	{ render: (item) => item.platform },
	{ render: (item) => item.status },
	{ render: (item) => item.time },
	{ render: (item) => item.actions },
];

const LIMIT = 15;

const prepareParams = (type: string, limit?: number): string => `type=${type}&limit=${limit || LIMIT}`;

export const RealEstateAdPlatformsTable = ({ applicationId, type, title }: Props): JSX.Element => {
	const collapseRef = useRef<HTMLDivElement>(null);

	const [offset, setOffset] = useState(LIMIT);
	const [allAdResults, setAllAdResults] = useState({});
	const [params, setParams] = useState(prepareParams(type));

	const {
		data: platforms,
		isLoading,
		refetch,
		remove,
		isFetching,
	} = useLoadPlatformsTable({ applicationId, params });
	const showMore = () => {
		setParams(prepareParams(type, offset + LIMIT));
		setOffset(offset + LIMIT);
	};

	const setLimitDefault = () => {
		setParams(prepareParams(type, LIMIT));
		setOffset(LIMIT);
	};

	useEffect(() => {
		if (isLoading) {
			collapseRef.current?.scrollIntoView(true);

			return;
		}

		const count: number = platforms ? platforms?.count : 0;

		setOffset(count);
	}, [isLoading]);

	const { startAd, stopAd, currentlyUpdating, loading, setCurrentlyUpdating } = useStartAdActions({
		applicationId,
		refetch,
	});

	// FIXME: Mock data on payMethod
	const onStartAd = (feedId: string) => startAd(feedId, { months: 1 }, 1);
	const onStopAd = (feedId: string) => stopAd(feedId);

	const { mutate: mutateAdAll, isLoading: isMutateAdAllLoading } = useAddOrRemoveAllPlatformFeed({
		onSuccess: (successData) => {
			refetch();
			setAllAdResults(
				successData.data
					.filter((item) => !item.success && !!item.feedId)
					.reduce((acc, item) => {
						const { feedId } = item;

						return feedId ? { ...acc, [feedId]: 1 } : acc;
					}, {})
			);
		},
	});

	const onStartOrStopAdAll = (e, action: "stop" | "start"): void => {
		e.stopPropagation();
		remove();
		mutateAdAll({
			applicationId,
			tableName: type,
			action,
		});
	};

	const onOpen = (val: boolean): void => {
		if (!val) {
			remove();
			setAllAdResults([]);
		}
	};

	const platformsData = generateSimpleTableData(
		platforms?.data || [],
		allAdResults,
		{
			onStartAd,
			onStopAd,
		},
		loading,
		currentlyUpdating,
		setCurrentlyUpdating
	);

	return (
		<CollapseDown
			preInfo={`(${platforms?.total || 0})`}
			label={title}
			onClick={onOpen}
			headerChildren={
				<div ref={collapseRef} className={styles.buttons}>
					<Button variant="text" onClick={(e) => onStartOrStopAdAll(e, "start")}>
						Разместить на всех
					</Button>

					<Button variant="text" color="red" onClick={(e) => onStartOrStopAdAll(e, "stop")}>
						Отменить все
					</Button>
				</div>
			}
		>
			{isLoading || isMutateAdAllLoading ? (
				<Loader height={300} />
			) : (
				platforms && (
					<div className={styles.tableWrapper}>
						<TableSimple className={styles.table} thead={thead} cols={cols} data={platformsData} />

						{(isFetching && <Loader height={30} style={{ marginTop: 12 }} />) || (
							<div className={styles.buttonsAction}>
								{platforms?.total > LIMIT && (
									<Button variant="text" onClick={setLimitDefault}>
										Свернуть
									</Button>
								)}

								{platforms?.total !== platforms?.count && (
									<Button onClick={showMore} variant="text">
										Еще &nbsp;
										{platforms?.total - platforms?.count}
									</Button>
								)}
							</div>
						)}
					</div>
				)
			)}
		</CollapseDown>
	);
};
