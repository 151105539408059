import { useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { AccountSwitcherOffices } from "./AccountSwitcherOffices";
import { UserInfo } from "./UserInfo";
import {
	changeCurrentUser,
	logoutAsEmployeeThunk,
} from "../../../../../service/redux/modules/auth/auth.thunk";
import { setOpenAccounts, setOpenOffices } from "../../../../../service/redux/modules/sidebar/sidebar.action";
import {
	selectAccounts,
	selectCurrentAccount,
	selectSideBar,
	useSelect,
} from "../../../../../service/redux/selectors";

import { ReactComponent as Arrow } from "../../../../../assets/icons/arrow-down.svg";
import styles from "./index.module.scss";
import { useShowAccountSwitcher } from "./helpers";

export const AccountSwitcher = (): JSX.Element => {
	const { accounts, isAsEmployee } = useSelect(selectAccounts);
	const { currentAccount } = useSelect(selectCurrentAccount);
	const otherUsers = useMemo(
		() => accounts.filter((user) => user.id !== currentAccount?.id),
		[currentAccount, accounts]
	);
	const dispatch = useDispatch();
	const { isAccountsOpen } = useSelect(selectSideBar);

	const showAccountSwitcher = useShowAccountSwitcher({
		currentAccountId: currentAccount?.id,
		currentAccountPositionName: currentAccount?.position?.name,
	});

	const handleButtonClick = () => {
		if (isAsEmployee) {
			dispatch(logoutAsEmployeeThunk());
		} else {
			dispatch(setOpenOffices(true));
		}
	};

	return (
		<>
			<AccountSwitcherOffices />

			<div
				className={cn(styles.root, {
					[styles.showAccountSwitcher]: showAccountSwitcher,
					[styles.rootOpened]: isAccountsOpen,
				})}
			>
				{showAccountSwitcher && (
					<div className={styles.toggleContent}>
						<button
							onClick={() => {
								dispatch(setOpenAccounts(!isAccountsOpen));
								dispatch(setOpenOffices(false));
							}}
							type="button"
							className={styles.toggle}
						>
							<span
								className={cn(styles.toggleText, {
									[styles.hideToggleText]: isAccountsOpen,
								})}
							>
								Аккаунты
							</span>

							<Arrow className={styles.toggleIcon} />
						</button>

						{isAccountsOpen && (
							<div
								className={cn(styles.toggleVisible, {
									[styles.toggleVisibleIsAsEmployee]: isAsEmployee,
								})}
							>
								{/* Mock div for correct layout */}
								<div />

								<div className={styles.toggleVisibleBottom}>
									<button
										onClick={handleButtonClick}
										type="button"
										className={cn(styles.changeBtn, {
											[styles.changeBtnIsAsEmployee]: isAsEmployee,
										})}
									>
										{isAsEmployee ? "Выйти" : "Войти под сотрудником"}
									</button>

									<div className={styles.otherAccounts}>
										{otherUsers.map((user) => (
											<button
												onClick={() => dispatch(changeCurrentUser({ user }))}
												key={user.id}
												type="button"
												className={styles.userWrapper}
											>
												<UserInfo account={user} />
											</button>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				)}

				<UserInfo account={currentAccount} current />
			</div>
		</>
	);
};
