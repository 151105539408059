import { Popover } from "../../../../../../UI";
import { ButtonsFuncReturnType } from "./types";

import styles from "../index.module.scss";

type Props = {
	title?: string;
	buttons: ButtonsFuncReturnType;
};

export const StageButtons = ({ title, buttons }: Props): JSX.Element => (
	<>
		<div className={styles.headline}>{title}</div>

		<div className={styles.buttonsContainer}>
			{buttons.map((button, index) => (
				<button
					type="button"
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					className={button.classNames}
					onClick={button.onClick}
				>
					{button.popoverText ? (
						<Popover position="right" overElement={button.popoverText} classNames={{over: styles.over}}>
							{button.child}
						</Popover>
					) : (
						button.child
					)}
				</button>
			))}
		</div>
	</>
);
