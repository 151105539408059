import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { UseFormSetError } from "react-hook-form";

import { ResetPasswordPayload } from "../../../../types";
import { api } from "../../index";
import { axiosError } from "../../../../utils/errors";

const DEFAULT_MN = "sdelka/auth/reset-password/DEFAULT_MN";

type UseRequirePhone = {
	onSuccess?: () => void;
	setError?: UseFormSetError<any>;
};

export const useResetPassword = ({ onSuccess, setError }: UseRequirePhone) => {
	const onError = (error: AxiosError) => {
		const message = axiosError({ error });

		if (setError) {
			if (message.includes("password too weak")) {
				setError("password", { message: "Слишком простой пароль" });
			} else {
				setError("password", { message });
			}
		}
	};

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: Omit<ResetPasswordPayload, "phone"> & { token: string }) =>
			api.auth.resetPassword(data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
		isSuccess: mutation.isSuccess,
	};
};
