import { useHistory } from "react-router";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQueryParams } from "../../../../utils/hooks";
import { RealEstateSort } from "../../../../types";
import { useLoadOffices, useLoadServices, useLoadUsers } from "../../../../service/api/queries";
import { openDrawer } from "../../../../service/redux/modules/drawer/drawer.action";
import { PersonalCard } from "../../PersonalCard";
import { useDispatch } from "react-redux";
import { OfficeCard } from "../../office-card/OfficeCard";
import { ProductCard } from "../../product-card";
import { PersonalCardWrapper } from "../../PersonalCard/components/wrapper/PersonalCardWrapper";

type TabType = "PRODUCTS" | "OFFICES" | "EMPLOYERS";

enum TabTypeEnum {
  PRODUCTS = 0,
  OFFICES = 1,
  EMPLOYERS = 2,
}

type Props = {
  tab: TabType;
  defaultPage: number;
};

type Return = {
  list: any[];
  isFetching: boolean;
  isLoading: boolean;
  total?: number;
  take?: number;
  setTake?: Dispatch<SetStateAction<number | undefined>>;
  page?: number;
  setPage?: Dispatch<SetStateAction<number | undefined>>;
  sort?: RealEstateSort;
  setSort?: Dispatch<SetStateAction<RealEstateSort | undefined>>;
  // filter: RealEstateFilter;
  // setFilter: Dispatch<SetStateAction<RealEstateFilter>>;
  refetch: () => void
};

export const useServices = ({tab, defaultPage}: Props) => {
  const {push} = useHistory();
  const [curTab, setCurTab] = useState(0);
  const dispatch = useDispatch();
  const {queryParams, removeQueryParam} = useQueryParams<{
    tab: string;
    officeId: string
    userId: string
    productId: string
  }>([
    {name: "tab"},
    {name: "officeId"},
    {name: "userId"},
    {name: "productId"},
  ]);

  const {
    userId,
    officeId,
    productId,
  } = queryParams;

  const scopes = ["withCart"]

  const {
    isLoading: isLoadingUsers,
    users,
    take: usersTake,
    setTake: usersSetTake,
    page: usersPage,
    setPage: usersSetPage,
    sort: usersSort,
    setSort: usersSetSort,
    total: usersTotal,
    refetch: refetchUsers,
    isFetching: isFetchingUsers
  } = useLoadUsers({
    enabled: TabTypeEnum[ tab ] === TabTypeEnum.EMPLOYERS,
    scopes
  })

  const {
    isLoading: isLoadingOffices,
    offices,
    take: officesTake,
    setTake: officesSetTake,
    page: officesPage,
    setPage: officesSetPage,
    sort: officesSort,
    setSort: officesSetSort,
    total: officesTotal,
    refetch: refetchOffices,
    isFetching: isFetchingOffices
  } = useLoadOffices({
    enabled: TabTypeEnum[ tab ] === TabTypeEnum.OFFICES
  })

  const {
    isLoading: isLoadingServices,
    services,
    take: servicesTake,
    setTake: servicesSetTake,
    page: servicesPage,
    setPage: servicesSetPage,
    sort: servicesSort,
    setSort: servicesSetSort,
    total: servicesTotal,
    refetch: refetchServices,
    isFetching: isFetchingServices
  } = useLoadServices({
    enabled: TabTypeEnum[ tab ] === TabTypeEnum.PRODUCTS
  })


  const TABS: Record<TabType, Return> = {
    EMPLOYERS: {
      list: users,
      isFetching: isFetchingUsers,
      isLoading: isLoadingUsers,
      total: usersTotal,
      take: usersTake,
      setTake: usersSetTake,
      page: usersPage,
      setPage: usersSetPage,
      sort: usersSort,
      setSort: usersSetSort,
      // filter: filterParsedRealEstates,
      // setFilter: setFilterParsedRealEstates,
      refetch: refetchUsers
    },
    PRODUCTS: {
      list: services,
      isFetching: isFetchingServices,
      isLoading: isLoadingServices,
      total: servicesTotal,
      take: servicesTake,
      setTake: servicesSetTake,
      page: servicesPage,
      setPage: servicesSetPage,
      sort: servicesSort,
      setSort: servicesSetSort,
      // filter: filterParsedRealEstates,
      // setFilter: setFilterParsedRealEstates,
      refetch: refetchServices
    },
    OFFICES: {
      isLoading: isLoadingOffices,
      list: offices,
      take: officesTake,
      setTake: officesSetTake,
      page: officesPage,
      setPage: officesSetPage,
      sort: officesSort,
      setSort: officesSetSort,
      total: officesTotal,
      refetch: refetchOffices,
      isFetching: isFetchingOffices
    }
  }
  const updateCurTab = (index: number): void => {
    if (curTab !== index) {
      setCurTab(index);
    }
  };

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case "EMPLOYERS":
          if (userId) {
            dispatch(
              openDrawer({
                children: <PersonalCardWrapper userId={userId} refetch={refetchUsers}/>,
                width: 800,
                onClose: () => removeQueryParam("userId"),
              })
            );
          }
          break
        case "OFFICES":
          if (officeId) {
            dispatch(
              openDrawer({
                width: 800,
                onClose: () => removeQueryParam("officeId"),
                children: <OfficeCard officeId={officeId} refetch={refetchOffices}/>
              })
            )
          }
          break
        case "PRODUCTS":
          if (productId) {
            dispatch(
              openDrawer({
                width: 800,
                onClose: () => removeQueryParam("productId"),
                children: <ProductCard productId={productId} refetch={refetchOffices}/>
              })
            )
          }
          break
        default:
          updateCurTab(0);
          break;
      }
    }
  }, [])

  const {
    list, isFetching, total, take, setTake, page, setPage, sort, setSort, isLoading,
    // filter, setFilter,
    refetch
  } = TABS[ tab ];
  const TAB_KEYS = Object.keys(TABS);
  const onChangeCurTab = (newTab: number) => {
    setCurTab(newTab);
    push(`/services?tab=${TAB_KEYS[ newTab ]}`);
  };

  return {
    list,
    isFetching,
    isLoading,
    total,
    take,
    setTake,
    page,
    setPage,
    sort,
    setSort,
    // filter,
    // setFilter,
    refetch
  }
}