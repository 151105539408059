import styles from "./index.module.scss"
import { Contact, UserListItem } from "../../../../types";
import { ContactStrap } from "../../../pages/Contacts/ContactStrap/ContactStrap";
import { ReactComponent as CallIn } from "../../../../assets/icons/callIn.svg";
import { ReactComponent as CallOut } from "../../../../assets/icons/callOut.svg";
import { ReactComponent as CallMissed } from "../../../../assets/icons/callMissed.svg";
import { ReactComponent as CallBusy } from "../../../../assets/icons/callBusy.svg";
import { ReactComponent as ImResponsibleIcon } from "../../../../assets/icons/imResponsibleIcon.svg";
import { ReactComponent as Responsible } from "../../../../assets/icons/iconFace.svg";
import { ReactComponent as Chat } from "../../../../assets/icons/iconChat.svg";
import { formatDateWithTime } from "../../../../utils/date";
import cn from "classnames";
import { ReactComponent as Telegramm } from "../../../../assets/icons/telegram.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/icons/whatsapp.svg";
import { capitalizeFirstLetter, formatPhone } from "../../../../utils/formatters";
import { translateContactType } from "../../../pages/ContactCard/ContactInfo/components/General/components";
import { useDeleteContactsPreApp } from "../../../../service/api/mutations/pre-application-template";
import { PopupMenu } from "../../Popup";
import { ReactComponent as IconCancel } from "../../../../assets/icons/iconClear.svg";
import { ReactComponent as OpenInNew } from "../../../../assets/icons/openInNewBlue.svg";
import { useDeleteContacts } from "../../../../service/api/mutations";
import { useDeleteContactModal } from "./delete-contact-modal/useDeleteContactModal";

type Props = {
  contact: Contact | UserListItem;
  mini?: boolean;
  onCardClick?: (id: string) => void;
  backgroundWhite?: boolean
  canOpenNew?: boolean
  atList?: boolean
  classNames?: {
    root?: string
  }
  refetch?: () => void;
  ondelete?: (id: string) => void
  canDelete?: boolean
}

export const CardContact = (
  {
    contact,
    onCardClick,
    mini,
    backgroundWhite,
    canOpenNew,
    atList,
    classNames,
    refetch,
    ondelete,
    canDelete
  }: Props): JSX.Element => {

  const {
    iAmResponsibleUser,
    responsibleUser,
    relatedKinds,
    callData,
    phones,
    lastCommunication
  } = contact

  const secondName = capitalizeFirstLetter(contact?.secondName || "");
  const name = capitalizeFirstLetter(contact?.name || "");
  const lastName = capitalizeFirstLetter(contact?.lastName || "");
  const pseudonym = capitalizeFirstLetter(contact?.pseudonym || "");
  const type = capitalizeFirstLetter(translateContactType(contact?.type) || "")

  const FIO = `${pseudonym} ${lastName} ${name} ${secondName}`;

  const getIcon = (call): JSX.Element => {
    if (!call) {
      return <></>
    }
    if (call?.status && call?.status === "success") {
      if (call?.type && call?.type === "in") {
        return <CallIn/>
      }
      if (call?.type && call?.type === "out") {
        return <CallOut/>
      }
      return <Chat/>
    }
    if (call?.status && call?.status === "missed") {
      return <CallMissed/>
    }
    return <CallBusy/>
  }

  const openInNewTab = () => {
    window.open(`/contacts?id=${contact.id}`)
  }


  const deleteAppId = ((contact as UserListItem).preApplicationId ? (contact as UserListItem).preApplicationId : (contact as UserListItem).applicationId)

  const {mutate: mutatePreapp} = useDeleteContactsPreApp({onSuccess: refetch})
  const {mutate: mutateApp} = useDeleteContacts({onSuccess: refetch})

  const action = (contact as UserListItem).preApplicationId
    ? mutatePreapp
    : mutateApp

  const handleDelete = () => {
    // @ts-ignore
    action({applicationId: deleteAppId, id: contact.id, type: contact.type})
  }

  const { openDeleteContactModal } = useDeleteContactModal({onDelete: handleDelete})

  const menu = [
    {
      label: "Открыть в новой вкладке",
      icon: <OpenInNew/>,
      action: openInNewTab,
    },
    {
      label: "Открепить",
      className: styles.red,
      icon: <IconCancel/>,
      action: () => {
        openDeleteContactModal()
        if (refetch) {
          setTimeout(() => {
            refetch()
          }, 1000)
        }
      },
    },
  ]

  const actualMenu = canDelete ? menu.slice(0, 1) : menu

  return (
    <div className={cn(styles.root, classNames?.root,
      {
        [ styles.backgroundWhite ]: backgroundWhite,
        [ styles.mini ]: mini,
        [ styles.withStrap ]: relatedKinds
      })
    }>
      <div className={styles.column}>
        <div className={styles.rowBetween}>
          <div className={styles.title}>
            {FIO}
          </div>
          <div className={styles.row}>
            <div>
              {getIcon(lastCommunication)}
            </div>
            {lastCommunication &&
              <div className={styles.date}>
                {formatDateWithTime(lastCommunication?.start ?? new Date, true, true)}
                <br/>
                {formatDateWithTime(lastCommunication?.start ?? new Date, true, false)}
              </div>}
            <div>
              <div className={styles.popup}>
                <PopupMenu
                  menuItems={actualMenu}
                  options={{primaryOpen: "left"}}
                  classNames={{
                    card: styles.popupCard
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rowBetween}>
          <div className={styles.row}>
            <div onClick={(event) => {
              event?.stopPropagation();
              window.open(`https://t.me/+${contact && phones ? phones[ 0 ].phone : ""}`, "_blank")
            }}>
              <Telegramm/>
            </div>
            <div style={{marginLeft: "8px"}} onClick={(event) => {
              event?.stopPropagation();
              window.open(`https://api.whatsapp.com/send?phone=${contact && phones ? phones[ 0 ].phone : ""}`, "_blank")
            }}>
              <Whatsapp/>
            </div>
            {phones && phones.length > 0 &&
              <div className={styles.phone}>
                {formatPhone(String(phones[ 0 ].phone))}
              </div>}
            <div className={styles.type}>
              {type}
            </div>
          </div>
          <div className={cn(styles.responsible)}>
            {responsibleUser?.name && !iAmResponsibleUser && (
              <>
                {responsibleUser?.avatar
                  ? <img className={styles.responsibleLogo} src={responsibleUser?.avatar} alt="responsibleIcon.svg"/>
                  : <Responsible/>
                }
                <span>{`${responsibleUser?.lastName} ${responsibleUser?.name}`}</span>
              </>
            )}
            {iAmResponsibleUser &&
              <div className={styles.responsibleLogo}>
                <ImResponsibleIcon/>
              </div>
            }
          </div>
        </div>
        {relatedKinds &&
          <ContactStrap
            relatedKinds={relatedKinds}
            classNames={styles.strap}
            mini={mini}
            canOpenANewTab={canOpenNew}
            onClick={openInNewTab}
            atList={atList}
          />
        }
      </div>
    </div>
  )
}