import {
	Conditions,
	RealEstateAdditionalProperties,
	RealEstateFilter,
	RealEstateSort,
	SortOption,
	AddressValue,
} from "../../../../types";

export const getRealEstateSort = (sort: RealEstateSort): SortOption[] => [
	{
		field: sort.field,
		order: sort.sort,
	},
];

const getAdditionalPropertiesConditions = (
	additionalProperties: RealEstateAdditionalProperties
): Conditions => {
	const additionalPropertiesConditions: Conditions = [];
	Object.entries(additionalProperties).forEach((i: any) => {
		const name = i[0];
		let value = i[1];

		const isString = typeof value === "string";

		if (name && value !== undefined && value !== "") {
			if (Array.isArray(value)) {
				additionalPropertiesConditions.push({
					field: "additionalProperties",
					operator: "jsonin",
					value: [name, value],
				});

				return;
			}

			if (name?.includes("From")) {
				value = isString ? value.replaceAll(" ", "") : value;

				additionalPropertiesConditions.push({
					field: "additionalProperties",
					operator: "jsongte",
					value: [name.replace("From", ""), value],
				});

				return;
			}

			if (name?.includes("To")) {
				value = isString ? value.replaceAll(" ", "") : value;

				additionalPropertiesConditions.push({
					field: "additionalProperties",
					operator: "jsonlte",
					value: [name.replace("To", ""), value],
				});

				return;
			}

			additionalPropertiesConditions.push({
				field: "additionalProperties",
				operator: "jsoneq",
				value: [name, value],
			});
		}
	});

	return additionalPropertiesConditions;
};

type GetRealEstateFilters = {
	searchPayload: RealEstateFilter;
	subName?: string;
	absoluteSearchConditions?: Conditions;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getRealEstateFilters = ({
	searchPayload,
	subName = "",
	absoluteSearchConditions,
}: GetRealEstateFilters): Conditions => {
	const search = { ...searchPayload };
	if (search?.unit) {
		if (search?.unit !== "объект") {
			search.pricePerUnitFrom = search?.priceFrom ?? "";
			search.pricePerUnitTo = search?.priceTo ?? "";

			delete search.priceFrom;
			delete search.priceTo;
		}

		delete search.unit;
	}
	let searchConditions: Conditions = [];

	Object.entries(search).forEach((i: any) => {
		const name = i[0];
		let value = i[1];

		const isString = typeof value === "string";
		const isArray = Array.isArray(value);
		const isObject = typeof value === "object" && !isArray;

		if (name && value !== undefined && value !== "") {
			if (name === "additionalProperties") {
				searchConditions = [...searchConditions, ...getAdditionalPropertiesConditions(value)];

				return;
			}

			if (isArray) {
				searchConditions.push({
					field: subName + name,
					operator: "in",
					value,
				});

				return;
			}

			if (name === "contactId") {
				searchConditions.push({
					field: "contacts.contactId",
					operator: "eq",
					value,
				});

				return;
			}

			if (name === "fullTextSearch") {
				searchConditions.push({
					field: "fullTextSearch",
					operator: "like",
					value,
				});

				return;
			}

			if (name === "responsibleUsers") {
				const usersId = value?.userId?.map((userOption) => userOption.value);

				searchConditions.push({
					field: "responsibleUsers.userId",
					operator: "in",
					value: usersId,
				});

				return;
			}

			if (name?.includes("address")) {
				searchConditions.push({
					field: subName + name,
					operator: "eq",
					value: (value as AddressValue).value,
				});

				return;
			}

			if (isObject) {
				searchConditions = [
					...searchConditions,
					...getRealEstateFilters({
						searchPayload: value,
						subName: `${name}.`,
					}),
				];

				return;
			}

			if (name?.includes("From")) {
				value = isString ? value.replaceAll(" ", "") : value;

				searchConditions.push({
					field: subName + name.replace("From", ""),
					operator: "gte",
					value,
				});

				return;
			}

			if (name?.includes("To")) {
				value = isString ? value.replaceAll(" ", "") : value;

				searchConditions.push({
					field: subName + name.replace("To", ""),
					operator: "lte",
					value: String(value).replaceAll(" ", ""),
				});

				return;
			}

			if (name === "phone") {
				searchConditions.push({
					filterType: "or",
					conditions: [
						{
							field: "additionalProperties",
							operator: "jsoneq",
							value: ["parserPhones.phone", value],
						},
						{
							field: "contacts.phones.phone",
							operator: "eq",
							value,
						},
					],
				});

				return;
			}

			searchConditions.push({
				field: subName + name,
				operator: "eq",
				value,
			});
		}
	});

	if (absoluteSearchConditions) {
		return [...searchConditions, ...absoluteSearchConditions];
	}

	return searchConditions;
};
