import { useState } from "react";
import { H1 } from "@sdelka_crm/sdelka-crm-component-library";

import { Positions } from "./components";
import { useLoadPositions } from "../../../service/api/queries";

import styles from "./index.module.scss";

// Positions permissions wrapper
export const AccessSettings = (): JSX.Element => {
	const [activePosition, setActivePosition] = useState("");
	const { positions, refetch } = useLoadPositions({});

	return (
		<div className={styles.root}>
			<H1 classNames={{ root: styles.h1 }}>Настройки должности и права</H1>

			<div className={styles.content}>
				{positions && (
					<Positions
						refetch={refetch}
						positions={positions}
						activePosition={activePosition}
						setActivePosition={setActivePosition}
					/>
				)}
				{/* Temporary comment until done  */}
				{/* <Permissions /> */}
			</div>
		</div>
	);
};
