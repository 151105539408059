import { UserAccountData } from "../../../../../../types";
import cn from "classnames";
import styles from "./index.module.scss";
import { ReactComponent as ContactIcon } from "../../../../../../assets/icons/iconFaceGray.svg";

type Props = {
  account: UserAccountData
}

export const CardObjectResponsible = ({account}: Props): JSX.Element => {
  const {
    avatar,
    lastName,
    name,
    secondName
  } = account

  return (
    <div className={cn(styles.contact)}>
      <div className={styles.avatar}>
        {avatar
          ? <img src={avatar} alt={"Contact"}/>
          : <ContactIcon/>
        }
      </div>
      <span>
           {`${name || ""} ${secondName || ""} ${lastName || ""}`}
        </span>
    </div>
  )
}