import { CreateTaskForm, FinishTaskPayload, Task, TaskModalMode } from "../../../types";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { useHandleUpdateTask, useModeReducer } from "./helpers";
import { useLoadTask } from "../../api/queries";
import { useFinishTask } from "../../api/mutations";
import { useFinishSuccessTask } from "../../api/mutations/tasks/useFinishSuccessTask";
import { useRefuseTask } from "../../api/mutations/tasks/useRefuseTask";

type TaskModalContextType = {
  task?: Task;
  isFetching: boolean;
  onUpdatedTask: () => void;
  refetch: () => void;
  mode: TaskModalMode;
  handleFinishMode: () => void;
  handleEditMode: () => void;
  handleViewMode: () => void;
  handleUpdateTask: (form: CreateTaskForm) => void;
  handleSubmitFinishTask: (values: FinishTaskPayload) => void;
  handleSubmitFinishSuccessTask: (values: FinishTaskPayload) => void;
  handleSubmitRefuse: (values: FinishTaskPayload) => void;
  isLoadingFinishing: boolean;
};

const TaskModalContext = createContext<TaskModalContextType | null>(null);

type Props = {
  id: string;
  children: ReactNode;
  onUpdatedTask: () => void;
};

export const TaskModalContextProvider = ({id, onUpdatedTask, children}: Props): JSX.Element => {
  const {data: task, isFetching, refetch} = useLoadTask({id});
  const {createdAt} = task || {};

  const {mode, handleFinishMode, handleViewMode, handleEditMode} = useModeReducer({createdAt});

  const onUpdateSuccess = () => {
    handleViewMode();
    refetch();
  };

  const {handleUpdateTask} = useHandleUpdateTask({onUpdateSuccess, id});

  const onSuccess = () => {
    handleViewMode();
    onUpdatedTask();
  };

  const {mutate: handleSubmitFinishTask, isLoading: isLoadingFinishing} = useFinishTask({onSuccess});
  const {
    mutate: handleSubmitFinishSuccessTask,
    isLoading: isLoadingFinishingSuccess
  } = useFinishSuccessTask({onSuccess});
  const {mutate: handleSubmitRefuse, isLoading: isLoadingFinishingRefuse} = useRefuseTask({onSuccess});

  const value = useMemo(
    () => ({
      task,
      isFetching,
      onUpdatedTask,
      refetch,
      mode,
      handleFinishMode,
      handleViewMode,
      handleEditMode,
      handleUpdateTask,
      handleSubmitFinishTask,
      handleSubmitFinishSuccessTask,
      handleSubmitRefuse,
      isLoadingFinishing
    }),
    [
      task,
      isFetching,
      onUpdatedTask,
      refetch,
      mode,
      handleFinishMode,
      handleViewMode,
      handleEditMode,
      handleUpdateTask,
      handleSubmitFinishTask,
      isLoadingFinishing,
      isLoadingFinishingSuccess,
      isLoadingFinishingRefuse
    ]
  );

  return <TaskModalContext.Provider value={value}>{children}</TaskModalContext.Provider>;
};

export const useTaskModalContext = (): TaskModalContextType => {
  const context = useContext(TaskModalContext);
  if (context === null) {
    throw new Error("Task Modal context must be inside values");
  }

  return context;
};
