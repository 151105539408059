import styles from "./index.module.scss";
import { Popup, RadioButton } from "../../../../../../../../../../UI";
import cn from "classnames";
import { DetalizationViewChart } from "../DetalizationViewChart";
import { CollapseDown, Loader } from "@sdelka_crm/sdelka-crm-component-library";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Control, useForm } from "react-hook-form";
import { useGetChartData } from "../../../../../../../../../../../service/api/queries/real-estate-ad/useChartData";
import {
	GroupByDetalizationParams,
	KindDetalizationParams,
	KindDetalizationParamsMapper,
	KindDetalizationUniqueParamsMapper,
	PeriodDatesType,
	PlatformDetailsData,
} from "../../../../../../../../../../../types";
import { PeriodSelector } from "../PeriodSelector";
import { DATETYPES, formatDate, getDefaultDate } from "../PeriodSelector/utils";

type Props = {
	applicationId: string;
	feedId: string;
	isYandexOrDomclick?: boolean;
};

/*const chartButtonOptions = [
	{
		value: 1,
		label: <p>{KindDetalizationParamsMapper[KindDetalizationParams.VIEWS]}</p>,
	},
	{
		value: 2,
		label: <p>{KindDetalizationParamsMapper[KindDetalizationParams.CONTACTS]}</p>,
	},
	{
		value: 3,
		label: <p>{KindDetalizationParamsMapper[KindDetalizationParams.CALLS]}</p>,
	},
	{
		value: 4,
		label: <p>{KindDetalizationParamsMapper[KindDetalizationParams.ADD_TO_FAVORITE]}</p>,
	},
];*/

const getChartButtonOptions = (availableOptions: Partial<PlatformDetailsData>) => {
	const options: any[] = [];

	if (!availableOptions) return [];

	if (availableOptions.views || availableOptions.uniqViews)
		options.push({
			value: 1,
			label: <p>{KindDetalizationParamsMapper[KindDetalizationParams.VIEWS]}</p>,
		});
	if (availableOptions.contacts || availableOptions.unicContacts)
		options.push({
			value: 2,
			label: <p>{KindDetalizationParamsMapper[KindDetalizationParams.CONTACTS]}</p>,
		});
	if (availableOptions.calls)
		options.push({
			value: 3,
			label: <p>{KindDetalizationParamsMapper[KindDetalizationParams.CALLS]}</p>,
		});

	if (availableOptions.addToFavorite || availableOptions.uniqAddToFavorite)
		options.push({
			value: 4,
			label: <p>{KindDetalizationParamsMapper[KindDetalizationParams.ADD_TO_FAVORITE]}</p>,
		});

	return options;
};

const grouByOptions = ["неделю", "месяц", "9 месяцев"];

export const DetalizationChart: React.FC<Props> = ({ applicationId, feedId, isYandexOrDomclick }) => {
	const [enabled, setEnabled] = useState(false);
	const { control, watch } = useForm({
		defaultValues: {
			chartButtons: 1,
		},
	});
	const [selectedGroupByIndex, setSelectedGroupByIndex] = useState(0);

	const [isOpen, setIsOpen] = useState(false);

	const selectedGroupBy = Object.values(GroupByDetalizationParams)[selectedGroupByIndex];

	const [periodDates, updatePeriodDates] = useState<PeriodDatesType>({
		startDate: formatDate(getDefaultDate(selectedGroupBy, DATETYPES.START)),
		endDate: formatDate(getDefaultDate(selectedGroupBy, DATETYPES.END)),
	});

	const kindRaw = watch("chartButtons");

	const selectedKind = Object.keys(
		isYandexOrDomclick ? KindDetalizationParamsMapper : KindDetalizationUniqueParamsMapper
	)[kindRaw - 1];

	const {
		data: chartData,
		refetch: fetchChartData,
		isLoading,
		isRefetching,
	} = useGetChartData({
		applicationId,
		feedId,
		kind: selectedKind as KindDetalizationParams,
		groupBy: selectedGroupBy as GroupByDetalizationParams,
		enabled,
		periodDates,
	});

	const chartButtonOptions = getChartButtonOptions(chartData?.totalForPeriod as PlatformDetailsData);

	const handleClick = useCallback(
		(value: boolean) => {
			if (value) {
				setEnabled(true);
				fetchChartData();
			} else setEnabled(false);
		},
		[fetchChartData]
	);

	const itemClickHandler = (index) => {
		setSelectedGroupByIndex(index);
		setIsOpen(false);
	};

	return (
		<CollapseDown
			label={
				<div className={styles.label}>
					<p>Детализация</p>
					<Popup
						openTrigger={
							<p className={styles.popupSelectedOption}>
								за {grouByOptions.find((_, index) => index === selectedGroupByIndex)}
							</p>
						}
						outerOpenState={{
							outerIsOpen: isOpen,
							outerSetIsOpen: setIsOpen,
						}}
					>
						<div className={styles.popupBody}>
							
							{grouByOptions
								.map((item, index) => <p key = {item} onClick={() => itemClickHandler(index)}>за {item}</p>)
								.filter((_, index) => index !== selectedGroupByIndex)}
						</div>
					</Popup>
				</div>
			}
			headerChildren={
				<PeriodSelector selectedGroupBy={selectedGroupBy} updatePeriodDates={updatePeriodDates} />
			}
			onClick={handleClick}
			classNames={{ arrow: styles.arrow, root: styles.root }}
		>
			{isLoading && (
				<div style={{ padding: "24px" }}>
					<Loader />
				</div>
			)}
			{chartData && (
				<>
					<div className={styles.detailsWrapper}>
						<div>
							<div className={styles.detailsRow}>
								<p>Просмотров объявления:</p>
								{chartData?.totalForPeriod?.views !== undefined ? (
									<p>{chartData?.totalForPeriod?.views}</p>
								) : (
									<span className={styles.unknownInfo}>Неизвестно</span>
								)}
							</div>
							<div className={styles.detailsRow}>
								<p>Звонки:</p>
								{chartData?.totalForPeriod?.calls !== undefined ? (
									<p>{chartData?.totalForPeriod?.calls}</p>
								) : (
									<span className={styles.unknownInfo}>Неизвестно</span>
								)}
							</div>
						</div>
						<div>
							<div className={styles.detailsRow}>
								<p>Просмотров контакта:</p>
								{chartData?.totalForPeriod?.contacts !== undefined ? (
									<p>{chartData?.totalForPeriod?.contacts}</p>
								) : (
									<span className={styles.unknownInfo}>Неизвестно</span>
								)}
							</div>
							<div className={styles.detailsRow}>
								<p>Добавление в Избранное:</p>
								{chartData?.totalForPeriod?.addToFavorite !== undefined ? (
									<p>{chartData?.totalForPeriod?.addToFavorite}</p>
								) : (
									<span className={styles.unknownInfo}>Неизвестно</span>
								)}
							</div>
						</div>
					</div>
					<RadioButton
						separate
						className={cn(styles.radioButton)}
						name="chartButtons"
						options={chartButtonOptions}
						control={control}
						// disabled={isWaiting || isError}
						// disabledClassName={styles.paymentOptionsDisabled}
					/>
					<div className={styles.chartWrapper}>
						<DetalizationViewChart
							selectedOption={selectedKind as KindDetalizationParams}
							chartPointsData={chartData.chartPoints}
							isYandexOrDomclick={isYandexOrDomclick}
							isRefetching={isRefetching}
						/>
					</div>
				</>
			)}
		</CollapseDown>
	);
};
