import styles from "./index.module.scss"
import { ReactComponent as AddInCart } from "../../../../../../../../../../../../../../assets/icons/Shopping_Cart.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardProductForTable, RadioButton } from "../../../../../../../../../../../../../UI";
import {
  useLoadAppliedServicesHistory,
  useLoadAvailableServices,
  useLoadPurchasedServices
} from "../../../../../../../../../../../../../../service/api/queries";
import { Product, PurchasedProduct } from "../../../../../../../../../../../../../../types/product";
import cn from "classnames";
import { useApplyService } from "../../../../../../../../../../../../../../service/api/mutations";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../../../../../../../service/redux/modules/modal/modal.action";

type Props = {
  applicationId: string
}

const typeOptions = [
  {label: `Доступные`, value: "purchased"},
  {label: `Можно купить`, value: "available"},
];

type TabType = "available" | "purchased"

export const ApplyServiceModal = (
  {
    applicationId
  }: Props): JSX.Element => {
  const [tab, setTab] = useState<TabType | undefined>("purchased")
const dispatch = useDispatch()
  const [selected, setSelected] = useState("")

  const onTypeChange = (value: string | number) => {
    switch (value) {
      case "purchased":
        return setTab("purchased")
      case "available":
        return setTab("available")
      default:
        return setTab("purchased")
    }
  };

  const {control} = useForm({});

  const {isLoading: isFetchingPurchase, services: purchasedServices, refetch: refetchPurchased} =
    useLoadPurchasedServices({applicationId})
  const {isLoading: isFetchingAvailable, services: availableServices, refetch: refetchAvailable} =
    useLoadAvailableServices({applicationId})


  let data = purchasedServices
  let loading = isFetchingPurchase

  useEffect(() => {
    if (tab === "available") {
      refetchAvailable()
      data = availableServices
      loading = isFetchingAvailable
    } else {
      refetchPurchased()
      data = purchasedServices
      loading = isFetchingPurchase
    }
  }, [tab])

  const onOpenShop = () => {
    window.open(`/services?tab=PRODUCTS`)
  }

  const onCardClick = (id: string) => {
    window.open(`/services?tab=PRODUCTS&productId=${id}`)
  }
  const {refetch} = useLoadAppliedServicesHistory({applicationId})
  const {mutate, isLoading} = useApplyService({onSuccess:() => {
      tab === "purchased" ? refetchPurchased() : refetchAvailable()
      dispatch(closeModal())
      refetch()
    }})

  const handleApply = () => {
    mutate({applicationId, serviceId: selected})
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Применение купона
      </div>
      <div className={styles.select}>
        <RadioButton
          name="historyType"
          control={control}
          options={typeOptions}
          handleOnChange={onTypeChange}
          background="blue"
          backgroundButtons="blue"
          currentValue={tab}
        />
      </div>
      <div className={styles.content}>
        {data && !loading && data.map(elem => (
          <div
            className={cn(styles.item,
              {[ styles.selected ]: elem.id === selected})}
            onClick={() => setSelected(elem.id)}
            key={elem.id}
          >
            {tab === "purchased" && !isFetchingPurchase && !isFetchingAvailable
              ? <CardProductForTable product={elem} refetch={refetchPurchased} type={tab}/>
              : <CardProductForTable product={elem} refetch={refetchAvailable} type={tab} onCardClick={onCardClick}/>
            }
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <div className={styles.row} onClick={onOpenShop}>
          <AddInCart/>
          <div className={styles.openCart}>
            Открыть магазин
          </div>
        </div>
        <Button disabled={isLoading} onClick={handleApply}>
          Применить
        </Button>
      </div>
    </div>
  )
}