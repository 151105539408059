import { useQuery } from "react-query";

import { api } from "../../api";
import { Conditions, RealEstateFilter, RealEstateSort } from "../../../../types";
import { useState } from "react";

const DEFAULT_QN = "sdelka/user/load-users-list/DEFAULT_QN";

type UseLoadUsers = {
	enabled?: boolean;
	defaultTake?: number;
	// defaultFilter?: RealEstateFilter;
	absoluteSearchConditions?: Conditions;
	options?: {
		keepPreviousData?: boolean;
	};
	defaultPage?: number;
	scopes?: string[]
};

export const useLoadUsers = (
	{
		enabled = true,
		defaultPage,
		defaultTake,
		absoluteSearchConditions,
		options,
		scopes
	}: UseLoadUsers) => {

	const [take, setTake] = useState(defaultTake);
	const [page, setPage] = useState(defaultPage);
	const [sort, setSort] = useState<RealEstateSort | undefined>({
		field: "price",
		sort: "ASC",
	});

	const loadUsers = () => api.users.list(scopes);

	const { data, isLoading, refetch, isFetching} = useQuery(DEFAULT_QN, loadUsers, {
		enabled,
	});

	return {
		users: data?.data.data || [],
		total: data?.data.total || 0,
		isLoading,
		refetch,
		take,
		page,
		sort,
		setTake,
		setSort,
		setPage,
		isFetching
	};
};
