import { ReactNode, useRef, useState } from "react";
import cn from "classnames";

import { useClickOutside } from "../../../../../../../utils/hooks";

import { ReactComponent as Settings } from "../../../../../../../assets/icons/settings.svg";
import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
};

export const SettingsWrapper = ({ children }: Props): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	useClickOutside({
		ref: wrapperRef,
		onClick: () => setIsOpen(false),
	});

	return (
		<div
			ref={wrapperRef}
			className={cn(styles.root, {
				[styles.rootActive]: isOpen,
			})}
		>
			<div className={styles.content}>
				<button type="button" onClick={() => setIsOpen(!isOpen)} className={styles.iconToggle}>
					<Settings className={styles.icon} />
				</button>

				{isOpen && <div className={styles.dropdown}>{children}</div>}
			</div>
		</div>
	);
};
