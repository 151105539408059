import { useQuery } from "react-query";
import { api } from "../../api";

const DEFAULT_QN = "sdelka/application/load-applied-services-history/DEFAULT_QN";

type UseLoadApplications = {
  enabled?: boolean;
  applicationId: string
};

export const useLoadAppliedServicesHistory = (
  {
    enabled,
    applicationId
  }: UseLoadApplications) => {


  const loadServices = () =>
    api.application.getAppliedServicesHistory(applicationId);

  const {data, isLoading, refetch, isFetching, isError} = useQuery(
    [DEFAULT_QN],
    loadServices,
    {enabled}
  );

  return {
    list: data?.data?.data || [],
    isLoading,
    isError,
    total: data?.data?.total || 0,
    isFetching,
    refetch
  };
};
