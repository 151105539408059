import { Control, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { createNumberMask } from "text-mask-addons";
import { H4, P, Underlay } from "@sdelka_crm/sdelka-crm-component-library";

import { TransparentInput } from "../../../../../../components/UI";
import { formatThousand } from "../../../../../formatters";

import styles from "./index.module.scss";

type Props = {
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	control: Control<any>;
	currentPrice: number;
};

export const GenerateForm = ({ register, setValue, control, currentPrice }: Props): JSX.Element => {
	const pricePresentation = useWatch({
		name: "pricePresentation",
		control,
	});

	return (
		<>
			<H4 classNames={{ root: styles.rootH4 }}>Изменить стоимость объектов</H4>

			<Underlay className={styles.underlay}>
				<div className={styles.underlayBlock}>
					<P variant="medium-thin" classNames={{ root: styles.rootPHeader }}>
						Текущая:
					</P>

					<P variant="medium" classNames={{ root: styles.rootP }}>
						{`${formatThousand(currentPrice)} ₽`}
					</P>
				</div>

				<div className={styles.underlayBlock}>
					<P variant="medium-thin" classNames={{ root: styles.rootPHeader }}>
						В презентации:
					</P>

					<TransparentInput
						name="pricePresentation"
						register={register}
						className={styles.input}
						setValueAs={(value: string) => value?.toString().replaceAll(/[^\d.-]/g, "")}
						setValueForMask={setValue}
						value={pricePresentation || ""}
						mask={createNumberMask({
							thousandsSeparatorSymbol: " ",
							prefix: "",
							suffix: " ₽",
						})}
					/>
				</div>
			</Underlay>
		</>
	);
};
