import { useQuery } from "react-query";
import { api } from "../../api";
import { Conditions, RealEstateFilter, RealEstateSort } from "../../../../types";
import { useState } from "react";

const DEFAULT_QN = "sdelka/user/load-services-list/DEFAULT_QN";

type UseLoadServices = {
  enabled?: boolean;
  defaultTake?: number;
  // defaultFilter?: RealEstateFilter;
  absoluteSearchConditions?: Conditions;
  options?: {
    keepPreviousData?: boolean;
  };
  defaultPage?: number;
};

export const useLoadServices = (
  {
    enabled = true,
    defaultPage,
    defaultTake,
    absoluteSearchConditions,
    options,
  }: UseLoadServices) => {

  const [take, setTake] = useState(defaultTake);
  const [page, setPage] = useState(defaultPage);
  const [sort, setSort] = useState<RealEstateSort | undefined>({
    field: "price",
    sort: "ASC",
  });

  const loadServices = () => api.services.list();

  const { data, isLoading, refetch, isFetching} = useQuery(DEFAULT_QN, loadServices, {
    enabled,
  });

  return {
    services: data?.data.data || [],
    total: data?.data.total || 0,
    isLoading,
    refetch,
    take,
    page,
    sort,
    setTake,
    setSort,
    setPage,
    isFetching
  };
};
