/* eslint-disable max-len */
import styles from "./index.module.scss";

export const TermsText = (): JSX.Element => (
  <div className={styles.root}>
    <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>

    <p>
      Настоящее пользовательское соглашение (далее – соглашение) регулирует отношения между ООО «Сделка»
      (далее – компания» и пользователями интернет ресурса https://crm.sdelka-it.ru/ (далее – Пользователи).
    </p>

    <h2>Понятия и определения:</h2>

    <p>
      <b>«Сделка»</b> - это интернет-сервис, доступный по адресу{" "}
      <a href="./index">https://crm.sdelka-it.ru/</a> и представляющий собой совокупность содержащихся в
      информационной системе объектов интеллектуальной собственности Компании и информации, составляющих CRM
      систему, позволяющую авторизованным пользователям получать актуальную информацию об объектах
      недвижимости, размещенную в открытых источниках сети интернет, вести личные базы клиентов и объектов
      недвижимости, вести учет контрактов с клиентами, размещать рекламные публикации на интернет сайты в
      раздел с недвижимостью, совершать сделки с недвижимым имуществом.
    </p>

    <p>
      <b>Компания</b> – юридическое лицо, зарегистрированное в соответствии с требованиями законодательства
      Российской Федерации – Общество с ограниченной ответственностью «Сделка» (ИНН 7841093140, ОГРН
      1217800039269).
    </p>

    <p>
      Компания не является агрегатором, участником либо организатором сделки, покупателем, продавцом,
      посредником, агентом, представителем какого-либо пользователя, выгодоприобретателем или иным
      заинтересованным лицом в отношении сделок между пользователями. Компания не несет ответственности за
      актуальность сведений об объектах недвижимости
    </p>

    <p>
      <b>Сервис</b> – совокупность услуг, предоставляемых компанией авторизованным пользователям, направленных
      на сбор информации об объектах недвижимости, размещенной в публичных источниках сети интернет и
      обеспечению рабочих процессов пользователя посредством системы CRM.
    </p>

    <p>
      <b>CRM – система</b> – интернет-сервис, разработанный в целях автоматизации рабочих процессов
      Пользователей сервиса, позволяющая авторизованным пользователям самостоятельно вести базу клиентов,
      осуществлять взаимодействие с клиентами, контролировать и анализировать заявки от клиентов, осуществлять
      действия направленные на совершение сделок с недвижимым имуществом.
    </p>

    <p>
      <b>Пользователь</b> – физическое лицо, действующее от своего имени, либо от имени юридического лица,
      обладающее гражданской правоспособностью в соответствии с законодательством Российской Федерации,
      прошедшее регистрацию и авторизацию в соответствии с условиями настоящего соглашения. Пользователь
      использует сервис, предоставляемый компанией, чтобы получать информацию об объектах недвижимости и
      заключать сделки на свой риск без прямого или косвенного участия или контроля со стороны Компании.
    </p>

    <p>
      <b>Регистрация</b> – процедура создания профиля и получения доступа к сервису посредством заполнения
      специальной формы, ввода номера мобильного телефона и подтверждением личности посредством подтверждения
      номера мобильного телефона пользователя (простая электронная подпись) или авторизации посредством ЕСИА
      (Единая система идентификации и аутентификации) с последующим созданием уникальной комбинации
      логин/пароль для доступа к профилю.
    </p>

    <p>
      <b>Авторизация</b> – введение комбинации логин/пароль для входа в личный кабинет и использования
      Сервиса.
    </p>
    <p>
      <b>Профиль</b> – официальная страница Пользователя, обладающая уникальными признаками и доступная только
      Пользователю.{" "}
    </p>

    <p>
      <b>Простая электронная подпись</b> - это электронная подпись, которая посредством использования кодов,
      паролей или иных средств подтверждает факт формирования электронной подписи определенным лицом, согласно
      статье 5 федерального закона 63-ФЗ «Об электронной подписи» от 06.04.2011 года.
    </p>

    <section>
      <ol>
        <li>
          <h2>СЕРВИС</h2>
          <ol>
            <li>
              {" "}
              «Сделка» - это сервис по поиску и подбору информации об объектах недвижимости, размещенных в
              публичных источниках сети интернет, позволяющий авторизованным пользователям получить эти
              сведения и использовать их по своему усмотрению, включающий в себя CRM систему, позволяющую
              автоматизировать процесс взаимодействия между Пользователем и клиентами пользователя: ведение
              личных баз клиентов и объектов недвижимости, учет контрактов с клиентами, размещение рекламных
              публикаций на интернет сайты в раздел с недвижимостью, совершение сделки с недвижимым
              имуществом.
            </li>
            <li>
              {" "}
              Сервис предоставляется на платной основе, тарифы и стоимость использования сервиса указываются
              на специальной странице ресурса: ссылка на тарифы.
            </li>
            <li>
              Выбирая тариф, пользователь соглашается с стоимостью, условиями и порядком оплаты выбранного
              тарифа.
            </li>
            <li>
              Оплачивая выбранный тариф, Пользователь оплачивает единоразовый доступ к сервису, право на
              использование сервиса сохраняется за Пользователем в течение периода действия соответствующего
              тарифа.
            </li>
            <li>
              Информация, предоставляемая сервисом не является собственностью Компании, компания не несет
              ответственности за достоверность и актуальность представленной сервисом информации.
            </li>
            <li>
              Компания не несет ответственности за действия Пользователей, добросовестность пользователя при
              использовании сервиса компании – предполагается.
            </li>
            <li>
              Пользователь обязан полностью ознакомиться с настоящим соглашением до момента начала
              использования сервиса. Начиная использовать сервис, Пользователь считается принявшим условия
              настоящего соглашения в полном объеме, без всяких оговорок и исключений в соответствии со ст.438
              Гражданского кодекса Российской Федерации.{" "}
            </li>
            <li>
              В случае несогласия Пользователя с какими-либо из положений настоящего соглашения, Пользователь
              не вправе использовать Сайт или сервисы Сайта. В случае если Компанией были внесены какие-либо
              изменения в настоящее соглашение, с которыми Пользователь не согласен, он обязан прекратить
              использование сервиса.
            </li>
          </ol>
        </li>
        <li>
          <h2> РЕГИСТРАЦИЯ И АВТОРИЗАЦИЯ ПОЛЬЗОВАТЕЛЯ</h2>
          <ol>
            <li>
              Регистрация пользователя осуществляется посредством заполнения специальной формы, размещенной на
              сайте компании с обязательным подтверждением личности посредством подтверждения номера
              мобильного телефона пользователя (простая электронная подпись) или авторизации посредством ЕСИА
              (Единая система идентификации и аутентификации)
            </li>
            <li>
              Информация, заполняемая пользователем должна быть достоверной и актуальной, не нарушающей
              законодательство Российской Федерации а также права третьих лиц. Пользователь несет
              ответственность за предоставленную о себе информацию.
            </li>
            <li>
              Компания посредством сервиса вправе запросить у Пользователя документы, удостоверяющие личность,
              подтверждающие полномочия или благонадежность пользователя. Перечень запрашиваемых документов
              компания выбирает по своему усмотрению.
            </li>
            <li>
              Компания не гарантирует, что вся информация, размещаемая Пользователем, соответствует
              действительности.
            </li>
            <li>
              Компания не раскрывает персональные данные Пользователя третьим лицам, за исключением случаев,
              предусмотренных законом.
            </li>
            <li>
              Авторизация в сервисе Компании осуществляется посредством ввода номера мобильного телефона и
              ввода пароля Пользователя.
            </li>
            <li>Авторизация дает Пользователю доступ к своему профилю и сервису Компании.</li>
          </ol>
        </li>

        <li>
          <h2>ИНФОРМАЦИЯ</h2>

          <ol>
            <li>
              Сервис по запросу Пользователя предоставляет информацию, собранную в публичных (открытых)
              источниках сети интернет об объектах недвижимости.
            </li>
            <li>
              Компания не является собственником данной информации, не отвечает за ее достоверность и
              последующее использование Пользователем.
            </li>
            <li>
              Информация может включать в себя сведения об объектах недвижимости, их стоимости, контактные
              данные правообладателей/представителей правообладателей объектов.{" "}
            </li>
            <li>
              Информация, полученная посредством сервиса, используется Пользователем на свой страх и риск.
            </li>
            <li>
              Компания осуществляет копирование и хранение полученной информации без целей самостоятельного
              коммерческого использования.
            </li>
            <li>
              Права на печатную информацию и графические изображения принадлежат их правообладателям. Компания
              не является распространителем данной информации.
            </li>
            <li>Компания не проверяет достоверность и актуальность полученной информации.</li>
            <li>
              Компания не несет ответственности за ущерб, убытки (в том числе упущенную выгоду), понесенные
              Пользователем в результате использования информации, предоставленной сервисом.
            </li>
            <li>Информация хранится компанией бессрочно и не подлежит удалению.</li>
          </ol>
        </li>

        <li>
          <h2>ПОЛЬЗОВАТЕЛЬСКИЙ КОНТЕНТ</h2>
          <ol>
            <li>
              Пользователь вправе создавать и загружать на сервис информацию (пользовательский контент) по
              своему усмотрению.
            </li>
            <li>Права на пользовательский контент принадлежат пользователю.</li>
            <li>
              Компания не несет ответственности за пользовательский контент, не осуществляет его проверку на
              актуальность и достоверность.
            </li>
            <li>
              Пользователь дает свое согласие Компании на хранение и использование пользовательского контента
              по своему усмотрению.
            </li>
            <li>Пользовательский контент, внесенный в систему, не подлежит удалению.</li>
          </ol>
        </li>

        <li>
          <h2>ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</h2>
          Пользователь обязуется:
          <ol>
            {" "}
            <li>Строго выполнять все требования законодательства Российской Федерации.</li>
            <li>Соблюдать требования настоящего соглашения.</li>
            <li>Обеспечивать актуальность и достоверность размещаемой Пользователем информации.</li>
            <li>
              Не копировать, тиражировать, распространять информацию, полученную в ходе взаимодействия с
              сервисом.
            </li>
            <li>
              Не использовать информацию, полученную в ходе взаимодействия с сервисом, в противоправных целях.
            </li>
            <li>Не загружать и не использовать на сервисе вредоносные программы.</li>
            <li>Не предпринимать действия, которые могут помешать нормальной работе сервиса.</li>
            <li>
              Сохранять конфиденциальность полученных персональных данных третьих лиц, в соответствии с
              политикой конфиденциальности персональных данных.{" "}
            </li>
            <li>
              Своевременно и в полном объеме производить оплату сервиса в соответствии с выбранным тарифом.
            </li>
            <li>
              В случае нарушения пользователем требований настоящего соглашения, компания вправе применить к
              пользователю меры, предусмотренные разделом 5 настоящего соглашения.
            </li>
          </ol>
        </li>
        <li>
          <h2>ПРАВА КОМПАНИИ</h2>
          <ol>
            Компания вправе:
            <li>Сохранять и анализировать информацию, полученную в ходе работы сервиса.</li>
            <li>Применять санкции, предусмотренные разделом 7 настоящего пользовательского соглашения.</li>
            <li>Получать стоимость оплаты сервиса.</li>
          </ol>
        </li>

        <li>
          <h2>САНКЦИИ</h2>
          <ol>
            <li>
              В случае нарушения пользователем требований настоящего соглашения, компания вправе по своему
              усмотрению:
            </li>
            <li>Ограничить доступ пользователя к своему профилю;</li>
            <li>Ограничить доступ пользователя к сервису;</li>
            <li>Заблокировать профиль пользователя;</li>
            <li>
              В случае блокировки профиля пользователя, данные о пользователе и совершенных пользователем
              действий хранятся компанией.
            </li>
          </ol>
        </li>
        <li>
          <h2>ДЕАКТИВАЦИЯ ПРОФИЛЯ</h2>
          <ol>
            <li>
              Пользователь имеет право в любой момент по собственной инициативе деактивировать свой профиль в
              сервисе, посредством запроса в службу поддержки.
            </li>
            <li>
              Профиль становится неактивным в течение 5 рабочих дней с момента поступления соответствующего
              запроса.
            </li>
            <li>
              В случае деактивации профиля, данные о пользователе и совершенных пользователем действиях,
              пользовательский контент хранятся компанией.
            </li>
          </ol>
        </li>

        <li>
          <h2>ЗАЩИТА ИНТЕЛЛЕКТУАЛЬНЫХ ПРАВ</h2>
          <ol>
            <li>
              Исключительные права на программы для ЭВМ, необходимые для использования сервиса, графическое
              оформление интерфейса (дизайна) и другие элементы сервиса, фирменное наименование, товарные
              знаки/логотипы и другие отличительные знаки Компании, в том числе используемые в составе
              доменного имени https://crm.sdelka-it.ru/, принадлежат Компании.
            </li>
          </ol>
        </li>
      </ol>
    </section>
  </div>
);
