import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Pagination } from "@sdelka_crm/sdelka-crm-component-library";
import { SidebarLayout, Table } from "../../UI";
import { ContactInfo } from "../ContactCard";
import { UnknownContact } from "../UnknownContact";
import { TableActions } from "./TableActions";
import { ContactsFilters } from "./ContactsFilters";
import { useColumns } from "./columns";
import { openDrawer } from "../../../service/redux/modules/drawer/drawer.action";
import { useContacts } from "../../../service/api/queries";
import { RoleGuard } from "../../../service/router/RoleGuard";
import { useCreateContact } from "./helpers";
import { useQueryParams } from "../../../utils/hooks";
import { capitalizeFirstLetter } from "../../../utils/formatters";
import { Contact, ContactSubtypeMap } from "../../../types";
import styles from "./index.module.scss";
import { useLocation } from "react-router";

const CONTACT_INFO_WIDTH = 800;
const navigationY = [{label: "Контакт"}];

export const Contacts = (): JSX.Element => {
  const {queryParams, changeQueryParams, removeQueryParam} = useQueryParams<{
    contactId: string;
    phone: string;
  }>([{name: "contactId"}, {name: "phone"}]);

  const dispatch = useDispatch();
  const [contextClick, setContextClick] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);

  const join = ["company", "user", "phones", "comments"];

  const {
    contactsList,
    total,
    page,
    take,
    setTake,
    sort,
    setSort,
    isFetching,
    setPage,
    refetch,
    filter,
    setFilter,
  } = useContacts({
    join,
    defaultSort: {subtype: "ASC"},
    options: {keepPreviousData: true},
  });

  useCreateContact({
    refetchContacts: refetch,
    drawerWidth: CONTACT_INFO_WIDTH,
  });

  const columns = useColumns(checked, setChecked, contextClick, refetch);

  const tableRowClick = ({id}) => changeQueryParams([{name: "contactId", newValue: id}]);

  useEffect(() => {
    if (selectAll) {
      setChecked([...contactsList.map((item) => item.id)]);
    } else {
      setChecked([]);
    }
  }, [selectAll]);

  const closeDrawer = () => {
    refetch();
    removeQueryParam("contactId");
  };

  const openContactDrawer = () => {
    dispatch(
      openDrawer({
        children: [<ContactInfo contactId={queryParams.contactId} refetchList={refetch}/>],
        width: CONTACT_INFO_WIDTH,
        onClose: closeDrawer,
        yNavigation: {
          initialTab: 0,
          navigation: navigationY,
        },
      })
    );
  };

  const openUnknownContactDrawer = (phone: string) => {
    dispatch(
      openDrawer({
        children: <UnknownContact phone={phone as string}/>,
        width: CONTACT_INFO_WIDTH,
        onClose: closeDrawer,
      })
    );
  };

  useEffect(() => {
    if (queryParams.phone) {
      openUnknownContactDrawer(queryParams.phone);
    }
  }, [queryParams.phone]);

  useEffect(() => {
    if (queryParams.contactId) {
      openContactDrawer();
    }
     // changeQueryParams([{name: "id", newValue: location.search.slice(17)}])
     //  openContactDrawer();


  }, [queryParams.contactId]);

  const transformTitle = (title: string): JSX.Element => (
    <div className={styles.tableGroupTitle}>{capitalizeFirstLetter(ContactSubtypeMap[ title ])}</div>
  );

  const onPageChange = (pageNumber: number) => {
    setChecked([]);
    setSelectAll(false);
    setPage(pageNumber);
  };

  return (
    <RoleGuard only="estateAgencyUser">
      <SidebarLayout
        hat={{
          element: <ContactsFilters filter={filter} formSubmit={setFilter} setPage={setPage}/>,
          elementHeight: 0,
        }}
      >
        <div className={styles.container}>
          <div className={styles.tableContainer}>
            <TableActions
              take={take}
              setTake={setTake}
              sort={sort}
              setSort={setSort}
            />
            <Table<Contact>
              idKey="id"
              groupKey="subtype"
              transformGroupTitle={transformTitle}
              data={contactsList}
              columns={columns}
              rowClass={styles.row}
              colClass={styles.contactsTableCol}
              onRowClick={tableRowClick}
              activeRow={{
                accessor: queryParams.contactId || "",
                activeId: queryParams.contactId || "",
                onContextMenu: ({id}) => setContextClick(id),
              }}
              loading={{
                isLoading: isFetching,
                rowsCount: 10,
              }}
            />
          </div>
          <Pagination
            totalCount={total}
            pageSize={take}
            currentPage={page}
            onPageChange={onPageChange}
            isLoading={isFetching}
            classNames={{root: styles.pagination}}
          />
        </div>
      </SidebarLayout>
    </RoleGuard>
  );
};
