import { useState } from "react";

import { Department, Office } from "../../../../../types";
import { Alerter } from "../../../../UI";
import { CreateDepartmentForm, OfficeDepartments, OfficeNameForm } from "./components";
import { useOfficeDelete } from "../../../../../service/api/mutations";

import { ReactComponent as TrashIcon } from "../../../../../assets/icons/trash.svg";
import styles from "./index.module.scss";

type Props = {
	refetchOffices: () => void;
	office: Office;
};

export const OfficeCard = ({ refetchOffices, office }: Props): JSX.Element => {
	const [deleteOfficeError, setDeleteOfficeError] = useState("");

	const { mutate } = useOfficeDelete({
		onSuccess: () => refetchOffices(),
		setError: (error: string) => setDeleteOfficeError(error),
	});

	return (
		<div className={styles.root}>
			<TrashIcon onClick={() => mutate(office.id)} className={styles.deleteIcon} />

			<OfficeNameForm refetchOffices={refetchOffices} office={office} />

			{deleteOfficeError && (
				<div className={styles.alertWrapper}>
					<Alerter
						iconPosition="top-left"
						withButton={{
							buttonText: "Перенести",
							onButtonClick: () => console.log("Move clicked"),
						}}
					>
						Для удаления перенесите сотрудников в другой отдел
					</Alerter>
				</div>
			)}

			{office.addressLabel && <div className={styles.address}>{office.addressLabel}</div>}

			{office.departments?.map((department: Department) => (
				<OfficeDepartments key={department.id} refetchOffices={refetchOffices} department={department} />
			))}

			<CreateDepartmentForm refetchOffices={refetchOffices} office={office} />
		</div>
	);
};
