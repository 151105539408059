import { useDispatch } from "react-redux";
import { Button, H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { OfficeSettings } from "../../../OfficeSettings";
import { AccessSettings } from "../../../AccessSettings";
import { SendInvitation } from "../SendInvitation";
import { useLoadCompany } from "../../../../../service/api/queries";
import { openDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";

import styles from "./index.module.scss";

type Props = {
	refetchInvites: () => void;
	officeOptions: any[];
	positionOptions: any[];
};

export const EmployeesHeader = ({ refetchInvites, officeOptions, positionOptions }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { company, isLoading } = useLoadCompany({});

	const openOfficesDrawer = () => {
		dispatch(
			openDrawer({
				children: <OfficeSettings />,
				width: 707,
			})
		);
	};

	const openAccessSettingsDrawer = () => {
		dispatch(
			openDrawer({
				children: <AccessSettings />,
				width: 1066,
			})
		);
	};

	return (
		<div className={styles.root}>
			<div className={styles.leftPart}>
				<div>
					<H2 classNames={{ root: styles.h2 }}>Сотрудники</H2>

					{isLoading ? (
						<div className={styles.companyLoading} />
					) : (
						<span>{`id компании: ${company?.shortId}`}</span>
					)}
				</div>

				<SendInvitation
					positionOptions={positionOptions}
					officeOptions={officeOptions}
					refetchInvites={refetchInvites}
				/>
			</div>

			<div className={styles.rightPart}>
				<Button
					type="button"
					variant="text"
					onClick={openAccessSettingsDrawer}
					classNames={{ root: styles.link }}
				>
					Должности
				</Button>

				<Button type="button" variant="text" onClick={openOfficesDrawer} classNames={{ root: styles.link }}>
					Офисы и отделы
				</Button>
			</div>
		</div>
	);
};
