export type TaskModalMode = "view" | "edit" | "finish";

export const TaskModalEnum: Record<TaskModalMode, TaskModalMode> = Object.freeze({
	view: "view",
	edit: "edit",
	finish: "finish",
});

export type ActionTypeModalMode = "makeEditMode" | "makeViewMode" | "makeFinishMode";

export type ActionModalMode = { type: ActionTypeModalMode };
export type StateModalMode = { mode: TaskModalMode };
