import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as SaveText } from "../../../../../../../assets/icons/saveComment.svg";

import { Textarea } from "../../../../../../UI";

import styles from "./index.module.scss";
import { ReactComponent as InfoIcon } from "../../../../../../../assets/icons/iconInfo.svg";
import cn from "classnames";

type Props = {
  comment?: string;
  onSaveComment: (value: string) => void;
  isInProgress?: boolean
  isLoading?: boolean
};

export const LeadComment = (
  {
    comment,
    onSaveComment,
    isInProgress,
    isLoading
  }: Props): JSX.Element => {
  const {register, reset} = useForm({defaultValues: {comment}});
  const [value, setValue] = useState<string>("")

  useEffect(() => {
    reset({comment});
  }, [comment]);

  const onSave = (val: string) => {
    if (val !== comment) {
      onSaveComment(val);
    }
  };
  return (
    <>
      <div className={styles.row}>
        <div className={styles.title}>
          Комментарий к лиду
        </div>
        <Tooltip
          classNames={{
            root: styles.tooltipDescription,
            card: styles.tooltipCard,
          }}
          iconSize="big"
          CustomIcon={InfoIcon}
        >
          Блок комментария используется для вспомогательных заметок только при работе с лидом.
        </Tooltip>
      </div>
      <Textarea
        name="comment"
        rows={4}
        placeholder="Комментарий отсутствует"
        register={register}
        className={styles.comment}
        onChange={(text) => setValue(text)}
        readOnly={!isInProgress}
      />
      <div
        className={cn(styles.saveText, {[styles.saveTextSaving]: isLoading})}
        onClick={() => onSave(value)}
        hidden={!isInProgress}
      >
        <SaveText/>
      </div>
    </>
  );
};
