import { useMemo, useState, useEffect, Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import isEqual from "lodash.isequal";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ButtonSquare, Checkbox, MultiSelect, RoundInput, MultiLayerSelect } from "../../../../UI";
import { RequestFiltersModal } from "../RequestFiltersModal";
import { useLoadBusinessProcessSources } from "../../../../../service/api/queries";
import { openDrawer, closeDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";
import { DEFAULT_TRAFFIC } from "../../../../../service/api/helpers";
import { useResponsibleUsers, useWindowWidth } from "../../../../../utils/hooks";
import { useSelect, selectBusinessProcesses } from "../../../../../service/redux/selectors";
import { useWatchers } from "./helpers";
import { ApplicationsFilter, Stage, SelectOption } from "../../../../../types";

import { ReactComponent as CancelIcon } from "../../../../../assets/icons/cancel.svg";
import { ReactComponent as Filters } from "../../../../../assets/icons/filters.svg";
import styles from "./index.module.scss";

const requestStatusOptions = [
	{ value: "SUCCESS", label: "Успешная" },
	{ value: "ALARM", label: "Требующая внимание" },
	{ value: "CANCEL", label: "Отказ" },
	{ value: "DELAY", label: "Отложенный спрос" },
];

type Props = {
	formSubmit: (values: ApplicationsFilter) => void;
	filter: ApplicationsFilter;
	setRequestStages: Dispatch<SetStateAction<Stage[]>>;
	requestStages: Stage[];
};

export const RequestFastFilters = ({
	formSubmit,
	setRequestStages,
	requestStages,
	filter,
}: Props): JSX.Element => {
	const { register, setValue, control, handleSubmit, getValues, watch, reset } = useForm({
		defaultValues: DEFAULT_TRAFFIC,
	});

	const { watcherResponsibleUsers, watcherTypeId } = useWatchers({
		control,
		getValues,
	});
	const [expanded, setExpanded] = useState(false);

	const width = useWindowWidth();

	useEffect(() => {
		if (width > 1600 && !expanded) {
			setExpanded(true);
		} else if (expanded) {
			setExpanded(false);
		}
	}, [width]);
	const [showBtn, setShowBtn] = useState(false);

	const formWatcher = watch();

	useEffect(() => {
		if (!isEqual(formWatcher, filter)) {
			if (!showBtn) {
				setShowBtn(true);
			}
		} else {
			setShowBtn(false);
		}
	}, [Object.values(formWatcher)]);

	useEffect(() => {
		if (filter) {
			reset(filter as any);
		}
	}, [filter]);

	const dispatch = useDispatch();
	const [showViews, setShowViews] = useState(false);
	const [requestStageOptions, setRequestStageOptions] = useState<SelectOption[]>([]);

	const { businessProcesses } = useSelect(selectBusinessProcesses);

	const requestTypeOptions = useMemo(
		() =>
			businessProcesses.map((process) => ({
				value: process.id,
				label: process.typeName,
			})),
		[businessProcesses]
	);

	const { users, responsibleUsers } = useResponsibleUsers();

	const { data: sourcesList } = useLoadBusinessProcessSources();
	const sourcesOptions = useMemo(
		() =>
			sourcesList.map((source) => ({
				value: source,
				label: source,
			})),
		[sourcesList]
	);

	useEffect(() => {
		if (watcherTypeId?.length === 1 && watcherTypeId) {
			const foundedBusinessProcess = businessProcesses.find((business) => business.id === watcherTypeId[0]);
			setRequestStages(
				foundedBusinessProcess?.applicationStages ? foundedBusinessProcess.applicationStages : []
			);
		} else {
			setRequestStageOptions([]);
			setRequestStages([]);
			if (getValues()?.stage?.length !== 0) {
				setValue("stage", []);
			}
		}
	}, [formWatcher.typeId, businessProcesses]);

	useEffect(() => {
		const requestOption: SelectOption[] = requestStages.map((stage) => ({
			value: String(stage.order),
			label: stage.name,
		}));

		setRequestStageOptions(requestOption);
	}, [requestStages]);

	const prepareSubmitForm = (values) => {
		reset(values);
		formSubmit(values);
		setShowBtn(false);
	};

	const submittedFromModal = (values) => {
		dispatch(closeDrawer());
		prepareSubmitForm({
			...values,
			fullTextSearch: formWatcher.fullTextSearch,
			typeId: formWatcher.typeId,
			responsibleUsers: formWatcher.responsibleUsers,
			signed: formWatcher.signed,
		});
	};

	const onOpenRequests = () => {
		const curShowViews = !showViews;
		setShowViews(curShowViews);
		dispatch(
			openDrawer({
				children: (
					<RequestFiltersModal
						defaultValues={getValues()}
						users={users}
						sourcesOptions={sourcesOptions}
						requestStageOptions={requestStageOptions}
						submitFilter={submittedFromModal}
					/>
				),
				width: 800,
			})
		);
	};

	const resetFilter = () => {
		formSubmit({ ...DEFAULT_TRAFFIC });
		reset({ ...DEFAULT_TRAFFIC } as any);
		setShowBtn(false);
	};

	return (
		<form
			className={cn(styles.root, {
				[styles.expanded]: expanded,
			})}
			onSubmit={handleSubmit(prepareSubmitForm)}
		>
			<div className={styles.firstLine}>
				<div className={cn(styles.filterWrapper, styles.searchWrapper)}>
					<RoundInput register={register} name="fullTextSearch" prepend="search" placeholder="Поиск" />
				</div>

				<div className={cn(styles.filterWrapper, styles.typeWrapper)}>
					<MultiSelect
						name="typeId"
						setValue={setValue}
						options={requestTypeOptions}
						register={register}
						placeholder="Тип заявки"
						defaultValue={watcherTypeId}
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.responsibleWrapper)}>
					<MultiLayerSelect
						name="responsibleUsers"
						options={responsibleUsers || []}
						register={register}
						placeholder="Ответственный"
						setValue={setValue}
						isSearchable
						defaultValue={watcherResponsibleUsers}
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.signedWrapper)}>
					<Checkbox
						control={control}
						name="signed"
						label="С подписанным договором"
						variant="noOpacity"
						weight="bold"
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.statusWrapper)}>
					<MultiSelect
						name="status"
						setValue={setValue}
						options={requestStatusOptions}
						register={register}
						placeholder="Статус заявки"
						defaultValue={formWatcher.status}
					/>
				</div>
			</div>

			<div className={styles.secondLine}>
				<div className={styles.filtersWrapper}>
					<Button color="blue-disabled" onClick={onOpenRequests} classNames={{ root: styles.moreWrapper }}>
						<div className={styles.more}>
							<Filters />

							<span className={styles.filterBtnText}>Еще фильтры</span>
						</div>
					</Button>
					<Button
						variant="text"
						classNames={{ root: styles.resetBtn, icon: styles.cancelIcon }}
						onClick={resetFilter}
						Icon={CancelIcon}
					>
						<span>Сбросить</span>
					</Button>

					{showBtn && (
						<div className={styles.submitWrapper}>
							<ButtonSquare type="submit">Показать</ButtonSquare>
						</div>
					)}
				</div>
			</div>
		</form>
	);
};
