import styles from "./index.module.scss";
import { AgencyAgrementType, Contact } from "types";
import { CardContact } from "../../../../../../../UI/Card/card-contact/CardContact";

type Props = {
  data?: Contact,
  whoIsClient?: 'seller' | 'buyer';
  canDelete?: boolean
};

export const ContactCard = ({data, whoIsClient, canDelete}: Props): JSX.Element => (
    <div className={styles.root}>
      {whoIsClient === "seller" ? <h2>Продавец</h2> : <h2>Покупатель</h2>}
      {data &&
        <CardContact
          contact={data}
          backgroundWhite
          canDelete={canDelete}
        />}
    </div>
  );
