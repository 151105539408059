import { RealEstateMap, XYNavLayout } from "../../../UI";
import { RealEstateCreate } from "../mode";
import { useMapAdjust } from "../helpers";
import { useSelect, selectCurrentAccount, selectObjectEdit } from "../../../../service/redux/selectors";
import { useQueryParams } from "../../../../utils/hooks";
import { paths } from "../../../../utils/paths";

import { ReactComponent as Edit } from "../../../../assets/icons/edit-page.svg";

type QueryParams = {
	preCreateForm: string;
};

export const CreateRealEstatePage = (): JSX.Element => {
	const { coord, dontMarkOnMap } = useSelect(selectObjectEdit);
	const {
		queryParams: { preCreateForm },
	} = useQueryParams<QueryParams>([{ name: "preCreateForm" }]);

	const { currentAccount } = useSelect(selectCurrentAccount);
	const mapZoom = Number(currentAccount?.company?.mapZoom);
	const mapCenter = currentAccount?.company?.mapCenter?.split(",")?.map((cord) => Number(cord)) as [
		number,
		number
	];

	const defaultValues = JSON.parse(decodeURIComponent(preCreateForm));

	const navigationY = [
		{
			id: "edit",
			label: <Edit />,
			changeTab: () => {},
			height: 90,
			disabled: true,
		},
	];

	const { onMapClick } = useMapAdjust({ isEditOrCreate: true });

	return (
		<XYNavLayout
			leftBlock={
				<RealEstateMap
					onMapClick={onMapClick}
					mark={dontMarkOnMap ? undefined : coord}
					options={{
						center: coord.lat && coord.lon ? [coord.lat as number, coord.lon as number] : mapCenter,
						zoom: mapZoom ?? 16,
						updateCenterIfChanged: true,
						withoutHandlers: true,
					}}
				/>
			}
			yNavigation={navigationY}
			yNavigationTop={60}
			activeYTab={0}
			withGoBack={{ defaultBackPath: `${paths.objectsPath.list}` }}
			navVariant="grey"
		>
			<RealEstateCreate defaultValues={defaultValues} />
		</XYNavLayout>
	);
};
