import { useDispatch } from "react-redux";

import { toastSuccess } from "../../../components/UI";
import { useFinishApplication } from "../../../service/api/mutations";
import { closeDrawer } from "../../../service/redux/modules/drawer/drawer.action";
import { useQueryParams } from "../index";

type Props = {
	applicationId: string;
	onSuccess?: () => void;
};

export const useHandleFinishApplication = ({ applicationId, onSuccess }: Props) => {
	const dispatch = useDispatch();

	const { removeQueryParam } = useQueryParams<{ id: string; client: string }>([
		{ name: "id" },
		{ name: "client" },
	]);

	const { mutate, isLoading } = useFinishApplication({
		onSuccess: (_, { action }) => {
			let text;

			switch (action) {
				case "abort": {
					text = "Заявка прервана";
					break;
				}
				case "toPentUpDemand": {
					text = "Заявка отправлена в отложенный спрос";
					break;
				}
				default: {
					text = "Заявка завершена успешно";
				}
			}

			toastSuccess({ text });

			removeQueryParam(["id", "client"]);
			onSuccess?.();
			dispatch(closeDrawer());
		},
	});

	const handleAbortApplication = () => {
		mutate({ applicationId, action: "abort" });
	};

	const handlePentUpDemandApplication = () => {
		mutate({ applicationId, action: "toPentUpDemand" });
	};

	const handleSuccessApplication = () => {
		mutate({ applicationId, action: "finishSuccess" });
	};

	return {
		handleAbortApplication,
		handlePentUpDemandApplication,
		handleSuccessApplication,
		isLoading,
	};
};
