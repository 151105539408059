import { useRouteMatch } from "react-router";
import { H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { CardObjectPublic, PublicLayout } from "../../../../UI";
import { AdTable, ApplicationBanner, AveragePriceBanner, ObjectShowsAndViews } from "../../components";
import { useLoadApplicationPublic } from "../../../../../service/api/queries";

import styles from "./index.module.scss";

export const ApplicationPublic = (): JSX.Element | null => {
	const { params } = useRouteMatch<{ id: string }>();
	const { application } = useLoadApplicationPublic({ id: params.id });

	if (!application) {
		return null;
	}

	return (
		<PublicLayout classNames={{ content: styles.root }}>
			<ApplicationBanner application={application} />

			<div>
				<H2>
					{application.type.whoIsClient === "seller" ? "Ваш объект на продажу" : "Ваш объект на покупку"}
				</H2>

				<div>
					<CardObjectPublic variant="horizontal" clientObject={application.clientObject} />

					<AveragePriceBanner averagePrice={8760000 - 105600} price={8760000} />
				</div>
			</div>

			<div>
				<H2>
					{application.type.whoIsClient === "seller" ? "Ваши показы объектов" : "Ваши просмотры объектов"}
				</H2>

				<ObjectShowsAndViews type="views" />
			</div>

			<div>
				<H2>Ваш объект рекламируют на 26 площадках</H2>

				<AdTable />
			</div>
		</PublicLayout>
	);
};
