/* eslint-disable sonarjs/cognitive-complexity */
import { Conditions, TrafficFilter } from "../../../../types";

export const DEFAULT_TRAFFIC_FILTERS: any = {
	callType: [],
	contactId: "",
	dateFrom: "",
	dateTo: "",
	messageSource: [],
	responsibleUsers: [],
	from: ["call", "message"],
};

export const getTrafficFilters = (search: TrafficFilter): Conditions => {
	const searchConditions: Conditions = [];

	if (search?.from) {
		searchConditions.push({
			field: "from",
			operator: "in",
			value: search.from,
		});
	}

	if (search?.callType) {
		const type: string[] = [];
		const status: string[] = [];

		if (search?.callType.includes("in")) {
			type.push("in");
		}

		if (search?.callType.includes("out")) {
			type.push("out");
		}

		if (search?.callType.includes("missed")) {
			status.push("missed");
		}

		if (search?.callType.includes("busy")) {
			status.push("busy");
		}

		if (type?.length > 0) {
			searchConditions.push({
				field: "callData.type",
				operator: "in",
				value: type,
			});
		}

		if (status?.length > 0) {
			searchConditions.push({
				field: "callData.status",
				operator: "in",
				value: status,
			});
		}
	}

	if (search?.messageSource) {
		searchConditions.push({
			field: "messageData.source",
			operator: "eq",
			value: search.messageSource,
		});
	}

	if (search?.messageSource) {
		searchConditions.push({
			field: "messageData.source",
			operator: "in",
			value: search.messageSource,
		});
	}

	if (search?.contactId) {
		searchConditions.push({
			field: "contactId",
			operator: "eq",
			value: search.contactId,
		});
	}

	if (search?.dateFrom) {
		searchConditions.push({
			field: "date",
			operator: "gte",
			value: search.dateFrom,
		});
	}

	if (search?.dateTo) {
		searchConditions.push({
			field: "date",
			operator: "lte",
			value: search?.dateTo,
		});
	}

	if (search?.status || search?.status === "") {
		searchConditions.push({
			field: "status",
			operator: "eq",
			value: search.status,
		});
	}

	return searchConditions;
};

export const getFreeTrafficFilters = (): Conditions => [
	{
		field: "status",
		operator: "isnull",
	},
];
