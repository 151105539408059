import { CreateTaskForm, FinishTaskPayload, Task, TaskTypeEnum } from "../../../../types";
import { useEffect, useState } from "react";
import styles from "./index.module.scss"
import { ReactComponent as IconDeadlineWarn } from "../../../../assets/icons/iconDeadlineWarn.svg";
import { ReactComponent as IconDeadlineInfo } from "../../../../assets/icons/iconDeadlineInfo.svg";
import { ReactComponent as IconArrow } from "../../../../assets/icons/arrow-down.svg";
import { ReactComponent as IconInfo } from "../../../../assets/icons/iconInfoSmall.svg";
import { ReactComponent as IconClose } from "../../../../assets/icons/close.svg";
import cn from "classnames";
import { CalendarInPopup, InfoRow, Select, Textarea, Time, toastError, toastSuccess } from "../../../UI";
import { formatDateWithTime } from "../../../../utils/date";
import { getTypeLabel, RenderCreator, RenderExecutor } from "./helpers";
import { CardLead } from "../../../UI/Card/card-lead/CardLead";
import { CardApplication } from "../../../UI/Card/card-application";
import { useForm, useWatch } from "react-hook-form";
import { remindOptions } from "../../../../utils/constants";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { useFinishSuccessTask } from "../../../../service/api/mutations/tasks/useFinishSuccessTask";
import { useRefuseTask } from "../../../../service/api/mutations/tasks/useRefuseTask";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { closeDrawerInDrawer } from "../../../../service/redux/modules/drawer/drawer.action";


type Props = {
  task: Task
  refetch: () => void
  refetchParent?: () => void
  isLast?: boolean
}

export const TaskCard = ({task, refetch, refetchParent, isLast}: Props): JSX.Element => {
  const {
    id,
    name,
    description,
    type,
    deadline,
    finishedAt,
    result,
    relatedKinds,
    executor,
    creatorUser,
    isSuccessFinished,
    status,
    creatorUserId,
    nextCommunicationDate
  } = task

  const dispatch = useDispatch()

  const isCommunication = type === TaskTypeEnum.communication
  const isDefault = type === TaskTypeEnum.default
  const isShowOrView = type === TaskTypeEnum.showOrView
  const deadlineLabel = isCommunication ? "Дата взаимодействия" : "Дата следующего взаимодействия"
  const [isDescriptionFull, setIsDescriptionFull] = useState(false)
  const [isResultFull, setIsResultFull] = useState(false)

  const {register, setValue, getValues, control, handleSubmit} = useForm();

  const diffSec = Math.round(Date.now() - new Date(deadline).getTime() / 1000);
  const min = 60;
  const hour = 60 * min;
  const day = 24 * hour;
  const expired = diffSec >= 0
  const willExpired = diffSec >= day
  const done = status === "done"
  const failed = status === "failed"

  const onSuccess = () => {
    if (refetchParent) {
      refetchParent()
    }
    refetch();
  };

  const setNumberValue = (nameField: string, value: string) =>
    setValue(nameField as keyof CreateTaskForm, Number(value));

  const {
    mutate: handleSubmitFinishSuccessTask,
  } = useFinishSuccessTask({onSuccess});
  const {mutate: handleSubmitRefuse} = useRefuseTask({onSuccess});

  function timeToMilliseconds(timeStr?: string) {
    if (timeStr === undefined) {
      return 0
    }
    if (timeStr === "0") {
      return 0
    }
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  }

  const date1 = new Date(getValues("deadlineDate")).getTime();
  const date2 = new Date(timeToMilliseconds((String(getValues("deadlineTime")) !== "" ? getValues("deadlineTime") : "0"))).getTime();
  const sumMilliseconds = date1 + (String(date2) !== "" ? date2 : 0);
  const sumDate = new Date(sumMilliseconds);

  const onRefuse = () => {
    const payload: FinishTaskPayload = {
      id,
      data: {
        comment: getValues("result"),
        isLastCommunication: isLast,
        nextCommunicationDate: isCommunication ? getValues("deadlineDate") : undefined
      }
    }
    if (String(getValues("result")).length === 0) {
      toastError({text: "Введите результат"})
    } else {
      handleSubmitRefuse(payload)
      toastSuccess({text: "Вы успешно отказались от задачи"})
      dispatch(closeModal())
    }
  }

  const onFinishSuccess = () => {
    const payload: FinishTaskPayload = {
      id,
      data: {
        comment: getValues("result"),
        isLastCommunication: isLast,
        nextCommunicationDate: isCommunication ? (sumDate) : undefined
      }
    }

    if ((String(getValues("result")).length === 0)) {
      toastError({text: "Введите результат"})
    } else if ((!isLast && isCommunication && getValues("deadlineDate") === undefined)) {
      toastError({text: "Введите дату следующего взаимодействия"})
    } else {
      handleSubmitFinishSuccessTask(payload)
      toastSuccess({text: "Вы успешно завершили задачу"})
      dispatch(closeModal())
      if (isLast) {
        dispatch(closeDrawerInDrawer())
      }
    }
  }

  const dateName = "deadlineDate";
  const currentDate = useWatch({
    name: dateName,
    control,
    defaultValue: getValues(dateName),
  });
  const timeName = "deadlineTime";
  const currentTime = useWatch({
    name: timeName,
    control,
  });

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.name}>
          {name}
          {expired && <IconDeadlineWarn/>}
          {willExpired && <IconDeadlineInfo/>}
        </div>
        {isDefault &&
          <>
            <div className={cn(styles.description, {[ styles.descriptionNotFull ]: !isDescriptionFull})}>
              {description}
            </div>
            {description && description.length >= 400 &&
              <div className={styles.seeMore} onClick={() => setIsDescriptionFull(!isDescriptionFull)}>
                Смотреть еще
                <IconArrow/>
              </div>}
          </>
        }
        <div className={styles.info}>
          <InfoRow classNames={{root: styles.infoRow}} label="Тип">
            <span>
              {getTypeLabel(type, relatedKinds?.application?.shortType.whoIsClient)}
            </span>
          </InfoRow>
          <InfoRow classNames={{root: styles.infoRow}} label={deadlineLabel}>
            <span>
              {formatDateWithTime(deadline)}
            </span>
          </InfoRow>
          {isSuccessFinished && finishedAt &&
            <InfoRow classNames={{root: styles.infoRow}} label="Дата выполнения">
            <span>
              {formatDateWithTime(finishedAt)}
            </span>
            </InfoRow>
          }
          <InfoRow classNames={{root: styles.infoRow}} label="Исполнитель">
            <span>
              <RenderExecutor executor={executor}/>
            </span>
          </InfoRow>
          {!isCommunication &&
            <InfoRow classNames={{root: styles.infoRow}} label="Создатель">
            <span>
              <RenderCreator creator={creatorUser} creatorId={creatorUserId}/>
            </span>
            </InfoRow>
          }
          {isCommunication && isSuccessFinished &&
            <InfoRow classNames={{root: styles.infoRow}} label="Дата следующего взаимодействия">
            <span>
               {formatDateWithTime(nextCommunicationDate ?? new Date())}
            </span>
            </InfoRow>
          }
          {isCommunication && !isSuccessFinished && !isLast &&
            <InfoRow
              classNames={{
                root: cn(styles.infoRow, styles.calendarChild),
                content: styles.flexRow,
              }}
              labelFont="ubuntu"
              label="Дата следующего взаимодействия"
            >
              <CalendarInPopup
                form={{name: dateName, register, setValue, getValues, required: true}}
                defaultDate={currentDate}
                classNames={{
                  wrapper: styles.calendar,
                  label: styles.calendarLabel,
                  trigger: styles.popupTrigger,
                }}
              />
              <Time
                classNames={{
                  root: styles.timeRoot,
                  wrapper: styles.timeWrapper,
                }}
                variant="noLabel"
                name={timeName}
                register={register}
                value={currentTime}
                onChange={(value) => {
                  if (getValues("deadlineTime") !== value) {
                    setValue("deadlineTime", value)
                  }
                }}
              />
            </InfoRow>
          }
          {status === "inWork" &&
            <form>
              <InfoRow classNames={{root: styles.infoRow}} labelFont="ubuntu" label="Напомнить за">
                <Select
                  placeholder="Выбрать"
                  name="remindFor"
                  register={register}
                  setValue={setNumberValue}
                  options={remindOptions}
                  defaultValue="3600"
                  required
                />
              </InfoRow>
            </form>
          }
        </div>
        <div>
          {(relatedKinds?.lead || relatedKinds?.application) &&
            <div className={styles.title}>
              Связь
            </div>
          }
          <div>
            {relatedKinds?.lead &&
              <div className={styles.lead}>
                <CardLead
                  lead={relatedKinds.lead}
                  background="gray"
                />
              </div>
            }
            {relatedKinds?.application &&
              <div className={styles.application}>
                <CardApplication
                  application={relatedKinds.application}
                  withStage
                  inCard
                  canOpenANewTab
                />
              </div>
            }
          </div>
        </div>
        {(status !== "inWork") && !isShowOrView &&
          <div>
            <div className={styles.title}>
              Результат
            </div>
            <div className={cn(styles.description, {[ styles.descriptionNotFull ]: !isResultFull})}>
              {result}
            </div>
            {result && result?.length >= 400 &&
              <div className={styles.seeMore} onClick={() => setIsResultFull(!isResultFull)}>
                Смотреть еще
                <IconArrow/>
              </div>
            }
          </div>
        }
        {(status === "inWork") && !isShowOrView &&
          <div>
            <div className={styles.title}>
              Результат
            </div>
            <form>
              <Textarea
                name="result"
                className={styles.textarea}
                required
                placeholder="Введите результат."
                register={register}
                rows={4}
              />
            </form>
          </div>
        }
        {isShowOrView &&
          <div className={styles.linkBlock}>
            <div className={styles.item}>
              <div className={styles.linkLabel}>
                {relatedKinds?.show && "ID показа"}
                {relatedKinds?.view && "ID просмотра"}
              </div>
              <div className={styles.link}>
                {relatedKinds?.show && relatedKinds.show.shortId}
                {relatedKinds?.view && relatedKinds.view.shortId}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.linkLabel}>
                {relatedKinds?.application && "ID заявки"}
              </div>
              <div className={styles.link}>
                {relatedKinds?.application && relatedKinds.application.shortId}
              </div>
            </div>
          </div>
        }
        <div className={styles.rowAction}>
          {isCommunication && !done &&
            <div className={styles.footerDefault}>
              <Button onClick={onFinishSuccess}>
                Завершить
              </Button>
            </div>
          }
          {isShowOrView &&
            <div className={styles.footerShow}>
              <IconInfo/>
              <div>
                Задача завершится автоматически, когда вы завершите просмотр
              </div>
            </div>
          }
          {isDefault && !done && !failed &&
            <div className={styles.footerDefault}>
              <div className={styles.refuse}>
                <IconClose/>
                <div onClick={onRefuse}>
                  Отказаться
                </div>
              </div>
              <Button onClick={onFinishSuccess}>
                Завершить успешно
              </Button>
            </div>
          }
        </div>
      </div>
    </div>
  )
}