import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { toastError, toastSuccess } from "../../../../../../../../../../../../UI";
import { useAddAccessToUser } from "../../../../../../../../../../../../../service/api/mutations";
import { closeDrawer } from "../../../../../../../../../../../../../service/redux/modules/drawer/drawer.action";

type Props = {
	contactId: string;
};

export const useAddToMyContacts = ({ contactId }: Props) => {
	const { push } = useHistory();
	const dispatch = useDispatch();

	const onError = () => {
		const text = "Не удалось добавить в Мои Контакты";
		toastError({ text });
	};

	const onSuccess = () => {
		dispatch(closeDrawer());
		const text = "Контакт добавлен в Мои Контакты";
		push(`contacts?id=${contactId}`);
		toastSuccess({ text });
	};

	const { mutate, isLoading } = useAddAccessToUser({ onError, onSuccess });

	const handleMutate = () => {
		mutate(contactId);
	};

	return {
		handleMutate,
		isLoading,
	};
};
