import { useState } from "react";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { GalleryGrid } from "../GalleryGrid/GalleryGrid";
import { DroppableZone, PhotosPlug, WatermarkSwitcher } from "./components";
import { useObjectAddPhoto, useObjectDeletePhoto, useObjectUpdatePhoto, } from "../../../service/api/mutations";
import { useGrabScrollX, useLoadPhotos } from "../../../utils/hooks";
import { RealEstate, RealEstateAddPhotoPayload, RealEstatePhoto } from "../../../types";

import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { ReactComponent as BuildingsIcon } from "../../../assets/icons/buildings.svg";
import styles from "./index.module.scss";

type Props = {
  realEstate: Pick<RealEstate, "id" | "photos"> & Partial<Exclude<RealEstate, "id" | "photos">>;
  refetch?: () => void;
  alwaysOpen?: boolean;
  isFromParser?: boolean;
};

export const ObjectPhotoGallery = ({realEstate, refetch, alwaysOpen, isFromParser}: Props): JSX.Element => {
  const {loadPhotos} = useLoadPhotos();

  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<(File & { preview: string; photoType?: "photo" | "plan" })[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {photos, applicationId} = realEstate;

  const {galleryMouseDown, galleryMouseUp, galleryMouseMove, isGrabbing} = useGrabScrollX();

  const {mutate} = useObjectAddPhoto({
    onSuccess: () => {
      if (refetch) {
        refetch();
      }
    },
  });

  const deletePhoto = useObjectDeletePhoto({});
  const updatePhotos = useObjectUpdatePhoto({});

  const onLoadPhotos = (payload: RealEstateAddPhotoPayload) => {
    const lastOrder = photos.length ? photos[ photos.length - 1 ].order + 1 : 1;

    for (let i = 0; i < payload.data.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      payload.data[ i ].order = lastOrder + payload.data[ i ].order;
    }

    mutate(payload);
  };

  const onSortPhotos = (photosToSort: RealEstatePhoto[]) => {
    const payload = photosToSort.map((photo, index) => ({
      id: photo.id,
      order: index + 1,
      description: photo.description,
    }));

    updatePhotos.mutate({
      applicationId: applicationId ?? "",
      data: payload,
    });
  };

  const onDeletePhoto = async (photoId: string): Promise<boolean> => {
    let success = false;

    try {
      await deletePhoto.mutateAsync(photoId);
      success = true;

      return success;
    } catch (error) {
      console.log(error);
    }

    return false;
  };

  const onLoadImgs = async () => {
    setIsLoading(true);

    const curPhotos = await loadPhotos(files);

    onLoadPhotos({
      applicationId: applicationId ?? "",
      data: curPhotos,
    });
    setFiles([]);
    setIsLoading(false);
  };

  const thumbs = files.map((file) => (
    <div className={styles.thumb} key={file.name}>
      <div className={styles.thumbInner}>
        <img src={file.preview} className={styles.img} alt=""/>
      </div>
    </div>
  ));

  const shouldRenderWatermarkSwitcher = !isFromParser && realEstate?.applicationId && thumbs.length === 0;

  return (
    <div className={styles.gallery}>
      {isOpen || alwaysOpen ? (
        <div className={styles.galleryOpened}>
          <GalleryGrid
            photos={photos}
            onDeletePhoto={onDeletePhoto}
            onSortPhotos={onSortPhotos}
            isFromParser={isFromParser}
            withWatermark={realEstate?.additionalProperties?.watermarkEnabled as boolean}
          >
            {!isFromParser && <DroppableZone files={files} setFiles={setFiles}/>}
          </GalleryGrid>

          {thumbs.length ? (
            <aside className={styles.newImages}>
              <div className={styles.thumbsContainer}>{thumbs}</div>

              {thumbs.length && (
                <div className={styles.buttonContainer}>
                  <Button onClick={onLoadImgs} isLoading={isLoading}>
                    Сохранить изображения
                  </Button>
                </div>
              )}
            </aside>
          ) : null}

          {shouldRenderWatermarkSwitcher && (
            <WatermarkSwitcher
              applicationId={realEstate.applicationId as string} // Typescript bug, it's definitely string
              switcherValue={realEstate?.additionalProperties?.watermarkEnabled as boolean}
              refetch={refetch}
            />
          )}
        </div>
      ) : (
        <div
          onMouseDown={galleryMouseDown}
          onMouseUp={galleryMouseUp}
          onMouseMove={galleryMouseMove}
          className={cn(styles.galleryClosed__scroll, {
            [ styles.grabbing ]: isGrabbing,
          })}
        >
          {photos?.map((photo) => (
            <img
              key={photo.photoOriginal}
              src={photo.photoOriginal}
              alt="img"
              className={styles.galleryClosed__img}
            />
          ))}
          {photos && photos.length === 0 &&
            <PhotosPlug/>
          }
        </div>
      )}
      {!alwaysOpen && (
        <button
          onClick={() => setIsOpen((prev) => !prev)}
          type="button"
          className={cn(styles.toggle, {
            [ styles.toggleReverse ]: isOpen,
            [styles.toggleNoPhoto] : photos.length === 0,
          })}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            isOpen ? "Свернуть " : photos.length ? "Все фото" : "Добавить"
          }{" "}
          <Arrow/>
        </button>
      )}
    </div>
  );
};
