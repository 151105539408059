import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthLayout, RegistrationAccessController } from "../components";
import { cleanUp, Steps } from "../../../../service/redux/modules/registration-steps/reg-steps.action";
import { selectRegisterSteps } from "../../../../service/redux/selectors";
import { stepToComponent } from "./helpers";

export const RegistrationPageV2 = (): JSX.Element => {
	const { currentStep } = useSelector(selectRegisterSteps);

	const dispatch = useDispatch();

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			dispatch(cleanUp());
		};
	}, []);

	const renderStepIfNeeded = (step: Steps) => currentStep === step && stepToComponent(step);

	return (
		<RegistrationAccessController
			forUnauthorized={
				<AuthLayout>
					{renderStepIfNeeded("first-step")}
					{renderStepIfNeeded("personal-data")}
					{renderStepIfNeeded("sms-code")}
					{renderStepIfNeeded("choose-role")}
					{renderStepIfNeeded("has-active-invites")}
					{renderStepIfNeeded("done-auth-accession")}
					{renderStepIfNeeded("finish-registration")}
				</AuthLayout>
			}
			forAuthorized={
				<AuthLayout>
					{renderStepIfNeeded("done-single-realtor")}
					{renderStepIfNeeded("done-new-company")}
					{renderStepIfNeeded("done-invitation")}
				</AuthLayout>
			}
		/>
	);
};
