import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";

type Props = {
	currentBlocks: any[] | undefined;
};

type Return = {
	scrollY: number;
	onScroll: DebouncedState<(event: any) => void>;
};

type UseCardScroll = (props: Props) => Return;

export const useCardScroll: UseCardScroll = ({ currentBlocks }) => {
	const [isScrollBottom, setIsScrollBottom] = useState(!currentBlocks);

	useEffect(() => {
		setIsScrollBottom(!currentBlocks);
	}, [currentBlocks]);

	const [scrollY, setScrollY] = useState(0);

	const checkScroll = (event) => {
		if (!isScrollBottom) {
			const {
				target: { scrollTop, scrollHeight, clientHeight },
			} = event;
			if (scrollHeight <= scrollTop + clientHeight + 250) {
				setIsScrollBottom(true);
				setScrollY(scrollHeight);
			} else {
				setScrollY(scrollTop);
			}
		}
	};

	const onScroll = useDebouncedCallback(checkScroll, 50);

	return {
		scrollY,
		onScroll,
	};
};
