import { useMemo } from "react";

import { Select } from "../../../../../../../../index";

import { FormPropsEditRealEstate, RealEstateSchemaConfigField } from "../../../../../../../../../../types";
import { getDefaultForSelect, getSelectOptions } from "../../../../../helpers";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const JustSelect = ({ form, field }: Props): JSX.Element => {
	const {
		required,
		dataType: { name },
	} = field;
	const { setValue, register, getValues } = form;

	const fieldName = name!;

	const defaultValue = useMemo(
		() => getDefaultForSelect({ fieldName, getValues }) as string,
		[fieldName, getValues]
	);

	return (
		<Select
			register={register}
			setValue={setValue}
			name={fieldName}
			placeholder="Выбрать"
			defaultValue={defaultValue}
			options={getSelectOptions(field)}
			required={required}
		/>
	);
};
