import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RealEstateFilter, RealEstate } from "../../../../../types";

import { RealEstateMap } from "../../../../UI";
import { setObjectsGroupIds } from "../../../../../service/redux/modules/object-list/object-list.action";
import { useSelect, selectCurrentAccount } from "../../../../../service/redux/selectors";
import { findCenterOfCoordinates } from "../../../../../utils/coordinates";
import { formatPhone } from "../../../../../utils/formatters";
import { useLoadCluster } from "./helpers";
import { PolygonGeometry } from "react-yandex-maps";

type Props = {
	realEstatesList: RealEstate[];
	isLoading: boolean;
	filter: RealEstateFilter;
	setFilter: (value) => void
	isParser?: boolean;
};

export const ObjectsMap = ({
	realEstatesList,
	isLoading,
	filter,
	setFilter,
	isParser,
}: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { handleMapChange, pointsToMap, clustersToMap } = useLoadCluster({
		isParser,
		filter,
	});
	const [centerXY, setCenterXY] = useState<[number | typeof NaN, number | typeof NaN]>([NaN, NaN]);
	const { currentAccount } = useSelect(selectCurrentAccount);

	const mapZoom = Number(currentAccount?.company?.mapZoom);
	const mapCenter = currentAccount?.company?.mapCenter?.split(",")?.map((cord) => Number(cord)) as [
		number,
		number
	];

	useEffect(() => {
		if (!isLoading) {
			const coordinatesXY = findCenterOfCoordinates(
				realEstatesList?.map((object) => [
					object.location?.coordinates[0] as number,
					object.location?.coordinates[1] as number,
				])
			);

			setCenterXY(coordinatesXY);
		}
	}, [realEstatesList.length, isLoading]);

	// const points = objectListToPoints(realEstatesList);

	const getDefaultCenter = (initialCenter?: [number, number]): [number, number] => {
		if (!initialCenter || !initialCenter[0] || !initialCenter[1]) {
			return [59.94506601286178, 30.31705281156423];
		}

		return [initialCenter[0], initialCenter[1]];
	};

	const fetchPhoneNumber = (id: string): Promise<string> =>
		new Promise((resolve) => {
			setTimeout(() => {
				const objectToPreview = realEstatesList.find((object) => object.id === id) as RealEstate;
				const phone = formatPhone(objectToPreview?.responsibleUser?.account?.phone || "");

				resolve(phone);
			}, 500);
		});

	const onDrawPolygon = (polygon: number[][]) => {
		const newPolygons = filter?.polygon?.length ? [...filter.polygon] : [];
		newPolygons?.push([polygon]);

		setFilter({
			...filter,
			polygon: newPolygons,
		});
	};

	const onRemovePolygon = () => {
		const withNoPolygon = { ...filter };
		withNoPolygon.polygon = [];
		setFilter({ ...withNoPolygon });
	};

	useEffect(() => {}, [isParser]);

	const defaultPolygons: PolygonGeometry[] = filter?.polygon?.length ? [...filter.polygon] : []

	return (
		<RealEstateMap
			defaultPolygons={defaultPolygons}
			points={pointsToMap}
			clusters={clustersToMap}
			selectPointWithObjects={(point) =>
				dispatch(setObjectsGroupIds(point.objects.map((object) => object.id)))
			}
			getContactPhone={(id) => fetchPhoneNumber(id)}
			onDraw={onDrawPolygon}
			onRemovePolygon={onRemovePolygon}
			onMapChange={handleMapChange}
			options={{
				order: "map-first",
				center: mapCenter ?? getDefaultCenter(centerXY),
				zoom: mapZoom,
				updateCenterIfChanged: true,
			}}
			isParser={isParser}
		/>
	);
};
