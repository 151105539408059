
import { Comment } from "./comment";

import styles from "./index.module.scss";

type Props = {
	id: string;
};

export const Influence = ({ id }: Props): JSX.Element => (
	<div className={styles.container} id={id}>
		<h2>Влияй</h2>
		<Comment />
	</div>
);
