export const SET_CURRENT_STEP = "sdelka/reset-password-steps/SET_CURRENT_STEP";
export const SET_VERIFICATION_CODE = "sdelka/reset-password-steps/SET_VERIFICATION_CODE";
export const SET_PHONE = "sdelka/reset-password-steps/SET_PHONE";

export const CLEAN_UP = "sdelka/reset-password-steps/CLEAN_UP";

export const SET_JWT_TOKEN = "sdelka/reset-password-steps/SET_JWT_TOKEN";

export type Steps = "phone-verification" | "sms-code" | "reset-password";

export type ResetPassStepsAction =
	| { type: typeof SET_CURRENT_STEP; step: Steps }
	| { type: typeof SET_VERIFICATION_CODE; code: string }
	| { type: typeof SET_PHONE; phone: string }
	| { type: typeof CLEAN_UP }
	| { type: typeof SET_JWT_TOKEN; token: string };

export const setStep = (step: Steps): ResetPassStepsAction => ({
	type: SET_CURRENT_STEP,
	step,
});

export const setVerificationCode = (code: string): ResetPassStepsAction => ({
	type: SET_VERIFICATION_CODE,
	code,
});

export const setPhone = (phone: string): ResetPassStepsAction => ({
	type: SET_PHONE,
	phone,
});

export const cleanUp = (): ResetPassStepsAction => ({
	type: CLEAN_UP,
});

export const setJwtToken = (token: string): ResetPassStepsAction => ({
	type: SET_JWT_TOKEN,
	token,
});
