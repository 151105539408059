import { useDispatch } from "react-redux";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { useAddResponsibleModal } from "../../index";
import { UserList } from "../UserList";
import { useUpdateApplication } from "../../../../service/api/mutations";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { User, StageConditions, UserListItem, KindType } from "../../../../types";

import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";

type Props = {
	scrollY?: number;
	applicationId?: string;
	stageConditions?: StageConditions;
	responsibleUser?: User;
	refetch: () => void;
	kind?: KindType
};

export const Responsibles = ({
	applicationId = "",
	scrollY,
	stageConditions,
	responsibleUser,
	refetch,
	kind
}: Props): JSX.Element => {
	const users = responsibleUser ? [responsibleUser] : [];

	const dispatch = useDispatch();

	const { mutate: mutateUpdateApplication } = useUpdateApplication({
		onSuccess: () => {
			refetch();
			dispatch(closeModal());
		},
		kind
	});

	const handleChangeResponsible = (id: string | null) => {
		mutateUpdateApplication({
			applicationId,
			applicationData: { responsibleUserId: id },
		});
	};

	const handleDeleteResponsible = () => {
		handleChangeResponsible(null);
	};

	const disabled = stageConditions ? stageConditions.isLast : false;

	const { openAddResponsibleModal } = useAddResponsibleModal({
		title: "Выбрать ответственного",
		buttonLabel: "Добавить",
		onPin: ({ responsiblesIds: selectedResponsibles }) => handleChangeResponsible(selectedResponsibles[0]),
		checkedResponsibles: users?.filter((user) => user.id ?? false).map((user) => user.id as string),
		isPinOne: true,
	});

	const userList: UserListItem[] = users.map((respUser) => {
		const {
			account: { name, lastName, secondName, phone, avatar },
			position,
			id,
			type,
		} = respUser;

		return {
			id,
			avatar,
			name: name,
			lastName,
			secondName,
			position,
			type,
			phone,
		};
	});

	return (
		<UserList
			title="Ответственный"
			applicationId={applicationId}
			users={userList}
			refetch={refetch}
			handleDelete={handleDeleteResponsible}
			scrollY={scrollY}
			isSuccess={false}
			stageConditions={stageConditions}
		>
			{(stageConditions ? !stageConditions.isNext : true) && (
				<Button Icon={PlusIcon} variant="text" disabled={disabled} onClick={openAddResponsibleModal}>
					Добавить ответственного
				</Button>
			)}
		</UserList>
	);
};
