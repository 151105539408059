import type { Company, Department, MinMax, Office, PageInfo, User } from "../index";
import { PaymentMethods } from "../index";

export type StatisticsBody = {
  defaultAdSum: number;
  extraServicesSum: number;
  totalSum: number;
  defaultPlacements: number;
  extraServicesPlacements: number;
  views: number;
  viewPrice: number;
  contactOpens: number;
  contactOpenPrice: number;
  leads: number;
  leadPrice: number;
};

export type Statistics = StatisticsBody & {
  totalFeeds: number;
  postedFeeds: number;
  waitingFeeds: number;
  errors: number;
};

export type AdError = {
  code: string;
  message: string;
  action: AdErrorAction[];
};

/*
  "callToSupport" - просто надпись
  "openPlatformSite" - кнопка "перейти в личный кабинет площадки", которая откроет урл в новой вкладке
  "openObjectCard" - открывает карточку объекта и подсвечивает поля
  "openObjectAdDescription" - открывает рекламное описание площадки (если заполнено) или общее рекламное описание объекта
  "changeAdId" - изменяет id объекта
*/
export type AdErrorActionType =
  | "openPlatformSite"
  | "openObjectCard"
  | "openObjectAdDescription"
  | "changeAdId"
  | "callToSupport";

export type AdErrorAction = {
  type: AdErrorActionType;
  url?: string; // Урл для открытия дял типа "openPlatformSite"
  highlightProps?: string[]; // массив с name'ми пропсов, которые нужно подсветить в карточке объекта после открытия
};

export enum SubStatusesEnum {
  ON_MODERATION = "OnModeration",
  STOPPING = "Stopping",
}

export enum UpdateStatusesEnum {
  ON_MODERATION = "OnModeration",
  ERROR = "Error",
}

export type PlatformAdStatusValues = {
  startedAt?: Date;
  endDate?: Date;
  status: AdStatusesEnumV2;
  subStatus: SubStatusesEnum;
  gotErrorAt?: Date;
  finishDate?: Date;
  updateOnPlatformAt?: Date;
  payMethod?: PaymentMethods;
  period?: number;
  updateStatus: {
    status: UpdateStatusesEnum;
    errorDate: Date;
    errors: ErrorsAndWarningsType[];
    warinigs: ErrorsAndWarningsType[];
  };
  complaintAutoReject: boolean;
  previousStatus: AdStatusesEnumV2;
  errors?: ErrorsAndWarningsType[];
};

export interface ErrorsAndWarnings {
  errors: ErrorsAndWarningsType[];
  warinigs: ErrorsAndWarningsType[];
}

export type PlatformAdStatus = PlatformAdStatusValues & {
  id: string;
  link: null | string;
  autoRenewal: null | boolean;
  reason: null | Array<AdError>;
  reviewStatus: string;
  reviewReason: null | string;
  externalId: string;
  advertisingCost: null | string;
  statistic: StatisticsBody;
  cianTariff: boolean | CianTariff;
  feedId: string;
  payMethod?: string;
  tariff?: string;
  period?: number;
} & ErrorsAndWarnings;

export type PlatformSupportData = {
  telNum: string;
  profileNum?: string;
  addNum?;
  manager: {
    img?: string;
    firstName: string;
    lastName: string;
    patronym: string;
  };
};

export type Limits = {
  tariff: null | string;
  limit: number;
  renewday: number;
  remainder: number;
};

export type Applied = {
  startDate: Date;
  endDate: Date;
};

export type CianTariff = "premium" | "top" | "standart";

export enum CianTariffNames {
  "standart" = 1,
  "premium" = 2,
  "top" = 3,
}

export type PlatformCianData = {
  auctionValue: string;
  myPosition: number;
  myPage: number;
  premiumPrice: number;
  topPrice: number;
  cianTariff: CianTariff;
};

export type CianData = {
  feedAdId: string;
  cianAuction: number;
  cianTariff: CianTariff;
};

export type UnfilledRequiredFields = { label: string; name: string; generalName: string };

export type RequiredFieldsType = {
  unfilledrequiredFields: UnfilledRequiredFields[];
  allrequiredFieldsCount: number;
  filledrequiredFieldsCount: number;
  unfilledrequiredFieldsCount: number;
  valid: boolean;
};

export type PlatformData = {
  id: string;
  name: string;
  link: string;
  active: boolean;
  icon: null | string;
  format: string;
  supportPhone: number;
  companyId: string;
  userId: null;
  limits: boolean | Limits;
  requiredFields: RequiredFieldsType;
  supportData: PlatformSupportData;
  adStatus: PlatformAdStatus;
};

export type Platform = PageInfo & {
  data: PlatformData[];
};

export type PlatformAdDescription = {
  price: string;
  pricePerUnit: string;
  adTitle: string;
  adDescription: string;
  adDescriptionSignatureId: string;
  unit: string;
  square: string;
  icon: string;
};

export type UpdatePlatformPaidService = {
  feedAdId: string;
  tariff: string;
  status: "apply" | "delete";
};

export type PlatformDescriptionIdData = {
  applicationId: string;
  feedId: string;
};

export type PlatformPaidServicesData = PlatformDescriptionIdData & {
  feedAdId: string;
};

export type GetFeedSeparatedPayload = {
  applicationId: string;
  feedId: string;
};

export type PlatformsTableAll = {
  applicationId: string;
  tableName: "freeTable" | "paidTable";
  action: "stop" | "start";
};

export type PaidServicesType = {
  name: string;
  label: string;
  tooltip: string;
  limits: boolean | Limits;
  applied: boolean | Applied;
  cianData?: PlatformCianData;
  price: number;
};

export type FeedPaidData = {
  tariff: string;
  status: string;
};

export type SuccessResponse = {
  success: boolean;
  errorCode?: string;
};

export type FeedPaidResponse = {
  tariff: string;
  startDate: Date;
  endDate: Date;
  companyId: string;
  userId: string;
  feedAdId: string;
  id: string;
};

export type PercentRecommendationsItem = {
  id: string;
  label: string;
  type?: "wait" | "info";
  percent: number;
  children: null | {
    border: MinMax;
    labels: { label: string; color: string }[];
    median: MinMax;
    medianPrice: number;
    price: number;
  };
};

export type PercentCardList = {
  percent: number;
  recommendationsList: PercentRecommendationsItem[];
};

export enum AdStatusEnum {
  // back compatibility
  /** @deprecated */
  Finished = "Закончилась",
  /** @deprecated */
  Pending = "Ожидает размещения",
  /** @deprecated */
  Released = "Передан на площадку",
  /** @deprecated */
  Placed = "Размещен",
  /** @deprecated */
  Archive = "Архивная",

  // actual
  NotPublished = "NotPublished",
  NotRequested = "NotRequested",
  PassedToPlatform = "PassedToPlatform",
  Published = "Published",
  Removed = "Removed",
  Error = "Error",
}

export type FeedAd = {
  feedName: string
  id: string
  status: 'NotRequested' | 'Removed' | 'Published' | 'Error'
  errorText?: string
}

export const enum AdStatusesEnumV2 {
  NOT_POSTED = "NotPosted",
  WAITING = "Waiting",
  ERROR = "Error",
  POSTED = "Posted",
  ARCHIVE = "Archive",
}

export interface ErrorsAndWarningsType {
  createdAt: Date;
  text: string;
  recommendation: string;
}

export enum FeedImportanceStatusEnum {
  "important" = "important",
  "paidTable" = "paidTable",
  "freeTable" = "freeTable",
}

export type FeedsToUserSettings = {
  id: string;
  visible?: boolean | null;
  personalPhone?: string | null;
  personalName?: string | null;
  personalCompanyName?: string | null;
  needReview?: boolean;
  userId: string;
  user: User;
  feedId: string;
  feedSettings: FeedSettings;
};

export type FeedSettings = {
  id: string;
  name: string;
  active: boolean;
  icon?: string;
  format?: string;
  formatSettings?: any;
  feedImportanceStatus?: FeedImportanceStatusEnum;
  feedBackFormat?: string;
  feedBackSettings?: any;
  autoPosting: boolean;
  supportPhone?: string | null;
  commonPhone?: string | null;
  commonAgentName?: string | null;
  commonAgencyName?: string | null;
  siteAuthData?: any;
  oldCRMFeed?: string | null;
  joinWithOldFeed?: boolean;
  checkingMode?: string | null;
  usePersonalViewSettings: boolean;
  lastOpenTime?: Date | null;
  companyId?: string | null;
  company: Company;
  userId?: string | null;
  user?: User;
  offices?: Office[];
  departments?: Department[];
  FeedSettingsToUser: FeedsToUserSettings[];
  totaladvertisingCost?: number | null;
  totalAdvertisingСount?: number | null;
};

export type RealEstateUpdateAutoRenewalPayload = {
  feedAdId: string;
  autoRenewal: boolean;
};

export type RealEstateUpdateAutoRenewalResponse = {
  autoRenewal: boolean;
  feedId: string;
  success: boolean;
};

export type StopAdPayload = {
  applicationId: string;
  feedId: string;
};

export type ComplaintAutoRejectPayload = StopAdPayload & {
  isEnabled: boolean;
};

export type ChangeTariffPayload = StopAdPayload & {
  newTariffValue: CianTariffNames;
};

export enum GroupByDetalizationParams {
  WEEK = "week",
  MONTH = "month",
  NINE_MONTH = "nineMonth",
}

export enum KindDetalizationParams {
  VIEWS = "views",
  ADD_TO_FAVORITE = "addToFavorite",
  CONTACTS = "contacts",
  CALLS = "calls",
  UNIQUE_VIEWS = "uniqViews",
  UNIQUE_ADD_TO_FAVORITES = "uniqAddToFavorite",
  UNIQUE_CONTACTS = "unicContacts",
}

export type PeriodDatesType = {
  startDate: string;
  endDate: string;
};

// TODO: Это не должны быть уникальные параметры
export const KindDetalizationParamsMapper = {
  [ KindDetalizationParams.VIEWS ]: "Просмотры",
  [ KindDetalizationParams.CONTACTS ]: "Контакты",
  [ KindDetalizationParams.CALLS ]: "Звонки",
  [ KindDetalizationParams.ADD_TO_FAVORITE ]: "Избранное",
};

export const KindDetalizationUniqueParamsMapper = {
  [ KindDetalizationParams.UNIQUE_VIEWS ]: "Уникальные просмотры",
  [ KindDetalizationParams.UNIQUE_CONTACTS ]: "Уникальные показы контактов",
  [ KindDetalizationParams.CALLS ]: "Звонки",
  [ KindDetalizationParams.UNIQUE_ADD_TO_FAVORITES ]: "Уникальные добавления в избранное",
};

export type DetalizationStatsPayload = {
  applicationId: string;
  feedId: string;
  groupBy: GroupByDetalizationParams;
  kind: KindDetalizationParams;
  periodDates: PeriodDatesType;
};

export type SendObjectToFeedResponseError = {
  success: false;
  type: "notAllFields" | "alreadyPublished" | "limitOver";
  reason: string;
  result: {
    feedId: string;
  };
};

export type SendObjectToFeedResponseSuccess = {
  success: true;
  result: PlatformAdStatus;
};

export type SendObjectToFeedResponse = SendObjectToFeedResponseError | SendObjectToFeedResponseSuccess;

export type PlatformDetailsData = {
  views: number;
  addToFavorite: number;
  contacts: number;
  calls: number;
  uniqViews: number;
  uniqAddToFavorite: number;
  unicContacts: number;
};

export type ChartPointType = {
  period: string;
  value: string;
};

export type ChartData = {
  chartPoints: ChartPointType[];
  totalForPeriod: {
    addToFavorite: number;
    calls: number;
    contacts: number;
    uniqAddToFavorite: number;
    uniqViews: number;
    views: number;
    unicContacts: number;
  };
};
