const indexPath = "/";

const loginPath = "/login";

const registrationPath = {
	default: "/registration",
	linkInvite: "/registration/:inviteCode",
};

const resetPasswordPath = "/reset-password";

const dashboardPath = "/dashboard";

const enterCodePath = "/enter-code";

const employeesPath = {
	list: "/employees",
	view: "/employees/:id",
};

const objectsPath = {
	list: "/real-estate",
	createApplicationObject: "/real-estate/application/new",
	viewParser: "/real-estate/parser/:id",
	viewApplicationObject: "/real-estate/application/:id",
};

const advertisingPath = {
	list: "/advertising-cabinet",
};

const contactsPath = {
	list: "/contacts",
	view: "/contacts/:id",
};

const leadsAndDealsPath = {
	list: "/leadsanddeals",
};

const services = {
	list: "/services"
}

const tasks = {
	list: "/tasks",
	view: "/tasks/:id",
};

const publicPath = {
	application: "/application-public/:id",
	publicMain: "/public/:contactId",
	agentCompilation: "/agent-compilation",
	publicComparison: "/public/:contactId/object/:objectId",
};

export const paths = {
	indexPath,
	loginPath,
	registrationPath,
	resetPasswordPath,
	dashboardPath,
	enterCodePath,
	employeesPath,
	objectsPath,
	advertisingPath,
	contactsPath,
	leadsAndDealsPath,
	tasks,
	publicPath,
	services,
};
