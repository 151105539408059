import { Control, Controller } from "react-hook-form";
import cn from "classnames";

import styles from "./index.module.scss";

type TRadio = {
	value: string | number;
	label: string;
	disabled?: boolean;
};

type Props = {
	name: string;
	options: TRadio[];
	control: Control<any>;
	direction?: "row" | "column" | "grid";
	labelPosition?: "left" | "right";
	error?: string;
	size?: "medium" | "big";
	font?: "ubuntu" | "cera";
	handleOnChange?: (value: string | number) => void;
	classNames?: {
		groupRadioRoot?: string;
		label?: string;
	};
	settings?: {
		preventOnChange?: boolean;
	};
};

export const Radio = ({
	name,
	options,
	control,
	direction = "column",
	labelPosition = "right",
	error,
	size = "big",
	font = "ubuntu",
	handleOnChange,
	classNames,
	settings = {},
}: Props): JSX.Element => (
	<Controller
		control={control}
		name={name}
		render={({ field: { onChange, value } }) => (
			<div
				className={cn(
					styles.groupRadio,
					styles[`size__${size}`],
					styles[`groupRadio__${direction}`],
					styles[font],
					classNames?.groupRadioRoot
				)}
			>
				{options.map((option) => (
					<div
						key={option.value}
						className={cn(styles.radio, {
							[styles.disabled]: option?.disabled,
						})}
					>
						<input type="radio" value={option.value} name={name} disabled={option?.disabled} />

						<label
							onClick={() => {
								if (handleOnChange) {
									handleOnChange(option.value);
								}

								if (!settings.preventOnChange) {
									onChange(option.value);
								}
							}}
							className={cn(classNames?.label, {
								[styles.checked]: option.value === value,
								[styles.labelOnLeft]: labelPosition === "left",
								[styles.error]: error,
							})}
						>
							<div className={styles.circle}>
								<div className={styles.dot} />
							</div>

							<div className={styles.label}>{option.label}</div>
						</label>
					</div>
				))}
			</div>
		)}
	/>
);
