import { ProductTypeEnum, User, UserStatusEnum, userStatuses } from "../../../../../types";
import { useBlinkRow, useQueryParams } from "../../../../../utils/hooks";
import { useColumns } from "./columns";
import { useCallback } from "react";
import styles from "./index.module.scss";
import { Table } from "../../../../UI";
import { useOpenProfileCard } from "../../../PersonalCard/helpers";
import { ReactComponent as Cart } from "../../../../../assets/icons/Shopping_Cart.svg";
import { useAddToCart } from "../../../../../service/api/mutations";
import { useAddInCartModal } from "../../cart/helpers/addInCartModal/useAddInCartModal";


type Props = {
  usersList: User[];
  isLoading: boolean;
  refetch: () => void;
};

export const UsersTable = ({usersList, isLoading, refetch}: Props): JSX.Element => {
  const {queryParams, changeQueryParams, removeQueryParam} = useQueryParams<{
    userId: string;
  }>([{name: "userId"}]);
  const columns = useColumns(refetch);
  const {rowsToBlink, callback} = useBlinkRow();

  const {openProfileDrawer} = useOpenProfileCard({
    onClose: () => removeQueryParam(["userId"]),
    refetch: refetch,
  });

  const openUserSettingsDrawer = (accountId: string, userId: string) => {
    changeQueryParams([
      {name: "userId", newValue: userId},
    ]);
    openProfileDrawer(userId);
  };
  const {mutate} = useAddToCart({onSuccess: () => { refetch()}})

  const renderTitle: (item: string) => JSX.Element = useCallback(
    (status) => {
    const ids = usersList.filter(elem => elem.status === status).map(elem => elem.id)
      const {openAddInCartModal} = useAddInCartModal({
        ids,
        type: ProductTypeEnum.employerSubscribtion,
        title: "Выберите срок продления",
        action: mutate,

      })
      return (
        <div className={styles.row}>
          <div className={styles.title}>
            {userStatuses[ status ]}
          </div>
          {status !== UserStatusEnum.Active && status !== UserStatusEnum.Waiting &&
            <div className={styles.cart} onClick={() => openAddInCartModal()}>
              <Cart/>
              Добавить всех
            </div>}
        </div>
      )
    },
    []
  );

  const handleRowClick = (row: User) => {
    openUserSettingsDrawer(row.account.id, row.id)
  };

  return (
    <div className={styles.wrapper}>
      {usersList?.length > 0 || isLoading ? (
        <Table<User>
          idKey="id"
          data={usersList}
          columns={columns}
          groupKey="status"
          rowClass={styles.row}
          transformGroupTitle={renderTitle}
          onRowClick={handleRowClick}
          loading={{isLoading, rowsCount: 5}}
          activeRow={{
            accessor: "",
            activeId: 1,
          }}
          blinkRows={rowsToBlink}
        />
      ) : (
        <div className={styles.empty}>Похожих пользователей не найдено</div>
      )}
    </div>
  );
}