import { CardObjectExistOrNot } from "../../../../../../../../../UI";
import { CardObjectBuyElement } from "../../../../../../../../../UI";
import { scrollIntoView } from "../../../../../../../../../../utils/scroll";
import { RealEstate } from "../../../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	whoIsClient: string;
	clientObject: RealEstate;
	applicationId: string;
};
export const Header = ({ whoIsClient, clientObject, applicationId }: Props): JSX.Element => {
	const toScroll = () => scrollIntoView(whoIsClient === "seller" ? "jointObject" : "jointPodbor");

	return (
		<div className={styles.cardObjectExist}>
			{(whoIsClient === "seller" && (
				<CardObjectExistOrNot clientObject={clientObject} onAddObject={toScroll} />
			)) ||
				(clientObject ? (
					<CardObjectExistOrNot clientObject={clientObject} onAddObject={toScroll} />
				) : (
					<div>
						<CardObjectBuyElement applicationId={applicationId} />
					</div>
				))}
		</div>
	);
};
