import { ApplicationInListV2, KindType } from "../../../../../types";
import { PopupMenuItems } from "../../../Popup";
import { handleOpenApplicationNewTab, useOpenApplicationCard } from "../../../../../utils/application";
import { useCreateTaskModal } from "../../../../../utils/hooks";
import { openApplicationPublicPage } from "./openApplicationPublicPage";
import { openObjectAd } from "./openObjectAd";
import { openClientContact } from "./openClientContact";


type Props = {
  application: ApplicationInListV2;
};

type SortableMenuItem = PopupMenuItems & {
  order: number;
};

export const usePopupMenu = ({application}: Props) => {
  const {
    id,
    type: {whoIsClient},
  } = application;

  const clientId = application.contact?.id

  const hasFeedAds = application.feedAds?.length && application.feedAds?.length > 0;

  const {handleClickApplication: openApplication} = useOpenApplicationCard({});
  const {openCreateTaskModal} = useCreateTaskModal({applicationId: id, kind:  KindType.APPLICATION});

  const menu: SortableMenuItem[] = [
    {
      label: "Открыть в новой вкладке",
      action: () => handleOpenApplicationNewTab(id),
      order: 0,
    },
    {
      label: "Посмотреть показы/просмотры",
      action: () => openApplication({applicationId: id, initialTab: 1, whoIsClient}),
      order: 1,
    },
    {
      label: "Открыть на публичной странице",
      action: () => openApplicationPublicPage(id),
      order: 2,
    },
    {
      label: "Создать задачу",
      action: openCreateTaskModal,
      order: 5,
    },
  ];

  if (hasFeedAds) {
    menu.push({
      label: "Посмотреть рекламу",
      action: () => openObjectAd(id),
      order: 3,
    });
  }

  if (clientId) {
    menu.push({
      label: "Открыть страницу клиента",
      action: () => openClientContact(clientId),
      order: 4,
    });
  }

  menu.sort((left, right) => left.order - right.order);

  return {menu};
};
