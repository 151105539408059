import { TaskTypeEnum } from "../../../../../types";

export const getTypeLabel = (type: TaskTypeEnum, 	whoIsClient?: 'seller' | 'buyer') => {
  switch (type) {
    case TaskTypeEnum.communication:
      return "Взаимодействие"
    case TaskTypeEnum.default:
      return "Обычная"
    case TaskTypeEnum.showOrView:
      if (whoIsClient === "seller") {
        return "Показ"
      } 
        return "Просмотр"
    default:
      return ""
  }
}