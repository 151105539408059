import { formatPhone } from "../../../../../../../../utils/formatters";
import { Contact } from "../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	contact: Contact;
};

export const RelatedContactCard = ({ contact }: Props): JSX.Element => {
	const phone = formatPhone(contact?.phones && contact?.phones[0] ? contact?.phones[0].phone : "");

	return (
		<div className={styles.card}>
			<div className={styles.info}>
				<span className={styles.name}>{contact.name}</span>
				<span className={styles.phone}>{phone}</span>
			</div>

			<div className={styles.actions} />
		</div>
	);
};
