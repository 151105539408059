import { ReactNode } from "react";
import cn from "classnames";

import { Header, Footer } from "./components";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	classNames?: {
		root?: string;
		content?: string;
	};
};

export const PublicLayout = ({ children, classNames }: Props): JSX.Element => (
	<div className={cn(styles.root, classNames?.root)}>
		<Header />

		<div className={cn(styles.content, classNames?.content)}>{children}</div>

		<Footer />
	</div>
);
