import { Dispatch, SetStateAction } from "react";
import { TrafficWithDate } from "../../../../../../types";

import { ContactCell, CheckCell, ResponseCell, ResultCell, SourceCell } from "./cells";

import styles from "../index.module.scss";
import { CardTraffic } from "../../../../../UI/Card/card-traffic/CardTraffic";

export const useColumns = (
	checked: string[],
	setChecked: Dispatch<SetStateAction<string[]>>,
	refetchTraffics: () => void
) => {
	const onClickCheckbox = (id: string) => {
		if (!checked.includes(id)) {
			checked.push(id);
			setChecked([...checked]);
		} else {
			const index = checked.indexOf(id);
			if (index > -1) {
				checked.splice(index, 1);
				setChecked([...checked]);
			}
		}
	};

	return [
		{
			accessor: "checkbox",
			render: ({ row }: { row: TrafficWithDate }) => (
				<CardTraffic callData={row.callData} />
			),
		},
	];
};
