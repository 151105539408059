import { MaskFieldName } from "../../../../../../types";
import { Control, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";

import { useMask } from "./helpers";
import { InfoRow, TransparentInput } from "../../../../index";

type Props = {
	control: Control<any>;
	name: string;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	label: string;
	maxSymbols?: number;
	disabled?: boolean;
	mask?: MaskFieldName;
};

export const NumberTemplate = ({
	control,
	name,
	register,
	setValue,
	label,
	maxSymbols,
	disabled,
	mask,
}: Props): JSX.Element => {
	const watcherNumber = useWatch({
		control,
		name,
	});

	const { inputMask } = useMask({ mask, maxSymbols });

	return (
		<InfoRow aspect={{ label: 8, content: 7.3 }} label={label}>
			<TransparentInput
				register={register}
				name={name}
				placeholder={maxSymbols ? "0".repeat(maxSymbols) : "Не указано"}
				mask={inputMask}
				value={watcherNumber}
				variant="noPadding"
				setValueForMask={setValue}
				disabled={disabled}
			/>
		</InfoRow>
	);
};
