import { useEffect, useState } from "react";
import { RealEstateFilterBlock } from "../../../../../../../types";

import { useGetActiveNavByScroll } from "../../../../../../../utils/scroll";

const headerOffset = 50;

type UseNavbar = {
	objectsList: RealEstateFilterBlock[];
	navigation?: {
		label: string;
		action: () => void;
	}[];
	rootId: string;
};

export const useNavbar = ({ objectsList, navigation, rootId }: UseNavbar) => {
	const [activeNav, setActiveNav] = useState("");
	const [scrollTop, setScrollTop] = useState(0);

	useGetActiveNavByScroll(navigation, setScrollTop, scrollTop, setActiveNav, rootId, headerOffset + 40);

	useEffect(() => {
		if (activeNav === "" && navigation?.length) {
			setActiveNav(navigation[0]?.label || "");
		} else {
			setActiveNav("");
		}
	}, [objectsList]);

	return {
		activeNav,
		setActiveNav,
	};
};
