import { UseMutateFunction, useMutation, useQuery } from "react-query";
import { Compilation, Condition } from "../../../../types";
import { api } from "../../api";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/DEFAULT_MN";

type Props = {
  queryName?: string;
  onSuccess?: () => void;
  onError?: () => void;
  condition: Condition[]
};

type Return = {
  collections: Compilation[]
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  remove: () => void;
  mutate:  UseMutateFunction<AxiosResponse<void>, unknown, void, unknown>
  mutateLoading: boolean
  data: Compilation[]
}

type UseGetRealEstateCollections = (props: Props) => Return

export const UseGetRealEstateCollections: UseGetRealEstateCollections = (
  {
    onError,
    onSuccess,
    condition,
  }) => {

  const loadCollections = () => api.realEstate.getRealEstateCollections(condition)
  const {data, refetch, remove, isLoading, isFetching} = useQuery(
    DEFAULT_MN,
    loadCollections,
    {
      onError,
      onSuccess,
      cacheTime: 0,
    }
  )

  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: () => api.realEstate.getRealEstateCollections(condition),
    onError,
    onSuccess
  })

  return {
    mutate: mutation.mutate,
    mutateLoading: mutation.isLoading,
    data: mutation.data?.data.data,
    collections: data?.data.data,
    isLoading,
    isFetching,
    refetch,
    remove,
  }
}