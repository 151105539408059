import { useEffect, useState } from "react";
import { RealEstateFilter, ClusterZone, OnChangeArgs } from "../../../../../../types";
import { useDebouncedCallback } from "use-debounce";

import {
	useGetParsedRealEstateClusters,
	useGetApplicationRealEstateClusters,
} from "../../../../../../service/api/mutations";

type Props = {
	filter: RealEstateFilter;
	isParser?: boolean;
};

export const useLoadCluster = ({ isParser, filter }: Props) => {
	const [pointsToMap, setPointsToMap] = useState([]);
	const [clustersToMap, setClustersToMap] = useState([]);
	const [payload, setPayload] = useState<null | ClusterZone>(null);

	const getParsedRealEstateClustersMutation = useGetParsedRealEstateClusters({});
	const getApplicationRealEstateClustersMutation = useGetApplicationRealEstateClusters({});

	const { mutateAsync } = isParser
		? getParsedRealEstateClustersMutation
		: getApplicationRealEstateClustersMutation;

	const handleMutateCluster = async () => {
		if (!payload) return;

		const response = await mutateAsync({ payload, search: filter });

		setClustersToMap(response.data.clusters);

		const pointsFromResponse = response.data.points.map((point) => ({
			lat: point.lat,
			lon: point.lon,
			count: 1,
			objects: [
				{
					id: point.id,
					price: point.price,
					pricePerUnit: point.pricePerUnit,
					unit: point.unit,
					applicationId: point.applicationId,
				},
			],
		}));

		setPointsToMap(pointsFromResponse);
	};

	const handleMapChange = useDebouncedCallback(async (args: OnChangeArgs) => {
		const newPayload: {
			bounds: [[number, number], [number, number]];
			zoom: number;
		} = {
			bounds: args.newBounds,
			zoom: args.newZoom,
		};

		setPayload(newPayload);
	});

	useEffect(() => {
		handleMutateCluster();
	}, []);

	useEffect(() => {
		handleMutateCluster();
	}, [isParser, filter, payload]);

	return {
		handleMapChange,
		pointsToMap,
		clustersToMap,
	};
};
