import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import styles from "./index.module.scss";

export const Footer = (): JSX.Element => (
	<div className={styles.root}>
		{`© ${formatDate(new Date(), "year")} СRM система`}
		<span>Сделка</span>
	</div>
);
