import { useMutation } from "react-query";

import { IUserEditFormRequest, UpdateUserDataPayload } from "../../../../types";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/user/user-update-card/DEFAULT_MN";

type UseUserUpdate = {
  onSuccess?: () => void;
};

export const useUpdateUserCard = ({ onSuccess }: UseUserUpdate) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: ({ id, payload }: { id: string; payload: IUserEditFormRequest }) =>
      api.users.updateUser(id, payload),
    onSuccess,
  });

  return {
    userUpdate: mutate.mutate,
    isLoading: mutate.isLoading
  };
};
