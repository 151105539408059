import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import {
	ChartPointType,
	KindDetalizationParams,
	KindDetalizationParamsMapper,
	KindDetalizationUniqueParamsMapper,
} from "../../../../../../../../../../../types";
import styles from "./index.module.scss";
import { ApexOptions } from "apexcharts";

type Props = {
	selectedOption: KindDetalizationParams;
	chartPointsData: ChartPointType[];
	isYandexOrDomclick?: boolean;
	isRefetching: boolean;
};

const strokeColors = {
	[KindDetalizationParams.UNIQUE_VIEWS]: "#FF03A9",
	[KindDetalizationParams.UNIQUE_CONTACTS]: "#49D7BD",
	[KindDetalizationParams.CALLS]: "#FF9052",
	[KindDetalizationParams.UNIQUE_ADD_TO_FAVORITES]: "#0056BB",

	[KindDetalizationParams.VIEWS]: "#FF03A9",
	[KindDetalizationParams.CONTACTS]: "#49D7BD",
	[KindDetalizationParams.CALLS]: "#FF9052",
	[KindDetalizationParams.ADD_TO_FAVORITE]: "#0056BB",
};
export const DetalizationViewChart: React.FC<Props> = ({
	selectedOption,
	chartPointsData,
	isYandexOrDomclick,
	isRefetching,
}) => {
	const pointsData = chartPointsData;

	const strokeColor = strokeColors[selectedOption];

	const selectedOptionText = isYandexOrDomclick
		? KindDetalizationParamsMapper[selectedOption]
		: KindDetalizationUniqueParamsMapper[selectedOption];

	const renderTooltip = function ({ series, seriesIndex, dataPointIndex, w }) {
		const label = pointsData[w.globals.labels[dataPointIndex] - 1]?.period;

		return renderToString(
			<div className={styles.tooltipContainer}>
				<div className={styles.tooltipHeader}>
					<p>{label}</p>
				</div>
				<div className={styles.tooltipBody}>
					<div className={styles.tooltipMarker} style={{ backgroundColor: strokeColor }} />
					<p>{selectedOptionText}</p>
					<p>{series[seriesIndex][dataPointIndex]}</p>
				</div>
			</div>
		);
	};

	const [series, setSeries] = useState<ApexAxisChartSeries>([
		{
			name: "Desktops",
			data: pointsData.map((item) => parseInt(item.value, 10)),
		},
	]);
	const [options, setOptions] = useState<ApexOptions>({
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
			background: "#fff",
			toolbar: {
				show: false,
			},
			animations: {
				enabled: true,
				easing: "easeinout",
				speed: 400,
				animateGradually: {
					enabled: true,
					delay: 150,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350,
				},
			},
		},
		noData: {
			text: "По заданным параметрам отсутствуют данные",
			align: "center",
			verticalAlign: "middle",
			offsetX: 0,
			offsetY: 0,
			style: {
				color: "#000000",
				fontSize: "14px",
				fontFamily: "Helvetica",
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			colors: ["#FF03A9"],
		},
		grid: {
			borderColor: "rgba(204, 204, 204, 0.4)",
			strokeDashArray: 0,
			padding: {
				left: 25,
			},
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: false,
				},
			},
		},
		tooltip: {
			custom: renderTooltip,
			followCursor: true,
		},

		markers: {
			colors: ["#FF03A9"],
			strokeWidth: 0,
		},
		xaxis: {
			categories: pointsData.map((item) => item.period),
			axisTicks: {
				show: false,
			},
			tooltip: {
				enabled: false,
			},
		},
		yaxis: {
			show: false,
		},
	});

	useEffect(() => {
		setOptions((prev) => ({
			...prev,
			stroke: { ...prev.stroke, colors: [strokeColor] },
			markers: { ...prev.markers, colors: [strokeColor] },
			tooltip: {
				...prev.tooltip,
				marker: {
					fillColors: [strokeColor],
				},
				custom: renderTooltip,
			},
		}));
	}, [strokeColor]);

	const [shouldExecute, setShouldExecute] = useState(false);

	useEffect(() => {
		let timer;
		if (isRefetching) {
			timer = setTimeout(() => {
				setShouldExecute(true);
			}, 1000); // 1 second
		} else {
			clearTimeout(timer);
			setShouldExecute(false);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [isRefetching]);

	useEffect(() => {
		if (!isRefetching) {
			setSeries([
				{
					name: "Desktops",
					data: pointsData.map((item) => parseInt(item.value, 10)),
				},
			]);
			setOptions((prev) => ({
				...prev,
				xaxis: { ...prev.xaxis, categories: pointsData.map((item) => item.period) },
				tooltip: {
					...prev.tooltip,
					custom: renderTooltip,
				},
			}));
		}
	}, [pointsData, isRefetching]);

	/* useEffect(() => {
		if (!isRefetching) {
			setSeries([
				{
					name: "Desktops",
					data: pointsData.map((item) => parseInt(item.value, 10)),
				},
			]);
			setOptions((prev) => ({
				...prev,
				xaxis: { ...prev.xaxis, categories: pointsData.map((item) => item.period) },
				tooltip: {
					...prev.tooltip,
					custom: renderTooltip,
				},
			}));
		} else {
			setSeries([
				{
					name: "Desktops",
					data: [],
				},
			]);
		}
	}, [pointsData, isRefetching]); */

	return (
		<div id="chart" className={styles.chartWrapper}>
			<div className={`${styles.loadingContainer} ${shouldExecute ? styles.showLoading : ""}`}>
				<p>Загрузка графика...</p>
			</div>
			<Chart options={options} series={series} type="line" height={350} />
		</div>
	);
};
