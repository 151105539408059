import { TasksLeftFilters, TasksList, TasksMainFilters } from "./components";

import styles from "./index.module.scss";

export const TasksMain = (): JSX.Element => (
	<div className={styles.root}>
		<TasksMainFilters className={styles.mainFilters} />

		<TasksLeftFilters className={styles.leftFilters} />

		<TasksList className={styles.tasksList} />
	</div>
);
