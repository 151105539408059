import { TasksAttention, TasksMain } from "./components";

import styles from "./index.module.scss";

export const TasksContent = (): JSX.Element => (
	<div className={styles.root}>
		<TasksMain />

		<TasksAttention />
	</div>
);
