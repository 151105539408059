import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/real-estate/delete-presentation/DEFAULT_MN";

type UseDeletePresentation = {
	onSuccess?: () => void;
};

export const useDeletePresentation = ({ onSuccess }: UseDeletePresentation) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (id: string) => api.realEstate.deletePresentation(id),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
