import {
	ACTIVATE_LOADING,
	CHANGE_TITLE,
	CLOSE_MODAL,
	DEACTIVATE_LOADING,
	DELETE_TITLE,
	ModalAction,
	OPEN_MODAL,
} from "./modal.action";
import { ModalTInitialState } from "../../types";

const initialState: ModalTInitialState = {
	isOpen: false,
	variant: "default",
	title: "",
	body: "",
	classNames: {},
	onClose: () => {},
	isLoading: false,
};

export const modalReducer = (state = initialState, action: ModalAction): ModalTInitialState => {
	switch (action.type) {
		case OPEN_MODAL: {
			return {
				...state,
				isOpen: true,
				title: action.payload.title,
				body: action.payload.body,
				width: action.payload.width,
				variant: action.payload.variant,
				alertIcon: action.payload.alertIcon,
				alertButtonSubmit: action.payload.alertButtonSubmit,
				alertButtonDecline: action.payload.alertButtonDecline,
				onClose: action.payload.onClose,
				classNames: action.payload.classNames,
			};
		}

		case CLOSE_MODAL: {
			return {
				...state,
				isOpen: false,
				title: "",
				body: "",
				variant: "default",
				alertIcon: undefined,
				alertButtonSubmit: undefined,
				alertButtonDecline: undefined,
				width: undefined,
				isLoading: false,
				onClose: () => {},
				classNames: {},
			};
		}

		case ACTIVATE_LOADING: {
			return {
				...state,
				isLoading: true,
			};
		}

		case CHANGE_TITLE: {
			return {
				...state,
				title: action.payload.newTitle,
			};
		}

		case DEACTIVATE_LOADING: {
			return {
				...state,
				isLoading: false,
			};
		}

		case DELETE_TITLE: {
			return {
				...state,
				title: undefined,
			};
		}

		default:
			return state;
	}
};
