import { useMutation } from "react-query";

import { ResetPasswordCodeConfirm } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "registration/sdelkaPro/DEFAULT_MN";

type UseCodeConfirm = {
	onSuccess?: () => void;
};

export const useJoinToSdelkaPro = () => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (   ) =>
			api.auth.joinTosdelkaPro(),
	});

	return {
		data: mutation.data,
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
		mutation,
		asyncMutate: mutation.mutateAsync,
	};
};
