import { Bank, DefaultSelectProps } from "../../../../../types";

import { RemoteSelect } from "../RemoteSelect";
import { useFindJK } from "../../../../../service/api/mutations";
import { useLoadJK } from "../../../../../service/api/queries";

type Props = {
	value: string;
	onChange?: (value: string) => void;
	source: "avito" | "cian" | "yandex";
} & Omit<DefaultSelectProps, "isSearchable">;

export const JKSelect = ({
	value,
	placeholder = "Введите ЖК",
	name,
	register,
	setValue,
	onChange,
	error,
	classNames,
	prepend,
	append,
	size,
	required,
	isLoading,
	disabled,
	source,
}: Props): JSX.Element => {
	const createLabel = (bank: Bank) => bank.name;
	const objectMutate = () => useFindJK({ source });

	return (
		<RemoteSelect
			value={value}
			placeholder={placeholder}
			name={name}
			register={register}
			setValue={setValue}
			error={error}
			classNames={classNames}
			prepend={prepend}
			append={append}
			size={size}
			required={required}
			disabled={disabled}
			isLoading={isLoading}
			createLabel={createLabel}
			objectMutate={objectMutate}
			getObjectQuery={useLoadJK}
			onChange={onChange}
		/>
	);
};
