import { Dispatch, SetStateAction } from "react";
import { TabsStage, TabStage , Creator, CardObject } from "../../../../../../../../../../../UI";
import { Block } from "./components/Block";
import {
	ApplicationCardBlock,
	ApplicationCardResponse,
	KindType,
	RealEstate,
	WhoIsClient,
} from "../../../../../../../../../../../../types";

import styles from "./index.module.scss";
import { StickyFooterApplication } from "./components/sticky-footer/StickyFooterApplication";

type Props = {
	application: ApplicationCardResponse;
	applicationId: string;
	setStageNum: Dispatch<SetStateAction<number>>;
	refetch: () => void;
	currentBlocks: any[];
	scrollY: number;
	onNextStage: (stageNumber: number) => void;
	errorsLabel: string[];
	onTab?: (tab: number) => void;
	unpinObject: () => void;
	addObject: (object: RealEstate) => void;
	isFetchingBlocks: boolean;
	onPinObject: (object: RealEstate) => void;
	onFinish: () => void;
	kind?: KindType,
};

export const BodyInfo = ({
	application,
	applicationId,
	setStageNum,
	currentBlocks,
	refetch,
	scrollY,
	onNextStage,
	errorsLabel,
	onTab,
	unpinObject,
	addObject,
	onPinObject,
	isFetchingBlocks,
	onFinish,
	kind
}: Props): JSX.Element => {
	const {
		comment,
		creator,
		stages,
		stageNo,
		collectionDescription,
		additionalProperties,
		clientObject,
		createdAt,
		modifiedAt,
		shortId,
		responsibleUser,
		type: { whoIsClient },
	} = application;

	return (
		<div id="stages" className={ kind===KindType.PREAPPLICATION ? styles.pre_applications : styles.wrapper}>

			<TabsStage
				onChange={setStageNum}
				stages={stages}
				onFinish={onFinish}
				currentStage={stageNo}
				onNextStage={onNextStage}
				whoIsClient={whoIsClient as WhoIsClient}
				errorsLabel={errorsLabel}
				key={stageNo}
				isFetchingBlocks={isFetchingBlocks}
			>
				
				{currentBlocks && stages.map((stage, index) => (
					<TabStage key={stage.title}>
						{(currentBlocks || []).map((block: ApplicationCardBlock) => (
							<Block
								application={application}
								block={block}
								index={index}
								stageNo={stageNo}
								scrollY={scrollY}
								comment={comment}
								applicationId={applicationId}
								clientObject={clientObject}
								onTab={onTab}
								onPinObject={onPinObject}
								unpinObject={unpinObject}
								addObject={addObject}
								additionalProperties={additionalProperties}
								collectionDescription={collectionDescription}
								refetch={refetch}
								responsibleUser={responsibleUser}
								key={block.id}
								kind={kind}
								whoIsClient={whoIsClient}
							/>
						))}
					</TabStage>
				))}

			</TabsStage>

			<div id="aboutApplication" className={styles.creator}>
				<Creator
					applicationId={applicationId}
					creator={{ ...creator.account, userId: creator.id }}
					lead={{
						id: application.parentLead?.id ?? applicationId,
						shortId: String(application.parentLead?.shortId ?? shortId),
					}}
					refetch={refetch}
					createdDate={(createdAt as string) || ""}
					updatedDate={(modifiedAt as string) || ""}
				/>
			</div>
		</div>
	);
};
