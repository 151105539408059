import { Button, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";

import { PopupObjectType, Select } from "../../../../../../components/UI";
import { useBusinessProcessOptions, useDisplaySelect, useFormData } from "./helpers";
import { objectFilters } from "../../../../../clientObjects";
import {
	ApplicationBusinessProcessType,
	PreCreateApplicationPayload,
	PreCreateForm,
} from "../../../../../../types";

import styles from "./index.module.scss";
import { SubmitHandler } from "react-hook-form";
import { useEffect, useState } from "react";

type Props = {
	onCreated: (values: PreCreateApplicationPayload) => void;
	isFull?: boolean;
	isLoading?: boolean
};

export const PreCreateModal = ({ onCreated, isFull, isLoading }: Props): JSX.Element => {
	const { register, setValue, handleSubmit, watchTypeId, watchCategory, watchTypeValue } = useFormData();
	const [disabled, setDisabled] = useState(isLoading)

	const businessProcessOption = isFull
		? [...objectFilters.sellBusinessProcess, ...objectFilters.buyBusinessProcess]
		: objectFilters.sellBusinessProcess;

	const { businessProcessOptions, isFetching, list } = useBusinessProcessOptions({
		businessProcessType: watchTypeValue,
		category: watchCategory,
	});

	const { showBusinessProcess, showCategory } = useDisplaySelect({
		watchCategory,
		watchTypeValue,
	});

	const handleOnCreate: SubmitHandler<PreCreateForm> = (values: PreCreateForm) => {
		const {
			type: { value, id },
			category,
		} = values;

		const found = list?.find((item) => item.id === id);

		if (found) {
			const type: ApplicationBusinessProcessType = {
				value,
				id: found.id,
				label: found.typeName,
			};

			if (category) {
				onCreated({
					category: category[0],
					type,
				});
			} else {
				onCreated({
					type,
				});
			}
		}
		setDisabled(true)
	};

	return (
		<form className={styles.root} onSubmit={handleSubmit(handleOnCreate)}>
			<InfoRow label="Тип сделки" variant="column" classNames={{ root: styles.select }}>
				<Select name="type.value" options={businessProcessOption} setValue={setValue} register={register} />
			</InfoRow>

			{showCategory && (
				<InfoRow label="Тип объекта" variant="column" classNames={{ root: styles.select }}>
					<PopupObjectType
						register={register}
						setValue={setValue}
						placeholder="Выбрать"
						defaultValues={watchCategory || []}
						variant="light"
						notMulti
					/>
				</InfoRow>
			)}

			{showBusinessProcess && (
				<InfoRow label="Тип воронки" variant="column" classNames={{ root: styles.select }}>
					<Select
						name="type.id"
						register={register}
						setValue={setValue}
						options={businessProcessOptions}
						disabled={isFetching}
						defaultValue={businessProcessOptions[0]?.value ?? ""}
					/>
				</InfoRow>
			)}

			<div className={styles.buttonContainer}>
				<Button disabled={!watchTypeId || disabled} type="submit">
					Создать
				</Button>
			</div>
		</form>
	);
};
