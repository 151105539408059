import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CollectionItem, RealEstate } from "../../../../../../types";
import isEqual from "lodash.isequal";

import { ObjectListItem, Skeleton } from "./components";
import { cleanUp } from "../../../../../../service/redux/modules/compilation/compilation.action";
import { useSample } from "./helpers";

import styles from "./index.module.scss";

type Props = {
  realEstatesList: RealEstate[];
  isLoading: boolean;
  sampledApplicationId: string;
  isCompare: boolean;
  isCompilation: boolean;
  isParser: boolean;
};

export const RealEstateListCompilation = ({
                                            realEstatesList,
                                            isLoading,
                                            sampledApplicationId,
                                            isCompare,
                                            isCompilation,
                                            isParser,
                                          }: Props): JSX.Element => {
  const {objects, refetch} = useSample({
    sampledApplicationId,
    isCompare,
    isCompilation,
  });

  const [compilationIds, setCompilationIds] = useState<Record<string, boolean>>({});

  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(cleanUp());
    },
    []
  );

  useEffect(() => {
    const arrayOfObjects = objects || [];
    const arrayOfIds: { [ x: string ]: boolean }[] = [];
    arrayOfObjects.forEach((item) => {
      const {parserObjectId, isDeleted, objectApplicationId,} = item;

      if (isDeleted) return;

      const id = objectApplicationId || parserObjectId;

      if (typeof id === "string" && id.length > 0) {
        arrayOfIds.push({[ id ]: true});
      }
    });

    const compilationIdsObject = Object.assign({}, ...arrayOfIds);

    if (!isEqual(compilationIds, compilationIdsObject)) {
      setCompilationIds(compilationIdsObject);
    }
  }, [realEstatesList.length, objects, objects.length]);

  if (isLoading) {
    return <Skeleton/>;
  }

  return (
    <div className={styles.root}>
      {realEstatesList.map((realEstate) => {
        const id = realEstate.applicationId || realEstate.id;

        const object = objects.find(item => item.objectApplicationId === id || item.parserObjectId === id)

        let collectionItem: CollectionItem | undefined
        if (object) {
          const {collectionStatus, clientComment} = object
          collectionItem = {
            collectionStatus,
            clientComment
          }
        } else {
          collectionItem = undefined
        }


        return (
          <ObjectListItem
            key={id}
            realEstate={realEstate}
            isInSample={!!compilationIds[ id ]}
            refetch={refetch}
            isParser={isParser}
            isCompilation={isCompilation}
            isCompare={isCompare}
            sampledApplicationId={sampledApplicationId}
            collectionItem={collectionItem}
          />
        );
      })}
    </div>
  );
};
