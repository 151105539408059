import { useMutation, useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/pre-application/application-template/DEFAULT_MN";

type UseLoadPreApplicationTemplate = {
	applicationId: string;
};

export const useFinishSuccessPreApplications = ({ applicationId }: UseLoadPreApplicationTemplate) => {

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: () => api.preApplicationTemplate.finishSuccess({ applicationId }),
	});
	return {
		data: mutation.data,
    mutate: mutation.mutate,
		isLoading:mutation.isLoading,
		error: mutation.error
	};
};
