import { useQuery } from "react-query";
import { api } from "../../api";
import { useLoadApplicationRealEstate } from "../application-object";
import { EditClientEstate, KindType, RealEstate } from "../../../../types";
import { RealEstateEdit, RealEstateViewWithTabs } from "../../../../components/pages/RealEstatePage/mode";
import { useGetNavigation } from "../../../../components/pages/RealEstatePage/helpers";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { selectDrawer, useSelect } from "../../../redux/selectors";
import {
  addTabInDrawer,
  addTabInDrawerInDrawer, changeCurrentTab,
  closeDrawerInDrawer,
  openDrawerInDrawer
} from "../../../redux/modules/drawer/drawer.action";
import { PreApplicationTemplate } from "../../../../components/pages/Application/PreApplicationTemplate";
import { ReactComponent as Shows } from "../../../../assets/icons/shows_and_views.svg";
import { ShowsAndViews } from "../../../../components/pages/ShowsAndViews";
import { ReactComponent as Edit } from "../../../../assets/icons/edit-page.svg";

const DEFAULT_QN = "sdelka/pre-application/application-template/DEFAULT_QN";

type UseLoadPreApplicationTemplate = {
  applicationId: string;
  isFromLead?: boolean
  refetchLead?: () => void
  reopenPreapp?: (id: string) => void
  isFromObjectList?: boolean
};

export const useLoadPreApplicationTemplate = (
  {
    applicationId,
    isFromLead,
    refetchLead,
    reopenPreapp,
    isFromObjectList
  }: UseLoadPreApplicationTemplate) => {
  const loadPreApplicationTemplate = () => api.preApplicationTemplate.load({applicationId});
  const dispatch = useDispatch();
  const {drawerInDrawer, yNavigation} = useSelect(selectDrawer);
  const {data, refetch, remove, isLoading, isFetched} = useQuery(DEFAULT_QN, loadPreApplicationTemplate);

  const applicationTemplateId = data?.data.applicationTemplateId
  const {realEstate, isFetching, refetch: refetchRealEstate} = useLoadApplicationRealEstate({
    id: applicationTemplateId ?? "",
    kind: KindType.PREAPPLICATION
  });

  const {blocksIds} = useGetNavigation({
    realEstate,
  });

  const handleSaveChanges = () => {

    setTimeout(() => {
      dispatch(
        openDrawerInDrawer({
          children: (<PreApplicationTemplate refetchLead={refetch} reopenPreapp={() => {
          }} applicationId={applicationId} isFromLead={true}/>),
          width: 800,
        })
      );

    }, 500)
    refetchRealEstate()
    refetch()
    dispatch(closeDrawerInDrawer())
  }

  const clientEstate: RealEstate | undefined = useMemo(() => {
    if (realEstate) {
      const copy: EditClientEstate = {
        ...realEstate,
        applications: [],
        activeApplications: {},
      };
      if (copy?.schema) {
        delete copy.schema;
      }
      return copy;
    }
    return undefined;
  }, [realEstate]);

  const viewNav = {
    label: "Объект",
    height: 160,
    title: "view"
  };

  const editNav = {
    label: <Edit/>,
    height: 90,
    title: "edit"
  };

  const viewPreApp = {
    label: "Предзаявка",
    height: 200,
    title: "preapp"
  };

  const preappChildren = (
    <PreApplicationTemplate
      applicationId={applicationId}
      isFromLead={isFromLead}
    />)

  const viewChildren = (
    <RealEstateViewWithTabs
      id={applicationTemplateId ?? ""}
      blocksIds={blocksIds}
      applicationTemplateId={applicationTemplateId ?? ""}
      isFromLead={isFromLead}
      kind={KindType.PREAPPLICATION}
    />
  )

  const editObject = (
    <RealEstateEdit
      // id={applicationId}
      id={applicationTemplateId ?? ""}
      realEstate={clientEstate as RealEstate}
      refetch={handleSaveChanges}
      remove={remove}
      isFetching={isFetching}

    />
  )

  useEffect(() => {
    if (isFromLead === true) {
      if (drawerInDrawer?.yNavigation && drawerInDrawer?.yNavigation?.navigation.find((el) => el.title !== "preapp") !== undefined) {
      } else {
        dispatch(addTabInDrawerInDrawer({
          newChildren: preappChildren,
          newNavigation: viewPreApp
        }))
      }
    }
  }, [yNavigation])

  useEffect(() => {
    if (realEstate && drawerInDrawer?.yNavigation?.navigation.every((el) => el.title !== "view")) {
      if (isFromLead === true) {
        dispatch(addTabInDrawerInDrawer({
          newChildren: viewChildren,
          newNavigation: viewNav
        }))
        dispatch(addTabInDrawerInDrawer({
          newChildren: editObject,
          newNavigation: editNav
        }))
      }
    }

    if (realEstate && yNavigation && yNavigation?.navigation.every((el) => el.title !== "view")) {
      dispatch(addTabInDrawer({
        newChildren: viewChildren,
        newNavigation: viewNav
      }))

      if (isFromObjectList) {
        dispatch(changeCurrentTab({newCurrentTab: 2}))
      }
    }

    if (data) {
      const {
        type: {whoIsClient},
      } = data.data;

      const newNavigationShows = {
        label: <Shows/>,
        title: "shows",
        height: 90,
      };
      const newChildrenShows = (
        <ShowsAndViews applicationId={applicationTemplateId ?? ""} type={whoIsClient === "buyer" ? "views" : "shows"}/>
      );

      // if (isFromLead && drawerInDrawer?.yNavigation && drawerInDrawer?.yNavigation?.navigation.every((el) => el.title !== "shows")) {
      //   dispatch(
      //     addTabInDrawerInDrawer({
      //       newChildren: newChildrenShows,
      //       newNavigation: newNavigationShows,
      //     })
      //   )
      // }

      if (yNavigation && yNavigation.navigation.every((el) => el.title !== "shows")) {
        dispatch(
          addTabInDrawer({
            newChildren: newChildrenShows,
            newNavigation: newNavigationShows,
          })
        )
      }
    }
  }, [dispatch, drawerInDrawer?.yNavigation, isFromLead, realEstate, viewChildren, viewNav, yNavigation])

  return {
    data: data?.data,
    isLoading,
    refetch,
    remove,
    isFetched,
  };
};
