import { useQuery } from "react-query";

import { api } from "../../index";

export const useLoadUserInvites = () => {
	const loadUserInvites = () => api.invites.userInvites();

	const { data, isLoading, refetch } = useQuery("loadUserInvites", loadUserInvites);

	return {
		invites: data?.data || [],
		isLoading,
		refetch,
	};
};
