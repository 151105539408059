import { H1, Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ReactComponent as PlotsIcon } from "../../../../../../../assets/icons/plots.svg";
import styles from "./index.module.scss";

type Props = {
	onButtonClick: () => void;
};

export const RealEstateAdHeader = ({ onButtonClick }: Props): JSX.Element => (
	<div className={styles.root}>
		<H1 classNames={{ root: styles.h1 }}>Реклама объекта</H1>

		<Button onClick={onButtonClick} Icon={PlotsIcon}>
			График рекламы
		</Button>
	</div>
);
