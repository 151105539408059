import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { useAdDescriptionModal } from "../../../helpers";
import { AdErrorActionType } from "../../../../../../../../../../types";

type Props = {
	type?: AdErrorActionType;
	data: any;
	applicationId: string;
};

const AdDescription = ({ data, applicationId }: { data: any; applicationId: string }) => {
	const { feedId, cianError, onRetry } = data;

	const { onModalOpen } = useAdDescriptionModal({
		feedId,
		error: cianError,
		applicationId,
	});

	onModalOpen();

	return (
		<>
			<Button onClick={onModalOpen} variant="outlined">
				Исправить
			</Button>
			<Button onClick={onRetry} variant="text">
				Запросить размещение повторно
			</Button>
		</>
	);
};

export const ErrorAction = ({ applicationId, type, data }: Props): JSX.Element => {
	if (type === "callToSupport") {
		return <Button variant="text">Обратиться в службу поддержки {data.platformName}</Button>;
	}

	if (type === "openPlatformSite") {
		return (
			<a href={data.link}>
				<Button>Перейти в личный кабинет площадки</Button>
			</a>
		);
	}

	if (type === "openObjectAdDescription") {
		return <AdDescription data={data} applicationId={applicationId} />;
	}

	if (type === "changeAdId") {
		return <div />;
	}

	if (type === "openObjectCard") {
		return <div />;
	}

	return <div />;
};
