import { Button, InfoRow, Loader } from "@sdelka_crm/sdelka-crm-component-library";

import styles from "./index.module.scss";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../../../../../../service/redux/modules/modal/modal.action";
import { LeadSourceEnum } from "../../../../../../../../../../../types";
import { useLeadsAndDealsTabs, useOpenLead } from "../../../../../../../../../../pages/LeadsAndDeals/helpers";
import { useQueryParams } from "../../../../../../../../../../../utils/hooks";
import { Select } from "../../../../../../../../../Form";
import { useForm } from "react-hook-form";
import { useStartLead } from "./helpers/useAddLead";
import { useGetAdSources } from "./helpers";

type SourceOption = {
	label: string;
	value: LeadSourceEnum;
};

type Props = {
	contactId?: string
}

export const CreateContactModalForm = (
	{
		contactId
}: Props): JSX.Element => {
	const { push } = useHistory();
	const dispatch = useDispatch();

	const { refetch } = useLeadsAndDealsTabs();
	const { adSources } = useGetAdSources()

	const adSourceOptions: SourceOption[] = adSources.map(el => Object.create({label: el, value: el}))

	const { handleOpenLead } = useOpenLead({ refetch });

	const { queryParams } = useQueryParams<{
		tab: string;
	}>([{ name: "tab" }]);
	const { tab } = queryParams;

	const { mutate: mutateStartLead, isLoading } = useStartLead({
		onSuccess: async ({ data }) => {
			if (data.success && data.id !== undefined) {
				dispatch(closeModal());

				const leadId = data.id;

				// const formattedPhone = phoneWatch.replace(/\D/gi, "");
				push(`leadsanddeals?tab=leads&id=${leadId}`);

				if (tab && tab === "leads") handleOpenLead(leadId);
			}
		},
		contactId
	});

	const { setValue, register, watch } = useForm();

	const selectedValue = watch("adSource");

	const handleGoCreateLead = () => {
		mutateStartLead(selectedValue);
	};

	if (isLoading)
		return (
			<div className={styles.loaderContainer}>
				<Loader />
			</div>
		);

	return (
		<form className={styles.root}>
			<InfoRow label="Источник" variant="column" classNames={{ root: styles.select }}>
				<Select name="adSource" options={adSourceOptions} setValue={setValue} register={register} />
			</InfoRow>

			<div className={styles.submitBtnContainer}>
				<Button disabled={!selectedValue} onClick={handleGoCreateLead}>
					Создать лид
				</Button>
			</div>
		</form>
	);
};
