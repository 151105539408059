import styles from "./index.module.scss"
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { UseOpenAssignShowAndViewModal } from "./assign-show-view-modal/useOpenAssignShowAndViewModal";

type Props = {
  applicationId: string
  isViews: boolean;
  refetch: () => void
}

export const StickyFooter = ({isViews, refetch, applicationId}: Props): JSX.Element => {

  const buttonText = `Назначить ${!isViews ? "показ" : "просмотр"}`
  const onSuccess = () => {
    refetch()
  }
  const {openAssignShowAndViewModal} = UseOpenAssignShowAndViewModal({isViews, onSuccess, applicationId})

  return (
    <div className={styles.root}>
      <Button
        classNames={{root: styles.button}}
        onClick={openAssignShowAndViewModal}
      >
        {buttonText}
      </Button>
    </div>
  )
}