import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";
import { Lead } from "../../../../types";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/lead/create-lead-from-traffic/DEFAULT_MN";

type Props = {
	onSuccess?: (res: AxiosResponse<Lead>) => void;
	onError?: () => void;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<Lead>, unknown, string, unknown>;
	isLoading: boolean;
};

type UseCreateLeadFromObject = (props: Props) => Return;

export const useCreateLeadFromObject: UseCreateLeadFromObject = ({ onSuccess, onError }) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (objectId: string) => api.leads.createFromObject(objectId),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
