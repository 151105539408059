import {
	Compilation,
	CompilationAddObjectPayload,
	CompilationDeleteObjectPayload,
	CompilationUpdateFilterPayload,
} from "../../../../types";

import { Client } from "../../Client";

export const CompilationService = {
	get: (applicationId: string) => Client.get<Compilation>(`/application/${applicationId}/collection`),

	addCompilation: (applicationId: string) =>
		Client.post<Compilation>(`/application/${applicationId}/collection`),

	deleteCompilation: (applicationId: string) =>
		Client.delete<boolean>(`/application/${applicationId}/collection`),

	addObjects: ({ applicationId, parserObjectIds, clientObjectApplicationIds }: CompilationAddObjectPayload) =>
		Client.post<void>(`/application/${applicationId}/collection/objects/add`, {
			parserObjectIds,
			clientObjectApplicationIds,
		}),

	deleteObject: ({ applicationId, objectId }: CompilationDeleteObjectPayload) =>
		Client.delete<{ objectId: string }>(`/application/${applicationId}/collection/objects/${objectId}`),

	updateFilter: ({ applicationId, filterData }: CompilationUpdateFilterPayload) =>
		Client.put<boolean>(`/application/${applicationId}/collection`, {
			filterData,
		}),

	deleteUnactual: (applicationId: string) =>
		Client.delete(`/application/${applicationId}/collection/unActualObjects`),

	getCollectionObjects: ( applicationId: string) => Client.get(`/application/${applicationId}/collection/objects`)
};
