import { Parallax, ParallaxItem } from "../../../../../UI";
import { selectAccounts, useSelect } from "../../../../../../service/redux/selectors";
import styles from "../TitleImage/styles.module.scss";
import title_image from "../../../../../../assets/images/sdelka.png";

export const HandWithKey = (): JSX.Element => {
  const {errors} = useSelect(selectAccounts);

  return (
    <Parallax>
      <ParallaxItem coordinates={() => ["", ""]} className={styles.wrap}>
        <img alt="Hand" src={title_image}/>
      </ParallaxItem>
    </Parallax>
  );
};
