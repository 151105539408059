import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { closeDrawer, closeDrawerInDrawer } from "../../../../../service/redux/modules/drawer/drawer.action";
import { selectDrawer, selectModal, useSelect } from "../../../../../service/redux/selectors";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	wrapperClassName?: string;
	performance?: "high" | "low";
};

export const DrawerWrapper = ({ children, wrapperClassName, performance }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { isOpen: isModalOpen } = useSelect(selectModal);
	const { drawerInDrawer, onClose, disableClose } = useSelect(selectDrawer);

	const handleCloseDrawer = () => {
		if (!isModalOpen) {
			if (drawerInDrawer) {
				dispatch(closeDrawerInDrawer());
			} else {
				if (onClose) {
					onClose();
				}

				if (!disableClose) {
					dispatch(closeDrawer());
				}
			}
		}
	};

	return (
		<>
			<div
				onClick={handleCloseDrawer}
				className={cn(styles.drawerBackground, {
					[styles.lowPerf]: performance === "low",
				})}
			/>

			<div
				className={cn(
					styles.wrapper,
					wrapperClassName,
					"animate__animated",
					"animate__faster",
					"animate__fadeInRightBig"
				)}
			>
				{children}
			</div>
		</>
	);
};
