import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInMonths,
	differenceInSeconds,
	differenceInWeeks,
} from "date-fns";

import { Difference } from "../types";

/**
 * Calculate time differences between now and deadline, and then pick
 *  a smallest difference
 */
export const getAppropriateDiff = (deadline: Date | string) => {
	const times = [new Date(), new Date(deadline)] as const;

	const seconds: Difference = {
		value: differenceInSeconds(...times),
		type: "seconds",
	};
	const minutes: Difference = {
		value: differenceInMinutes(...times),
		type: "minutes",
	};
	const hours: Difference = {
		value: differenceInHours(...times),
		type: "hours",
	};
	const days: Difference = { value: differenceInDays(...times), type: "days" };
	const weeks: Difference = {
		value: differenceInWeeks(...times),
		type: "weeks",
	};
	const months: Difference = {
		value: differenceInMonths(...times),
		type: "months",
	};

	const diffs = [seconds, minutes, hours, days, weeks, months] as const;

	const mostShortDiffIndex = diffs.findIndex((diff) => diff.value === 0) - 1;

	return diffs[mostShortDiffIndex] ?? diffs.at(-1);
};
