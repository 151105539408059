import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router";

import styles from "./index.module.scss";
import { Block } from "./components/Block";
import { ApplicationCardResponse } from "types";

type Props = {
	id: string;
	block: any;
	refetch?: ()=>void;
	application?: ApplicationCardResponse
};

export const DealCharacteristics = ({
	id,
	block,
	refetch,
	application
}: Props): JSX.Element => {

	const {
		control,
		register,
		setValue,
		handleSubmit,
		watch,
		reset,
		getValues,
		formState,
		clearErrors,
		setError,
	} = useForm<any>({
		defaultValues:application,
	});
	const form = {
		control,
		register,
		setValue,
		getValues,
		watch,
		clearErrors,
		setError,
		formState,
		reset,
		handleSubmit,
	};
	// const abjArr = Object.entries(populations);
	const formData = form.getValues().additionalProperties;

	return (
		<div className={styles.root}>
      <div className={styles.title}>
				{block?.title}
			</div>
			<div className={styles.blocks}>
				{block?.blocks && block.blocks.map(((el, index)=>
					<ul key={index}>
						<li>
							<div>
								<Block 
									refetch={refetch}
									id={id}
									form={form} 
									formData={formData}
									blockName={el.blockName} 
									editFields={el.editFields}
									viewFields={el.viewFields}
									blockTitle={el.blockTitle}
								/>
							</div>
						</li>
					</ul>
					))}
			</div>
		</div>
	);
};
