export const NO_IMG_VALUE_COMP = "NO_IMG/value-for-state";

export const SET_HOVERED_RE_IMG = "sdelka/compilation/SET_HOVERED_RE_IMG";
export const CLEAN_UP = "sdelka/compilation/CLEAN_UP";

export type CompilationAction =
	| { type: typeof SET_HOVERED_RE_IMG; hoveredRealEstateImg?: string }
	| { type: typeof CLEAN_UP };

export const setHoveredRealEstateImg = (hoveredRealEstateImg?: string): CompilationAction => ({
	type: SET_HOVERED_RE_IMG,
	hoveredRealEstateImg,
});

export const cleanUp = (): CompilationAction => ({
	type: CLEAN_UP,
});
