import { useRef, useEffect, useState, RefObject, UIEvent } from "react";

type Props = {
	withScrollBtn?: boolean;
};

type Return = {
	scrollTop: () => void;
	scrollWrapper: RefObject<HTMLDivElement>;
	showScrollBtn: boolean;
};

type UseScroll = (props: Props) => Return;

export const useScroll: UseScroll = ({ withScrollBtn }) => {
	const scrollWrapper = useRef<HTMLDivElement>(null);
	const haveWrapper = scrollWrapper?.current;
	const [showScrollBtn, setShowScrollBtn] = useState(false);

	const onScroll: EventListenerOrEventListenerObject = (event) => {
		if (event) {
			const {
				currentTarget: { scrollTop },
			} = event as unknown as UIEvent<HTMLElement>;

			if (scrollTop > 0 && !showScrollBtn) {
				setShowScrollBtn(true);
			} else if (scrollTop === 0 && showScrollBtn) {
				setShowScrollBtn(false);
			}
		}
	};

	useEffect(() => {
		if (withScrollBtn && haveWrapper) {
			const elemInScroll = scrollWrapper?.current?.childNodes[0].childNodes[0];
			elemInScroll?.addEventListener("scroll", onScroll);
		}

		return () => {
			if (withScrollBtn && haveWrapper) {
				const elemInScroll = scrollWrapper?.current?.childNodes[0].childNodes[0];

				elemInScroll?.removeEventListener("scroll", onScroll);
			}
		};
	});

	const scrollTop = () => {
		if (scrollWrapper?.current) {
			const elem = scrollWrapper?.current?.childNodes[0].childNodes[0];
			(elem as HTMLElement)?.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	};

	return {
		scrollTop,
		scrollWrapper,
		showScrollBtn,
	};
};
