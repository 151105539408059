import cn from "classnames";
import { ApplicationTitle, BusinessProcessTranslateEnum } from "../../../../../../../../../../../types";

import { useOpenApplication } from "./helpers";
import { prepareShortUserOutput } from "../../../../../../../../../../../utils/transformation";

import styles from "./index.module.scss";
import { ReactComponent as DefaultAvatar } from "../../../../../../../../../../../assets/icons/account-circle.svg";

type Props = {
	application: ApplicationTitle;
	isActive: boolean;
};

export const Info = ({ application, isActive }: Props): JSX.Element => {
	const {
		id,
		shortId,
		type,
		responsibleUser: { name, lastName, secondName, avatar },
	} = application;

	const { handleClick } = useOpenApplication({ isActive, applicationId: id });

	const label = `Заявка №${shortId}: ${BusinessProcessTranslateEnum[type]}`;

	const fioLabel = prepareShortUserOutput({
		name: name || "",
		lastName: lastName || "",
		secondName,
	});

	return (
		<div
			className={cn(styles.root, {
				[styles.active]: isActive,
			})}
			onClick={handleClick}
		>
			<div className={styles.label}>{label}</div>

			<div className={styles.responsible}>
				{avatar ? <img src={avatar} alt="avatar" /> : <DefaultAvatar />}

				<span>{fioLabel}</span>
			</div>
		</div>
	);
};
