import cn from "classnames";
import styles from "./index.module.scss";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import {
  UseSetObjectDoublesFromOwner
} from "../../../../../../../service/api/mutations/real-estate/useGetObjectDoublesFromOwners";
import { CardObject } from "../../../../../../UI";
import {
  UseSetObjectDoublesFromAgency
} from "../../../../../../../service/api/mutations/real-estate/useGetObjectDoublesFromAgency";
import { Plug } from "../plug/Plug";

type Props = {
  id: string
  fromAgency?: boolean
  isFromParser?: boolean
}

export const ObjectDoubles = (
  {
    id,
    fromAgency,
    isFromParser
  }: Props): JSX.Element => {

  const useHook = !fromAgency ? UseSetObjectDoublesFromOwner({id}) : UseSetObjectDoublesFromAgency({id})

  const {doubles, isLoading, isFetching, total} = useHook
  const title = !fromAgency ? "Дубли объекта от собственника" : "Дубли объекта от агенств"
  const count = doubles ? `(${total})` : "(0)"


  return (
    <div className={styles.root}>
      <div className={cn(styles.row, styles.header)}>
        <div className={styles.row}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.counter}>
            {count}
          </div>
        </div>
      </div>

      {(doubles && doubles.length > 0)
        ? <>
          {isLoading || isFetching
            ? <div
              className={cn(styles.loading, "animate__animated", "animate__faster", "animate__fadeIn", {
                [ styles.loadingHide ]: !isFetching,
              })}
            >
              <Loader/>
            </div>
            : <div className={styles.collections}>
              {doubles && doubles.length > 0 && doubles.slice(0, 3).map((double) => (
                <div key={double.applicationId}>
                  <CardObject
                    id={double.id}
                    clientObject={double}
                    variant="standard"
                  />
                </div>
              ))
              }
            </div>
          }
        </>
        :   <>
          <Plug plugText="Других обьявлений не найдено"/>
        </>
      }
    </div>
  )
}


