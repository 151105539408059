import { useEffect, useState } from "react";
import { Select } from "../Select";
import { useFindAddressDetailed } from "../../../../../service/api/mutations";
import { DefaultSelectProps, FindAddressDetailed, LocationType } from "../../../../../types";

type AddressValue = {
	label: string;
	value: string;
	location?: LocationType;
};

type Props = {
	value: AddressValue;
	label?: string
} & Omit<DefaultSelectProps, "isSearchable" & "arrowVariant" & "onChange">;

export const AddressSelect = ({
	value,
	placeholder = "Введите адрес",
	name,
	register,
	setValue,
	error,
	classNames,
	prepend,
	append,
	size,
	required,
	disabled,
	withBorder,
	label,
}: Props): JSX.Element => {
	const [addressObject, setAddressObject] = useState<AddressValue>({
		label: value?.label || "",
		value: value?.value || "",
		location: value?.location || undefined,
	});

	const [options, setOptions] = useState<AddressValue[]>([
		{
			label: addressObject.label,
			value: addressObject.value,
			location: addressObject.location,
		},
	]);

	useEffect(() => {
		setAddressObject({
			label: value?.label || "",
			value: value?.value || "",
			location: value?.location || undefined,
		});

		if (value?.label === "") {
			setOptions([]);
		}
	}, [value]);

	const findAddress = useFindAddressDetailed();

	const onSelectWrite = async (searchString: string) => {
		if (searchString === "") {
			setOptions([]);

			setValue(name, {
				label: "",
				value: "",
				location: undefined,
			});

			return;
		}

		const { data } = await findAddress.mutateAsync(searchString);

		if (data) {
			const foundAddresses = data.filter((address) => address.label);
			const withoutDuplicate = foundAddresses.filter(
				(address, index, self) => index === self.findIndex((t) => t.label === address.label)
			);

			if (data.length) {
				const newOptions = [
					{
						label: addressObject.label as string,
						value: addressObject.value as string,
					},
				].concat(
					withoutDuplicate.map((address) => ({
						label: address.label,
						value: address.id,
					}))
				);

				setOptions(newOptions);
			}
		}
	};

	const onSelectChange = (id: string) => {
		if (options?.length === 0) {
			return;
		}

		if (id === value.value) {
			return;
		}

		const lastMutation: any = findAddress?.getLastMutationCache();

		const selectedAddress: FindAddressDetailed = lastMutation?.state?.data?.data?.find(
			(address: FindAddressDetailed) => address.id.toString() === id.toString()
		);

		const coords = selectedAddress?.point?.split(",");

		if (!selectedAddress) {
			setValue(name, {
				label: "",
				value: "",
				location: undefined,
			});

			return;
		}

		const newOptions: AddressValue[] = [
			{
				label: selectedAddress.label,
				value: selectedAddress.id,
				location: {
					coordinates: [Number(coords[1]), Number(coords[0])],
				},
			},
		];

		setOptions(newOptions);
		setValue(name, newOptions[0]);
		setAddressObject(newOptions[0]);
	};

	const setCurrentAddress = (_, values: string) => {
		onSelectChange(values);
	};

	return (
		<Select
			label={label}
			placeholder={placeholder}
			name={name}
			hideArrow
			defaultValue={addressObject.value}
			defaultSearchValue={addressObject.label}
			isSearchable
			size={size}
			options={options}
			register={register}
			setValue={setCurrentAddress}
			classNames={classNames}
			customSearchFunction={onSelectWrite}
			onChange={onSelectChange}
			error={error}
			append={append}
			prepend={prepend}
			required={required}
			isLoading={findAddress.isLoading}
			disabled={disabled}
			withBorder={withBorder}
		/>
	);
};
