import { Button, ButtonProps } from "@sdelka_crm/sdelka-crm-component-library";
import cn from "classnames";

import { ReactComponent as CloseIcon } from "../../../../../assets/icons/plus.svg";
import styles from "./index.module.scss";

type Props = Pick<ButtonProps, "disabled" | "onClick" | "classNames">;

export const ButtonClose = ({ disabled, onClick, classNames }: Props): JSX.Element => (
	<Button
		variant="outlined"
		Icon={CloseIcon}
		disabled={disabled}
		isLoading={false}
		onClick={onClick}
		classNames={{
			root: cn(classNames?.root, styles.root),
			icon: classNames?.icon,
		}}
	/>
);
