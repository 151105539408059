import { useMutation } from "react-query";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/application/delete-application-contacts/DEFAULT_MN";

type UseDeleteContacts = {
	onSuccess?: (data: any) => void;
	onError?: () => void
};

export const useDeleteContacts = ({ onSuccess,onError }: UseDeleteContacts) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: { applicationId?: string; id: string; type?: string }) =>
			api.application.deleteContact(data),
		onSuccess,
		onError
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
		error: mutation.error
	};
};
