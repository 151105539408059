import { ReactElement, useEffect, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import cn from "classnames";
import isEqual from "lodash.isequal";

import styles from "./index.module.scss";
import commonStyles from "../index.module.scss";

type IMultiCheckboxOption = {
	label: string;
	value: string;
	child?: ReactElement;
	disabled?: boolean;
};

type IMultiCheckboxProps = {
	direction?: "row" | "column" | "grid";
	variant?: "default" | "radioButton" | "radioButtonWhite";
	margin?: "small" | "medium";
	name: string;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	className?: string;
	defaultValue?: string[];
	options: IMultiCheckboxOption[];
	onChange?: (values: string[]) => void;
};

export const MultiCheckbox = ({
	name,
	register,
	setValue,
	defaultValue = [],
	options,
	direction = "row",
	variant = "default",
	className,
	onChange,
	margin = "medium",
}: IMultiCheckboxProps): JSX.Element => {
	const getDefaultValue = () => (Array.isArray(defaultValue) ? defaultValue : []);

	const [selected, setSelected] = useState<string[]>(getDefaultValue());

	useEffect(() => {
		setValue(name, [...selected]);
	}, [selected]);

	useEffect(() => {
		if (isEqual(defaultValue, selected) || !Array.isArray(defaultValue)) return;

		setSelected(defaultValue);
	}, [defaultValue?.length]);

	const onCheckboxChange = (item: string) => {
		if (selected.includes(item)) {
			const newSelected = selected.filter((i) => i !== item);
			if (onChange) {
				onChange(newSelected);
			}

			return setSelected(newSelected);
		}

		const newSelected = selected.concat(item);
		if (onChange) {
			onChange(newSelected);
		}

		return setSelected(newSelected);
	};

	const getHtmlId = ({ option }: { option: IMultiCheckboxOption }) => name + option.value;

	return (
		<div
			className={cn(
				styles.checkboxesWrapper,
				styles[direction],
				commonStyles[variant],
				commonStyles[margin],
				styles[variant],
				className
			)}
		>
			{options.map((option) => (
				<div
					className={cn(styles.checkboxWrapper, styles[margin], {
						[styles.disabled]: option?.disabled,
					})}
					key={option.value}
				>
					<label
						htmlFor={getHtmlId({ option })}
						className={cn(commonStyles.label, {
							[commonStyles.checked]: selected?.includes(option.value),
							[commonStyles.smallRadioButton]:
								option.label.length <= 2 && (variant === "radioButton" || variant === "radioButtonWhite"),
						})}
					>
						<input
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...register(name)}
							id={getHtmlId({ option })}
							value={option.value}
							onClick={() => onCheckboxChange(option.value)}
							type="checkbox"
							disabled={option.disabled}
							className={commonStyles.checkbox}
						/>
						<span className={commonStyles.checkboxBg} />
						<span className={commonStyles.labelText}>{option.label}</span>
					</label>

					{option.child}
				</div>
			))}
		</div>
	);
};
