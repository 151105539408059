import cn from "classnames";
import { H1, H2, H3, formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { ApplicationCardResponse } from "../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	application: ApplicationCardResponse;
};

export const ApplicationBanner = ({ application }: Props): JSX.Element => {
	const { stages, stageNo, stage, clientObject, shortId, createdAt, type } = application;
	const { background, image } = stages[stageNo - 1];

	const { title } = clientObject;
	const whoIsClient = type.whoIsClient === "buyer" ? "Покупка" : "Продажа";

	return (
		<div className={styles.root}>
			<div
				className={styles.banner}
				style={{
					background: `radial-gradient(25.32% 51.1% at 70.86% 55.8%, rgba(89, 195, 255, 0.5) 0%, ${background} 100%)`,
				}}
			>
				<img src={image} className={styles.img} alt="_image" />
			</div>

			<div className={styles.content}>
				<H3 classNames={{ root: cn(styles.noMargin, styles.grey) }}>Ваша заявка</H3>

				<H1 classNames={{ root: styles.h1 }}>{`${whoIsClient} ${title}`}</H1>

				<H2 classNames={{ root: cn(styles.noMargin, styles.grey) }}>{clientObject.addressLabel || ""}</H2>

				<p className={styles.stage}>
					{`Этап ${stageNo}: `}
					<span>{stage}</span>
				</p>

				<div className={styles.idCreatedAt}>
					<span>{`Заявка №${shortId}`}</span>

					<span>{`Создана ${formatDate(createdAt)}`}</span>
				</div>
			</div>
		</div>
	);
};
