import { useHistory } from "react-router";
import { UseMutateFunction, useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { KindType, RealEstateCreatePayload } from "../../../../types";
import { api } from "../../index";
import { useDispatch } from "react-redux";
import { changeCurrentTab, closeDrawer, openDrawer } from "../../../redux/modules/drawer/drawer.action";
import { ApplicationCard } from "../../../../components/pages/Application";
import { useOpenDrawerRealEstate } from "../../../../utils/hooks";

const DEFAULT_MN = "sdelka/real-estate/create-real-estate/DEFAULT_MN";

type Props = {
	onSuccess?: (data) => void;
	disabledPush?: boolean;
};

type Return = {
	mutate: UseMutateFunction<
		AxiosResponse<{
			applicationId: string;
		}>,
		unknown,
		RealEstateCreatePayload,
		unknown
	>;
	isLoading: boolean;
};

type UseCreateApplicationObject = (props: Props) => Return;

export const useCreateApplicationObject: UseCreateApplicationObject = ({
	onSuccess,
	disabledPush = false,
}) => {
	const { push } = useHistory();
	const dispatch = useDispatch()

  const onCardClick = (id: string) => {
		dispatch(
			openDrawer({
				width: 800,
				children:
					<ApplicationCard
						applicationId={id}
						inDrawer
						kind={KindType.APPLICATION}
						isFromObjectList={true}
					/>,
				onClose: () => {
					dispatch(changeCurrentTab({newCurrentTab: 0}))
				}
			})
		);
	};

	const onCreateSuccess = (response: AxiosResponse<{ applicationId: string }>) => {
		if (onSuccess) {
			onSuccess(response.data);
		}

		if (!disabledPush && response?.data?.applicationId) {
			dispatch(closeDrawer())
			push(`/real-estate?tab=AGENCY`);
			setTimeout(() => {
        onCardClick(response?.data?.applicationId)
			}, 1000)
		}
	};

	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (payload: RealEstateCreatePayload) => api.applicationRealEstate.create(payload),
		onSuccess: (response) => onCreateSuccess(response),
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
