import { CSSProperties, ReactNode, useMemo } from "react";
import cn from "classnames";
import styles from "./index.module.scss";

const HIGHEST_Z_INDEX = 100;

export type FixedNavVerticalMenu = {
  id: string | number;
  label: string | ReactNode;
  title?: string;
  changeTab: () => void;
  height?: number;
  disabled?: boolean;
};

export type FixedNavVerticalProps = {
  navigation: FixedNavVerticalMenu[];
  activeTab: string | number;
  top?: number;
  classNames?: {
    container?: string;
  };
  style?: CSSProperties;
};

export const FixedNavVertical = (
  {
    classNames,
    navigation,
    top,
    activeTab,
    style,
  }: FixedNavVerticalProps): JSX.Element => {
  const activTabIndex = useMemo(
    () => navigation.findIndex((nav) => nav.id === activeTab),
    [activeTab, navigation]
  );

  const getZindex = (tabId: string | number): number => {
    const tabIndex = navigation.findIndex((nav) => nav.id === tabId);
    const difference = Math.abs(activTabIndex + 1 - (tabIndex + 1));

    return HIGHEST_Z_INDEX - difference;
  };

  return (
    <div className={cn(styles.container, classNames?.container)} style={{top: top || 0, ...style}}>
      <div className={styles.buttonsContainer}>
        {navigation.map((nav) => (
          <div
            key={nav.id}
            style={{
              minHeight: nav.height || 120,
              zIndex: nav.id !== activeTab ? getZindex(nav.id) : HIGHEST_Z_INDEX,
            }}
            onClick={() => {
              if (!nav.disabled) {
                nav.changeTab();
              }
            }}
            className={cn(styles.buttonItem, {
              [ styles.buttonItemActive ]: nav.id === activeTab,
              [ styles.buttonItemDisabled ]: nav.disabled,
            })}
          >
            <div className={styles.buttonText}>{nav.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
