import { Loader, H3, Button } from "@sdelka_crm/sdelka-crm-component-library";

import { NotContact, RelatedContactCard } from "./components";
import { useRelatedContacts } from "../../../../../../service/api/queries";
import { ContactFilter } from "../../../../../../types";

import styles from "./index.module.scss";
import { Plug } from "../../../../RealEstatePage/mode/RealEstateView/components/plug/Plug";

type Props = {
	id: string;
	contactId: string;
};

const RELATED_CONTACTS_TAKE = 2;

export const RelatedContacts = ({ id, contactId }: Props): JSX.Element => {
	const defaultFilter: ContactFilter = {
		parents: { childId: contactId },
		children: { parentId: contactId },
	};

	const { relatedContactsList, total, take, setTake, isFetching } = useRelatedContacts({
		takeAmount: RELATED_CONTACTS_TAKE,
		defaultFilter,
	});

	const nextPage = () => setTake(take + RELATED_CONTACTS_TAKE);

	const firstPage = () => setTake(RELATED_CONTACTS_TAKE);

	const more = () => total - take;

	return (
		<div className={styles.root} id={id}>
			<H3>Связанные контакты</H3>

			{((relatedContactsList.length > 0 || isFetching) && (
				<>
					<div className={styles.relatedContacts}>
						{relatedContactsList.map((contact) => (
							<RelatedContactCard key={contact.id} contact={contact} />
						))}
					</div>

					{(isFetching && <Loader height={30} style={{ marginTop: 12 }} />) || (
						<div className={styles.buttons}>
							{take > RELATED_CONTACTS_TAKE && (
								<Button variant="text" onClick={firstPage}>
									Очистить
								</Button>
							)}

							{take < total && (
								<Button variant="text" onClick={nextPage}>
									Еще &nbsp;
									{more()}
								</Button>
							)}
						</div>
					)}
				</>
			)) || <Plug plugText={"Связанных контактов нет"}/>}
		</div>
	);
};
