import { User } from "../../../../types";

import { AccountsTInitialState } from "../../types";

const SET_USERS = "sdelka/users-reducer/SET_USERS";
const INITIALIZE_APP = "sdelka/users-reducer/INITIALIZE_APP";
const CLEAN_UP = "sdelka/users-reducer/CLEAN_UP";
const SET_ERRORS = "sdelka/users-reducer/SET_ERRORS";
const SET_AS_EMPLOYEE = "sdelka/users-reducer/SET_AS_EMPLOYEE";

type TAction =
	| { type: typeof SET_USERS; items: User[] }
	| { type: typeof INITIALIZE_APP }
	| { type: typeof CLEAN_UP }
	| { type: typeof SET_ERRORS; errors?: string | string[] }
	| { type: typeof SET_AS_EMPLOYEE; isAsEmployee: boolean };

const initialState: AccountsTInitialState = {
	accounts: [],
	initialized: false,
	authorized: false,
};

export const accountsReducer = (state = initialState, action: TAction) => {
	switch (action.type) {
		case SET_USERS:
			return {
				...state,
				accounts: action.items,
				authorized: true,
				initialized: true,
			};
		case INITIALIZE_APP:
			return {
				...state,
				initialized: true,
			};
		case SET_AS_EMPLOYEE:
			return {
				...state,
				isAsEmployee: action.isAsEmployee,
			};
		case CLEAN_UP:
			return {
				...state,
				accounts: [],
				authorized: false,
				errors: undefined,
				isAsEmployee: false,
			};
		case SET_ERRORS:
			return {
				...state,
				errors: action.errors,
			};
		default:
			return { ...state };
	}
};

export const setUsers = (items: User[]): TAction => ({
	type: SET_USERS,
	items,
});

export const initializeApp = (): TAction => ({
	type: INITIALIZE_APP,
});

export const cleanUpAccounts = (): TAction => ({
	type: CLEAN_UP,
});

export const setErrors = (errors?: string | string[]): TAction => ({
	type: SET_ERRORS,
	errors,
});

export const setAsEmployee = (isAsEmployee: boolean): TAction => ({
	type: SET_AS_EMPLOYEE,
	isAsEmployee,
});
