import { useWatch } from "react-hook-form";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { FormPropsEditRealEstate, RealEstateSchemaConfigField } from "../../../../../../../../../types";
import { TransparentInput } from "../../../../../../../index";

import { cadastralCodeMask } from "../../../../../../../../../utils/masks";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

const CADASTRAL_GENERAL_NAME = "kadastrovyj_nomer";

export const TextInput = ({ form, field }: Props): JSX.Element => {
	const { register, setValue, control, getValues } = form;
	const {
		placeholder,
		label,
		generalName,
		help,
		dataType: { name = "" },
	} = field;

	const watchValue = useWatch({
		name,
		control,
		defaultValue: getValues(name) || "",
	});

	const isCadastral = CADASTRAL_GENERAL_NAME === generalName;

	if (!name) {
		return <></>;
	}

	return (
		<div className={styles.inputContainer}>
			<div className={styles.inputLabel}>
				<span>{label}</span>

				{help.text ? (
					<Tooltip
						classNames={{
							root: styles.tooltipRoot,
							card: styles.tooltipCard,
						}}
					>
						{help.text}
					</Tooltip>
				) : null}
			</div>

			<div className={styles.inputWrapper}>
				<TransparentInput
					name={name}
					register={register}
					placeholder={placeholder || "Не указано"}
					mask={isCadastral ? cadastralCodeMask : undefined}
					setValueForMask={setValue}
					value={watchValue}
					guide={false}
				/>
			</div>
		</div>
	);
};
