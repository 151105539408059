import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/cancel-application/DEFAULT_MN";

type UseCancelApplication = {
  onSuccess?: () => void;
  onError?: () => void
};

export const useCancelApplication = ({ onSuccess, onError }: UseCancelApplication) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (id: string) => api.application.finishApplication(id),
    onSuccess,
    onError
  });

  return {
    mutate: mutation.mutate,
    mutateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };
};
