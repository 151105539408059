import { ReactNode } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	side: "left" | "right";
	speaker?: string;
	classNames?: {
		root?: string;
		dialog?: string;
	};
};

export const DialogShape = ({ children, side, speaker, classNames }: Props): JSX.Element => (
	<div className={cn(styles.root, classNames?.root)}>
		<div
			className={cn(styles.dialog, classNames?.dialog, {
				[styles.bordersWithLeftSide]: side === "left",
				[styles.bordersWithRightSide]: side === "right",
			})}
		>
			{children}
		</div>
		<div
			className={cn({
				[styles.dialogSideContainer]: side === "right",
			})}
		>
			<div
				className={cn(styles.shapeContainer, {
					[styles.shapeContainerLeft]: side === "left",
					[styles.shapeContainerRight]: side === "right",
				})}
			>
				<div
					className={cn(styles.shape, {
						[styles.shapeLeft]: side === "left",
						[styles.shapeRight]: side === "right",
					})}
				/>
			</div>
		</div>
		<div
			className={cn(styles.fromWho, {
				[styles.fromWhoLeftSide]: side === "left",
				[styles.fromWhoRightSide]: side === "right",
			})}
		>
			{speaker}
		</div>
	</div>
);
