import { ApplicationInListV2 } from "../../../../../types";
import cn from "classnames";
import styles from "./index.module.scss";
import { getPriceLabel } from "../../../../../utils/clientObjects";
import { CardApplicationContact } from "../card-application-contact/CardApplicationContact";
import { CardApplicationResponsible } from "../card-application-responsible/CardApplicationResponsible";
import { useMemo } from "react";
import { Popover } from "../../../Popover";
import { ReactComponent as OpenInNew } from "../../../../../assets/icons/open-in-new.svg";
import { ReactComponent as Success } from "../../../../../assets/icons/iconSuccess.svg";

type Props = {
  application: ApplicationInListV2;
  onCardClick?: ({id, isTemplate}) => void;
  classNames?: {
    root: string;
  };
  /** @deprecated */
  isTemplate?: boolean;
  options?: {
    canOpenANewTab?: boolean
    success?: boolean
  }
};

export const CardApplicationMini = (
  {
    application,
    onCardClick,
    classNames,
    options,
  }: Props): JSX.Element => {

  const {
    title,
    addressLabel,
    price,
    contact,
    responsibleUser,
    iAmResponsibleUser,
    stageNo,
    stage,
    type,
  } = application

  const getColor = useMemo(() => {
    switch (application.shortType.kind) {
      case 'preApplication':
        return "#D5D3CE";
      case  'application':
        return "#30CFB1"
      case 'deal':
        return "#336BEB"
      case "successDeal":
        return `${styles.linear}`
      default:
        return "#000"
    }
  }, [application]);

  const getColorTitle = useMemo(() => {
    switch (application.shortType.kind) {
      case 'preApplication':
        return "#9A9589";
      case  'application':
        return "#0FB092"
      case 'deal':
        return "#336BEB"
      case "successDeal":
        return `${styles.linear}`
      default:
        return "#000"
    }
  }, [application]);

  const getStage = useMemo(() => {
    switch (application.shortType.kind) {
      case 'preApplication':
        return "Предзаявка";
      case  'application':
        return "Заявка"
      case 'deal':
        return "Сделка"
      case "successDeal":
        return "Успешная сделка"
      default:
        return ""
    }
  }, [application]);

  const handleCardClick = () => {
    if (onCardClick && application) {
      onCardClick({
        id: application?.id,
        isTemplate: application?.isTemplate,
      });
    }
  };

  const onOpenInNewTab = () => {
    if (options?.canOpenANewTab) {
      window.open(`/leadsanddeals?tab=applications&id=${application.id}`, "_blank");
    }
  }

  const isInArchive = application.isArchive
  const titleText = isInArchive ? `Архив. ${title}` : title
  const isSuccess = application.shortType.kind === "successDeal"

  return (
    <div className={cn(styles.root, classNames?.root)} onClick={handleCardClick}>
      <div className={styles.flexRow}>
        <div className={cn(styles.flexColumn, {[styles.withoutStage]: application.shortType.kind !== "deal"})}>
          <div className={styles.titleAndPrice}>
            <div className={cn(styles.title, {[ styles.archived ]: isInArchive})}>
              <span style={{color: getColorTitle}}>{titleText}</span>
            </div>
            <div className={cn(styles.price, {[ styles.archived ]: isInArchive})}>
              {type.whoIsClient === "buyer" && "до"}
              {price && getPriceLabel({price})}
            </div>
          </div>
          <div>
            <div className={cn(styles.flexRow, styles.flexRowAlignStart, styles.address)}>
              <div className={cn(styles.addressLabel, {[ styles.archived ]: isInArchive})}>{addressLabel}</div>
            </div>
          </div>
          <div className={cn(styles.flexBetween, styles.flexRow)}>
            <CardApplicationContact contact={contact}/>
            <CardApplicationResponsible iAmResponsibleUser={iAmResponsibleUser} responsible={responsibleUser}
                                        classNames={styles.responsible}/>
          </div>
        </div>
        <div
          style={{background: (application.shortType.kind === "deal" ? getColor : "white")}}
          className={styles.stage}
          onClick={() => onOpenInNewTab()}
        >
          {options?.canOpenANewTab && <div className={cn({[styles.icon]: application.shortType.kind !== "deal"})}><OpenInNew/></div>}
          <Popover
            classNames={{over: styles.stagePopup}}
            overElement={
              <>
								<span className={styles.stageCircle} style={{background: getColor}}>
									{stageNo}
								</span>
                {`${getStage}. ${isInArchive ? "Архив." : ""} Этап ${stageNo}: ${stage}`}
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};