import { Condition } from "../../../../../../../../types";

export const getCondition = (isFromParser, id, forAdded) => {
  let conditionArray: Condition[] = []
  if (isFromParser) {
    conditionArray = forAdded === true
      ? [
        {
          "field": "hasAddedParserObjectId",
          "operator": "eq",
          "value": `${id}`
        }
      ]
      : [
        {
          "field": "isSituableForParserObjectId",
          "operator": "eq",
          "value": `${id}`
        }
      ]
    return conditionArray
  }
  conditionArray = forAdded === true
    ? [
      {
        "field": "hasAddedAgencyObjectId",
        "operator": "eq",
        "value": `${id}`
      }
    ]
    : [
      {
        "field": "isSituableForAgencyObjectId ",
        "operator": "eq",
        "value": `${id}`
      }
    ]
  return conditionArray
}