import { useEffect, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import cn from "classnames";
import { Tooltip } from "@sdelka_crm/sdelka-crm-component-library";

import { IndividualInputWrapperOptions, IndividualSaveWrapper } from "./Wrapper";

import { ReactComponent as SpinnerIcon } from "../../../../../assets/icons/spinner.svg";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/arrow-down.svg";
import { ReactComponent as Checkmark } from "../../../../../assets/icons/checkmark-pixeled.svg";
import styles from "./index.module.scss";

type InputProps = {
	name: string;
	placeholder?: string;
	defaultValue: string;
	isLoading: boolean;
	error?: string;
	className?: string;
	register: UseFormRegister<any>;
	options?: IndividualInputWrapperOptions;
	classNames?: {
		value?: string;
		input?: string;
	};
};

export const IndividualInput = ({
	name,
	placeholder,
	defaultValue,
	isLoading,
	error,
	className,
	register,
	options,
	classNames,
}: InputProps): JSX.Element => {
	const [canSave, setCanSave] = useState(false);

	return (
		<IndividualSaveWrapper
			defaultValue={defaultValue}
			isLoading={isLoading}
			className={className}
			options={{ ...options }}
			classNames={classNames}
		>
			<div className={styles.inputWrapper}>
				{error && (
					<div className={styles.errorIcon}>
						<Tooltip iconColor={{ unHovered: "red", hovered: "red" }}>{error}</Tooltip>
					</div>
				)}
				<input
					placeholder={placeholder}
					className={cn(
						styles.input,
						{
							[styles.errorInput]: error,
						},
						classNames?.input
					)}
					{...register(name, {
						setValueAs: (v) => {
							setCanSave(v !== defaultValue);

							return v;
						},
					})}
				/>
				{canSave && !isLoading && (
					<button type="submit" className={styles.submit}>
						<Checkmark />
					</button>
				)}
				{isLoading && (
					<div className={styles.spinnerContainer}>
						<SpinnerIcon />
					</div>
				)}
			</div>
		</IndividualSaveWrapper>
	);
};

type SelectProps = {
	name: string;
	defaultValue: string;
	options: { value: string | number; label: string }[];
	isLoading: boolean;
	error?: string;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
};

export const IndividualSelect = ({
	name,
	defaultValue,
	options,
	isLoading,
	error,
	register,
	setValue,
}: SelectProps): JSX.Element => {
	const [selected, setSelected] = useState(defaultValue);
	const [isListOpen, setListOpen] = useState(false);
	const selectField = register(name);

	useEffect(() => {
		setSelected(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		const element = document.getElementById(name);
		if (element) {
			(element as HTMLInputElement).value = selected as string;
			setValue(name, (element as HTMLInputElement).value);
		}
	}, [selected]);

	const selectItem = (value: string) => {
		setSelected(value);
		setListOpen(false);
	};

	return (
		<IndividualSaveWrapper
			isLoading={isLoading}
			defaultValue={options.find((item) => item.value === defaultValue)?.label || "Выберите..."}
		>
			<div className={styles.selectWrapper}>
				<select id={name} className={styles.htmlSelect} ref={selectField.ref}>
					{options.map((item) => (
						<option key={item.value} value={item.value}>
							{item.label}
						</option>
					))}
				</select>

				<div className={styles.customSelectWrapper}>
					<div
						className={cn(styles.customSelect, {
							[styles.errorCustomSelect]: error && defaultValue !== selected,
						})}
					>
						{error && defaultValue !== selected && !isListOpen && (
							<div className={styles.errorIcon}>
								<Tooltip iconColor={{ unHovered: "red", hovered: "red" }}>{error}</Tooltip>
							</div>
						)}
						<div className={styles.customOption}>
							<span className={styles.selectedOptionWrapper} onClick={() => setListOpen((prev) => !prev)}>
								{options.find((item) => item.value === selected)?.label}
							</span>
							{defaultValue !== selected && !isListOpen && !isLoading && (
								<button type="submit" className={styles.submit}>
									<Checkmark />
								</button>
							)}
							{defaultValue === selected && !isListOpen && !isLoading && (
								<div className={styles.arrowContainer} onClick={() => setListOpen((prev) => !prev)}>
									<ArrowIcon />
								</div>
							)}
							{isLoading && (
								<div className={styles.spinnerContainer}>
									<SpinnerIcon />
								</div>
							)}
						</div>
						{isListOpen && (
							<div>
								{options
									.filter((item) => item.value !== selected)
									.map((item) => (
										<div
											key={item.value}
											onClick={() => selectItem(item.value.toString())}
											className={cn(styles.customOption, styles.customOptionNotSelected)}
										>
											{item.label}
										</div>
									))}
							</div>
						)}
					</div>
				</div>
			</div>
		</IndividualSaveWrapper>
	);
};
