import { useDispatch } from "react-redux";
import styles from "./index.module.scss";
import { Button, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { closeModal } from "../../../../../../../service/redux/modules/modal/modal.action";
import cn from "classnames";
import { CalendarInPopup, CardObject, Time, toastError, toastSuccess } from "../../../../../../UI";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { UseMutateFunction } from "react-query";
import { AxiosResponse } from "axios";
import { Payload } from "../../../../../../../service/api/mutations/shows-views/useCreateView";
import { useLoadApplications } from "../../../../../../../service/api/queries";
import { CardApplication } from "../../../../../../UI/Card/card-application";
import { useGetCompilationObjects } from "../../../../../../../service/api/mutations";
import { RealEstate } from "../../../../../../../types";
import { Plug } from "../../../plug/Plug";


type Props = {
  onCreate: UseMutateFunction<AxiosResponse<any>, unknown, Payload, unknown>
  fromObject?: boolean
  applicationId?: string
  parserObjectId?: string;
  clientObjectApplicationId?: string;
}

export const AssignViewModal = (
  {
    onCreate,
    fromObject,
    parserObjectId,
    clientObjectApplicationId,
    applicationId,
  }: Props): JSX.Element => {
  const createText = "просмотр"
  const dispatch = useDispatch()

  const {list, isLoading} =
    useLoadApplications({defaultFilter: {viewForObjectApplicationId: applicationId}});
  const {data, isLoading: isLoadingObjects, mutate} = useGetCompilationObjects({applicationId: applicationId ?? ""})

  useEffect(() => {
    mutate()
  }, [])

  const {register, getValues, setValue, control} = useForm();

  function timeToMilliseconds(timeStr?: string) {
    if (timeStr === undefined) {
      return 0
    }
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  }

  const [time, setTime] = useState("")
  const date1 = new Date(getValues("deadlineDate"));
  const dateError = !getValues("deadlineDate") ? "Выберите дату" : undefined
  const date2 = new Date(timeToMilliseconds(time));
  const timeError = +time < 60000 ? "Выберите время" : undefined
  const sumMilliseconds = date1.getTime() + date2.getTime();
  const sumDate = new Date(sumMilliseconds);

  const [selectedObjectId, setSelectedObjectId] = useState<string | undefined>(undefined)
  const [selectedParserObjectId, setSelectedParserObjectId] = useState<string | undefined>(undefined)
  const [hasError, setHasError] = useState(false)
  const [selectedApplicationId, setSelectedApplicationId] = useState<string>('')

  const payload: Payload =
    {
      applicationId: selectedApplicationId,
      date: sumDate,
      parserId: parserObjectId,
      clientObjectId: clientObjectApplicationId
    }

  const payloadFromApplication: Payload = {
    applicationId: applicationId ?? "",
    date: sumDate,
    parserId: selectedParserObjectId,
    clientObjectId: selectedObjectId
  }

  const labelText = fromObject ? "Выберите заявку на покупку" : "Выберите объект в подборке"
  const finalPayload = fromObject ? payload : payloadFromApplication

  const handleSelectObject = (parserData?, object?: RealEstate) => {
    if (object?.id === selectedObjectId) {
      if (parserData) {
        setSelectedParserObjectId(undefined)
      }
      setSelectedObjectId(undefined)
    }
    if (parserData) {
      setSelectedParserObjectId(object?.id)
    }
    setSelectedObjectId(object?.applicationId)
  }

  const handleSelectApp = (id?: string) => {
    if (id === selectedApplicationId) {
      setSelectedApplicationId('')
    }
    setSelectedApplicationId(id ?? '')
  }

  const onApply = () => {
    if (dateError === "Выберите дату") {
      setHasError(true)
    }
    if (dateError === undefined) {
      setHasError(false)
      onCreate(finalPayload)
      toastSuccess({text: `Вы успешно создали ${createText}`})
      dispatch(closeModal())
    }
  }
  const onDeny = () => {
    toastError({text: `Вы не создали ${createText}`})
    dispatch(closeModal())
  }

  const dateName = "deadlineDate";
  const currentDate = useWatch({
    name: dateName,
    control,
    defaultValue: getValues(dateName),
  });

  const timeName = "deadlineTime";
  const currentTime = useWatch({
    name: timeName,
    control,
    defaultValue: getValues(timeName),
  });

  useEffect(() => {
    setTime(currentTime)
    if (dateError === undefined) {
      setHasError(false)
    }
  }, [currentTime, dateError])

  return (
    <>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.title}>
            {`Назначение ${createText}а`}
          </div>
          <div className={styles.text}>
            Выберите дату показа, после этого вы сможете добавить контакт
          </div>
        </div>
      </div>
      <InfoRow
        classNames={{
          root: cn(styles.infoRow, styles.calendarChild),
          content: styles.flexRow,
        }}
        labelFont="ubuntu"
        label="Дата и время просмотра"
      >
        <CalendarInPopup
          form={{name: dateName, register, setValue, getValues, required: true}}
          defaultDate={currentDate}
          classNames={{
            wrapper: styles.calendar,
            label: styles.calendarLabel,
            trigger: styles.popupTrigger,
            error: styles.calendarError
          }}
          error={hasError ? dateError : undefined}
        />
        <Time
          classNames={{
            root: styles.timeRoot,
            wrapper: styles.timeWrapper,
            inputs: styles.timeInputs,
            error: styles.timeError,
          }}
          variant="noLabel"
          name={timeName}
          onChange={(value) => setTime(value)}
          register={register}
          value={currentTime}
          error={timeError}
        />
      </InfoRow>
      <div className={styles.warningContainer}>
        <div className={styles.title}>
          Внимание:
        </div>
        <div className={styles.warning}>
          При назначении показа СДЕЛКА автоматически создаст "Предзаявку на покупку" с просмотром по данному объекту.
          Если вы хотите "назначить показ" для уже созданного покупателя, то найдите и выберите его предзаявку или заявку в списке.
        </div>
      </div>
      <div className={styles.addLabel}>
        {labelText}
      </div>
      {fromObject
        ? <div>
          {!isLoading && <div className={styles.applications}>
            {list.map((app) => (
              <div
                key={app.id}
                onClick={() => handleSelectApp(app.id)}
                className={cn(styles.application, {[ styles.selectedCard ]: app.id === selectedApplicationId})}>
                <CardApplication
                  application={app}
                  withStage
                  canOpenANewTab
                  // classNames={{
                  //   root: styles.appFlexRow
                  // }}
                />
              </div>
            ))}
          </div>
          }
        </div>
        : <div>
          {!isLoadingObjects &&
            <>
              {data && data.length > 0 ?
                <div className={styles.objects}>
                {data && data.map((object) => (
                  <div
                    key={object.id}
                    className={cn(styles.object,
                      {
                        [ styles.selectedObjectParserCard ]: object.id === selectedParserObjectId,
                        [ styles.selectedObjectCard ]: (object.parserData === undefined && object.applicationId === selectedObjectId)
                      })}
                    onClick={() => handleSelectObject(object.parserData, object)}
                  >
                    <CardObject
                      clientObject={object}
                      id={object.id}
                      variant="standard"
                    />
                  </div>
                ))}
              </div>
              : <>
                  <Plug plugText={"Нет объектов"}/>
                </>
              }
            </>

          }
        </div>
      }
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button
          onClick={onApply}
          classNames={{root: styles.buttonCreate}}
          disabled={hasError}
        >
          {`Создать ${createText}`}
        </Button>
      </div>
    </>
  )
}