import { Dispatch, SetStateAction, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { toastError } from "../../../index";

import { ReactComponent as Plus } from "../../../../../assets/icons/plus.svg";
import styles from "./index.module.scss";

type Props = {
	files: Array<File & { preview: string }>;
	setFiles: Dispatch<
		SetStateAction<
			(File & {
				preview: string;
				photoType?: "photo" | "plan";
			})[]
		>
	>;
};

export const DroppableZone = ({ files, setFiles }: Props): JSX.Element => {
	const createDropzone = (photoType?: "photo" | "plan") =>
		useDropzone({
			accept: "image/*",
			onDrop: (acceptedFiles: File[]) => {
				const acceptedSizes = acceptedFiles.filter((file) => {
					if (file.size > 5000000) {
						toastError({
							text: `Невозможно загрузить ${file.name}. Картинка должна весить менее 5мб`,
						});
					}

					return file.size <= 5000000;
				});

				setFiles(
					files.concat(
						acceptedSizes.map((file) =>
							Object.assign(file, {
								preview: URL.createObjectURL(file),
								photoType: photoType || "photo",
							})
						)
					)
				);
			},
		});

	const appartmentDropzone = createDropzone();
	const planDropzone = createDropzone("plan");

	useEffect(
		() => () => {
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		},
		[files]
	);

	useEffect(() => {
		const borderWhite = (dropElement: HTMLElement) => {
			// eslint-disable-next-line no-param-reassign
			dropElement.style.border = "1px dashed #fff";
		};

		const borderBlue = (dropElement: HTMLElement) => {
			// eslint-disable-next-line no-param-reassign
			dropElement.style.border = "1px dashed #7f8def";
		};

		const dropzoneApartment = document.getElementById("#dropzoneApartment");
		const dropzonePlan = document.getElementById("#dropzonePlan");

		dropzoneApartment?.addEventListener("dragover", () => borderWhite(dropzoneApartment));
		dropzoneApartment?.addEventListener("dragleave", () => borderBlue(dropzoneApartment));

		dropzonePlan?.addEventListener("dragover", () => borderWhite(dropzonePlan));
		dropzonePlan?.addEventListener("dragleave", () => borderBlue(dropzonePlan));

		// eslint-disable-next-line arrow-body-style
		return () => {
			dropzoneApartment?.removeEventListener("dragover", () => borderWhite(dropzoneApartment));
			dropzoneApartment?.removeEventListener("dragleave", () => borderBlue(dropzoneApartment));

			dropzonePlan?.removeEventListener("dragover", () => borderWhite(dropzonePlan));
			dropzonePlan?.removeEventListener("dragleave", () => borderBlue(dropzonePlan));
		};
	}, []);

	return (
		<>
				<div
					id="#dropzoneApartment"
					{...appartmentDropzone.getRootProps({
						className: styles.dropzoneApartment,
					})}
				>
					<input {...appartmentDropzone.getInputProps()} />

					<div className={styles.label}>
						<Plus />
						<p>Добавить фото квартиры</p>
					</div>

					<div className={styles.description}>Не более 5 мб. JPG, PNG, GIF</div>
				</div>

				<div id="#dropzonePlan" {...planDropzone.getRootProps({ className: styles.dropzonePlan })}>
					<input {...planDropzone.getInputProps()} />

					<div className={styles.label}>
						<Plus />
						<p>Добавить тех. план</p>
					</div>

					<div className={styles.description}>Не более 5 мб. JPG, PNG, GIF</div>
				</div>
			</>
	);
};
