import { NoData } from "@sdelka_crm/sdelka-crm-component-library";
import { EditModeFromTaskModal, FinishModeFromTaskModal, ViewModeFromTaskModal } from "./mode";
import { useDisplayFetching } from "./helpers";
import { TaskModalContextProvider, useTaskModalContext } from "../../../../service/context/TaskModalContext";
import { TaskModalMode } from "../../../../types";
import { TaskCard } from "../../../pages/Tasks/task/Task";

const ModeRenderMap: Record<TaskModalMode, () => JSX.Element> = {
  view: () => <ViewModeFromTaskModal/>,
  finish: () => <FinishModeFromTaskModal/>,
  edit: () => <EditModeFromTaskModal/>,
};

const TaskInProvider = (
  {refetchParent, isLast}
) => {
  const {task, refetch, isFetching, mode} = useTaskModalContext();

  useDisplayFetching({isFetching});

  if (!task) {
    return (
      <NoData onRefetch={refetch}/>
    );
  }

  return (
    <div>
    <TaskCard task={task} refetchParent={refetchParent} refetch={refetch} isLast={isLast}/>
  </div>
  );
};

type Props = {
  id: string;
  onUpdatedTask: () => void;
  refetchParent?: () => void
  isLast?: boolean
};

export const TaskModal = ({id, onUpdatedTask, refetchParent, isLast}: Props): JSX.Element => (
  <TaskModalContextProvider id={id} onUpdatedTask={onUpdatedTask}>
    <TaskInProvider refetchParent={refetchParent} isLast={isLast}/>
  </TaskModalContextProvider>
);
