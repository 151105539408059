import { Conditions, DefaultTasksFiltersType } from "../../../../types";

export const DEFAULT_TASKS_FILTERS: DefaultTasksFiltersType = {
	taskType: [],
	taskSubType: [],
	responsibleUserId: [],
	creatorId: [],
	participantUserId: [],
	executorUserId: [],
	createdAtFrom: "",
	createdAtTo: "",
	startAtFrom: "",
	startAtTo: "",
	deadlineFrom: "",
	deadlineTo: "",
	priority: false,
	hot: false,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getTasksFilters = (search: DefaultTasksFiltersType) => {
	const searchConditions: Conditions = [];

	if (search?.taskType) {
		searchConditions.push({
			field: "taskType",
			operator: "in",
			value: search?.taskType,
		});
	}

	if (search?.taskSubType) {
		searchConditions.push({
			field: "taskSubType",
			operator: "in",
			value: search?.taskSubType,
		});
	}

	if (search?.variant) {
		searchConditions.push({
			field: "status",
			operator: "in",
			value: search?.variant,
		});
	}

	if (search?.requiresAttention) {
		searchConditions.push({
			field: "isAttentionRequired",
			operator: "eq",
			value: "true",
		});
	}

	if (search?.responsibleUserId) {
		searchConditions.push({
			field: "responsibleUserId",
			operator: "in",
			value: search?.responsibleUserId.map((user) => user.value),
		});
	}

	if (search?.creatorId) {
		searchConditions.push({
			field: "creatorId",
			operator: "in",
			value: search?.creatorId.map((user) => user.value),
		});
	}

	if (search?.participantUserId) {
		searchConditions.push({
			field: "participantUserId",
			operator: "in",
			value: search?.participantUserId.map((user) => user.value),
		});
	}

	if (search?.executorUserId) {
		searchConditions.push({
			field: "executorUserId",
			operator: "in",
			value: search?.executorUserId.map((user) => user.value),
		});
	}

	if (search?.status) {
		searchConditions.push({
			field: "leftMenu",
			operator: "in",
			value: search?.status,
		});
	}

	if (search?.byMyRole) {
		searchConditions.push({
			field: "topTab",
			operator: "eq",
			value: search?.byMyRole,
		});
	}

	if (search?.deadlineFrom) {
		searchConditions.push({
			field: "deadline",
			operator: "gte",
			value: search?.deadlineFrom,
		});
	}

	if (search?.deadlineTo) {
		searchConditions.push({
			field: "deadline",
			operator: "leq",
			value: search?.deadlineTo,
		});
	}

	if (search?.createdAtFrom) {
		searchConditions.push({
			field: "createdAt",
			operator: "gte",
			value: search?.createdAtFrom,
		});
	}

	if (search?.createdAtTo) {
		searchConditions.push({
			field: "createdAt",
			operator: "leq",
			value: search?.createdAtTo,
		});
	}

	if (search?.startAtFrom) {
		searchConditions.push({
			field: "startAt",
			operator: "gte",
			value: search?.startAtFrom,
		});
	}

	if (search?.startAtTo) {
		searchConditions.push({
			field: "startAt",
			operator: "leq",
			value: search?.startAtTo,
		});
	}

	if (search?.priority) {
		searchConditions.push({
			field: "priority",
			operator: "eq",
			value: "important",
		});
	}

	if (search?.applicationId) {
		searchConditions.push({
			field: "applicationId",
			operator: "eq",
			value: search?.applicationId,
		});
	}

	if (search?.connectedPreApplicationId) {
		searchConditions.push({
			field: "connectedPreApplicationId",
			operator: "eq",
			value: search?.connectedPreApplicationId ,
		});
	}

	if (search?.connectedApplicationId) {
		searchConditions.push({
			field: "connectedApplicationId",
			operator: "eq",
			value: search?.connectedApplicationId,
		});
	}

	if (search?.stage && !search?.stage.includes("all")) {
		searchConditions.push({
			field: "stage",
			operator: "in",
			value: search?.stage,
		});
	}

	if (search?.communicationsForLeadId) {
		searchConditions.push({
			field: "communicationsForLead",
			operator: "eq",
			value: search?.communicationsForLeadId
		})
	}

	return searchConditions;
};
