import { useDispatch } from "react-redux";
import { TargetObjectToLead, LeadTargetPinPayload, LeadTargetPinType } from "../../../types";

import {
	closeModal,
	activateLoading,
	deactivateLoading,
} from "../../../service/redux/modules/modal/modal.action";
import { useTargetApplication, useTargetObject } from "../../../service/api/mutations";
import { toastError, toastSuccess } from "../../../components/UI";

type Props = {
	refetch: () => void;
	makeNotEditMode: () => void;
	leadId: string;
};

type Return = {
	onPinSelected: (pinPayload: LeadTargetPinPayload) => void;
};

type UsePinGoal = (props: Props) => Return;

export const usePinGoal: UsePinGoal = ({ leadId, refetch, makeNotEditMode }) => {
	const dispatch = useDispatch();

	const onSuccess = () => {
		refetch();
		makeNotEditMode();
		dispatch(deactivateLoading());
		dispatch(closeModal());
		const SUCCESS_TEXT = "Цель лида прикреплена";
		toastSuccess({ text: SUCCESS_TEXT });
	};

	const onError = () => {
		dispatch(deactivateLoading());
		const ERROR_TEXT = "Не удалось прикрепить цель лида";
		toastError({ text: ERROR_TEXT });
	};

	const { mutate: mutateTargetObject } = useTargetObject({
		onSuccess,
		onError,
	});

	const onPinSelected = (pinPayload: LeadTargetPinPayload) => {
		if (pinPayload.type === LeadTargetPinType.OBJECT) {
			const payload: TargetObjectToLead = {
				leadId,
				objectId: pinPayload.entityId,
			};
			dispatch(activateLoading());
			mutateTargetObject(payload);
			dispatch(closeModal())
		}
	};

	return {
		onPinSelected,
	};
};
