import { useMemo } from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";

import { DoubleInput, InfoRow, PopupObjectType, TransparentInput, Select } from "../../../../../UI";
import { useContacts } from "../../../../../../service/api/queries";
import { prepareShortUserOutput } from "../../../../../../utils/transformation";
import { commonPlaceholders } from "../../../../../../utils/placeholders";
import { phoneMask } from "../../../../../../utils/masks";

type Props = {
	control: Control<any>;
	register: UseFormRegister<any>;
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
};

export const ObjectInfo = ({ control, register, setValue, getValues }: Props): JSX.Element => {
	const { contactsList } = useContacts({});

	const [
		categoryName,
		priceFromName,
		priceToName,
		priceMFromName,
		priceMToName,
		ownerPhoneName,
		ownerIdName,
	] = ["category", "priceFrom", "priceTo", "priceMFrom", "priceMTo", "ownerPhone", "ownerId"];

	const [
		watchCategory,
		watchPriceFrom,
		watchPriceTo,
		watchPriceMFrom,
		watchPriceMTo,
		watchOwnerPhone,
		watchOwnerId,
	] = [
		useWatch({
			name: categoryName,
			control,
			defaultValue: getValues(categoryName) || [],
		}),
		useWatch({
			name: priceFromName,
			control,
			defaultValue: getValues(priceFromName) || "",
		}),
		useWatch({
			name: priceToName,
			control,
			defaultValue: getValues(priceToName) || "",
		}),
		useWatch({
			name: priceMFromName,
			control,
			defaultValue: getValues(priceMFromName) || "",
		}),
		useWatch({
			name: priceMToName,
			control,
			defaultValue: getValues(priceMToName) || "",
		}),
		useWatch({
			name: ownerPhoneName,
			control,
			defaultValue: getValues(ownerPhoneName) || "",
		}),
		useWatch({
			name: ownerIdName,
			control,
			defaultValue: getValues(ownerIdName) || "",
		}),
	];

	const contactsOption = useMemo(
		() =>
			contactsList.map((contact) => ({
				label: prepareShortUserOutput(contact),
				value: contact.id,
			})),
		[contactsList]
	);

	return (
		<>
			<InfoRow label="Тип объекта" labelFont="ubuntu">
				<PopupObjectType
					variant="light"
					register={register}
					setValue={setValue}
					defaultValues={watchCategory}
				/>
			</InfoRow>

			<InfoRow label="Цена ₽" labelFont="ubuntu">
				<DoubleInput
					size="extraLarge"
					register={register}
					placeholders={[commonPlaceholders.notSpecified, commonPlaceholders.notSpecified]}
					setValue={setValue}
					names={["priceFrom", "priceTo"]}
					value={[watchPriceFrom, watchPriceTo]}
				/>
			</InfoRow>

			<InfoRow label="Цена ₽/м2" labelFont="ubuntu">
				<DoubleInput
					size="extraLarge"
					register={register}
					placeholders={[commonPlaceholders.notSpecified, commonPlaceholders.notSpecified]}
					names={["priceMFrom", "priceMTo"]}
					value={[watchPriceMFrom, watchPriceMTo]}
					setValue={setValue}
				/>
			</InfoRow>

			<InfoRow label="Номер собственника" labelFont="ubuntu">
				<TransparentInput
					placeholder="+7 ХХХ ХХХ ХХ-ХХ"
					name="ownerPhone"
					register={register}
					withCleanUp={{ setValue }}
					mask={phoneMask}
					setValueForMask={setValue}
					variant="noPadding"
					value={watchOwnerPhone}
				/>
			</InfoRow>

			<InfoRow label="Контакт собственника" labelFont="ubuntu">
				<Select
					name="ownerId"
					setValue={setValue}
					options={contactsOption}
					register={register}
					placeholder="Выбрать"
					defaultValue={watchOwnerId}
				/>
			</InfoRow>
		</>
	);
};
