import { H4, P } from "@sdelka_crm/sdelka-crm-component-library";

import { useGetPresentations } from "../../../../../../service/api/queries";
import { useDeletePresentation } from "../../../../../../service/api/mutations";
import { useCopyText } from "../../../../index";
import { countTimeLeft, createLink } from "../../helpers";

import { ReactComponent as CloseIcon } from "../../../../../../assets/icons/close.svg";
import { ReactComponent as CopyIcon } from "../../../../../../assets/icons/copy-icon.svg";
import styles from "./index.module.scss";

type Props = {
	id: string;
};

export const PresentationsList = ({ id }: Props): JSX.Element => {
	const { presentations, refetch } = useGetPresentations({ id });
	const { mutate, isLoading } = useDeletePresentation({
		onSuccess: () => refetch(),
	});
	const { onCopy } = useCopyText({});

	const handleDeletePresentation = (idForDelete: string) => {
		if (!isLoading) {
			mutate(idForDelete);
		}
	};

	return (
		<div>
			{presentations.length ? (
				<>
					<H4>Созданные презентации</H4>

					{presentations?.map((presentation) => (
						<div key={presentation.id} className={styles.linkBlock}>
							<P variant="large" classNames={{ root: styles.rootP }}>
								{createLink(presentation.id)}
							</P>

							<div className={styles.timeLeftBlock}>
								<P variant="medium" classNames={{ root: styles.timeLeft }}>
									{`Осталось ${countTimeLeft(new Date(presentation.createdAt))}`}
								</P>
							</div>

							<div className={styles.actions}>
								<div className={styles.action} onClick={() => onCopy(createLink(presentation.id))}>
									<CopyIcon />
								</div>

								<div className={styles.action} onClick={() => handleDeletePresentation(presentation.id)}>
									<CloseIcon className={styles.closeIcon} />
								</div>
							</div>
						</div>
					))}
				</>
			) : null}
		</div>
	);
};
