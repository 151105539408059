export const openInNewWindow = (url: string): void => {
	const link = url;

	const a = document.createElement("a");
	a.setAttribute("href", link);
	a.setAttribute("target", "_blank");
	a.setAttribute("rel", "noreferrer noopener");
	a.click();
	a.remove();
};
