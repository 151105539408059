import type { AddressValue, ApplicationInList, CallData, Company, LocationType, User, UserPreview } from "../index";

export type Phone = {
  id?: string;
  type: string;
  phone: string;
  companyId?: string;
  contactId?: string;
};

export type ContactPreview = {
  id: string;
  name: string;
  secondName: string;
  lastName: string;
  pseudonym: string;
  type: string;
  otherType?: string;
  subtype?: string;
  otherSubtype?: string;
  phone?: string;
  comment?: string;
};
export type Kind = {
  businessProcessTitle: string;
  kind: string;
  stage: string;
  stageNo: number;
};

export type Contact = Omit<ContactPreview, "phone"> & {
  address?: string;
  sex: "male" | "female";
  dob: string;
  origin?: string;
  email: string;
  createdAt: Date;
  companyId: string;
  userId: string;
  phone?: string;
  phones?: Phone[];
  company?: Company;
  user?: User;
  applications?: ApplicationInList[];
  addressLabel?: string;
  addressId?: string;
  location?: LocationType;
  access: UserPreview[];
  relatedKinds?: {
    applications: Kind[];
    deals: Kind[];
    preApplications: Kind[];
  };
  tabsNewEvents?: {
    cian: boolean,
    avito: boolean,
    companySite: boolean,
  }
  callData?: {
    type: "in" | "out" | "chat";
    status: "success" | "missed" | "busy" | "not_available";
    start: Date;
  }
  responsibleUser?: UserPreview
  iAmResponsibleUser?: boolean
  lastCommunication?: {
    type: "in" | "out" | "chat";
    status: "success" | "missed" | "busy" | "not_available";
    start: Date;
  } | null
  passportInfo?: PassportInfo
};

export type PassportInfo = {
  series: string
  passportNumber: string
  dateOfIssue: string
  issuedBy: string
  issuedByCode: string
  registerAddress: string
}

export enum ContactHistoryTabs {
  calls = "calls",
  chatCian = "chatCian",
  chatAvito = "chatAvito",
  chatCompany = "chatCompany"
}

export type Duration = {
  duration: '2_WEEKS' | '1_MONTH' | '2_MONTH' | '3_MONTH';
}

export enum AgencyAgreementStatusEnum {
  unsigned = 'unsigned',
  oral = 'oral',
  offline = 'offline',
  waitOnline = 'waitOnline',
  signedOnline = 'signedOnline',
  done = 'done'
}

export type AgencyAgrementType = {
  id: string;
  clientRequestedContractTermination?: boolean;
  shortId: string;
  form: 'ORAL' | 'ONLINE' | "OFFLINE";
  type: 'ADVERTISING' | 'EX' | "FIND" | "LOYAL";
  duration: '2_WEEKS' | '1_MONTH' | '2_MONTH' | '3_MONTH';
  prolongation: "YES" | "NO";
  contact?: Contact;
  objectPrice?: number;
  comissionSum?: number;
  comissionPercent?: number;
  counterCommission?: number;
  status: AgencyAgreementStatusEnum
  sentSMS?: boolean;
  sentAct?: boolean;
  terminationRequested?: boolean;
  validUntil?: Date;
  validFrom?: Date;
  whoIsClient?: 'seller' | 'buyer';
  agreementLink?: string;
  downloadActLink?: string;
  downloadAgreementLink?: string;
  files?: string[]
  callDataId?: string
  callData?: CallData
}
export type AgencyAgrementPayloadType = {
  form: 'ORAL' | 'WRITTEN';
  type: 'ADVERTISING' | 'EX';
  duration: '2_WEEKS' | '1_MONTH' | '2_MONTH' | '3_MONTH';
  prolongation: "YES" | "NO";
  comissionSum?: number;
  comissionPercent?: number;
  counterCommission?: number;
}
export type ContactList = {
  data: Contact[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
};

export type ContactToObject = {
  id: string;
  jointType: string;
  contactId: string;
  clientObjectId: string;
  contact: Contact;
};

export type ChatAvitoItem = {
  id: string
  author_id: string
  created: Date
  content: {
    text: string
  }
  type: string
  direction: "in" | "out"
  isRead: boolean
  read: Date
}

export type ContactToObjectList = {
  data: ContactToObject[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
};

export type ContactPinToObject = {
  jointType: string;
  contactId: string;
  clientObjectId: string;
};

export type ContactFilter = {
  phone?: string;
  lastName?: string;
  name?: string;
  secondName?: string;
  hideThisIds?: string[];
  type?: string | string[];
  otherType?: string;
  email?: string;
  parents?: {
    childId?: string;
  };
  pseudonym?: string;
  children?: {
    parentId?: string;
  };
  fullTextSearch?: string;
  responsibles?: string[];
  department?: string[];
  withApplication?: boolean;
  withObject?: boolean;
};

export type ContactCreate = {
  name: string;
  secondName: string;
  lastName: string;
  type: string;
  otherType?: string;
  phones: Omit<Phone, "id" | "companyId" | "contactId">[];
  email: string;
  comment?: string;
  pseudonym?: string;
  passportInfo?: PassportInfo
};

export type ContactUpdate = Omit<Contact, "id" | "createdAt" | "sex" | "companyId" | "userId" | "access">;

export type CreateContactPayload = {
  name: string;
  secondName: string;
  lastName: string;
  mainPhone: string;
  extraPhone: string;
  type: string;
  otherType?: string;
  dob: string;
  email: string;
  pseudonym: string;
  addressObject: AddressValue;
  passportInfo?: PassportInfo
};

export type UpdateContactPayload = Omit<Contact, "id"> & {
  addressObject: AddressValue;
};

export type ContactSort = {
  createdAt?: "ASC" | "DESC";
  subtype?: "ASC" | "DESC";
};

export type CreateContactForm = {
  phones: Pick<Phone, "phone" | "type">[];
  addressId?: string;
  addressLabel?: string;
} & Omit<CreateContactPayload, "extraPhone" | "mainPhone" | "addressObject">;

export type CreateContactScheme = {
  name?: string;
  secondName?: string;
  lastName?: string;
} & Pick<CreateContactPayload, "email" | "mainPhone" | "extraPhone">;

export const ContactTypeMap = {
  agency: "агентство недвижимости",
  customer: "клиент",
  personal: "личный",
  other: "другое",
};

export const ContactSubtypeMap = {
  buyer: "покупатель",
  seller: "продавец",
  investor: "инвестор",
  tenant: "съемщик",
  lessor: "арендодатель",
  estate_agent: "риэлтор",
  agency_manager: "руководитель АН",
  lawyer: "юрист",
  sales_manager: "РОП",
  notary: "нотариус",
  contractor: "подрядчик",
  estimator: "оценщик",
  other: "другое",
};

export enum ExistContact {
  existInCompany = "existInCompany",
  existsInMyContacts = "existsInMyContacts",
  notFound = "notFound",
}

export type FindContactByPhoneResponse = FindContactByPhoneResponseExist | FindContactByPhoneResponseNotExist;

export type FindContactByPhoneResponseRelations = {
  deals?: number;
  applications?: number;
  leads?: number;
};
export type FindContactByPhoneResponseExist = {
  result: ExistContact.existInCompany | ExistContact.existsInMyContacts;
  contact: Contact;
  relations: FindContactByPhoneResponseRelations;
};

export type FindContactByPhoneResponseNotExist = {
  result: ExistContact.notFound;
  contact: undefined;
  relations: undefined;
};
