import { CSSProperties } from "react";
import { ColumnInstance } from "react-table";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import cn from "classnames";

import { ReactComponent as Bar } from "../../../../../../../assets/icons/bar.svg";
import { ReactComponent as FilledCheckbox } from "../../../../../../../assets/icons/filled-checkbox.svg";
import styles from "./index.module.scss";

type Props = {
	column: ColumnInstance;
	index: number;
	activeId: string | undefined;
	updateColumn: (index: number, column: ColumnInstance) => void;
};

export const SortableItem = ({ column, index, activeId, updateColumn }: Props): JSX.Element => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: column.id });

	const itemStyle: CSSProperties = {
		transform: CSS.Transform.toString(transform),
		transition: transition as string,
	};

	return (
		<div
			style={itemStyle}
			ref={setNodeRef}
			{...attributes}
			className={cn(styles.root, {
				[styles.rootActive]: column.id === activeId,
			})}
		>
			<div className={styles.content}>
				<label className={styles.label}>
					<input
						type="checkbox"
						className={styles.checkboxHide}
						onClick={() => {
							column.toggleHidden();
							updateColumn(index, column);
						}}
					/>

					{column.isVisible ? (
						<FilledCheckbox className={styles.checkboxFilled} />
					) : (
						<span className={styles.checkboxEmpty} />
					)}

					<span>{column.Header}</span>
				</label>

				<div className={styles.bar} {...listeners}>
					<Bar />
				</div>
			</div>
		</div>
	);
};
