import { Conditions, LeadFiltersType, LeadStatusEnum } from "../../../../types";

export const DEFAULT_LEAD_FILTERS = {
	responsibleUsers: [],
	createdAtFrom: "",
	createdAtTo: "",
	status: [ LeadStatusEnum.in_progress ],
	source: [],
};

export const getLeadFilters = (search: LeadFiltersType): Conditions => {
	const searchConditions: Conditions = [];

	if (search?.fullTextSearch) {
		searchConditions.push({
			field: "fullTextSearch",
			operator: "like",
			value: search.fullTextSearch,
		});
	}

	if (search?.source) {
		searchConditions.push({
			field: "source",
			operator: "in",
			value: search.source,
		});
	}

	if (search?.responsibleUsers) {
		searchConditions.push({
			field: "responsibleUserId",
			operator: "in",
			value: search.responsibleUsers,
		});
	}

	if (search?.createdAtFrom) {
		searchConditions.push({
			field: "createdAt",
			operator: "gte",
			value: search.createdAtFrom,
		});
	}

	if (search?.createdAtTo) {
		searchConditions.push({
			field: "createdAt",
			operator: "lte",
			value: search?.createdAtTo,
		});
	}

	if (search?.status?.length) {
		searchConditions.push({
			field: "status",
			operator: "in",
			value: [...search.status],
		});
	}

	return searchConditions;
};
