import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/company/load-company/DEFAULT_QN";

type UseLoadCompany = {
	enabled?: boolean;
};

export const useLoadCompany = ({ enabled = true }: UseLoadCompany) => {
	const loadCompany = () => api.companies.get();

	const { data, isLoading, refetch } = useQuery(DEFAULT_QN, loadCompany, {
		enabled,
	});

	return {
		company: data?.data,
		isLoading,
		refetch,
	};
};
