import { useHistory } from "react-router-dom";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { useSelector } from "../../../../../service/redux/useSelector";
import { paths } from "../../../../../utils/paths";

import { ReactComponent as CheckMark } from "../../../../../assets/icons/check-mark.svg";
import styles from "./index.module.scss";

export const AcceptedInvitation = (): JSX.Element => {
	const { companyName } = useSelector((state) => state.regSteps);
	const title = `Вы успешно присоединились к компании ${companyName ? `«${companyName}»` : ""}`;
	const { push } = useHistory();

	return (
		<div className={styles.root}>
			<CheckMark />
			<h2>{title}</h2>
			<div className={styles.noParagraph} />

			<Button onClick={() => push(paths.dashboardPath)}>Продолжить</Button>
		</div>
	);
};
