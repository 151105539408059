import { useGetVideoLessonsList } from "./helpers";
import styles from "./index.module.scss"
import { SearchInput } from "../../../../../collections-modal/add-to-collection/helpers";
import { useEffect, useState } from "react";
import cn from "classnames";
import { Button, Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { CardVideoLesson } from "../../../../../Card";
import { useDebouncedCallback } from "use-debounce";


export const VideoLessonsModal = (): JSX.Element => {
  const [searchValue, setSearchValue] = useState("")

  const {list, isLoading, isFetching, refetch} = useGetVideoLessonsList({defaultFilter: {fullTextSearch: searchValue}})

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
  }

  const debouncedSearch = useDebouncedCallback(handleSearchChange, 500);

  useEffect(() => {
    debouncedSearch(searchValue)
    refetch()
  }, [searchValue])

  return (
    <div className={styles.root}>
      <div className={styles.title}>Видео-инструкции</div>
      <div className={styles.search}>
        <SearchInput
          name="Поиск"
          placeholder="Поиск"
          prepend="search"
          onChange={debouncedSearch}
        />
      </div>
      {isLoading
        ? <div
          className={cn(styles.loading, "animate__animated", "animate__faster", "animate__fadeIn", {
            [ styles.loadingHide ]: !isFetching,
          })}
        >
          <Loader/>
        </div>
        : <>
          <div className={styles.subTitle}>
            Выберите видео о данной странице
          </div>
          <div className={styles.listWrapper}>
            <div className={styles.list}>
              {list && list.length > 0
                ? list.map((item) => (
                  <div
                    key={item.title}
                    className={styles.item}
                  >
                    <CardVideoLesson
                      lesson={item}
                    />
                  </div>
                ))
                : <div className={styles.noFound}>
                  Ничего не найдено
                </div>
              }
            </div>
          </div>
        </>
      }
      <div className={styles.button}>
        <Button>
          Задать свой вопрос
        </Button>
      </div>
    </div>
  )
}