import { Dispatch, SetStateAction } from "react";
import { ListSettings } from "./ListSettings";
import { ContactSort } from "../../../../types";

import styles from "./index.module.scss";

type Props = {
  take: number;
  setTake: Dispatch<SetStateAction<number>>;
  sort: ContactSort;
  setSort: Dispatch<SetStateAction<ContactSort>>;
};

export const TableActions = (
  {
    take,
    setTake,
    sort,
    setSort,
  }: Props): JSX.Element => {

  return (
    <div className={styles.root}>
      <div>
        <ListSettings take={take} setTake={setTake} sort={sort} setSort={setSort}/>
      </div>
    </div>
  );
};
