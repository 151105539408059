import { useMutation } from "react-query";

import { api } from "../../api";
import { Payload } from "./useCreateView";

const DEFAULT_MN = "sdelka/shows-views/show/DEFAULT_MN";

type UseCreateShowOrView = {
  onSuccess?: () => void;
};

export const useCreateShow = ({onSuccess}: UseCreateShowOrView) => {
  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (payload: Payload) => api.showsViews.createShow(payload.applicationId, payload.date),
    onSuccess,
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
