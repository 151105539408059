import styles from "./index.module.scss";
import { AgencyAgreementStatusEnum, AgencyAgrementType } from "../../../../../../../../types";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as Close } from "../../../../../../../../assets/icons/close.svg";

type Props = {
  initialSaveText: string
  resetFormValues: () => void
  isEdit: boolean
  errorCode: string
  status?: AgencyAgreementStatusEnum
  data?: AgencyAgrementType
  onClick: () => void
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>
  isButtonActive?: boolean
}

export const ACStickyFooter = (
  {
    initialSaveText,
    resetFormValues,
    isEdit,
    status,
    errorCode,
    data,
    onClick,
    setIsEdit,
    isButtonActive
  }: Props): JSX.Element => {


  return (
    <>
      <div className={styles.buttonBlock}>
        {isEdit && status !== AgencyAgreementStatusEnum.unsigned && errorCode !== "AP005" &&
          <Button onClick={() => {
            resetFormValues()
            setIsEdit(false)
          } } color="red" variant="text" Icon={Close}>
            Отменить редактирование
          </Button>
        }
        {errorCode === "AP005" && isEdit &&
          <Button onClick={onClick} disabled={!isButtonActive}>
            {initialSaveText}
          </Button>
        }
        {status !== AgencyAgreementStatusEnum.unsigned && isEdit && errorCode !== "AP005" &&
          <Button onClick={onClick} disabled={!isButtonActive}>
            Внести изменения
          </Button>
        }
        {data?.terminationRequested &&
          <Button color="red">
            Подтвердить расторжение
          </Button>
        }
      </div>
    </>
  )
}