import { useDispatch } from "react-redux";
import { H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { openModal } from "../../../../../../../../../service/redux/modules/modal/modal.action";

import styles from "./styles.module.scss";
import { CreateContactModalForm } from "./components/CreateContactModalForm";

export const useAddLeadModal = (contactId?: string) => {
	const dispatch = useDispatch();

	const openLeadModal = () =>
		dispatch(
			openModal({
				title: <H2 classNames={{ root: styles.heading }}>Создать лид</H2>,
				body: <CreateContactModalForm  contactId={contactId}/>,
				width: 514,
			})
		);

	return {
		openLeadModal,
	};
};
