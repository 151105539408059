import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";

import { BankSelect, InfoRow, QuestionnaireValue } from "../../../index";
import { Boolean } from "../Boolean";

import styles from "./index.module.scss";

type Props = {
	handleOnChange: (value: QuestionnaireValue) => void;
	register: UseFormRegister<any>;
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	control: Control<any>;
	nameGroup: string[];
	disabled?: boolean;
	options?: { label: string; value: number }[];
};

export const MortgageSelect = ({
	nameGroup,
	register,
	getValues,
	setValue,
	control,
	handleOnChange,
	disabled,
	options,
}: Props): JSX.Element => {
	const [radioName, inputName] = nameGroup;

	const [radioWatcher, bankWatcher] = [
		useWatch({
			control,
			name: radioName,
			defaultValue: getValues(radioName),
		}),
		useWatch({
			name: inputName,
			control,
			defaultValue: getValues(inputName),
		}),
	];

	const change = (value: QuestionnaireValue) => {
		if (value === 0) {
			setValue(inputName, "");
		}

		if (handleOnChange) {
			handleOnChange(value);
		}
	};

	return (
		<div>
			<Boolean
				control={control}
				name={radioName}
				handleOnChange={change}
				disabled={disabled}
				options={options}
			/>
			{radioWatcher === 1 && (
				<InfoRow label="Укажите банк" aspect={{ label: 2, content: 3 }}>
					<div className={styles.row}>
						<BankSelect
							name={inputName}
							value={bankWatcher}
							setValue={setValue}
							register={register}
							classNames={{ input: styles.placeholder }}
							disabled={disabled}
							onChange={handleOnChange}
						/>
					</div>
				</InfoRow>
			)}
		</div>
	);
};
