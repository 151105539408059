import { CardPerson } from "../../../../../../UI";
import { useLoadPositions } from "../../../../../../../service/api/queries";
import { Lead } from "../../../../../../../types";

import { ReactComponent as ArrowsIcon } from "../../../../../../../assets/icons/arrows.svg";
import styles from "./index.module.scss";
import cn from "classnames";
import {
  UseOpenConfirmChangeResponsibleModal
} from "./confirm-change-responsible-modal/UseOpenConfirmChangeResponsibleModal";
import { selectCurrentAccount, useSelect } from "../../../../../../../service/redux/selectors";

type Props = {
  lead: Lead
  refetch: () => void;
};

export const Responsibles = ({lead, refetch}: Props): JSX.Element => {

  const {responsibleUser} = lead
  const {getPositionName} = useLoadPositions({});
  const {currentAccount} = useSelect(selectCurrentAccount);
  const isAnotherResponsible = responsibleUser ? responsibleUser.id !== currentAccount?.id : false;

  if (!responsibleUser) {
    return <></>;
  }
  const {openConfirmChangeResponsibleLeadModal} = UseOpenConfirmChangeResponsibleModal({lead, refetch})

  const positionId = responsibleUser.position?.id;
  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          Ответственный
        </div>
        {!isAnotherResponsible && <div
          className={cn(styles.row, styles.change)}
          onClick={openConfirmChangeResponsibleLeadModal}
        >
          <ArrowsIcon/>
          <div className={styles.add}>Сменить</div>
        </div>}
      </div>
      <div className={styles.card}>
        <CardPerson
          id={responsibleUser.id}
          phone={responsibleUser.account.phone}
          position={getPositionName(positionId)}
          avatar={responsibleUser.account.avatar}
          fio={{
            firstName: responsibleUser.account.name,
            lastName: responsibleUser.account.lastName,
            secondName: responsibleUser.account.secondName
          }}
         mini
        />
      </div>
    </>
  );
};
