import { useDispatch } from "react-redux";
import { KindType, Lead, OpenApplicationInDrawer } from "../../../../../../../types";
import { ApplicationCard, ApplicationTemplate } from "../../../../../Application";
import {
  closeDrawerInDrawer,
  openDrawerInDrawer,
} from "../../../../../../../service/redux/modules/drawer/drawer.action";
import { useAutoOpenApplication } from "./hooks";
import styles from "./index.module.scss";
import { CardApplicationMini } from "../../../../../../UI/Card/card-application/card-application-mini";
import { Plug } from "../plug/Plug";

type Props = {
  lead: Lead;
  refetch: () => void;
};

export const LeadApplications = ({lead, refetch}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {childApplications} = lead;
  
    const openApplicationDrawerInDrawer = ({ id, isTemplate }: OpenApplicationInDrawer) => {
      dispatch(closeDrawerInDrawer());
      setTimeout(() => {
        dispatch(
          openDrawerInDrawer({
            children: <>
              <ApplicationCard applicationId={id} inDrawer kind={KindType.APPLICATION} isFromObjectList={false} inDrawerInDrawer={true} />
            </> ,
            width: 800,
        })
      );
    });
  };
  //TODO ???что это
  // useAutoOpenApplication({openApplicationDrawerInDrawer});

  return (
    <>
      <div className={styles.row}>
        <div className={styles.title}>
          Заявки и сделки
        </div>
      </div>
      <div className={styles.card}>
        {childApplications && childApplications.length > 0
          ? childApplications?.map(
          (application) =>
            application && (
              <CardApplicationMini
                key={application.id}
                application={application}
                onCardClick={openApplicationDrawerInDrawer}
                options={{
                  canOpenANewTab: true,
                }}
              />
            )
        )
        : <Plug  plugText="Нет заявок"/>
        }
      </div>
    </>
  );
};

