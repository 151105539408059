import styles from "./index.module.scss";

type Props = {
	adIsStarted: boolean;
	tariff?: string | null;
	placementsLeft: string;
};

export const AdStatusHeaderFooter = ({ adIsStarted, tariff, placementsLeft }: Props): JSX.Element => {
	return (
		<div>
			{adIsStarted && tariff ? (
				<div className={styles.tariff}>Тариф {tariff}</div>
			) : (
				placementsLeft &&
				placementsLeft !== "" && (
					<div className={styles.actionsLeft}>
						<span>Осталось размещений</span>
						<span>{placementsLeft}</span>
					</div>
				)
			)}
		</div>
	);
};
