import { useDispatch } from "react-redux";

import { closeModal } from "../../../../service/redux/modules/modal/modal.action";
import { useCreatePreApplicationTemplate } from "service/api/mutations/pre-application-template";
import { usePreCreatePreApplication } from "../usePreCreatePreApplication";
import { PreCreateApplicationPayload } from "../../../../types";

type Props = {
	leadId: string;
	onSuccess?: (data) => void;
	isTemplate?: boolean;
};

type Return = {
	handleCreateApplication: () => void;
	isLoading: boolean;
};

type UseCreatePreApplicationModal = (props: Props) => Return;

export const useCreatePreApplicationModal: UseCreatePreApplicationModal = ({ leadId, onSuccess }) => {
	const dispatch = useDispatch();

	const { mutate, isLoading } = useCreatePreApplicationTemplate({
		onSuccess: (data) => {
			dispatch(closeModal());

			if (onSuccess) {
				onSuccess(data.data);
			}
		},
	});

	const onApplicationCreate = ({ type, category }: PreCreateApplicationPayload) =>
		mutate({
			leadId,
			category,
			businessProcessId: type.id,
		});

	const CREATE_APPLICATION_TITLE = "Создать предзаявку";

	const { handleCreateObject } = usePreCreatePreApplication({
		onCreated: onApplicationCreate,
		title: CREATE_APPLICATION_TITLE,
		isFull: true,
		isLoading
	});

	return {
		handleCreateApplication: handleCreateObject,
		isLoading,
	};
};
