import { useDispatch } from "react-redux";
import { Lead } from "../../../../../../../../types";
import { openModal } from "../../../../../../../../service/redux/modules/modal/modal.action";
import { ConfirmChangeResponsibleModal } from "./confirm-modal/ConfirmChangeResponsibleModal";


type Props = {
  lead: Lead
  refetch: () => void;
}

type Return = {
  openConfirmChangeResponsibleLeadModal: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type UseOpenConfirmChangeResponsibleModal = (props: Props) => Return

export const UseOpenConfirmChangeResponsibleModal: UseOpenConfirmChangeResponsibleModal = (
  {
    lead,
    refetch,
  }) => {

  const dispatch = useDispatch()

  const openConfirmChangeResponsibleLeadModal = () => {
    dispatch(
      openModal({
        width: 660,
        body:
          <ConfirmChangeResponsibleModal
            lead={lead}
            refetch={refetch}
          />,
      })
    )
  }
  return {
    openConfirmChangeResponsibleLeadModal
  }
}