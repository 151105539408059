/* eslint-disable sonarjs/no-duplicate-string */
import { memo, MouseEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import cn from "classnames";
import { Badge, Button } from "@sdelka_crm/sdelka-crm-component-library";
import { AddModal, VideoLessonsModal } from "./components";
import { AccountSwitcher } from "../AccountSwitcher";
import { InnerMenu } from "../InnerMenu";
import { setExpanded } from "../../../../../service/redux/modules/sidebar/sidebar.action";
import { openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { setFiltersShowButton } from "../../../../../service/redux/modules/object-list/object-list.action";
import { selectCurrentAccount, selectSideBar, useSelect } from "../../../../../service/redux/selectors";
import { useWindowWidth } from "../../../../../utils/hooks";
import { defaultValues } from "../../../../../utils/clientObjects";
import { paths } from "../../../../../utils/paths";
import { useSidebarTasksCount } from "./helpers";
import { InnerMenu as InnerMenuType, SideBarMenu } from "../../../../../types";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { ReactComponent as IconVideoPlay } from "../../../../../assets/icons/iconVideoPlay.svg";
import { ReactComponent as DocumentIcon } from "../../../../../assets/icons/document.svg";
import { ReactComponent as SecondaryHousingIcon } from "../../../../../assets/icons/secondary-housing.svg";
import { ReactComponent as Service } from "../../../../../assets/icons/briefcase.svg";
import { ReactComponent as MainLogo } from "../../../../../assets/icons/mainLogo.svg";
import { ReactComponent as MainLogoMini } from "../../../../../assets/icons/mainLogoMini.svg";
import { ReactComponent as Roll } from "../../../../../assets/icons/roll.svg";
import { ReactComponent as LogoutIcon } from "../../../../../assets/icons/logout.svg";
import styles from "./index.module.scss";
import { logoutThunk } from "../../../../../service/redux/modules/auth/auth.thunk";

const sourceSelectStringify = (sourceSelect: string[]) =>
  encodeURIComponent(JSON.stringify({...defaultValues, sourceSelect}));

const agencySelectStringify = (agencySource: string[]) =>
  encodeURIComponent(JSON.stringify({...defaultValues, agencySource}));

const menuItemIdPrefix = "sidebar-menu-item-";

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Sidebar = memo((): JSX.Element => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const width = useWindowWidth();
  const {push} = useHistory();

  const {isAccountsOpen, isOfficesOpen} = useSelect(selectSideBar);
  const {currentAccount} = useSelect(selectCurrentAccount);

  const userSubscriptionFinished = currentAccount?.employerSubscribtionFinished ?? false

  const data = useSidebarTasksCount() ?? 0;

  const totalTasksCountLabel =
    data.toString().length > 7
      ? `${data.toString().slice(0, 7)}...`
      : data.toString();

  const noInnerMenu = {height: 0, url: ""};
  const [openInnerMenu, setOpenInnerMenu] = useState<{
    height: number;
    url: string;
  }>(noInnerMenu);
  const innerMenuRef = useRef<HTMLDivElement>(null);

  const handleOpenInnerMenu = (queries: [string, string][]) => {
    let queriesString = "";

    queries.forEach((query) => {
      const [key, value] = query;

      const firstSymbol = queriesString.length === 0 ? "" : "&";
      queriesString += `${firstSymbol}${key}=${value}`;
    });

    push(`${openInnerMenu.url}?${queriesString}`);
    setOpenInnerMenu(noInnerMenu);
  };

  const innerMenu: InnerMenuType = {
    [ paths.services.list ]: [
      {
        id: "1",
        text: "Товары и услуги",
        onClick: () => handleOpenInnerMenu([["tab", "PRODUCTS"]]),
      },
      {
        id: "2",
        text: "Офис",
        onClick: () => handleOpenInnerMenu([["tab", "OFFICES"]]),
      },
      {
        id: "3",
        text: "Сотрудники",
        onClick: () => handleOpenInnerMenu([["tab", "EMPLOYERS"]]),
      },
    ],
    [ paths.leadsAndDealsPath.list ]: [
      {
        id: "1",
        text: "Контакты",
        onClick: () => handleOpenInnerMenu([["tab", "contacts"]]),
      },
      {
        id: "2",
        text: "Лиды",
        onClick: () => handleOpenInnerMenu([["tab", "leads"]]),
      },
      {
        id: "3",
        text: "Заявки",
        onClick: () => handleOpenInnerMenu([["tab", "applications"]]),
      },
    ],
    [ paths.objectsPath.list ]: [
      {
        id: "1",
        text: "С парсера",
        // eslint-disable-next-line sonarjs/no-duplicate-string
        onClick: () => handleOpenInnerMenu([["tab", "PARSED"]]),
        submenu: [
          {
            id: "2",
            text: "Собственники",
            onClick: () =>
              handleOpenInnerMenu([
                ["filter", sourceSelectStringify(["Собственники"])],
                ["tab", "PARSED"],
              ]),
          },
          {
            id: "3",
            text: "Агентства",
            onClick: () =>
              handleOpenInnerMenu([
                ["filter", sourceSelectStringify(["База агентств"])],
                ["tab", "PARSED"],
              ]),
          },
        ],
      },
      {
        id: "4",
        text: "Мои объекты",
        onClick: () => handleOpenInnerMenu([["tab", "AGENCY"]]),
        submenu: [
          {
            id: "5",
            text: "Мои объекты",
            onClick: () =>
              handleOpenInnerMenu([
                ["filter", agencySelectStringify(["Мои объекты"])],
                ["tab", "AGENCY"],
              ]),
          },
          {
            id: "6",
            text: "Мой отдел",
            onClick: () =>
              handleOpenInnerMenu([
                ["filter", agencySelectStringify(["Мой отдел"])],
                ["tab", "AGENCY"],
              ]),
          },
          {
            id: "7",
            text: "Мой офис",
            onClick: () =>
              handleOpenInnerMenu([
                ["filter", agencySelectStringify(["Мои объекты"])],
                ["tab", "AGENCY"],
              ]),
          },
        ],
      },
    ],
  };

  const sidebarMenu: SideBarMenu = [
    {
      Icon: <SecondaryHousingIcon/>,
      text: "Объекты",
      url: paths.objectsPath.list,
      isInnerMenu: true,
    },
    {
      Icon: <DocumentIcon/>,
      text: "CRM",
      url: paths.leadsAndDealsPath.list,
      isInnerMenu: true,
    },
    {
      Icon: <Roll/>,
      text: "Задачи",
      url: paths.tasks.list,
      children: !totalTasksCountLabel ? undefined : (
        <Badge
          className={styles.sidebarBadge}
          number={totalTasksCountLabel as unknown as number}
          hideZero={false}
        />
      ),
    },
    {
      Icon: <Service/>,
      text: "Управление",
      url: paths.services.list,
      isInnerMenu: true,
    },
  ];
  const sidebarMenuActual = userSubscriptionFinished ? sidebarMenu.slice(3) : sidebarMenu

  useEffect(() => {
    if (width > 1600) {
      dispatch(setExpanded(true));
    }
    if (width < 1600) {
      dispatch(setExpanded(false));
    }
  }, [width]);

  const handleOpenCreateModal = () => {
    dispatch(
      openModal({
        title: "Выберите, что хотите создать",
        body: <AddModal/>,
        alertIcon: "plus",
        variant: "alert",
      })
    );
  };

  const handleOpenVideoLessonsModal = () => {
    dispatch(
      openModal({
        width: 800,
        body: <VideoLessonsModal/>,
      })
    );
  }

  const handleLinkClick = (url: string): void => {
    if (url === paths.objectsPath.list) {
      dispatch(setFiltersShowButton(true));
    }
  };

  const handleHoverMenuItem = (event: MouseEvent<HTMLDivElement>, url: string) => {
    const element = document.getElementById(`${menuItemIdPrefix}${url}`);

    setOpenInnerMenu({
      height: url && element ? element.offsetTop : 0,
      url,
    });
  };

  return (
    <div
      ref={innerMenuRef}
      className={cn(styles.sidebar, {
        [ styles.open ]: isAccountsOpen || openInnerMenu.url || width > 1600,
        [ styles.sidebar__inner ]: openInnerMenu.url,
      })}
      onMouseLeave={() => setOpenInnerMenu(noInnerMenu)}
    >
      <div
        className={cn(styles.sidebarBlur, {
          [ styles.sidebarBlur__open ]: isOfficesOpen,
        })}
      />
      <div
        className={cn(styles.sidebarColumn, {
          [ styles.sidebar__wrapperHide ]: isAccountsOpen,
        })}
      >
        <div className={styles.logo} onClick={() => push("/dashboard")}>
          <MainLogoMini/>
          <MainLogo/>
        </div>
        <div className={styles.sidebarColumn__content}>
          {!userSubscriptionFinished &&
            <div className={styles.buttonContainer}>
              <Button
                variant={"outlined"}
                Icon={PlusIcon}
                onClick={handleOpenCreateModal}
                classNames={{
                  root: cn(styles.button, {
                    [ styles.buttonShort ]: isAccountsOpen,
                  }),
                }}
              >
                <span className={styles.buttonText}>Добавить</span>
              </Button>
            </div>}
          <div className={styles.sidebarMenu}>
            {sidebarMenuActual.map((it) => (
              <div
                id={`${menuItemIdPrefix}${it.url}`}
                key={it.url}
                onMouseEnter={(event: MouseEvent<HTMLDivElement>) =>
                  handleHoverMenuItem(event, it.isInnerMenu ? it.url : "")
                }
                className={cn(styles.sidebarMenuItem, {
                  [ styles.sidebarMenuItemHide ]: it.hide,
                })}
              >
                {/* @ts-ignore */}
                <Link
                  to={it.url}
                  onClick={() => handleLinkClick(it.url)}
                  className={cn(styles.sidebarMenuItem__link, {
                    [ styles.sidebarMenuItem__link_active ]: pathname.includes(it.url),
                  })}
                >
                  <div className={styles.sidebarMenuItem__icon}>{it.Icon}</div>
                  <span className={styles.sidebarMenuItem__text}>{it.text}</span>
                  {it.children}
                </Link>
              </div>
            ))}
            <div className={styles.sidebarMenuItem}>
              <button
                type="button"
                onClick={() => dispatch(logoutThunk())}
                className={cn(styles.sidebarMenuItem__link, styles.sidebarMenuItem__button)}
              >
                <div className={styles.sidebarMenuItem__icon}>
                  <LogoutIcon/>
                </div>
                <span className={styles.sidebarMenuItem__text}>Выйти</span>
              </button>
            </div>
          </div>
          {!userSubscriptionFinished &&
            <div className={styles.buttonContainerVideo}>
              <Button
                variant={"outlined"}
                Icon={IconVideoPlay}
                onClick={handleOpenVideoLessonsModal}
                classNames={{
                  root: cn(styles.buttonVideo, {
                    [ styles.buttonShort ]: isAccountsOpen,
                  }),
                }}
              >
                <span className={styles.buttonText}>Видео-уроки</span>
              </Button>
            </div>}
          <AccountSwitcher/>
        </div>
      </div>
      <InnerMenu
        isOpen={Boolean(openInnerMenu.url)}
        list={openInnerMenu.url ? innerMenu[ openInnerMenu.url ] : []}
        paddingTop={openInnerMenu.height}
      />
    </div>
  );
});
