import { ReactNode, useEffect, useMemo, useRef, useState, Dispatch, SetStateAction, MouseEvent } from "react";
import cn from "classnames";

import { UseClickOutside, useClickOutside } from "../../../../utils/hooks";

import { ReactComponent as Arrow } from "../../../../assets/icons/arrow-down.svg";
import styles from "./index.module.scss";

type PopupClassNames = {
	button?: string;
	arrow?: string;
	card?: string;
	wrapper?: string;
	root?: string;
	trigger?: string;
	disabled?: string;
};

type PopupOptions = {
	withArrow?: boolean;
	primaryOpen?: "left" | "right";
	arrowColor?: "black" | "light";
	openUp?: boolean
} & Pick<UseClickOutside, "withClickListener">;

type PopupOuterOpenState = {
	outerIsOpen: boolean;
	outerSetIsOpen: Dispatch<SetStateAction<boolean>>;
};

export type PopupProps = {
	children: ReactNode;
	openTrigger: ReactNode | string;
	outerOpenState?: PopupOuterOpenState;
	disabled?: boolean;
	classNames?: PopupClassNames;
	onClickOutside?: () => void;
	options?: PopupOptions;
	forceClose?: boolean
};

export const Popup = ({
	children,
	openTrigger,
	outerOpenState,
	classNames,
	disabled,
	onClickOutside,
	options = {},
	forceClose
}: PopupProps): JSX.Element => {
	const [openToTop, setOpenToTop] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	
	useEffect(() => {
		if (forceClose === true) {
			setIsOpen(false)
		}
	}, [forceClose])

	const { withArrow, primaryOpen = "right", arrowColor = "black", withClickListener } = options;

	const wrapperRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const checkForOpenToTop = (pHeight: number): boolean => {
		const contentPosition = contentRef?.current?.getBoundingClientRect();

		const y = contentPosition?.y || 0;
		const height = contentPosition?.height || 0;

		return Boolean(!options?.openUp ? contentPosition && pHeight <= y + height : true);
	};

	const openHandler = (
		value: boolean,
		setterValue: boolean | ((value: boolean) => boolean) = value,
		event: MouseEvent<HTMLOrSVGElement> | undefined = undefined
	) => {
		if (event) {
			event.stopPropagation();
		}

		if (disabled) {
			return;
		}

		outerOpenState?.outerSetIsOpen(value);
		setIsOpen(setterValue);
	};

	useClickOutside({
		ref: wrapperRef,
		onClick: () => {
			openHandler(false);
			if (onClickOutside) {
				onClickOutside();
			}
		},
		exclude: [buttonRef],
		withClickListener,
	});

	useEffect(() => {
		if (outerOpenState) {
			setIsOpen(outerOpenState.outerIsOpen);
		}
	}, [outerOpenState, outerOpenState?.outerIsOpen]);

	useEffect(() => {
		setOpenToTop(checkForOpenToTop(window.innerHeight));
	}, [isOpen, contentRef.current, window.pageYOffset]);

	const openToRight = useMemo(() => {
		const position = wrapperRef?.current?.getBoundingClientRect();

		if (primaryOpen === "right") {
			return !!(position && window.innerWidth - position.left - position.width >= 271);
		}

		return !!(position && position.left <= 271);
	}, [isOpen, wrapperRef.current]);

	const isOpenConditionHandler = () => isOpen && (outerOpenState ? outerOpenState.outerIsOpen : true);

	const isOpenCondition = isOpenConditionHandler();

	const animationPopupStyles = "animate__animated animate__zoomIn";

	return (
		<div
			ref={wrapperRef}
			className={cn(styles.wrapper, classNames?.wrapper, {
				[styles.withArrow]: withArrow,
			})}
		>
			<div className={cn(styles.root, classNames?.root)}>
				<div
					ref={buttonRef}
					className={cn(styles.button, classNames?.button, {
						[styles.triggerClicked]: isOpenCondition,
					})}
				>
					<div
						onClick={(event: MouseEvent<HTMLOrSVGElement>) => openHandler(!isOpen, (prev) => !prev, event)}
						className={cn(styles.triggerWrapper, classNames?.trigger, {
							[cn(styles.disabled, classNames?.disabled)]: disabled,
						})}
					>
						{openTrigger}

						{withArrow && (
							<Arrow className={cn(styles.icon, styles[`arrow__${arrowColor}`], classNames?.arrow)} />
						)}
					</div>
				</div>

				<div
					ref={contentRef}
					onClick={(event: MouseEvent<HTMLElement>) => event.stopPropagation()}
					onMouseUp={(event: MouseEvent<HTMLOrSVGElement>) => event.stopPropagation()}
					className={cn(styles.popup, animationPopupStyles, classNames?.card, {
						[styles.popupHide]: !isOpen && (outerOpenState ? !outerOpenState.outerIsOpen : true),
						[styles.popupToRight]: openToRight,
						[styles.popupToTop]: openToTop,
					})}
				>
					{isOpen && children}
				</div>
			</div>
		</div>
	);
};
