import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import cn from "classnames";

import { CalendarRangeInPopup } from "../../../../../../UI";
import styles from "./index.module.scss";

type Props = {
	name: [string, string];
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	register: UseFormRegister<any>;
	control: Control<any>;
	dateNotSelected: string;
};

export const DateSpan = ({
	name,
	getValues,
	setValue,
	register,
	dateNotSelected,
	control,
}: Props): JSX.Element => {
	const [dateFromWatcher, dateToWatcher] = [
		useWatch({
			control,
			name: name[0],
			defaultValue: getValues(name[0]),
		}),
		useWatch({
			control,
			name: name[1],
			defaultValue: getValues(name[1]),
		}),
	];

	const defaultDate =
		dateFromWatcher === "" || dateToWatcher === "" ? undefined : [dateFromWatcher, dateToWatcher];

	return (
		<div className={cn(styles.filterWrapper, styles.periodWrapper)}>
			<CalendarRangeInPopup
				classNames={{
					label: styles.periodLabelWrapper,
					wrapper: styles.periodPopupWrapper,
				}}
				defaultDate={defaultDate}
				placeholder={dateNotSelected}
				form={{
					name,
					register,
					setValue,
					getValues,
				}}
			/>
		</div>
	);
};
