import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { TextField } from "../../../../UI";
import { codeMask } from "../../../../../utils/masks";
import { commonErrors } from "../../../../../utils/errors";
import { digitOnly } from "../../../../../utils/string";
import styles from "./index.module.scss";
import { ReactComponent as LockIcon } from "../../../../../assets/icons/lock.svg";

type Props = {
  onSubmit: (values: { code: string }) => void;
  resetCode: () => void;
  setCode: (value: string) => void;
  setBackStep: () => void;
  timer: number;
  label?: string;
};

export const EnterCodeForm = (
  {
    onSubmit,
    resetCode,
    setCode,
    setBackStep,
    timer,
    label,
  }: Props): JSX.Element => {
  const schema = yup.object().shape({
    code: yup.string().min(4, "Введите код полностью").required(commonErrors.required),
  });

  const {handleSubmit, control, formState} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {code: ""},
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="code"
        placeholder={label ? "" : "****"}
        label={label}
        inputClassName={styles.customPrepend}
        mask={codeMask}
        onChange={(value: string) => {
          setCode(value);
          if (value && digitOnly(value).length === 4) {
            handleSubmit(onSubmit)();
          }
        }}
        error={formState?.errors?.code?.message?.toString()}
        prepend={<LockIcon/>}
      />
      <div className={styles.info}>
        Сейчас на&nbsp;ваш&nbsp;телефон поступит звонок с&nbsp;уникального номера. Введите последние 4&nbsp;цифры
        входящего номера.
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.resend}>
          {timer > 0 && (
            <span>
							Повториить через&nbsp;
              <span className={styles.timeLeft}>{timer}</span>
              &nbsp;с
						</span>
          )}
        </div>
        <div className={styles.buttons}>
          {timer <= 0 && (
            <Button classNames={{root: styles.button}} onClick={resetCode} type="button" variant="text">
              Запросить новый код
            </Button>
          )}
          <Button classNames={{root: styles.button}} type="button" variant="text" onClick={setBackStep}>
            Назад
          </Button>
        </div>
      </div>
    </form>
  );
};
