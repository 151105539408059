import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";

import { Select } from "../../../../UI";
import { ContactSort } from "../../../../../types";

import styles from "./index.module.scss";

type Props = {
	take: number;
	setTake: Dispatch<SetStateAction<number>>;
	sort: ContactSort;
	setSort: Dispatch<SetStateAction<ContactSort>>;
};

export const ListSettings = ({ take, setTake, sort, setSort }: Props): JSX.Element => {
	const { setValue, register } = useForm();

	const sizeOptions = [
		{ label: "Показать 10", value: "10" },
		{ label: "Показать 20", value: "20" },
		{ label: "Показать 30", value: "30" },
	];

	const dateOptions = [
		{ label: "Сначала старые", value: "ASC" },
		{ label: "Сначала новые", value: "DESC" },
	];

	const onChangeDataSort = (value: string) => {
		setSort({
			...sort,
			createdAt: value as "ASC" | "DESC",
		});
	};

	return (
		<div className={styles.root}>
			<Select
				name="size"
				defaultValue={take.toString()}
				onChange={(value) => setTake(Number(value))}
				options={sizeOptions}
				register={register}
				setValue={setValue}
				size="small"
				classNames={{ root: styles.firstSelect }}
			/>

			<Select
				name="dateSort"
				defaultValue={sort?.createdAt || "ASC"}
				onChange={onChangeDataSort}
				options={dateOptions}
				register={register}
				setValue={setValue}
				size="small"
			/>
		</div>
	);
};
