import { ReactNode, useRef } from "react";
import cn from "classnames";

import { useClickOutside } from "../../../../../../utils/hooks";
import { DefaultSelectProps } from "../../../../../../types";

import selectStyles from "../selectStyles.module.scss";

type Props = {
	children: ReactNode;
	setListOpen: (value: boolean) => void;
} & Pick<DefaultSelectProps, "size" | "withBorder" | "classNames" | "error" | "disabled">;

export const SelectWrapper = ({
	children,
	setListOpen,
	size,
	withBorder,
	classNames,
	error,
	disabled,
}: Props): JSX.Element => {
	const wrapperRef = useRef<HTMLDivElement>(null);

	useClickOutside({
		ref: wrapperRef,
		onClick: () => setListOpen(false),
	});

	return (
		<div
			ref={wrapperRef}
			className={cn(selectStyles.selectWrapper, selectStyles[`size__${size}`], classNames?.root, {
				[selectStyles.withBorder]: withBorder,
				[selectStyles.selectError]: error,
				[selectStyles.disabled]: disabled,
			})}
		>
			{children}
		</div>
	);
};
