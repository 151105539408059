import { useState } from "react";
import { useForm } from "react-hook-form";
import { CollapseDown, Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { ShowsViews, ShowsViewsFinishPayload } from "../../../../../types";
import { useCardStatusHandler } from "./useCardStatusHandler";
import styles from "./index.module.scss";
import { CardHeader } from "../card-header/CardHeader";
import { CardFooter } from "../card-footer/CardFooter";
import { CardActions } from "../card-actions/CardActions";
import { CardFeedback } from "../card-feedback/CardFeedback";
import { CardShowOrViewContact } from "../card-contact-refactored";
import { CardObjectMini } from "../../../../UI";
import { Plug } from "../plug/Plug";

type Props = {
  isViews: boolean;
  item: ShowsViews;
  onSuccess: () => void;
};

export const CardWrapper = ({item, onSuccess, isViews}: Props): JSX.Element => {
  const [currentItem, setCurrentItem] = useState(item);

  const type = isViews ? "buyer" : "seller";

  const form = useForm({
    defaultValues: {
      buyerNeedsHelpToSellHisObject: currentItem.buyerNeedsHelpToSellHisObject,
      sellerNeedsHelpToAnotherNewObject: currentItem.sellerNeedsHelpToAnotherNewObject,
      sellerNeedsHelpToSellHisObject: currentItem.sellerNeedsHelpToSellHisObject,
      offer: "",
    },
  });
  const {getValues, watch, setValue, control, register} = form;
  const watchForm = watch();

  const {isLoading} = useCardStatusHandler({
    item: currentItem,
    onSuccess,
    setCurrentItem,
    type,
    form: {getValues, setValue, watchForm},
  });

  const fefactor = (value) => {
    if (value === "") {
      return undefined
    }
    if (value === "true") {
      return true
    }
    if (value === "false") {
      return false
    }
    return value
  }

  const payload: ShowsViewsFinishPayload = {
    id: item.id,
    offer: getValues("offer") !== null ? Number(getValues("offer").replaceAll(" ", "")) : undefined,
    buyerNeedsHelpToSellHisObject: isViews ? undefined : fefactor(getValues("buyerNeedsHelpToSellHisObject")),
    sellerNeedsHelpToSellHisObject: isViews ? fefactor(getValues("sellerNeedsHelpToSellHisObject")) : undefined,
    sellerNeedsHelpToAnotherNewObject: isViews ? fefactor(getValues("sellerNeedsHelpToAnotherNewObject")) : undefined
  }
  const viewObject = item.realEstateObject || item.clientObjectApplication

  return (
    <div className={styles.root}>
      <Loader height={450} style={{display: isLoading ? "block" : "none"}}/>
      <div style={{display: isLoading ? "none" : "block"}}>
        <div className={styles.cardRoot}>
          <CardHeader isViews={isViews} item={item}/>
          <div className={styles.content}>
            {isViews &&
              <div className={styles.row}>
                <div className={styles.labelObject}>
                  Объект просмотра:
                </div>
                {viewObject ?
                  <CardObjectMini
                    id={viewObject?.id ?? ""}
                    objectData={viewObject}
                  />
                  : <Plug plugText="Нет объекта" mini/>}
              </div>
            }
            <CardShowOrViewContact isViews={isViews} item={item} isAgent={false} refetch={onSuccess}/>
            <CardShowOrViewContact isViews={isViews} item={item} isAgent refetch={onSuccess}/>
            <div className={styles.collapse}>
              <CollapseDown
                label={
                  <div className={styles.titleCollapse}>
                    {`Результат ${isViews ? "Просмотра" : "Показа"}`}
                  </div>
                }
                classNames={{
                  root: styles.tableRoot,
                  labelText: styles.labelText
                }}
                headerChildren={
                  <>
                  </>
                }
              >
                <CardFeedback
                  isViews={isViews}
                  item={item}
                  register={register}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  inputName="offer"
                  payload={payload}
                />
                <CardFooter isViews={isViews} item={item}/>
              </CollapseDown>
            </div>
          </div>
          <CardActions
            isViews={isViews}
            item={item}
            onSuccess={onSuccess}
            payload={payload}
          />
        </div>
      </div>
    </div>
  );
};
