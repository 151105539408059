import { AxiosResponse } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ShowsAndViews } from "../../../ShowsAndViews";
import { useLoadApplicationCard, useLoadApplicationRealEstate } from "../../../../../service/api/queries";
import {
  addTabInDrawer, addTabInDrawerInDrawer,
  changeCurrentTab,
  showMapInDrawer
} from "../../../../../service/redux/modules/drawer/drawer.action";
import { selectDrawer, useSelect } from "../../../../../service/redux/selectors";
import { Application, ApplicationCardResponse, EditClientEstate, KindType, RealEstate } from "../../../../../types";
import { RealEstateAd, RealEstateEdit, RealEstateViewWithTabs } from "components/pages/RealEstatePage/mode";
import { useGetNavigation } from "components/pages/RealEstatePage/helpers";
import { ReactComponent as Edit } from "../../../../../assets/icons/edit-page.svg";
import { ReactComponent as Ad } from "../../../../../assets/icons/ad.svg";
import { ReactComponent as Shows } from "../../../../../assets/icons/shows_and_views.svg";
import { useQueryParams } from "utils/hooks";
import { ApplicationCard } from "../index";

type Props = {
  applicationId: string;
  inDrawer?: boolean;
  inDrawerInDrawer?: boolean;
  onFirstLoad?: () => void;
  kind?: KindType
  isFromObjectList?: boolean
};

type Return = {
  application?: ApplicationCardResponse;
  isLoadingApplicationCard: boolean;
  refetch: () => void;
};

type UseLoadApplication = (props: Props) => Return;

export const useLoadApplication: UseLoadApplication = (
  {
    applicationId,
    inDrawer,
    onFirstLoad,
    kind,
    inDrawerInDrawer,
    isFromObjectList,
  }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const {location} = useHistory();
  const history = useHistory()
  const {yNavigation} = useSelect(selectDrawer);

  const {realEstate, isFetching, remove, refetch: refetchRealEstate} = useLoadApplicationRealEstate({
    id: applicationId,
    kind
  });

  const {onAdNav, navigationY, blocksIds} = useGetNavigation({
    realEstate,
  });

  const {
    queryParams: {mode}, changeQueryParams
  } = useQueryParams<{ mode: string }>([{name: "mode"}]);

  const handleChangeTab = (newTab: string) => changeQueryParams([{name: "mode", newValue: newTab}]);

  const clientEstate: RealEstate | undefined = useMemo(() => {
    if (realEstate) {
      const copy: EditClientEstate = {
        ...realEstate,
        applications: [],
        activeApplications: {},
      };

      if (copy?.schema) {
        delete copy.schema;
      }

      return copy;
    }

    return undefined;
  }, [realEstate]);

  const viewNav = {
    label: "Объект",
    height: 160,
    title: "view"
  };

  const viewChildren = (
    <RealEstateViewWithTabs
      id={applicationId}
      blocksIds={blocksIds}
      kind={kind}
    />
  )
  const editNav = {
    label: <Edit/>,
    height: 90,
  };
  const editChildren = (realEstate && clientEstate &&
    <RealEstateEdit
      id={applicationId}
      realEstate={clientEstate}
      refetch={refetchRealEstate}
      remove={remove}
      isFetching={isFetching}
    />
  )

  const adNav = {
    label: <Ad/>,
    height: 90,
    title: "ad"
  };
  const adChildren = (
    <RealEstateAd
      id={applicationId}
      onNav={onAdNav}
      realEstate={realEstate}
      refetch={refetchRealEstate}
      isFetchingObject={isFetching}
    />
  )

  const appNav = {
    label: "Заявка",
    height: 160,
    title: "app"
  }
  const appChildren = (
    <ApplicationCard
      applicationId={applicationId}
      inDrawer
      kind={KindType.APPLICATION}
      isFromObjectList
    />
  )

  const addTab = isFromObjectList ? addTabInDrawerInDrawer : addTabInDrawer
  useEffect(() => {
    if (yNavigation && yNavigation?.navigation?.length > 3 || inDrawerInDrawer) {
      return
    }
    if (realEstate?.type.label === "Продажа") {
      if (!yNavigation && navigationY && navigationY.find((el) => el.title !== "view")) {
        dispatch(
          addTabInDrawer({
            newChildren: viewChildren,
            newNavigation: viewNav,
          })
        );
      }
      if (isFromObjectList) {
        setTimeout(() => {
          dispatch(changeCurrentTab({newCurrentTab: 2}))
        }, 200)
      }
    }

    if (!yNavigation && navigationY && navigationY.find((el) => el.title !== "app")) {
      dispatch(
        addTabInDrawer({
          newChildren: appChildren,
          newNavigation: appNav,
        })
      );
    }

    if (!isFetching) {
      // && location.pathname === "/leadsanddeals" && !location.search.startsWith('?tab=leads&')

      if (realEstate?.type.label === "Продажа") {
      }

      if (realEstate?.type.label === "Продажа") {
        if (yNavigation && yNavigation.navigation.every((el) => el.title !== "view")) {
          dispatch(
            addTabInDrawer({
              newChildren: viewChildren,
              newNavigation: viewNav,
            })
          );
        }
        dispatch(
          showMapInDrawer({
            hasMap: true
          })
        )
      }

      if (realEstate?.type.label === "Продажа") {
        if (yNavigation && yNavigation.navigation.every((el) => el.title !== "ad")) {
          dispatch(
            addTabInDrawer({
              newChildren: adChildren,
              newNavigation: adNav,
            })
          );
        }
        if (!yNavigation && navigationY && navigationY.every((el) => el.title !== "ad")) {
          dispatch(
            addTabInDrawer({
              newChildren: adChildren,
              newNavigation: adNav,
            })
          );
        }
      }
    }
    if (onFirstLoad) {
      onFirstLoad();
    }

  }, [isFetching, location, dispatch, yNavigation, navigationY, onFirstLoad, realEstate?.type.label])

  const haveTab = () => {
    if (yNavigation && yNavigation?.navigation.length > 0) {
      return yNavigation.navigation.find((nav) => nav.label === "Просмотры" || nav.label === "Показы");
    }

    return undefined;
  };

  const onSuccess = (data: AxiosResponse<Application>) => {
    if (fetched) {
      return;
    }

    if (!inDrawerInDrawer && !haveTab()) {
      const {
        type: {whoIsClient},
      } = data.data;

      if (!isFromObjectList) {
        // history.push(`/leadsanddeals?tab=${kind}s&id=${applicationId}&client=${whoIsClient}`);
      }

      const newNavigation = {
        label: <Shows/>,
        title: "shows",
        height: 90,
      };
      const newChildren = (
        <ShowsAndViews
          applicationId={applicationId}
          type={whoIsClient === "buyer" ? "views" : "shows"}
        />
      );
      if (yNavigation && yNavigation.navigation.every((el) => el.title !== "shows")) {
        dispatch(
          addTabInDrawer({
            newChildren,
            newNavigation,
          })
        )
      }

      if (!yNavigation && navigationY && navigationY.find((el) => el.title !== "shows")) {
        dispatch(
          addTabInDrawer({
            newChildren,
            newNavigation,
          })
        )
      }

      if (onFirstLoad) {
        onFirstLoad();
      }
    }

    setFetched(true);
  };

  const {
    data: application,
    isLoading: isLoadingApplicationCard,
    refetch,
  } = useLoadApplicationCard({applicationId, onSuccess, kind}) as unknown as {
    data: ApplicationCardResponse;
    isLoading: boolean;
    refetch: () => void;
  };

  return {
    application,
    isLoadingApplicationCard,
    refetch,
  };

};
