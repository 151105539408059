import { ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import cn from "classnames";

import styles from "./index.module.scss";

export type TRadio = {
	value: string | number | undefined;
	label: string | ReactNode;
	disabled?: boolean;
	icon?: ReactNode
};

type Props = {
	name: string;
	options: TRadio[];
	control: Control<any>;
	background?: "white" | "blue";
	backgroundButtons?: "white" | "blue";
	textColor?: "auto" | "blue";
	separate?: boolean;
	handleOnChange?: (value: string | number) => void;
	className?: string;
	required?: boolean;
	size?: "small" | "medium";
	currentValue?: string | number;
	canUnselect?: boolean;
	settings?: {
		preventOnChange?: boolean;
	};
	disabled?: boolean;
	disabledClassName?: string;
};

export const RadioButton = ({
	name,
	options,
	control,
	background = "white",
	size = "medium",
	textColor = "auto",
	separate,
	handleOnChange,
	className,
	required,
	currentValue,
	canUnselect,
	settings = {},
	disabled,
	disabledClassName,
	backgroundButtons
}: Props): JSX.Element => {

	return (
		<Controller
			control={control}
			name={name}
			rules={{ required }}
			// eslint-disable-next-line sonarjs/cognitive-complexity
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<div
					className={cn(
						styles.groupRadio,
						className,
						styles[`background_${background}`],
						styles[`size_${size}`],
						styles[`text_${textColor}`],
						{
							[styles.noBgColor]: separate,
						},
						{ [styles.disabledContainer]: disabled },
						disabledClassName ? { [disabledClassName]: disabled } : {}
					)}
				>
					{options.map((item, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<div
							key={index}
							className={cn({
								[styles.groupRadioItem]: separate,
								[styles.br]: item.value === "<br>",
								[styles.disabled]: disabled,
							})}
						>
							<input type="radio" value={currentValue || item.value} disabled={item.disabled} />
							<label
								onClick={() => {
									if (!item.disabled) {
										if (handleOnChange && item.value) {
											handleOnChange(item.value);
										}

										if ((currentValue || value) === item.value && !settings.preventOnChange) {
											onChange(canUnselect ? "" : item.value);
										} else if (!settings.preventOnChange) {
											onChange(item.value);
										} else if (handleOnChange && (item.value || item.value === 0)) {
											handleOnChange(item.value);
										}
									}
								}}
								className={cn({
									[styles.unchecked]: (currentValue || value) !== item.value && separate,
									[cn(styles.notActive, className)]: (currentValue || value) !== item.value,
									[styles.checked]: (currentValue || value) === item.value,
									[styles.radioError]: error,
									[styles.disabled]: item.disabled,
									[styles.icon]: item.icon,
									[styles.buttons]: (currentValue || value) !== item.value && backgroundButtons === "white",
									[styles.buttonsBlue]: (currentValue || value) !== item.value && backgroundButtons === "blue"
								})}
							>
								{item.label}
								{item.icon}
							</label>
						</div>
					))}
				</div>
			)}
		/>
	);
}
