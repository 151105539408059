import { InfoRow, Select, Textarea, TransparentInput } from "../../../index";
import { Deadline, Executor } from "./components";
import { useSelector } from "../../../../../service/redux/useSelector";
import { prepareUserData } from "../../../../../utils/user/prepareUserData";
import { remindOptions } from "../../../../../utils/constants";
import { prepareShortUserOutput } from "../../../../../utils/transformation";
import { useTaskFormContext } from "../../../../../service/context/TaskForm";
import { CreateTaskForm } from "../../../../../types";

import styles from "./index.module.scss";
import { useWatchers } from "./helpers";

export const Form = (): JSX.Element => {
	const { register, setValue, errors, control } = useTaskFormContext();

	const { currentAccount } = useSelector((state) => state.currentAccount);

	const { remindForWatcher } = useWatchers();

	const setNumberValue = (name: string, value: string) =>
		setValue(name as keyof CreateTaskForm, Number(value));

	const { name, secondName, lastName } = prepareUserData(currentAccount);

  return (
    <>
      <div className={styles.rows}>
        <Deadline/>
        <Executor classnames={styles.executor}/>

        <InfoRow classNames={{root: styles.infoRow}} labelFont="ubuntu" label="Создатель">
					<span>
						{prepareShortUserOutput({
              name,
              lastName,
              secondName,
            })}
					</span>
        </InfoRow>
        <InfoRow classNames={{root: styles.infoRow}} labelFont="ubuntu" label="Напомнить за">
          <Select
            placeholder="Выбрать"
            name="remindFor"
            register={register}
            setValue={setNumberValue}
            options={remindOptions}
            defaultValue="3600"
            error={errors.remindFor?.message?.toString()}
            required
          />
        </InfoRow>
      </div>

      <div className={styles.inputs}>
        <div className={styles.inputWrapper}>
          <TransparentInput
            name="name"
            className={styles.name}
            placeholder="Введите имя задачи"
            register={register}
            error={errors?.name ? "Обязательное поле" : ""}
          />
        </div>

        <Textarea
          name="description"
          className={styles.textarea}
          required
          placeholder="Введите описание задачи."
          register={register}
          error={errors?.description ? "Обязательное поле" : ""}
          rows={10}
        />
      </div>
    </>
  );
};
