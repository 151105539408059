import { format } from "date-fns";
import { useLayoutEffect, useRef } from "react";
import { UseFormGetValues } from "react-hook-form";

import { getDeepField } from "./getDeepField";

type Props = {
	fieldName: string;
	getFormValues: UseFormGetValues<{ additionalProperties: any }>;
};

export const useInitialDatetime = ({ fieldName, getFormValues }: Props) => {
	const initialDate = useRef<Date | undefined>(undefined);
	const initialTime = useRef<string | undefined>(undefined);

	useLayoutEffect(() => {
		const values = getFormValues();
		const initialDatetime = getDeepField<string>(values, fieldName);

		const initial = new Date(initialDatetime);

		initialDate.current = initialDatetime ? initial : undefined;

		initialTime.current = initialDatetime ? format(initial, "HH:mm") : undefined;
	}, []);

	return {
		initialDate,
		initialTime,
	};
};
