import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";
import { Lead } from "../../../../types";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/lead/create-lead-from-archived-estate-object/DEFAULT_MN";

type Props = {
  onSuccess?: (res: AxiosResponse<Lead>) => void;
  onError?: () => void;
};

type Return = {
  mutate: UseMutateFunction<AxiosResponse<Lead>, unknown, string, unknown>;
  isLoading: boolean;
};

type UseCreateLeadFromObject = (props: Props) => Return;

export const useCreateLeadFromArchivedEstateObject: UseCreateLeadFromObject = ({ onSuccess, onError }) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: (applicationId: string) => api.leads.createFromArchivedObject(applicationId),
    onSuccess,

  });

  return {
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};
