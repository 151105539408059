import { UseMutateFunction, useMutation } from "react-query";
import { CianData } from "../../../../types";
import { AxiosResponse } from "axios";

import { api } from "../../index";

const DEFAULT_MN = "sdelka/real-estate-ad/update-cian/DEFAULT_MN";

type Props = {
	onSuccess?: (data: AxiosResponse<any>, variables: CianData, context: unknown) => void | Promise<unknown>;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<any>, unknown, CianData, unknown>;
	isLoading: boolean;
};

type UseUpdateCian = (props: Props) => Return;

export const useUpdateCian: UseUpdateCian = ({ onSuccess }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: CianData) => api.realEstateAd.updateCianData(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
