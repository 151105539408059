import { useEffect } from "react";

import { FixedNav, DRAWER_IN_DRAWER_ID } from "../../../UI";
import {
	Applications,
	ContactLoading,
	ContactRealEstates,
	ContactStickyFooter,
	General,
	RelatedContacts
} from "./components";
import { useContact } from "../../../../service/api/queries";
import { RoleGuard } from "../../../../service/router/RoleGuard";
import { scrollToElement } from "../../../../utils/scroll";
import { Contact } from "../../../../types";

import styles from "./index.module.scss";
import { CommunicationHistory } from "./components/communication-history";

type Props = {
	contactId: string;
	inDrawer?: boolean;
	refetchList?: () => void;
};

export const ContactInfo = (
	{
		contactId,
		inDrawer,
		refetchList
	}: Props): JSX.Element => {
	const { contact, isFetching, refetch, remove } = useContact(contactId);


	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			remove();
		};
	}, []);

	const blocksIds = {
		wrapper: inDrawer ? DRAWER_IN_DRAWER_ID : "drawer-right-block",
		general: "general-block-contact-view",
		requests: "requests-block-contact-view",
		contacts: "contact-block-contact-view",
		realEstates: "real-estates-block-contact-view",
	};


	if (isFetching) {
		return <ContactLoading />;
	}

	const refetchAll = () => {
		refetch();
		if (refetchList) {
			refetchList();
		}
	};

	return (
		<RoleGuard only="estateAgencyUser">
			<div className={styles.container}>
				<div className={styles.contentContainer}>
					<div className={styles.content}>
						<General contact={contact as Contact} sectionId={blocksIds.general} refetch={refetchAll} />

						<CommunicationHistory contact={contact as Contact}/>

						{contact && <Applications id={blocksIds.requests} contact={contact} />}

						<RelatedContacts id={blocksIds.contacts} contactId={contactId} />

						<ContactRealEstates id={blocksIds.realEstates} contactId={contactId} />
					</div>
					<ContactStickyFooter contactId={contactId}/>
				</div>
			</div>
		</RoleGuard>
	);
};
