import styles from "./index.module.scss"
import { InfoRow, TransparentInput } from "../../../../UI";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

type Props = {
  refetch: () => void
  onCreate: (name: string | undefined) => void
}

export const CreateDepartmentModal = ({refetch, onCreate}: Props): JSX.Element => {
  const [name, setName] = useState("")
  const {register, setValue} = useForm()

  const handleCreate = () => {
    onCreate(name)
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Создание отдела
      </div>
      <InfoRow label="Название отдела">
        <TransparentInput
          className={styles.input}
          register={register}
          placeholder="Указать"
          name="name"
          withCleanUp={{setValue}}
          required
          onChange={(val) => setName(val)}
        />
      </InfoRow>
      <div className={styles.footer}>
        <Button
          onClick={() => handleCreate()}
        >
          Подтвердить
        </Button>
      </div>
    </div>
  )
}