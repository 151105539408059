import { CSSProperties } from "react";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ReactComponent as ArrowIcon } from "../../../../../../assets/icons/arrow-down.svg";
import styles from "../../index.module.scss";
import { StagesType, WhoIsClientType } from "../../../../../../types";

const LastBtnText = {
	seller: "Покупатель найден",
	buyer: "Перейти на сделку",
};

type Props = {
	getStyle: (stage: StagesType) => CSSProperties;
	stages: StagesType[];
	selectedTab: number;
	handleChangeStage: (stage: "prev" | "next", type?: "view" | "change") => void;
	isGrey: boolean;
	showPrevArrow: boolean;
	showNextArrow: boolean;
	showNextStageBtn: boolean;
	showLastStageBtn: boolean;
	currentStage: number;
	whoIsClient: WhoIsClientType;
	showReturnBtn: boolean;
	handleReturnStage: () => void;
	goToCurrentStage: () => void;
	onFinish?: () => void;
};

export const Banner = ({
	getStyle,
	stages,
	selectedTab,
	handleChangeStage,
	isGrey,
	showPrevArrow,
	showNextArrow,
	showNextStageBtn,
	showLastStageBtn,
	onFinish,
	currentStage,
	whoIsClient,
	showReturnBtn,
	handleReturnStage,
	goToCurrentStage,
}: Props): JSX.Element => (
	<div
		style={getStyle(stages[selectedTab])}
		className={cn(styles.stageCard, {
			[styles.firstStageCard]: selectedTab === 0,
			[styles.stageCardGrey]: isGrey,
			[styles.lastStageCard]: selectedTab === stages.length - 1 && stages.length >= 4,
		})}
	>
		{showPrevArrow && (
			<div className={styles.arrowLeft} onClick={() => handleChangeStage("prev")}>
				<ArrowIcon />
			</div>
		)}

		{showNextArrow && (
			<div className={styles.arrowRight} onClick={() => handleChangeStage("next")}>
				<ArrowIcon />
			</div>
		)}

		<div className={styles.image}>
			<img
				alt="right hand"
				src={stages?.[selectedTab]?.image}
				height="600"
				className={cn({
					[styles.imageBlackAndWhite]: isGrey,
				})}
			/>
		</div>

		<div className={styles.currentTitle}>
			<div>
				<span style={{color: `${stages?.[selectedTab]?.textColor}`}}>{selectedTab + 1} этап</span>
				<p style={{color: `${stages?.[selectedTab]?.textColor}`}}>{stages?.[selectedTab]?.title}</p>
			</div>

			<div className={styles.buttons}>
				{(showNextStageBtn && (
					<Button color="blue-disabled" onClick={() => handleChangeStage("next", "change")}>
						Следующий этап
					</Button>
				)) ||
					(showLastStageBtn && (
				<></>
					)) || (
						<>
							<Button color="blue-disabled" onClick={goToCurrentStage}>
								К текущему этапу
							</Button>

							{showReturnBtn && (
								<Button color="blue-disabled" onClick={handleReturnStage}>
									Вернуться на этот этап
								</Button>
							)}
						</>
					)}
			</div>

			{stages?.[ selectedTab ]?.stageNumber < currentStage && <div className={styles.finishedStageLabel}>
				 <span style={{color: `${stages?.[selectedTab]?.textColor}`}}>Этап завершен</span>
			</div>}
		</div>
	</div>
);
