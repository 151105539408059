import {
	SidebarAction,
	SET_EXPANDED,
	SET_OPEN_ACCOUNTS,
	SET_OPEN_OFFICES,
	CLOSE_ALL,
} from "./sidebar.action";
import { SidebarTInitialState } from "../../types";

const initialState: SidebarTInitialState = {
	isExpanded: false,
	isAccountsOpen: false,
	isOfficesOpen: false,
};

export const sidebarReducer = (state = initialState, action: SidebarAction): SidebarTInitialState => {
	switch (action.type) {
		case SET_EXPANDED:
			return {
				...state,
				isExpanded: action.isExpanded,
			};
		case SET_OPEN_ACCOUNTS:
			return {
				...state,
				isAccountsOpen: action.isOpen,
			};
		case SET_OPEN_OFFICES:
			return {
				...state,
				isOfficesOpen: action.isOpen,
			};
		case CLOSE_ALL:
			return {
				...state,
				isExpanded: false,
				isAccountsOpen: false,
				isOfficesOpen: false,
			};
		default:
			return state;
	}
};
