import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router";

import styles from "./index.module.scss";
import { ReactComponent as Edit } from "../../../../../assets/icons/edit-page.svg";

type Props = {
	id: string;
	block?: any;
};

export const DealProperties = ({
	id,
	block
}: Props): JSX.Element => {

	return (
		<div className={styles.root}>

		</div>
	);
};
