import { useMutation, useQueryClient } from "react-query";

import { api } from "../../api";

const DEFAULT_MN = "sdelka/address/load-points-detailed/DEFAULT_MN";

export const useFindPointDetailed = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: (addressOrPoint: string) => api.address.findAddressPoint(addressOrPoint),
  });

  const getLastMutationCache = () => {
    const allMutations = queryClient.getMutationCache().findAll({ mutationKey: DEFAULT_MN });

    return allMutations[allMutations.length - 1];
  };

  return {
    mutate: mutate.mutate,
    mutateAsync: mutate.mutateAsync,
    isLoading: mutate.isLoading,
    getLastMutationCache,
  };
};
