import cn from "classnames";

import styles from "./index.module.scss";

export const ObjectLayoutLoading = (): JSX.Element => (
	<div className={styles.root}>
		<div className={cn(styles.skeleton, styles.header)} />

		<div className={cn(styles.skeleton, styles.gallery)} />

		<div className={cn(styles.skeleton, styles.body)} />
	</div>
);
