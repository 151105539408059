import { Task } from "../../../../../../types";

import { useLoadTask } from "../../../../../../service/api/queries/tasks";
import { useOpenTaskModal } from "../../../../../../utils/hooks/task";

type Props = {
	taskId: string;
	refetch: () => void;
};

type Return = {
	task?: Task;
	openTaskModal: () => void;
};

type UseTask = (props: Props) => Return;

export const useTask: UseTask = ({ taskId, refetch }) => {
	const { data, refetch: refetchTask } = useLoadTask({ id: taskId });

	const onUpdatedTask = () => {
		if (refetch) {
			refetch();
		}

		refetchTask();
	};

	const { handleOpenTaskModal } = useOpenTaskModal({ onUpdatedTask });

	const openTaskModal = () => {
		const { id } = data || {};

		if (id) {
			handleOpenTaskModal(id);
		}
	};

	return {
		task: data,
		openTaskModal,
	};
};
