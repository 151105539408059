import { Steps } from "../../../../../service/redux/modules/registration-steps/reg-steps.action";
import { ChooseRole } from "../ChooseRole";
import { CreatingAuthAccession } from "../RegistrationDone/CreatingAuthAccession";
import { CreatingNewCompany } from "../RegistrationDone/CreatingNewCompany";
import { CreatingSingleRealtor } from "../RegistrationDone/CreatingSingleRealtor";
import { AcceptedInvitation } from "../RegistrationDone/AcceptedInvitation";
import { EnterCodeRegistrationPage } from "../EnterCode";
import { PersonalDataForm } from "../PersonalDataForm";
import { HasInvites } from "../HasInvites";
import { FirstStep } from "../FirstStep";
import { FinishRegistration } from "../FinishRegistration";

export const stepToComponentMap: Record<Steps, JSX.Element> = {

	"first-step": <FirstStep />,
	"sms-code": <EnterCodeRegistrationPage />,
	"personal-data": <PersonalDataForm />,
	"finish-registration" : <FinishRegistration />,
	"has-active-invites": <HasInvites />,
	"done-new-company": <CreatingNewCompany />,
	"done-auth-accession": <CreatingAuthAccession />,
	"done-single-realtor": <CreatingSingleRealtor />,
	"done-invitation": <AcceptedInvitation />,
	"choose-role": <ChooseRole />,
};

export const stepToComponent = (step: Steps) => stepToComponentMap[step];
