import { CartItemProduct, CartPayload } from "../../../../types";
import styles from "./index.module.scss"
import { useState } from "react";
import { Select } from "../../Form";
import { useForm } from "react-hook-form";
import { ReactComponent as Plus } from "../../../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../../../assets/icons/minus.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/cancel.svg";
import { useDeleteFromCart, useUpdateCart } from "../../../../service/api/mutations";
import cn from "classnames";
import { formatThousand } from "../../../../utils/formatters";



type Props = {
  product: CartItemProduct
  onSuccess: () => void
  isHovered: boolean
}

export const CardProduct = ({product, onSuccess, isHovered}: Props) => {
  const {
    id,
    tariff,
    tariffOptions,
    totalPrice,
    photo,
    productType,
    description,
    title,
    amount,
  } = product
  console.log(totalPrice)
  const {mutate: updateCart, isLoading, data} = useUpdateCart({onSuccess})
  const {mutate: mutateDelete, isLoading: isLoadingDelete} = useDeleteFromCart({
    onSuccess
  })

  const [hovered, setHovered] = useState(false);
  const {register, setValue} = useForm()
  const [selectedTariff, setSelectedTariff] = useState(tariff)
  const [count, setCount] = useState(data?.amount ?? amount)

  const payload: CartPayload = {
    id,
    amount: count,
    tariff: selectedTariff
  }

  const increase = () => {
    setCount(count + 1)
    setTimeout(() => {
      updateCart(payload)
    }, 50)
  }
  const decrease = () => {
    if (count >= 2) {
      setCount(count - 1)
      setTimeout(() => {
        updateCart(payload)
      }, 50)
    }
  }

  const handleChangeTariff = (value) => {
    setSelectedTariff(value)
    setTimeout(() => {
      updateCart(payload)
    }, 50)
  }

  return (
    <div
      className={styles.root}
      onMouseEnter={() => {
        setHovered(true)
      }}
      onMouseLeave={() => {
        setHovered(false)
      }}>
      <div className={cn(styles.content, {[styles.open]: isHovered})}>
        <div className={cn(styles.row, styles.image, {[styles.imageOpen]: !isHovered})} style={{width: (isHovered ? "200px" : "86px")}}>
          <img src={photo} alt={"Photo"}/>
          {isHovered && <div>
            {title} <br></br>
            {description}
          </div>}
        </div>
        {isHovered &&
          <>
          <div className={styles.select}>
            <Select
              options={tariffOptions}
              name={"tariff"}
              register={register}
              setValue={setValue}
              onChange={(value) => handleChangeTariff(value)}
              defaultValue={data?.tariff ?? tariff}
            />
          </div>
          <div className={styles.amount}>
            <Minus onClick={() => decrease()}/>
            {count}
            <Plus onClick={() => increase()}/>
          </div>
          <div className={styles.price}>
            {data?.totalPrice ? `${formatThousand(data?.totalPrice)} ₽` : `${formatThousand(totalPrice)} ₽`}
          </div>
          {
            <div className={styles.delete} onClick={() => mutateDelete(id)}>
              <Delete/>
            </div>}
        </>}
      </div>
    </div>
  )
}