import { Lead, LeadStatusEnum, PreApplicationStatusesEnum } from "../../../../../../../../../types";
import { Button, Loader } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "../../index.module.scss";
import { useRefuseModal } from "../../helpers/useChangeLeadStatus/Modals";
import { useTakeLead } from "../../../../../../../../../service/api/mutations";
import { selectCurrentAccount, useSelect } from "../../../../../../../../../service/redux/selectors";
import { UseOpenRelatedLeadModal } from "../../../RelatedLeads/addRelatedLeadModal/UseOpenRelatedLeadModal";


type Props = {
  lead: Lead
  refetch: () => void;
  isLoading?: boolean
}

export const MainActionButton = ({lead, refetch, isLoading}: Props): JSX.Element => {

  const {
    id,
    status,
    responsibleUser,
    childPreApplications
  } = lead
  const isCheckedLeadTaken = lead.responsibleUser !== null
  const {currentAccount} = useSelect(selectCurrentAccount);
  const isAnotherResponsible = false;// responsibleUser ? responsibleUser.id !== currentAccount?.id : false;
  const isLeadTaken = false;// isCheckedLeadTaken || isAnotherResponsible;
  const {openAddRelatedLeadModal} = UseOpenRelatedLeadModal({lead, refetch})
  const {mutate: mutateTakeLead} = useTakeLead({});


  const handleClickTakeLead = (takenID: string) => {
    if (!isLeadTaken) {
      mutateTakeLead(takenID);
      setTimeout(() => {
        refetch()
      }, 1000)
    }
    if (lead.status === LeadStatusEnum.deferred) {
      mutateTakeLead(takenID);
      setTimeout(() => {
        refetch()
      }, 1000)
    }
  };
  const {openRefuseModal} = useRefuseModal({leadId: id, refetch});

  const getLeadButton = () => {
    switch (status) {
      case LeadStatusEnum.free:
      case LeadStatusEnum.new:
        return <Button
          onClick={() => handleClickTakeLead(lead.id)}
          isLoading={isLoading}
          disabled={isLeadTaken}
        >
          {!isLeadTaken ? "Взять в работу" : "Нельзя взять чужой лид в работу"}
        </Button>
      case LeadStatusEnum.deferred:
        return <Button
          onClick={() => handleClickTakeLead(lead.id)}
          isLoading={isLoading}
        >
          Разморозить
        </Button>
      case LeadStatusEnum.done:
        return <Button
          onClick={openAddRelatedLeadModal}
          isLoading={isLoading}
        >
          Создать побочный лид
        </Button>
      case LeadStatusEnum.in_progress:
        return <Button
          classNames={{root: styles.refuseButton}}
          onClick={openRefuseModal}
          isLoading={isLoading}
          // disabled={childPreApplications && childPreApplications?.find(el => el.status === PreApplicationStatusesEnum.IN_WORK)}
        >
          Убрать в архив
        </Button>
      default:
        return <> </>
    }
  }

  return (
    <>
      {getLeadButton()}
    </>
  )
}