import { Office } from "../../../../types";
import styles from "./index.module.scss"
import cn from "classnames";
import { ReactComponent as Avatar } from "../../../../assets/icons/office-briefcase-svgrepo-com 1.svg";

type Props = {
  office: Office
  selected?: boolean
  onCardClick: (id: string) => void
}

export const CardOffice = (
  {
    office,
    selected,
    onCardClick
  }: Props): JSX.Element => {
  const {
    logotip,
    name,
    id,
    addressLabel,
    mainPhone
  } = office

  return (
    <div
      className={cn(styles.root, {[ styles.selected ]: selected})}
      onClick={() => onCardClick(id)}
    >
      <div className={styles.content}>
        <div className={styles.row}>
          {logotip
            ? <img src={logotip} alt={"avatar"}/>
            : <div className={styles.logo}><Avatar/></div>
          }
          <div className={styles.col} style={{height: "100%", padding: "4px 0"}}>
            <div className={styles.col}>
              <div className={styles.title}>
                {name}
              </div>
              <div>
                {addressLabel}
              </div>
            </div>
            <div>
              {mainPhone} {" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}