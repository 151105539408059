import { ReactNode, useState } from "react";
import { Application, ApplicationInListV2, ApplicationsFilter, KindType } from "../../../../types";

import { Table } from "../../index";
import { useTableApplicationColumns } from "../../../../utils/application";

import styles from "./index.module.scss";

type Props = {
  data: ApplicationInListV2[];
  filter?: ApplicationsFilter;
  isLoading: boolean;
  transformGroupTitle?: (title: string, data?: Application) => ReactNode;
  rowClass?: string;
  colClass?: string;
  dots?: boolean;
  groupKey?: string;
  rowsCount?: number;
  withStage?: boolean;
  tableRowClick?: (row: ApplicationInListV2) => void;
  refetchApplications?: () => void;
  blinkRows?: string;
  kind?:KindType
};

export const TableApplication = ({
	data,
	filter,
	isLoading,
	transformGroupTitle,
	rowClass,
	colClass,
	dots,
	groupKey = "",
	rowsCount = 5,
	withStage,
	tableRowClick,
	refetchApplications,
	blinkRows,
  kind
}: Props): JSX.Element => {
	const [contextClick, setContextClick] = useState("");

  const columns = useTableApplicationColumns({
    dots,
    withStage,
    contextClick,
    refetchApplications,
    kind
  });

  const isTypeSelected = filter ? filter.typeId?.length === 1 : false;

  return (
    <div className={styles.root}>
      <Table<ApplicationInListV2>
        idKey="id"
        data={data}
        columns={columns}
        groupKey={isTypeSelected ? groupKey : undefined}
        transformGroupTitle={transformGroupTitle}
        onRowClick={tableRowClick}
        rowClass={rowClass || styles.applicationsTableRow}
        colClass={colClass}
        loading={{isLoading, rowsCount}}
        activeRow={{
          accessor: "",
          activeId: 1,
          onContextMenu: ({id}) => setContextClick(id),
        }}
        blinkRows={blinkRows}
      />
    </div>
  );
};
