import { ReactNode } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
	children: ReactNode;
	label?: string | ReactNode;
	classNames?: {
		root?: string;
		content?: string;
	};
	isLoading?: boolean;
	variant?: "default" | "singleBorder" | "column" | "noBorder" | "noLabel";
	weight?: "bold" | "regular";
	labelFont?: "cera" | "ubuntu";
	aspect?: {
		label: number;
		content: number;
	};
};

// Row wrapper for object's element (with optional label)
export const InfoRow = ({
	children,
	label,
	classNames,
	isLoading,
	variant = "default",
	aspect = { label: 6, content: 8 },
	weight = "bold",
	labelFont = "cera",
}: Props): JSX.Element => (
	<>
		{isLoading ? (
			<div className={styles.rowLoading} />
		) : (
			<div className={cn(styles.infoRowWrapper, classNames?.root, styles[variant], styles[weight])}>
				{label || variant === "noLabel" ? (
					<div style={{ flex: `${aspect.label} 1` }} className={cn(styles.label, styles[labelFont])}>
						{variant === "noLabel" ? " " : label}
					</div>
				) : null}
				<div
					style={{ flex: `${aspect.content} 1` }}
					className={cn(styles.childrenContainer, classNames?.content)}
				>
					{children}
				</div>
			</div>
		)}
	</>
);

export const InfoRowLoading = ({ rowsCount = 1 }: { rowsCount?: number }): JSX.Element => (
	<>
		{Array.from(Array(rowsCount).keys()).map((row) => (
			<div key={row} className={styles.rowLoading} />
		))}
	</>
);
