import { memo, useRef, useState } from "react";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { Link } from "../../index";
import { formatDateWithTime } from "../../../../utils/date";
import { prepareShortUserOutput } from "../../../../utils/transformation";
import { useClickOutside, useHandleFinishApplication } from "../../../../utils/hooks";
import { UserData } from "../../../../types";

import { ReactComponent as CloseOutlined } from "../../../../assets/icons/close-outlined.svg";
import { ReactComponent as Timer } from "../../../../assets/icons/timer.svg";
import styles from "./index.module.scss";

type Props = {
	applicationId: string;
	creator: UserData & { id: string; userId: string };
	lead: {
		shortId: string;
		id: string;
	};
	createdDate: string;
	updatedDate: string;
	refetch: () => void;
};

export const Creator = memo(
	({
		applicationId,
		creator,
		lead: { shortId, id },
		createdDate,
		updatedDate,
		refetch,
	}: Props): JSX.Element => {
		const { handleAbortApplication, handlePentUpDemandApplication, isLoading } = useHandleFinishApplication({
			applicationId,
			onSuccess: refetch,
		});

		const triggerRef = useRef<HTMLDivElement>(null);
		const [active, setActive] = useState(false);

		useClickOutside({
			ref: triggerRef,
			onClick: () => {
				if (active) {
					setActive(false);
				}
			},
		});

		const pushToLead = () => window.open(`/leadsanddeals?tab=leads&id=${id}`, "_blank");
		const pushToAccount = () =>
			window.open(`/employees?userId=${creator.userId}&accountId=${creator.id}`, "_blank");

		return (
			<div className={styles.root}>
				<div>
					<div className={styles.block}>
						<div className={styles.block}>
							<div className={styles.title}>Заявку создал:</div>
							<Link onClick={pushToAccount} classNames={{ root: styles.info }}>
								{prepareShortUserOutput(creator)}
							</Link>
						</div>
					</div>

					{shortId && (
						<div className={styles.block}>
							<div className={styles.title}>Заявка создана из лида:</div>
							<Link onClick={pushToLead} classNames={{ root: styles.info }}>{`№ ${shortId}`}</Link>
						</div>
					)}

					{createdDate && (
						<div className={styles.block}>
							<div className={styles.title}>Дата создания:</div>
							<div className={cn(styles.info, styles.date)}>
								{formatDateWithTime(new Date(createdDate), false)}
							</div>
						</div>
					)}
				</div>
				<div className={styles.block}>
					<div className={styles.buttonsWithDate}>
						{updatedDate && (
							<div className={styles.block}>
								<div className={styles.title}>Дата изменения:</div>
								<div className={cn(styles.info, styles.date)}>
									{formatDateWithTime(new Date(updatedDate), false)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
);
