import { useHistory } from "react-router";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { CardRequestInfo } from "../../../../UI";
import { useLoadYourApplications } from "../../../../../service/api/queries/public-page";

const getSideDataByClient = (item) => {
	if (item.whoIsClient === "seller") {
		return [
			{
				id: "1",
				label: "Просмотров объявления в рекламе:",
				value: item.adsViews,
			},
			{ id: "2", label: "Активности риэлтора:", value: item.agentActivites },
			{ id: "3", label: "Просмотры объектов", value: item.views },
		];
	}

	return [
		{ id: "1", label: "Объектов в подборке", value: item.objectsInCollection },
		{ id: "2", label: "Вы не оценили объектов", value: item.unmarkedObjects },
		{ id: "3", label: "Активностей риэлтора", value: item.agentActivites },
	];
};

const mapTypeName = {
	Продажа: "sell",
	Покупка: "buy",
};

type Props = {
	contactId: string;
};

export const DealApplications = ({ contactId }: Props): JSX.Element => {
	const { data, isLoading } = useLoadYourApplications({ contactId });

	const { push } = useHistory();

	const pushToApplicationSale = (applicationId: string, type: string) => {
		push(`${contactId}/application-sale/${mapTypeName[type]}/${applicationId}`);
	};

	return (
		<div>
			<Loader height={250} style={{ display: isLoading ? "block" : "none" }} />
			<div style={{ display: isLoading ? "none" : "block" }}>
				{data.map((item) => {
					const typeCondition = item.typeName === "Продажа";

					if (typeCondition && !item.clientObject) return <></>;

					return (
						<div
							onClick={() => pushToApplicationSale(item.id, item.typeName)}
							key={item.id}
							style={{ marginBottom: 20, cursor: "pointer" }}
						>
							<CardRequestInfo
								title={typeCondition ? item.clientObject.title : "На покупку объекта"}
								subTitle={typeCondition ? item.clientObject.addressLabel : ""}
								currentStage={{
									tooltip: item.stage,
									text: item.stage,
								}}
								createdDate={item.createdAt}
								sideData={getSideDataByClient(item)}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};
