import { Link } from "react-router-dom";

import { paths } from "../../../../../utils/paths";

import styles from "./index.module.scss";

export const InviteNotFound = (): JSX.Element => (
	<>
		<p>Ссылка больше не действительна</p>

		<div className={styles.loginLinkContainer}>
			<Link className={styles.link} to={paths.registrationPath.default}>
				Зарегистрируйтесь
			</Link>
			{" или "}
			<Link className={styles.link} to={paths.loginPath}>
				войдите
			</Link>
		</div>
	</>
);
