import { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { api } from "../../api";
import { ContactFilter } from "../../../../types";

type UseRelatedContacts = {
	takeAmount?: number;
	queryName?: string;
	defaultFilter?: ContactFilter;
	enabled?: boolean;
};

export const useRelatedContacts = ({
	takeAmount = 10,
	queryName = "loadRealaContacts",
	defaultFilter = {},
	enabled,
}: UseRelatedContacts) => {
	const [filter, setFilter] = useState<ContactFilter>(defaultFilter);
	const [page, setPage] = useState(1);

	const [take, setTake] = useState(takeAmount);
	const skip = useMemo(() => (page - 1) * takeAmount, [page]);

	const loadContacts = () => api.contacts.relatedContacts({ take, skip, search: filter });

	const { data, isLoading, isFetching, refetch } = useQuery([queryName, take, skip, filter], loadContacts, {
		keepPreviousData: true,
		enabled,
	});

	return {
		relatedContactsList: data?.data?.data || [],
		refetch,
		total: data?.data?.total || 0,
		page: data?.data?.page || 1,
		isLoading,
		setPage,
		filter,
		setFilter,
		take,
		setTake,
		isFetching,
	};
};
