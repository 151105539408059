import { createSelectorCreator, defaultMemoize } from "reselect";
import { useSelector } from "../useSelector";
import isEqual from "lodash.isequal";

import { Select } from "../types";
import { AppState } from "../store";

type SSelect = (state: AppState) => any;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
export const selectSideBar = createDeepEqualSelector(
	(state: AppState) => state.sidebar,
	(sidebar) => sidebar
);

export const selectCurrentAccount = createDeepEqualSelector(
	(state: AppState) => state.currentAccount,
	(currentAccount) => currentAccount
);

export const selectAccounts = createDeepEqualSelector(
	(state: AppState) => state.accounts,
	(accounts) => accounts
);

export const selectObjectList = createDeepEqualSelector(
	(state: AppState) => state.objectList,
	(objectList) => objectList
);

export const selectObjectEdit = createDeepEqualSelector(
	(state: AppState) => state.objectEdit,
	(objectEdit) => objectEdit
);

export const selectCompilation = createDeepEqualSelector(
	(state: AppState) => state.compilation,
	(compilation) => compilation
);

export const selectModal = createDeepEqualSelector(
	(state: AppState) => state.modal,
	(modal) => modal
);

export const selectDrawer = createDeepEqualSelector(
	(state: AppState) => state.drawer,
	(drawer) => drawer
);

export const selectGallerySlider = createDeepEqualSelector(
	(state: AppState) => state.gallerySlider,
	(gallerySlider) => gallerySlider
);

export const selectResetPassword = createDeepEqualSelector(
	(state: AppState) => state.resetPassSteps,
	(resetPassSteps) => resetPassSteps
);

export const selectRegisterSteps = createDeepEqualSelector(
	(state: AppState) => state.regSteps,
	(regSteps) => regSteps
);

export const selectBusinessProcesses = createDeepEqualSelector(
	(state: AppState) => state.businessProcess,
	(businessProcesses) => businessProcesses
);

type UseSelect = (selector: SSelect) => Select;

export const useSelect: UseSelect = (selector) => useSelector((state) => selector(state));
