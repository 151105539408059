import { MultiLayerSelectOption } from "../../types";
import { prepareShortUserOutput } from "./prepareShortUserOutput";

export const prepareResponsibleUsers = (users: Array<any>): MultiLayerSelectOption[] => {
	const layers: MultiLayerSelectOption[] = [];
	users.forEach((user) => {
		if (!user?.department || !user?.department?.office) return;

		const {
			department: { office },
			department,
		} = user;
		const foundedOffice = layers.find((layer) => layer.value === office?.id);
		if (!foundedOffice) {
			layers.push({
				label: { name: office.name, children: office.name },
				value: office.id,
				parents: [],
				layer: 0,
			});
		}

		const foundedDep = layers.find((layer) => layer.value === department.id);
		if (!foundedDep) {
			layers.push({
				label: { name: department.name, children: department.name },
				value: department.id,
				parents: [office?.id || ""],
				layer: 1,
			});
		}

		const shortUserName = prepareShortUserOutput(user.account);

		layers.push({
			label: {
				name: shortUserName,
				children: shortUserName,
			},
			value: user.id,
			parents: [department?.id || "", office?.id || ""],
			layer: 2,
		});
	});

	return layers;
};
