import styles from "./index.module.scss"
import { CallData } from "../../../../../../../../types";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../../../service/redux/modules/modal/modal.action";
import { AddCallModal } from "./AddCallModal";

type Props = {
  onSelect: (callData?: CallData | null, callDataId?: string | null) => void
}

type Return = {
  openAddCallModal: () => void
}

export const useOpenAddCallModal = ({onSelect}: Props): Return => {

  const dispatch = useDispatch()

  const openAddCallModal = () => {
    dispatch(
      openModal({
        width: 800,
        // contentClass: styles.root,
        // classNames:{
        //   contentBody: styles.root
        // },
        body: <AddCallModal onSelect={onSelect}/>
      })
    )
  }
  return {
    openAddCallModal
  }
}