import { createContext, ReactNode, useContext, useMemo } from "react";
import {
	UseFormRegister,
	UseFormSetValue,
	UseFormGetValues,
	Control,
	FieldErrorsImpl,
} from "react-hook-form";

import { CreateTaskForm } from "../../../types";

type TaskFormContextType = {
	register: UseFormRegister<CreateTaskForm>;
	setValue: UseFormSetValue<CreateTaskForm>;
	getValues: UseFormGetValues<CreateTaskForm>;
	control: Control<CreateTaskForm>;
	errors: FieldErrorsImpl<CreateTaskForm>;
	isCreating?: boolean;
};

const TaskFormContext = createContext<TaskFormContextType | null>(null);

type Props = {
	registerProp: UseFormRegister<CreateTaskForm>;
	setValueProp: UseFormSetValue<CreateTaskForm>;
	getValuesProp: UseFormGetValues<CreateTaskForm>;
	controlProp: Control<CreateTaskForm>;
	errorsProp: FieldErrorsImpl<CreateTaskForm>;
	children: ReactNode;
	isCreating?: boolean;
};

export const TaskFormContextProvider = ({
	registerProp,
	setValueProp,
	getValuesProp,
	controlProp,
	errorsProp,
	children,
	isCreating,
}: Props): JSX.Element => {
	const value = useMemo(
		() => ({
			register: registerProp,
			setValue: setValueProp,
			getValues: getValuesProp,
			control: controlProp,
			errors: errorsProp,
			isCreating,
		}),
		[registerProp, setValueProp, getValuesProp, controlProp, errorsProp, isCreating]
	);

	return <TaskFormContext.Provider value={value}>{children}</TaskFormContext.Provider>;
};

export const useTaskFormContext = (): TaskFormContextType => {
	const context = useContext(TaskFormContext);

	if (context === null) {
		throw new Error("TaskForm context must be inside values");
	}

	return context;
};
