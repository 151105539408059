import { Department, DepartmentFilters, ResponseList } from "../../../../types";
import { Client } from "../../Client";
import { getDepartmentFilters } from "../../helpers";

export const DepartmentsService = {
	get: () => Client.get("/company/department"),
	getByIds: ({ search }: { search: DepartmentFilters }) => {
		const searchConditions = search ? getDepartmentFilters({ searchPayload: search }) : undefined;

		return Client.post<ResponseList<Department>>(
			"/company/department/search",
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
			})
		);
	},
	getById: (id: string) => Client.get<Department>(`/company/department/${id}`),
	delete: (id: string) => Client.delete(`/company/department/${id}`),
	create: (data: Partial<Department>) => Client.post("/company/department", data),
	update: (id: string, data: Partial<Department>) => Client.put(`/company/department/${id}`, data),
};
