import styles from "./index.module.scss"
import { useLoadDeals } from "../../../../service/api/queries/deals/useLoadDeals";
import { Plug } from "../helpers";
import { CardApplication } from "../../Card/card-application";
import { KindType } from "../../../../types";
import cn from "classnames";

type Props = {
  applicationId: string
  isArchived?: boolean
}

export const ChildDeals = (
  {
    applicationId,
    isArchived
  }: Props): JSX.Element => {
  const {list: dealsList, isLoading, refetch} = useLoadDeals({
    enabled: true, defaultFilter: {
      parentApplicationId: applicationId,
    }
  });

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Сделки
      </div>
      {dealsList
        ? <div className={styles.list}>
          {dealsList.map((app) => (
              <div key={app.id} className={cn(styles.card, {[ styles.archive ]: isArchived})}>
                <CardApplication
                  withStage
                  canOpenANewTab
                  application={app}
                  kind={KindType.DEAL}
                />
              </div>
            )
          )}
        </div>
        : <Plug plugText="У вас пока нет существующей сделки"/>}
    </div>
  )
}