import { Task as TaskType } from "../../../../../../../types";

import { CardTask } from "../../../../../index";
import { useDeleteTaskModal } from "../../../../../../../utils/hooks";

import { ReactComponent as Close } from "../../../../../../../assets/icons/close.svg";
import styles from "./index.module.scss";

type Props = {
	task: TaskType;
	refetch: () => void;
	onCardClick: () => void;
	onDeleteTask: () => void;
	disabled?: boolean;
};

export const TaskCard = ({ task, onCardClick, refetch, onDeleteTask, disabled }: Props): JSX.Element => {
	const {
		type,
		subtype,
		createdAt,
		description,
		creatorUser,
		responsibleUser,
		status,
		priority,
		duration,
		id,
		isSuccessFinished,
		deadline,
	} = task;

	const onSuccessDelete = () => {
		onDeleteTask();
		refetch();
	};

	const { openUnpinResult } = useDeleteTaskModal({ onSuccessDelete });

	const deleteTask = () => {
		openUnpinResult(id);
	};

	return (
		<div className={styles.root}>
			<h3>Задача</h3>

			<div className={styles.wrapper}>
				<div className={styles.card}>
					<CardTask
						label={`${type} ${subtype ? `- ${subtype}` : ""}`}
						date={createdAt}
						description={description}
						leftContact={creatorUser}
						rightContact={responsibleUser}
						status={status}
						important={priority === "important"}
						onCardClick={onCardClick}
						isSuccessFinished={isSuccessFinished}
						deadline={deadline}
					/>
				</div>

				{!disabled && (
					<div className={styles.icon}>
						<Close className={styles.close} onClick={deleteTask} />
					</div>
				)}
			</div>
		</div>
	);
};
