import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useGetAgencyAgrement } from "service/api/mutations/agency-agrement";
import { Header } from "./components/Header/Header";
import { Form } from "./components/Form/Form";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";
import { ACStickyFooter } from "./components/sticky-footer/ACStickyFooter";

export const AgencyAgrement = (applicationId): JSX.Element => {
  const [dropDown, setDropDown] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const {
    mutate,
    data,
    isLoading,
    error,
    mutation,
  } = useGetAgencyAgrement(applicationId)

  useEffect(() => {
    mutate()
  }, [mutate])

  const errorCode = (mutation?.error as any)?.response?.data?.error?.errorCode

  const formatDate = (inputDate: Date) => {
    const date = new Date(inputDate);
    const year = date.getFullYear().toString().slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${day}.${month}.${year}`;
  }

  useEffect(() => {
    if (errorCode === "AP005") {
      setIsEdit(true)
    }
  }, [errorCode, error, mutate])

  let submitCallback: any = {
    test: 'no',
    text: "",
    reset: "",
    buttonActive: ""
  }
  const [text1, setText1] = useState("")
  const [active, setActive] = useState(true)

  let call = (arr, text, reset, buttonActive) => {
    submitCallback.test = arr
    setText1(text)
    submitCallback.reset = reset
    setActive(buttonActive)
  }
  const submit = () => {
    if (typeof submitCallback.test === 'function') {
      submitCallback.test()
    }
  }
  const reset = () => {
    if (typeof submitCallback.reset === 'function') {
      submitCallback.reset()
    }
  }
  return (
    <div className={styles.root}>
      {!isLoading ?
        <>
          <div className={styles.wrap}>
            <Header
              data={data?.data}
              setDropDown={setDropDown}
              formatDate={formatDate}
              dropDown={dropDown}
              errorCode={errorCode}
              applicationId={applicationId}
              setIsEdit={setIsEdit}
              edit={isEdit}
              refetch={() => mutate()}
            />
            <div className={styles.stripe}/>
            {dropDown ?
              <div className={styles.content}>
                <div className={styles.body}>
                  <Form
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    data={data?.data}
                    applicationId={applicationId}
                    errorCode={errorCode}
                    refetch={mutate}
                    err={error}
                    call={call}
                  />
                </div>
                {isEdit &&
                  <ACStickyFooter
                    errorCode={errorCode}
                    initialSaveText={text1}
                    isEdit
                    resetFormValues={reset.bind(this)}
                    data={data?.data}
                    onClick={submit.bind(this)}
                    setIsEdit={setIsEdit}
                    isButtonActive={active}
                  />}
              </div>
              : <div className={styles.stripe2}/>
            }
          </div>
        </>
        : <Loader className={styles.loader}/>
      }
    </div>
  );
};
