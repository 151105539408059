export const CLEAN_UP = "sdelka/object-edit/CLEAN_UP";
export const SET_OBJECTS_GROUP_IDS = "sdelka/object-list/SET_OBJECTS_GROUP_IDS";
export const SET_OBJECTS_TYPE_FOR_FILTER = "sdelka/object-list/SET_OBJECTS_TYPE_FOR_FILTER";
export const SET_FILTERS_SHOW_BUTTON = "sdelka/object-list/SET_FILTERS_SHOW_BUTTON";

export type ObjectListAction =
	| { type: typeof CLEAN_UP }
	| { type: typeof SET_OBJECTS_GROUP_IDS; ids: string[] }
	| { type: typeof SET_FILTERS_SHOW_BUTTON; filtersShowButton: boolean }
	| { type: typeof SET_OBJECTS_TYPE_FOR_FILTER; filterTypes: string[] };

export const setObjectsGroupIds = (ids: string[]): ObjectListAction => ({
	type: SET_OBJECTS_GROUP_IDS,
	ids,
});

export const setObjectsFilterTypes = (filterTypes: string[]): ObjectListAction => ({
	type: SET_OBJECTS_TYPE_FOR_FILTER,
	filterTypes,
});

export const setFiltersShowButton = (filtersShowButton: boolean): ObjectListAction => ({
	type: SET_FILTERS_SHOW_BUTTON,
	filtersShowButton,
});

export const cleanUp = (): ObjectListAction => ({
	type: CLEAN_UP,
});
