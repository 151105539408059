import { useDispatch } from "react-redux";
import styles from "./index.module.scss"
import { useNewDate } from "../../../../../../../service/api/mutations";
import { ChangeDateModal } from "./ChangeDateModal";
import { openModal } from "../../../../../../../service/redux/modules/modal/modal.action";

type Props = {
  id: string
  onSuccess: () => void;
}

type Return = {
  openOpenChangeDateModal: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type UseOpenOpenChangeDateModal = (props: Props) => Return

export const UseOpenChangeDateModal: UseOpenOpenChangeDateModal = (
  {
    id,
    onSuccess
  }) => {

  const {mutate} = useNewDate({})
  const width = 660
  const dispatch = useDispatch()

  const openOpenChangeDateModal = () => {
    dispatch(
      openModal({
        width,
        contentClass: styles.root,
        body:
          <ChangeDateModal onChange={mutate} id={id} onSuccess={onSuccess}/>
      })
    )
  }

  return {
    openOpenChangeDateModal
  }
}