import * as Yup from "yup";

import { commonErrors } from "../../../../../../../../utils/errors";

export const userResolver = Yup.object().shape(
	{
		dob: Yup.string().test("dob", "Некорректная дата", (dob) => (dob ? new Date(dob) < new Date() : true)),
		email: Yup.string().when("email", {
			is: (email) => email?.lenght !== 0,
			then: Yup.string().email("Введите корректный E-mail"),
		}),
		workPhone: Yup.string()
			.ensure()
			.test("workPhone", commonErrors.badPhone, (workPhone) => {
				const numberPhone = workPhone?.replace(/\D/gi, "") || "";
				if (numberPhone[0] === "7") {
					return numberPhone.length === 11;
				}

				return numberPhone.length === 10;
			})
			.required(),
	},
	[
		["email", "email"],
		["workPhone", "workPhone"],
	]
);
