import { useEffect } from "react";
import cn from "classnames";

import { FeedsList } from "./FeedsList";
import { RealEstateAdPartnersInfo } from "../../pages/RealEstatePage/mode/RealEstateAd/components/RealEstateAdPlatform/components";
import { useLoadStatistics } from "../../../service/api/queries";

import styles from "./index.module.scss";

type Props = {
	title: string;
	small?: boolean;
	applicationId: string;
};

export const ObjectAdGlobalStatistics = ({ title, small = false, applicationId }: Props): JSX.Element => {
	const { data, isLoading, refetch, remove } = useLoadStatistics({
		applicationId,
	});

	useEffect(() => {
		if (applicationId) refetch();

		return () => remove();
	}, [applicationId]);

	const feedsData = {
		totalFeeds: data?.totalFeeds || 0,
		postedFeeds: data?.postedFeeds || 0,
		waitingFeeds: data?.waitingFeeds || 0,
		errors: data?.errors || 0,
	};

	return (
		<RealEstateAdPartnersInfo
			className={cn(styles.partnersInfo, { [styles.small]: small })}
			headerClassName={styles.partnersInfoHeader}
			statistics={data}
			small={small}
			isLoading={isLoading}
			applicationId={applicationId}
			disableDetalization
			header={
				<>
					<div className={styles.header}>
						<h3>{title}</h3>
					</div>

					<FeedsList small={small} feedsData={feedsData} />
				</>
			}
		/>
	);
};
