import { Lead } from "../../../../../../../../types";
import { AxiosResponse } from "axios";

import { toastError, toastSuccess } from "../../../../../../../UI";
import { useCreateLeadFromObject } from "../../../../../../../../service/api/mutations";
import { useQueryParams } from "../../../../../../../../utils/hooks";
import { useDispatch } from "react-redux";
import { closeDrawer, openDrawerInDrawer } from "../../../../../../../../service/redux/modules/drawer/drawer.action";
import { useOpenLead } from "../../../../../../LeadsAndDeals/helpers";
import { RealEstateView } from "../../../index";
import { useLoadParsedRealEstate } from "../../../../../../../../service/api/queries";
import { useGetNavigation } from "../../../../../helpers";

type Return = {
  handleCreateColdCall: (object: string) => void;
  isLoading: boolean;
};

type UseColdCall = (parserId?: string) => Return;

export const useColdCall: UseColdCall = (parserId?: string) => {
  const dispatch = useDispatch()
  const {handleOpenLead} = useOpenLead({
    refetch: () => {
    }
  })

  const {changeQueryParams, removeQueryParam} = useQueryParams([
    {name: "tab"},
    {name: "id"},
    {name: "parserRealEstateId"},
  ]);

  const onError = () => {
    toastError({text: "Не удалось совершить Холодный звонок"});
  };

  const {realEstate, isFetching: isFetchingParser, refetch: refetchParser, remove} = useLoadParsedRealEstate({
    id: parserId ?? "",
  });

  const {blocksIds} = useGetNavigation({
    realEstate,
    isParser: true,
  });

  const onSuccess = (res: AxiosResponse<Lead>) => {
    const {
      data: {id},
    } = res;

    dispatch(closeDrawer())
    toastSuccess({text: "Холодный звонок совершен"});
    changeQueryParams([{name: "id", newValue: id}, {name: "parserRealEstateId", newValue: parserId ?? ""}])
    handleOpenLead(id);
    setTimeout(() => {
      dispatch(
        openDrawerInDrawer({
          width: 800,
          children: <RealEstateView
            id={parserId ?? ""}
            blocksIds={blocksIds}
            realEstate={realEstate}
            refetch={refetchParser}
            remove={remove}
            isFetching={isFetchingParser}
          />,
          onClose: () => removeQueryParam(["parserRealEstateId"]),
        }))
    }, 100)
  };

  const {mutate, isLoading} = useCreateLeadFromObject({
    onSuccess,
    onError,
  });

  const handleCreateColdCall = (objectId: string) => {
    if (!isLoading) {
      mutate(objectId);
    }
  };

  return {
    handleCreateColdCall,
    isLoading,
  };
};
