import { useMutation } from "react-query";
import { AxiosResponse } from "axios";

import { api } from "../../index";
import { RealEstatePhoto } from "../../../../types";

const DEFAULT_MN = "sdelka/real-estate/rotate-photo/DEFAULT_MN";

type UseObjectRotatePhoto = {
	onSuccess?: (res: AxiosResponse<RealEstatePhoto>) => void;
	onError?: (e: unknown) => void;
};

export const useObjectRotatePhoto = ({ onSuccess, onError }: UseObjectRotatePhoto) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: api.realEstate.rotatePhoto,
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
