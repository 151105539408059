import { useForm } from "react-hook-form";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { CardObjectLessInfo, Comment } from "../../../../../../UI";
import { RealEstate, CategoryEnum } from "../../../../../../../types";

import styles from "../common.module.scss";
import fakeImgSrc from "../../../../../../../assets/images/fake-object-img.png";
import tyler from "../../../../../../../assets/images/tyler.jpeg";

const objectExample = {
	actual: "Актуальный",
	adDescription: "<br><br>Номер лота: 4102940",
	addressLabel: "Ленинский, Фестивальная улица, 39",
	category: CategoryEnum["Квартиры"],
	createdAt: "2021-09-02T06:57:17.828Z",
	dealType: "Продажа",
	floor: 3,
	floors: 9,
	id: "a2697a68-da41-443f-a515-19544d97732b",
	modifiedAt: "2021-09-02T16:31:43.216Z",
	objectDescription: "https://www.avito.ru/ulyanovsk/kvartiry/2-k._kvartira_53m_39et._1734970107\n",
	photos: [{ photo1280: fakeImgSrc }],
	responsibleUser: {} as any,
	price: "200000",
	pricePerUnit: "40000",
	rooms: "2",
	shortId: "4102940",
	saleSquare: "53.00",
	fullSquare: "53.00",
	unit: "м2",
} as RealEstate;

const text = `
  В комнате проведен достаточно некачественный ремонт.
  Отсутствует водонагреватель, соответственно будут проблемы с горячей водой.
  В комнате проведен достаточно некачественный ремонт.
  Отсутствует водонагреватель, соответственно будут проблемы. Отсутствует водонагреватель, соответственно будут проблемы."
`;

export const ObjectViews = (): JSX.Element => {
	const { register, setValue, getValues } = useForm({
		defaultValues: {
			prevComment: "Test prev Comment",
			comment: "Test comment",
		},
	});

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<h2>Какой объект будем смотреть</h2>
				<CardObjectLessInfo id={objectExample.id} clientObject={objectExample} />

				<Button classNames={{ root: styles.button }}>Перенести просмотр</Button>
			</div>

			<div className={styles.comments}>
				<Comment image={tyler} title="Комментарий риэлтора" text={text} />

				<Comment
					editable
					title="Ваш комментарий"
					text="Test comment"
					form={{ name: "prevComment", register, setValue }}
				/>
			</div>

			<div className={styles.comment}>
				<Comment
					isActive
					placeholder="Введите описание задачи"
					title="Ваш комментарий"
					text={getValues().comment}
					form={{ name: "comment", register, setValue }}
				/>
			</div>
		</div>
	);
};
