import { CollectionObject } from "../../../../../../../../../types";

import { useGetCompare, COMPARE_PAGE_LOAD_COMP_QN } from "../../../../../../../../../service/api/queries";
import { useDeleteUnactualCompareObject } from "../../../../../../../../../service/api/mutations";

type Props = {
	applicationId: string;
	isCompare: boolean;
};

type Return = {
	compareObjects: CollectionObject[];
	refetchCompare: () => void;
	isLoadingCompareRemoveObjectFromActual: boolean;
	handleCompareRemoveObjectFromActual: () => void;
	returnCompareLabelTitle: string;
	compareTitle: string;
};

type UseCompare = (props: Props) => Return;

export const useCompare: UseCompare = ({ applicationId, isCompare }) => {
	const { data, refetch: refetchCompare } = useGetCompare({
		id: applicationId,
		enabled: isCompare,
		queryName: COMPARE_PAGE_LOAD_COMP_QN,
	});

	const compareObjects = data || [];

	const { mutate, isLoading: isLoadingCompareRemoveObjectFromActual } = useDeleteUnactualCompareObject({
		onSuccess: refetchCompare,
	});

	const handleCompareRemoveObjectFromActual = () => mutate(applicationId);

	const returnCompareLabelTitle = "Вернуться к объекту";
	const compareTitle = "Конкурентный анализ";

	return {
		compareObjects,
		refetchCompare,
		isLoadingCompareRemoveObjectFromActual,
		handleCompareRemoveObjectFromActual,
		returnCompareLabelTitle,
		compareTitle,
	};
};
