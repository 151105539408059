import { RealEstateWithSchema } from "../../../../types";
import { useQuery } from "react-query";

import { api } from "../../index";

const DEFAULT_QN = "sdelka/real-estate/load-parser-real-estate/DEFAULT_QN";

type Props = {
	id: string;
	enabled?: boolean;
	queryName?: string;
	onSuccess?: () => void;
	onError?: () => void;
};

type Return = {
	realEstate: RealEstateWithSchema | undefined;
	isLoading: boolean;
	isFetching: boolean;
	refetch: () => void;
	remove: () => void;
};

type UseLoadParsedRealEstate = (props: Props) => Return;

export const useLoadParsedRealEstate: UseLoadParsedRealEstate = ({
	id,
	enabled = true,
	queryName,
	onError,
	onSuccess,
}) => {
	const loadRealEstate = () => api.realEstate.get({ id });

	const defaultQueryName = `${DEFAULT_QN}/${id}`;

	const { data, refetch, remove, isLoading, isFetching } = useQuery(
		queryName || defaultQueryName,
		loadRealEstate,
		{
			enabled: !!enabled && Boolean(id) && id !== "new",
			onError,
			onSuccess,
		}
	);

	// sort photos by order field
	data?.data.photos.sort((a, b) => {
		if (a.order < b.order) {
			return -1;
		}
		if (a.order > b.order) {
			return 1;
		}

		return 0;
	});

	return {
		realEstate: data?.data,
		isLoading,
		isFetching,
		refetch,
		remove,
	};
};
