import {
	CollectionObject,
	Compilation,
	RealEstate,
	AddProps,
	RemoveProps,
} from "../../../../../../../../../types";
import { AxiosResponse } from "axios";
import { QueryObserverResult, RefetchOptions } from "react-query";
import { useDispatch } from "react-redux";

import {
	useAddObjectToCompilation,
	useDeleteObjectFromCompilation,
} from "../../../../../../../../../service/api/mutations";
import {
	useLoadCompilation,
	COMPILATION_PAGE_LOAD_COMP_QN,
} from "../../../../../../../../../service/api/queries";
import { setHoveredRealEstateImg } from "../../../../../../../../../service/redux/modules/compilation/compilation.action";

type Props = {
	sampledApplicationId: string;
	isCompilation: boolean;
};

type Return = {
	compilationObjects: CollectionObject[];
	refetchCompilation: (
		options?: RefetchOptions | undefined
	) => Promise<QueryObserverResult<AxiosResponse<Compilation>, unknown>>;
	onAddCompilation: AddProps;
	onDeleteCompilation: RemoveProps;
};

type UseCompilation = (props: Props) => Return;

export const useCompilation: UseCompilation = ({ sampledApplicationId, isCompilation }) => {
	const dispatch = useDispatch();
	const { compilation, refetch: refetchCompilation } = useLoadCompilation({
		applicationId: sampledApplicationId,
		queryName: COMPILATION_PAGE_LOAD_COMP_QN,
		enabled: isCompilation,
	});

	const compilationObjects = compilation?.collectionObjects || [];

	const { mutate: mutateRemove, isLoading: isRemoveLoading } = useDeleteObjectFromCompilation({
		onSuccess: () => refetchCompilation(),
	});

	const { mutate: mutateAdd, isLoading: isAddLoading } = useAddObjectToCompilation({
		onSuccess: () => {
			refetchCompilation();
			dispatch(setHoveredRealEstateImg(undefined));
		},
	});

	const handleOnAdd = (objectData: RealEstate) => {
		const { applicationId, parserData, id } = objectData;

		if (applicationId) {
			mutateAdd({
				applicationId: sampledApplicationId,
				clientObjectApplicationIds: [applicationId],
			});

			return;
		}

		if (parserData && id) {
			mutateAdd({ applicationId: sampledApplicationId, parserObjectIds: [id] });
		}
	};

	const handleOnRemove = (objectData: RealEstate) => {
		const { applicationId: objectsApplicationId, id } = objectData;
		const objectId = objectsApplicationId || id;

		mutateRemove({ applicationId: sampledApplicationId, objectId });
	};

	const onAddCompilation: AddProps = {
		onAdd: handleOnAdd,
		isAddLoading,
	};

	const onDeleteCompilation: RemoveProps = {
		onRemove: handleOnRemove,
		isRemoveLoading,
	};

	return {
		compilationObjects,
		refetchCompilation,
		onAddCompilation,
		onDeleteCompilation,
	};
};
