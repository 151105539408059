import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";
    // Pagination, NoData

import { useLeadsAndDealsTabs } from "../../LeadsAndDeals/helpers";

import styles from "./index.module.scss";

// Сделки hidden while is not ready
const tabs = ["Знания"].map((tab) => ( // , "Тарифы", "Внедрения"
	<div className={styles.tabLabel}>{tab}</div>
));

export const DashboardSwitch = (): React.ReactElement => {
	const {
		curTab,
		// onChangeCurTab,
		// refetch,
		// leadsData,
		// applicationsData,
		// trafficData,
		// isFetching,
	} = useLeadsAndDealsTabs();


	return (
		<div className={styles.tabs}>
			<Tabs size="large" active={curTab} tabs={tabs} offset={36} /> 
			{/* onChange={onChangeCurTab} */}
		</div>		
	);
};
