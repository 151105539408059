import { ReactNode } from "react";
import cn from "classnames";

import {
  ButtonCloseBlack,
  ButtonMap,
  FixedNav,
  FixedNavMenu,
  FixedNavVertical,
  FixedNavVerticalMenu,
} from "../../index";
import { useGoBackRealEstate } from "../../../../utils/objects";

import styles from "./index.module.scss";

type Props = {
  children: ReactNode;
  leftBlock?: ReactNode;
  xNavigation?: {
    navigation: FixedNavMenu[];
    additionalLabel?: ReactNode | string;
  };
  yNavigation: FixedNavVerticalMenu[];
  activeYTab: string | number;
  yNavigationTop?: number;
  onCloseClick?: () => void;
  withGoBack?: {
    defaultBackPath?: string;
  };
  navVariant?: "white" | "grey";
  hasMap?: boolean
};

export const XYNavLayout = (
  {
    leftBlock,
    children,
    xNavigation,
    yNavigation,
    activeYTab,
    yNavigationTop,
    onCloseClick,
    withGoBack = {},
    navVariant = "white",
    hasMap
  }: Props): JSX.Element => {
  const {defaultBackPath} = withGoBack;
  const {goBack} = useGoBackRealEstate({defaultBackPath});

  return (
    <div className={styles.root}>
      <div className={styles.leftBlock}>
        {(withGoBack || onCloseClick) && (
          <>
            <div className={styles.goBack}>
              <ButtonCloseBlack onCloseClick={onCloseClick ?? goBack}/>
            </div>
            {hasMap &&
              <div className={styles.buttonMap}>
                <ButtonMap/>
              </div>
            }
          </>
        )}
        {leftBlock}
      </div>
      <div className={styles.rightBlock}>
        {xNavigation && (
          <FixedNav
            navigation={xNavigation.navigation}
            additionalLabel={xNavigation.additionalLabel}
            navVariant={navVariant}
          />
        )}
        <FixedNavVertical
          navigation={yNavigation}
          top={yNavigationTop}
          activeTab={activeYTab}
        />
        <div
          className={cn(styles.rightBlockContent, {
            [ styles.rightBlockMinHeight ]: xNavigation,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
