import cn from "classnames";
import { Contact } from "../../../../types";
import { NameWithPhone } from "../../Avatar";
import { formatPhone } from "../../../../utils/formatters";
import { ReactComponent as Close } from "../../../../assets/icons/close.svg";
import styles from "./index.module.scss";

type Props = {
	title?: string;
	contact: Contact;
	onClose?: () => void;
};

export const CardContact = ({ title, contact, onClose }: Props): JSX.Element => (
	<div className={cn(styles.root, { [styles.withClose]: Boolean(onClose) })}>
		<div className={styles.title}>{title}</div>

		<div>
			<NameWithPhone
				name={contact.name}
				secondName={contact.secondName}
				lastName={contact.lastName}
				
				classNames={{
					phone: styles.phone,
					displayName: styles.name,
				}}
				phone={contact.phones && contact.phones.length ? formatPhone(contact.phones[0].phone) : ""}
			/>

			{onClose && (
				<div className={styles.close}>
					<Close onClick={onClose} />
				</div>
			)}
		</div>
	</div>
);
