import {
	Conditions,
	GetTasksResponse,
	Task,
	TaskCount,
	TaskTypes,
	FinishTaskPayload,
	CreateTaskPayload,
	EditTaskPayload,
} from "../../../../types";

import { Client } from "../../Client";
import { getTasksFilters } from "../../helpers/tasks";

export const TasksService = {
	list: ({ search, take, skip }: any) => {
		const searchConditions: Conditions = search ? getTasksFilters(search) : [];
		return Client.post<GetTasksResponse>(
			"/tasks/filter",
			JSON.stringify({
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
				take,
				skip,
			})
		);
	},

	count: ({ search }: { search: any }) => {
		const searchConditions: Conditions = search ? getTasksFilters(search) : [];

		return Client.post<TaskCount>(
			"/tasks/count",
			JSON.stringify({
				take: 0,
				skip: 0,
				search: {
					filterType: "and",
					conditions: searchConditions,
				},
			})
		);
	},

	countForLeftMenu: () => Client.get<number>(`/tasks/countForLeftMenu`),

	createTask: (payload: CreateTaskPayload) => Client.post<Task>("/tasks", payload),
	updateTask: ({ id, editPayload }: EditTaskPayload) => Client.put<Task>(`/tasks/${id}`, editPayload),
	getTask: (id: string) => Client.get<Task>(`/tasks/${id}`),
	deleteTask: (id: string) => Client.delete(`/tasks/${id}`),
	types: () => Client.get<TaskTypes[]>("/tasks/types"),
	finishTask: ({ id, data }: FinishTaskPayload) => Client.patch<Task>(`/tasks/${id}/finish`, data),
	finishTaskSuccess: ({ id, data }: FinishTaskPayload) => Client.patch<Task>(`/tasks/${id}/finishSuccess`, data),
	refuseTask: ({ id, data }: FinishTaskPayload) => Client.patch<Task>(`/tasks/${id}/refuse`, data),
};
