import { UseMutateFunction, useMutation } from "react-query";
import { PlatformsTableAll } from "../../../../types";

import { api } from "../../index";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/real-estate-ad/add-or-remove-all-platform-feed/DEFAULT_MN";

type Props = {
	onSuccess?: (
		data: AxiosResponse<
			{
				feedId?: string;
				success: boolean;
			}[]
		>,
		variables: PlatformsTableAll,
		context: unknown
	) => void | Promise<unknown>;
};

type Return = {
	mutate: UseMutateFunction<
		AxiosResponse<
			{
				feedId?: string;
				success: boolean;
			}[]
		>,
		unknown,
		PlatformsTableAll,
		unknown
	>;
	isLoading: boolean;
};

type UseAddOrRemoveAllPlatformFeed = (props: Props) => Return;

export const useAddOrRemoveAllPlatformFeed: UseAddOrRemoveAllPlatformFeed = ({ onSuccess }) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: PlatformsTableAll) => api.realEstateAd.startOrRemoveAllAds(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
