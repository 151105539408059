import { useRef, useState } from "react";
import cn from "classnames";

import { useClickOutside } from "../../../../../utils/hooks";
import { PersonalInviteForm } from "./PersonalInviteForm";
import { GeneralInvite } from "./GeneralInvite";
import { IMultiSelect, ISelect } from "../../../../../types";

import { ReactComponent as InvitePerson } from "../../../../../assets/icons/invite-person.svg";
import { ReactComponent as Chevron } from "../../../../../assets/icons/chevron.svg";
import styles from "./index.module.scss";

type Props = {
	positionOptions?: ISelect[];
	officeOptions?: IMultiSelect[];
	refetchInvites: () => void;
};

export const SendInvitation = ({ positionOptions, officeOptions, refetchInvites }: Props): JSX.Element => {
	const [isInviteState, setInviteState] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	useClickOutside({
		ref: wrapperRef,
		onClick: () => setInviteState(false),
	});

	return (
		<div ref={wrapperRef} className={styles.dropdown}>
			<button
				type="button"
				onClick={() => setInviteState((prev) => !prev)}
				className={cn(styles.button, {
					[styles.buttonActive]: isInviteState,
				})}
			>
				{!isInviteState && <InvitePerson className={styles.man} />}

				<span>Пригласить</span>

				{isInviteState && <Chevron className={styles.arrow} />}
			</button>

			{officeOptions && positionOptions && isInviteState && (
				<div className={styles.container}>
					<GeneralInvite />

					<PersonalInviteForm
						positionOptions={positionOptions}
						officeOptions={officeOptions}
						setInviteState={setInviteState}
						refetchInvites={refetchInvites}
					/>
				</div>
			)}
		</div>
	);
};
