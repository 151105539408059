import { useEffect, useState } from "react";

import { DefaultTasksFiltersType, TaskUserRole } from "../../../../types";

type Filter = keyof DefaultTasksFiltersType;

export type RoleRelatedFilters = Record<TaskUserRole, Filter[]>;

// filters related to any role
const globalFilters: Filter[] = [
	"taskType",
	"taskSubType",
	"deadlineFrom",
	"deadlineTo",
	"createdAtFrom",
	"createdAtTo",
];

/**
 * Map "role" -> "filter", describing which filters can be applied with role
 */
const roleRelatedFiltersMap: RoleRelatedFilters = {
	executor: [...globalFilters, "creatorId"],
	responsible: [...globalFilters, "executorUserId", "creatorId"],
	creator: [...globalFilters, "participantUserId"],
	allowed: [...globalFilters, "creatorId", "participantUserId"],
};

type Props = {
	defaultTasksFilter: DefaultTasksFiltersType;
	submitFilter: (newFilter: DefaultTasksFiltersType) => void;
	role: TaskUserRole;
};

export const useFilters = ({ defaultTasksFilter, submitFilter, role }: Props) => {
	const [filter, setFilter] = useState(defaultTasksFilter);
	const roleRelatedFilters = roleRelatedFiltersMap[role];

	useEffect(() => {
		const remainingFilters = roleRelatedFilters.reduce(
			(accum, filterName) => ({
				...accum,
				[filterName]: filter[filterName],
			}),
			{}
		);

		submitFilter({
			// clear everything except left menu and remaining filters
			...remainingFilters,
			status: filter.status,
			byMyRole: role,
		});
	}, [filter, role]);

	return {
		filter,
		setFilter,
		roleRelatedFilters,
	} as const;
};
