import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { ContactUpdate, Contact } from "../../../../types";

import { toastError } from "../../../../components/UI";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/contact/update-contact/DEFAULT_MN";

type UseContactUpdate = {
	onSuccess?: (data: Contact) => void;
};

type MutateProp = {
	id: string;
	payload: ContactUpdate;
};

export const useContactUpdate = ({ onSuccess }: UseContactUpdate) => {
	const onError = () => {
		toastError({
			text: "Произошла ошибка при обновлении контакта",
		});
	};

	const onSuccessUpdateCreate = (data: AxiosResponse<Contact>) => {
		if (onSuccess) {
			onSuccess(data.data);
		}
	};

	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: ({ payload, id }: MutateProp) => api.contacts.update({ id, data: payload }),
		onSuccess: (data: AxiosResponse<Contact>) => onSuccessUpdateCreate(data),
		onError,
	});

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading,
	};
};
