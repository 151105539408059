import { ApplicationInRealEstateList } from "../../../../../../types";

import { Popover } from "../../../../index";

import styles from "./index.module.scss";

type Props = {
	applications: ApplicationInRealEstateList[];
};

export const ApplicationPopover = ({ applications }: Props): JSX.Element | null => {
	if (!applications || !applications.length) {
		return null;
	}

	const {
		stage: { background, stageNo },
		whoIsClient,
	} = applications[0];
	const backgroundColor = background || "#FFE254";

	return (
		<div style={{ background: backgroundColor }} className={styles.root}>
			<Popover
				overElement={
					<div className={styles.overRoot}>
						<div style={{ background: backgroundColor }} className={styles.popoverCircle}>
							{stageNo}
						</div>

						<div className={styles.whoIsClient}>
							{`${whoIsClient === "buyer" ? "Покупка" : "Продажа"}. Этап ${stageNo}`}
						</div>
					</div>
				}
				position="left"
			/>
		</div>
	);
};
