import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import cn from "classnames";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { arrayIsEqual } from "../../../../utils/arrayIsEqual";
import { SelectWithPreviewOption } from "../../../../types";

import styles from "./index.module.scss";

type Props = {
	defaultValue?: string[] | string;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	name: string;
	options: SelectWithPreviewOption[];
	multiselect?: boolean;
	required?: boolean;
};

const Multiselect = forwardRef<
	HTMLDivElement,
	Props & {
		openToTop: boolean;
		openToRight: boolean;
		setIsPreviewOpen: (value: boolean) => void;
	}
>(({ defaultValue, options, setValue, name, openToTop, openToRight, setIsPreviewOpen }, ref): JSX.Element => {
	const convertedOptions = useMemo(
		() =>
			options.map((option) => ({
				group: option.group,
				values: option.values,
				hotValues: option.values.map((i) => i.value),
			})),
		[options]
	);

	const [selected, setSelected] = useState<string[]>((defaultValue as string[]) || []);

	useEffect(() => {
		const isEqual = arrayIsEqual(defaultValue as string[], selected);
		if (!isEqual && defaultValue) {
			setSelected(defaultValue as string[]);
		}
	}, [defaultValue]);

	useEffect(() => setValue(name, selected), [selected]);

	const onOptionClick = (value) => {
		if (selected.includes(value)) {
			return setSelected(selected.filter((i) => i !== value));
		}

		return setSelected(selected.concat(value));
	};

	const onGroupClick = (values) => {
		if (values.every((v) => selected.includes(v))) {
			return setSelected(selected.filter((el) => !values.includes(el)));
		}

		return setSelected(selected.concat(values));
	};

	return (
		<div className={styles.root}>
			{convertedOptions.map((option) => (
				<div
					key={option.group}
					className={cn(styles.optionWrapper, {
						[styles.optionWrapperSelected]: option.hotValues.some((v) => selected.includes(v)),
					})}
				>
					<Button
						onClick={() => onGroupClick(option.hotValues)}
						classNames={{ root: styles.optionGroup }}
						variant="text"
					>
						{option.group}
					</Button>

					{option.values.map((item, index) => (
						<div
							ref={ref}
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							className={styles.buttonWrapper}
							onMouseEnter={() => setIsPreviewOpen(true)}
							onMouseLeave={() => setIsPreviewOpen(false)}
						>
							<label
								className={cn(styles.label, {
									[styles.checked]: selected.includes(item.value),
								})}
							>
								<input
									onClick={() => onOptionClick(item.value)}
									type="checkbox"
									className={styles.checkbox}
								/>
								<span className={styles.checkboxBg} />
								<span
									className={cn(styles.labelText, {
										[styles.labelWithSign]: item.label.length > 1,
									})}
								>
									{item.label}
								</span>
							</label>
							<img
								src={item.src}
								alt="preview"
								className={cn(styles.preview, {
									[styles.previewLeft]: openToRight,
									[styles.previewTop]: openToTop,
								})}
							/>
						</div>
					))}
				</div>
			))}
		</div>
	);
});

export const SelectWithPreview = ({
	defaultValue,
	options,
	multiselect,
	setValue,
	register,
	name,
	required,
}: Props): JSX.Element => {
	// Preview placement
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);
	const [openToTop, setOpenToTop] = useState(false);
	const [openToRight, setOpenToRight] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);

	register(name, { required });

	const checkForOpenToTop = (pHeight: number): boolean => {
		const contentPosition = wrapperRef?.current?.getBoundingClientRect();

		const y = contentPosition?.y || 0;
		const height = contentPosition?.height || 0;

		return Boolean(contentPosition && pHeight <= y + height + 290);
	};

	const checkOpenToRight = () => {
		const position = wrapperRef?.current?.getBoundingClientRect();

		return !!(position && position.left <= 433);
	};

	useEffect(
		() => setOpenToTop(checkForOpenToTop(window.innerHeight)),
		[wrapperRef.current, window.pageYOffset, isPreviewOpen]
	);
	useEffect(() => setOpenToRight(checkOpenToRight()), [wrapperRef.current, window.pageXOffset]);

	// Single choose
	const [selected, setSelected] = useState<string>((defaultValue as string) || "");
	useEffect(() => {
		if (selected) {
			setValue(name, selected);
		}
	}, [selected]);

	const onOptionClick = (value) => setSelected(value);

	// Multi Variant
	if (multiselect)
		return (
			<Multiselect
				ref={wrapperRef}
				register={register}
				setValue={setValue}
				name={name}
				options={options}
				defaultValue={defaultValue}
				required={required}
				openToTop={openToTop}
				openToRight={openToRight}
				setIsPreviewOpen={setIsPreviewOpen}
			/>
		);

	return (
		<div className={styles.root}>
			{options.map((option) => (
				<div
					key={option.group}
					className={cn(styles.optionWrapper, {
						[styles.optionWrapperSelected]: option.values.some((v) => v.value === selected),
					})}
				>
					<div className={styles.optionGroup}>{option.group}</div>
					{option.values.map((item, index) => (
						<div
							ref={wrapperRef}
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							className={styles.buttonWrapper}
							onMouseEnter={() => setIsPreviewOpen(true)}
							onMouseLeave={() => setIsPreviewOpen(false)}
						>
							<label
								className={cn(styles.label, {
									[styles.checked]: selected === item.value,
								})}
							>
								<input
									onClick={() => onOptionClick(item.value)}
									type="checkbox"
									className={styles.checkbox}
								/>
								<span className={styles.checkboxBg} />
								<span
									className={cn(styles.labelText, {
										[styles.labelWithSign]: item.label.length > 1,
									})}
								>
									{item.label}
								</span>
							</label>
							<img
								src={item.src}
								alt="preview"
								className={cn(styles.preview, {
									[styles.previewLeft]: openToRight,
									[styles.previewTop]: openToTop,
								})}
							/>
						</div>
					))}
				</div>
			))}
		</div>
	);
};
