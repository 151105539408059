import { useDebouncedCallback } from "use-debounce";

import { toastError } from "../../../../../../../UI";
import { useApplicationRealEstateUpdate } from "../../../../../../../../service/api/mutations";

type Props = {
	applicationId: string;
};

export const useUpdateDescription = ({ applicationId }: Props) => {
	const onError = () => {
		toastError({ text: "Не удалось изменить комментарий у объекта" });
	};

	const { mutate, isLoading } = useApplicationRealEstateUpdate({
		onError,
	});

	const handleSaveComment = useDebouncedCallback((objectDescription: string) => {
		const payload = {
			id: applicationId,
			data: {
				objectDescription,
			},
		};
		mutate(payload);
	}, 1000);

	return {
		isLoading,
		handleSaveComment,
	};
};
