import cn from "classnames";
import { Lead, LeadStatusEnum } from "../../../../../../../types";
import { ReactComponent as FreezeIcon } from "../../../../../../../assets/icons/iconFreeze.svg";
import styles from "./index.module.scss";
import { MainActionButton, PreApplications } from "./components";
import { useDefferedModal } from "./helpers/useChangeLeadStatus/Modals";

type Props = {
  lead: Lead;
  refetch: () => void;
  isLoading?: boolean
};

export const LeadActions = (
  {
    lead,
    refetch,
    isLoading
  }: Props): JSX.Element => {
  const {
    status,
    childPreApplications,
  } = lead;

  const taken = status !== LeadStatusEnum.free && status !== LeadStatusEnum.new
  const {openDefferedModal} = useDefferedModal({
    leadId: lead.id,
    refetchLead: refetch,
  });

  const canFreezeLead = status === LeadStatusEnum.in_progress && lead.childPreApplications?.find(app => !app.isArchive) === undefined

  return (
    <div className={styles.root}>
      {taken &&
        <PreApplications
          preApplications={childPreApplications}
          status={status}
          lead={lead}
          refetch={refetch}
        />
      }
      <div className={cn(styles.buttonContainer)}>
        {canFreezeLead &&
          <div
            className={styles.freezeContainer}
            onClick={openDefferedModal}
          >
            <FreezeIcon/>
            <div className={styles.actionText}>
              Заморозить
            </div>
          </div>
        }
        <MainActionButton
          lead={lead}
          refetch={refetch}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
