import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RealEstateWithSchema } from "../../../../types";

import { Coord, setAddress } from "../../../../service/redux/modules/object-edit/object-edit.action";
import { useFindAddressDetailed, useFindPointDetailed } from "../../../../service/api/mutations";
import { useSelect, selectObjectEdit } from "../../../../service/redux/selectors";

type Props = {
	realEstate?: RealEstateWithSchema;
	isEditOrCreate?: boolean;
	allowChangeByMap?: boolean
};

type Return = {
	onMapClick: (coordinates: [number, number]) => Promise<void>;
	point: Coord
};

type UseMapAdjust = (props: Props) => Return;

export const useMapAdjust: UseMapAdjust = ({ isEditOrCreate, realEstate, allowChangeByMap }: Props) => {
	const dispatch = useDispatch();
	const { addressId, addressLabel, noHomeNumber, coord } = useSelect(selectObjectEdit);

	const findAddress = useFindPointDetailed();

	useEffect(() => {
		if (allowChangeByMap === false) {
			return
		}
		dispatch(
			setAddress({
				coord: {
					lat: realEstate?.location?.coordinates[0] || NaN,
					lon: realEstate?.location?.coordinates[1] || NaN,
				},
				addressId: realEstate?.addressId,
				addressLabel: realEstate?.addressLabel,
			})
		);

	}, [allowChangeByMap, dispatch, realEstate, realEstate?.location, realEstate?.location?.coordinates]);

	const [point, setPoint]  = useState(coord)

	const onMapClick = async (coordinates: [number, number]) => {
			if (isEditOrCreate) {
				const address = await findAddress.mutateAsync(`${coordinates[1]},${coordinates[0]}`);
				setPoint({lat: coordinates[0], lon: coordinates[1]})
				if (allowChangeByMap === false) {
					dispatch(
						setAddress({
							coord: {
								lat: coordinates[0],
								lon: coordinates[1],
							},
							addressId,
							addressLabel,
						})
					);
				} else {
					dispatch(
						setAddress({
							coord: {
								lat: coordinates[0],
								lon: coordinates[1],
							},
							addressId: address.data[0]?.id && !noHomeNumber ? address.data[0]?.id : addressId,
							addressLabel: address.data[0]?.label && !noHomeNumber ? `${address.data[0].label}` : addressLabel,
						})
					);
				}
			}
	};

	return { onMapClick, point };
};
