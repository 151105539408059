import { useEffect, useRef } from "react";
import { H3 } from "@sdelka_crm/sdelka-crm-component-library";

import { Contacts, ObjectLayoutLoading, ObjectPhotoGallery } from "../../../../UI";
import {
  AdDescription,
  BuildingInfo,
  CopiedBy,
  Description,
  Footer,
  Header,
  MainInfo,
  Responsibles,
} from "./components";
import { useDispatch } from "react-redux";
import { useOfficialData } from "../../../../../service/api/queries";
import { RealEstateWithSchema } from "../../../../../types";
import { ReactComponent as Edit } from "../../../../../assets/icons/edit-page.svg";
import styles from "./index.module.scss";
import { OwnerComment } from "./components/OwnerComment";
import { StickyFooter } from "./components/stickyFooter/StickyFooter";
import { CollectionList } from "./components/collection-list/CollectionList";
import { ObjectDoubles } from "./components/doubles/ObjectDoubles";
import { Contact } from "./components/contacts/Contacts";
import { useQueryParams } from "utils/hooks";
import { RealEstateEdit } from "components/pages/RealEstatePage/mode";
import { addTabInDrawer, closeDrawer } from "service/redux/modules/drawer/drawer.action";
import { useGetNavigation } from "../../helpers";
import {
  selectCurrentAccount,
  selectDrawer,
  selectObjectEdit,
  useSelect
} from "../../../../../service/redux/selectors";
import { setAddress } from "../../../../../service/redux/modules/object-edit/object-edit.action";
import { CallCenterMenu } from "./components/StickyMenu/components";

type Props = {
  id: string;
  blocksIds?: Record<string, string>;
  realEstate?: RealEstateWithSchema;
  isFetching: boolean;
  refetch: () => void;
  remove: () => void;
  inDrawer?:boolean
  isFromLead?: boolean
  refetchList?: () => void
};

export const RealEstateView = (
  {
    id,
    blocksIds,
    realEstate,
    isFetching,
    refetch,
    remove,
    isFromLead,
    inDrawer,
    refetchList
  }: Props): JSX.Element => {
  const {catalog, objectDescription, applicationId, shortType, parserData} = realEstate || {};
  const {yNavigation} = useSelect(selectDrawer)
  const { currentAccount } = useSelect(selectCurrentAccount);
  const {officialData} = useOfficialData({id: realEstate?.addressId});
  const contentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const isFromParser = catalog === "база агентств" || catalog === "база собственников";
  const idForDoubles = parserData ? realEstate?.id : realEstate?.applicationId

  const canViewCallCenterMenu = realEstate?.needsCallCenterCheck && isFromParser && currentAccount?.callCenterAccess;

  const editNav = {
    label: <Edit/>,
    title: "edit",
    height: 90,
  };
  const editChildren = (realEstate &&
    <RealEstateEdit
      id={id}
      realEstate={realEstate}
      refetch={refetch}
      remove={remove}
      isFetching={isFetching}
    />
  )

  const {
    queryParams: {mode},
  } = useQueryParams<{ mode: string }>([{name: "mode"}]);

  useEffect(() => {
    if (mode === "edit" && !yNavigation?.navigation.find(el => el.title === "edit")) {
      dispatch(
        addTabInDrawer({
          newChildren: editChildren,
          newNavigation: editNav,
        })
      );
    }
  }, [mode])

  useEffect(() => () => {
    remove();
  }, [remove]);

  if ( !realEstate) {
    return <ObjectLayoutLoading/>;
  }

  const coords = realEstate?.point ?? realEstate.location?.coordinates;

  if (coords) {
    dispatch(
      setAddress({
        coord:
          {
            lat:  Number(coords[0]),
            lon: Number(coords[1]),
          },
        addressId: realEstate.addressId,
        addressLabel: realEstate.addressLabel,
        dontMarkOnMap: realEstate.category !== "Дом",
      })
    );
  }


const handleCallCenterAction = () => {
  if (refetchList) {
    refetchList()
  }
  dispatch(closeDrawer())
}

  return (
    <div className={styles.root}>
      <div id={blocksIds?.common}>
        <Header realEstate={realEstate}/>
      </div>
      <ObjectPhotoGallery realEstate={realEstate} refetch={refetch} isFromParser={isFromParser}/>

      {canViewCallCenterMenu && (
        <div className={styles.callCenterMenu}>
          <CallCenterMenu
            parserObjectId={realEstate.id}
            phone={realEstate.parserPhones && realEstate.parserPhones[0]?.phone}
            price={+realEstate.price}
            ownerComment={realEstate.ownerComment}
            callCenterComment={realEstate.callCenterComment}
            onSuccess={handleCallCenterAction}
          />
        </div>
      )}

      <div ref={contentRef} className={styles.content}>
        <div className={styles.contentContainer}>
          {isFromParser
            ? <Contact realEstate={realEstate}/>
            : <>
              <Contacts
                type={shortType?.whoIsClient ?? "seller"}
                applicationId={applicationId}
                label={"Контакт"}
                classNames={styles.contacts}
              />
            </>
          }
          <Responsibles responsibleUser={realEstate.responsibleUser}/>
          {isFromParser && <OwnerComment ownerComment={realEstate.ownerComment}/>}
          {isFromParser && <CopiedBy realEstate={realEstate}/>}
          {realEstate.adDescription && <AdDescription adDescription={realEstate.adDescription}/>}
          <div id={blocksIds?.info}>
            <MainInfo realEstate={realEstate}/>
          </div>
          <div>
            <CollectionList
              isFromParser={isFromParser}
              realEstate={realEstate}
              forAdded={false}
            />
          </div>
          <div>
            <CollectionList
              isFromParser={isFromParser}
              realEstate={realEstate}
              forAdded={true}
            />
          </div>
          <div>
            <ObjectDoubles id={idForDoubles ?? ""} fromAgency/>
          </div>
          <div>
            <ObjectDoubles id={idForDoubles ?? ""}/>
          </div>
          {!isFromParser && (
            <div className={styles.adDescription} id={blocksIds?.description}>
              <Description objectDescription={objectDescription} applicationId={id}/>
            </div>
          )}
          <div id={blocksIds?.official}>
            <H3>Сведения с официальных ресурсов</H3>
          </div>
          <BuildingInfo officialData={officialData}/>
        </div>
        <Footer realEstate={realEstate}/>
        <StickyFooter
          realEstate={realEstate}
          realEstateRefetch={refetch}
          isFromParser={isFromParser}
          isFromLead={isFromLead}
        />
      </div>
    </div>
  );
};
