import { AxiosResponse } from "axios";
import { useMutation } from "react-query";

import { ErrorCodeEnum } from "../../../../types";
import { api } from "../../api";

const mutationKey = "sdelka/auth/checkConfirm";

export type SmsCodeConfirmationResponsePayload = {

	error: {
		errorCode: ErrorCodeEnum;
		payload: {
			token: string;
		};
	};
};
// mutationFn: (data: { applicationId: string; responsibleUsers: string[] }) =>
// api.application.addEmployee(data),
export type SmsCodeConfirmationResponse = AxiosResponse<SmsCodeConfirmationResponsePayload>;

export type Props = {
	onSuccess?: (response: SmsCodeConfirmationResponse) => void;
};
// todo
export const useSmsCodeConfirmation = ( onSuccess : Props) => {
	const mutation = useMutation({
		mutationKey,
		// mutationFn: () => api.auth.confirmSmsCode(data),
		mutationFn: api.auth.confirmSmsCode,
		onSuccess: (data) => {

    },
		onError: (error, variables, context) => {
			console.error("Mutation error:", error,variables,context);
		},
		
	},);

	return {
		mutate: mutation.mutate,
		isSuccess: mutation.isSuccess,
		data: mutation.data,
		error: mutation.error
	};
}
	