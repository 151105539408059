import { Control } from "react-hook-form";

import { QuestionnaireValue, RadioButton } from "../../../index";

const BooleanOptions = [
	{ value: 1, label: "Да" },
	{ value: 0, label: "Нет" },
];

type Props = {
	handleOnChange: (value: QuestionnaireValue) => void;
	control: Control<any>;
	name?: string;
	disabled?: boolean;
	options?: { label: string; value: number }[];
};

export const Boolean = ({ name, control, handleOnChange, disabled, options }: Props): JSX.Element => {
	const getOptions = () => {
		const radioOptions = options ?? BooleanOptions;

		return radioOptions.map((item) => ({
			...item,
			disabled,
		}));
	};

	return (
		<RadioButton
			name={name as string}
			control={control}
			options={getOptions()}
			handleOnChange={handleOnChange}
		/>
	);
};
