import { adStatuses, AdStatusesEnumV2, RealEstate } from "../../../../../types";
import styles from "./index.module.scss";
import { useAdColumns } from "./components/helpers";
import { Table } from "../../../../UI";
import { useBlinkRow } from "../../../../../utils/hooks";
import { ReactNode, useCallback } from "react";
import { ReactComponent as IconPublished } from "../../../../../assets/icons/iconPublished.svg"
import { ReactComponent as IconNotPublished } from "../../../../../assets/icons/iconNotPublished.svg"
import { ReactComponent as IconError } from "../../../../../assets/icons/iconError.svg"
import { ReactComponent as IconRNotRequested } from "../../../../../assets/icons/iconNotRequested.svg"
import { ReactComponent as Archive } from "../../../../../assets/icons/archive-2.svg"

type Props = {
  objects: RealEstate[];
  isFetching: boolean;
  counts: { wait: number, inAd: number, noAd: number }
};

const stageKeys: Record<AdStatusesEnumV2, ReactNode> = {
  [ AdStatusesEnumV2.ERROR ]: <IconError className={styles.alertIcon}/>,
  [ AdStatusesEnumV2.NOT_POSTED ]: <Archive className={styles.timerIcon}/>,
  [ AdStatusesEnumV2.POSTED ]: <IconPublished className={styles.inWorkIcon}/>,
  [ AdStatusesEnumV2.ARCHIVE ]: <IconNotPublished className={styles.successIcon}/>,
  [ AdStatusesEnumV2.WAITING ]: <IconRNotRequested className={styles.cancelIcon}/>,
};

export const AdvertisedObjectsTable = ({objects, isFetching, counts}: Props): JSX.Element => {
  const haveObject = objects.length > 0;

  const columns = useAdColumns();
  const {rowsToBlink, callback} = useBlinkRow();

  const getCount = (title: string) => {
    switch (adStatuses[ title ]) {
      case "Ожидает размещения":
        return counts.wait
      case "В рекламе":
        return counts.inAd
      case "Без рекламы":
        return counts.noAd
      default:
        return 0
    }
  }

  const renderTitle: (item: string) => JSX.Element = useCallback(
    (title) => (
      <div className={styles.tableGroupTitle}>
        <div className={styles.title}>
          {stageKeys[ title ]}
          {adStatuses[ title ]}
        </div>
        <div className={styles.count}>
          {`${getCount(title)} объектов`}
        </div>
      </div>
    ),
    [getCount]
  );

  return (
    <div className={styles.root}>
      {isFetching ? (
        Array.from(Array(10).keys()).map((item, index) => <div key={item + index} className={styles.skeleton}/>)
      ) : (
        <>
          {!haveObject ? (
            <div className={styles.noObjects}>
              <span>Похожих объектов не найдено</span>
            </div>
          ) : (
            <div className={styles.wrapper}>
              {objects?.length > 0 || isFetching ? (
                  <Table<RealEstate>
                    idKey="id"
                    data={objects}
                    columns={columns}
                    groupKey="status"
                    rowClass={styles.row}
                    transformGroupTitle={renderTitle}
                    loading={{isLoading: isFetching, rowsCount: 5}}
                    activeRow={{
                      accessor: "",
                      activeId: 1,
                    }}
                    blinkRows={rowsToBlink}
                  />) :
                Array.from(Array(10).keys()).map((item, index) => <div key={item + index} className={styles.skeleton}/>)
              }
            </div>
          )}
        </>
      )}
    </div>
  );
};
