import { SelectOption } from "../../types";

const MIN = 60;
const HOUR = MIN * 60;
const DAY = HOUR * 24;

export const durationOptions: SelectOption[] = [
	{
		label: "5 мин",
		value: String(MIN),
	},
	{
		label: "15 мин",
		value: String(MIN * 15),
	},
	{
		label: "30 мин",
		value: String(MIN * 30),
	},
	{
		label: "1 ч.",
		value: String(HOUR),
	},
	{
		label: "2 ч.",
		value: String(HOUR * 2),
	},
	{
		label: "3 ч.",
		value: String(HOUR * 3),
	},
	{
		label: "6 ч.",
		value: String(HOUR * 6),
	},
	{
		label: "12 ч.",
		value: String(HOUR * 12),
	},
	{
		label: "день",
		value: String(DAY),
	},
];

export const remindOptions = [
	...durationOptions,
	{
		label: "2 дн.",
		value: String(DAY * 2),
	},
	{
		label: "3 дн.",
		value: String(DAY * 3),
	},
];
