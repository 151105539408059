import { UseMutateFunction, useMutation } from "react-query";
import { api } from "../../../../../../../../../../../../service/api";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/leads/add-lead/DEFAULT_MN";

type Props = {
	onSuccess?: (
		data: AxiosResponse<{ success: boolean; id: string }>,
		variables: string,
		context: unknown
	) => void;

	onError?: (data: AxiosResponse<string>, variables: string, context: unknown) => void;
	contactId?: string
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<{ success: boolean; id: string }>>;
	isLoading: boolean;
};

type UseStartAd = (props: Props) => Return;

// @ts-ignore
export const useStartLead: UseStartAd = ({ onSuccess, onError , contactId}) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (adSource: string) => api.leads.addLead(adSource, contactId),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
