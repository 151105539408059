import cn from "classnames";
import { Loader } from "@sdelka_crm/sdelka-crm-component-library";

import { CompanyPublicData } from "../../../../../../types";

import imgSrc from "../../../../../../assets/images/company-img.png";
import styles from "./index.module.scss";

type Props = {
  company?: CompanyPublicData;
};

export const CompanyInformation = ({company}: Props): JSX.Element => (
  <div className={cn(styles.root, "animate__animated animate__fadeIn animate__faster")}>
    {company ? (
      <>
        <img src={imgSrc} alt="company" className={styles.image}/>
        <div className={cn(styles.content, "animate__animated animate__fadeIn animate__faster")}>
          <h4 className={styles.title}>{company?.companyName}</h4>
          <p className={styles.desc}>
            Lorem Ipsum - это текст-&quot;рыба&quot;, часто используемый в печати и вэб-дизайне. Lorem Ipsum
            является стандартной &quot;рыбой&quot; для текстов на латинице с начала XVI века. В то время некий
            безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для
            распечатки образцов.
          </p>
          <div className={styles.footer}>
            <div className={styles.people}>Сотрудников в системе</div>
            <span className={styles.number}>{company?.users}</span>
            <div className={styles.phone}>
              <span>Телефон</span>
              <a className={styles.phone__link} href={`tel:${company.phone}`}>
                {company.phone || "не указан"}
              </a>
            </div>
          </div>
          <div className={styles.address}>
            <span>Адрес офиса</span>
            <span className={styles.address__text}>{company.addressLabel}</span>
          </div>
        </div>
      </>
    ) : (
      <Loader height={50}/>
    )}
  </div>
);
