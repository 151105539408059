import { useMemo } from "react";

import { useLoadFeedList } from "../../../../../../service/api/queries";

export const useFeedList = () => {
	const { data } = useLoadFeedList();

	const feedAds = useMemo(
		() =>
			data?.map((feed) => ({
				value: feed.id,
				label: feed.name,
			})),
		[data]
	);

	return {
		feedAds,
	};
};
