import { useDispatch } from "react-redux";
import { openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { FireModal } from "./FireModal";

type Props = {
  id: string
  refetch: () => void
}

type Return = {
  openFireModal: () => void;
}

export const useFireModal = (
  {
    id,
    refetch
  }: Props): Return => {
  const dispatch = useDispatch();

  const openFireModal = () => {
    dispatch(
      openModal({
        body: (
          <FireModal
            id={id}
            refetch={refetch}
          />
        ),
        width: 660,
      })
    );
  };

  return {
    openFireModal
  }
}