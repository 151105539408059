import styles from "./index.module.scss";

export const BodyLoading = (): JSX.Element => (
	<div className={styles.root}>
		<div className={styles.stages} />

		<div className={styles.blocks}>
			{[1, 2, 3].map((item) => (
				<div key={item} className={styles.block}>
					<div className={styles.header} />

					<div className={styles.blockItem} />
				</div>
			))}
		</div>
	</div>
);
