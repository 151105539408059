import { IUserEditFormRequest, IUserEditFormResponse } from "../../../../../types";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss"
import cn from "classnames";
import { Button, formatDate, InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { ReactComponent as PenIcon } from "../../../../../assets/icons/pencil.svg";
import { ageNounHelper } from "../../../../../utils/string";
import { initialize } from "../../../../../service/redux/modules/auth/auth.thunk";
import { useUpdateUserCard } from "../../../../../service/api/mutations";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { CalendarInPopup, InputList, MarkdownEditor, Select, Textarea, TransparentInput } from "../../../../UI";
import { parsePhone } from "../../../../../utils/parsers";
import { LOAD_POSITIONS_REQUESTS_QN, useLoadDepartments, useLoadPositions } from "../../../../../service/api/queries";
import { ReactComponent as SaveText } from "../../../../../assets/icons/saveComment.svg";

type Props = {
  user?: IUserEditFormResponse;
  canEdit?: boolean;
  refetch?: () => void;
};

export const Characteristics = (
  {
    user,
    canEdit,
    refetch
  }: Props) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false)
  const toggleMode = () => setEditMode((prev) => !prev);

  function calculateAge(date) {
    const birthDate = new Date(date);
    const otherDate = new Date();
    let years = (otherDate.getFullYear() - birthDate.getFullYear());
    if (otherDate.getMonth() < birthDate.getMonth() ||
      otherDate.getMonth() === birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
      years--;
    }
    return years;
  }

  const phoneInputsNameBuilder = (baseName: string, index: number, objectKey: string) =>
    index === 0 ? "mainPhone" : `${baseName}.${index}.${objectKey}`;

  const onEditFormSuccessfulSubmit = () => {
    refetch?.();
    setEditMode(false);
    dispatch(initialize());
  };
  const {userUpdate, isLoading} = useUpdateUserCard({
    onSuccess: onEditFormSuccessfulSubmit,
  });
  const {handleSubmit, register, setValue, formState, control, getValues} = useForm<IUserEditFormRequest>({
    defaultValues: {name: user?.name, secondName: user?.secondName, lastName: user?.lastName}
  });
  const onSubmit = (values: IUserEditFormRequest) => {
    const payload = {
      ...values,
    }
    userUpdate({id: user?.id || "", payload});
  };

  const {
    data: departments
  } = useLoadDepartments({});
  const {
    positions,
  } = useLoadPositions({queryName: LOAD_POSITIONS_REQUESTS_QN});

  const officeOptions = useMemo(
    () =>
      (departments ?? []).map((department) => ({
        label: department.name,
        value: department.id,
      })),
    [departments, departments?.length]
  );

  const positionOptions = useMemo(
    () =>
      positions?.map((position) => ({
        label: position.name,
        value: position.id,
      })),
    [positions, positions.length]
  );
  const [valueSpec, setValueSpec] = useState<string>(user?.specialisations ?? "")
  const [valueAbout, setValueAbout] = useState<string>(user?.aboutMe ?? "")
  const onSaveSpec = (val: string) => {
    if (val !== user?.specialisations) {
      onSubmit({id: user?.id || "", specialisations: val});
    }
  };

  const onSaveAbout = () => {

      onSubmit({id: user?.id || "", aboutMe: getValues("aboutMe")});

  };
  
  useEffect(() => {
    if (user) {
      setValue("aboutMe", user.aboutMe)
      setValue("specialisations", user.specialisations)
    }
  }, [setValue, user])

  const emailWatcher = useWatch({
    control,
    name: "email",
    defaultValue: user?.email || "",
  })
  const vkWatcher = useWatch({
    control,
    name: "vk",
    defaultValue: user?.vk || "",
  })
  const specWatcher = useWatch({
    control,
    name: "specialisations",
    defaultValue: user?.specialisations || "",
  })

  const aboutMeWatcher = useWatch({
    name: "aboutMe",
    control,
    defaultValue: user?.aboutMe || getValues("aboutMe"),
  });

  // @ts-ignore
  const {fields, append, remove} = useFieldArray({control: control as any, name: "extraPhones" as any});

  return (
    <div className={styles.root}>
      <div className={cn(styles.row, styles.header)}>
        <div className={styles.title}>Характеристики</div>
        {!editMode &&
          <Button variant="text" Icon={PenIcon} onClick={toggleMode}>
            Редактировать
          </Button>
        }
      </div>
      {!editMode
        ? <>
          <InfoRow label="Офис">
            {user?.officeName || "Не указан"}
          </InfoRow>
          <InfoRow label="Отдел">
            {user?.department?.name || "Не указан"}
          </InfoRow>
          <InfoRow label="Должность">
            {user?.position?.name || "Не указан"}
          </InfoRow>
          <InfoRow label="Дата рождения">
            {`${formatDate(user?.dob || new Date)} ${calculateAge(user?.dob)} ${ageNounHelper(calculateAge(user?.dob))}` || "Не указан"}
          </InfoRow>
          <InfoRow label="Дополнительный телефон">
            {user?.extraPhones?.join(", ") || "Не указан"}
          </InfoRow>
          <InfoRow label="VK">
            {user?.vk || "Не указан"}
          </InfoRow>
          <InfoRow label="E-mail">
            {user?.email || "Не указан"}
          </InfoRow>
        </>
        : <form onSubmit={handleSubmit(onSubmit)}>
          <InfoRow label="Офис">
            {user?.officeName || "Не указан"}
          </InfoRow>
          <InfoRow label="Отдел">
            <Select
              classNames={{
                selected: styles.selected
              }}
              name="departmentId"
              withBorder
              options={officeOptions}
              placeholder="Отдел"
              register={register}
              setValue={setValue}
              defaultValue={user?.departmentId}
              error={formState.errors?.departmentId?.message?.toString()}
            />
          </InfoRow>
          <InfoRow label="Должность">
            <Select
              classNames={{
                selected: styles.selected
              }}
              name="positionId"
              withBorder
              options={positionOptions}
              placeholder="Должность"
              register={register}
              setValue={setValue}
              defaultValue={user?.positionId}
              error={formState.errors?.positionId?.message?.toString()}
            />
          </InfoRow>
          <InfoRow label="Дата рождения">
            <div className={styles.calendar}>
              <CalendarInPopup
                form={{
                  name: "dob",
                  register,
                  setValue,
                  getValues,
                }}
                defaultDate={new Date(user?.dob ?? "")}
              />
            </div>
          </InfoRow>
          <InputList
            fieldName="extraPhones"
            fields={fields}
            control={control}
            nameBuilder={phoneInputsNameBuilder}
            append={append}
            remove={remove}
            firstLabel="Дополнительный телефон"
            otherLabels="Дополнительный телефон"
            objectKey="extraPhone"
            parser={parsePhone}
            haveFirstLabel={false}
            placeholder="Укажите телефон"
          />

          <InfoRow label={"VK"}>
            <TransparentInput
              className={styles.input}
              register={register}
              placeholder="vk"
              name="vk"
              withCleanUp={{setValue}}
              value={vkWatcher}
              error={formState?.errors?.secondName?.message?.toString()}
            />
          </InfoRow>
          <InfoRow label={"E-mail"}>
            <TransparentInput
              className={styles.input}
              register={register}
              placeholder="e-mail"
              name="email"
              withCleanUp={{setValue}}
              value={emailWatcher}
              error={formState?.errors?.secondName?.message?.toString()}
            />
          </InfoRow>
          <div className={styles.submitBtns}>
            <Button variant="outlined" onClick={toggleMode} isLoading={isLoading}>
              Отменить
            </Button>
            <Button type="submit" isLoading={isLoading}>
              Применить
            </Button>
          </div>

        </form>
      }
      <div>
        <div className={styles.title}>
          Описание
        </div>
        <div className={styles.subtitle}>
          Специализация
        </div>
        <Textarea
          name="specialisations"
          rows={4}
          register={register}
          className={styles.comment}
          onChange={(text) => setValueSpec(text)}
          readOnly={isLoading}
          value={specWatcher}
        />
        <div
          className={cn(styles.saveText, {[ styles.saveTextSaving ]: isLoading})}
          onClick={() => onSaveSpec(valueSpec)}
          hidden={isLoading}
        >
          <SaveText/>
        </div>
        <div className={styles.subtitle}>
          Обо мне
        </div>
        <MarkdownEditor
          name="aboutMe"
          setValue={setValue}
          getValues={getValues}
          register={register}
          error={formState.errors.aboutMe?.message?.toString()}
          curValue={aboutMeWatcher}
        />
        <div
          className={cn(styles.saveText)}
        >
          <div onClick={() => onSaveAbout()}>
            <SaveText/>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          <div>ID сотрудника:</div>
          <div>{user?.shortId}</div>
        </div>

      </div>
    </div>
  )
}