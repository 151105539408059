import { useDispatch } from "react-redux";
import { openModal } from "../../../service/redux/modules/modal/modal.action";
import { Discount } from "./discount/Discount";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import styles from "./index.module.scss"

type Props = {
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  onSave: (priceWithDiscount) => void;
  register: UseFormRegister<any>;
  control: Control<any>;
  price: string | number
}

type Return = {
  openDiscountModal: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type useDiscountModal = (props: Props) => Return

export const useDiscountModal: useDiscountModal = (
  {
    getValues,
    setValue,
    register,
    onSave,
    price,
    control,
  }) => {

  const dispatch = useDispatch()

  const openDiscountModal = () => {
    dispatch(
      openModal({
        width: 660,
        body: <Discount
          control={control}
          getValues={getValues}
          onSave={onSave}
          register={register}
          setValue={setValue}
          price={price}/>,
        classNames: {
          contentBody: styles.content
        }
      })
    )
  }
  return {
    openDiscountModal
  }
}