import { useDispatch } from "react-redux";
import { Lead } from "../../../../../../types";
import { closeModal, openModal } from "../../../../../../service/redux/modules/modal/modal.action";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import styles from "./index.module.scss"
import { closeDrawer } from "service/redux/modules/drawer/drawer.action";
import { ReactComponent as CheckMarkSuccess } from "../../../../../../assets/icons/CheckMarkSuccess.svg";
import classNames from "classnames";
import { useJoinToSdelkaPro } from "service/api/mutations/auth/useJoinToSdelkaPro";
import { TokenService } from "service/api/services/inner/TokenService";
import { initialize, useFinishRegistration } from "service/redux/modules/auth/auth.thunk";
// eslint-disable-next-line @typescript-eslint/naming-convention
// type UseOpenRelatedLeadModal = (props: Props) => Return
//CheckMark Success
export const JoinCompanyModal =() => {
  const dispatch = useDispatch()

	const {mutate, data, asyncMutate} = useJoinToSdelkaPro()

  const onApply = async () => {
    
    try {
      const response = await asyncMutate()
      if (response) {
        TokenService.setCurrentToken(response.data[0].token)
        await dispatch(useFinishRegistration());
        dispatch(initialize())
        dispatch(closeModal());
        dispatch(closeDrawer());
      }
      

    } catch (error) {
      console.error("Error during mutation:", error);
    }

  };
  const openJoinCompanyModal = () => {
    dispatch(
      openModal({
        width: 660,
        body:
          <div className={styles.wrap}>
            <div className={styles.block}>
              <CheckMarkSuccess />
              <div className={styles.text}>
                <h1>
                Запрос отправлен
                </h1>
                <p>
                  До подтверждения вступления в компанию вы можете использовать систему как участник СДЕЛКА ПРО
                </p>
              </div>
            </div>
            <Button onClick={onApply} classNames={{root: styles.button}}>
                Начать работу
            </Button>

					</div>
      })
    )
  }
  return {
    openJoinCompanyModal
  }
}