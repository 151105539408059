import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { InfoRow, TransparentInput } from "../../../index";
import styles from "../index.module.scss";
import {
	getMask
} from "../../../RealEstate/RealEstateForm/ObjectEditLayout/components/BuildingInfo/InputRows/Inputs/NumberInput/helpers";

type Props = {
	register: UseFormRegister<any>;
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	inputName: string;
	label?: string
	disabled?: boolean;
	handleOnChange: (value: string | number) => void;
	percent?: number
};

export const PriceInput = ({
	register,
	control,
	setValue,
	getValues,
	inputName,
	disabled,
	label,
	handleOnChange,
	percent,
}: Props): JSX.Element => {
	const debounced = useDebouncedCallback(handleOnChange, 0);

	const inputValue = useWatch({
		control,
		name: inputName,
		defaultValue: String(getValues(inputName)),
	});

	const handleOnChangeWithDebounce = (value: number | string) => {
		debounced(value);
	};

	const mask = getMask({  });

	return (
		<InfoRow
			label={label}
	    classNames={{
		  content: styles.root,
	}}>
			<TransparentInput
				mask={mask}
				register={register}
				placeholder="Указать ₽"
				name={inputName || "1"}
				value={inputValue}
				onChange={handleOnChangeWithDebounce}
				disabled={disabled}
				setValueForMask={setValue}
				textAlign="right"
			/>
			{percent !== undefined &&
				<div className={styles.root}>
				 <div style={{padding: "0 20px 0 20px"}}>
					 |
				 </div>
					<div>
						{`${percent.toFixed(2)}%`}
					</div>
				</div>
			}
		</InfoRow>
	);
};
