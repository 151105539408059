import { api } from "../../api";
import { useMutation } from "react-query";

const DEFAULT_MN = "sdelka/shows-views/pin-contact/DEFAULT_MN";

type Props = {
  isViews: boolean
  isAgent: boolean
  onSuccess?: () => void;
  onError?: () => void;
}

type Payload = {
  id: string
  contactId: string
}

export const usePinContactToShowView = (
  {
    isViews,
    isAgent,
    onSuccess
  }: Props) => {

  // eslint-disable-next-line no-nested-ternary
  const mutFn = isViews
    ? (isAgent ? api.showsViews.pinSellerAgentContact : api.showsViews.pinSellerContact)
    : (isAgent ? api.showsViews.pinBuyerAgentContact : api.showsViews.pinBuyerContact)


  const mutation = useMutation(DEFAULT_MN, {
    mutationFn: (payload: Payload) => mutFn(payload.id, payload.contactId),
    onSuccess
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    mutateAsync: mutation.mutateAsync
  }
}