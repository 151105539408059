import { RealEstateSchemaConfigField, FormPropsEditRealEstate } from "../../../../../../../../../../types";

import { Radio } from "../../../../../../../../index";
import { RequiredDots } from "../../RequiredDots";

import styles from "./index.module.scss";

type Props = {
	form: FormPropsEditRealEstate;
	field: RealEstateSchemaConfigField;
};

export const RadioSelect = ({ form, field }: Props): JSX.Element => {
	const {
		label,
		reqiredFor,
		dataType: { name, allowedValues },
	} = field;
	const { control } = form;

	if (!name) {
		return <></>;
	}

	const options =
		allowedValues?.map((allowed) => ({
			value: allowed,
			label: allowed,
		})) || [];

	return (
		<div className={styles.root}>
			<div className={styles.dots}>
				<RequiredDots required={reqiredFor} />
			</div>

			<label className={styles.label}>{label}</label>

			<Radio
				name={name}
				options={options}
				control={control}
				size="medium"
				classNames={{
					label: styles.radioLabel,
					groupRadioRoot: styles.radioRoot,
				}}
			/>
		</div>
	);
};
