import { CategoryEnum, RealEstateFilter } from "../../../../../../../types";
import { defaultValues as defaultFilters } from "../../../../../../../utils/clientObjects";

type WantedKeys = Array<keyof RealEstateFilter>;

const generalPersistentFilters: WantedKeys = [
	"type",
	"actual",
	"polygon",
	"address",
	"priceFrom",
	"priceTo",
	"pricePerUnitFrom",
	"pricePerUnitTo",
];

const wantedFiltersForCategory: Record<CategoryEnum, WantedKeys> = {
	// non-commercial
	[CategoryEnum.Квартира]: ["sourceSelect", "squareFrom", "squareTo", "rooms"],
	[CategoryEnum.Комната]: ["sourceSelect", "squareFrom", "squareTo"],
	[CategoryEnum.Дом]: ["sourceSelect", "squareAreaFrom", "squareAreaTo", "squareValue"],
	[CategoryEnum.Участок]: ["sourceSelect", "squareAreaFrom", "squareAreaTo", "squareValue"],
	[CategoryEnum.Гараж]: ["sourceSelect", "squareAreaFrom", "squareAreaTo", "squareValue"],

	// commercial
	[CategoryEnum.Земля]: [],
	[CategoryEnum.Офис]: [],
	[CategoryEnum["Торговая площадка"]]: [],
	[CategoryEnum["Свободное назначение"]]: [],
	[CategoryEnum.Склад]: [],
	[CategoryEnum.Здание]: [],
};

const pickFields = (keys: string[], obj: Record<string, unknown>, fallbackObj?: Record<string, unknown>) =>
	keys.reduce(
		(accum, key) => ({
			...accum,
			[key]: obj[key] ?? fallbackObj?.[key],
		}),
		{}
	);

export const getWantedFiltersForCategory = (
	categories: CategoryEnum[],
	currentFilter: RealEstateFilter
): Partial<RealEstateFilter> => {
	const categoryWantedKeys = categories.reduce(
		(accum, category) => accum.concat(wantedFiltersForCategory[category] ?? []),
		[] as WantedKeys
	);

	const allWantedKeys = generalPersistentFilters.concat(categoryWantedKeys);

	return pickFields(allWantedKeys, currentFilter, defaultFilters);
};
