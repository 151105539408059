import { useDispatch } from "react-redux";
import { LeadUpdatePayload, TransferLead, OnPinResponsiblePayload } from "../../../types";

import { toastError } from "../../../components/UI";
import {
	activateLoading,
	closeModal,
	deactivateLoading,
	openModal,
} from "../../../service/redux/modules/modal/modal.action";
import { useTransferLead, useUpdateLead } from "../../../service/api/mutations";

type Props = {
	refetch: () => void;
	leadId: string;
};

type Return = {
	onPinResponsible: (payload: OnPinResponsiblePayload) => void;
	onUnpinResponsibles: () => void;
	modalPinTitle: string;
};

type UseOnPinResponsible = (props: Props) => Return;

export const useOnPinResponsible: UseOnPinResponsible = ({ refetch, leadId }) => {
	const dispatch = useDispatch();

	const onSuccessMutation = () => {
		dispatch(deactivateLoading());
		dispatch(closeModal());
		refetch();
	};

	const { mutate } = useUpdateLead({
		onSuccess: onSuccessMutation,
		onError: () => {
			dispatch(deactivateLoading());
			dispatch(closeModal());
			toastError({ text: "Не удалось прикрепить ответственного" });
		},
	});

	const { mutate: mutateTransfer } = useTransferLead({
		onSuccess: onSuccessMutation,
	});

	const modalPinTitle = "Передать лид";

	const handlePinResponsibles = (userId: string) => {
		const mutatePayload: TransferLead = { leadId, userId };

		dispatch(activateLoading());
		mutateTransfer(mutatePayload);
	};

	const handleUnpinResponsibles = () => {
		const mutatePayload: LeadUpdatePayload = {
			id: leadId,
			data: {
				responsibleUserId: null,
			},
		};

		mutate(mutatePayload);
	};

	const onUnpinResponsibles = () => {
		dispatch(
			openModal({
				title: 'После открепления ответственного, лид перейдет в статус "Нераспределенные"',
				body: "",
				variant: "alert",
				alertIcon: "warning",
				alertButtonSubmit: {
					label: "Открепить",
					onClick: handleUnpinResponsibles,
				},
				alertButtonDecline: {
					label: "Отменить",
					onClick: () => dispatch(closeModal()),
				},
			})
		);
	};

	const onPinResponsible = ({ responsiblesIds }: OnPinResponsiblePayload) => {
		if (responsiblesIds.length === 0) {
			handleUnpinResponsibles();
		} else {
			handlePinResponsibles(responsiblesIds[0]);
		}
	};

	return {
		onPinResponsible,
		onUnpinResponsibles,
		modalPinTitle,
	};
};
