import { useMutation } from "react-query";
import { api } from "../../api";

type UseSendToChatAvito = {
  onSuccess?: () => void;
}

const DEFAULT_MN = "sdelka/application/send-to-chat-avito/DEFAULT_MN";

export const useSendToChatAvito = ({onSuccess}: UseSendToChatAvito) => {

  const mutation = useMutation(DEFAULT_MN, {
   mutationFn: (data:{contactId: string, text: string}) => api.contacts.sendToChatAvito(data),
    onSuccess,
  });

  return {
    mutate: mutation.mutate,
    mutateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };

}