import React from "react"
import { useForm } from "react-hook-form";

// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from "react-player"

import ImgFunctions from "../../../../assets/images/imgFunctions.png";
import ErrorIcon from "../../../../assets/icons/error-painted.svg";
import Unwrap from "../../../../assets/icons/unwrap.svg"
import { Checkbox } from "../../../UI";
import { CONTACT_DEFAULT_FILTERS } from "../../../../service/api/helpers";


import styles from "./index.module.scss";

type Props = {
    sectionId: string;
};

export const Introduction = ({ sectionId }: Props): JSX.Element => {
    const { control } = useForm({
        defaultValues: CONTACT_DEFAULT_FILTERS,
    });

    return (
        <><div className={styles.container} id={sectionId}>
            <h2> Знакомься</h2>
            <div className={styles.grid}>
                <div className={styles.contentLeft}>
                    <h3>Присоединяйся к сделке</h3>
                    <p>
                        Презентация о бизнес-процессах и работе сервиса. Этот методический материал посвящен
                        автоматизации вашего труда и оптимизации ваших сценариев, которые вы каждый день репетируете
                        совершая холодные звонки, рекламируя объект, назначая и проводя показы.{" "}
                    </p>
                </div>

                <div className={styles.contentRight}>
                    <div className={styles.boxPlayer}>
                        <p className={styles.yellowSpoiler}>Советуем посмотреть</p>
                        <ReactPlayer
                            className={styles.videoPlayer}
                            url="https://www.youtube.com/watch?v=uHio0NZ_lPc"
                            controls
                            width={480}
                            height={244} />
                    </div>
                </div>
            </div>
        </div><div className={styles.container}>
                <h2>Изучай</h2>
                <div className={styles.containerClientBlur}>
                    <div className={styles.grid}>
                        <div className={styles.contentLeft}>
                            <h3>Методология риэлторской деятельности</h3>
                            <span className={styles.contentLeftSpan}>Работа с продавцом и покупателем</span>
                            <p>
                                Презентация о бизнес-процессах и работе сервиса. Этот методический материал посвящен
                                автоматизации вашего труда и оптимизации ваших сценариев, которые вы каждый день репетируете
                                совершая холодные звонки, рекламируя объект, назначая и проводя показы.{" "}
                            </p>

                            <div className={styles.importantInformation}>
                                <img src={ErrorIcon} alt="error" />
                                <p>
                                    Для ознакомления с тарифами необходимо посмотреть данный ролик
                                </p>
                            </div>

                        </div>

                        <div className={styles.contentRight}>
                            <div className={styles.boxPlayer}>
                            <p>Обязательно к просмотру</p>
                                <ReactPlayer
                                    className={styles.videoPlayer}
                                    url="https://www.youtube.com/watch?v=cg5tGlD-Yq8"
                                    controls
                                    width={480}
                                    height={244} />
                            </div>
                        </div>
                    </div>

                    <div className={styles.functionality}>
                        <div className={styles.headerFunctionality}>
                            <h3>Описание функциональных возможностей</h3>
                        </div>
                        <div className={styles.blockRow}>
                            <div className={styles.containerRow}>
                                <p>Лиды</p>
                                <div className={styles.imgContent}>
                                    <p>Только для клиентов</p>
                                    <img src={ImgFunctions} alt="functions"
                                        style={{
                                            borderRadius: "16px",
                                            filter: 'blur(5px)',
                                            background: "rgba(255, 255, 255, 0.70)"
                                        }} />
                                </div>
                            </div>

                            <div className={styles.containerRow}>
                                <p>Предзаявки</p>
                                <div className={styles.imgContent}>
                                    <p>Только для клиентов</p>
                                    <img src={ImgFunctions} alt="functions"
                                        style={{
                                            borderRadius: "16px",
                                            filter: 'blur(5px)',
                                        }} />
                                </div>
                            </div>

                            <div className={styles.containerRow}>
                                <p>Заявки</p>
                                <div className={styles.imgContent}>
                                    <p>Только для клиентов</p>
                                    <img src={ImgFunctions} alt="functions"
                                        style={{
                                            borderRadius: "16px",
                                            filter: 'blur(5px)',
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.footerInfo}>
                            <div className={styles.moreVideo}>
                                <Checkbox
                                    labelTextClassName={styles.checkboxLabel}
                                    control={control}
                                    name="withApplication"
                                    label="Показывать просмотренные видео"
                                    variant="noOpacity"
                                    weight="bold"
                                />
                            </div>
                            <div className={styles.moreInfo}>
                                <span>Развернуть</span>
                                <img src={Unwrap} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}