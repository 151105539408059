import { TasksAttentionHeader, TasksAttentionList } from "./components";
import { TasksAttentionContextProvider } from "../../../../../../../service/context/TasksAttention";

import styles from "./index.module.scss";

export const TasksAttention = (): JSX.Element => (
	<TasksAttentionContextProvider>
		<div className={styles.root}>
			<TasksAttentionHeader />

			<TasksAttentionList />
		</div>
	</TasksAttentionContextProvider>
);
