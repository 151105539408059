import isEqual from "lodash.isequal";
import { useMemo } from "react";
import { RealEstate } from "../../../../../../types";
import styles from "./index.module.scss";
import { EstateBaseRow } from "../card-object-estatebaserow/EstateBaseRow";
import { CardObjectContact } from "../card-object-contact";
import { ReactComponent as LogoOwner } from "../../../../../../assets/icons/logoOwner.svg";
import { ReactComponent as ImResponsibleIcon } from "../../../../../../assets/icons/imResponsibleIcon.svg";
import cn from "classnames";
import { CardObjectResponsible } from "./CardObjectResponsible";

type Props = {
  realEstate: RealEstate;
  variant?: "standard" | "strange" | "atApp";
  classnames?: string
};


export const Owner = ({realEstate, variant, classnames}: Props): JSX.Element => {
  const {
    parserData,
    sourceLinks,
    isAgency,
  } = realEstate;

  const isParser = !isEqual(parserData, undefined);

  const fullLeftPart = useMemo(() => {
    switch (variant) {
      case "standard":
        return (isParser
          ? <EstateBaseRow sourceLinks={sourceLinks}/>
          : <CardObjectContact contact={realEstate.contact}/>)
      case "strange":
        return (<div/>)
      case "atApp":
        return (
          <CardObjectContact contact={realEstate.contact}/>
        )
      default:
        return (<div/>)
    }
  }, [isParser, realEstate.contact, sourceLinks, variant])

  const rightPart: JSX.Element =
    isParser && parserData ? (
      <div className={cn(styles.owner, classnames)}>
        {parserData.agencyName ? (
          <>
            <span>{parserData.agencyName}</span>
          </>
        ) : (
          <>
            {!isAgency && <div className={styles.ownerLogo}>
                <LogoOwner/>
            </div>}
            <span>
              {parserData?.ownerName ? parserData?.ownerName : "Без названия"}
            </span>
          </>
        )}
      </div>
    ) : (
      <div className={cn(styles.responsible, classnames)}>
        {realEstate.iAmResponsibleUser
          ? <div className={styles.responsibleLogo}>
          <ImResponsibleIcon/>
        </div>
        : <CardObjectResponsible account={realEstate.responsibleUser.account}/>
        }
      </div>
    );

  return (
    <div className={styles.root}>
      <div>{fullLeftPart}</div>
      {rightPart}
    </div>
  );
};
