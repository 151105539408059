import { Contact } from "../../../../../../../../../types";
import styles from "./index.module.scss"
import { ReactComponent as Alert } from "../../../../../../../../../assets/icons/iconWarnBig.svg";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { closeModal } from "../../../../../../../../../service/redux/modules/modal/modal.action";
import { useDispatch } from "react-redux";

type Props = {
  onPinContact: (contact: (Contact | null | undefined), onPinSuccess?: () => void) => Promise<void>
}

export const DeleteContactModal = ({onPinContact}: Props) => {
  const dispatch = useDispatch()

  const onApply = () => {
    dispatch(closeModal())
    onPinContact(undefined)
  }
  const onDeny = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <div className={styles.row}>
        <div>
          <Alert/>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Вы уверены, что хотите открепить контакт?</div>
        </div>

      </div>
      <div className={styles.rowEnd}>
        <Button onClick={onDeny} variant="outlined">
          Отмена
        </Button>
        <Button onClick={onApply} classNames={{root: styles.buttonCreate}}>
          Открепить
        </Button>
      </div>
    </>

  )
}