import { ReactNode, useEffect, useState } from "react";
import { LoadByScroll, H2 } from "@sdelka_crm/sdelka-crm-component-library";

import { UserListCards } from "./components";
import { useBlockAction } from "../helpers";
import { StageConditions, UserListItem } from "../../../../types";

type Props = {
	type?: any;
	title: string;
	applicationId?: string;
	scrollY?: number;
	children?: ReactNode;
	isSuccess: boolean;
	enabled?: boolean;
	stageConditions?: StageConditions;
	users: UserListItem[];
	refetch: () => void;
	handleDelete?: ({ applicationId, id, type }) => void;
	withoutAvatars?: boolean;
	mini?: boolean;
	classNames?: string
	atModal?: boolean
};

export const UserList = ({
	type,
	isSuccess,
	children,
	title,
	enabled = true,
	applicationId = "",
	scrollY,
	stageConditions,
	users,
	refetch,
	handleDelete,
	withoutAvatars,
	mini,
	classNames,
	atModal
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props): JSX.Element => {
	const [startFetching, setStartFetching] = useState(false);

	const haveStageCondition = stageConditions && (stageConditions.isLast || stageConditions?.isNext);

	const { checkAllow } = useBlockAction({
		isLast: stageConditions ? haveStageCondition : false,
	});

	const handleInView = () => enabled && setStartFetching(true);
	const onDelete = (id: string) => {
		checkAllow(() => (handleDelete ? handleDelete({ applicationId, id, type }) : {}));
	};

	useEffect(() => {
		if (isSuccess) refetch();
	}, [isSuccess]);

	useEffect(() => {
		if (startFetching) refetch();
	}, [startFetching]);
	
	useEffect(()=> {
		handleInView()
	}, [enabled])

	const onClickClose = haveStageCondition ? undefined : handleDelete && onDelete;

	return (
		<>
			<H2 button={(stageConditions ? !haveStageCondition : true) && children}>{title}</H2>

			<LoadByScroll
				isLoading={enabled && !startFetching}
				height={300}
				scrollY={scrollY}
				onInView={handleInView}
			>
				<UserListCards
					data={users}
					onClickClose={onClickClose}
					withoutAvatars={withoutAvatars}
					mini={mini}
					classNames={classNames}
				  atModal={atModal}
					refetch={refetch}
				/>
			</LoadByScroll>
		</>
	);
};
