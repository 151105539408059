import { useMutation } from "react-query";
import { LeadUpdatePayload } from "../../../../types";

import { api } from "../../api";

const DEFAULT_MN = "sdelka/lead/update-lead/DEFAULT_MN";

type UseUpdateLead = {
	onSuccess?: () => void;
	onError?: () => void;
};

export const useUpdateLead = ({ onSuccess, onError }: UseUpdateLead) => {
	const mutate = useMutation(DEFAULT_MN, {
		mutationFn: (payload: LeadUpdatePayload) => api.leads.updateLead(payload),
		onSuccess,
		onError,
	});

	return {
		mutate: mutate.mutate,
		mutateAsync: mutate.mutateAsync,
		isLoading: mutate.isLoading
	};
};
