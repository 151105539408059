import styles from "./index.module.scss"
import { CardApplication } from "../../Card/card-application";
import { ApplicationInListV2, KindType } from "../../../../types";
import { Plug } from "../helpers";
import cn from "classnames";

type Props = {
  applicationId: string | null | undefined
  application: ApplicationInListV2
  whoIsClient: "seller" | "buyer"
  kind: KindType
  isArchived?: boolean
}

export const MirrorApplication = (
  {
    applicationId,
    application,
    whoIsClient,
    kind,
    isArchived
  }: Props): JSX.Element => {

  const title = whoIsClient === "buyer" ? "Заявка покупателя" : "Заявка продавца"
  const plugText = whoIsClient === "buyer" ? "Покупатель пока не найден" : "Продавец пока не найден"
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {title}
      </div>
      {applicationId && application ?
        <div className={cn(styles.card, {[ styles.archived ]: isArchived})}>
          <CardApplication
            withStage
            canOpenANewTab
            application={application}
            kind={kind}
          />
        </div>
        : <Plug plugText={plugText}/>
      }
    </div>
  )
}