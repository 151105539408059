import { AuthService } from "./services/outer/AuthService";
import { CompaniesService } from "./services/outer/CompaniesService";
import { CompanyService } from "./services/outer/CompanyService";
import { ContactsService } from "./services/outer/ContactsService";
import { DepartmentsService } from "./services/outer/DepartmentsService";
import { InvitesService } from "./services/outer/InvitesService";
import { RealEstateService } from "./services/outer/RealEstateService";
import { OfficesService } from "./services/outer/OfficesService";
import { PositionsService } from "./services/outer/PositionsService";
import { UsersService } from "./services/outer/UsersService";
import { ObjectSignatureService } from "./services/outer/ObjectSignatureService";
import { AddressService } from "./services/outer/AddressService";
import { RealEstateAdService } from "./services/outer/RealEstateAdService";
import { FileStorageService } from "./services/outer/FileStorageService";
import { LeadsService } from "./services/outer/LeadsService";
import { TrafficService } from "./services/outer/TrafficService";
import { CompilationService } from "./services/outer/CompilationService";
import { CallService } from "./services/outer/CallService";
import { ShowsViewsService } from "./services/outer/ShowsViewsService";
import { SettingsService } from "./services/outer/SettingsService";
import { ApplicationService } from "./services/outer/ApplicationService";
import { PreApplicationService } from "./services/outer/PreApplicationService";
import { ApplicationPublicService } from "./services/outer/ApplicationPublicService";
import { TasksService } from "./services/outer/TasksService";
import { ApplicationTemplateService } from "./services/outer/ApplicationTemplateService";
import { PreApplicationTemplateService } from "./services/outer/PreApplicationTemplateService";
import { PublicService } from "./services/outer/PublicService";
import { ObjectCompareService } from "./services/outer/ObjectCompare";
import { ExperienceService } from "./services/outer/ExperienceService";
import { ParsedRealEstateService } from "./services/outer/ParsedRealEstateService";
import { ApplicationRealEstateService } from "./services/outer/ApplicationRealEstateService";
import { CallCenterService } from "./services/outer/CallCenterService";
import { DealsService } from "./services/outer/DealsService";
import { DealRealEstateService } from "./services/outer/DealRealEstateService";
import { VideoLessonsService } from "./services/outer/VideoLessonsService";
import { CartService } from "./services/outer/CartService";
import { ServicesService } from "./services/outer/ServicesService";

export const api = {
	auth: AuthService,
	users: UsersService,
	companies: CompaniesService,
	company: CompanyService,
	invites: InvitesService,
	realEstate: RealEstateService,
	realEstateAd: RealEstateAdService,
	offices: OfficesService,
	positions: PositionsService,
	departments: DepartmentsService,
	contacts: ContactsService,
	objectSignature: ObjectSignatureService,
	address: AddressService,
	leads: LeadsService,
	files: FileStorageService,
	traffic: TrafficService,
	compilations: CompilationService,
	call: CallService,
	showsViews: ShowsViewsService,
	settings: SettingsService,
	application: ApplicationService,
	deals: DealsService,
	applicationPublic: ApplicationPublicService,
	applicationTemplate: ApplicationTemplateService,
	preApplicationTemplate: PreApplicationTemplateService,
	PreApplicationService: PreApplicationService,
	tasks: TasksService,
	publicPage: PublicService,
	objectCompare: ObjectCompareService,
	experience: ExperienceService,
	parsedRealEstate: ParsedRealEstateService,
	dealRealEstate: DealRealEstateService,
	applicationRealEstate: ApplicationRealEstateService,
	callCenter: CallCenterService,
	videoLessons: VideoLessonsService,
	cart: CartService,
	services: ServicesService
};
