import { InfoRow } from "@sdelka_crm/sdelka-crm-component-library";
import { Control, useForm, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import cn from "classnames";
import { useTaskFormContext } from "../../../../../../../service/context/TaskForm";

import { CalendarInPopup, Time } from "../../../../../index";

import styles from "./index.module.scss";
import { CreateTaskForm } from "../../../../../../../types";

export const Deadline = (): JSX.Element => {
	const { register, errors, getValues, setValue, control } = useTaskFormContext();

	const dateName = "deadlineDate";
	const currentDate = useWatch({
		name: dateName,
		control,
		defaultValue: getValues(dateName),
	});

	const timeName = "deadlineTime";
	const currentTime = useWatch({
		name: timeName,
		control,
		defaultValue: getValues(timeName),
	});

	return (
		<InfoRow
			classNames={{
				root: cn(styles.infoRow, styles.calendarChild),
				content: styles.flexRow,
			}}
			labelFont="ubuntu"
			label="Дедлайн"
		>
			<CalendarInPopup
				form={{ name: dateName, register, setValue, getValues, required: true }}
				defaultDate={currentDate}
				classNames={{
					wrapper: styles.calendar,
					label: styles.calendarLabel,
					trigger: styles.popupTrigger,
				}}
			/>

			<Time
				classNames={{
					root: styles.timeRoot,
					wrapper: styles.timeWrapper,
				}}
				variant="noLabel"
				name={timeName}
				register={register}
				value={currentTime}
			/>
		</InfoRow>
	);
};
