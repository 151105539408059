import { FixedNav } from "../../../UI";
import { RealEstateView } from "../mode";
import { scrollIntoView } from "../../../../utils/scroll";
import { useLoadApplicationRealEstate } from "../../../../service/api/queries";

type Props = {
	applicationId: string;
	objectShortId: string;
};

export const RealEstatePageNoTabs = ({ applicationId, objectShortId }: Props): JSX.Element => {
	const blocksIds = {
		common: "common-block-object-view",
		info: "info-block-object-view",
		description: "description-block-object-view",
		maybe: "maybe-block-object-view",
		official: "official-block-object-view",
	};


	const { realEstate, isFetching, refetch, remove } = useLoadApplicationRealEstate({ id: applicationId });

	return (
		<>

			<RealEstateView
				realEstate={realEstate}
				refetch={refetch}
				remove={remove}
				id={applicationId}
				blocksIds={blocksIds}
				isFetching={isFetching}
			/>
		</>
	);
};
