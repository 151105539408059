import { H4, P } from "@sdelka_crm/sdelka-crm-component-library";

import { CardObjectLessInfo } from "../../../../UI";
import { useLoadApplicationRealEstates } from "../../../../../service/api/queries";
import { useOpenRealEstate } from "../../../../../utils/objects";
import { RealEstateFilter } from "../../../../../types";

import styles from "./index.module.scss";

export const NeedAttention = (): JSX.Element => {
	const DEFAULT_VALUES: RealEstateFilter = {
		withAds: ["Требуют внимания"],
	};

	const DEFAULT_TAKE = 20;

	const openObject = useOpenRealEstate({});

	const { realEstatesList, isFetching, total } = useLoadApplicationRealEstates({
		defaultPage: 1,
		defaultTake: DEFAULT_TAKE,
		options: { keepPreviousData: true },
		defaultFilter: DEFAULT_VALUES,
	});

	return (
		<div className={styles.root}>
			<H4>Ваша реклама требующая внимания</H4>

			{isFetching ? (
				Array.from(Array(5).keys()).map((item) => <div key={item} className={styles.skeleton} />)
			) : (
				<>
					{total > 0 ? (
						realEstatesList.map((object) => (
							<div className={styles.card} key={object.id}>
								<CardObjectLessInfo
									id={object.id}
									clientObject={object}
									cardBackground="white"
									withPrice={false}
									onCardClick={() => openObject(object)}
								/>
							</div>
						))
					) : (
						<P classNames={{ root: styles.p }}>Реклама, требующая внимание, отсутствует</P>
					)}
				</>
			)}
		</div>
	);
};
