import styles from "./index.module.scss";
import cn from "classnames";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus-small.svg";
import { useOnPinContact } from "./helpers/useOnPinContactToShowView";
import { PinContact } from "../../../../UI";
import { Contact } from "../../../../../types";


type Props = {
  contact?: Contact | null
  refetch: () => void
  showviewId: string
  isViews: boolean
  isAgent: boolean
}

export const AddContactLabel = (
  {
    contact,
    refetch,
    showviewId,
    isViews,
    isAgent
  }: Props) => {
  const {onPinContact} = useOnPinContact({showviewId, refetch, isAgent, isViews})

  const handlePinContact = (contactItem: Contact | null | undefined) => {
    onPinContact(contactItem, refetch)
  };

  return (
    <>
      {!contact &&
        <div className={cn(styles.row, styles.add)}>
          <PlusIcon/>
          <PinContact onPin={handlePinContact}>
            <div className={styles.add}>Добавить контакт</div>
          </PinContact>
        </div>}
    </>
  )
}