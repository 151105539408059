import cn from "classnames";
import { User } from "../../../../../../types";

import { Avatar } from "../../../../index";
import { useOpenProfileCard } from "../../../../../pages/PersonalCard/helpers";

import { ReactComponent as NotificationIcon } from "../../../../../../assets/icons/notification.svg";
import styles from "./index.module.scss";

type Props = {
	account?: User;
	current?: boolean;
};

export const UserInfo = ({ account, current }: Props): JSX.Element => {
	const { openProfileDrawer } = useOpenProfileCard({});

	const handleProfileDrawer = () => openProfileDrawer(account?.id);

	return (
		<div className={styles.root}>
			<div className={cn(styles.base, { [styles.baseHover]: !current })}>
				<div className={styles.avatarContainer}>
					<Avatar
						isLoading={!account}
						imgSrc={account?.account.avatar}
						name={account?.account.name}
						lastName={account?.account.lastName}
						color={current ? "primary" : "secondary"}
					/>

					{current && (
						<div className={styles.notifications}>
							<NotificationIcon />
						</div>
					)}
				</div>

				<div
					className={cn(styles.info, {
						[styles.infoCurrent]: current,
					})}
				>
					<div className={styles.company}>{account?.company?.companyName}</div>

					<div className={styles.name}>
						{`${account?.account.lastName} ${account?.account?.name?.slice(0, 1) || ""}.${
							account?.account?.secondName?.slice(0, 1) || ""
						}`}
					</div>
				</div>
			</div>

			{current && (
				<div className={styles.link} onClick={handleProfileDrawer}>
					Личный кабинет
				</div>
			)}
		</div>
	);
};
