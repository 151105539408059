import { useHistory } from "react-router";

export const useOpenRealEstate = () => {
	const { location } = useHistory();

	const handleOpenClientEstate = (applicationId: string) => {
		const encodedBackPath = encodeURIComponent(`${location.pathname}${location.search}`);

		window.open(
			`real-estate/application/${applicationId}?mode=view&backPath=${encodedBackPath}`,
			"_blank",
			"noopener noreferrer"
		);
	};

	return {
		handleOpenClientEstate,
	};
};
