import { Control, UseFormGetValues, useWatch } from "react-hook-form";

import { MultiLayerSelectOption } from "../../../../../types";

type Props = {
	control: Control<any>;
	getValues: UseFormGetValues<any>;
};

type Return = {
	responsibleUsersWatcher: MultiLayerSelectOption[];
	createdAtFromWatcher: Date;
	createdAtToWatcher: Date;
	sourceWatcher: string[];
};

type UseFilterWatchers = (props: Props) => Return;

export const useFilterWatchers: UseFilterWatchers = ({ control, getValues }) => {
	const RESP_USERS = "responsibleUsers";
	const CREATED_AT_FROM = "createdAtFrom";
	const CREATED_AT_TO = "createdAtTo";
	const SOURCE = "source";

	const [responsibleUsersWatcher, createdAtFromWatcher, createdAtToWatcher, sourceWatcher]: [
		MultiLayerSelectOption[],
		Date,
		Date,
		string[]
	] = [
		useWatch({
			control,
			name: RESP_USERS,
			defaultValue: getValues(RESP_USERS) || [],
		}),
		useWatch({
			control,
			name: CREATED_AT_FROM,
			defaultValue: getValues(CREATED_AT_FROM),
		}),
		useWatch({
			control,
			name: CREATED_AT_TO,
			defaultValue: getValues(CREATED_AT_TO),
		}),
		useWatch({
			control,
			name: SOURCE,
			defaultValue: getValues(SOURCE) || [],
		}),
	] as [MultiLayerSelectOption[], Date, Date, string[]];

	return {
		responsibleUsersWatcher,
		createdAtFromWatcher,
		createdAtToWatcher,
		sourceWatcher,
	};
};
