import { useMemo, useState } from "react";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { Table } from "../../../../UI";
import { useColumns } from "./columns";
import { sortInObject } from "../../../../../utils/formatters/sort";
import { useBlinkRow } from "../../../../../utils/hooks";
import { Traffic, TrafficWithDate } from "../../../../../types";

import styles from "./index.module.scss";
import cn from "classnames";

type Props = {
  setSelect?: (row: TrafficWithDate) => void
  refetchTraffics: () => void;
  trafficList: Traffic[];
  isLoading: boolean;
  className?: {
    root?: string
    title?: string
    row?: string
    col?: string
  }
};

export const TrafficTable = ({refetchTraffics, trafficList, isLoading, className, setSelect}: Props): JSX.Element => {
  const [checked, setChecked] = useState<string[]>([]);
  const columns = useColumns(checked, setChecked, refetchTraffics);
  const {rowsToBlink, callback} = useBlinkRow();
  const [selected, setSelected] = useState<TrafficWithDate | undefined>(undefined)

  // const { handleOpenContact } = useOpenTraffic({
  // 	callbackAfterClose: (id: string) => callback(id),
  // });

  const handleRowClick = (row: TrafficWithDate) => {
    if (setSelect) {
      setSelect(row)
    }
    setSelected(row)
  };

  const sortedByDate: TrafficWithDate[] = useMemo(() => {
    const trafficWithDate = trafficList.map((traffic) => ({
      ...traffic,
      groupKeyDate: formatDate(traffic.date, "full"),
    }));

    return sortInObject(trafficWithDate, "date");
  }, [trafficList]);


  const Title = ({title}: { title: string }) => <div className={cn(styles.title, className?.title)}>{title}</div>;

  return (
    <div className={cn(styles.wrapper, className?.root)}>
      {((trafficList?.length > 0 || isLoading) && (
        <Table<TrafficWithDate>
          idKey="id"
          groupKey="groupKeyDate"
          transformGroupTitle={(title) => <Title title={title}/>}
          colClass={cn(styles.col, className?.col)}
          rowClass={cn(styles.row, className?.row, {[ styles.selected ]: selected})}
          data={sortedByDate}
          columns={columns}
          onRowClick={handleRowClick}
          loading={{isLoading, rowsCount: 5}}
          blinkRows={rowsToBlink}
        />
      )) || <div className={styles.empty}>Похожго трафика не найдено</div>}
    </div>
  );
};
