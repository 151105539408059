import * as Yup from "yup";
import { CreateContactScheme } from "../../../types";

import { commonErrors } from "../../errors";

const formattedPhone = (phone?: string) => phone?.replace(/\D/gi, "");

const checkFirstChar = (phone: string) =>
	formattedPhone(phone)?.charAt(1) !== "7" || formattedPhone(phone)?.charAt(1) !== "8";

export const contactSchema: Yup.SchemaOf<CreateContactScheme> = Yup.object().shape(
	{
		name: Yup.string()
			.when("name", {
				is: (name) => name?.length !== 0,
				then: Yup.string()
					.ensure()
					.matches(/^[a-zA-Zа-яА-Я]+$/, commonErrors.onlyLetters),
			})
			.when("mainPhone", {
				is: (mainPhone) => mainPhone?.length > 0,
				then: Yup.string().required(commonErrors.required),
			})
			.test("name", "В имени не может быть пробелов", (name) => !name?.includes(" "))
			.max(19, "Слишком длинное имя"),
		secondName: Yup.string()
			.when("secondName", {
				is: (secondName) => secondName?.length !== 0,
				then: Yup.string()
					.ensure()
					.matches(/^[a-zA-Zа-яА-Я]+$/, commonErrors.onlyLetters),
			})
			.test("secondName", "В отчестве не может быть пробелов", (name) => !name?.includes(" "))
			.max(19, "Слишком длинное отчество"),
		lastName: Yup.string()
			.when("lastName", {
				is: (lastName) => lastName?.length !== 0,
				then: Yup.string()
					.ensure()
					.matches(/^[a-zA-Zа-яА-Я]+$/, commonErrors.onlyLetters),
			})
			.test("lastName", "В фамилии не может быть пробелов", (lastName) => !lastName?.includes(" "))
			.max(19, "Слишком длинная фамилия"),
		series: Yup.string().ensure()
			.matches(/^([0-9]{4})?$/, "Введите верную серию паспорта"),
		passportNumber: Yup.string().ensure()
			.matches(/^([0-9]{6})?$/, "Введите верный номер паспорта"),
		issuedByCode: Yup.string()
			.ensure()
			.matches(/^([0-9]{3}-[0-9]{3})?$/, "Введите верный код подразделения"),
		mainPhone: Yup.string()
			.ensure()
			.when("email", {
				is: "",
				then: Yup.string()
					.test("mainPhone", commonErrors.badPhone, (mainPhone) => formattedPhone(mainPhone)?.length === 11)
					.required(),
			})
			.test("mainPhone", commonErrors.badPhone, (mainPhone) =>
				mainPhone?.length !== 0 ? checkFirstChar(mainPhone) : true
			)
			.test("mainPhone", commonErrors.badPhone, (mainPhone) =>
				mainPhone?.length !== 0 ? formattedPhone(mainPhone)?.length === 11 : true
			),
		extraPhone: Yup.string()
			.default("")
			.test("extraPhone", commonErrors.badPhone, (extraPhone) =>
				extraPhone?.length !== 0 ? checkFirstChar(extraPhone) : true
			)
			.test("extraPhone", commonErrors.badPhone, (extraPhone) =>
				extraPhone?.length !== 0 ? formattedPhone(extraPhone)?.length === 11 : true
			),
		type: Yup.string()
			.test("type", "Выберите тип", (type) => !!type)
			.required(commonErrors.required),
		email: Yup.string()
			.ensure()
			.when("mainPhone", {
				is: "",
				then: Yup.string().email("Введите корректный E-mail").required(commonErrors.required),
			})
			.when("email", {
				is: (email) => email?.lenght !== 0,
				then: Yup.string().email("Введите корректный E-mail"),
			}),
		otherType: Yup.string()
			.ensure()
			.when("type", {
				is: (type) => type === "other",
				then: Yup.string().ensure().required(commonErrors.required),
			}),
	},
	[
		["email", "email"],
		["email", "mainPhone"],
		["name", "name"],
		["lastName", "lastName"],
		["secondName", "secondName"],
		["mainPhone", "email"],
		["otherType", "type"],
	]
);
