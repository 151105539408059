import { useMutation } from "react-query";
import {
	RealEstateCreatePresentationResponse,
	RealEstateGeneratePresentationPayload,
} from "../../../../types";

import { api } from "../../index";
import { AxiosResponse } from "axios";

const DEFAULT_MN = "sdelka/real-estate/generate-presentation/DEFAULT_MN";

type UseRealEstateGeneratePresentation = {
	onSuccess?: (
		data: AxiosResponse<RealEstateCreatePresentationResponse>,
		variables: RealEstateGeneratePresentationPayload,
		context: unknown
	) => void | Promise<unknown>;
	onError?: (
		error: unknown,
		variables: RealEstateGeneratePresentationPayload,
		context: unknown
	) => void | Promise<unknown>;
};

export const useRealEstateGeneratePresentation = ({
	onSuccess,
	onError,
}: UseRealEstateGeneratePresentation) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: RealEstateGeneratePresentationPayload) => api.realEstate.generatePresentation(data),
		onSuccess,
		onError,
	});

	return {
		mutate: mutation.mutate,
		mutateAsync: mutation.mutateAsync,
		isLoading: mutation.isLoading,
	};
};
