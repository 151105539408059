import { RealEstate } from "../../../../../../../../../../types";

import { Balloon } from "../../../../../../../../index";
import { RealEstateDesc } from "./components";

import { ReactComponent as BuildingsIcon } from "../../../../../../../../../../assets/icons/buildings.svg";

type Props = {
	realEstate: RealEstate;
};

export const Info = ({ realEstate }: Props): JSX.Element => {
	const { photos, id, price, adDescription, pricePerUnit, title } = realEstate;

	const [firstPhoto] = photos;
	const { photo1280 } = firstPhoto || {};

	const balloonHeader = photo1280 ? (
		<img alt="object-img" className="real-estate-balloon__img" src={photo1280} />
	) : (
		<div className="real-estate-balloon__img real-estate-balloon__noimg">
			<BuildingsIcon />
		</div>
	);

	const balloonBody = (
		<RealEstateDesc
			id={id}
			desc={adDescription}
			title={title}
			price={Number(price)}
			pricePerUnit={Number(pricePerUnit)}
			changeOfPrice="increased"
		/>
	);

	const balloonFooter = <></>;

	return <Balloon header={balloonHeader} body={balloonBody} footer={balloonFooter} />;
};
