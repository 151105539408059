import { useState } from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import styles from "./index.module.scss"
import { Button } from "@sdelka_crm/sdelka-crm-component-library";
import { PriceInput } from "./helpers/PriceInput";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../service/redux/modules/modal/modal.action";


type Props = {
  register: UseFormRegister<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  onSave: (priceWithDiscount) => void;
  price: string | number
}

export const Discount = (
  {
    register,
    control,
    setValue,
    getValues,
    onSave,
    price,
  }: Props): JSX.Element => {

  const title = "Укажите цену со скидкой"
  const [percent, setPercent] = useState(0)

  const handleOnChangePriceWithDiscount = () => {

    const pricedWithPercent = getValues("priceWithDiscount")
    if (getValues("Discount") !== String(+price - +pricedWithPercent)) {
      setValue("Discount", String(+price - +pricedWithPercent))
    }
    setPercent(((+price - +pricedWithPercent) / +price) * 100)
  }

  const handleOnChangeDiscount = () => {
    const disc2 = getValues("Discount")
    if (getValues("priceWithDiscount") !== String(+price - +disc2)) {
      setValue("priceWithDiscount", String(+price - +disc2))
    }
  }

  const dispatch = useDispatch()

  const onApply = () => {
    onSave(getValues("priceWithDiscount"))
    dispatch(closeModal())
  }

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        {title}
      </div>
      <PriceInput
        label="Цена со скидкой"
        control={control}
        handleOnChange={handleOnChangePriceWithDiscount}
        register={register}
        getValues={getValues}
        inputName="priceWithDiscount"
        setValue={setValue}
      />
      <div>
        <PriceInput
          label="Размер скидки"
          control={control}
          handleOnChange={handleOnChangeDiscount}
          register={register}
          getValues={getValues}
          inputName="Discount"
          setValue={setValue}
          percent={percent}
        />
      </div>
      <div className={styles.row}>
        <Button onClick={onApply}>
          Сохранить
        </Button>
      </div>
    </div>
  )
}
