export const makeEmpty = <T>(myObject: T): T => {
	const emptyObject = { ...myObject };
	// @ts-ignore
	Object.keys(emptyObject).forEach((key) => {
		const type = typeof emptyObject[key];

		if (Array.isArray(emptyObject[key])) {
			emptyObject[key] = [];
		} else if (type === "object") {
			emptyObject[key] = makeEmpty(emptyObject[key]);
		} else {
			emptyObject[key] = "";
		}
	});

	return emptyObject;
};
