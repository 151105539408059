import { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";
import isEqual from "lodash.isequal";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

import { ButtonSquare, CalendarRangeInPopup, MultiLayerSelect, MultiSelect } from "../../../UI";
import { useLoadUsers } from "../../../../service/api/queries";
import { DEFAULT_LEAD_FILTERS } from "../../../../service/api/helpers/lead";
import { useWindowWidth } from "../../../../utils/hooks";
import { prepareResponsibleUsers } from "../../../../utils/transformation";
import { useFilterWatchers } from "./helpers";
import { LeadFiltersType, LeadSourceEnum, MultiLayerSelectOption } from "../../../../types";

import { ReactComponent as CancelIcon } from "../../../../assets/icons/cancel.svg";
import styles from "./index.module.scss";

type Props = {
	formSubmit: (values: LeadFiltersType) => void;
	filter: LeadFiltersType;
};

type SourceOption = {
	label: string;
	value: LeadSourceEnum;
};

const sourceOptions: SourceOption[] = [
	{ label: "Холодный звонок (парсер)", value: LeadSourceEnum.coldCall },
	{ label: "Входящий звонок", value: LeadSourceEnum.call },
	{ label: "Связанный лид", value: LeadSourceEnum.lead },
	{ label: "С внешнего источника", value: LeadSourceEnum.external },
	{ label: "Показ объекта", value: LeadSourceEnum.show },
	{ label: "Просмотр объекта", value: LeadSourceEnum.view },
	{ label: "Публичная страница", value: LeadSourceEnum.publicPage },
	{ label: "Из архивных объектов", value: LeadSourceEnum.archivedEstateObject },
];

type FormValues = {
	responsibleUsers: MultiLayerSelectOption[];
} & LeadFiltersType;

const mapFormValues = (values: FormValues): LeadFiltersType => ({
	...values,
	responsibleUsers: values.responsibleUsers.map((user) => user.value),
});

export const LeadFilters = ({ formSubmit, filter }: Props): JSX.Element => {
	const { register, setValue, handleSubmit, watch, reset, getValues, control } = useForm<FormValues>({
		defaultValues: DEFAULT_LEAD_FILTERS,
	});

	const [showBtn, setShowBtn] = useState(false);
	const [isResetting, setIsResetting] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const width = useWindowWidth();

	useEffect(() => {
		if (width > 1600 && !expanded) {
			setExpanded(true);
		} else if (expanded) {
			setExpanded(false);
		}
	}, [width]);

	const formWatcher = watch();

	useEffect(() => {
		if (!isEqual(formWatcher, filter)) {
			if (!showBtn) {
				setShowBtn(true);
			}
		} else {
			setShowBtn(false);
		}
	}, [Object.values(formWatcher)]);

	const prepareSubmitForm = (values: FormValues) => {
		reset(values);
		formSubmit(mapFormValues(values));
		setShowBtn(false);
	};

	const resetFilter = () => {
		formSubmit({ ...DEFAULT_LEAD_FILTERS });
		reset({ ...DEFAULT_LEAD_FILTERS } as any);
		setShowBtn(false);
		setIsResetting(true);
		setTimeout(() => {
			setIsResetting(false);
		}, 0);
	};

	useEffect(() => {
		reset(filter);
	}, [filter]);

	const { users } = useLoadUsers({});
	const responsibleUsers = useMemo((): MultiLayerSelectOption[] => prepareResponsibleUsers(users), [users]);

	const { responsibleUsersWatcher, createdAtFromWatcher, createdAtToWatcher, sourceWatcher } =
		useFilterWatchers({ control, getValues });

	const defaultDate =
		!!createdAtFromWatcher && createdAtFromWatcher ? [createdAtFromWatcher, createdAtToWatcher] : undefined;

	return (
		<form
			className={cn(styles.root, {
				[styles.expanded]: expanded,
			})}
			onSubmit={handleSubmit(prepareSubmitForm)}
		>
			<div className={styles.firstLine}>
				<div className={cn(styles.filterWrapper, styles.responsibleWrapper)}>
					<MultiLayerSelect
						name="responsibleUsers"
						options={responsibleUsers || []}
						register={register}
						placeholder="Сотрудник"
						setValue={setValue}
						isSearchable
						defaultValue={responsibleUsersWatcher}
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.periodWrapper)}>
					<CalendarRangeInPopup
						placeholder="От - До"
						defaultDate={defaultDate}
						classNames={{
							label: styles.periodLabelWrapper,
							wrapper: styles.periodPopupWrapper,
						}}
						form={{
							name: ["createdAtFrom", "createdAtTo"],
							register,
							setValue,
							getValues,
						}}
						isResetting={isResetting}
					/>
				</div>

				<div className={cn(styles.filterWrapper, styles.sourceWrapper)}>
					<MultiSelect
						name="source"
						setValue={setValue}
						options={sourceOptions}
						register={register}
						placeholder="Источник"
						defaultValue={sourceWatcher}
					/>
				</div>
			</div>

			<div>
				<Button
					variant="text"
					classNames={{
						root: styles.resetBtn,
						icon: styles.cancelIcon,
					}}
					onClick={resetFilter}
					Icon={CancelIcon}
				>
					<span>Сбросить</span>
				</Button>
			</div>

			{showBtn && (
				<div className={styles.submitWrapper}>
					<ButtonSquare type="submit">Показать</ButtonSquare>
				</div>
			)}
		</form>
	);
};
