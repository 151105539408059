import { useMutation } from "react-query";

import { ResetPasswordCodeConfirm } from "../../../../types";
import { api } from "../../index";

const DEFAULT_MN = "sdelka/auth/code-confirm/DEFAULT_MN";

type UseCodeConfirm = {
	onSuccess?: () => void;
};

export const usePasswordCodeConfirm = ({ onSuccess }: UseCodeConfirm) => {
	const mutation = useMutation(DEFAULT_MN, {
		mutationFn: (data: Pick<ResetPasswordCodeConfirm, "code"> & { token: string }) =>
			api.auth.resetPasswordCodeConfirm(data),
		onSuccess,
	});

	return {
		mutate: mutation.mutate,
		isLoading: mutation.isLoading,
	};
};
