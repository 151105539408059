import { ReactNode } from "react";
import cn from "classnames";
import { Loader, Underlay } from "@sdelka_crm/sdelka-crm-component-library";

import { RealEstateAdStatistics, SupportData } from "./components";
import { RealEstateAdErrors, RealEstateAdErrorsProps } from "../RealEstateAdErrors";
import { PlatformSupportData, StatisticsBody } from "../../../../../../../../../types";

import styles from "./index.module.scss";

type Props = {
	header?: ReactNode;
	footer?: ReactNode;
	disableStatistics?: boolean;
	supportData?: PlatformSupportData & { id: string };
	className?: string;
	headerClassName?: string;
	footerClassName?: string;
	isLoading?: boolean;
	statistics?: StatisticsBody;
	small?: boolean;
	editError?: RealEstateAdErrorsProps;
	applicationId: string;
	feedId?: string;
	disableDetalization?: boolean;
	isYandexOrDomclick?: boolean;
	headerLabel?: string;
};

const audit = {
	list: {
		defaultAdSum: 1000,
		extraServicesSum: 1000,
		totalSum: 1000,
		defaultPlacements: 1000,
		extraServicesPlacements: 1000,
	},
	cards: {
		views: 10000,
		viewPrice: 10000,
		contactOpens: 10000,
		contactOpenPrice: 10000,
		leads: 10000,
		leadPrice: 10000,
	},
};

const prepareStatistics = (data?: Record<string, number>) => ({
	list: {
		defaultAdSum: data?.defaultAdSum || 0,
		extraServicesSum: data?.extraServicesSum || 0,
		totalSum: data?.totalSum || 0,
		defaultPlacements: data?.defaultPlacements || 0,
		extraServicesPlacements: data?.extraServicesPlacements || 0,
	},
	cards: {
		views: data?.views || 0,
		viewPrice: data?.viewPrice || 0,
		contactOpens: data?.contactOpens || 0,
		contactOpenPrice: data?.contactOpenPrice || 0,
		leads: data?.leads || 0,
		leadPrice: data?.leadPrice || 0,
	},
});

export const RealEstateAdPartnersInfo = ({
	className,
	header,
	footer = "",
	supportData,
	disableStatistics = false,
	headerClassName = "",
	footerClassName = "",
	statistics,
	isLoading = true,
	small = false,
	editError,
	applicationId,
	feedId,
	disableDetalization,
	isYandexOrDomclick,
	headerLabel,
}: Props): JSX.Element => {
	const { list, cards } = prepareStatistics(statistics);

	return (
		<div className={styles.root}>
			<h1>{headerLabel}</h1>
			<Underlay className={className}>
				{isLoading ? (
					<Loader height={450} />
				) : (
					<>
						<div
							className={cn(headerClassName, {
								[styles.disableStatistics]: disableStatistics && !footer,
							})}
						>
							{header}
						</div>

						{editError && (
							<div className={styles.editError}>
								<RealEstateAdErrors
									errors={editError.errors}
									data={editError.data}
									isEditError
									applicationId={applicationId}
								/>
							</div>
						)}

						{!disableStatistics && (
							<div className={styles.statistics}>
								<RealEstateAdStatistics
									small={small}
									list={statistics ? list : audit.list}
									cards={statistics ? cards : audit.cards}
									applicationId={applicationId}
									feedId={feedId}
									disableDetalization={disableDetalization}
									isYandexOrDomclick={isYandexOrDomclick}
								/>
							</div>
						)}

						{footer && <div className={footerClassName}>{footer}</div>}
					</>
				)}
			</Underlay>

			{supportData && <SupportData supportData={supportData} />}
			<div />
		</div>
	);
};
