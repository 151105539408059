import { memo, MouseEvent, ReactNode, useState } from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import { RealEstate } from "../../../../types";
import { PopupMenu, PopupMenuItems } from "../../index";
import { ApplicationPopover, Owner } from "./components";
import { getPriceLabel } from "../../../../utils/clientObjects";
import { LeadIconBlock } from "./components/iconBlock";
import { CardObjectStage } from "./components/stage";
import { AdStatRow } from "./components/AdStat/ad-stat-row/AdStatRow";
import { ReactComponent as BuildingsIcon } from "../../../../assets/icons/buildings.svg";
import { ReactComponent as HasNotifications } from "../../../../assets/icons/iconHasNotifications.svg";

export type CardObjectProps = {
  id: string;
  clientObject: RealEstate;
  cardBackground?: "white" | "blue";
  popupMenu?: PopupMenuItems[];
  selectedId?: string;
  onCardClick?: (id: string) => void;
  onAuxClick?: (id: string) => void;
  options?: {
    hideFluctuations?: boolean;
    leftBottomComponent?: ReactNode;
    showApplication?: boolean;
    canOpenANewTab?: boolean
  };
  variant?: "standard" | "ad-list" | "atApp";
  classNames?: {
    root?: string;
    content?: string;
    imgContainer?: string;
    owner?: string
  };
  withFeedAds?: boolean;
  isCompilation?: boolean
  isAdCabinet?: boolean
  atPinTab?: boolean
  selected?: boolean
};

export const CardObject = memo(
  ({
     id,
     clientObject,
     cardBackground = "white",
     popupMenu,
     selectedId,
     onCardClick,
     onAuxClick,
     options,
     variant,
     classNames,
     isCompilation,
    atPinTab,
    selected,
   }: // eslint-disable-next-line sonarjs/cognitive-complexity
     CardObjectProps): JSX.Element => {
    const [contextClick, setContextClick] = useState("");
    const [isImageError, setIsImageError] = useState(false);
    const {
      addressLabel,
      price,
      pricePerUnit,
      photos,
      actual,
      applications,
      title,
      icons,
      feedAds,
    } =
      clientObject;
    const priceLabel = getPriceLabel({price});
    const pricePerUnitLabel = getPriceLabel({pricePerUnit});
    const isNotActual = actual ? actual !== "Актуальный" : false;
    const bottomWithRightPadding = options?.showApplication && applications && applications.length;

    const handleCardClick = () => {
      if (onCardClick) {
        onCardClick(id);
      }
    };

    const handleAuxClick = () => {
      if (onAuxClick) {
        onAuxClick(id);
      }
    };

    const mouseUpHandler = (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      switch (event.button) {
        case 1:
          return handleAuxClick();
        case 2:
          return "";
        case 0:
        default:
          return handleCardClick();
      }
    };

    const handleContextMenu = (event) => {
      event.preventDefault();
      setContextClick(id);
    };

    const onImageError = () => setIsImageError(true);

    const popupIsActive = contextClick === id;

    return (
      <div
        onMouseUp={mouseUpHandler}
        onContextMenu={handleContextMenu}
        className={cn(classNames?.root, styles.root, styles[ `background_${cardBackground}` ], {
          [ styles.selectedCard ]: id === selectedId,
          [ styles.onActivePopup ]: popupIsActive,
        })}
      >
        {popupMenu && (
          <div className={styles.popup}>
            <PopupMenu
              menuItems={popupMenu}
              options={{primaryOpen: "left"}}
              contextClick={popupIsActive}
              onClose={() => setContextClick("")}
              source="parser"
            />
          </div>
        )}
        {options?.showApplication && <ApplicationPopover applications={applications}/>}
        <div
          className={cn(styles.img, classNames?.imgContainer, {
            [ styles.noImg ]: !photos.length || isImageError,
          })}
        >
          {photos.length && !isImageError ? (
            <>
              <img onError={onImageError} alt="object" src={photos[ 0 ].photo1280}/>
            </>
          ) : (
            <BuildingsIcon/>
          )}
        </div>

        <div className={cn(styles.content, classNames?.content)}>
          <div
            className={cn(styles.flexColumn, {
              [ styles.withPopup ]: popupMenu,
              [ styles.atPinTab ]: atPinTab,
            })}
          >
            <div className={cn(styles.flexRow, styles.titleAndPrice)}>
              <div className={styles.title}>
                {icons?.hasNotifications &&
                    <div className={styles.notification}>
                        <HasNotifications/>
                    </div>}
                <span className={cn({[ styles.lineThrough ]: isNotActual})}>{title}</span>
                <LeadIconBlock clientObject={clientObject}/>
              </div>
              <div>
                <span className={styles.price}>{priceLabel}</span>
              </div>
            </div>
            <div className={cn(styles.flexRow, styles.flexRowAlignStart, styles.address)}>
              <div className={styles.addressLabel}>{addressLabel}</div>
              <div className={styles.price}>{pricePerUnitLabel}</div>
            </div>
          </div>
          <div className={cn({[ styles.withPadding ]: bottomWithRightPadding})}>
            {variant === "standard" && (
              <Owner realEstate={clientObject} variant={variant} classnames={classNames?.owner}/>
            )}
            {variant === "atApp" && (
              <Owner realEstate={clientObject} variant={variant} classnames={classNames?.owner}/>
            )}
            {variant === "ad-list" && (
              <AdStatRow feedAds={feedAds} object={clientObject}/>
            )}
          </div>
        </div>
        <CardObjectStage
          object={clientObject}
          canOpenANewTab={options?.canOpenANewTab}
          isCompilation={isCompilation}
          selected={selected}
        />
      </div>
    );
  }
);
