import { AxiosResponse } from "axios";
import { UseMutateFunction, useMutation } from "react-query";

import { api } from "../../index";
import { ObjectCompareRemove } from "../../../../types";

type Props = {
	onSuccess?: () => void;
};

type Return = {
	mutate: UseMutateFunction<AxiosResponse<any>, unknown, ObjectCompareRemove, unknown>;
	isLoading: boolean;
};

type UseDeleteObjectFromCompare = (props: Props) => Return;

export const useDeleteObjectFromCompare: UseDeleteObjectFromCompare = ({ onSuccess }) => {
	const mutate = useMutation("addObjectToCompilation", {
		mutationFn: (payload: ObjectCompareRemove) => api.objectCompare.deleteObject(payload),
		onSuccess,
	});

	return {
		mutate: mutate.mutate,
		isLoading: mutate.isLoading,
	};
};
