import { RefObject, useEffect } from "react";

export type UseClickOutside = {
	ref: RefObject<HTMLButtonElement> | RefObject<HTMLDivElement>;
	onClick: () => void;
	exclude?: RefObject<HTMLElement>[];
	withClickListener?: boolean;
};

// Handling click outside of component
// Watch component by `ref`
export function useClickOutside({
	ref,
	onClick,
	exclude = [],
	withClickListener = false,
}: UseClickOutside): void {
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		const { current } = ref;

		const handler = (e: MouseEvent): void => {
			if (!current) {
				return;
			}

			const target = e.target as Node;

			if (!current.contains(target)) {
				for (let i = 0; i < exclude.length; i += 1) {
					const item = exclude[i].current;

					if (!item) {
						break;
					}

					if (item.contains(target)) {
						e.preventDefault();

						return;
					}
				}

				onClick();
			}
		};

		if (current) {
			document.addEventListener("mousedown", handler);

			if (withClickListener) {
				document.addEventListener("click", handler);
			}
		}

		return () => {
			document.removeEventListener("mousedown", handler);

			if (withClickListener) {
				document.addEventListener("click", handler);
			}
		};
	}, [ref, onClick, exclude]);
}
