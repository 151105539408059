import styles from "./index.module.scss"
import { LOAD_POSITIONS_REQUESTS_QN, useLoadPositions } from "../../../../../service/api/queries";
import { ReactNode, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useInviteSend } from "../../../../../service/api/mutations";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../service/redux/modules/modal/modal.action";
import { getFlag } from "../../../../../utils/getFlag";
import { Col, Container, Row, Select, TextField } from "../../../../UI";
import { phoneMask } from "../../../../../utils/masks";
import { Button } from "@sdelka_crm/sdelka-crm-component-library";

type Props = {
  departmentId: string
}

type InviteCreate = {
  phone: string;
  departmentId: string;
  positionId: string;
};

export const AddToDepartmentModal = ({departmentId}: Props) => {
  const dispatch = useDispatch()
  const [flagIcon, setFlagIcon] = useState<ReactNode | undefined>(undefined);
  const {
    positions,
    refetch: refetchPositions,
    isLoading: isLoadingPositions,
  } = useLoadPositions({queryName: LOAD_POSITIONS_REQUESTS_QN});
  const positionOptions = useMemo(
    () =>
      positions?.map((position) => ({
        label: position.name,
        value: position.id,
      })),
    [positions, positions.length]
  );
  const {control, handleSubmit, setError, reset, formState, register, setValue} = useForm({});

  const {mutate} = useInviteSend({
    onSuccess: () => {
      reset();
      dispatch(closeModal())
    },
    setError,
  });

  const onSubmit = (data) => {
    const payload: InviteCreate = {
      departmentId,
      positionId: data.positionId,
      phone: data.phone
    }

    mutate(payload)
  };

  const getFlagIcon = (phone: string) => {
    setFlagIcon(<div className={styles.flagIconContainer}>{getFlag(phone)}</div>);
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Добавить сотрудника
      </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.container}>
          <div className={styles.fieldsContainer}>
            <Container>
              <Row marginBottom={20}>
                <Col xs={12}>
                  <TextField
                    label="Телефон"
                    variant="small"
                    mask={phoneMask}
                    name="phone"
                    control={control}
                    error={formState.errors?.phone?.message?.toString()}
                    onChange={getFlagIcon}
                    append={flagIcon}
                  />
                </Col>
              </Row>
              <Row marginBottom={20}>
                <Col xs={12}>
                  <Select
                    name="positionId"
                    withBorder
                    options={positionOptions}
                    placeholder="Должность"
                    register={register}
                    setValue={setValue}
                    error={formState.errors?.positionId?.message?.toString()}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          </div>
          <div className={styles.footer}>
            <Button type="submit">
              Добавить
            </Button>
          </div>
        </form>



    </div>
  )
}