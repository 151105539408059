import { useState } from "react";
import { useQuery } from "react-query";
import { GetRealEstateFiltersPayload, RealEstateListTab, SellBusinessProcessEnum } from "../../../../types";

import { api } from "../../index";
import { scrollToElement } from "../../../../utils/scroll";

const DEFAULT_QN = "sdelka/real-estate/load-real-estate-filters/DEFAULT_QN";

const TabEnum: Record<RealEstateListTab, string> = {
	PARSED: "parsed",
	AGENCY: "my",
	AD: "ads",
};

const DEFAULT_FILTER: GetRealEstateFiltersPayload = {
	category: [],
	type: {
		value: SellBusinessProcessEnum.sale,
	},
};

type Props = {
	defaultFilter?: GetRealEstateFiltersPayload;
	rootId?: string;
	tab?: RealEstateListTab;
};

export const useLoadFilters = ({ defaultFilter = DEFAULT_FILTER, rootId, tab = "AGENCY" }: Props) => {
	const [filter, setFilter] = useState<GetRealEstateFiltersPayload>(defaultFilter);

	const filterPayload = {
		tab: TabEnum[tab],
		...filter,
	};

	const loadObjectFilters = () => api.realEstate.getFilters(filterPayload);

	const { data, isFetching } = useQuery([DEFAULT_QN, filter, tab], loadObjectFilters);

	// Navigation render
	const navigation = () => {
		const filters = data?.data?.filters;

		// Need for render specific navigation with subTitle blocks (example: "Дом/Участок");
		const firstTitleBlock = filters?.find((i) => i.isTitle === true);
		if (firstTitleBlock) {
			// Leave blocks before subTitle
			const beforeTitle = filters?.slice(0, filters?.indexOf(firstTitleBlock));
			// Merge remaining with all subTitles
			const formattedNavigation = beforeTitle?.concat(filters?.filter((i) => i.isTitle === true) || []);

			return formattedNavigation?.map((block) => ({
				label: block.blockName,
				action: () => (rootId ? scrollToElement(rootId, block.blockName, 50) : () => {}),
			}));
		}

		return filters?.map((block) => ({
			label: block.blockName,
			action: () => (rootId ? scrollToElement(rootId, block.blockName, 50) : () => {}),
		}));
	};

	return {
		setFilter,
		filter,
		objectsList: data?.data?.filters || [],
		navigation: navigation(),
		isFetching,
	};
};
