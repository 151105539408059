import cn from "classnames";
import { ReactNode } from "react";
import { Underlay } from "@sdelka_crm/sdelka-crm-component-library";

import styles from "./index.module.scss";

type Props = {
	Icon: ReactNode;
	children?: ReactNode;
	classNames?: {
		root?: string;
	};
};

export const IconBlockInfo = ({ Icon, children, classNames }: Props): JSX.Element => (
	<Underlay borderRadius={10} className={cn(styles.root, classNames?.root)}>
		<div className={styles.icon}>{Icon}</div>

		<div className={styles.children}>{children}</div>
	</Underlay>
);
