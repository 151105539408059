import { useState } from "react";

import { SideMenu } from "../../../../UI";
import { SideMenuTime, ObjectViews, ObjectShows } from "./components";

import { ReactComponent as ClockOutline } from "../../../../../assets/icons/clock-outline.svg";

type Props = {
	type: "shows" | "views";
};

export const ObjectShowsAndViews = ({ type }: Props): JSX.Element => {
	const [active, setActive] = useState(0);

	return (
		<div>
			<SideMenu
				active={active}
				onClick={setActive}
				menuComponents={[
					<SideMenuTime
						active={active === 0}
						Icon={<ClockOutline />}
						date="09 дек. 18:00"
						dayOfWeek="Понедельник"
					/>,
					<SideMenuTime
						active={active === 1}
						Icon={<ClockOutline />}
						date="09 дек. 18:00"
						dayOfWeek="Вторник"
					/>,
				]}
			>
				{type === "views" && <ObjectViews />}

				{type === "shows" && <ObjectShows />}
			</SideMenu>
		</div>
	);
};
