import { useMutation } from "react-query";

import { api } from "../../api";

const DEFAULT_MN = "sdelka/application/relations-to-leads/setContactFromLead/DEFAULT_MN";

type Props = {
	onSuccess?: () => void;
	onError?: () => void;
};

export const useSetContactFromParentLead = ({ onError, onSuccess }: Props) =>
	useMutation(DEFAULT_MN, {
		mutationFn: api.application.setContactFromParentLead ,
		onSuccess,
		onError,
	});
