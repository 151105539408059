import { Polygon, PolygonGeometry } from "react-yandex-maps";

import { polyStyle } from "../map-utils/utils";

/**
 * Компонент для сохранения нарисованных полигонов.
 */

type Props = {
	polyPoints: PolygonGeometry[];
};

export const DrawnPolygons = ({ polyPoints }: Props): JSX.Element => {

	return(
		<>
			{polyPoints.map((geometry, index) => (
				// @ts-ignore
				// eslint-disable-next-line react/no-array-index-key
				<Polygon options={polyStyle} key={index} geometry={geometry}/>
			))}
		</>
	);
}
