import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CollapseDown, H1 } from "@sdelka_crm/sdelka-crm-component-library";

import { AgentDocumentForm } from "./AgentDocumentForm";
import { useLoadApplicationCard } from "../../../../service/api/queries";
import { commonErrors } from "../../../../utils/errors";

import { ReactComponent as ShieldIcon } from "../../../../assets/icons/admin-panel-settings.svg";
import styles from "./index.module.scss";

type Props = {
	applicationId?: string;
};

export const AgentDocument = ({ applicationId }: Props): JSX.Element => {
	const { data: application, refetch } = useLoadApplicationCard({
		applicationId: applicationId || "",
	});

	const isAgentDocumentExist = !!(
		application?.agentDocumentDateFrom &&
		application.agentDocumentDateTo &&
		application.agentDocumentPercent
	);

	const formDefaultFalues = {
		isAgentDocument: isAgentDocumentExist,
		agentDocumentDateFrom: application?.agentDocumentDateFrom,
		agentDocumentDateTo: application?.agentDocumentDateTo,
		agentDocumentPercent: application?.agentDocumentPercent,
	};

	const schema = yup.object().shape({
		agentDocumentDateFrom: yup.string().required(commonErrors.required),
		agentDocumentDateTo: yup.string().required(commonErrors.required),
		agentDocumentPercent: yup
			.number()
			.nullable()
			.typeError("Должно быть числом")
			.required(commonErrors.required),
	});

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: formDefaultFalues,
	});
	const { reset } = form;

	useEffect(() => {
		reset(formDefaultFalues);
	}, [
		isAgentDocumentExist,
		application?.agentDocumentDateFrom,
		application?.agentDocumentDateTo,
		application?.agentDocumentPercent,
	]);

	return (
		<div className={styles.root}>
			{isAgentDocumentExist ? (
				<CollapseDown
					label={
						<div className={styles.collapseLabel}>
							<ShieldIcon />
							Агентский договор заключен
						</div>
					}
				>
					<AgentDocumentForm
						form={form}
						applicationId={applicationId}
						isAgentDocumentExist={isAgentDocumentExist}
						refetch={refetch}
					/>
				</CollapseDown>
			) : (
				<>
					<H1>Агентский договор</H1>

					<AgentDocumentForm
						form={form}
						applicationId={applicationId}
						isAgentDocumentExist={isAgentDocumentExist}
						refetch={refetch}
					/>
				</>
			)}
		</div>
	);
};
