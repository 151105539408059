import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../../../service/redux/modules/modal/modal.action";
import { DeleteFromDepartmentModal } from "./DeleteFromDepartmentModal";
import { useRemoveFromDepartment } from "../../../../../service/api/mutations/user/useRemoveFromDepartment";

type Props = {
  id: string
  departmentId: string
}

export const useOpenCancelApplicationModal = (
  {
    id,
    departmentId
  }: Props) => {
  const dispatch = useDispatch()

  const onSuccessCancel = () => {
    dispatch(closeModal())
  }
  const {mutate} = useRemoveFromDepartment({onSuccess: onSuccessCancel, userId: id, departmentId})

  const handleDeleteFromDepartmentModal = () => {
    dispatch(
      openModal({
        body: <DeleteFromDepartmentModal
          onApply={() => mutate()}
        />,
        width: 660,
      })
    )
  }
  return {handleDeleteFromDepartmentModal}
}