import styles from "./index.module.scss"
import { useGetChatAvito, useSendToChatAvito } from "../../../../../../../../service/api/queries";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";
import { Textarea } from "../../../../../../../UI";
import { ReactComponent as SaveText } from "../../../../../../../../assets/icons/saveComment.svg";
import { formatDateWithTime } from "../../../../../../../../utils/date";
import { Contact } from "../../../../../../../../types";
import { PersonInChat } from "./PersonInChat";

type Props = {
  contactId: string
  contact: Contact
}

export const ChatAvito = ({contactId, contact}: Props): JSX.Element => {
  const {chat, refetch} = useGetChatAvito({contactId})
  const {register, reset, setValue} = useForm();
  const [message, setMessage] = useState("")
  
  const [rows, setRows] = useState(1)


  useLayoutEffect(() => {
    const id = setInterval(() => {
      refetch()
    }, 15000)
    return clearInterval(id)
  })

  const onSuccess = () => {
    refetch()
    reset()
  }
  const reversedChat: any[] = []
  reversedChat.reverse()

  // eslint-disable-next-line no-plusplus
  for (let i = chat.length - 1; i >= 0; i--) {
    // @ts-ignore
    reversedChat.push(chat[i])
  }

  function getStringWidth(string: string) {
    const tempElement = document.createElement("div");
    tempElement.style.position = "absolute";
    tempElement.style.visibility = "hidden";
    tempElement.style.width = "auto";
    tempElement.style.height = "auto";
    tempElement.style.whiteSpace = "nowrap";
    tempElement.style.font = "16px sans-serif";
    tempElement. textContent = string;
    document. body. appendChild(tempElement);
    const {width} = tempElement.getBoundingClientRect();
    document.body.removeChild(tempElement);
    return width;
  }

  useEffect(() => {
 const count =  getStringWidth(message) / rows
 const width =  getStringWidth(message)
    if ((count / 682 ) > 1) {
      setRows(rows + 1)
    }
  }, [message])


  const {mutate, isLoading} = useSendToChatAvito({onSuccess})

  const handleSendMessage = () => {
    if (message.replace(" ", "").length > 0) {
      mutate({contactId, text: message})
      setMessage("")
      setRows(1)
      setValue("chatAvito", "")
    }
  }
  window.scrollTo(0, document.body.scrollHeight);
  const onChangeMessage = (val: string) => {
    setMessage(val)
  }
  const ref = useRef(null)
  useEffect(() => {
    if (ref) {
      //@ts-ignore
      ref.current?.scrollIntoView({block: "end"});
    }
  }, [chat])

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {reversedChat.map((item) => (
            <div>
              <div key={item.id} className={cn(
                {[ styles.messageIn ]: item.direction === "in"},
                {[ styles.messageOut ]: item.direction === "out"})
              }>
                <div>{item.content.text}</div>
                <div className={styles.time}>{formatDateWithTime(item.created)}</div>
              </div>
              <div>
                <PersonInChat contact={contact} messageIn={item.direction === "in"}/>
              </div>
            </div>
          ))
          }
          <div ref={ref}></div>
        </div>
      </div>
      <Textarea
        register={register}
        name="chatAvito"
        onChange={onChangeMessage}
        rows={rows}
        className={styles.textArea}
        value={message}
      />
      <SaveText className={styles.saveText} onClick={() => handleSendMessage()}/>
    </div>
  )
}