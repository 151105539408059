import { useEffect, useState } from "react";
import { endOfDay, startOfDay, sub } from "date-fns";
import cn from "classnames";
import isEqual from "lodash.isequal";
import { formatDate } from "@sdelka_crm/sdelka-crm-component-library";

import { Calendar, CalendarProps, Popup } from "../../index";

import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import styles from "./index.module.scss";

type Range = "Сегодня" | "Вчера" | "7 дней" | "Месяц" | "3 месяца" | undefined;

type Props = Pick<CalendarProps, "form" | "onDateChange" | "defaultDate" | "disableDates"> & {
	classNames?: {
		label?: string;
		wrapper?: string;
		trigger?: string;
	};
	placeholder?: string;
	noAutoDates?: boolean;
	isResetting?: boolean;
};

export const CalendarRangeInPopup = ({
	form,
	onDateChange,
	defaultDate,
	classNames,
	disableDates,
	placeholder,
	noAutoDates,
	isResetting,
}: Props): JSX.Element => {
	const DATE_NOT_SELECTED = placeholder || "00.00.00 - 00.00.00";

	const [label, setLabel] = useState(DATE_NOT_SELECTED);
	const [currentRange, setCurrentRange] = useState<Range>(undefined);
	const [inputValue, setInputValue] = useState<Date[] | Date | undefined>(undefined);
	const ranges: Range[] = ["Сегодня", "Вчера", "7 дней", "Месяц", "3 месяца"];

	useEffect(() => {
		if (isResetting) {
			setLabel(DATE_NOT_SELECTED);
			setCurrentRange(undefined);
			setInputValue(undefined);
		}
	}, [isResetting]);

	const handleChangeRange = (newRange: Range) => {
		setCurrentRange(newRange);

		switch (newRange) {
			case "Сегодня": {
				setInputValue([startOfDay(new Date()), endOfDay(new Date())]);
				break;
			}
			case "Вчера": {
				setInputValue([startOfDay(sub(new Date(), { days: 1 })), endOfDay(new Date())]);
				break;
			}
			case "7 дней": {
				setInputValue([startOfDay(sub(new Date(), { days: 6 })), endOfDay(new Date())]);
				break;
			}
			case "Месяц": {
				setInputValue([startOfDay(sub(new Date(), { months: 1 })), endOfDay(new Date())]);
				break;
			}
			case "3 месяца": {
				setInputValue([startOfDay(sub(new Date(), { months: 3 })), endOfDay(new Date())]);
				break;
			}
			default:
				break;
		}
	};

	const handleDateChange = (date: Date[] | Date | null | undefined) => {
		setCurrentRange(undefined);

		if (!date) {
			setLabel(DATE_NOT_SELECTED);
		} else {
			const dateStart = formatDate(date[0], "day_month_short").replaceAll(".", "");
			const dateEnd = formatDate(date[1], "day_month_short").replaceAll(".", "");
			setLabel(`${dateStart} - ${dateEnd}`);
		}

		if (onDateChange) {
			onDateChange(date as any);
		}
	};

	useEffect(() => {
		if (label !== DATE_NOT_SELECTED && defaultDate === undefined) {
			handleDateChange(undefined);
			setLabel(DATE_NOT_SELECTED);
		} else if (!isEqual(defaultDate, inputValue)) {
			handleDateChange(defaultDate as any);
		}
	}, [defaultDate]);

	return (
		<Popup
			classNames={{
				wrapper: cn(styles.popupWrapper, classNames?.wrapper),
				trigger: classNames?.trigger,
				card: cn({ [styles.card]: !noAutoDates }),
			}}
			openTrigger={
				!isResetting ? (
					<div
						className={cn(styles.label, classNames?.label, {
							[styles.notSelectedDate]: label === DATE_NOT_SELECTED,
						})}
					>
						<CalendarIcon />

						{label}
					</div>
				) : (
					<div className={cn(styles.label, classNames?.label, styles.notSelectedDate, styles.reset)}>
						<CalendarIcon />

						{DATE_NOT_SELECTED}
					</div>
				)
			}
		>
			<>
				{!isResetting && (
					<div className={styles.popupContent}>
						{!noAutoDates && (
							<div className={styles.rangesContainer}>
								{ranges.map((range) => (
									<div
										key={range}
										onClick={() => handleChangeRange(range)}
										className={cn(styles.rangeItem, {
											[styles.rangeItemActive]: range === currentRange,
										})}
									>
										{range}
									</div>
								))}
							</div>
						)}

						<Calendar
							withRange
							form={form}
							onDateChange={handleDateChange}
							callRerender
							outerInputDates={inputValue}
							defaultDate={defaultDate}
							disableDates={disableDates}
						/>
					</div>
				)}
			</>
		</Popup>
	);
};
