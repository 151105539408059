import { useEffect, useState } from "react";
import cn from "classnames";

import { ReactComponent as UserIcon } from "../../../../assets/icons/user-icon.svg";
import styles from "./index.module.scss";

type Props = {
	imgSrc?: string;
	name?: string;
	lastName?: string;
	isLoading?: boolean;
	color?: "primary" | "secondary";
	classNames?: {
		root?: string;
		noImgBlock?: string;
		noInitialsIcon?: string;
	};
};

export const Avatar = ({
	imgSrc,
	name,
	lastName,
	classNames,
	isLoading,
	color = "primary",
}: Props): JSX.Element => {
	const initials = `${name?.slice(0, 1) || ""}${lastName?.slice(0, 1) || ""}`.toUpperCase();

	return (
		<div
			className={cn(styles.root, classNames?.root, {
				[styles.loading]: isLoading,
			})}
		>
			{imgSrc && !isLoading ? (
				<img src={imgSrc} alt="Avatar" />
			) : (
				<div className={cn(styles.noImg, classNames?.noImgBlock, styles[`bg__${color}`])}>
					{initials || <UserIcon className={cn(styles.noInitialsIcon, classNames?.noInitialsIcon)} />}
				</div>
			)}
		</div>
	);
};
