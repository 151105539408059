import { useForm } from "react-hook-form";
import cn from "classnames";

import { Select } from "../../index";
import { options } from "../map-utils/utils";

import { ReactComponent as CancelIcon } from "../../../../assets/icons/cancel.svg";
import { ReactComponent as DrawOnMapIcon } from "../../../../assets/icons/draw-on-map.svg";
import "./MapHandlers.scss";

type Props = {
	onDrawHandler: () => void;
	polygonCount: number;
	onRemoveHandler: () => void;
	activeHandlers: boolean;
	onSelect: (value: string) => void;
};

export const MapHandlers = ({
	onDrawHandler,
	polygonCount,
	onRemoveHandler,
	activeHandlers,
	onSelect,
}: Props): JSX.Element => {
	const { register, setValue } = useForm();

	return (
		<div className="map-handlers">
			<div
				className={cn("map-handlers__draw", {
					active: activeHandlers,
				})}
			>
				<button
					type="button"
					aria-label="handle-draw"
					className="map-handlers__button"
					onClick={onDrawHandler}
				>
					<DrawOnMapIcon />
					<span className="map-handlers__button-text">Нарисовать область</span>
				</button>

				{!!polygonCount && (
					<div className="map-handlers__count">
						{polygonCount}
						<button
							type="button"
							onClick={onRemoveHandler}
							aria-label="remove"
							className="map-handlers__remove"
						>
							<CancelIcon />
						</button>
					</div>
				)}
			</div>

			<form className="map-handlers__select">
				<Select
					name="positionId"
					options={options}
					register={register}
					onChange={onSelect}
					defaultValue={options[0].value}
					setValue={setValue}
				/>
			</form>
		</div>
	);
};
