import { Client } from "../../Client";
import { CartPayload, CartResponse, IRequestPrice, IRequestPriceResponse } from "../../../../types";

export const CartService = {
  list: () => Client.get<CartResponse>(`/services/cart`),
  add: (payload: CartPayload[]) => Client.post<CartResponse>(`/services/cart`, payload),
  update: (payload: CartPayload) => Client.patch(`/services/cart`, payload),
  delete: (id: string) => Client.delete(`/services/cart/${id}`),
  promoCode: (promoCode?: string) => Client.post(`/services/cart/promoCode`, {
    promoCode
  }),
  requestPrice: (data: IRequestPrice ) => Client.post<IRequestPriceResponse>(`/services/cart/requestPrice`, data)
}