import { useMutation } from "react-query";
import { api } from "../../api";

const DEFAULT_MN = "sdelka/user/application-apply-service/DEFAULT_MN";

type UseApplyService = {
  onSuccess?: () => void;
};

export const useApplyService = ({onSuccess}: UseApplyService) => {
  const mutate = useMutation(DEFAULT_MN, {
    mutationFn: ({applicationId, serviceId}: { applicationId: string; serviceId: string }) =>
      api.application.applyService(applicationId, serviceId),
    onSuccess,
  });

  return {
    mutate: mutate.mutate,
    isLoading: mutate.isLoading,
  };
};
