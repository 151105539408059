import { UseFormRegister } from "react-hook-form";
import cn from "classnames";

import { AnyInputProps } from "../../../../../types";

import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search.svg";
import styles from "./index.module.scss";

type Props = {
	prepend?: string;
	append?: string;
	register?: UseFormRegister<any>;
	withBorder?: boolean;
	onChange?: (value: string) => void;
} & AnyInputProps;

export const RoundInput = ({
	prepend,
	append,
	register,
	onChange,
	name,
	placeholder,
	loading,
	disabled,
	className,
	withBorder,
	value,
}: Props): JSX.Element => {
	if (loading) {
		return <div className={styles.loading} />;
	}

	return (
		<div className={cn(styles.root, className)}>
			{register && (
				<input
					disabled={disabled}
					autoComplete="off"
					placeholder={placeholder}
					className={cn(styles.input, {
						[styles.withPrepend]: prepend,
						[styles.withAppend]: append,
						[styles.withBorder]: withBorder,
					})}
					{...register(name, {
						setValueAs: (v) => {
							if (onChange) {
								onChange(v);
							}

							return v;
						},
					})}
				/>
			)}

			{!register && onChange && (
				<input
					value={value}
					disabled={disabled}
					autoComplete="off"
					placeholder={placeholder}
					className={cn(styles.input, {
						[styles.withPrepend]: prepend,
						[styles.withAppend]: append,
					})}
					onChange={(event) => onChange(event.target.value)}
				/>
			)}

			{prepend && (
				<div className={cn(styles.prepend, styles[`prepend__${prepend}`])}>
					{prepend === "search" ? <SearchIcon /> : prepend}
				</div>
			)}

			{append && (
				<div className={cn(styles.append, styles[`append__${append}`])}>
					{append === "search" ? <SearchIcon /> : append}
				</div>
			)}
		</div>
	);
};
