import styles from "./index.module.scss"
import { ReactComponent as IconDelete } from "../../../../../../../../../../../assets/icons/close.svg";
import { Compilation } from "../../../../../../../../../../../types";
import { CardObjectBuyElement } from "../../../../../../../../../../UI";


type Props = {
  applicationId: string;
  collection?: Compilation
  isFetchingCollection?: boolean
  classnames?: {
    body?: string
    countBlock?: string
  }
  onCardClick?: () => void
};

export const CardObjectBuyWrapper = (
  {
    applicationId,
    collection,
    isFetchingCollection,
    classnames,
    onCardClick,
  }: Props): JSX.Element => {
  return (
    <div className={styles.wrapperCard}>
      <div className={styles.rowBetween}>
        <div className={styles.label}>Подборка</div>
        <div className={styles.row}>
          <IconDelete/>
          <div className={styles.delete}>Удалить</div>
        </div>
      </div>
      <CardObjectBuyElement
        onCardClick={onCardClick}
        applicationId={applicationId}
        classnames={classnames}
        isFetchingCollection={isFetchingCollection}
        collection={collection}
      />
    </div>
  )
}