import { ReactNode, useCallback } from "react";
import { LeadStatusEnum, LeadInList, leadStatuses } from "../../../../../types";
import { Table } from "../../../../UI";
import { useOpenLead } from "../../helpers";
import { useColumns } from "./columns";
import { useBlinkRow } from "../../../../../utils/hooks";
import { ReactComponent as AlertIcon } from "../../../../../assets/icons/alert-info.svg";
import { ReactComponent as TimerIcon } from "../../../../../assets/icons/timer.svg";
import { ReactComponent as TabletOkIcon } from "../../../../../assets/icons/tablet-ok.svg";
import { ReactComponent as CheckedIcon } from "../../../../../assets/icons/radio-selected.svg";
import { ReactComponent as NewIcon } from "../../../../../assets/icons/help-outlined.svg";
import styles from "./index.module.scss";

const stageKeys: Record<LeadStatusEnum, ReactNode> = {
	new: <AlertIcon className={styles.alertIcon} />,
	deferred: <TimerIcon className={styles.timerIcon} />,
	in_progress: <TabletOkIcon className={styles.inWorkIcon} />,
	done: <CheckedIcon className={styles.successIcon} />,
	free: <NewIcon className={styles.cancelIcon} />,
};

type Props = {
	leadsList: LeadInList[];
	isLoading: boolean;
	refetch: () => void;
};

export const LeadsTable = ({ leadsList, isLoading, refetch }: Props): JSX.Element => {
	const columns = useColumns();
	const { rowsToBlink, callback } = useBlinkRow();

	const renderTitle: (item: string) => JSX.Element = useCallback(
		(title) => (
			<div className={styles.title}>
				{stageKeys[title]}
				{leadStatuses[title]}
			</div>
		),
		[]
	);

	const { handleOpenLead } = useOpenLead({
		refetch,
		callbackAfterClose: (id: string) => callback(id),
	});

	const handleRowClick = (row: LeadInList) => {
		handleOpenLead(row.id);
	};

	return (
		<div className={styles.wrapper}>
			{leadsList?.length > 0 || isLoading ? (
				<Table<LeadInList>
					idKey="id"
					data={leadsList}
					columns={columns}
					groupKey="status"
					rowClass={styles.row}
					transformGroupTitle={renderTitle}
					onRowClick={handleRowClick}
					loading={{ isLoading, rowsCount: 5 }}
					activeRow={{
						accessor: "",
						activeId: 1,
					}}
					blinkRows={rowsToBlink}
				/>
			) : (
				<div className={styles.empty}>Похожих лидов не найдено</div>
			)}
		</div>
	);
};
