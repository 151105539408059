import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EnterCodeForm } from "../../components";
import { acceptInvite } from "../../../../../service/redux/modules/auth/auth.thunk";
import {
  setCompanyName,
  setJwtToken,
  setStep,
  setVerificationCode,
} from "../../../../../service/redux/modules/registration-steps/reg-steps.action";
import {
  SmsCodeConfirmationResponse,
  useSmsCodeConfirmation,
} from "../../../../../service/api/mutations";
import { useLoadCompanyPublic } from "../../../../../service/api/queries";
import { useAuthAccession, useRegistration } from "../../../../../service/api/mutations";
import { useTimer } from "../../../../../utils/hooks";
import { digitOnly } from "../../../../../utils/string";
import { Invite, RegistrationResponse } from "../../../../../types";

type Props = {
  invite?: Invite;
};

export const EnterCodeRegistrationPage = ({invite}: Props): JSX.Element => {

  const [sessionData, setSessionData] = useState<RegistrationResponse | null>(null);
  const dispatch = useDispatch();
  const {mutate} = useAuthAccession({
    onSuccess: () => dispatch(setStep("done-auth-accession")),
  });

  const [timer, handleTimer] = useTimer(30);

  const {getLastMutationCache} = useRegistration({});

  const {companyPublic} = useLoadCompanyPublic({
    id: invite?.companyId || "",
  });

  const acceptOrCreateInvite = async () => {
    if (invite?.type === "Персональная ссылка") {
      dispatch(acceptInvite({id: invite.inviteId}));
      dispatch(setCompanyName(invite.companyName));
      dispatch(setStep("done-invitation"));
    } else if (invite?.type === "Публичная ссылка") {
      const data = {
        companyId: invite.companyId,
      };

      mutate(data);
    } else {
      dispatch(setStep("personal-data"));
    }
  };

  const onSmsCodeConfirmationSuccess = ({data}: SmsCodeConfirmationResponse) => {
    if (invite) {
      acceptOrCreateInvite();
    } else {
      dispatch(setJwtToken(data.error.payload.token));
      dispatch(setStep("personal-data"));
    }
  };

  const {mutate: confirmCode} = useSmsCodeConfirmation({
    onSuccess: onSmsCodeConfirmationSuccess,
  });

  useEffect(() => {
    handleTimer();
  }, []);

  useEffect(() => {
    const lastRegisterMutation = getLastMutationCache();

    if (lastRegisterMutation?.state?.status === "success") {
      const response = lastRegisterMutation.state?.data as any;

      setSessionData(response.data);
    }
  }, []);

  const onSubmit = (values: { code: string }) => {
    dispatch(setCompanyName(companyPublic?.companyName || ""));
    confirmCode({
      code: digitOnly(values.code),
      token: sessionData?.token ?? "",
    });
  };

  const resetCode = async () => {
    const lastRegisterMutation = getLastMutationCache();
    const {data}: any = await lastRegisterMutation.execute();
    setSessionData(data as RegistrationResponse);

    handleTimer();
  };

  if (!sessionData) {
    <>Произошла ошибка, попробуйте снова</>;
  }

  return (
    <div>
      <h1>Регистрация</h1>
      <EnterCodeForm
        onSubmit={onSubmit}
        resetCode={resetCode}
        label="Код для входа в систему"
        setCode={(value: string) => dispatch(setVerificationCode(value))}
        setBackStep={() => dispatch(setStep("personal-data"))}
        timer={timer}
      />
    </div>
  );
};
