import { Tabs } from "@sdelka_crm/sdelka-crm-component-library";
import cn from "classnames";

import { useTasksContext } from "../../../../../../../../../service/context/Tasks";
import { TaskUserRole } from "../../../../../../../../../types";

import styles from "./index.module.scss";

type Tab = {
	label: string;
	role: TaskUserRole;
};

const tabs: Tab[] = [
	{
		label: "Исполнитель",
		role: "executor",
	},
	{
		label: "Ответственный",
		role: "responsible",
	},
	{
		label: "Создатель",
		role: "creator",
	},
	{
		label: "Доступные",
		role: "allowed",
	},
];

const tabItems = tabs.map(({ label }) => <div>{label}</div>);

type Props = {
	className?: string;
};

export const TasksMainFilters = ({ className }: Props): JSX.Element => {
	const { setRole } = useTasksContext();

	const onTabChange = (idx: number) => setRole(tabs[idx].role);

	return (
		<div className={cn(styles.root, className)}>
			<Tabs onChange={onTabChange} tabs={tabItems} size="large" />
		</div>
	);
};
